// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { inviteUserForMeetingByToken } from '../../Actions/shareMeetingActions';
// import { connect } from 'react-redux';
// import { genericMeetingTranslations } from '../../Translations/genericMeetingTranslations'
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { InviteUserForMeetingByToken, InviteUserForMeetingByTokenPayload, InviteUsersForMeeting } from '../../Interfaces/PayloadsAndResponses/ShareMeeting';
// import { WithTranslation } from 'react-i18next';
// import { ShareMeeting } from '../../Interfaces/PayloadsAndResponses/Menu';
// import isEmpty from '../../Utilities/is_empty';
// import Spinner from '../Spinners/Spinner';
// import { phoneNumberPattern } from '../../Utilities/validationPatterns';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//   }
// };

// Modal.setAppElement('#root');
// Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal.defaultStyles.overlay!.zIndex = 6;

// type GenericMeetingPopUpProps = {
//   token: string | null | undefined;
//   i18n: WithTranslation | null;
//   meetingSlug: string | undefined;
//   alreadySharedMeeting?: ShareMeeting | undefined;
//   onRef: (ref: GenericMeetingPopUpRef | null) => void;
//   invite_user_status: InviteUserForMeetingByToken | InviteUsersForMeeting | null;
//   inviteUserForMeetingByToken: (user: InviteUserForMeetingByTokenPayload, meetingSlug: string, token: string, isAlreadySharedMeeting: boolean) => void,
// }
// type GenericMeetingPopUpState = {
//   modalIsOpen: boolean,
//   firstName: string,
//   lastName: string,
//   phoneNumber:string,
//   email: string,
//   isLoading: boolean;
// }
// class GenericMeetingPopUp extends Component<GenericMeetingPopUpProps, GenericMeetingPopUpState> {

//   state = {
//     modalIsOpen: false,
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneNumber: '',
//     isLoading: false,
//   };

//   componentDidMount() {
//     genericMeetingTranslations();
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null);
//   }
//   UNSAFE_componentWillReceiveProps(nextProps: GenericMeetingPopUpProps) {
//     const { invite_user_status } = nextProps;
//     if (invite_user_status !== null) {
//       this.closeModal();
//     }
//   }
//   openModal = (alreadySharedMeeting?: ShareMeeting) => {
//     if (alreadySharedMeeting) {
//       const { first_name = '', last_name = '', email = '', phone_number = '' } = alreadySharedMeeting;
//       this.setState({ firstName: first_name, lastName: last_name, email: email, phoneNumber: phone_number, modalIsOpen: true })
//     } else {
//       this.setState({ modalIsOpen: true });
//     }
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false, isLoading: false });
//   }
//   onSubmit = (e: React.MouseEvent<HTMLElement>) => {
//     e.preventDefault();
//     const { meetingSlug, inviteUserForMeetingByToken, token, alreadySharedMeeting } = this.props;
//     if (meetingSlug && token) {
//       let user: InviteUserForMeetingByTokenPayload = {
//         share_meeting: {
//           first_name: this.state.firstName,
//           last_name: this.state.lastName,
//           email: this.state.email,
//           phone_number: this.state.phoneNumber
//         }
//       }
//       this.setState({ isLoading: true });
//       // !isEmpty(alreadySharedMeeting) ? true : false (Param for Redirect Puropose)
//       inviteUserForMeetingByToken(user, meetingSlug, token, !isEmpty(alreadySharedMeeting));
//     }
//   }


//   handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     this.setState({ [e.target.name]: e.target.value } as unknown as GenericMeetingPopUpState);
//   }
//   render() {
//     const { i18n, alreadySharedMeeting } = this.props;

//     return (
//       <Modal
//         shouldCloseOnOverlayClick={false}
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className={`order-modal ${this.state.isLoading ? 'disabled-opacity' : ''}`} id='orderModelBox'>
//           <div className='modal-dialog' >
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className='modal-header' style={{ width: '690px' }}>
//                 <h5 className='modal-title' id='modal-title'>  {i18n && i18n.t('Your Name')} </h5>
//                 {/* <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button> */}
//               </div>
//               <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
//                 <div id='main' className='container'>
//                   <div className='form-row'>
//                     <div className='col-11 m-auto text-center'>
//                       <h2 className='text-danger'> {i18n && i18n.t(`What's Your Name ? `)}</h2>
//                       < p className='text-muted' > {i18n && i18n.t('Tell us a bit about yourself so we know whose ordering.')}</p>
//                     </div>
//                   </div>
//                   <form noValidate className='text-left'>
//                     <div className='form-row'>
//                       <div className='col-6 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '> {i18n && i18n.t('First Name')}</h6>
//                         <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
//                         <input type='text' className='form-control' id='firstName' name='firstName' value={this.state.firstName || ''} onChange={this.handleChange} />
//                       </div>
//                       <div className='col-6 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '> {i18n && i18n.t('Last Name')}</h6>
//                         <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
//                         <input type='text' className='form-control' id='lastName' name='lastName' value={this.state.lastName || ''} onChange={this.handleChange} />
//                       </div>
//                     </div>
//                     <div className='form-row'>
//                       <div className='col-6 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '> {i18n && i18n.t('Email')}</h6>
//                         <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
//                         <input disabled={!isEmpty(alreadySharedMeeting)} type='email' className='form-control' id='email' name='email' value={this.state.email || ''} onChange={this.handleChange} />
//                       </div>
//                       <div className='col-6 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '> {i18n && i18n.t('Phone Number')}</h6>
//                         <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('')}</h6>
//                         <input type='text' className='form-control' id='phoneNumber' placeholder='Optional, for Delivery Notification' name='phoneNumber' value={this.state.phoneNumber || ''} onChange={this.handleChange} />
//                         {this.state.phoneNumber &&
//                           <div style={{ 'opacity': '0.5', 'fontSize': '14px', 'color':'red' }}>*Please match the format (XXX-XXX-XXXX)</div>
//                         }
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer' style={{ width: '100%' }}>
//           {this.state.isLoading ?
//             <button style={{ width: 138, height: 38 }} className='ml-3 btn btn-primary delivery-btn-width' disabled><Spinner spinnerSizeClass='spinner-border-sm' /></button> :
//             <button
//               type='submit'
//               className='ml-3 btn btn-primary delivery-btn-width'
//               onClick={this.onSubmit} disabled={this.state.firstName === '' || this.state.lastName === '' || this.state.email === '' || this.state.email.match(/\S+@\S+\.\S+/) === null || (this.state.phoneNumber !== '' ? !phoneNumberPattern.test(this.state.phoneNumber) : false)}
//             >
//               {i18n && i18n.t('Begin Order')}
//             </button>
//           }
//         </div>
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   invite_user_status: state.share_meeting.inviteUserForMeetingByToken,
//   i18n: state.i18n.i18n
// });

// export type GenericMeetingPopUpRef = React.ElementRef<typeof GenericMeetingPopUp>;

// export default (connect(mapStateToProps, { inviteUserForMeetingByToken })(GenericMeetingPopUp));


import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Modal from 'react-modal';
import { inviteUserForMeetingByToken } from '../../Actions/shareMeetingActions';
import { genericMeetingTranslations } from '../../Translations/genericMeetingTranslations';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { InviteUserForMeetingByTokenPayload } from '../../Interfaces/PayloadsAndResponses/ShareMeeting';
import { ShareMeeting } from '../../Interfaces/PayloadsAndResponses/Menu';
import isEmpty from '../../Utilities/is_empty';
import Spinner from '../Spinners/Spinner';
import { phoneNumberPattern } from '../../Utilities/validationPatterns';
import { useSelector, useDispatch } from 'react-redux';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '692px',
    padding: '0'
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

export type GenericMeetingPopUpRef = {
  openModal: (alreadySharedMeeting?: ShareMeeting) => void;
  closeModal: () => void;
  modalIsOpen: boolean;
}

type GenericMeetingPopUpProps = {
  token: string | null | undefined;
  meetingSlug: string | undefined;
  alreadySharedMeeting?: ShareMeeting | undefined;
}

const GenericMeetingPopUp = forwardRef<GenericMeetingPopUpRef | null, GenericMeetingPopUpProps>(
  ({ token, meetingSlug, alreadySharedMeeting }, ref) => {
  
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const invite_user_status = useSelector((state: ReduxRootState) => state?.share_meeting?.inviteUserForMeetingByToken);
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);
  const dispatch: any = useDispatch();
  
  useEffect(() => {
    genericMeetingTranslations();
  }, []);

  useEffect(() => {
    if (invite_user_status !== null) {
      closeModal();
    }
  }, [invite_user_status]);
  
  const closeModal = () => {
    setModalIsOpen(false);
    setIsLoading(false);
  };

  const openModal = (alreadySharedMeeting?: ShareMeeting) => {
    if (alreadySharedMeeting) {
      const { first_name = '', last_name = '', email = '', phone_number = '' } = alreadySharedMeeting;
      setFirstName(first_name);
      setLastName(last_name);
      setEmail(email);
      setPhoneNumber(phone_number);
      setModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  }

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    modalIsOpen
  }));

  const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (meetingSlug && token) {
      const user: InviteUserForMeetingByTokenPayload = {
        share_meeting: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNumber
        }
      };
      setIsLoading(true);
      dispatch(inviteUserForMeetingByToken(user, meetingSlug, token, !isEmpty(alreadySharedMeeting)));
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className={`order-modal ${isLoading ? 'disabled-opacity' : ''}`} id='orderModelBox'>
          <div className='modal-dialog' >
            <div className='modal-content' style={{ border: 'none' }}>
              <div className='modal-header' style={{ width: '690px' }}>
                <h5 className='modal-title' id='modal-title'>  {i18n && i18n?.t('Your Name')} </h5>
                {/* <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button> */}
              </div>
              <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
                <div id='main' className='container'>
                  <div className='form-row'>
                    <div className='col-11 m-auto text-center'>
                      <h2 className='text-danger'> {i18n && i18n?.t(`What's Your Name ? `)}</h2>
                      < p className='text-muted' > {i18n && i18n?.t('Tell us a bit about yourself so we know whose ordering.')}</p>
                    </div>
                  </div>
                  <form noValidate className='text-left'>
                    <div className='form-row'>
                      <div className='col-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {i18n && i18n?.t('First Name')}</h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('Required')}</h6>
                        <input type='text' className='form-control' id='firstName' name='firstName' value={firstName || ''} onChange={handleChange} />
                      </div>
                      <div className='col-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {i18n && i18n?.t('Last Name')}</h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('Required')}</h6>
                        <input type='text' className='form-control' id='lastName' name='lastName' value={lastName || ''} onChange={handleChange} />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {i18n && i18n?.t('Email')}</h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('Required')}</h6>
                        <input disabled={!isEmpty(alreadySharedMeeting)} type='email' className='form-control' id='email' name='email' value={email || ''} onChange={handleChange} />
                      </div>
                      <div className='col-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {i18n && i18n?.t('Phone Number')}</h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('')}</h6>
                        <input type='text' className='form-control' id='phoneNumber' placeholder='Optional, for Delivery Notification' name='phoneNumber' value={phoneNumber || ''} onChange={handleChange} />
                        {phoneNumber &&
                          <div style={{ 'opacity': '0.5', 'fontSize': '14px', 'color':'red' }}>*Please match the format (XXX-XXX-XXXX)</div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer' style={{ width: '100%' }}>
          {isLoading ?
            <button style={{ width: 138, height: 38 }} className='ml-3 btn btn-primary delivery-btn-width' disabled><Spinner spinnerSizeClass='spinner-border-sm' /></button> :
            <button
              type='submit'
              className='ml-3 btn btn-primary delivery-btn-width'
              onClick={onSubmit} disabled={firstName === '' || lastName === '' || email === '' || email.match(/\S+@\S+\.\S+/) === null || (phoneNumber !== '' ? !phoneNumberPattern.test(phoneNumber) : false)}
            >
              {i18n && i18n?.t('Begin Order')}
            </button>
          }
        </div>
      </Modal>
  )
})

export default GenericMeetingPopUp;