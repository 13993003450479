// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { connect } from 'react-redux';
// import ScheduleDeliveryForm, { ScheduleDeliveryFormRef } from '../ScheduleDeliveryForm';
// import $ from 'jquery'
// import { updateScheduleRequest, makeDeliveryRequest } from '../../Actions/scheduleDeliveryActions';
// import isEmpty from '../../Utilities/is_empty';
// import Spinner from '../Spinners/Spinner';
// import { getloggedInUser } from '../../Utilities/getloggedInUser';
// import { CloneOrderCustomFields } from '../../Pages/Menu/Common/CloneOrders';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { WithTranslation } from 'react-i18next';
// import { DeliveryRequestPayload } from '../../Interfaces/PayloadsAndResponses/Schedules';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//     comment: ''
//   }
// };
// Modal.setAppElement('#root');
// Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal.defaultStyles.overlay!.zIndex = 6;

// type PostCloneFieldsPopUpProps = {
//   i18n: WithTranslation | null,
//   isAdmin: boolean;
//   onRef: (ref: PostCloneFieldsPopUpRef | null) => void;
//   onUpdatePage: () => void;
//   updateScheduleRequest: (deliverySlug: string, deliveryData: DeliveryRequestPayload, successCallback: () => void) => void;
//   makeDeliveryRequest: (deliveryData: DeliveryRequestPayload, getDeliveryDates: boolean, cloneOrdersIds?: number[], successCallback?: () => void) => void;
// }

// export type PostCloneFieldsPopUpState = {
//   modalIsOpen: boolean;
//   runningmenuSlug: null | string;
//   marketplaceMeeting: DeliveryRequestPayload['runningmenu'];
//   orderFieldAttributes: CloneOrderCustomFields;
//   cloneOrdersIds: null | number[];
//   updatingFieldsLoading: boolean;
// }

// class PostCloneFieldsPopUp extends Component<PostCloneFieldsPopUpProps, PostCloneFieldsPopUpState> {

//   scheduleRequestForm = React.createRef<ScheduleDeliveryFormRef>().current;

//   static defaultProps = {
//     isAdmin: getloggedInUser().isCompanyAdmin
//   }

//   state: PostCloneFieldsPopUpState = {
//     modalIsOpen: false,
//     runningmenuSlug: null, // This Attribute can be updated from Parent Component
//     marketplaceMeeting: {},
//     cloneOrdersIds: null,
//     updatingFieldsLoading: false,
//     orderFieldAttributes: [],
//   };

//   componentDidMount() {
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null);
//   }
//   openModal = () => {
//     this.setState({ modalIsOpen: true });
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false, cloneOrdersIds: null });
//     localStorage.removeItem('order_id');
//     localStorage.removeItem('rating_count');
//   }
//   validateCustomFields() {
//     let requiredCustomFields = true;
//     let document: Document = window.document;
//     if (this.props.isAdmin && this.scheduleRequestForm?.state) {
//       this.scheduleRequestForm.state.orderFieldAttributes.forEach(field => {
//         if (field.required === 1) {
//           if (!field.value && requiredCustomFields) {
//             requiredCustomFields = false;
//             document.getElementById(`custom-${field.id}`)?.classList?.add('required-error-highlight');
//             $('#mydiv123').animate({ scrollTop: 100 }, 1000);
//           } else if (document.getElementById(`custom-${field.id}`)?.classList?.contains('required-error-highlight')) {
//             document.getElementById(`custom-${field.id}`)?.classList?.remove('required-error-highlight');
//           }
//         }
//       });
//     }
//     return requiredCustomFields;
//   }
//   onSubmit = () => {
//     const { marketplaceMeeting, runningmenuSlug, cloneOrdersIds } = this.state || {};
//     let temp = [];
//     const fields = this.scheduleRequestForm?.state.orderFieldAttributes;
//     if (fields && fields.length > 0) {
//       if (isEmpty(marketplaceMeeting)) {
//         for (var i = 0; i < fields.length; i++) {
//           if (fields[i].field_type === 'text' || fields[i].field_type === 1) {
//             temp.push({ field_id: fields[i].field_id!, value: fields[i].value, id: fields[i].id });
//           } else {
//             temp.push({ field_id: fields[i].field_id!, fieldoption_id: fields[i].value, id: fields[i].id });
//           }
//         }
//       } else {
//         for (var g = 0; g < fields.length; g++) {
//           if (fields[g].field_type === 'text' || fields[g].field_type === 1) {
//             temp.push({ field_id: fields[g].id, value: fields[g].value });
//           } else {
//             temp.push({ field_id: fields[g].id, fieldoption_id: fields[g].value });
//           }
//         }
//       }
//     };

//     if (this.scheduleRequestForm?.state && this.validateCustomFields()) {
//       this.setState({ updatingFieldsLoading: true });
//       if (isEmpty(marketplaceMeeting) && cloneOrdersIds) {
//         let requestData: DeliveryRequestPayload = {
//           order_ids: cloneOrdersIds,
//           runningmenu: {
//             runningmenufields_attributes: Object.assign({}, temp)
//           }
//         }
//         runningmenuSlug && this.props.updateScheduleRequest(runningmenuSlug, requestData, () => {
//           this.setState({ updatingFieldsLoading: false, modalIsOpen: false })
//         });
//       } else {
//         let requestData: DeliveryRequestPayload = {
//           runningmenu: {
//             ...marketplaceMeeting,
//             runningmenufields_attributes: Object.assign({}, temp)
//           }
//         };
//         // 2nd Argument is to get Delivery Dates
//         this.props.makeDeliveryRequest(requestData, false, cloneOrdersIds || [], () => this.setState({ updatingFieldsLoading: false, modalIsOpen: false }));
//       }
//     }
//   }

//   render() {
//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className='order-modal' id='orderModelBox'>
//           <div className='modal-dialog' >
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className='modal-header' style={{ width: '740px' }}>
//                 <h5 className='modal-title' id='modal-title'> Custom Fields </h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div id='mydiv123' className='modal-body' style={{ width: '740px' }}>
//                 <div id='main' className='container'>
//                   <form noValidate onSubmit={this.onSubmit} className='text-left'>
//                     {this.props.isAdmin ?
//                       <ScheduleDeliveryForm
//                         selectedType={undefined}
//                         selectedAddress={undefined}
//                         meetingUpdateSlug={undefined}
//                         meetingStatus={undefined}
//                         onRef={(ref: ScheduleDeliveryFormRef | null) => (this.scheduleRequestForm = ref)}
//                         setState={(p: PostCloneFieldsPopUpState) => { this.setState(p) }}
//                         closeModal={this.closeModal}
//                         onUpdatePage={this.props.onUpdatePage}
//                         requiredFields={undefined}
//                         orderPopUp={true}
//                         cloneOrderPurposes={true}
//                         orderFieldAttributes={this.state.orderFieldAttributes}
//                       /> : ''}
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer' style={{ width: 690 }}>
//           {this.state?.updatingFieldsLoading ?
//             <button
//               style={{ width: '138px', height: '38px' }}
//               className='ml-3 btn btn-primary'
//             >
//               <Spinner spinnerSizeClass='spinner-border-sm' />
//             </button> :
//             <button
//               type='submit'
//               className='ml-3 btn btn-primary delivery-btn-width'
//               onClick={() => this.onSubmit()}
//             >
//               Submit
//             </button>
//           }
//         </div>
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n
// });

// export type PostCloneFieldsPopUpRef = React.ElementRef<typeof PostCloneFieldsPopUp>;

// export default (connect(mapStateToProps, { updateScheduleRequest, makeDeliveryRequest })(PostCloneFieldsPopUp));


// conversion from class to functional component
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import Modal from 'react-modal';
import ScheduleDeliveryForm, { ScheduleDeliveryFormRef } from '../ScheduleDeliveryForm';
import $ from 'jquery'
import { updateScheduleRequest, makeDeliveryRequest } from '../../Actions/scheduleDeliveryActions';
import isEmpty from '../../Utilities/is_empty';
import Spinner from '../Spinners/Spinner';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { CloneOrderCustomFields } from '../../Pages/Menu/Common/CloneOrders';
import { DeliveryRequestPayload } from '../../Interfaces/PayloadsAndResponses/Schedules';
import { useDispatch } from 'react-redux';

export type PostCloneFieldsPopUpRef = {
  openModal: () => void;
  closeModal: () => void;
  handleState: (modalIsOpen: boolean, runningmenuSlug: null | string, orderFieldAttributes: CloneOrderCustomFields, marketplaceMeeting: DeliveryRequestPayload['runningmenu'], cloneOrdersIds: null | number[]) => void
}

type PostCloneFieldsPopUpProps = {
  onUpdatePage: () => void;
}
const PostCloneFieldsPopUp = forwardRef<PostCloneFieldsPopUpRef | null, PostCloneFieldsPopUpProps>(
  ({ onUpdatePage }, ref) => {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '752px',
        padding: '0',
        comment: ''
      }
    };
    Modal.setAppElement('#root');
    Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
    Modal.defaultStyles.overlay!.zIndex = 6;

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [runningmenuSlug, setRunningmenuSlug] = useState<null | string>(null);
    const [marketplaceMeeting, setMarketplaceMeeting] = useState<DeliveryRequestPayload['runningmenu']>({});;
    const [orderFieldAttributes, setOrderFieldAttributes] = useState<CloneOrderCustomFields>([]);
    const [cloneOrdersIds, setCloneOrdersIds] = useState<null | number[]>(null);
    const [updatingFieldsLoading, setUpdatingFieldsLoading] = useState<boolean>(false);

    let scheduleRequestForm = React.createRef<ScheduleDeliveryFormRef>().current;

    const dispatch: any = useDispatch()

    const isAdmin = getloggedInUser()?.isCompanyAdmin

    const openModal = () => {
      setModalIsOpen(true)
    }
    const closeModal = () => {
      setModalIsOpen(false)
      setCloneOrdersIds(null)
      localStorage.removeItem('order_id');
      localStorage.removeItem('rating_count');
    }
    const validateCustomFields = () => {
      let requiredCustomFields = true;
      let document: Document = window.document;
      if (isAdmin && scheduleRequestForm?.state) {
        scheduleRequestForm?.state?.orderFieldAttributes?.forEach(field => {
          if (field?.required === 1) {
            if (!field?.value && requiredCustomFields) {
              requiredCustomFields = false;
              document.getElementById(`custom-${field.id}`)?.classList?.add('required-error-highlight');
              $('#mydiv123').animate({ scrollTop: 100 }, 1000);
            } else if (document.getElementById(`custom-${field?.id}`)?.classList?.contains('required-error-highlight')) {
              document.getElementById(`custom-${field?.id}`)?.classList?.remove('required-error-highlight');
            }
          }
        });
      }
      return requiredCustomFields;
    }
    const onSubmit = () => {
      let temp = [];
      const fields = scheduleRequestForm?.state?.orderFieldAttributes;
      if (fields && fields?.length > 0) {
        if (isEmpty(marketplaceMeeting)) {
          for (var i = 0; i < fields?.length; i++) {
            if (fields[i]?.field_type === 'text' || fields[i]?.field_type === 1) {
              temp?.push({ field_id: fields[i]?.field_id!, value: fields[i]?.value, id: fields[i]?.id });
            } else {
              temp?.push({ field_id: fields[i]?.field_id!, fieldoption_id: fields[i]?.value, id: fields[i]?.id });
            }
          }
        } else {
          for (var g = 0; g < fields?.length; g++) {
            if (fields[g]?.field_type === 'text' || fields[g]?.field_type === 1) {
              temp?.push({ field_id: fields[g]?.id, value: fields[g]?.value });
            } else {
              temp?.push({ field_id: fields[g]?.id, fieldoption_id: fields[g]?.value });
            }
          }
        }
      };

      if (scheduleRequestForm?.state && validateCustomFields()) {
        setUpdatingFieldsLoading(true)
        if (isEmpty(marketplaceMeeting) && cloneOrdersIds) {
          let requestData: DeliveryRequestPayload = {
            order_ids: cloneOrdersIds,
            runningmenu: {
              runningmenufields_attributes: Object.assign({}, temp)
            }
          }
          runningmenuSlug && dispatch(updateScheduleRequest(runningmenuSlug, requestData, () => {
            setUpdatingFieldsLoading(false);
            setModalIsOpen(false)
          }));
        } else {
          let requestData: DeliveryRequestPayload = {
            runningmenu: {
              ...marketplaceMeeting,
              runningmenufields_attributes: Object.assign({}, temp)
            }
          };
          // 2nd Argument is to get Delivery Dates
          dispatch(makeDeliveryRequest(requestData, false, cloneOrdersIds || [], () => {
            setUpdatingFieldsLoading(false)
            setModalIsOpen(false)
          }))
        }
      }
    }

    const handleState = (modalIsOpen: boolean, runningmenuSlug: null | string, orderFieldAttributes: CloneOrderCustomFields, marketplaceMeeting: DeliveryRequestPayload['runningmenu'], cloneOrdersIds: null | number[]) => {
      setModalIsOpen(modalIsOpen);
      setRunningmenuSlug(runningmenuSlug);
      setOrderFieldAttributes(orderFieldAttributes);
      setMarketplaceMeeting(marketplaceMeeting);
      setCloneOrdersIds(cloneOrdersIds)
    }

    useImperativeHandle(ref, () => ({
      openModal,
      closeModal,
      handleState,
    }));
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className='order-modal' id='orderModelBox'>
          <div className='modal-dialog' >
            <div className='modal-content' style={{ border: 'none' }}>
              <div className='modal-header' style={{ width: '750px' }}>
                <h5 className='modal-title' id='modal-title'> Custom Fields </h5>
                <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div id='mydiv123' className='modal-body' style={{ width: '750px' }}>
                <div id='main' className='container'>
                  <form noValidate onSubmit={onSubmit} className='text-left'>
                    {isAdmin &&
                      <ScheduleDeliveryForm
                        selectedType={undefined}
                        selectedAddress={undefined}
                        meetingUpdateSlug={undefined}
                        meetingStatus={undefined}
                        onRef={(ref: ScheduleDeliveryFormRef | null) => (scheduleRequestForm = ref)}
                        // setState={(p: PostCloneFieldsPopUpState) => { this.setState(p) }}
                        closeModal={closeModal}
                        onUpdatePage={onUpdatePage}
                        requiredFields={undefined}
                        orderPopUp={true}
                        cloneOrderPurposes={true}
                        orderFieldAttributes={orderFieldAttributes}
                      />}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer' style={{ width: 690 }}>
          {updatingFieldsLoading ?
            <button
              style={{ width: '138px', height: '38px' }}
              className='ml-3 btn btn-primary'
            >
              <Spinner spinnerSizeClass='spinner-border-sm' />
            </button> :
            <button
              type='submit'
              className='ml-3 btn btn-primary delivery-btn-width'
              onClick={() => onSubmit()}
            >
              Submit
            </button>
          }
        </div>
      </Modal>
    )

  })

export default PostCloneFieldsPopUp;






