import React from 'react';
import dayjs from 'dayjs';
import { getFutureDateWithTimeZone } from '../../../Utilities/getDateForMarketplace';
import ReactTooltip from 'react-tooltip';
import { getZonedTime } from '../../../Utilities/functions';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';

interface CutoffDisplayProps {
  yourOrderHeaderPaddingCheck: any,
  divPositionCheck: any,
  time: Date | string;
  currentDelivery?: Delivery;
  fromMarketplace?: boolean;
  i18n?: any;
}

const CutoffDisplay: React.FC<CutoffDisplayProps> = ({ divPositionCheck, yourOrderHeaderPaddingCheck, time, currentDelivery, fromMarketplace, i18n }) => {

  return (
    <>{time ? <div className='text-nowrap flex-grow-1 cursor-default'>
      <span data-tip
        data-for='menu-detail-page-cutoff'
        className='date-bages'
        style={{ color: dayjs(time).isAfter(new Date()) ? '#007bff ' : '#dc3545' }}
      >
        {i18n?.t('Cutoff ')}
        {
          dayjs(time).isAfter(new Date()) ?
            `in ${dayjs(time).toNow(true)}` : `was ${dayjs(time).fromNow()}`
        }
      </span>
      {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
        <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className={`react-tooltip ${(yourOrderHeaderPaddingCheck || divPositionCheck) && 'react-tooltip-sticky-header-margin-top'} `}>
          {fromMarketplace ? <span>{getFutureDateWithTimeZone(`${dayjs(time).subtract(1, 'day').format(' MMM DD 13:45')}`, currentDelivery?.time_zone)}</span> : <span>{getZonedTime(time, 'EEE. MMM dd h:mma, zzzz', currentDelivery?.time_zone)}</span>}
        </ReactTooltip>
      }
    </div> : <>
      <span data-tip
        data-for='menu-detail-page-cutoff'
        className='date-bages'
        style={{ color: '#dc3545' }}
      >Invalid time
      </span>
    </>
    }</>
  );
};

export default CutoffDisplay;
