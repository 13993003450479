import { LOADING, SET_INVITE_VENDOR_BUTTON_LOADING, REMOVE_INVITE_VENDOR_BUTTON_LOADING, SET_ALL_BUTTONS_LOADING_STATE, RESET_STATE } from '../Actions/types';
import { InviteStatus } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: InviteStatus = {
  loading: false,
  inviteVendorButtonsLoading: {}
}

const inviteReducer = (state: InviteStatus = initialState, action: any): InviteStatus => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case SET_ALL_BUTTONS_LOADING_STATE:
      return {
        ...state,
        inviteVendorButtonsLoading: {}
      }
    case SET_INVITE_VENDOR_BUTTON_LOADING:
      const newLoadingState = { ...state.inviteVendorButtonsLoading }
      newLoadingState[action.payload] = true;
      return {
        ...state,
        inviteVendorButtonsLoading: newLoadingState
      }
    case REMOVE_INVITE_VENDOR_BUTTON_LOADING:
      const LoadingState = { ...state.inviteVendorButtonsLoading };
      LoadingState[action.payload] = false;
      return {
        ...state,
        inviteVendorButtonsLoading: LoadingState
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default inviteReducer;