import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import { GenericTypeForMarketplaceAPIs } from '../../Interfaces/PayloadsAndResponses/Marketplace';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import MarketplaceFilterDummyLogo from '../../Pages/Menu/SVGs/marketplaceFiltersDummyLogo.svg'


type MarketPlaceFilterProps = {
  modalIsOpen: boolean,
  closeModal: () => void,
  marketPlaceFiltersState: GenericTypeForMarketplaceAPIs[],
  resetAllFilters?: () => void | undefined,
  selectSearchStatistics: (pill: GenericTypeForMarketplaceAPIs, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean | undefined) => void;
  eRestaurantLoading?: boolean | null;
}

const MarketPlaceFilterPopUp: FC<MarketPlaceFilterProps> = ({ modalIsOpen, closeModal, resetAllFilters, eRestaurantLoading, selectSearchStatistics, marketPlaceFiltersState }) => {
  const marketplacefilterPopupLoading = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions);
  const { marketplaceCombosLoading, marketplaceFavoritesLoading, marketPlaceMostPopularRestaurantsLoading, marketPlaceNewRestaurantNearYouLoading, marketPlaceFiltersLoading } = marketplacefilterPopupLoading;
  const loading = useSelector((state: ReduxRootState) => state?.loading?.isLoading)
  const marketplaceFilters: any =  useSelector((state: ReduxRootState) => state?.marketplaceSuggestions?.marketplace_filters)
  //Select Diataries, cuisines and ingregients loading
  const isFiltersLoading =
  marketplaceCombosLoading ||
  marketplaceFavoritesLoading ||
  marketPlaceMostPopularRestaurantsLoading ||
  marketPlaceNewRestaurantNearYouLoading ||
  marketPlaceFiltersLoading ||
  eRestaurantLoading || loading;

  const filterType: Record<string, string> = {
    dietary: 'dietaries',
    cuisine: 'cuisines',
    ingredient: 'ingredients',
  };

  const updateArrayAfterComparison = (originalArray: GenericTypeForMarketplaceAPIs[], ArrayObjectsToCompare: GenericTypeForMarketplaceAPIs[]) => {
    return originalArray?.map(item => {
      const matchingObject = ArrayObjectsToCompare?.find(compareObj => (
        compareObj?.id === item?.id && compareObj?.name === item?.name
      ));

      if (matchingObject) {
        return { ...item, selected: true };
      }

      return item;
    });
  }

  const marketplaceFiltersToBeRendered = updateArrayAfterComparison(marketplaceFilters, marketPlaceFiltersState);


  const handleModalClose = () => {
    resetAllFilters?.();
    closeModal?.();
  }

  return (
    <div>
      <div className={`popup-filter shadow ${modalIsOpen ? 'show-popup' : 'hide-popup'}`}>
        <div>
          <div className="filter-header py-3">
            <h4 className='m-0 fw-600 px-4'>Filters</h4>
          </div>
          <div className="wrapper px-0 px-md-4 filter_popup_scroll">
            {/* MarketPlace Diataries */}
            {marketplaceFiltersToBeRendered?.map((filters, index) => {
              const updatedFilterType: any = filterType[filters?.filter_type] || filters?.filter_type;
              return (
                (filters?.name?.trim() === 'Gluten-Free Upon Request') ||
                  (filters?.name?.trim() === 'Nut-Free Upon Request') ||
                  (filters?.name?.trim() === 'Dairy-Free Upon Request') ||
                  (filters?.name?.trim() === 'Vegan-Upon Request') ? ''
                  :
                  <React.Fragment key={index}>
                    {
                    isFiltersLoading ? <button type='button' className={filters?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills  btn btn-sm'} name='updatedFilterType[]'
                      value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                    </button> :
                      <button type='button' className={filters.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills  btn btn-sm'} name='updatedFilterType[]'
                        value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(filters, updatedFilterType)}>
                        <div className='d-flex mx-auto dietary-logo dietary-logo-wrapper'>
                            {filters?.logo ? <div className='d-flex align-items-center justify-content-center' style={{ width: '36px', height: '36px' }} >{parse(filters?.logo)}</div> :
                              <img src={MarketplaceFilterDummyLogo} alt='marketplacefilters_logo' className='svg-sizes' />
                            }
                          </div>
                          <p className='mt-2 mb-0 dietary-labels'>{filters.name} </p>
                        </button>}
                  </React.Fragment>
              )
            })}
          
          </div>
        </div>
        <div className="filter-footer p-4 d-flex justify-content-end">
          <button className='btn btn-light rounded-pill px-4 py-2 font-weight-bold' onClick={handleModalClose}>Reset</button>
          <button className='btn btn-danger rounded-pill px-4 py-2 ml-2 font-weight-bold' onClick={closeModal}>View Results</button>
        </div>
      </div>
    </div>
  )
}

export default MarketPlaceFilterPopUp