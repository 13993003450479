import axios from 'axios';
import { GET_PROFILE_DATA, SET_PROFILE_DATA, GET_BILLING_DATA } from './types';
import { getError } from './errorActions';
import { SaveProfilePayload, SavingBillingPayload } from '../Interfaces/PayloadsAndResponses/Profile';
import { AppThunkAction } from '../store';

export const saveProfile = (profileData: SaveProfilePayload, profileSavedCallback?: (isError?: boolean, errMsg?: string) => void): AppThunkAction => dispatch => {
  axios.put(`/users/profile`, profileData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      if (res.data?.user?.first_name) {
        res.data.user.first_name = res.data.user.first_name?.trim();
      }
      localStorage.setItem('user', JSON.stringify(res.data.user));
      localStorage.setItem('company', JSON.stringify(res.data.company));
      profileSavedCallback?.();
      dispatch({
        type: SET_PROFILE_DATA,
        payload: res.data
      });
    })
    .catch(err => {
      const errMsg = err.response.data.message ?? 'Profile information can not be saved successfully';
      profileSavedCallback?.(true, errMsg);
      dispatch(getError(err));
    }
    );
};

export const saveBilling = (companyBilling: number | null, billing: SavingBillingPayload, billingSavedCallback?: (message: string, isError?: boolean) => void, changingCardloader?: () => void): AppThunkAction => dispatch => {
  axios.put(`/companies/billings/${companyBilling ?? 0}`, { billing },
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_BILLING_DATA,
        payload: res.data
      });
      billingSavedCallback?.('Company Billing information saved successfully');
      changingCardloader?.()
    })
    .catch(err => {
      const errMsg = err.response.data.message ?? 'Company Billing information can not be saved successfully';
      billingSavedCallback?.(errMsg, true);
      dispatch(getError(err));
    }
    );
};
export const getBillingDetails = (): AppThunkAction => dispatch => {
  axios.get('/companies/billings',
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      dispatch({
        type: GET_BILLING_DATA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
export const getProfile = (): AppThunkAction => dispatch => {
  axios
    .get('/users/profile',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
    .then(res => {
      // console.log(res.data)
      if (res.data?.user?.first_name) {
        res.data.user.first_name = res.data.user.first_name?.trim();
      }
      localStorage.setItem('user', JSON.stringify(res.data.user));
      localStorage.setItem('company', JSON.stringify(res.data.company));
      dispatch({
        type: GET_PROFILE_DATA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};


export const slackValidationAndAuthResponseCode = (authCode: string, sucessfullyIntegrated: () => void): AppThunkAction => dispatch => {
  axios
    .post('/users/oath/slack',
      {
        code: authCode
      },
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
    .then(res => {
      sucessfullyIntegrated?.()
    })
    .catch(error => {
      dispatch(getError(error))
      sucessfullyIntegrated?.()
    })
} 
