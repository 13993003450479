import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef, ChangeEvent } from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../../Common/ModalHeader';
import AddLocationForm, { AddressChangeHandler } from '../Forms/AddLocationForm';
import AddNewDepartmentForm from '../Forms/AddNewDepartmentForm';
import AddNewAdminForm from '../Forms/AddNewAdminForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addNewDepartment } from '../../../Actions/companyActions';
import ButtonSpinner from '../../../Common/Spinners/ButtonSpinner';
import isEmpty from '../../../Utilities/is_empty';
import { hasValueInKey } from '../../../Utilities/functions';
import { emailPattern, phoneNumberPattern } from '../../../Utilities/validationPatterns';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { NewAddressData } from './AddNewLocationModal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    padding: '0'
  }
};

Modal.setAppElement('#root');

type Props = {
  isOpen: boolean,
  closeModal: () => void,
};

type NewDepartmentData = {
  name: string,
  mealBudget: number
}

type NewLocationData = {
  addressName: string,
  suitNumber: string,
  deliveryInstructions: string
}

type NewAdminData = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  deskNumber: string
}

export type NewDepartmentModalErrors = {
  name: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  deskNumber: string,
  addressLine: string
  timezone: string
}

const initialDepartmentData = {
  name: '',
  mealBudget: 0
};

const locationInitialData: NewLocationData = {
  addressName: '',
  suitNumber: '',
  deliveryInstructions: ''
};

const initialAdminData: NewAdminData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  deskNumber: ''
};

export const initialAddressData: NewAddressData = {
  addressLine: '',
  longitude: null,
  latitude: null,
  city: '',
  state: '',
  zipCode: null,
  streetNumber: '',
  street: '',
  timezone: '',
  formatted_address: ''
};

const initialErrors: NewDepartmentModalErrors = {
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  deskNumber: '',
  addressLine: '',
  timezone: ''
};

const AddNewDepartmentModal = forwardRef(({ isOpen, closeModal }: Props, ref) => {
  const dispatch:  any = useDispatch();
  const history = useHistory();
  // const { company_id } = useParams<{ company_id: any }>(); // extracted company ID from Local Storage
  const { buttonLoading } = useSelector((state: ReduxRootState) => state.loading);
  const { errors } = useSelector((state: ReduxRootState) => state.errors);
  const [errorAlert, setErrorAlert] = useState<boolean>(false)
  const [beforeSubmit, SetBeforeSubmit] = useState<boolean>(true)

  const [_errors, setErrors] = useState<NewDepartmentModalErrors>(initialErrors);
  const [firstShown, setFirstShown] = useState<boolean>(false);

  const [newDepartmentData, setDepartmentData] = useState<NewDepartmentData>(initialDepartmentData);
  const { name, mealBudget } = newDepartmentData;

  const [newAdminData, setNewAdminData] = useState<NewAdminData>(initialAdminData);
  const { firstName, lastName, email, phoneNumber, deskNumber } = newAdminData;

  const [locationData, setLocationData] = useState<NewLocationData>(locationInitialData);
  const { addressName, suitNumber, deliveryInstructions } = locationData;

  const [addressData, setAddressData] = useState<NewAddressData>(initialAddressData);
  const { addressLine, longitude, latitude, city, state, zipCode, streetNumber, street, timezone } = addressData;

  // Hook
  function usePrevious(value: any) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  const prevErrors = usePrevious(errors);

  useEffect(() => {
    let _errors = initialErrors;
    if (errors && errors !== prevErrors && !firstShown) {
      if (errors['company_admins_active.email'] && errors['company_admins_active.email'][0]) {
        console.log('Comes inside to change')
        _errors.email = `Email ${errors['company_admins_active.email'][0]}.`;
      }

      if (errors['addresses_active.address_line'] && errors['addresses_active.address_line'][0]) {
        _errors.addressLine = errors['addresses_active.address_line'][0];
      }

      setErrors(_errors);
      setFirstShown(true);
    }
  }, [errors, prevErrors, firstShown, _errors]);

  useImperativeHandle(
    ref,
    () => ({
      clearErrors: () => {
        setErrors(initialErrors);
        setDepartmentData(initialDepartmentData);
        setNewAdminData(initialAdminData);
        setLocationData(locationInitialData);
        setAddressData(initialAddressData);
      },
    }), [setErrors]);
  useEffect(() => {
    if (beforeSubmit) {
      setErrors(initialErrors)
    } else {
      validate()
    }
    // eslint-disable-next-line
  }, [errorAlert, beforeSubmit])
  const handleDepartmentData = (e: ChangeEvent<HTMLInputElement>): void => {
     setDepartmentData({ ...newDepartmentData, [e.target.name]: e.target.value })
    setErrorAlert(!errorAlert)
  };

  const handleNewAdminData = (e: ChangeEvent<HTMLInputElement>): void => {
    setErrorAlert(!errorAlert) 
    let value;
    if (e.target.name === 'phoneNumber' || e.target.name === 'deskNumber') {
      let temp = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      if (temp) {
        value = !temp[2] ? temp[1] : + temp[1] + '-' + temp[2] + (temp[3] ? '-' + temp[3] : '');
      }
    } else {
      value = e.target.value;
    }
    setNewAdminData({ ...newAdminData, [e.target.name]: value })
  };

  const handleAddress: AddressChangeHandler = (address) => {
    if (!isEmpty(address)) {
      setAddressData({ ...addressData, ...address });
      setErrors({
        ...errors,
        addressLine: "valid"
      })
    } else {
      setErrors({
        ...errors,
        addressLine: "error"
      })
    }
  };

  const handleTimezone = (timezone: string) => {
    if (!isEmpty(timezone)) {
      setAddressData({ ...addressData, timezone });
      setErrors({
        ...errors,
        timezone: "valid"
      })
    } else {
      setErrors({
        ...errors,
        timezone: "error"
      })
    }
  }

  const handleLocation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationData({ ...locationData, [e.target.name]: e.target.value });
  };


  const validate:any = () => {
    let _errors = initialErrors;

    if (isEmpty(name)) {
      _errors.name = 'Please enter department name.';
    } else {
            _errors.name = '';
    }

    if (isEmpty(firstName)) {
      _errors.firstName = 'Please enter first name.';
    } else {
            _errors.firstName = '';
    }

    if (isEmpty(lastName)) {
      _errors.lastName = 'Please enter last name.';
    } else {
            _errors.lastName = '';
    }

    if (isEmpty(email)) {
      _errors.email = 'Please enter email.';
    } else {
            // _errors.email = '';
    }

    if (isEmpty(deskNumber)) {
      _errors.deskNumber = 'Please enter phone number.';
    } else {
            _errors.deskNumber = 'Please enter desk number.';
    }

    if (isEmpty(phoneNumber)) {
      _errors.phoneNumber = 'Please enter phone number.';
    } else {
            _errors.phoneNumber = 'Please enter phone number.';
    }
    if (email && !emailPattern.test(email)) {
      _errors.email = 'Please enter a valid email';
    } else if (isEmpty(email)) {
      _errors.email = 'Email is required';
    } else {
      _errors.email = '';
    }

    if (phoneNumber && !phoneNumberPattern.test(phoneNumber)) {
      _errors.phoneNumber = 'Please use this format: XXX-XXX-XXXX';
    } else if (isEmpty(phoneNumber)) {
            _errors.phoneNumber = 'Please enter phone number.';
    }else{
      _errors.phoneNumber = '';
    }

    if (deskNumber && !phoneNumberPattern.test(deskNumber)) {
      _errors.deskNumber = 'Please use this format: XXX-XXX-XXXX';
    } else if (isEmpty(phoneNumber)) {
      _errors.deskNumber = 'Please enter phone number.';
    } else {
      _errors.deskNumber = '';
    }

    if (isEmpty(addressLine)) {
      _errors.addressLine = 'Please enter delivery address.';
    } else {
            _errors.addressLine = '';
    }

    if (isEmpty(timezone)) {
      _errors.timezone = 'Please enter timezone.';
    } else {
            _errors.timezone = '';
    }

    setErrors(_errors);
    return _errors;
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    SetBeforeSubmit(false)
    // JSON.parse(window.localStorage.getItem('company')); // Now accessing the Company Id from Local Storage
    let data:any =localStorage.getItem('company');
    let finaldata: any = JSON.parse(data)
    if (finaldata?.id) {
      const newAdminData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        desk_phone: deskNumber,
        time_zone: timezone
      };

      const newLocationData = {
        address_line: addressLine,
        address_name: addressName,
        suite_no: suitNumber,
        longitude: longitude,
        latitude: latitude,
        time_zone: timezone,
        delivery_instructions: deliveryInstructions,
        street_number: streetNumber,
        street: street,
        city: city,
        state: state,
        zip: zipCode
      };

      let adminDataArray = [];
      let locationArray = [];

      locationArray.push(newLocationData);
      adminDataArray.push(newAdminData);

      const formattedData = {
        company: {
          id: finaldata?.id,
          name: name,
          user_meal_budget: mealBudget,
          company_admins_active_attributes: adminDataArray,
          addresses_active_attributes: locationArray,
          billing_attributes: {
            card_number: '',
            expiry_month: '',
            expiry_year: '',
            cvc: '',
            delivery_fee: '0.0',
            disable_auto_invoice: '0',
            name: ''
          }
        }
      };

      let hasError = validate();
      if (!hasValueInKey(hasError)) {
        dispatch(addNewDepartment(formattedData, closeModal, history));
        setFirstShown(false);
      }
    }
    
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Add Location Modal'
    >
      <ModalHeader label='Add New Department' onModalClose={closeModal} />
      <div id='mydiv' className='modal-body'>
        <AddNewDepartmentForm
          handleChange={handleDepartmentData}
          errors={_errors}
        />
        <AddNewAdminForm
          handleChange={handleNewAdminData}
          showHeading
          adminData={newAdminData}
          modalType='create'
          errors={_errors}
        />
        <AddLocationForm
          showHeading
          handleChange={handleLocation}
          valid = {validate}
          handleAddress={handleAddress}
          handleTimezone={handleTimezone}
          timezone={timezone}
          errors={_errors}
        />
      </div>
      <div className='modal-footer'>
        <button
          type='submit'
          className='create-department ml-3 btn btn-primary normalBtn'
          onClick={handleSubmit}
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonSpinner /> : 'Create Department'}
        </button>
      </div>
    </Modal>
  );
});

export default AddNewDepartmentModal;