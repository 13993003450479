import { GET_COMPANY_USERS, LOADING, DELETE_USER, REACTIVATE, GET_ANNOUNCEMENTS, SET_DELIVERY_DATES, REMOVE_ALL_DELIVERY_DATES, GET_USER_TYPES, GET_FILTER_COUNT_DELETED, GET_FILTER_COUNT_INVITED, GET_FILTER_COUNT_ADMIN, GET_FILTER_COUNT_MANAGER, GET_FILTER_COUNT_UNSUBSIZIZED, RESET_STATE, GET_USER_REFERRAL_CODE, GET_FILTER_COUNT_REGULAR_USER } from '../Actions/types';
import { Users } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Users = {
  users: null,
  announcements: [],
  delivery_dates: {},
  loading: false,
  user_types: {},
  deleted_users_count: 0,
  invited_users_count: 0,
  admin_users_count: 0,
  managers_count: 0,
  unsubsidized_users_count: 0,
  user_referral_link: null,
  regular_users_count: 0,
}

const userReducer = (state: Users = initialState, action: any): Users => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_COMPANY_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      }

    case GET_USER_TYPES:
      return {
        ...state,
        user_types: action.payload,
        loading: false
      }
    //////
    case GET_FILTER_COUNT_DELETED:
      return {
        ...state,
        deleted_users_count: action.payload,
        loading: false
      }
    case GET_FILTER_COUNT_INVITED:
      return {
        ...state,
        invited_users_count: action.payload,
        loading: false
      }
    case GET_FILTER_COUNT_ADMIN:
      return {
        ...state,
        admin_users_count: action.payload,
        loading: false
      }
    case GET_FILTER_COUNT_MANAGER:
      return {
        ...state,
        managers_count: action.payload,
        loading: false
      }
    case GET_FILTER_COUNT_UNSUBSIZIZED:
      return {
        ...state,
        unsubsidized_users_count: action.payload,
        loading: false
      }
    case GET_FILTER_COUNT_REGULAR_USER:
      return {
        ...state,
        regular_users_count: action.payload,
        loading: false
      }
    ///////
    case DELETE_USER:
      let updatedUsersList = state.users ? {
        ...state.users,
        users: state.users?.users?.filter((user) => user.id !== action.payload)
      } : null;
      return {
        ...state,
        users: updatedUsersList
      }
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      }
    case SET_DELIVERY_DATES:
      return {
        ...state,
        delivery_dates: { ...state?.delivery_dates, ...action.payload }
      }
    case REMOVE_ALL_DELIVERY_DATES:
      return {
        ...state,
        delivery_dates: {}
      }
      case GET_USER_REFERRAL_CODE:
      return {
        ...state,
        user_referral_link: action.payload
      }
    case REACTIVATE:
      let reactivatedUsersList = state.users ? {
        ...state.users,
        users: state.users?.users?.map((user) => {
          if(user.id === action.payload) {
            return {...user, status : 'active', active : false};
          }
          return user
        })
      } : null;
      return {
        ...state,
        users: reactivatedUsersList
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default userReducer;