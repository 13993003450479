import i18n from "i18next";

export const shareMeetingPopUpTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Invite Guests": "Invite Guests",
      "Please use this form to invite other users to order…": "Please use this form to invite other users to order for this meeting. The users will not be required to login and will be able to order any items within the meeting budget.",
      "Emails to Invite": "Emails to Invite",
      "Email Address": "Email Address",
      "Copy Shareable Link": "Copy Shareable Link",
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Invite Guests": "شیئر میٹنگ",
      "Please use this form to invite other users to order…": "براہ کرم اس میٹنگ کا آرڈر دینے کے لئے دوسرے صارفین کو مدعو کرنے کے لئے یہ فارم استعمال کریں۔ صارفین کو لاگ ان کرنے کی ضرورت نہیں ہوگی اور وہ اجلاس کے بجٹ میں کسی بھی چیز کا آرڈر دے سکیں گے۔",
      "Emails to Invite": "مدعو کرنے کے لئے ای میلز",
      "Email Address": "ای میل اڈریس",
      "Copy Shareable Link": "اشتراک کے قابل لنک کاپی کریں",
    });
};