import { GET_USERS_LIST_FOR_CONFIRMATION, LOADING, RESET_STATE } from '../Actions/types';
import { ConfirmUsers } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: ConfirmUsers = {
  loading: false,
  confirm_users: null
}

const confirmReducer = (state: ConfirmUsers = initialState, action: any): ConfirmUsers => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_USERS_LIST_FOR_CONFIRMATION:
      return {
        ...state,
        confirm_users: action.payload,
        loading: false
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default confirmReducer;