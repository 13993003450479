enum ScreenWidths {
  ExtraExtraLargeDesktops = 2300,
  ExtraLargeDesktops = 1900,
  LargeDesktops = 1440,
  SmallDesktops = 1200,
  LargeLaptops = 1024,
  SmallLaptops = 992,
  Tablets = 768,
  LargeMobiles = 576,
  MediumMobiles = 425,
  SmallMobiles = 375,
}

export const getResponsiveNoOfSlides = () => {
  const screenWidth = window.document.body.clientWidth || 0;
  let noOfSlidesForRestaurants = 0, noOfSlidesForDietaries = 0, noOfSlidesForUserLiking = 0, noOfSlidesForFoodItems = 0, noOfSlidesForComboRestaurants = 0, noOfSlidesForMarketPlaceFilters = 0, noOfSlidesmostPopular = 0, noOfSlidesForMixMatchCombos = 0, restaurantCardCarousel = 0;
  if (screenWidth <= ScreenWidths.SmallMobiles) {
    noOfSlidesForRestaurants = 1.25; noOfSlidesForDietaries = 2.25; noOfSlidesForUserLiking = 2; noOfSlidesForFoodItems = 1.15; noOfSlidesForComboRestaurants = 1.5; noOfSlidesForMarketPlaceFilters = 1.5; noOfSlidesmostPopular = 1; noOfSlidesForMixMatchCombos = 1; restaurantCardCarousel = 1.2;
  } else if (screenWidth <= ScreenWidths.MediumMobiles) {
    noOfSlidesForRestaurants = 1.5; noOfSlidesForDietaries = 2.75; noOfSlidesForUserLiking = 2.75; noOfSlidesForFoodItems = 1.25; noOfSlidesForComboRestaurants = 1.25; noOfSlidesForMarketPlaceFilters = 2.5; noOfSlidesmostPopular = 1.2; noOfSlidesForMixMatchCombos = 1; restaurantCardCarousel = 1.2;
  } else if (screenWidth <= ScreenWidths.LargeMobiles) {
    noOfSlidesForRestaurants = 2.25; noOfSlidesForDietaries = 3.25; noOfSlidesForUserLiking = 3.25; noOfSlidesForFoodItems = 2.25; noOfSlidesForComboRestaurants = 1.5; noOfSlidesForMarketPlaceFilters = 3; noOfSlidesmostPopular = 2; noOfSlidesForMixMatchCombos = 1.25; restaurantCardCarousel = 1.2;
  } else if (screenWidth <= ScreenWidths.Tablets) {
    noOfSlidesForRestaurants = 3.25; noOfSlidesForDietaries = 4.25; noOfSlidesForUserLiking = 5; noOfSlidesForFoodItems = 2.25; noOfSlidesForComboRestaurants = 2.25; noOfSlidesForMarketPlaceFilters = 3; noOfSlidesmostPopular = 2; noOfSlidesForMixMatchCombos = 1.50; restaurantCardCarousel = 1.4;
  } else if (screenWidth <= ScreenWidths.SmallLaptops) {
    noOfSlidesForRestaurants = 4; noOfSlidesForDietaries = 5; noOfSlidesForUserLiking = 5; noOfSlidesForFoodItems = 3.25; noOfSlidesForComboRestaurants = 2.5; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 1.50; restaurantCardCarousel = 2;
  } else if (screenWidth <= ScreenWidths.LargeLaptops) {
    noOfSlidesForRestaurants = 4; noOfSlidesForDietaries = 6; noOfSlidesForUserLiking = 6; noOfSlidesForFoodItems = 3.25; noOfSlidesForComboRestaurants = 3.25; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 1.50; restaurantCardCarousel = 2;
  } else if (screenWidth <= ScreenWidths.SmallDesktops) {
    noOfSlidesForRestaurants = 4; noOfSlidesForDietaries = 7; noOfSlidesForUserLiking = 7; noOfSlidesForFoodItems = 4.25; noOfSlidesForComboRestaurants = 3.25; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 2.5; restaurantCardCarousel = 3.5;
  } else if (screenWidth <= ScreenWidths.LargeDesktops) {
    noOfSlidesForRestaurants = 5; noOfSlidesForDietaries = 8; noOfSlidesForUserLiking = 8; noOfSlidesForFoodItems = 4.25; noOfSlidesForComboRestaurants = 3.25; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 3; restaurantCardCarousel = 4;
  } else if (screenWidth <= ScreenWidths.ExtraLargeDesktops) {
    noOfSlidesForRestaurants = 6; noOfSlidesForDietaries = 9; noOfSlidesForUserLiking = 9; noOfSlidesForFoodItems = 5.25; noOfSlidesForComboRestaurants = 3.25; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 3.25; restaurantCardCarousel = 4;
  } else if (screenWidth <= ScreenWidths.ExtraExtraLargeDesktops) {
    noOfSlidesForRestaurants = 7; noOfSlidesForDietaries = 9; noOfSlidesForUserLiking = 9; noOfSlidesForFoodItems = 6.25; noOfSlidesForComboRestaurants = 3.25; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 4.25; restaurantCardCarousel = 4;
  } else {
    noOfSlidesForRestaurants = 8; noOfSlidesForDietaries = 10; noOfSlidesForUserLiking = 10; noOfSlidesForFoodItems = 7.25; noOfSlidesForComboRestaurants = 3.50; noOfSlidesmostPopular = 3; noOfSlidesForMixMatchCombos = 4.5; restaurantCardCarousel = 5;
  }
  return { noOfSlidesForRestaurants, noOfSlidesForDietaries, noOfSlidesForUserLiking, noOfSlidesForFoodItems, noOfSlidesForComboRestaurants, noOfSlidesForMarketPlaceFilters, noOfSlidesmostPopular, noOfSlidesForMixMatchCombos, restaurantCardCarousel }
}