import React, { FC, useEffect, useState } from 'react';
import FormHeading from '../Common/FormHeading';
import GooglePlacesAutocomplete from '../../../Common/GooglePlacesAutocomplete';
import InputField from '../../../Common/InputField';
import TimezonePicker from '../../../Common/TimeZonePicker/TimezonePicker';
import { DeliveryAddressPopupErrors } from '../../../Common/Popups/DeliveryAddressPopUp';
import { NewLocationModalErrors } from '../Modals/AddNewLocationModal';
import { NewDepartmentModalErrors } from '../Modals/AddNewDepartmentModal';
import isEmpty from '../../../Utilities/is_empty';

export type AddressChangeHandler = (address: {
  city?: string,
  state?: string,
  zipCode?: number | null,
  streetNumber?: string,
  street?: string,
  addressLine: string,
  longitude?: number | null,
  latitude?: number | null,
  timezone?: string,
  formatted_address?: string,
  focusDeliveryAddress?: boolean;
}) => void;

type Props = {
  showHeading: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valid?:any,
  handleAddress: AddressChangeHandler;
  handleTimezone: (timezone: string) => void;
  timezone: string;
  errors: DeliveryAddressPopupErrors | NewLocationModalErrors | NewDepartmentModalErrors;
  focusDeliveryAddress?: boolean;
};

const AddLocationForm: FC<Props> = ({
  showHeading,
  handleChange,
  handleAddress,
  valid,
  handleTimezone,
  timezone,
  errors,
  focusDeliveryAddress
}) => {
  const [addressError] = useState<any>(errors)

  useEffect(() => {
    if (!isEmpty(errors.addressLine) || !isEmpty(errors.timezone)) {
      //check valid is only passed from AddNewDepartmentModal
      valid?.();
    }
    // eslint-disable-next-line
  }, [addressError, errors])
  return (
    <>
      {showHeading && (
        <FormHeading
          heading='Delivery Address'
          description='Please add a delivery address below. You can add more delivery addresses later..'
        />
      )}
      <div id='main' className='container'>
        <form noValidate className='text-left'>
          <div className='form-row'>
            <div
              className={`col-sm-12 company-required-field ${errors.addressLine ? 'px-2 pt-3' : 'pt-3 px-2'
                }`}
            >
              <h6 className='orderform-section-name float-left '>Address</h6>
              <h6 className='orderform-section-required muted float-right pt-0'>
                {' '}
                Required
              </h6>
              <GooglePlacesAutocomplete
                handleAddress={handleAddress}
                errors={addressError}
               focusDeliveryAddress ={focusDeliveryAddress}
                classnames='dept-delivery-address'
              />
              {addressError?.addressLine && (
                <p className='invalid-feedback-password'>
                  {' '}
                  {addressError?.addressLine}
                </p>
              )}
            </div>
          </div>
          <div className='form-row'>
            <InputField
              label='Name This Location'
              type='text'
              name='addressName'
              onChange={handleChange}
              placeholder='e.g. main hospital building'
              errors={errors}
              classnames='dept-delivery-location-name'
            />
            <InputField
              label='Suite Number'
              type='text'
              name='suitNumber'
              onChange={handleChange}
              placeholder='Suite Number'
              errors={errors}
              classnames='dept-delivery-suite-number'
            />
          </div>
          <div className='form-row'>
            <div className='col-sm-12 company-required-field pt-3 px-2'>
              <h6 className='orderform-section-name float-left'>Timezone</h6>
              <h6 className='orderform-section-required muted float-right pt-0'>
                Required
              </h6>
              <TimezonePicker
                absolute={false}
                placeholder='Select Timezone'
                onChange={handleTimezone}
                value={timezone}
                classnames='dept-delivery-timezone'
                isInvalid={errors?.timezone}
              />
              {addressError?.timezone && (
                <p className='invalid-feedback-password'>
                  {' '}
                  {addressError?.timezone}
                </p>
              )}
            </div>
          </div>
          <div className='form-row'>
            <InputField
              sm={12}
              label='Delivery Instructions'
              type='text'
              name='deliveryInstructions'
              placeholder='e.g. Call receptionist upon arrival, wait in the lobby, setup in conference room, etc'
              onChange={handleChange}
              classnames='dept-delivery-instructions'
              errors={errors}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddLocationForm;