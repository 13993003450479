
//Common Function for showing Bootstrap Toast Notifications
import { Store } from 'react-notifications-component';
export const showToastNotification = (message, status, error = false, containerPosition) => {
  if (document.getElementById('show-notification')) {
    document.getElementById('show-notification').innerHTML = `<div aria-live='assertive' aria-atomic='true'>
    <div class='toast-bg-color' style='position: absolute; top: 0; right: 0; width: 100%;'>
    <div class='toast' role='alert' aria-live='assertive' aria-atomic='true' data-autohide='false'>
    <div class='toast-header'><strong class='mr-auto'>${status}</strong>
    <button type='button' class='ml-2 mb-1 close' data-dismiss='toast' aria-label='Close' onClick='window.hideToast();'>
    <span aria-hidden='true'>×</span></button></div><div class='toast-body text-left'>${message}</div>
    </div></div>`;
  }
  if (!!!document.documentMode) {
    window.showToast(message, status, Store, error, containerPosition);
  }
}
