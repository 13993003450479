import React, { FC } from 'react';

interface Props {
  heading: string,
  description: string
};

const FormHeading: FC<Props> = ({ heading, description }) => {
  return (
    <div className='form-row mt-4'>
      <div className='col-11 m-auto text-center'>
        <h4 className='text-center'>{heading}</h4>
        <p className='text-muted'>
          {description}
        </p>
      </div>
    </div>
  );
};

export default FormHeading;
