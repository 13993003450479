import { AddressDataType } from "../Interfaces/PayloadsAndResponses/Address";

export const modifyAddressInUrl = (fetchedAddress: AddressDataType, fromChowmill?: boolean) => {

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const address_found = params.get('address')
  // params.delete('address');
  url.pathname = '/'
  if (fromChowmill) {
    params.delete('placeId')
  }
  if (!!address_found) {
    params.set('address', `${fetchedAddress?.formatted_address ?? fetchedAddress?.address_line}`);
  } else {
    params.append('address', `${fetchedAddress?.formatted_address ?? fetchedAddress?.address_line}`);
  }
  url.search = params?.toString();

  window.history.replaceState({}, '', url?.toString());

}