import React from 'react';

const Spinner = ({ spinnerSizeClass }: { spinnerSizeClass?: string }) => {
  return (
    <div className='d-flex justify-content-center'>
      <div className={`spinner-border ${spinnerSizeClass || ''}`} role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
