import { GET_ERRORS, CLEAR_ERRORS, RESET_STATE } from '../Actions/types';
import { Errors } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Errors = {
  errors: null
};

const errorReducer = (state: Errors = initialState, action: any): Errors => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default errorReducer;