import { CART_ITEMS, CART_ITEMS_UPDATE_ALL, GET_DELIVERY_FEE, CART_DETAILS, CART_SUMMARY, EMPTY_CART, CART_SUMMARY_TIP, CART_SUMMARY_TIP_PERCENTAGE, CART_DETAILS_ITEM_COUNT, CART_ADDRESS_IDS_LOCAL, SAVE_CART_ORDER_PAYLOAD, RESET_STATE } from "../Actions/types";
import { Cart } from "../Interfaces/ReduxInterface/ReduxRootState";
import { CartDetails, CartSummary, PlaceCartOrderPayload } from "../Interfaces/PayloadsAndResponses/Cart";

const initialState: Cart = {
  items: [],
  summary: {} as CartSummary,
  details: {} as CartDetails,
  address_ids: [],
  cart_order_payload: {} as PlaceCartOrderPayload
  // cart_at_login: {} as CartAtLogin,
};

const cartReducer = (state: Cart = initialState, action: any): Cart => {
  switch (action.type) {
    case CART_ITEMS:
      return {
        ...state,
        items: state?.items && state.items.length > 0 ? [...state.items, action.payload] : [action.payload],
      };
    case CART_ITEMS_UPDATE_ALL:
      return {
        ...state,
        items: action.payload,
      };
    case GET_DELIVERY_FEE:
      return {
        ...state,
        summary: { ...state.summary, deliveryFee: action.payload }
      };
    case CART_DETAILS:
      return {
        ...state,
        details: state?.details.itemCount >=1 ? 
          { ...action.payload, itemCount: state.details.itemCount + action.payload.itemCount }
        :
        action.payload
      }
    case CART_DETAILS_ITEM_COUNT:
      return {
        ...state,
        details: { ...state.details, itemCount: action.payload}
      }
    case CART_ADDRESS_IDS_LOCAL:
      return {
        ...state,
        address_ids: action.payload,
      }
    case CART_SUMMARY:
      return {
        ...state,
        // summary: {
        //   ...state.summary,
        //   itemsSubtotal: action.payload.itemsSubtotal,
        //   salesTax: action.payload.salesTax,
        //   cardProcessingFee: action.payload.cardProcessingFee,
        //   total: action.payload.total
        // },
        summary: action.payload
      }
    case CART_SUMMARY_TIP:
      return {
        ...state,
        summary: { ...state.summary, driverTip: action.payload }
      };
    case CART_SUMMARY_TIP_PERCENTAGE:
      return {
        ...state,
        summary: { ...state.summary, driverTipPercent: action.payload }
      };
    // case CART_AT_SERVER:
    //   return {
    //     ...state,
    //     cart_at_login: action.payload
    //   };
    case EMPTY_CART:
      return {
        ...state,
        address_ids: [],
        items: [],
        summary: {} as CartSummary,
        details: {} as CartDetails,
        cart_order_payload: {} as PlaceCartOrderPayload
      }
    case SAVE_CART_ORDER_PAYLOAD:
      return {
        ...state,
        cart_order_payload: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
