import { format, utcToZonedTime } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { getTimeZoneByLocation } from '../Actions/companyActions';
import { AddressChangeHandlerType } from '../Interfaces/PayloadsAndResponses/Address';
// import { AddressesActiveAttributes } from '../Interfaces/PayloadsAndResponses/Company';
import { getloggedInUser } from './getloggedInUser';
import isEmpty from './is_empty';
import dayjs from 'dayjs';
// import { User } from '../Interfaces/PayloadsAndResponses/Auth';

//A function to check if an object has value in any of its key.
export const hasValueInKey = (obj: object) => {
  let hasValue = false;
  Object.values(obj).forEach((item) => {
    if (item) {
      hasValue = true;
    }
    return hasValue;
  });
  return hasValue;
};

export const getZonedTime = (dateToBeConverted: string | Date, formatToBeApplied: string, timeZone?: string, insertAt?: 'insertAtInFormat') => {
  if (window.navigator.userAgent.match(/MSIE|Trident/) === null) {
    const date = new Date(dateToBeConverted);
    const zonedDate = utcToZonedTime(date, timeZone ? timeZone : getUserOrLocalTimeZone().userOrLocalTimeZone);
    // The time zone name is generated by the Intl API which works best when a locale is also provided
    let formattedDateTime = format(zonedDate, formatToBeApplied, { timeZone, locale: enGB });

    let splittedDateTime = formattedDateTime.split(',');
    // let zoneAbbr = formattedDateTime.split(',')?.[1];
    if (splittedDateTime[splittedDateTime.length - 1]) {
      if (insertAt) {
        return `${splittedDateTime[0]} at ${splittedDateTime[1]} ${splittedDateTime[splittedDateTime.length - 1]?.match(/[A-Z]/g)?.join('')}`;
      } else {
        return `${splittedDateTime[0]} ${splittedDateTime[splittedDateTime.length - 1]?.match(/[A-Z]/g)?.join('')}`;
      }
    }
  }
};
export const utcTotimezoneDate = (dateToBeUsed: Date | string, providedTimezone: string) => {
  const date = new Date(dateToBeUsed);
  const zonedDate = utcToZonedTime(date, providedTimezone ? providedTimezone : getUserOrLocalTimeZone().userOrLocalTimeZone);

  return zonedDate
}

export const getDateWithTimeZone = (dateToBeFormatted: string | Date, timeZone?: string) => {
  // Get the user's or local time zone if not specified
  const userOrLocalTimeZone = timeZone ? timeZone : getUserOrLocalTimeZone().userOrLocalTimeZone;

  try {
    // Format the date with the provided time zone
    return format(new Date(dateToBeFormatted), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: userOrLocalTimeZone
    });
  } catch (error) {
    // If the provided time zone is invalid, fallback to a default time zone
    // console.error('Invalid time zone:', userOrLocalTimeZone);
    return format(new Date(dateToBeFormatted), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: 'UTC' // Fallback to UTC time zone
    });
  }
};


export const getUserOrLocalTimeZone = () => {
  const { loggedInUser } = getloggedInUser();
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  const userOrLocalTimeZone = !isEmpty(loggedInUser) ? loggedInUser?.time_zone : localAddress?.time_zone;

  return { localAddress, userOrLocalTimeZone }
}

export const getAddressDataPlaceId = (address: any) => {
  let city: string, state: string, street_number: string, zip: number, street: string;
  geocodeByPlaceId(address)
    .then(async results => {
      results && results[0] && results[0].address_components.map((item) => {
        item.types?.forEach(type => {
          if (type === 'locality') {
            city = item.long_name;
          }
          if (type === 'administrative_area_level_1') {
            state = item.short_name;
          }
          if (type === 'street_number') {
            street_number = item.short_name;
          }
          if (type === 'route') {
            street = item.short_name;
          }
          if (type === 'postal_code') {
            zip = +item.short_name;
          }
        })
        return true;
      });

      let latLang = await getLatLng(results[0]);
      let response = await getTimeZoneByLocation(latLang.lat, latLang.lng);
      let time_zone = response?.timeZoneId;
      const PlaceIdData = {
        placeId: address
      }
      localStorage.setItem("address-placeId", JSON.stringify(PlaceIdData));

      let formatted_address = `${street_number ? `${street_number} ` : ''}${street ? `${street}` : ''}${(street || street_number) && city ? ',' : ''}${city ? ` ${city}` : ''}`;
      saveAddress({
        city,
        state,
        zip,
        street_number,
        street,
        address_line: results[0]?.formatted_address,
        longitude: latLang.lng,
        latitude: latLang.lat,
        time_zone,
        formatted_address,
        // address
      });
      return time_zone;
    })
    .then(time_zone => {
      // let zonedTimeFtn = await getZonedTime(new Date(), 'EEE. MMM dd h:mma, zzzz', timezone?? '')
      // let zonedDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: time_zone }))
      // let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') // date for marketplace with threshold: 2pm
      // const dateForMarketplace = dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime)) ? 2 : 1), 'day').format('M-D-YYYY');
      // const hasAddress = JSON.parse(localStorage.getItem('address') || '{}');
      // if (user?.phone_number) {
      //   history.push(`/menu/${dateForMarketplace}`)
      // } else if (hasAddress.latitude) {
      //   history.push(`/home/${dateForMarketplace}`)
      // }
    })
    .catch(error => console.error('Error', error));
}


const saveAddress: AddressChangeHandlerType = (address) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (user?.phone_number) {
    // const formattedAddressData: AddressesActiveAttributes = {
    //   address_line: address.address_line,
    //   address_name: address.address_name,
    //   suite_no: address.suite_no ?? '',
    //   longitude: address.longitude ?? 0,
    //   latitude: address.latitude ?? 0,
    //   time_zone: address.time_zone ?? '',
    //   delivery_instructions: address.delivery_instructions ?? '',
    //   street_number: address.street_number ?? '',
    //   street: address.street ?? '',
    //   city: address.city ?? '',
    //   state: address.state ?? '',
    //   zip: address.zip ?? 0
    // }
    // const formattedLocationData = {
    //   company: {
    //     addresses_active_attributes: [formattedAddressData]
    //   }
    // };

    // dispatch(addNewLocationData(+loggedInCompanyId, formattedLocationData));
  } else if (!isEmpty(address?.latitude)) {
    localStorage.setItem("address", JSON.stringify(address));
  }
  // return formattedAddressData
};
export const formatDate = (date: string | Date): string => {
  let parsedDate;

  // If the input is a string, attempt to parse it using Day.js
  if (typeof date === 'string') {
    // Attempt to parse the date string using known formats
    parsedDate = dayjs(date, ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'MMMM DD, YYYY', 'MM/DD/YYYY', 'ddd, DD MMM YYYY HH:mm:ss Z', 'M-DD-YYYY', 'M-D-YYYY'], true);
  } else {
    // If the input is already a Date object, use it directly
    parsedDate = dayjs(date);
  }
  // Check if the parsed date is valid, if not return an empty string
  if (!parsedDate.isValid()) {
    return '';
  }
  // Return the formatted date in 'YYYY-MM-DD' format
  return parsedDate.format('YYYY-MM-DD');
}

// validateAddress
export const addressValidationFromGoogleValidationAPI = async (AddressToBeTested: string) => {
if(!isEmpty(AddressToBeTested)){
  try {
    const response = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`, {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      //   // Add your API key here
      //   'Authorization': `Bearer ${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
      // },
      body: JSON.stringify({
        address: {
          "addressLines": [AddressToBeTested]
        },
        "enableUspsCass": true,
        "languageOptions": {}
      })
    });

    const data = await response.json();
    // console.log('Data For Validation',data);
    const isValidAddress = data.result.verdict

    // handleVerdict(data.result.verdict);

    return isValidAddress

    // setValidatedAddress(data.result);
  } catch (error) {
    console.error('Error validating address:', error);
    return false
  }
}

};