import { combineReducers } from 'redux';
import inviteReducer from './inviteReducer';
import cuisineReducer from './cuisineReducer';
import addressReducer from './addressReducer';
import profileReducer from './profileReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import confirmReducer from './confirmReducer';
import userReducer from './userReducer';
import menuReducer from './menuReducer';
import orderReducer from './orderReducer';
import scheduleDeliveryReducer from './scheduleDeliveryReducer';
import emailReducer from './emailReducer';
import shareMeetingReducer from './shareMeetingReducer';
import companyReducer from './companyReducer';
import billingReducer from './billingReducer';
import i18nReducer from './i18nReducer';
import marketplaceReducer from './marketplaceReducer'
import reportsReducer from './reportsReducer';
import loadingReducer from './loadingReducer';
import cartReducer from './cartReducer';
import serverCartReducer from './serverCartReducer';
import cartPendingConfirmationReducer from './cartPendingConfirmationReducer'

let allReducers = {
    addresses: addressReducer,
    auth: authReducer,
    company_data_reducer: companyReducer,
    confirm_users: confirmReducer,
    cuisine_list: cuisineReducer,
    email: emailReducer,
    errors: errorReducer,
    i18n: i18nReducer,
    invite_status: inviteReducer,
    loading: loadingReducer,
    marketplaceSuggestions: marketplaceReducer,
    menu: menuReducer,
    order: orderReducer,
    payment_saved: billingReducer,
    profile: profileReducer,
    reports: reportsReducer,
    schedules_details: scheduleDeliveryReducer,
    share_meeting: shareMeetingReducer,
    users: userReducer,
    cart: cartReducer,
    server_cart: serverCartReducer,
    cart_order_pending_confirmation: cartPendingConfirmationReducer

};

export default combineReducers(allReducers);