import { CREATE_NEW_ORDER, UPDATE_SINGLE_ORDER, LOADING_ORDER, LOADING_ORDER_CANCEL, GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS, GET_DELIVERY_ORDERS_DETAILS, GET_SINGLE_ORDER, GET_FOODITEM_FOR_RATING, GET_RESTAURANT_FOR_RATING, STOP_LOADING_ORDER, GET_LOADING_OFF, CLONE_ORDERS, STOP_LOADING_ORDER_CANCEL, EMPTY_ORDER_RATING_DATA, EMPTY_DELIVERY_ORDERS_DETAILS, SET_INVOICE_DOWNLOADING_LOADING, STOP_INVOICE_DOWNLOADING_LOADING, SET_CONFIRM_ORDER_POPUP_STATE, GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS, RESET_STATE } from '../Actions/types';
import { OrderStatus } from '../Interfaces/PayloadsAndResponses/Orders';
import { OrderDetails } from '../Interfaces/ReduxInterface/ReduxRootState';

export const cancel_order_loading_key_prefix = 'order-';
export const invoice_downloading_loading_key_prefix = 'delivery-invoice-';

const initialState: OrderDetails = {
  loading: false,
  new_order_request_status: null,
  update_single_order_request: null,
  company_deliveries_for_orders: null,
  delivery_orders_details: null,
  self_signup_delivery_orders_details: null,
  view_single_order: null,
  rating_fooditem: null,
  rating_restaurant: null,
  meetings_loading: false,
  clone_orders: null,
  cancel_orders_loading: {},
  invoices_download_loading: {},
  error: '',
  orderCreated: false,
  isOrderConfirmModalOpen: true
}

const orderReducer = (state: OrderDetails = initialState, action: any): OrderDetails => {
  switch (action.type) {
    case LOADING_ORDER:
      return {
        ...state,
        [action.payload]: true,
      }
    case CREATE_NEW_ORDER:
      return {
        ...state,
        new_order_request_status: action.payload,
        loading: false,
        orderCreated: true
      }
    case SET_CONFIRM_ORDER_POPUP_STATE:
      return {
        ...state,
        isOrderConfirmModalOpen: action.payload
      }
    case UPDATE_SINGLE_ORDER:
      return {
        ...state,
        update_single_order_request: action.payload,
        loading: false
      }
    case GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS:
      return {
        ...state,
        company_deliveries_for_orders: action.payload,
        loading: false,
        meetings_loading: false
      }
    case GET_DELIVERY_ORDERS_DETAILS:
      return {
        ...state,
        delivery_orders_details: action.payload,
        loading: false
      }
    case GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS:
      return {
        ...state,
        self_signup_delivery_orders_details: action.payload,
        loading: false
      }
    case LOADING_ORDER_CANCEL: {
      return {
        ...state,
        cancel_orders_loading: {
          ...state?.cancel_orders_loading, [cancel_order_loading_key_prefix + action.payload]: true
        }
      }
    }
    case STOP_LOADING_ORDER_CANCEL: {
      const { delivery_orders_details, cancel_orders_loading } = state
      return {
        ...state,
        delivery_orders_details: delivery_orders_details ? {
          ...delivery_orders_details,
          order_summary: { ...delivery_orders_details.order_summary, cancelled_total_quantity: delivery_orders_details.order_summary.cancelled_total_quantity + 1 },
          orders: (delivery_orders_details?.orders || []).map(order => order.id === action.payload ? { ...order, status: OrderStatus.Cancelled } : { ...order })
        } : null,
        cancel_orders_loading: {
          ...cancel_orders_loading, [cancel_order_loading_key_prefix + action.payload]: false
        }
      }
    }
    case GET_SINGLE_ORDER:
      return {
        ...state,
        view_single_order: action.payload,
        loading: false
      }
    case GET_FOODITEM_FOR_RATING:
      return {
        ...state,
        rating_fooditem: action.payload,
        loading: false
      }
    case GET_RESTAURANT_FOR_RATING:
      return {
        ...state,
        rating_restaurant: action.payload,
        loading: false
      }
    case STOP_LOADING_ORDER:
      return {
        ...state,
        loading: false,
        meetings_loading: false
      }
    case GET_LOADING_OFF:
      return {
        ...state,
        loading: false,
        error: "Order failed!"
      }
    case CLONE_ORDERS:
      return {
        ...state,
        clone_orders: action.payload,
        loading: false
      }
    case EMPTY_ORDER_RATING_DATA:
      return {
        ...state,
        rating_fooditem: null,
        rating_restaurant: null,
      }
    case EMPTY_DELIVERY_ORDERS_DETAILS:
      return {
        ...state,
        delivery_orders_details: null
      }
    case SET_INVOICE_DOWNLOADING_LOADING:
      return {
        ...state,
        invoices_download_loading: {
          ...state?.invoices_download_loading, [invoice_downloading_loading_key_prefix + action.payload]: true
        }
      }
    case STOP_INVOICE_DOWNLOADING_LOADING:
      return {
        ...state,
        invoices_download_loading: {
          ...state.invoices_download_loading, [invoice_downloading_loading_key_prefix + action.payload]: false
        }
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default orderReducer;