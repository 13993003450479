// import React, { Component } from 'react';
// import './footer.css';
// class Footer extends Component {

//   render() {
//     return (
//       <div className="mt-4 pt-4 text-center border-top pb-3">
//         <div className="col-12">
//           <p className="muted mb-0">
//             <img alt="Chowmill" src="/imgs/chowmill_icon.svg" className="logo-chowmill" />
//             <br />
//             Thank you for using Chowmill!<br />We're here to help: <a href="mailto:support@chowmill.com">support@chowmill.com</a>.</p>
//         </div>
//       </div>
//     )
//   }
// }

// export default Footer;
import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="mt-4 pt-4 text-center border-top pb-3">
      <div className="col-12">
        <p className="muted mb-0">
          <img alt="Chowmill" src="/imgs/chowmill_icon.svg" className="logo-chowmill" />
          <br />
          Thank you for using Chowmill!<br />We're here to help: <a href="mailto:support@chowmill.com">support@chowmill.com</a>.
        </p>
      </div>
    </div>
  );
};

export default Footer;