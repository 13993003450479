import { TRANSLATE_I18N } from '../Actions/types';
import { i18n } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: i18n = {
  i18n: null
};

// Removed the state reseting action case for translation

const i18nReducer = (state: i18n = initialState, action: any): i18n => {
  switch (action.type) {
    case TRANSLATE_I18N:
      return {
        ...state,
        i18n: action.payload
      };
    default:
      return state;
  }
};

export default i18nReducer;