import axios, { AxiosError } from 'axios';
import { MAKE_NEW_SCHEDULE_REQUEST, EMPTY_NEW_SCHEDULE_REQUEST_DATA, GET_SINGLE_SCHEDULE, LOADING, CANCEL_DELIVERY, UPDATE_SCHEDULE_REQUEST, SET_DELIVERY_DATES, REMOVE_ALL_DELIVERY_DATES, GET_SINGLE_SCHEDULE_LOADING, CLONE_ORDERS, SET_MENU_TAB_MEETING } from './types';
import { getError } from './errorActions';
import { getShowMenuTab, setShowMenuTabLoading } from './menuActions';
import dayjs from 'dayjs';
import isEmpty from '../Utilities/is_empty';
import { showToastNotification } from '../Utilities/showToastNotification';
import { getloggedInUser } from '../Utilities/getloggedInUser';
import { DeliveryRequestPayload, SchedualeRequest } from '../Interfaces/PayloadsAndResponses/Schedules';
import { AppThunkAction } from '../store';
import { CloneOrdersRunningMenu } from '../Interfaces/PayloadsAndResponses/Orders';

export const makeDeliveryRequest = (requestData: DeliveryRequestPayload, updataeDeliveryDates = false, cloneOrdersIds?: number[], createNewScheduleCallback?: () => void, createSchedular?: boolean): AppThunkAction => dispatch => {
  // Empty New ScheduleRequestData so that Popup Componenet Recieved Latest Data
  dispatch(emptyNewScheduleRequestData());
  dispatch(setShowMenuTabLoading());

  const headers = JSON.parse(localStorage.getItem('headers') || '{}')
  axios
    .post('/schedules', requestData,
      {
        headers
      })
    .then(async (res) => {
      const newSchedule = res.data as SchedualeRequest;
      const { delivery_at, id, slug, runningmenu_name, status } = newSchedule.schedule || {};
      const { loggedInUser: user, loggedInUserCompany } = getloggedInUser();
      if (!isEmpty(cloneOrdersIds)) {
        const { data } = await axios.post(`/orders/clone`, { runningmenu_id: newSchedule.schedule.id, order_ids: cloneOrdersIds }, { headers });
        dispatch({
          type: CLONE_ORDERS,
          payload: data
        });
        showToastNotification(`${runningmenu_name || user?.first_name + `'s New Delivery`} items have been cloned.`, 'Success!')
      }
      if (status !== 'pending') {
        dispatch({
          type: SET_MENU_TAB_MEETING,
          payload: { delivery_at, id, slug }
        });
      } else if (status === 'pending') {
        dispatch({
          type: SET_MENU_TAB_MEETING,
          payload: null
        });
      }

      // const newDeliveryDate = dayjs(res?.data?.schedule?.delivery_at)?.format('D-M-YYYY')
      // It is called from DeliveryDatesCalendar Component Itself Upon Loading
      // updataeDeliveryDates && dispatch(getDeliveryDates(newDeliveryDate || '',  newDeliveryDate || ''));
      if (status !== 'pending') {
        dispatch({
          type: MAKE_NEW_SCHEDULE_REQUEST,
          payload: newSchedule
        });
      } else if (status === 'pending') {
        dispatch({
          type: MAKE_NEW_SCHEDULE_REQUEST,
          payload: null
        });
      }
      createNewScheduleCallback && createNewScheduleCallback();
      if (!loggedInUserCompany.enable_marketplace) {
        showToastNotification(`Request has been sent for Approval!`, 'Success!')

      }

      if (createSchedular && status === 'approved') {
        // Redirect to order pages if meeting is created from marketplace and status is 'approved'
        window.location?.replace('/menu/')
      } else if (createSchedular && status !== 'approved') {
        // If meeting is not approved, determine the redirect based on time
        // const currentHour = dayjs().hour();
        // const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');
        // window.location.href = `/menu/${dateForMarketplace}`
        window.location.href = `/menu/`
      }
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateScheduleRequest = (requestedScheduleSlug: string, requestData: DeliveryRequestPayload, updateScheduleCallback?: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/schedules/${requestedScheduleSlug}`, requestData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if (res?.data?.runningmenu) { // Will be True if Meeting is updated for Clone Order Puropose and Custom Field was Required for Schedule
        dispatch(updateSingleScheduleAfterCloneOrders(res?.data?.runningmenu as CloneOrdersRunningMenu))
      } else {
        const updatedSchedule = res.data as SchedualeRequest;
        const { delivery_at, id, slug } = updatedSchedule.schedule || {};
        const newDeliveryDate = dayjs(delivery_at)?.format('D-M-YYYY')
        dispatch({
          type: SET_MENU_TAB_MEETING,
          payload: { delivery_at, id, slug }
        });
        dispatch(getDeliveryDates(newDeliveryDate || '', newDeliveryDate || ''));
        dispatch({
          type: UPDATE_SCHEDULE_REQUEST,
          payload: updatedSchedule,
        });
      }
      updateScheduleCallback && updateScheduleCallback();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getSingleSchedule = (scheduleSlug: string | undefined, setLoading = true, token = ''): AppThunkAction => dispatch => {
  setLoading && dispatch({
    type: GET_SINGLE_SCHEDULE_LOADING
  })
  axios
    .get(`${!token ? `/schedules/${scheduleSlug}` : `/schedules/${undefined}?token=${token}`}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_SINGLE_SCHEDULE,
        payload: res.data
      })
    )
    .catch((err: AxiosError) => {
      if (err.response?.status && [406, 404].includes(err.response?.status)) {

        const currentHour = dayjs().hour();
        const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');
        window.location.href = `/menu/${dateForMarketplace}`
      } else {
        dispatch(getError(err));
      }
    });
};

export const getDeliveryDates = (from = '', to = ''): AppThunkAction => dispatch => {
  axios
    .get((from && to) ? `/schedules/delivery_dates?from=${from}&to=${to}` : `/schedules/delivery_dates`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: SET_DELIVERY_DATES,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const cancelDelivery = (deliverySlug: string, reason: string, meetingCanceledCallback: () => void): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .delete(`/schedules/${deliverySlug}/cancel/?cancel_reason=${reason}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch(getShowMenuTab());
      meetingCanceledCallback && meetingCanceledCallback();
      dispatch({
        type: REMOVE_ALL_DELIVERY_DATES,
      })
      dispatch({
        type: CANCEL_DELIVERY,
        payload: res.data
      })
    })
    .catch(err => err);
};

export const updateSingleScheduleAfterCloneOrders = (updatedData: CloneOrdersRunningMenu): AppThunkAction => (dispatch, getState) => {
  const { schedules_details: { single_schedule }, auth: { loggedInUser } } = getState() || {};
  const clonedOrdersSchedule = single_schedule?.schedule;
  const updated_single_schedule = {
    ...single_schedule,
    schedule: {
      ...clonedOrdersSchedule,
      clone_orders: null,
      ...updatedData,
    }
  }
  dispatch({
    type: GET_SINGLE_SCHEDULE,
    payload: updated_single_schedule
  });
  showToastNotification(`${clonedOrdersSchedule?.runningmenu_name || loggedInUser?.first_name + `'s New Delivery`} items have been cloned.`, 'Success!');
}

// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}

export const emptyNewScheduleRequestData = () => {
  return {
    type: EMPTY_NEW_SCHEDULE_REQUEST_DATA
  };
}


