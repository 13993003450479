import { GET_MENU, SET_MENU_TAB_MEETING, GET_MENU_OF_RESTAURANT, GET_MEETINGS_LOADING, GET_MEETINGS_FOR_MAIN_DASHBOARD, GET_SCHEDULE, MENU_REDUCER_LOADING, SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, GET_DELIVERY_RECENT_ORDERS, GET_DELIVERY_RECENT_ORDERS_LOADING, GET_MENU_TAB_MEETING_LOADING, GET_DELIVERY_DYNAMIC_SECTIONS, GET_DELIVERY_DYNAMIC_SECTIONS_LOADING, RESET_STATE, GET_MENU_FILTERS } from '../Actions/types';
import { DashboardDeliveryTypes, FoodItem, GetRecentOrdersByMeetingMenu, RecentOrder } from '../Interfaces/PayloadsAndResponses/Menu'
import { Menu } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Menu = {
  loading: false,
  filter_purpose_loading: false,
  schedule: null,
  menu: null,
  menu_filters: null,
  delivery_recent_orders: null,
  delivery_recent_orders_loading: false,
  delivery_dynamic_sections: null,
  delivery_dynamic_sections_loading: false,
  menu_of_restaurant: null,
  admin_approved_deliveries: null,
  admin_approved_deliveries_loading: false,
  admin_delivery_requests: null,
  admin_delivery_requests_loading: false,
  user_location_deliveries: null,
  user_location_deliveries_loading: false,
  user_other_locations_deliveries: null,
  user_other_locations_deliveries_loading: false,
  menu_tab_meeting: undefined,
  menu_tab_meeting_loading: false,
  isOpenPopupOnMenuDetailPage: false,
  isFilterApplied: false,
}

const menuReducer = (state: Menu = initialState, action: any): Menu => {
  switch (action.type) {
    case MENU_REDUCER_LOADING:
      return {
        ...state,
        loading: true,
        filter_purpose_loading: action.payload?.filter_purpose_loading,
      }
    case GET_DELIVERY_RECENT_ORDERS_LOADING:
      return {
        ...state,
        delivery_recent_orders_loading: true,
      }
    case GET_MEETINGS_LOADING:
      return {
        ...state,
        [action?.payload]: true
      }
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
        loading: false
      }
    case GET_MENU:
      return {
        ...state,
        menu: action.payload,
        loading: false,
        filter_purpose_loading: false,
      }
    case GET_MENU_FILTERS:
      return {
        ...state,
        menu_filters: action.payload,
        loading: false,
        filter_purpose_loading: false,
      }
    case GET_DELIVERY_DYNAMIC_SECTIONS_LOADING:
      return {
        ...state,
        delivery_dynamic_sections_loading: true,
      }
    case GET_DELIVERY_DYNAMIC_SECTIONS:
      return {
        ...state,
        delivery_dynamic_sections: action.payload,
        delivery_dynamic_sections_loading: false,
      }
    case GET_DELIVERY_RECENT_ORDERS:
      const recent_orders = action.payload as GetRecentOrdersByMeetingMenu ?? null;
      const recent_orders_fooditems: FoodItem[] = [], recent_orders_data: RecentOrder[] = [];
      (recent_orders?.orders ?? []).forEach(({ fooditem, ...order }) => {
        recent_orders_fooditems.push(fooditem);
        recent_orders_data.push(order);
      });
      return {
        ...state,
        delivery_recent_orders: { recent_orders_fooditems, recent_orders_data },
        delivery_recent_orders_loading: false,
      }
    case GET_MENU_OF_RESTAURANT:
      return {
        ...state,
        menu_of_restaurant: action.payload,
        loading: false,
        filter_purpose_loading: false,
        isFilterApplied: action.filterApplied
      }
    case GET_MEETINGS_FOR_MAIN_DASHBOARD:
      const deliveryType = action.deliveriesType as DashboardDeliveryTypes;
      const perPageMeetings = (deliveryType !== 'admin_delivery_requests' && state[deliveryType]?.total_pages) || 15
      let runningmenus = state[deliveryType]?.runningmenus;
      if (action.updatedMeeting && deliveryType === 'admin_approved_deliveries' && action?.payload?.runningmenus && runningmenus) {
        runningmenus.splice(+action?.page * perPageMeetings - perPageMeetings, perPageMeetings, ...action?.payload?.runningmenus);
      } else {
        runningmenus = runningmenus ? [...runningmenus, ...action?.payload?.runningmenus] : [...action?.payload?.runningmenus]
      }
      return {
        ...state,
        [deliveryType]: (action?.page === '1' || deliveryType === 'admin_delivery_requests') ? action?.payload : { ...action?.payload, runningmenus },
        [deliveryType + '_loading']: false
      }
    case SET_MENU_TAB_MEETING:
      return {
        ...state,
        menu_tab_meeting: action.payload,
        menu_tab_meeting_loading: false,
      }
    case GET_MENU_TAB_MEETING_LOADING:
      return {
        ...state,
        menu_tab_meeting: undefined,
        menu_tab_meeting_loading: true
      }
    case SET_POPUP_STATE_ON_MENU_DETAIL_PAGE:
      return {
        ...state,
        isOpenPopupOnMenuDetailPage: action.payload,
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default menuReducer;