import React, { FC } from "react";
import Modal from 'react-modal';
import { useDispatch } from "react-redux";
import { cancelOrderAnyway } from "../../Actions/orderActions";
import { DeliveryOrdersSidebarMeeting } from "../../Common/Popups/DeliveryOrdersSidebar";
import dayjs from "dayjs";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '32px',
  }
};

type DeleteOrderConfirmationPopupPrrops = {
  orderDeletePopup: boolean;
  updatePageOnOrderDelete?: () => void;
  handleCloseModal: () => void;
  fromMenuDetailPopup?: boolean;
  selectedMeeting: DeliveryOrdersSidebarMeeting;
  invitedMeetingToken?: string;
  orderId: number;
}

const DeleteOrderConfirmationPopup: FC<DeleteOrderConfirmationPopupPrrops> = ({ orderDeletePopup,updatePageOnOrderDelete, handleCloseModal, fromMenuDetailPopup, selectedMeeting, invitedMeetingToken, orderId }) => {

  const dispatch = useDispatch<any>();
  const redirectToBillingPage = () => {
    window.location.href = '/profile/billing'
  }

  const successCallBack = () => {
    handleCloseModal();
  }

  const onConfirmDeleteOrder = () => {
    const deliveryDate = fromMenuDetailPopup ? dayjs(selectedMeeting?.delivery_at).format('D-M-YYYY') : undefined;
    dispatch(cancelOrderAnyway(orderId, deliveryDate, invitedMeetingToken,  () => {updatePageOnOrderDelete?.()} , successCallBack));
  }


  return (
    <Modal
      isOpen={orderDeletePopup}
      onRequestClose={handleCloseModal}
      style={customStyles}
      contentLabel='Confirmation Modal'
    >
      {/* <ModalHeader className='cancel-order-header' label={'Unable to cancel your order'} onModalClose={handleCloseModal} /> */}
      <div className='modal-header new-modal-header p-0'>
        <h5 className='modal-title' id='modal-title'>
          Unable to cancel your order
        </h5>
        <button
          type='button'
          onClick={handleCloseModal}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>×</span>
        </button>
      </div>
      <div className='modal-body px-0 pb-0 pt-1.5'>
        <form className=''>
          <h6 className='text-left mb-3 text-gray-100'>
            Your credit card is no longer on file so we are not able to provide a refund on this user. </h6>
          <h6 className='text-left my-3 text-gray-100'>
            Please add a credit card by going to your <a href="/profile/billing" style={{ color: '#DC3644' }} onClick={redirectToBillingPage}>Profile {'>'} Billing</a> in order to receive a refund.
          </h6>
          <div className='text-center modal-footer border-top-0 p-0 pt-4'>
            <button type='button' className='btn btn-outline-secondary combo-restaurant-info-card text-gray-100 fw-500' onClick={handleCloseModal}>No Don't Cancel</button>
            <button type='button' className='btn btn-danger btn-cancel-order' onClick={onConfirmDeleteOrder}>
              Cancel(No Refund)
            </button>
          </div>
        </form>
      </div>
    </Modal>

  )
}

export default DeleteOrderConfirmationPopup;