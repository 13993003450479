import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
import { getloggedInUser } from '../../Utilities/getloggedInUser';

const { loggedInUser: user } = getloggedInUser();

type PrivateRouteProps = {
  component: any;
  exact: boolean;
  path: string | Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { auth } = useSelector((state: ReduxRootState) => state);

  return <Route
    {...rest}
    render={props =>
      (auth.isAuthenticated === true && user && user.user_type === UserType.ADMIN && user.address_id !== null) || props.location.pathname === '/signup' ? (
        <Component {...props} />
      ) : (
        <Redirect to={(user && user.address_id === null) ? '/signup' : '/signin'} />)
    }
  />
}

export default PrivateRoute
