import i18n from "i18next";

export const loginTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Login": "Login",
      "Not currently registered?": "Not currently registered?",
      "Email us": "Email us",
      "Please login below using your Email and Password.": "Please login below using your Email and Password.",
      "Email address": "Email address",
      "Password": "Password",
      "Forgot Password": "Forgot Password",
      "Keep me logged in": "Keep me logged in",
      "Sign in": "Sign in",
      "© Copyright": " © Copyright ",
      "Chowmill": " Chowmill ",
      "Submit": "Submit",

      "Reset Your Password": "Reset Your Password",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      "Please insert at least 6 characters.": "Please insert at least 6 characters."
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Login": "لاگ ان کریں",
      "Not currently registered?": "فی الحال رجسٹرڈ نہیں ہے؟",
      "Email us": "ہمیں ای میل کریں",
      "Please login below using your Email and Password.": "براہ کرم نیچے اپنا ای میل اور پاس ورڈ استعمال کرکے لاگ ان کریں۔",
      "Email address": "ای میل اڈریس",
      "Password": "پاس ورڈ",
      "Forgot Password": "پاسورڈ بھول گے",
      "Keep me logged in": "مجھے لاگ ان رکھیں",
      "Sign in": "سائن ان",
      "© Copyright": " © کاپی رائٹ ",
      "Chowmill": " چومیل ",
      "Submit": "جمع کرائیں",

      "Reset Your Password": "آپ کا پاس ورڈ دوبارہ ترتیب دیں",
      "New Password": "نیا پاس ورڈ",
      "Confirm Password": "پاس ورڈ کی تصدیق کریں",
      "Please insert at least 6 characters.": "براہ کرم کم از کم 6 حرف داخل کریں۔"
    });
};