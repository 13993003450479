import { GET_STATISTICS, RESET_STATE } from '../Actions/types';
import { Reports } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Reports = {
  statistics: null,
  statisticsLoading: false,
}

const reportsReducer = (state: Reports = initialState, action: any): Reports => {
  switch (action.type) {
    case GET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
        statisticsLoading: false
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default reportsReducer;