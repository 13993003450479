import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import { getTimeZoneByLocation } from "../Actions/companyActions";
import isEmpty from "./is_empty";

export interface LocationData {
  city: string;
  state: string;
  zip: string;
  street_number: string;
  street: string;
  address_line: string;
  longitude: number;
  latitude: number;
  time_zone: string;
  formatted_address: string;
}
const user = JSON.parse(localStorage.getItem("user") || "{}");
const getPlaceDetails = async (placeId: string): Promise<LocationData | null> => {
  try {
    const makeSearchablePlaceId = placeId.split('?')
    const placeIdtoSearch = makeSearchablePlaceId.length >= 1 ? `${makeSearchablePlaceId[0]}` : placeId

    const results = await geocodeByPlaceId(placeIdtoSearch);
    const placeDetails = results[0];

    if (placeDetails) {
      const latLng = await getLatLng(results[0]);

      // Assuming you have a getTimeZoneByLocation function implemented
      const response = await getTimeZoneByLocation(latLng?.lat, latLng?.lng);
      const time_zone = response?.timeZoneId;

      const PlaceIdData = {
        placeId: placeId
      };

      localStorage.setItem("address-placeId", JSON.stringify(PlaceIdData));

      const formatted_address = `${placeDetails.address_components.find(component => component.types.includes('street_number'))?.short_name || ''} ${placeDetails.address_components.find(component => component.types.includes('route'))?.long_name || ''}${(placeDetails.address_components.find(component => component.types.includes('route'))?.long_name || placeDetails.address_components.find(component => component.types.includes('street_number'))?.short_name) && placeDetails.address_components.find(component => component.types.includes('locality')) ? ',' : ''}${placeDetails.address_components.find(component => component.types.includes('locality'))?.long_name || ''}`;

      const locationData: LocationData = {
        city: placeDetails.address_components.find(component => component.types.includes('locality'))?.long_name || '',
        state: placeDetails.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '',
        zip: placeDetails.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
        street_number: placeDetails.address_components.find(component => component.types.includes('street_number'))?.short_name || '',
        street: placeDetails.address_components.find(component => component.types.includes('route'))?.long_name || '',
        address_line: placeDetails.formatted_address || '',
        longitude: latLng.lng || 0,
        latitude: latLng.lat || 0,
        time_zone: time_zone || '',
        formatted_address: formatted_address || '',
      };

      saveAddress(locationData);
      return locationData;
    } else {
      console.error('Error fetching location data: Place details not found');
      return null;
    }
  } catch (error) {
    console.error('Error fetching location data:', error);
    return null;
  }
};

const saveAddress = (address: LocationData) => {
  if (user?.phone_number) {
    //   dispatch(addNewLocationData(+loggedInCompanyId, formattedLocationData));
  } else {
    if (!isEmpty(address?.latitude)) {

      localStorage.setItem("address", JSON.stringify(address));
    }
  }
};

export default getPlaceDetails;


