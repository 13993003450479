// Pacakges
import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
// import { Slide } from 'pure-react-carousel';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
// React JSX Components
// import MenuDetailPageCarousel from './MenuDetailPageCarousel';
// Redux Dispatch Actions
import { getDeliveryDates } from '../../../Actions/scheduleDeliveryActions';
// Utilities
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
// import { getAllDaysForDateRange } from '../../../Utilities/getAllDaysForDateRange';
// Types
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import Calendar from '../../../Utilities/mobileCalendar';
import '../../../Utilities/css/mobileCalendar.css';
// import 'react-day-picker/dist/style.css';

export type mobileCalendarDatesType = {
  date: Date,
  orderPresent: undefined | boolean
}

type DeliveryDatesCalendarProps = {
  isMobileCalendar?: boolean;
  hideCalendar?: boolean;
  deliveryDate: string | undefined;
  onMonthChange?: (date: Date, month: number, year: number) => void;
  onDateChangeHandler?: (newDelivery: DeliveryOnSingleDate, date: Date) => void;
}

// const numberOfSlidesForMobileCalendar = 7;

const DeliveryDatesCalendar = ({ deliveryDate, onDateChangeHandler, onMonthChange, isMobileCalendar = false, hideCalendar = false }: DeliveryDatesCalendarProps) => {

  const history = useHistory();
  const didMount = useRef(false);
  const dispatch: any = useDispatch();
  const { delivery_dates } = useSelector((state: ReduxRootState) => state.users)
  const { date: currentDeliveryDateParam, meetingSlug } = useParams<{ date: string, meetingSlug: string }>();
  const [{ meetingsWithNoOrders, meetingsWithOrders }, setDeliveryDates] = useState<{ meetingsWithOrders: Date[], meetingsWithNoOrders: Date[] }>({ meetingsWithOrders: [], meetingsWithNoOrders: [] })

  const { loggedInUser } = getloggedInUser();
  const currentDeliveryExactDate = deliveryDate ? dayjs(deliveryDate).format('YYYY-MM-DD') : dayjs(currentDeliveryDateParam, 'M-D-YYYY').format('YYYY-MM-DD');
  // const [mobileCalendarDates, setMobileCalendarDates] = useState<mobileCalendarDatesType[]>(getAllDaysForDateRange(currentDeliveryExactDate))
  const [monthsWithDeliveryDates, setMonthsWithDeliveryDates] = useState<{ month: number, year: number }[]>([])

  // DayPicker Styles
  const css = `
    .rdp {
      display: inline-block;
      font-size: 1rem;
      --rdp-cell-size: 40px;
      --rdp-accent-color: #0000ff;
      --rdp-background-color: #e7edff;
      --rdp-accent-color-dark: #3003e1;
      --rdp-background-color-dark: #180270;
      --rdp-outline: 2px solid var(--rdp-accent-color);
      --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
      margin: 0;
    }
    .rdp-head_cell {
      display: table-cell;
      padding: 0.5em;
      color: #8b9898;
      text-align: center;
      font-size: .875em;

      vertical-align: unset
      text-transform: uppercase;
      font-weight: 700;
      height: 100%;
      height: var(--rdp-cell-size);
    }

    .rdp-cell {
      padding: 0;
      text-align: center;
      width: 35px;
      height: 35px;
    }

    .rdp-day {
      position: relative;
      cursor: default;
      color: #99a4a4 !important;
      height: 35px;
      width: 35px;
      display: table-cell;
      padding: 0.5em;
      vertical-align: middle;
      
      overflow: unset;
      align-items: center;
      justify-content: center;
      box-sizing: unset;
      max-width: var(--rdp-cell-size);
      margin: 0;
      border: 0;
      border-radius: 50%;
    }

    .rdp-nav_button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 100%;
      color: red;
      width: 2em;
      height: 1.75em;
      color: #8b9898;
      cursor: pointer;
    }
    .rdp-button:focus:not([disabled]), .rdp-button:active:not([disabled]), .rdp-button:hover:not([disabled]) {
      /* color: inherit; */
      border: none;
      /* background-color: var(--rdp-background-color); */
      background-color: transparent;
      cursor: default;

    }
    .rdp-button {
      cursor: default;
      color: #99a4a4 !important;
      /* height: 30px; */
      /* width: 30px; */
    }

    .rdp-nav_button > svg,.rdp-nav_button{
      height:14px;
      width:14px;
      color:#8b9898;
      cursor:pointer;
    }
    .rdp-button_reset {
      appearance: auto;
      color: inherit;
    }

    .rdp-months {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-weight: bold;
    }

    .rdp-caption {
      font-weight: 600;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      text-align: left;
    }

    .rdp-caption_label {
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0 0.25em;
      white-space: nowrap;
      color: currentColor;
      border: 0;
      border: 2px solid transparent;
      font-family: inherit;
      font-weight: 600;
      font-size: 18px;
  }
 
}
  `;

  // Code to handle show Correct Slide For the First Time Correctly
  // const selectedDeliveryDate = dayjs(currentDeliveryDateParam, 'M-D-YYYY').date();
  // const { current: currentSlideForMobieCalendar } = useRef(selectedDeliveryDate + numberOfSlidesForMobileCalendar > mobileCalendarDates?.length
  //   ? mobileCalendarDates?.length - numberOfSlidesForMobileCalendar : selectedDeliveryDate - 1)

  const getDeliveryDatesRange = useCallback(() => {
    const currentDateForRange = currentDeliveryDateParam ? dayjs(currentDeliveryDateParam, 'M-D-YYYY') : dayjs();
    const currentDeliveryMonth = currentDateForRange?.month() + 1;
    const currentDeliveryYear = currentDateForRange?.year();
    const pastMonth = currentDeliveryMonth - 1 <= 0 ? 12 : currentDeliveryMonth - 1, pastMonthYear = currentDeliveryMonth - 1 <= 0 ? currentDeliveryYear - 1 : currentDeliveryYear;
    const nextMonth = currentDeliveryMonth + 1 > 12 ? 1 : currentDeliveryMonth + 1, nextMonthYear = currentDeliveryMonth + 1 > 12 ? currentDeliveryYear + 1 : currentDeliveryYear;
    const intialDeliveryDatesMonth = [{
      month: pastMonth, year: pastMonthYear
    }, {
      month: currentDeliveryMonth, year: currentDeliveryYear
    }, {
      month: nextMonth, year: nextMonthYear
    }];
    const from = `1-${pastMonth}-${pastMonthYear}`;
    const to = `${dayjs(`${currentDeliveryYear}-${currentDeliveryMonth + 1}-1`)?.daysInMonth()}-${nextMonth}-${nextMonthYear}`;
    return { from, to, intialDeliveryDatesMonth }
  }, [currentDeliveryDateParam])

  const monthChangeHandler = (date: Date) => {
    const month = dayjs(date)?.month() + 1, year = dayjs(date)?.year();
    const newMonthForDeliveryDates = monthsWithDeliveryDates?.find((date) => date?.month === month && date?.year === year);

    if (!newMonthForDeliveryDates) {
      setMonthsWithDeliveryDates(prevMonths => [...prevMonths, { month, year }])
      dispatch(getDeliveryDates(`1-${month}-${year}`, `${dayjs(`${year}-${month}-1`)?.daysInMonth()}-${month}-${year}`));
    }
    onMonthChange && onMonthChange(date, month, year);
  }

  const redirectToNewDelivery = (newDelivery: DeliveryOnSingleDate, deliveryDate: Date) => {
    if (!!newDelivery && !!deliveryDate && meetingSlug !== newDelivery?.slug && dayjs(deliveryDate) !== dayjs(currentDeliveryDateParam)) {
      const menuPageDate = dayjs(deliveryDate).format('M-D-YYYY');
      onDateChangeHandler && onDateChangeHandler(newDelivery, deliveryDate);
      // APIs commented as these APIs are called from Dashboard DidUpdate
      // dispatch(getSingleSchedule(newDelivery?.slug));
      // dispatch(getSingleDeliveryMenu(newDelivery?.slug, newDelivery?.runningmenu_type || 'lunch'));
      history.push(`/menu/${menuPageDate}/${newDelivery?.slug}`);
    }
  }

  const daySelectorHandler = (selectedDate: Date) => {
    const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
    const singleDateDeliveries = delivery_dates[formattedDate];
    if (!!singleDateDeliveries && singleDateDeliveries?.length > 0) {
      let newSelectedDelivery = singleDateDeliveries[0];
      // if (isCompanyUser) {// In case of Company User Redirect to First delivery matching user location
      newSelectedDelivery = singleDateDeliveries.find(delivery => delivery.address_id === loggedInUser.address_id) || singleDateDeliveries[0];
      // }
      redirectToNewDelivery(newSelectedDelivery, selectedDate);
    }
  }

  // Did Mount
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      const { from, to, intialDeliveryDatesMonth } = getDeliveryDatesRange();
      setMonthsWithDeliveryDates(intialDeliveryDatesMonth);
      dispatch(getDeliveryDates(from, to));
    }
  }, [getDeliveryDatesRange, dispatch])

  // Did Update
  useEffect(() => {
    const meetingsWithOrders: Date[] = [], meetingsWithNoOrders: Date[] = [];
    // const prevMobileCalendarDates = getAllDaysForDateRange(currentDeliveryExactDate)?.map(({ date }) => {
    //   const existingDelivery = delivery_dates[dayjs(date)?.format('YYYY-MM-DD')]?.find((meeting: DeliveryOnSingleDate) => meeting?.placed_order || meeting?.placed_order === false);
    //   return { date, orderPresent: existingDelivery?.placed_order }
    // });
    Object.keys(delivery_dates)
      ?.forEach((date => {
        const formattedDate = dayjs(date).toDate();
        const orderPresent = delivery_dates[date]?.find((meeting) => meeting?.placed_order);
        orderPresent ? meetingsWithOrders.push(formattedDate) : meetingsWithNoOrders.push(formattedDate);
      }));
    // setMobileCalendarDates(prevMobileCalendarDates);
    setDeliveryDates({ meetingsWithOrders, meetingsWithNoOrders });

  }, [delivery_dates, currentDeliveryExactDate])

  return hideCalendar ? <></> :
    (isMobileCalendar ?
      <div>
        <Calendar
          meetingsWithOrders={meetingsWithOrders}
          meetingsWithNoOrders={meetingsWithNoOrders}
          daySelectorHandler={daySelectorHandler}
          currentDeliveryDateParam={currentDeliveryDateParam}
          // onMonthChange={monthChangeHandler}
        />
      </div>

      // <MenuDetailPageCarousel
      //   totalSlides={mobileCalendarDates?.length}
      //   visibleSlides={numberOfSlidesForMobileCalendar}
      //   showCarouselButtons={true}
      //   stretchSlidesToFullWidth
      //   currentSlide={currentSlideForMobieCalendar}
      // >
      //   <button className='btn btn-lg' onClick={() => mobileMonthChangeHandler(new Date(), 'prev')}>Previous</button>

      //   {mobileCalendarDates.map(({ date, orderPresent }, index: number) => {
      //     const selectedDay = dayjs(date)?.date() === dayjs(mobileCalendarMontChange? mobileCalendarMontChange : currentDeliveryExactDate).date();
      //     return <>
      //     <Slide key={`${date}`} index={index}>
      //       <div className='single-date cursor-default'>
      //         <p>{dayjs(date)?.format('dd')}</p>
      //         <p onClick={() => orderPresent !== undefined && daySelectorHandler(date)}
      //           className={`${selectedDay ? 'selected-day' : ''} position-relative ${orderPresent === true ? 'order-present' : (orderPresent === false) ? 'delivery-present' : 'delivery-not-present'}`}>
      //           {dayjs(date)?.format('D')}
      //         </p>
      //       </div>
      //     </Slide>
          
      //     </>
      //   })}
      //    ;
      //   <button className='btn btn-lg' onClick={() => mobileMonthChangeHandler(dayjs(mobileCalendarMontChange).toDate(), "next")}>Next</button>
      // </MenuDetailPageCarousel>
      :
      <>
        <style>{css}</style>
        <DayPicker
          className={`menu-detail dashboard-cal sidebar-meeting-header`}
          numberOfMonths={1}
          modifiers={{
            deliveryExists: meetingsWithNoOrders,
            orderPresent: meetingsWithOrders
          }}
          modifiersClassNames={{
            orderPresent: 'DayPicker-Day--orderPresent',
            deliveryExists: 'DayPicker-Day--deliveryExists',
            selected: 'DayPicker-Day--selected'
            // nav_button: 'nav_button'
          }}
          //weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          showOutsideDays
          selected={[dayjs(currentDeliveryDateParam).toDate()]}
          defaultMonth={currentDeliveryExactDate ? new Date(currentDeliveryExactDate) : new Date()}
          onDayClick={daySelectorHandler}
          onMonthChange={monthChangeHandler}
        />
      </>
      )
}

export default DeliveryDatesCalendar