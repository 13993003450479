// import React, { Component } from 'react';
// import { loginUser, adminlogin } from '../../Actions/authActions';
// import { connect } from 'react-redux';
// import './LoginStyle.css';
// import { getFutureDelivery } from '../../Actions/userActions';
// import dayjs from 'dayjs';
// import { RouteComponentProps } from 'react-router-dom';
// import { Auth, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

// export type AdminLoginPayload = {
//   key: string,
// }
// export type AdminLoginProps = {
//   delivery_at: string;
//   meeting_id: string;
//   adminlogin: (date: AdminLoginPayload) => void;
//   auth: Auth;

// } & RouteComponentProps
// export type AdminLoginState = {

// }
// class AdminLogin extends Component<AdminLoginProps, AdminLoginState, AdminLoginPayload> {

//   state = {
//     delivery_at: '',
//     meeting_id: '',
//   };

//   componentDidMount() {
//     let url = window.location.href;
//     let token: string | string[] = url.split('/');
//     let leng = token.length;
//     let params = token[leng - 1].split('?')[1] && token[leng - 1].split('?')[1].split('&');
//     token = token[leng - 1].split('?')[0];
//     localStorage.clear();
//     if (params) {
//       this.setState({ delivery_at: params[0].split('=')[1], meeting_id: params[1].split('=')[1] });
//     }
//     const data = {
//       key: token
//     };
//     this.props.adminlogin(data);
//   }
//   UNSAFE_componentWillReceiveProps(nextProps: AdminLoginProps) {
//     if (nextProps.auth.isAuthenticated) {
//       this.redirectUser();
//     }
//   }
//   checkFutureDelivery = async () => {
//     try {
//       // const user = JSON.parse(localStorage.getItem('user') || '{}');
//       // if(user && user?.user_type === UserType.ADMIN) {
//       //   this.props.history?.replace('/dashboard');
//       //   window.location.href = '/dashboard';
//       //   return;
//       // }
//       const { data: { meeting = null } = {} } = await getFutureDelivery() || {};
//       if (meeting?.slug) {
//         const url = `/menu/${dayjs(meeting?.delivery_at).format('M-D-YYYY')}/${meeting?.slug}`
//         window.location.href = url
//       } else {
//         this.props.history?.replace('/dashboard');
//         window.location.href = '/dashboard';
//       }
//     } catch (error) {
//       this.props.history?.replace('/dashboard');
//       window.location.href = '/dashboard';
//     }
//   }
//   redirectUser() {
//     if (this.state.delivery_at) {
//       this.props.history.replace(`/dashboard/${this.state.delivery_at}?meetingId=${this.state.meeting_id}`);
//       window.location.href = `/dashboard/${this.state.delivery_at}?meetingId=${this.state.meeting_id}`;
//     } else {
//       // this.checkFutureDelivery()
//       window.location.href = `/menu`;
//     }
//   }
//   componentWillUnmount() { }
//   render() {
//     return (
//       <div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   auth: state.auth,
//   errors: state.errors
// });

// export default connect(
//   mapStateToProps,
//   { loginUser, adminlogin }
// )(AdminLogin);

import React, { FC, useEffect } from 'react';
import { adminlogin } from '../../Actions/authActions';
import { useSelector } from 'react-redux';
import './LoginStyle.css';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { useDispatch } from 'react-redux';
import Spinner from '../../Common/Spinners/Spinner';

export type AdminLoginPayload = {
  key: string,
}
export type AdminLoginProps = {
  delivery_at: string;
  meeting_id: string;
  adminlogin: (date: AdminLoginPayload) => void;
}

const AdminLogin: FC<AdminLoginProps> = () => {

  const [delivery_at, setDelivery_at] = React.useState<string>('');
  // const [meeting_id, setMeeting_id] = React.useState<string>('');
  const auth = useSelector((state: ReduxRootState) => state.auth)
  const dispatch: any = useDispatch()

  const newPageis = window.location.origin

  useEffect(() => {
    let url = window.location.href;
    let token: string | string[] = url.split('/');
    let leng = token.length;
    let params = token[leng - 1].split('?')[1] && token[leng - 1].split('?')[1].split('&');
    token = token[leng - 1].split('?')[0];
    // localStorage.clear();


    if (params) {
      setDelivery_at(params[0].split('=')[1]);
      // setMeeting_id(params[1].split('=')[1]);
    }
    const data = {
      key: token
    };
    dispatch(adminlogin(data));

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const delay = 1000;

    const timerId = setTimeout(() => {
      if (auth.isAuthenticated) {
        redirectUser();
      }
    }, delay);

    // Cleaning up the timer to avoid memory leaks
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line
  }, [auth.isAuthenticated]);

  const redirectUser = () => {
    if (delivery_at) {
      // Dashboard page is disabled/removed 
      // history.replace(`/dashboard/${delivery_at}?meetingId=${meeting_id}`);
      // window.location.href = `/dashboard/${delivery_at}?meetingId=${meeting_id}`;
    } else {
      // redirecting user to home page
      // const newWindow = window.open(`${newPageis}/menu`, '_blank');
      window.location.replace(`${newPageis}/menu`)
    }
  }
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <Spinner />
    </div>
  );
}


export default AdminLogin;
