import { forwardRef, useImperativeHandle } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';

export type StripeRef = {
	stripe: Stripe | null;
}
const StripeObjectReference = forwardRef((props, ref) => {
	const stripe = useStripe();
	// To Pass Ref of handleFormSubmit to Parent Component.
	useImperativeHandle(ref, () => ({ stripe }), [stripe]);

	return null;
})

export default StripeObjectReference;
