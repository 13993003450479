// Pacakges
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
// React JSX Components
import Spinner from '../../../Common/Spinners/Spinner';
// Redux Dispatch Actions
import { CLONE_ORDERS } from '../../../Actions/types';
import { cloneOrders } from '../../../Actions/orderActions';
import { makeDeliveryRequest } from '../../../Actions/scheduleDeliveryActions';
import PostCloneFieldsPopUp, { PostCloneFieldsPopUpRef } from '../../../Common/Popups/PostCloneFieldsPopUp';
// Utilities
import isEmpty from '../../../Utilities/is_empty';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
// Types
import { ApprovedMeetingMenuDetailRef } from '../ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import { MarketplaceRef } from '../MarketplacePreview/Marketplace';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { CloneOrdersCustomFields } from '../../../Interfaces/PayloadsAndResponses/Orders';
import { MarketPlaceMenuRestaurantCustomField } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { Delivery, DeliveryRequestPayload } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { getZonedTime } from '../../../Utilities/functions';
// CSS

export type CloneOrderCustomFields = CloneOrdersCustomFields[] | MarketPlaceMenuRestaurantCustomField[];

type CloneOrdersProps = {
  currentDelivery: Delivery;
  fromDashboard?: boolean;
  fromMarketplace?: boolean;
  parentRef: MarketplaceRef | ApprovedMeetingMenuDetailRef;
}

const CloneOrders = ({ currentDelivery, fromDashboard = false, fromMarketplace = false, parentRef }: CloneOrdersProps) => {

  const cloneOrdersPopupRef = useRef<PostCloneFieldsPopUpRef | null>(null);
  const dispatch: any = useDispatch();
  const { cuisine_list: { cuisines },
    menu: { menu_of_restaurant },
    order: { clone_orders }
  } = useSelector((state: ReduxRootState) => state);

  const [cloneOrdersLoading, setCloneOrdersLoading] = useState(false);
  const [cloneOrdersRequired, setCloneOrdersRequired] = useState(false);
  const allowedRestaurantsIdArray: any = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);

  const { loggedInUser: user } = getloggedInUser();
  const { delivery_at, orders_count, order_ids } = currentDelivery.clone_orders || {}

  const openRequiredFieldsPopup = useCallback((fields: CloneOrderCustomFields, cloneOrdersIds: number[], runningmenu: DeliveryRequestPayload['runningmenu'] = {}) => {
    const cloneOrdersPopup = cloneOrdersPopupRef.current;
    if (cloneOrdersPopup) {
      // cloneOrdersPopup.openModal();
      // cloneOrdersPopup.setState({ modalIsOpen: true, runningmenuSlug: currentDelivery.slug, orderFieldAttributes: fields, marketplaceMeeting: runningmenu, cloneOrdersIds })
    cloneOrdersPopup.handleState(true,currentDelivery.slug,fields,runningmenu,cloneOrdersIds)
    }
  }, [currentDelivery.slug])

  const cloneOrdersHandler = () => {
    // dispatch previous cloned orders to avoid opening view all item popup in case of two time cloning orders
    dispatch({ type: CLONE_ORDERS, payload: null });
    // In Case of Marketplace When Meeting is to be created
    if (fromMarketplace || isEmpty(currentDelivery.id)) {
      const marketplaceRef = parentRef as MarketplaceRef;
      const marketplaceState = marketplaceRef?.state;
      let cuisines_menus_attributes: { cuisine_id: number }[] = [];
      cuisines?.forEach(cuisine => {
        if (cuisine.name === 'Pick for Me') {
          cuisines_menus_attributes = [{ cuisine_id: cuisine.id }];
        }
      });
      let runningmenu: DeliveryRequestPayload['runningmenu'] = {
        runningmenu_name: `${user.first_name?.trim()}’s New Delivery`,
        address_ids: allowedRestaurantsIdArray,
        delivery_instructions: marketplaceState.selectedAddressDetail?.delivery_instructions || '',
        runningmenu_type: marketplaceState?.meal_type,
        address_id: marketplaceState?.selectedCompanyAddressID,
        per_meal_budget: marketplaceRef?.perMealBudget,
        delivery_at: marketplaceState?.deliveryAt,
        orders_count: 1,
        // end_time: marketplaceState?.deliveryAt,
        special_request: '',
        menu_type: marketplaceState?.mealType,
        cuisines_menus_attributes: cuisines_menus_attributes,
        hide_meeting: true
      }
      const { clone_orders, restaurants } = menu_of_restaurant || {};
      if (!isEmpty(restaurants?.[0]?.custom_fields)) { // First Open PostCloneFieldsPopup => Create Delivery => Clone Orders => Update Delivery Details after Routing
        setCloneOrdersRequired(true);
        openRequiredFieldsPopup(restaurants?.[0]?.custom_fields!, clone_orders?.order_ids ?? [], runningmenu);
      } else { // Create Delivery => Clone Orders => Update Delivery Details after Routing
        setCloneOrdersLoading(true);
        dispatch(makeDeliveryRequest({ runningmenu }, false, clone_orders?.order_ids ?? []));
      }
    } else if (currentDelivery.id && order_ids) {// In Case of Menu Detail Page Dashboard When Meeting is already Present.
      setCloneOrdersRequired(true);
      setCloneOrdersLoading(true);
      dispatch(cloneOrders({ runningmenu_id: currentDelivery.id, order_ids }));
    }
  }

  useEffect(() => {
    if (clone_orders && cloneOrdersRequired) {
      if (currentDelivery?.field_required) { // Will run when try to clone Order of a Delivery Required Custom Fields but was missing.
        setCloneOrdersRequired(false);
        setCloneOrdersLoading(false);
        openRequiredFieldsPopup(clone_orders as CloneOrdersCustomFields[], currentDelivery.clone_orders?.order_ids ?? []);
      }
    }
  }, [clone_orders, currentDelivery, cloneOrdersRequired, openRequiredFieldsPopup]);

  return <>
    {!isEmpty(currentDelivery.clone_orders) &&
      <div className='cuttof-passed' style={{ background: '#28A745', width: '100%', color: '#fff' }}>
        <div className='alert d-flex justify-content-center align-items-center container mb-0' role='alert' style={{ padding: '17px' }}>
          <p className='m-0'>Good news, you've ordered from this restaurant(s) before! Clone your order from {delivery_at ? getZonedTime(delivery_at, 'EEE. MMM dd h:mma, zzzz', currentDelivery?.time_zone) : ''} For {orders_count} Item(s).</p>
          {!cloneOrdersLoading ?
            <button className='btn btn-light btn-sm ml-3 text-nowrap' style={{ borderRadius: 1000, color: 'green' }} onClick={cloneOrdersHandler}>
              {/* <i className='fas fa-redo mr-1' aria-hidden='true'></i> */}
              Repeat Order
            </button> :
            <button className='btn btn-light btn-sm ml-4 text-nowrap' style={{ borderRadius: 1000, height: '31px', width: '105px', backgroundColor: '#fff', color: '#28A745' }}>
              <Spinner spinnerSizeClass='spinner-border-sm' />
            </button>
          }
        </div>
      </div>
    }

    <PostCloneFieldsPopUp
      ref={(ref: PostCloneFieldsPopUpRef | null) => (cloneOrdersPopupRef.current = ref)}
      onUpdatePage={() => { }}
    />
  </>
}

export default CloneOrders;