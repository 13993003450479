// Packages
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import ScheduleDeliveryForm, { ScheduleDeliveryFormRef } from '../../../Common/ScheduleDeliveryForm';
import ButtonSpinner from '../../../Common/Spinners/ButtonSpinner';
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { MarketPlaceCompanyAddress } from '../../../Interfaces/PayloadsAndResponses/Company';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '702px',
//     padding: '0'
//   }
// };

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

export type MarketplaceMeeting = {
  mealType: string;
  deliveryInstructions: string | undefined;
  selectedCompanyAddress: MarketPlaceCompanyAddress | undefined;
  selectedCompanyAddressID: number | undefined;
}

type MeetingDetailsPopUpProps = {
  i18n: WithTranslation | null,
  onRef: (ref: MeetingDetailsPopUpRef | null) => void,
  selectedDate: Date | undefined,
  selectedType?: string | null,
  selectedAddress?: string | null,
  onUpdatePage: () => void,
  fromDashboard?: boolean,
  marketPlace?: boolean,
  marketPlacePage?: boolean,
  meeting?: MarketplaceMeeting,
  selectedRestaurants?: number[],
  createSchedular?: boolean,
}
export type MeetingDetailsPopUpState = {
  modalIsOpen: boolean,
  meetingUpdateSlug: string | undefined,
  showLoading: boolean,
  pending: boolean,
  meetingStatus: string | undefined,
  requiredFields: boolean,
}
class MarketplaceScheduleDelivery extends Component<MeetingDetailsPopUpProps, MeetingDetailsPopUpState> {

  state = {
    modalIsOpen: false,
    meetingUpdateSlug: '',
    showLoading: false,
    pending: false,
    meetingStatus: '',
    requiredFields: false
  };

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(null)
  }
  openModal = (meetingUpdateSlug?: string, meetingStatus?: string, requiredFields = false) => {
    if (meetingUpdateSlug) {
      this.setState({ pending: true })
    } else {
      this.setState({ pending: false })
    }
    this.setState({ modalIsOpen: true, meetingUpdateSlug: meetingUpdateSlug, meetingStatus: meetingStatus, requiredFields: requiredFields });
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value } as unknown as MeetingDetailsPopUpState)
  }
  onSubmit = () => {
    this.sch_req_form?.onSubmit();
  }

  sch_req_form = React.createRef<ScheduleDeliveryFormRef>().current;

  render() {

    return (
    <>
      <div className={`container ${this.props.marketPlacePage ? 'mb-4' : ''}`} style={{ width: '100%' }}>
        {/* use props from msrketplace are, selectedRestaurants, marketPlace, meeting  */}
        <ScheduleDeliveryForm
          selectedDate={this.props.selectedDate}
          selectedType={this.props.selectedType}
          selectedAddress={this.props.selectedAddress}
          meetingUpdateSlug={this.state.meetingUpdateSlug}
          meetingStatus={this.state.meetingStatus}
          onRef={(ref: ScheduleDeliveryFormRef | null) => { this.sch_req_form = ref }}
          setState={(p: MeetingDetailsPopUpState) => { this.setState(p) }}
          closeModal={this.closeModal}
          onUpdatePage={this.props.onUpdatePage}
          requiredFields={this.state.requiredFields}
          marketPlace={this.props.marketPlace}
          marketPlacePage={this.props.marketPlacePage}
          selectedRestaurants={this.props.selectedRestaurants}
          meeting={this.props.meeting}
          createSchedular={true}
        />
        <div className='d-flex justify-content-end mr-3'>
          <button
            type='submit'
            onClick={this.onSubmit}
            className={`confirm-update-delivery btn  delivery-btn-width p-3 mr-3 ${this.state.pending === false ? 'btn-primary ' : 'btn-warning'} ${this.props?.marketPlacePage ? 'delivery-btn-page-styles ' : ''}`}
          >
            {(this.props.marketPlace) ?
              (this.state.showLoading === true) ?
                <ButtonSpinner /> :
                (this.props.i18n && this.props.i18n.t('Schedule Delivery')) :
              (this.state.showLoading === true) ? <ButtonSpinner /> :
                (this.props.i18n && this.props.i18n.t('Update Delivery'))}
          </button>
        </div>
      </div>
    </>
    )
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  i18n: state.i18n.i18n
});

export type MeetingDetailsPopUpRef = React.ElementRef<typeof MarketplaceScheduleDelivery>;

export default connect(mapStateToProps, {})(MarketplaceScheduleDelivery);