// Packages
import React from 'react';
import parse from 'html-react-parser';
// React JSX Components
// Utilities
import isEmpty from '../../../Utilities/is_empty';
// Types
import { GetAnnouncements } from '../../../Interfaces/PayloadsAndResponses/Users';
// CSS


type AnnouncementsAndAlertsProps = {
  cuttofPassed: boolean;
  announcements: GetAnnouncements;
}

const AnnouncementsAndAlerts = ({ announcements = [], cuttofPassed }: AnnouncementsAndAlertsProps) => {

  return <>
    {cuttofPassed &&
      <div className='cuttof-passed mt-1'>
        <div className='alert d-flex align-items-center container my-0' role='alert' style={{ padding: '17px' }}>
          <i className='fas fa-exclamation-triangle mr-3' aria-hidden='true' style={{ fontSize: '1.2rem' }}></i>
          <div>Sorry, Unable to accept any more orders for this delivery because the cutoff has passed.</div>
        </div>
      </div>}
    {/* Announcements Section */}
    {!isEmpty(announcements) ?
      <div className='menu-detail-announcements pt-3'>
        {announcements?.map(announcement =>
          <div key={announcement?.id} className='pb-3 container d-flex align-items-top'>
            <i className='fas fa-bell mr-3' style={{ marginTop: '7px', fontSize: '1.2rem' }}></i>
            <div>
              <h5>{announcement?.title}</h5>
              {parse(announcement?.description)}
            </div>
          </div>
        )}
      </div> : ''
    }
  </>
}

export default AnnouncementsAndAlerts;