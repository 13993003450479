export type ObjectOptionalProperties<T, K extends keyof T> = {
  [P in K]?: T[P];
}

export enum CreditCardType {
  AMERICAN_EXPRESS = 'American Express',
  DISCOVER = 'Discover',
  MASTER_CARD = 'MasterCard',
  VISA = 'Visa',
}

export type CreditCard = {
  address_city: null | string;
  address_country: null | string;
  address_line1: null | string;
  address_line1_check: null | string;
  address_line2: null | string;
  address_state: null | string;
  address_zip: null | string;
  address_zip_check: string
  brand: CreditCardType;
  country: string
  cvc_check: string
  dynamic_last4: null | string;
  exp_month: number
  exp_year: number
  fingerprint: null | string;
  funding: string
  id: string
  last4: string
  metadata: any
  name: string
  object: string
  tokenization_method: null | string;
}