import i18n from "i18next";

export const dashboardTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Delivery Date & Time": "Delivery Date & Time",
      "Logout": "Logout",
      "Lunch": "Lunch",
      "Dinner": "Dinner",
      "Breakfast": "Breakfast",
      "Cutoff": "Cutoff",
      "Delivery Time": "Delivery Time",
      "Style": "Style",
      "Orders": "Orders",
      "Avg. Per Meal": "Avg. Per Meal",
      "Total": "Total",
      "Update Meeting Details": "Update Delivery Details",
      "Cancel Delivery": "Cancel Delivery",
      "Share": "Share",
      "Cutoff on": "Cutoff on",
      "Show More Orders": "Show More Orders",
      "Modify": "Modify",
      "Remove": "Remove",
      "Schedule a Meeting": "Schedule a Meeting",
      "Schedule Meeting": "Schedule Meeting",
      "Schedule New Delivery": "<i class=\"fas fa-plus-circle\"></i> New Delivery",
      "Schedule a meeting to get started!": "Schedule a meeting to get started!",
      "care": "We take tremendous care to curate delicious meals from our hand-picked restaurants. As a part of that process, we gather a few tidbits of information about your meeting.",
      "No meetings scheduled. Please schedule a meeting to see your curated menu.": "No meetings scheduled. Please schedule a meeting to see your curated menu.",
      "Show Fewer Orders": "Show Fewer Orders",
      "customfields": "We understand your company is unique, so we've put in some custom fields to accompany this meeting. These can be updated later.",

      "We are missing some required specifics for this meeting.": "We are missing some required specifics for this meeting.",
      "Additional Details": "Additional Details",
      "Delivery Name": "Delivery Name",
      "Required": "Required",
      "Delivery name can't be empty.": "Delivery name can't be empty.",
      "Location": "Location",
      "Per Meal Budget": "Per Meal Budget",
      "Approximate Count": "Approximate Count",
      "Buffet Style": "Buffet Style",
      "Minimum count should be 25.": "Minimum count should be 25.",
      "Count can't be empty.": "Count can't be empty.",
      "Meal Type": "Meal Type",
      "Cuisine Preference": "Cuisine Preference",
      "You have to select atleast one cuisine.": "You have to select atleast one cuisine.",
      "Please Select One": "Please Select One",
      "Special Requests": "Special Requests",

      "Cancel Delivery.": "Cancel Delivery",
      "will be cancelled for this meeting.": "will be cancelled for this meeting.",
      "Unable to Cancel Delivery": "Unable to Cancel Delivery",
      "Since it is within 24 hours of delivery, we are unable to cancel this meeting.": "Since it is within 24 hours of delivery, we are unable to cancel this meeting.",
      "for any urgent requests.": "for any urgent requests.",
      "Reason for Cancellation": "Reason for Cancellation",
      "First name can't be empty.": "First name can't be empty.",
      "Rewill be cancelled for this meeting.quired": "will be cancelled for this meeting.",
      "Prefer different vendor, meeting rescheduled, etc": "Prefer different vendor, meeting rescheduled, etc",
      "Pending Meeting": "Pending Meeting",
      "Update Meeting": "Update Delivery",
      "Begin Order":"Begin Order",
      "We're working on a delicious menu for this meeting and let you know as soon as its ready.": "We're working on a delicious menu for this meeting and let you know as soon as its ready.",
      "Please email": "Please email",
      "Following list of users have been invited to order for this meeting.": "Following list of users have been invited to order for this meeting.",
      "Invited Users": "Invited Users",
      "Resend Invitation": "Resend Invitation",
      "Remaining Budget": "Remaining Budget",
      "View All Items": "View All Items",
      "Based on Your Preferences": "Based on your preferences",
      "We have selected the following dishes based on your dietary restrictions and preferences.": "We have selected the following dishes based on your dietary restrictions and preferences.",
      "Update preferences »": "Update preferences »",
      "Unable to make further changes to this delivery due to cutoff being reached on": "Unable to make further changes to this delivery due to cutoff being reached on",
      "Order Fields" : "Order Fields"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Logout": "لاگ آوٹ",
      "Lunch": "لنچ",
      "Dinner": "ڈنر",
      "Breakfast": "ناشتہ",
      "Cutoff": "منقطع",
      "Delivery Time": "ترسیل کا وقت",
      "Style": "انداز",
      "Orders": "احکامات",
      "Avg. Per Meal": "اوسط فی کھانے",
      "Total": "کل",
      "Update Meeting Details": "میٹنگ کی تفصیلات کو اپ ڈیٹ کریں",
      "Cancel Delivery": "میٹنگ منسوخ کریں",
      "Share": "بانٹیں",
      "Cutoff on": "کٹ آف",
      "Show More Orders": "مزید احکامات دکھائیں",
      "Modify": "ترمیم کریں",
      "Remove": "دور",
      "Schedule a Meeting": "میٹنگ کا نظام الاوقات",
      "Schedule Meeting": "شیڈول میٹنگ",
      "Schedule a meeting to get started!": "شروع کرنے کے لئے میٹنگ کا نظام الاوقات بنائیں!",
      "We take tremendous care to curate delicious meals from our hand-picked restaurants. As a part of that process, we gather a few tidbits of information about your meeting.": "ہم اپنے ہاتھوں سے چننے والے ریستورانوں سے مزیدار کھانوں کی تیاری کے لئے بے حد احتیاط کرتے ہیں۔ اس عمل کے ایک حصے کے طور پر ، ہم آپ کی ملاقات کے بارے میں معلومات کے چند گوشے اکٹھا کرتے ہیں۔",
      "No meetings scheduled. Please schedule a meeting to see your curated menu.": "کوئی میٹنگ طے نہیں ہے۔ براہ کرم اپنا تیار کردہ مینو دیکھنے کے لئے میٹنگ کا شیڈول بنائیں۔",
      "Show Fewer Orders": "کم احکامات دکھائیں",
      "We understand your company is unique, so weve put in some custom fields to accompany this meeting. These can be updated later.": "ہم سمجھتے ہیں کہ آپ کی کمپنی انوکھی ہے ، لہذا اس میٹنگ کے ساتھ ہم نے کچھ کسٹم فیلڈز لگائے ہیں۔ یہ بعد میں اپ ڈیٹ ہوسکتے ہیں۔",

      "We are missing some required specifics for this meeting.": "ہم اس میٹنگ کے لئے کچھ مطلوبہ تفصیلات سے محروم ہیں۔",
      "Meeting Specifics": "ملاقات کی تفصیلات",
      "Meeting Name": "ملاقات کا نام",
      "Meeting name can't be empty.": "میٹنگ کا نام خالی نہیں ہوسکتا۔",
      "Location": "مقام",
      "Per Meal Budget": "فی کھانے کا بجٹ",
      "Approximate Count": "لگ بھگ گنتی",
      "Buffet Style": "بوفی انداز",
      "Minimum count should be 25.": "کم سے کم گنتی 25 ہونی چاہئے۔",
      "Count can't be empty.": "گنتی خالی نہیں ہوسکتی ہے۔",
      "Meal Type": "کھانے کی قسم",
      "Cuisine Preference": "کھانے کی ترجیح",
      "You have to select atleast one cuisine.": "آپ کو کم از کم ایک کھانا منتخب کرنا ہوگا۔",
      "Please Select One": "براہ کرم ایک کو منتخب کریں",
      "Special Requests": "خصوصی درخواستیں",

      "Cancel Delivery.": "میٹنگ منسوخ کریں",
      "will be cancelled for this meeting.": "اس میٹنگ کے لئے منسوخ کردیا جائے گا۔",
      "Unable to Cancel Delivery": "میٹنگ منسوخ کرنے سے قاصر",
      "Since it is within 24 hours of delivery, we are unable to cancel this meeting.": "چونکہ یہ 24 گھنٹے کی فراہمی کے اندر ہے لہذا ہم اس میٹنگ کو منسوخ کرنے سے قاصر ہیں۔",
      "for any urgent requests.": "کسی بھی فوری درخواست کے لئے۔",
      "Reason for Cancellation": "منسوخی کی وجہ",
      "First name can't be empty.": "پہلا نام خالی نہیں ہوسکتا۔",
      "Rewill be cancelled for this meeting.quired": " اس میٹنگ کے لئے دوبارہ منسوخ کیا جائے گا۔",
      "Prefer different vendor, meeting rescheduled, etc": "مختلف وینڈر کو ترجیح دیں ، میٹنگ میں شیڈول شیڈول وغیرہ",
      "Pending Meeting": "زیر التواء اجلاس",
      "Update Meeting": "اپ ڈیٹ میٹنگ",
      "Begin Order":"آرڈر شروع کریں",
      "We're working on a delicious menu for this meeting and let you know as soon as its ready.": "ہم اس میٹنگ کے ل a مزیدار مینو پر کام کر رہے ہیں اور اس کے تیار ہوتے ہی آپ کو بتا دیں۔",
      "Please email": "برائے مہربانی ای میل کریں",
      "Following list of users have been invited to order for this meeting.": "اس میٹنگ کا حکم دینے کے لئے صارفین کی مندرجہ ذیل فہرست کو طلب کیا گیا ہے۔",
      "Invited Users": "مدعو صارفین",
      "Resend Invitation": "دعوت نامہ دوبارہ بھیجیں",
      "Remaining Budget": "باقی بجٹ",
      "View All Items": "تمام احکامات دیکھیں",
      "Delivery Date & Time": "ترسیل کی تاریخ اور وقت",
      "Based on Your Preferences": "آپ کی ترجیحات پر مبنی",
      "We have selected the following dishes based on your dietary restrictions and preferences.": "ہم نے آپ کی غذائی پابندی اور ترجیحات کی بنیاد پر درج ذیل پکوانوں کا انتخاب کیا ہے۔",
      "Update preferences »": "ترجیحات کو اپ ڈیٹ کریں »",
      "Unable to make further changes to this delivery due to cutoff being reached on": "کٹ آف ہونے کی وجہ سے اس ترسیل میں مزید تبدیلیاں کرنے سے قاصر ہے",
      "Order Fields": "آرڈر فیلڈز"
    });
};