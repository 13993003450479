export enum UserType {
  ADMIN = 'company_admin',
  MANAGER = 'company_manager',
  USER = 'company_user',
  UNSUBSIDIZED_USER = 'unsubsidized_user'
}

export type Image = {
  url: null | string,
  medium: {
    url: null | string
  },
  thumb: {
    url: null | string
  }
}

export type UserHeaders = {
  Accept: string;
  'Accept-Language': string;
  'Access-Control-Allow-Origin': string;
  'Content-Type': string;
  'access-token': string;
  client: string;
  expiry: string;
  'token-type': string;
  uid: string;
}

export type LocalStorageCompany = Company & {
  customer?: boolean;
  display_intercom?: boolean;
  enable_saas?: boolean;
}

export type Company = {
  budget: string | number;
  buffet_addons_markup: string | number; // Unused
  copay_amount: string | number;
  enable_grouping_orders: boolean;
  enable_marketplace: boolean;
  enable_referral_factory: boolean;
  id: number;
  image: Image;
  latitude: number;
  longitude: number;
  markup: string | number;
  name: string;
  parent_company_id: null | string | number; // Unused
  reduced_markup: number;
  reduced_markup_check: boolean;
  show_remaining_budget: boolean;
  time_zone: string;
  user_copay: 1 | 0;
  user_meal_budget: string;
  self_signup?: boolean;
  enterprise_status?: string;
}

export type User = {
  address_id: number | null;
  allow_admin_to_manage_users: boolean;
  customer: boolean; // Not used anymore (Unused)
  cutoff_day_breakfast_reminder: boolean;
  cutoff_day_dinner_reminder: boolean;
  cutoff_day_lunch_reminder: boolean;
  cutoff_hour_breakfast_reminder: boolean;
  cutoff_hour_dinner_reminder: boolean;
  cutoff_hour_lunch_reminder: boolean;
  admin_cutoff_hour_lunch_reminder: boolean;
  admin_cutoff_hour_dinner_reminder: boolean;
  admin_cutoff_hour_breakfast_reminder: boolean;
  admin_cutoff_day_breakfast_reminder: boolean;
  admin_cutoff_day_lunch_reminder: boolean;
  admin_cutoff_day_dinner_reminder: boolean;
  email: string;
  first_name: string;
  id: number;
  status : string;
  last_sign_in_at: string | null;
  last_name: string;
  menu_ready_email: boolean;
  name: string;
  office_admin_id: null | string | number; // Unused
  phone_number: string | null;
  sms_notification: boolean;
  stripe_user_id: null | string | number; // Unused
  user_slack_id: string | null;
  survey_mail: boolean;
  time_zone: string;
  time_zone_offset: number; // Unused
  user_type: string;
  cutoff_hour_before_sms: boolean;
  cutoff_day_before_sms: boolean;
  email_verified: boolean;
  ss_user: boolean;
}

// POST => loginUser Action (/auth/sign_in)

export type LoginUser = {
  data: {
    user: User;
    company: Company;
  }
}

// POST => adminlogin Action (/auth/sign_in)

export type Adminlogin = {
  data: {
    user: User;
    company: Company;
  }
}

// DELETE => logoutUser Action (/auth/sign_out)

export type LogoutUser = {
  success: boolean;
}

// POST => resetPasswordLink Action (/auth/password?email=${email}&redirect_url=${process.env.REACT_APP_REDIRECT_URL})

export type ResetPasswordLink = {
  success?: boolean;
  message?: string;
  message_heading?: string;
  message_text?: string;
}

// PUT => resetPassword Action (/auth/password?password=${password}&password_confirmation=${confirmPassword})

export type ResetPassword = {
  success: boolean;
  data: User;
  message: string;
}

// GET => unlockUserAccount Action (/auth/password/edit?config=default&unlock_token=${token})

export type UnlockUserAccount = {
  message: string;
  success: boolean;
}

