import React, { CSSProperties, FC } from 'react';

type BackdropProps = {
    onClick: () => void;
    backdropStyles?: CSSProperties
}

const Backdrop: FC<BackdropProps> = ({ onClick, backdropStyles = {} }) => {

    return <div className='custom-backdrop' style={backdropStyles} onClick={e => { e.stopPropagation(); onClick() }}></div>
}
export default Backdrop;