// Pacakges
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// React JSX Components
import DeliveryDatesCalendar from './DeliverDatesCalendar';
import CustomPopUp from '../../../Common/Popups/CustomPopUp';
// Utilities
import store from '../../../store';
import ShoppingCart from '../SVGs/ShoppingCart.svg';
import { getZonedTime } from '../../../Utilities/functions';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
// Types
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { ShareMeetingPopUpRef } from '../../../Common/Popups/ShareMeetingPopUp';
import { Delivery, DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import isEmpty from '../../../Utilities/is_empty';
import { EMPTY_CART, SET_CONFIRM_ORDER_POPUP_STATE } from '../../../Actions/types';

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { getBillingDetails, getProfile } from '../../../Actions/profileActions';
import { useDispatch } from 'react-redux';
import { getCartAfterLogin } from '../../../Actions/cartActions';
import { getFutureDateWithTimeZone } from '../../../Utilities/getDateForMarketplace';
// import { getCartAfterLogin } from '../../../Actions/cartActions';
// MUI
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CutoffDisplay from './CutoffDisplay';


type DeliveryDetailsSectionProps = {
  currentDelivery: Delivery;
  isMarketPlacePreview?: boolean;
  parentComponentRef: any;
  companyTimeZone: string;
  isInvitedMeeting?: boolean;
  fromDashboard?: boolean;
  fromMarketplace?: boolean;
  fromPublicMarketPlace?: boolean;
  redirectToNewDelivery?: (delivery: DeliveryOnSingleDate, date: Date | string) => void;
  backToMarketplace?: () => void;
  hideCartOnHeader?: () => void;
  divPositionCheck?:boolean;
  openCancelMeetingPopUp?: () => void;
  yourOrderHeaderPaddingCheck?: boolean;
  resetSearchBar?: () => void;
}
const DeliveryDetailsSection = ({
  currentDelivery,
  redirectToNewDelivery,
  isMarketPlacePreview = false,
  companyTimeZone,
  isInvitedMeeting = false,
  parentComponentRef,
  fromDashboard = false,
  fromMarketplace = false,
  fromPublicMarketPlace = false,
  divPositionCheck,
  backToMarketplace,
  hideCartOnHeader,
  openCancelMeetingPopUp,
  yourOrderHeaderPaddingCheck,
  resetSearchBar,
}: DeliveryDetailsSectionProps) => {

  const history = useHistory();
  const dispatch: any = useDispatch()
  // getBillingDetails
  const { i18n: { i18n = null } = {}, users: { delivery_dates = {} } = {}, schedules_details: { single_schedule_loading = false } = {}, order } = useSelector((state: ReduxRootState) => state) || {};
  const { date: selectedDeliveryDateParam } = useParams<{ date: string, meetingSlug: string }>()
  const orderTotalForSelfSignUp = order.self_signup_delivery_orders_details?.orders?.reduce((orderTotal, order) =>
    orderTotal + order?.quantity, 0
  )
  const groupTagsArray = order.delivery_orders_details?.orders;
  const groupTags = groupTagsArray?.map((val) => {
      return val.group
  })
  const finalGrouptags = new Set(groupTags)
  const uniqueGroups:any = []
  finalGrouptags.forEach((val)=>{
    if(!isEmpty(val)){
      uniqueGroups.push(val)
    }
  })
  const screenWidth = window.document.body.clientWidth;
  const { shareMenu, cutOffPassed, meetingPopup: deliveryDetailPopup } = parentComponentRef || {};
  const { isCompanyAdmin, isCompanyManager,loggedInUserCompany, isUnsubsidizedUser } = getloggedInUser();
  const Self_signUp = loggedInUserCompany?.self_signup

  const shareMeetingPopupRef = shareMenu as ShareMeetingPopUpRef;

  const [isOpenCalendarPopUp, setIsOpenCalendarPopUp] = useState<boolean>(false);
  const [isOpenSingleDateDeliveriesDropdown, setIsOpenSingleDateDeliveriesDropdown] = useState<boolean>(false);
  const [hoveredDeliveryId, setHoveredDeliveryId] = useState<number | null>(null);
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');

  //Public marketplace cart info.
  const CartSummary =   useSelector((state: ReduxRootState) => state.cart.summary);
  const CartDetails =   useSelector((state: ReduxRootState) => state.cart.details);
  const ServerCart = useSelector((state: ReduxRootState) => state.server_cart.cart_at_login);
  const CartItemsCount = useSelector((state: ReduxRootState) => state.cart.items);
  
  const cartSummaryItemsCount = CartItemsCount?.reduce(
    (total, item) => total + item?.quantity,
    0
  );

  const deliveryChangeHandler = (newDelivery: DeliveryOnSingleDate) => {
    setIsOpenSingleDateDeliveriesDropdown(false);
    setLandonDelivery(true)
    redirectToNewDelivery?.(newDelivery, selectedDeliveryDateParam)
    history.push(`/menu/${dayjs(newDelivery.delivery_at).format('M-D-YYYY')}/${newDelivery?.slug}`);
  }

  const createOrUpdateDelivery = () => {
    if (isEditableDelivery && deliveryDetailPopup.openModal) {
      (fromMarketplace && isMarketPlacePreview) ?
        deliveryDetailPopup.openModal('', 'create') :
        deliveryDetailPopup.openModal(currentDelivery?.slug, currentDelivery?.status)
    }
  }

  const onDateChange = (newDelivery: DeliveryOnSingleDate, date: Date) => {
    setIsOpenCalendarPopUp(false);
    setLandonDelivery(true)
    redirectToNewDelivery?.(newDelivery, date);
  }

  //To Hide Cart from Header
  useEffect(() => {
    hideCartOnHeader?.()
    // eslint-disable-next-line
  }, [])

  // To Add Custom icons for Month Change
  useEffect(() => {
    if (isOpenCalendarPopUp) {
      const calendarNavBar = document.querySelector('.DayPicker-NavBar');
      const prevMonthIcon = calendarNavBar && calendarNavBar.querySelector('.DayPicker-NavButton--prev') as HTMLElement;
      const nextMonthIcon = calendarNavBar && calendarNavBar.querySelector('.DayPicker-NavButton--next') as HTMLElement;
      if (calendarNavBar && prevMonthIcon && nextMonthIcon) {
        prevMonthIcon.classList.add('fas', 'fa-chevron-left');
        nextMonthIcon.classList.add('fas', 'fa-chevron-right');
        prevMonthIcon.style.backgroundImage = 'none';
        nextMonthIcon.style.backgroundImage = 'none';
      }
    }
    setCurrentUrl(window.location.pathname.split('/'))
  }, [isOpenCalendarPopUp]);

  const formattedSelectedDeliveryDate = dayjs(selectedDeliveryDateParam, 'M-D-YYYY')?.format('YYYY-MM-DD')
  const currentDateDeliveries = delivery_dates[formattedSelectedDeliveryDate] || [];
  const isFutureDelivery = dayjs(currentDelivery.delivery_at).isAfter(new Date());
  const isEditableDelivery = isCompanyAdmin && (fromMarketplace || isFutureDelivery);
  const isBuffetDelivery = currentDelivery?.menu_type === 'buffet';
  const currentDeliveryCutOff = (fromPublicMarketPlace || isCompanyAdmin) ? currentDelivery?.admin_cutoff_at : currentDelivery?.cutoff_at;

  // const currentDeliveryCutOffPassedForSD = (fromPublicMarketPlace || isCompanyAdmin) ? (currentDelivery?.sd_admin_cutoff_at ?? '') : (currentDelivery?.sd_cutoff_at ?? '');

  const currentDeliveryCutOffForSameDay = (isCompanyAdmin) ? currentDelivery?.sd_admin_cutoff_at : currentDelivery?.sd_cutoff_at;
  let sameDayIsActive = false
  let sameDayIsPresent = false
  let bothSchedulersActive = false
  let allCutoffsPassedForScheduler = false
  if (dayjs(currentDeliveryCutOff).isBefore(new Date()) && dayjs(currentDeliveryCutOffForSameDay).isBefore(new Date())) {
    allCutoffsPassedForScheduler = true
  }

  if (currentDelivery?.same_day) {
    sameDayIsPresent = true
  }
  // let normalSchedularPassedAndSameDaySchedulerPendingActivation = false
  // if (dayjs(currentDeliveryCutOff).isBefore(new Date()) && dayjs(currentDelivery?.sd_activation_at).isAfter(new Date())) {
  //   normalSchedularPassedAndSameDaySchedulerPendingActivation = true
  // }

  if (sameDayIsPresent && dayjs(currentDelivery?.sd_activation_at).isBefore(new Date()) && dayjs(currentDeliveryCutOff).isAfter(new Date())) {
    bothSchedulersActive = true
  } else {
    if (currentDelivery?.sd_activation_at && dayjs(currentDelivery?.sd_activation_at).isBefore(new Date()) && dayjs(currentDeliveryCutOffForSameDay).isAfter(new Date())) {
      sameDayIsActive = true
    }
  }

  // const normalCutoffPassedForUser = dayjs(currentDelivery?.cutoff_at).isBefore(new Date()) ?? true
  // const normalCutoffPassedForAdmin = dayjs(currentDelivery?.admin_cutoff_at).isBefore(new Date()) ?? true
  // const sdCutoffPassedForUser = dayjs(currentDelivery?.sd_cutoff_at).isBefore(new Date()) ?? true
  // const sdCutoffPassedForAdmin = dayjs(currentDelivery?.sd_admin_cutoff_at).isBefore(new Date()) ?? true


  // const allCutoffsPassed = normalCutoffPassedForUser && normalCutoffPassedForAdmin && sdCutoffPassedForUser && sdCutoffPassedForAdmin


  const { item_name, more_items = 0, quantity } = currentDelivery?.your_order || {};
  const [currentUrl, setCurrentUrl] = useState<any>(window.location.pathname.split('/'))

  const [ landonDelivery, setLandonDelivery] = useState<boolean>(true)
  
  const selectedCartDeliveryData = currentDateDeliveries?.find((delivery) => {
    return delivery?.slug === currentDelivery.slug
  })
  const orderCreated = useSelector((state: ReduxRootState) => state?.order?.orderCreated);
  const confirmModal = useSelector((state: ReduxRootState) => state?.order?.isOrderConfirmModalOpen);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if(tooltip === false) {
      document.body.style.overflow = 'unset';
      store.dispatch({ type: SET_CONFIRM_ORDER_POPUP_STATE, payload: true });
    } else if(currentDelivery?.total_quantity){
      // Scroll to top
      window.scroll(0,0)
      // Disable background scroll
      document.body.style.overflow = 'hidden';
    }
    // eslint-disable-next-line
  }, [orderCreated, confirmModal, tooltip])

  useEffect(() => {
    if (Self_signUp) {
      //now slug is not optional so calls without slugs are stopped and optional slug in api call is also changed 
      //Self_signUp && CartDetails?.deliveryDate && dispatch(getCartAfterLogin(CartDetails.deliveryDate))
      !isEmpty(CartDetails) && parentComponentRef.setState({ showCart: true })
      store.dispatch({type: EMPTY_CART})
    }
    // eslint-disable-next-line
  }, [])

  const onVisibleChange = (visible:any) => {
    store.dispatch({ type: SET_CONFIRM_ORDER_POPUP_STATE, payload: true });
    setTooltip(visible);
  }
  const preventDefault= (e:any) => {
    store.dispatch({ type: SET_CONFIRM_ORDER_POPUP_STATE, payload: true });
    setTooltip(false)
    e.preventDefault();
  }
  const openSideBar = () => {
    store.dispatch({ type: SET_CONFIRM_ORDER_POPUP_STATE, payload: true });
    setTooltip(false)
    parentComponentRef.setState({ showDeliveryOrdersSidebar: true })
  }
  const billingDetailsOfUser = () => {
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    parentComponentRef.setState({ showCart: true })
    // const formattedDate = `${dayjs(selectedDeliveryDateParam).format('YYYY-MM-DD')}T${dayjs(selectedDeliveryDateParam).format('HH:mm')}`
    // Self_signUp && dispatch(getCartAfterLogin(formattedDate))
    if (!isEmpty(headers)){
      currentDelivery?.status === "pending" && dispatch(getBillingDetails())
    }
  }

  const CartDetailsOfUser = () => {
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    setLandonDelivery(false)
    parentComponentRef.setState({ showCart: true })
    if (!isEmpty(headers)) {
      // get Cart API only called for ss user
      if (loggedInUserCompany?.self_signup === true) {
        dispatch(getCartAfterLogin(currentDelivery.delivery_at, currentDelivery?.slug))
        currentDelivery?.status === "pending" && dispatch(getBillingDetails())
      }
    }
  }

  const backToMarketPlaceLocal = () => {
    backToMarketplace && backToMarketplace()
    // resetSearchBar && resetSearchBar()
  }

  const inviteGuestsAndCancelButtonsClasses = `btn btn-sm text-nowrap mr-2 ${screenWidth > 1350 ? '' : ''} ${cutOffPassed?.() ? 'disabled-opacity' : 'cursor-pointer'} `
  const inviteGuestsCancelAndEditMeetingButtons = <>
    <>
    {isCompanyAdmin &&
        <button
          data-tip data-for={'cancel-delivery-tooltip'}
          className={`btn btn-outline-danger cancel-delivery ${inviteGuestsAndCancelButtonsClasses}`}
          type='button'
          // onClick={() => { cancelMeeting?.openModal() }}
          onClick={() => openCancelMeetingPopUp && openCancelMeetingPopUp()}
        >
          <i className='fas fa-calendar-times fa-lg'></i>
          {/* {i18n && i18n.t('Cancel Delivery')} */}
          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
            <ReactTooltip place={yourOrderHeaderPaddingCheck ? 'bottom' : 'top'} type='dark' id='cancel-delivery-tooltip' effect='solid' className={`react-tooltip ${yourOrderHeaderPaddingCheck && 'react-tooltip-sticky-header-margin-top'} `} >
              <span>
                Cancel Delivery
              </span>
            </ReactTooltip>
          }
        </button>}
      {!isBuffetDelivery &&
        <button id={`ac-admin-step-4`}
          data-tip data-for = {'invite-guest-tooltip'}
          className={`invite-guests share-meeting-btn ${inviteGuestsAndCancelButtonsClasses} `}
          onClick={() => currentDelivery && shareMeetingPopupRef?.openModal(currentDelivery)}
        >
          {/* <i className='fas fa-share-square' ></i> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17.6" viewBox="0 0 16 17.6">
          <path id="add_user" data-name="add user" d="M14.2,5a4,4,0,1,1-4-4A4,4,0,0,1,14.2,5ZM8.6,10.6A5.6,5.6,0,0,0,3,16.2a2.4,2.4,0,0,0,2.4,2.4h5.927a.471.471,0,0,0,.363-.718,4.8,4.8,0,0,1,1.358-6.415.41.41,0,0,0-.081-.745A5.625,5.625,0,0,0,11.8,10.6Zm7.2,1.6a.8.8,0,0,1,.8.8v1.6h1.6a.8.8,0,0,1,0,1.6H16.6v1.6a.8.8,0,0,1-1.6,0V16.2H13.4a.8.8,0,1,1,0-1.6H15V13A.8.8,0,0,1,15.8,12.2Z" transform="translate(-3 -1)" fill="#167ffb" fillRule="evenodd"/>
        </svg>
          {/* {i18n && i18n.t('Invite Guests')} */}
          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
            <ReactTooltip place={yourOrderHeaderPaddingCheck ? 'bottom' : 'top'} type='dark' id='invite-guest-tooltip' effect='solid' className={`react-tooltip ${yourOrderHeaderPaddingCheck && 'react-tooltip-sticky-header-margin-top'} `} >
              <span>
                Invite Guests
              </span>
            </ReactTooltip>
          }
        </button>
       }
     
    </>

      {currentDelivery && isEditableDelivery && !Self_signUp && <button
        data-tip data-for={'edit-scheduler-tooltip'}
          // className={`${(isEditableDelivery && !Self_signUp) ? 'cursor-pointer' : 'disable-click'} text-nowrap overflow-hidden text-truncate fs-16 m-0 text-gray-600 fw-normal d-none d-md-block`}
          className={`edit-delivery cancel-delivery btn btn-sm text-nowrap mr-2 cursor-pointer`}
          type='button'
          onClick={() => !Self_signUp && createOrUpdateDelivery()}
        >
          {/* {fromPublicMarketPlace && !Self_signUp ? "New Delivery" : currentDelivery?.runningmenu_name} */}
            <span><i className='fas fa-edit'></i></span>
          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
            <ReactTooltip place={yourOrderHeaderPaddingCheck ? 'bottom' : 'top'} type='dark' id='edit-scheduler-tooltip' effect='solid' className={`react-tooltip ${yourOrderHeaderPaddingCheck && 'react-tooltip-sticky-header-margin-top'} `} >
              <span>
                Edit Delivery
              </span>
            </ReactTooltip>
          }
        </button>
        }
    
  </>

  useEffect(() => {
    if (currentDelivery.delivery_at && currentDelivery?.slug) {
      const headers = JSON.parse(localStorage.getItem('headers') || '{}')
      setLandonDelivery(false)
      if (!isEmpty(headers)) {
        // get Cart API only called for ss user
        if (loggedInUserCompany?.self_signup === true) {
          dispatch(getCartAfterLogin(currentDelivery.delivery_at, currentDelivery?.slug))
          currentDelivery?.status === "pending" && dispatch(getBillingDetails())
        }
      }
    }
    // eslint-disable-next-line
  }, [currentDelivery.slug])

  //Open Delivery order detail side bar
  const isOpenDeliveryOrderDetailSidebar = () => {
    currentDelivery?.your_order && parentComponentRef.setState({ showDeliveryOrdersSidebar: true })

    const { isCompanyManager, isCompanyUser, isUnsubsidizedUser } = getloggedInUser();
    if (isCompanyManager || isCompanyUser || isUnsubsidizedUser) {
      dispatch(getProfile())
    }
  }
  const StatusIndicator = (consumed: number, total: number) => {
    const percentage = ((total - consumed) / total) * 100;
    return percentage ?? 0
  };

  const subsidyValue = +(Number(+StatusIndicator(+currentDelivery?.remaining_budget, +currentDelivery?.per_meal_budget))?.toFixed(0))

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#DC3644' : '#308fe8',
    },
  }));

  return (!currentDelivery?.id || single_schedule_loading) && !fromMarketplace ? <DeliveryDetailsLoading isCompanyAdmin={isCompanyAdmin} /> : <>
    { Self_signUp || fromPublicMarketPlace ? <div className={`bg-white ${fromMarketplace && divPositionCheck && 'newdeliverypage-sticky hit-top'}`}>      
      <div style={{ paddingTop: !fromMarketplace ? (yourOrderHeaderPaddingCheck? '5px' :'89px') : '0px' }}>
        <div className='container d-flex flex-nowrap justify-content-between aling-items-center px-0 py-0 py-sm-3 top-bar' style={{ gridGap: '40px' }}>
          <div className='d-flex flex-wrap aling-items-center header-banner-mobile-view' style={{ gridGap: '40px' }}>
            {fromMarketplace && <div className='col-sm d-flex align-items-center px-0 cursor-pointer' onClick={() => backToMarketPlaceLocal()}>
              <span
                data-tip
                data-for='back-to-marketplace'
                className='icon-wrapper-back'
                style={{background:'#F5F5F5'}}
              >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="marketplace-background">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                  </svg>
                {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                  <ReactTooltip place='left' type='dark' id='back-to-marketplace' effect='solid' className={`react-tooltip ${divPositionCheck && 'react-tooltip-sticky-header-margin-top'} `}>
                    <span>Back To Marketplace</span>
                  </ReactTooltip>
                }
              </span>
              <p className='d-md-none fs-sm-14 fw-normal text-dark m-0 pl-2'>Back To Marketplace</p>
            </div>}
            {/* Delivery Date, Cuttoff Info and Calendar to Switch between Deliveries */}
            <div className={`${fromMarketplace ? 'remove-hover-effects meeting-details-mobile-view' : 'delivery-details-date'}`}>
            <div className='d-flex align-items-baseline align-items-sm-center'>
            <p onClick={() => !isInvitedMeeting && !fromMarketplace && setIsOpenCalendarPopUp(true)}
              className={`${(isInvitedMeeting || fromMarketplace) ? 'cursor-default mb-0' : 'cursor-pointer mb-0'} text-nowrap`}
            >
              <span className="icon-wrapper-large icon-wrapper-small">
                <img src="/imgs/SVGs/calendar.svg" alt="Delivery-Date" className='svg-dimensions-md d-block d-sm-none'/>
                <svg xmlns="http://www.w3.org/2000/svg" className='d-none d-md-block' fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
              </span>
            </p>
            <div className='d-flex d-md-block align-items-center pl-2 pl-md-3'>
                <p onClick={() => !isInvitedMeeting && !fromMarketplace && setIsOpenCalendarPopUp(true)}
                  className={`${(isInvitedMeeting || fromMarketplace) ? 'cursor-default' : 'cursor-pointer'} text-nowrap delivery-details-text fs-sm-14 text-dark m-0`}
                  id={`ac-${isCompanyAdmin ? 'admin' : 'user'}-step-1`}
                >
                  {fromMarketplace || fromPublicMarketPlace ? getFutureDateWithTimeZone(currentDelivery?.delivery_at, currentDelivery?.time_zone)
                  : getZonedTime(currentDelivery?.delivery_at, 'EEE. MMM dd h:mma, zzzz', currentDelivery?.time_zone)
                  }&nbsp;&nbsp;
                  {!fromPublicMarketPlace && !isInvitedMeeting && !fromMarketplace &&  <i className={`fas fa-chevron-down`}></i>}
                </p>
                {currentDeliveryCutOff &&
                <span className={`'text-nowrap ' ${dayjs(currentDeliveryCutOff).isAfter(new Date()) ? 'badge-outline-primary cursor-pointer' : 'badge-outline-danger cursor-pointer'}`}>
                  <span data-tip
                    data-for='menu-detail-page-cutoff'
                      // style={{ color: dayjs(currentDeliveryCutOff).isAfter(new Date()) ? '#007bff' : '#dc3545' }}
                      >
                        {i18n?.t('Cutoff ')}
                        {fromPublicMarketPlace || fromMarketplace ?
                          <React.Fragment>
                            {dayjs(currentDeliveryCutOff).isAfter(new Date()) ?
                              `in ${dayjs(`${dayjs(currentDeliveryCutOff).subtract(1, 'day').format('YYYY-MM-DD')}${'13:45'}`).toNow(true)}` :
                              `was ${dayjs(currentDeliveryCutOff).subtract(1, 'day').fromNow()}`}
                          </React.Fragment>
                          :
                          <React.Fragment>
                            {dayjs(currentDeliveryCutOff).isAfter(new Date()) ?
                              `in ${dayjs(currentDeliveryCutOff).toNow(true)}` : `was ${dayjs(currentDeliveryCutOff).fromNow()}`}
                          </React.Fragment>
                        }
                      </span>
                      {fromPublicMarketPlace || fromMarketplace ?
                        <React.Fragment>
                          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                            <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className={`react-tooltip ${(divPositionCheck || yourOrderHeaderPaddingCheck) && 'react-tooltip-sticky-header-margin-top'} `}>
                              {/* <span>{`${dayjs(currentDeliveryCutOff).subtract(1, 'day').format("ddd")} ${getZonedTime(`${dayjs(currentDeliveryCutOff).subtract(1, 'day').format(' MMM DD 13:45')}`, 'MMM dd h:mma, zzzz', currentDelivery?.time_zone ?? localAddress?.time_zone)}`}</span> */}
                              <span>{getFutureDateWithTimeZone(`${dayjs(currentDeliveryCutOff).subtract(1, 'day').format(' MMM DD 13:45')}`, currentDelivery?.time_zone)}</span>
                            </ReactTooltip>}
                        </React.Fragment> :
                        <React.Fragment>
                          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                            <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className={`react-tooltip ${(divPositionCheck || yourOrderHeaderPaddingCheck) && 'react-tooltip-sticky-header-margin-top'} `}>
                              <span>{getZonedTime(currentDeliveryCutOff, 'EEE. MMM dd h:mma, zzzz', currentDelivery?.time_zone)}</span>
                            </ReactTooltip>
                          }
                        </React.Fragment>
                      }                 
                </span>}
            </div>
            </div>
            
            
            {!isInvitedMeeting && !fromPublicMarketPlace && !fromMarketplace &&
              <CustomPopUp
                onPopUpClose={() => setIsOpenCalendarPopUp(false)}
                hideBackDrop={!isOpenCalendarPopUp}
                allowScrolling={!isOpenCalendarPopUp}
              >
                {(currentDelivery?.id || fromMarketplace) &&
                  <DeliveryDatesCalendar
                    hideCalendar={!isOpenCalendarPopUp}
                    deliveryDate={currentDelivery.delivery_at}
                    onDateChangeHandler={onDateChange}
                  />
                }
              </CustomPopUp>
            }
          </div>

          {/* Delivery Name, Address, Edit Delivery Details and List of All Delivries on a Single Date*/}
          <div className={`${fromMarketplace ? 'remove-hover-effects' : 'all-deliveries delivery-details-location cursor-pointer'} `}>
            <div className='d-flex'>
            <span className="mr-2 mr-md-3 mb-0 position-relative icon-wrapper-large icon-wrapper-small">
              <img src="/imgs/SVGs/location-icon.svg" alt="Delivery-location" className='svg-dimensions-md d-block d-md-none'/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='d-none d-md-block' width="32" height="32" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
              { !fromPublicMarketPlace && (currentDateDeliveries?.length > 0 ) &&
                <span className='badge badge-danger rounded-pill position-absolute icon-wrapper-badge-position'>{currentDateDeliveries?.length}</span>
              }
            </span>
            {/* <p className={`${fromDashboard ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => fromDashboard && setIsOpenSingleDateDeliveriesDropdown(true)}>
              {fromDashboard ? 'DELIVERIES' : 'DELIVERY'}
              {fromDashboard &&
              <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{currentDateDeliveries?.length}</span>
                <span className='all-deliveries-count'>
                  {currentDateDeliveries?.length}
                </span>
              }
            </p> */}
              <div className='d-flex flex-column justify-content-center'>
                  <p id={`ac-${isCompanyAdmin ? 'admin' : 'user'}-step-2`} className={`${fromDashboard ? 'cursor-pointer' : 'cursor-default'} text-nowrap overflow-hidden text-truncate delivery-details-text fs-sm-14 text-dark m-0`}
                  style={{ maxWidth: screenWidth > 1200 ? 'unset' : 300 }}
                  onClick={() => fromDashboard && setIsOpenSingleDateDeliveriesDropdown(true)}
                  >
                  {/* {fromPublicMarketPlace ? localAddress?.formatted_address : currentDelivery?.formatted_address}&nbsp;&nbsp; */}
                  {currentDelivery?.formatted_address ?? localAddress?.formatted_address}&nbsp;&nbsp;
                  {!fromPublicMarketPlace && fromDashboard && <i className='fas fa-chevron-down'></i>}
                  </p>
                  {currentDelivery &&
                    <p style={{ maxWidth: screenWidth > 1200 ? 'unset' : 335 }}
                      className={`${(isEditableDelivery && !Self_signUp)? 'cursor-pointer' : 'disable-click'} text-nowrap overflow-hidden text-truncate fs-16 m-0 text-gray-600 fw-normal d-none d-md-block`}
                      onClick={() => !Self_signUp && createOrUpdateDelivery() }
                    >
                    {fromPublicMarketPlace && !Self_signUp ? "New Delivery" : currentDelivery?.runningmenu_name}
                    {/* {isEditableDelivery && !Self_signUp &&
                      <i className='fas fa-edit ml-2' style={{ color: '#0089ff' }}></i>
                    } */}
                  </p>}
              </div>
            </div>
            {!fromPublicMarketPlace && !fromMarketplace && isOpenSingleDateDeliveriesDropdown && fromDashboard &&
              <CustomPopUp
                onPopUpClose={() => setIsOpenSingleDateDeliveriesDropdown(false)}
                popUpBodyClassName='invisible-scroll'
                popUpBodyStyles={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto', borderRadius: '17px' }}
              >
                {currentDateDeliveries?.map((delivery: DeliveryOnSingleDate, index: number) =>
                  <div key={delivery?.id}
                    className='d-flex align-items-center cursor-pointer all-deliveries-list-item'
                    style={{ borderTop: index > 0 ? '1px solid #E5E5E5' : 'none', backgroundColor: delivery.id === hoveredDeliveryId ? '#fbfbfb' : 'white', maxWidth: 450 }}
                    onClick={() => deliveryChangeHandler(delivery)}
                    onMouseEnter={() => setHoveredDeliveryId(delivery?.id)}
                    onMouseLeave={() => setHoveredDeliveryId(null)}
                  >
                    <i
                      className={`far fa-${(delivery?.id === currentDelivery?.id || delivery?.id === hoveredDeliveryId) ? 'check-circle' : 'circle'}`}
                      style={{ color: delivery?.id === currentDelivery?.id ? '#28A745' : '#8B9898', fontSize: 24 }}
                    >
                    </i>
                    <div className={`px-3 w-100`}>
                      <p>{delivery?.formatted_address}</p>
                      <p>{delivery?.runningmenu_name}  at {dayjs(delivery?.delivery_at).format('hh:mma')}</p>
                      {isCompanyAdmin && <>
                        <p>Delivery Instructions</p>
                        <p className='m-0 delivery-instructions'>{delivery?.delivery_instructions || 'None'}</p>
                      </>}
                    </div>
                  </div>
                )}
              </CustomPopUp>}
          </div>
          </div>

          {/*Delivery Order Details Section in case of both Buffet and Indiviual Delivery for Company Admins*/}
          {!fromPublicMarketPlace && !Self_signUp && isCompanyAdmin && fromDashboard &&
            <div>
              <div id={`ac-admin-step-3`} className='d-flex delivery-details cursor-default'>
                {!isBuffetDelivery ? <>
                  <div className='mx-1'>
                    <p className='text-nowrap'>ITEMS</p>
                    <p className='text-nowrap'>{currentDelivery?.total_quantity}</p>
                  </div>
                  <div className='mx-2'>
                    <p className='text-nowrap'>AVG / MEAL</p>
                    <p className='text-nowrap'>${currentDelivery?.avg_per_meal ?
                      Number(currentDelivery?.avg_per_meal).toFixed(2) : '00.00'}</p>
                  </div>
                </> :
                  <div className='mx-1 text-nowrap'>
                    <p className='text-nowrap'>PRICE PER HEAD</p>
                    <p>${currentDelivery?.price_per_head ? Number(currentDelivery?.price_per_head).toFixed(2) : '0.00'}</p>
                  </div>
                }
                <div className='mx-1'>
                  <p className='text-nowrap'>TOTAL</p>
                  <p className='text-nowrap'>
                    ${(+currentDelivery?.order_total + (currentDelivery.delivery_fee ? +currentDelivery.delivery_fee : 0)).toFixed(2)}
                    {/* <i className='fas fa-list-ul ml-2' style={{ color: '#0089ff' }}></i> */}
                  </p>
                </div>
              </div>
              {currentDelivery?.total_quantity ?
                <>
                  { orderCreated && !confirmModal ?
                  <>
                    <div>
                      <div id="spot-light" className="tooltip_wrapper"></div>
                      <Tooltip
                        visible={tooltip}
                        // visible= {orderCreated && !confirmModal}
                        animation="zoom"
                        placement="bottom"
                        onVisibleChange={onVisibleChange}
                        trigger="click"
                        overlay={
                        <div className="d-flex flex-column align-items-center justify-content-center py-3 my-1" style={{ width: "200px" }} >
                          <img src="/imgs/foodimage.svg" alt="" className='mb-1' />
                          <h4 className='mb-1'>New Order</h4>
                          <h6 className='font-weight-normal text-center'>Manage all your orders here.</h6>
                          <button className='btn btn-sm btn-primary align-self-center px-3 rounded' onClick={openSideBar}><strong>View</strong></button>
                        </div>
                        }
                      >
                        {/* eslint-disable-next-line */}
                        <a href="#" onClick={preventDefault}>View Details</a>
                      </Tooltip>
                    </div>
                  </>
                    :
                    <p
                    style={{ textDecoration: 'underline', color: '#147BFC' }}
                      className='m-0 link-style'
                      onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}>
                      View Details
                  </p>
                
                  }
                </>
                : ''
              }
            </div>
          }
          <>
            {currentDelivery?.status === "approved" ?
              <>
                {/* Self-SignUp view order details */}
                {Self_signUp && order?.self_signup_delivery_orders_details?.orders && (order?.self_signup_delivery_orders_details?.orders?.length !== 0) &&
                  <div className='d-flex delivery-details-cart shopping-cart'>
                    <span className="icon-wrapper-large position-relative shopping-bag">
                      <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                        <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644" />
                      </svg>
                      <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{orderTotalForSelfSignUp ?? 0}</span>
                    </span>
                    <div className='pl-3'>
                      <p className='cart-text-label m-0'> ${order?.self_signup_delivery_orders_details?.total} </p>
                      <span className="fs-16 text-decoration-none cursor-pointer" onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}>
                        View Details
                        {/* <span className='badge badge-primary rounded-circle ml-2'>1</span> */}
                      </span>
                    </div>
                  </div>
                }
              </>
              :
              <>
                {/* Public marketplace view cart */}
                {/* {(fromPublicMarketPlace || Self_signUp) &&
            <div className='d-flex delivery-details-cart shopping-cart'>
              <span className="icon-wrapper-large position-relative shopping-bag">
                <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                  <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644"/>
                </svg>
                <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{!isEmpty(ServerCart) ? (ServerCart.orders_quantity ?? 0) : (CartDetails?.itemCount ?? 0)}</span>
              </span>
              <div className='pl-3'>
                <p className='fs-18 fw-normal text-gray-600 m-0'> {`$${!isEmpty(ServerCart) ? (ServerCart.total ?? 0) : (CartSummary?.total ?? 0)}`} </p>
                { (!isEmpty(ServerCart) ? (ServerCart?.orders_quantity > 0) : (CartDetails?.itemCount > 0)) ?
                    <span className="fs-18 fw-normal text-decoration-none" onClick={billingDetailsOfUser}>
                    View Cart
                  </span>
                :
                  <span className="fs-18 fw-normal text-decoration-none">
                    Empty Cart
                  </span>
                }
              </div>
            </div>
          } */}
                <ToastContainer autoClose={3000} />
                {fromPublicMarketPlace && !Self_signUp && screenWidth > 440 &&
                  <div className={`d-flex  ${fromMarketplace ? 'remove-hover-effects' : 'delivery-details-cart shopping-cart'}`}>
                    <span className="icon-wrapper-large position-relative shopping-bag" onClick={() => CartDetails?.itemCount > 0 && billingDetailsOfUser()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                        <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644" />
                      </svg>
                      <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{cartSummaryItemsCount ?? 0}</span>
                    </span>
                    <div className='pl-3'>
                      <p className='m-0 cart-text-label'> {`$${CartSummary?.itemsSubtotal ?? 0}`} </p>
                      {CartDetails?.itemCount > 0 ?
                        <span className="text-nowrap text-decoration-none font-weight-normal cursor-pointer" style={{color:'#167FFB'}} onClick={billingDetailsOfUser}>
                          View Cart
                        </span>
                        :
                        <span className="text-nowrap text-decoration-none font-weight-normal">
                          Empty Cart
                        </span>
                      }
                    </div>
                  </div>
                }
                {Self_signUp && !fromPublicMarketPlace &&
                  <>
                    {(currentUrl[3] && !fromMarketplace && landonDelivery) ?
                      <div className='d-flex delivery-details-cart shopping-cart' onClick={() => selectedCartDeliveryData?.items_count && selectedCartDeliveryData?.items_count > 0 && CartDetailsOfUser()}>
                        <span className="icon-wrapper-large position-relative shopping-bag">
                          <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                            <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644" />
                          </svg>
                          <span className='badge badge-danger rounded-pill position-absolute icon-wrapper-badge-position'>{selectedCartDeliveryData?.items_count ?? 0}</span>
                        </span>
                        <div className='pl-3'>
                          <p className='cart-text-label m-0'> {`$${selectedCartDeliveryData?.orders_total ?? 0}`} </p>
                          {selectedCartDeliveryData?.items_count && selectedCartDeliveryData?.items_count > 0 ?
                            <span className="text-nowrap text-decoration-none cursor-pointer font-weight-normal" onClick={CartDetailsOfUser}>
                              View Cart
                            </span>
                            :
                            <span className="text-nowrap text-decoration-none font-weight-normal">
                              Empty Cart
                            </span>
                          }
                        </div>
                      </div>
                      :
                      <div className={`d-flex  ${fromMarketplace ? 'remove-hover-effects' : 'delivery-details-cart shopping-cart'}`}>
                        <span className="icon-wrapper-large position-relative shopping-bag" onClick={() => ServerCart?.orders_quantity > 0 && billingDetailsOfUser()}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                            <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644" />
                          </svg>
                          <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{ServerCart.orders_quantity ?? 0}</span>
                        </span>
                        <div className='pl-3'>
                          <p className='fs-18 fw-normal text-gray-600 m-0'> {`$${ServerCart.items_subtotal ?? 0}`} </p>
                          {ServerCart?.orders_quantity > 0 ?
                            <span className="fs-18 fw-normal text-decoration-none cursor-pointer" onClick={billingDetailsOfUser}>
                              View Cart
                            </span>
                            :
                            <span className="fs-18 fw-normal text-decoration-none">
                              Empty Cart
                            </span>
                          }
                        </div>
                      </div>
                    }
                  </>
                }
              </>
            }
          </>

          {/*Cancel and Invite Guests for Current Delivery for Company AdminsW*/}
          {!Self_signUp && isCompanyAdmin && !isMarketPlacePreview && fromDashboard &&
            <div className={`delivery-actions d-flex ${screenWidth > 1350 ? 'flex-row justify-content-center align-items-xl-start' : `flex-column`}`}
              style={{ flexDirection: screenWidth > 1350 ? 'row' : 'column' }}
            >
              {inviteGuestsCancelAndEditMeetingButtons}
            </div>
          }

          {/*Order Details Section in case of Indiviual Delivery for Company Users*/}
          {!isCompanyAdmin && !fromMarketplace &&
            <div className='d-flex justify-content-start flex-column' id={`ac-user-step-3`}>
              <div className={`${currentDelivery?.your_order ? 'cursor-pointer' : 'cursor-default'} user-orders`}
                onClick={() => currentDelivery?.your_order && parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}
              >
                <div className='d-flex justify-content-between align-items-center' style={{ minWidth: '200px' }}>
                  <p className='text-nowrap'>YOUR ORDER</p>
                  <>
                  { orderCreated && !confirmModal ?
                    <div>
                      <div id="spot-light" className="tooltip_wrapper_user"></div>
                      <Tooltip
                        visible={tooltip}
                        // visible= {orderCreated && !confirmModal}
                        animation="zoom"
                        placement="bottom"
                        onVisibleChange={onVisibleChange}
                        trigger="click"
                        overlay={
                        <div className="d-flex align-items-center justify-content-center flex-column py-3 my-1" style={{ width: "200px" }} >
                          <img src="/imgs/foodimage.svg" alt="" className='mb-1' />
                          <h4 className='mb-1'>New Order</h4>
                          <h6 className='font-weight-normal text-center'>Manage all your orders here.</h6>
                          <button className='btn btn-sm btn-primary align-self-center px-3 rounded' onClick={openSideBar}><strong>View</strong></button>
                        </div>
                        }
                      >
                        {/* {currentDelivery?.your_order && */}
                          <span className='ml-2 '>
                            {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                            <i className='fas fa-external-link-alt ml-1'></i>
                          </span>
                        
                      </Tooltip>
                    </div>
                    :
                    currentDelivery?.your_order &&
                      <span className='ml-2 '>
                        {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                        <i className='fas fa-external-link-alt ml-1'></i>
                      </span>
                  }
                </>
                  {/* {currentDelivery?.your_order &&
                    <span className='ml-2 '>
                      {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                      <i className='fas fa-external-link-alt ml-1'></i>
                    </span>
                  } */}
                </div>
                <>
                  {currentDelivery?.your_order ?
                    <>
                      <p className='user-first-order-name'>{quantity}
                        <i className='fas fa-times mx-1' style={{ fontSize: 'smaller' }}></i>
                        {item_name}
                      </p>
                      {!dayjs(currentDeliveryCutOff).isAfter(new Date()) && <p style={{ 'fontSize': '16px' }}>
                        Group(s) :
                        {uniqueGroups?.map((value: any, idx: any, arr: any) => {
                          if (idx === arr.length - 1) {
                            return '\t\t' + value
                          }
                          return '\t\t' + value + ','
                        })}
                      </p>
                      }
                    </>
                    :
                    <p style={{ color: '#6e6e6e', fontSize: 18 }}>
                      <img className='mr-2' width={27} height={27} src={ShoppingCart} alt='Empty Cart' />
                      No Orders Placed
                    </p>
                  }
                </>
              </div>
            </div>
          }

          {/*Invite Guests for Current Delivery for Company Managers*/}
          {isCompanyManager && fromDashboard &&
            <div className={`delivery-actions d-flex`}>
              {inviteGuestsCancelAndEditMeetingButtons}
            </div>
          }
        </div>
      </div>
    </div> 
    :
    <div className= {`bg-white ${fromMarketplace && divPositionCheck && 'newdeliverypage-sticky hit-top'}`}>
      <div style={{ paddingTop: !fromMarketplace ? (yourOrderHeaderPaddingCheck? '0' : '79px') : '0px' }}>
        <div className='container px-0 top-bar d-flex justify-content-center flex-nowrap py-4' style={{ gridGap: '40px' }}>
          <div className="d-flex" style={{ gridGap: '40px' }}>
            <div className="">
              {fromMarketplace && <div className='cursor-pointer' onClick={() => backToMarketPlaceLocal()}>
                <span
                  data-tip
                  data-for='back-to-marketplace'
                  className='icon-wrapper-back'
                  style={{background:'#F5F5F5'}}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="marketplace-background">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                  </svg> 
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                    <ReactTooltip place='left' type='dark' id='back-to-marketplace' effect='solid' className={`react-tooltip ${divPositionCheck && 'react-tooltip-sticky-header-margin-top'} `}>
                      <span>Back To Marketplace</span>
                    </ReactTooltip>
                  }
                </span>
              </div>}
            </div>

            {/* Delivery Date, Cuttoff Info and Calendar to Switch between Deliveries */}
          <div className='delivery-date d-flex flex-column'>
            <div className="d-flex">
            <span onClick={() => !isInvitedMeeting && setIsOpenCalendarPopUp(true)}
              className={`${isInvitedMeeting ? 'cursor-default icons-wrapper mx-0 ml-md-0 mr-lg-3' : 'icons-wrapper mx-0 ml-md-0 mr-lg-3 cursor-pointer'} text-nowrap`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 25 28.429">
                <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
              </svg>
            </span>
            <div>
              <p onClick={() => !isInvitedMeeting && setIsOpenCalendarPopUp(true)}
                className={`${isInvitedMeeting ? 'cursor-default' : 'cursor-pointer'} text-nowrap delivery-date-time-heading mb-1`}
                id={`ac-${isCompanyAdmin ? 'admin' : 'user'}-step-1`}
              >
                {fromMarketplace || fromPublicMarketPlace ? getFutureDateWithTimeZone(currentDelivery?.delivery_at, currentDelivery?.time_zone)
              : getZonedTime(currentDelivery?.delivery_at, 'EEE. MMM dd h:mma, zzzz', currentDelivery?.time_zone)
              }&nbsp;&nbsp;
                {!isInvitedMeeting && <i className={`fas fa-chevron-down`}></i>}
              </p>
                    {isCompanyAdmin === true ? (
                      <>
                        {sameDayIsPresent === false ? (
                          <CutoffDisplay
                            yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                            divPositionCheck={divPositionCheck}
                            time={currentDeliveryCutOff ?? ''}
                            currentDelivery={currentDelivery}
                            fromMarketplace={fromMarketplace}
                            i18n={i18n}
                          />
                        ) : sameDayIsPresent === true && (
                          <>
                            {(sameDayIsActive && dayjs(currentDeliveryCutOff).isBefore(new Date())) ?
                              <CutoffDisplay
                                yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                divPositionCheck={divPositionCheck}
                                time={currentDeliveryCutOffForSameDay ?? ''}
                                currentDelivery={currentDelivery}
                                fromMarketplace={fromMarketplace}
                                i18n={i18n}
                              />
                              :
                              <>{(bothSchedulersActive && sameDayIsActive && dayjs(currentDeliveryCutOff).isAfter(new Date())) ?
                                <><CutoffDisplay
                                  yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                  divPositionCheck={divPositionCheck}
                                  time={currentDeliveryCutOff}
                                  currentDelivery={currentDelivery}
                                  fromMarketplace={fromMarketplace}
                                  i18n={i18n}
                                />
                                </>
                                :
                                <>{allCutoffsPassedForScheduler ?
                                  <> <CutoffDisplay
                                    yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                    divPositionCheck={divPositionCheck}
                                    time={currentDeliveryCutOffForSameDay ?? ''}
                                    currentDelivery={currentDelivery}
                                    fromMarketplace={fromMarketplace}
                                    i18n={i18n}
                                  />

                                  </>
                                  :
                                  <>{(dayjs(currentDeliveryCutOff).isBefore(new Date()) && sameDayIsActive && dayjs(currentDeliveryCutOffForSameDay).isAfter(new Date())) ?
                                    <>
                                      <CutoffDisplay
                                        yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                        divPositionCheck={divPositionCheck}
                                        time={currentDeliveryCutOffForSameDay ?? ''}
                                        currentDelivery={currentDelivery}
                                        fromMarketplace={fromMarketplace}
                                        i18n={i18n}
                                      />

                                    </>
                                    :
                                    <>{currentDeliveryCutOff ?
                                      <CutoffDisplay
                                        yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                        divPositionCheck={divPositionCheck}
                                        time={currentDeliveryCutOff ?? ''}
                                        currentDelivery={currentDelivery}
                                        fromMarketplace={fromMarketplace}
                                        i18n={i18n}
                                      /> :
                                      <CutoffDisplay
                                        yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                        divPositionCheck={divPositionCheck}
                                        time={currentDeliveryCutOffForSameDay ?? ''}
                                        currentDelivery={currentDelivery}
                                        fromMarketplace={fromMarketplace}
                                        i18n={i18n}
                                      />}</>
                                  }
                                  </>
                                }
                                </>
                              }
                              </>
                            }
                          </>
                        )}
                      </>
                    )
                      :
                      <>
                        {sameDayIsPresent === false ? (
                          <>
                            <CutoffDisplay
                              yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                              divPositionCheck={divPositionCheck}
                              time={currentDeliveryCutOff}
                              currentDelivery={currentDelivery}
                              fromMarketplace={fromMarketplace}
                              i18n={i18n}
                            />
                          </>
                        ) : sameDayIsPresent === true && (
                          <>
                            {(sameDayIsActive && dayjs(currentDeliveryCutOff).isBefore(new Date())) ?
                              <CutoffDisplay
                                yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                divPositionCheck={divPositionCheck}
                                time={currentDeliveryCutOffForSameDay ?? ''}
                                currentDelivery={currentDelivery}
                                fromMarketplace={fromMarketplace}
                                i18n={i18n}
                              /> :
                              <>{(bothSchedulersActive && sameDayIsActive && dayjs(currentDeliveryCutOff).isAfter(new Date())) ?
                                <><CutoffDisplay
                                  yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                  divPositionCheck={divPositionCheck}
                                  time={currentDeliveryCutOff}
                                  currentDelivery={currentDelivery}
                                  fromMarketplace={fromMarketplace}
                                  i18n={i18n}
                                />
                                </>
                                :
                                <>{allCutoffsPassedForScheduler ? <CutoffDisplay
                                  yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                  divPositionCheck={divPositionCheck}
                                  time={currentDeliveryCutOffForSameDay ?? ''}
                                  currentDelivery={currentDelivery}
                                  fromMarketplace={fromMarketplace}
                                  i18n={i18n}
                                /> :
                                  <>{currentDeliveryCutOff ?
                                    <CutoffDisplay
                                      yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                      divPositionCheck={divPositionCheck}
                                      time={currentDeliveryCutOff ?? ''}
                                      currentDelivery={currentDelivery}
                                      fromMarketplace={fromMarketplace}
                                      i18n={i18n}
                                    />
                                    :
                                    <CutoffDisplay
                                      yourOrderHeaderPaddingCheck={yourOrderHeaderPaddingCheck}
                                      divPositionCheck={divPositionCheck}
                                      time={currentDeliveryCutOffForSameDay ?? ''}
                                      currentDelivery={currentDelivery}
                                      fromMarketplace={fromMarketplace}
                                      i18n={i18n}
                                    />}
                                  </>
                                }
                                </>
                              }
                              </>
                            }
                          </>
                        )}
                      </>
                    }
                  </div>
                </div>
            {!isInvitedMeeting && !fromPublicMarketPlace &&
              <CustomPopUp
                onPopUpClose={() => setIsOpenCalendarPopUp(false)}
                hideBackDrop={!isOpenCalendarPopUp}
                allowScrolling={!isOpenCalendarPopUp}
              >
                {(currentDelivery?.id || fromMarketplace) &&
                  <DeliveryDatesCalendar
                    hideCalendar={!isOpenCalendarPopUp}
                    deliveryDate={currentDelivery.delivery_at}
                    onDateChangeHandler={onDateChange}
                  />
                }
              </CustomPopUp>
            }
          </div>

          {/* Delivery Name, Address, Edit Delivery Details and List of All Delivries on a Single Date*/}
          <div className={`all-deliveries position-relative`}>
            {/* hidden zero deliveries count and  its click */}
            {/* <p className={`${(fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1) ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1 && setIsOpenSingleDateDeliveriesDropdown(true)}>
              {fromDashboard ? 'DELIVERIES' : 'DELIVERY'}
              {fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1 &&
                <span className='all-deliveries-count'>
                  {currentDateDeliveries?.length}
                </span>
              }
            </p> */}
            <div className="d-flex">
              <span className={`${(fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1) ? 'cursor-pointer icons-wrapper mx-0 ml-md-0 mr-lg-3' : 'cursor-default icons-wrapper mx-0 ml-md-0 mr-lg-2'}`} onClick={() => fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1 && setIsOpenSingleDateDeliveriesDropdown(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 25.062 33">
                  <path id="location-dot-thin_-_Outline" data-name="location-dot-thin - Outline" d="M12.031-.5A12.533,12.533,0,0,1,24.562,12.031c0,2.464-1.394,5.974-4.142,10.432a96.042,96.042,0,0,1-6.514,9.129,2.393,2.393,0,0,1-3.75,0C7.658,28.521-.5,18.023-.5,12.031A12.531,12.531,0,0,1,12.031-.5ZM14.473.76A11.533,11.533,0,0,0,.5,12.031c0,2.253,1.374,5.679,3.972,9.908a90.26,90.26,0,0,0,6.462,9.026,1.393,1.393,0,0,0,2.193,0c3.137-3.926,10.436-13.633,10.436-18.937a11.534,11.534,0,0,0-.26-2.442,11.635,11.635,0,0,1,.258,2.442,12.385,12.385,0,0,1-1.155,4.555,40.474,40.474,0,0,1-2.836,5.351,104.843,104.843,0,0,1-6.445,9.029,1.389,1.389,0,0,1-2.183,0A104.616,104.616,0,0,1,4.5,21.94a40.169,40.169,0,0,1-2.838-5.353A12.321,12.321,0,0,1,.5,12.031,11.529,11.529,0,0,1,12.031.5,11.635,11.635,0,0,1,14.473.76ZM12.031,30.5a.4.4,0,0,0,.313-.157,103.758,103.758,0,0,0,6.375-8.931A39.472,39.472,0,0,0,21.484,16.2a11.448,11.448,0,0,0,1.076-4.166,10.529,10.529,0,0,0-21.057,0A11.387,11.387,0,0,0,2.58,16.2a39.172,39.172,0,0,0,2.766,5.214,103.531,103.531,0,0,0,6.374,8.93A.4.4,0,0,0,12.031,30.5Zm0-23.979a5.513,5.513,0,1,1-5.513,5.513A5.519,5.519,0,0,1,12.031,6.518Zm0,10.026a4.513,4.513,0,1,0-4.513-4.513A4.518,4.518,0,0,0,12.031,16.544Zm0-9.023a4.51,4.51,0,1,1-4.51,4.51A4.515,4.515,0,0,1,12.031,7.521Zm0,8.021a3.51,3.51,0,1,0-3.51-3.51A3.514,3.514,0,0,0,12.031,15.542Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                </svg>
              </span>
              {fromDashboard && currentDateDeliveries?.length > 0 && (
                <span className='all-deliveries-count'>
                  {currentDateDeliveries.length}
                </span>
              )}
              <div>
                <p id={`ac-${isCompanyAdmin ? 'admin' : 'user'}-step-2`} className={`${(fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1) ? 'cursor-pointer' : 'cursor-default'} text-nowrap overflow-hidden text-truncate m-0 delivery-address pr-4`}
                  style={{ maxWidth: screenWidth > 1200 ? 'unset' : 300 }}
                  onClick={() => fromDashboard && currentDateDeliveries?.length && currentDateDeliveries?.length >= 1 && setIsOpenSingleDateDeliveriesDropdown(true)}
                >
                  {currentDelivery?.formatted_address}&nbsp;&nbsp;
                  {fromDashboard && <i className='fas fa-chevron-down' style={{ position: 'absolute', "top": '5px', 'right': "0px", transform: 'translate(-50%)' }} ></i>}
                </p>
                {(currentDelivery && fromMarketplace) ?
                  <p style={{ maxWidth: screenWidth > 1200 ? 'unset' : 335 }}
                    className={`${isEditableDelivery ? 'cursor-pointer' : 'disable-click'} text-nowrap overflow-hidden text-truncate mb-0 mt-1`}
                    onClick={createOrUpdateDelivery}
                  >
                    {currentDelivery?.runningmenu_name}
                    {isEditableDelivery &&
                      <i className='fas fa-edit ml-2' style={{ color: '#0089ff' }}></i>
                    }
                  </p>:
                  <p style={{ maxWidth: screenWidth > 1200 ? 'unset' : 335 }}>
                    {currentDelivery?.runningmenu_name}
                  </p>
                }
              </div>
            </div>

           
            {isOpenSingleDateDeliveriesDropdown && fromDashboard &&
              <CustomPopUp
                onPopUpClose={() => setIsOpenSingleDateDeliveriesDropdown(false)}
                popUpBodyClassName='invisible-scroll'
                popUpBodyStyles={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto', borderRadius: '17px' }}
              >
                {currentDateDeliveries?.map((delivery: DeliveryOnSingleDate, index: number) =>
                  <div key={delivery?.id}
                    className='d-flex align-items-center cursor-pointer all-deliveries-list-item'
                    style={{ borderTop: index > 0 ? '1px solid #E5E5E5' : 'none', backgroundColor: delivery.id === hoveredDeliveryId ? '#fbfbfb' : 'white', maxWidth: 450 }}
                    onClick={() => deliveryChangeHandler(delivery)}
                    onMouseEnter={() => setHoveredDeliveryId(delivery?.id)}
                    onMouseLeave={() => setHoveredDeliveryId(null)}
                  >
                    <i
                      className={`far fa-${(delivery?.id === currentDelivery?.id || delivery?.id === hoveredDeliveryId) ? 'check-circle' : 'circle'}`}
                      style={{ color: delivery?.id === currentDelivery?.id ? '#28A745' : '#8B9898', fontSize: 24 }}
                    >
                    </i>
                    <div className={`px-3 w-100`}>
                      <p>{delivery?.formatted_address}</p>
                      <p>{delivery?.runningmenu_name}  at {dayjs(delivery?.delivery_at).format('hh:mma')}</p>
                      {isCompanyAdmin && <>
                        <p>Delivery Instructions</p>
                        <p className='m-0 delivery-instructions'>{delivery?.delivery_instructions || 'None'}</p>
                      </>}
                    </div>
                  </div>
                )}
              </CustomPopUp>}
          </div>
          </div>

          {/*Delivery Order Details Section in case of both Buffet and Indiviual Delivery for Company Admins*/}
          {isCompanyAdmin && fromDashboard &&
            <div>
              <div id={`ac-admin-step-3`} className='d-flex delivery-details cursor-default'>
                {!isBuffetDelivery ? <>
                  <div className='mx-1'>
                    <p className='text-nowrap delivery-details'>ITEMS</p>
                      <div className='text-nowrap delivery-details-value d-flex'>
                        <p className='text-nowrap' style={{ fontSize: '16px', color: 'black' }}>
                          {currentDelivery?.total_quantity}
                        </p>
                        {currentDelivery?.total_quantity > 0 && <div><span data-tip
                          data-for='view-details' className="fas fa-eye ml-2 fa-sm cursor-pointer" onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })} style={{ color: "#167FFB" }}></span>
                          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                            <ReactTooltip place={yourOrderHeaderPaddingCheck ? 'bottom' : 'top'} type='dark' id='view-details' effect='solid' className={`react-tooltip ${yourOrderHeaderPaddingCheck && 'react-tooltip-sticky-header-margin-top'} `}>
                              <span>View Details</span>
                            </ReactTooltip>
                          }
                          {currentDelivery?.total_quantity ?
                            <>
                              {orderCreated && !confirmModal ?
                                <>
                                  <div>
                                    <div id="spot-light" className="tooltip_wrapper"></div>
                                    <Tooltip
                                      visible={tooltip}
                                      // visible= {orderCreated && !confirmModal}
                                      animation="zoom"
                                      placement="top"
                                      onVisibleChange={onVisibleChange}
                                      trigger="click"
                                      overlay={
                                        <div className="d-flex align-items-center justify-content-center flex-column py-3 my-1" style={{ width: "200px" }} >
                                          <img src="/imgs/foodimage.svg" alt="" className='mb-1' />
                                          <h4 className='mb-1'>New Order</h4>
                                          <h6 className='font-weight-normal text-center'>Manage all your orders here.</h6>
                                          <button className='btn btn-sm btn-primary align-self-center px-3 rounded' onClick={openSideBar}><strong>View</strong></button>
                                        </div>
                                      }
                                    >
                                      {/* eslint-disable-next-line */}
                                      <a href="#"></a>
                                    </Tooltip>
                                  </div>
                                </>
                                :
                                //   <p
                                //   style={{ textDecoration: 'underline', color: '#147BFC' }}
                                //     className='m-0 link-style'
                                //     onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}>
                                //     View Details
                                // </p>
                                <></>
                              }
                            </>
                            : ''
                          }
                        </div>
                        }
                    </div>
                  </div>
                  <div className='mx-2'>
                    <p className='text-nowrap delivery-details'>AVG / MEAL</p>
                    <p className='text-nowrap'>${currentDelivery?.avg_per_meal ?
                      Number(currentDelivery?.avg_per_meal).toFixed(2) : '00.00'}</p>
                  </div>
                </> :
                    <div className='mx-1 text-nowrap'>
                      <p className='m-0'>PRICE PER HEAD</p>
                      <p className='d-flex'>${currentDelivery?.price_per_head ? Number(currentDelivery?.price_per_head).toFixed(2) : '0.00'}
                        {currentDelivery?.price_per_head && +currentDelivery?.price_per_head > 0 && <div><span data-tip
                          data-for='view-details' className="fas fa-eye ml-2 fa-sm cursor-pointer" onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })} style={{ color: "#167FFB" }}></span>
                          {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                            <ReactTooltip place='top' type='dark' id='view-details' effect='solid' className='react-tooltip'>
                              <span>View Details</span>
                            </ReactTooltip>
                          }
                          {currentDelivery?.total_quantity ?
                            <>
                              {orderCreated && !confirmModal ?
                                <>
                                  <div>
                                    <div id="spot-light" className="tooltip_wrapper"></div>
                                    <Tooltip
                                      visible={tooltip}
                                      // visible= {orderCreated && !confirmModal}
                                      animation="zoom"
                                      placement="top"
                                      onVisibleChange={onVisibleChange}
                                      trigger="click"
                                      overlay={
                                        <div className="d-flex align-items-center justify-content-center flex-column py-3 my-1" style={{ width: "200px" }} >
                                          <img src="/imgs/foodimage.svg" alt="" className='mb-1' />
                                          <h4 className='mb-1'>New Order</h4>
                                          <h6 className='font-weight-normal text-center'>Manage all your orders here.</h6>
                                          <button className='btn btn-sm btn-primary align-self-center px-3 rounded' onClick={openSideBar}><strong>View</strong></button>
                                        </div>
                                      }
                                    >
                                      {/* eslint-disable-next-line */}
                                      <a href="#"></a>
                                    </Tooltip>
                                  </div>
                                </>
                    :
                  //   <p
                  //   style={{ textDecoration: 'underline', color: '#147BFC' }}
                  //     className='m-0 link-style'
                  //     onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}>
                  //     View Details
                  // </p>
                        <></>
                  }
                </>
                : ''
                }
                        </div>
                        }
                      </p>
                    </div>
                  }
                  <div className='mx-1'>
                    <p className='text-nowrap delivery-details'>TOTAL</p>
                    <p className='text-nowrap'>
                      ${(+currentDelivery?.order_total + (currentDelivery.delivery_fee ? +currentDelivery.delivery_fee : 0)).toFixed(2)}
                      {/* <i className='fas fa-list-ul ml-2' style={{ color: '#0089ff' }}></i> */}
                    </p>
                  </div>
                </div>
              </div>
          }

          {/* {fromPublicMarketPlace && 
            <div className={`delivery-actions d-flex ${screenWidth > 1350 ? 'flex-row justify-content-center align-items-xl-start' : `flex-column`}`}
            style={{ flexDirection: screenWidth > 1350 ? 'row' : 'column' }}
            >
              <div className="col-sm-12 col-md-12 col-lg-6 px-0 px-ml-3">
                <div className={`px-0 position-relative d-flex align-items-center`} >
                  
                  <span className='icons-wrapper mx-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 28.429">
                      <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                    </svg>
                  </span>
                  <p
                    style={{ color: '#147BFC' }}
                    className='m-0 link-style'
                    onClick={() => parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}
                  >
                    cart
                  </p>

                </div>
              </div>

            </div>
          } */}

          {/*Cancel and Invite Guests for Current Delivery for Company AdminsW*/}
          {isCompanyAdmin && !isMarketPlacePreview && fromDashboard &&
            <div className={`delivery-actions d-flex ${screenWidth > 1350 ? 'flex-row justify-content-center align-items-xl-start' : `flex-row`}`}
              style={{ flexDirection: screenWidth > 1350 ? 'row' : 'column' }}
            >
              {inviteGuestsCancelAndEditMeetingButtons}
            </div>
          }

            {/*Order Details Section in case of Indiviual Delivery for Company Users*/}
            {!isCompanyAdmin && !fromMarketplace && <>
              {(!isUnsubsidizedUser && loggedInUserCompany?.show_remaining_budget) ? <div style={{ minWidth: '120px' }} data-tip data-for={'subsidy-tooltip'}>
                <div className='d-flex justify-content-start flex-column'>
                  <p className='text-nowrap delivery-details'>SUBSIDY</p>
                  <p className='mb-1'>{`$${Number(currentDelivery?.remaining_budget)?.toFixed(2)} / $${Number(currentDelivery?.per_meal_budget)?.toFixed(2)}`}</p>
                  <div>
                    <div>
                      <BorderLinearProgress variant='determinate' value={subsidyValue} />
                    </div>
                    {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                      <ReactTooltip place='top' type='dark' id='subsidy-tooltip' effect='solid' className='react-tooltip'>
                        <b>{`$${Number(currentDelivery?.remaining_budget)?.toFixed(2)} left`}</b>
                        <br />
                        {(subsidyValue === 0) ? <span>100% Subsidy remaining</span> : <span>{`${subsidyValue}`}% Subsidy used</span>}
                      </ReactTooltip>
                    }
                  </div>
                </div>
              </div> :
              <></>
              }
              <div className='d-flex justify-content-start flex-column' id={`ac-user-step-3`}>
              <div className={`${currentDelivery?.your_order ? 'cursor-pointer' : 'cursor-default'} user-orders`}
                onClick={isOpenDeliveryOrderDetailSidebar}
              >
                <div className='d-flex justify-content-between align-items-center' style={{ minWidth: '200px' }}>
                  <p className='text-nowrap delivery-details'>YOUR ORDER</p>
                  <>
                  { orderCreated && !confirmModal ?
                    <div>
                      <div id="spot-light" className="tooltip_wrapper_user"></div>
                      <Tooltip
                        visible={tooltip}
                        // visible= {orderCreated && !confirmModal}
                        animation="zoom"
                        placement="bottom"
                        onVisibleChange={onVisibleChange}
                        trigger="click"
                        overlay={
                        <div className="d-flex align-items-center justify-content-center flex-column py-3 my-1" style={{ width: "200px" }} >
                          <img src="/imgs/foodimage.svg" alt="" className='mb-1' />
                          <h4 className='mb-1'>New Order</h4>
                          <h6 className='font-weight-normal text-center'>Manage all your orders here.</h6>
                          <button className='btn btn-sm btn-primary align-self-center px-3 rounded' onClick={openSideBar}><strong>View</strong></button>
                        </div>
                        }
                      >
                        {/* {currentDelivery?.your_order && */}
                          <span className='ml-2 '>
                            {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                            <i className='fas fa-external-link-alt ml-1'></i>
                          </span>
                        
                      </Tooltip>
                    </div>
                    :
                    currentDelivery?.your_order &&
                      <span className='ml-2 '>
                        {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                        <i className='fas fa-external-link-alt ml-1'></i>
                      </span>
                  }
                </>
                  {/* {currentDelivery?.your_order &&
                    <span className='ml-2 '>
                      {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
                      <i className='fas fa-external-link-alt ml-1'></i>
                    </span>
                  } */}
                </div>
                <>
                  {currentDelivery?.your_order ?
                    <>
                      <p className='user-first-order-name'>{quantity}
                        <i className='fas fa-times mx-1' style={{ fontSize: 'smaller' }}></i>
                        {item_name}
                      </p>
                      {!dayjs(currentDeliveryCutOff).isAfter(new Date()) && <p style={{ 'fontSize': '16px' }}>
                        Group(s) :
                        {uniqueGroups?.map((value: any, idx: any, arr: any) => {
                          if (idx === arr.length - 1) {
                            return '\t\t' + value
                          }
                          return '\t\t' + value + ','
                        })}
                      </p>
                      }
                    </>
                    :
                    <p style={{ color: '#6e6e6e', fontSize: 18 }}>
                      <img className='mr-2' width={27} height={27} src={ShoppingCart} alt='Empty Cart' />
                      No Orders Placed
                    </p>
                  }
                </>
              </div>
            </div>
            </>
          }

          {/*Invite Guests for Current Delivery for Company Managers*/}
          {isCompanyManager && fromDashboard &&
            <div className={`delivery-actions d-flex`}>
              {inviteGuestsCancelAndEditMeetingButtons}
            </div>
          }
        </div>
      </div>
    </div>
    }
  </>
}

export default DeliveryDetailsSection;

const DeliveryDetailsLoading = ({ isCompanyAdmin }: { isCompanyAdmin: boolean }) => {
  return <>
    <div className='bg-white'>
      <div className='container' style={{ paddingTop: '89px' }}>
        <div className='row top-bar flex-nowrap' style={{ gridGap: '50px' }}>
          <div className='delivery-date d-flex flex-column'>
            <p><Skeleton width={78} height={15} /></p>
            <p><Skeleton width={121} height={23} /></p>
            <p><Skeleton width={121} height={20} /></p>
          </div>
          <div className={`flex-grow-1 all-deliveries`}>
            <p><Skeleton width={82} height={15} /></p>
            <p className='w-100'><Skeleton width={'75%'} height={23} /></p>
            <p><Skeleton width={'75%'} height={20} /></p>
          </div>
          {isCompanyAdmin &&
            <div className='d-flex delivery-details'>
              <div className='mx-1'>
                <p><Skeleton width={32} height={15} /></p>
                <p><Skeleton width={32} height={23} /></p>
              </div>
              <div className='mx-2'>
                <p><Skeleton width={57} height={15} /></p>
                <p><Skeleton width={57} height={23} /></p>
              </div>
              <div className='mx-1'>
                <p><Skeleton width={32} height={15} /></p>
                <p><Skeleton width={75} height={23} /></p>
              </div>
            </div>}
          {isCompanyAdmin &&
            <div className='delivery-actions d-flex flex-column flex-xl-row justify-content-center align-items-xl-start'>
              <button className={`m-1 p-0 btn mt-xl-3`}>
                <Skeleton width={112} height={31} />
              </button>
              <button className={`m-1 p-0 btn mt-xl-3`}>
                <Skeleton width={124} height={31} />
              </button>
            </div>}
          {!isCompanyAdmin &&
            <div className=' d-flex justify-content-center flex-column'>
              <div className='user-orders'>
                <div className='d-flex justify-content-between'>
                  <p><Skeleton width={68} height={21} /></p>
                  <span className='mr-2'>
                    <Skeleton width={93} height={21} />
                  </span>
                </div>
                <div>
                  <p className='user-first-order-name'><Skeleton width={253} height={27} /></p>
                </div>
              </div>
            </div>
          }
        </div>
      </div></div>
  </>
}