import { GET_ADDRESS_DATA, GET_COMPANY_ADDRESSES, GET_CUSTOM_FIELDS, GET_PUBLIC_ADDRESSES, GET_SELECTED_ADDRESS_ID, RESET_STATE } from '../Actions/types';
import { AddressDataResponse } from '../Interfaces/PayloadsAndResponses/Address';
import { Addresses } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Addresses = {
  comp_addresses: null,
  custom_fields: null,
  public_addresses: null,
  selectedAddressId: null,
  selectedAddressData: {} as AddressDataResponse
}
const addressReducer = (state: Addresses = initialState, action: any): Addresses => {
  switch (action.type) {
    case GET_COMPANY_ADDRESSES:
      return {
        ...state,
        comp_addresses: action.payload,
      }
    case GET_PUBLIC_ADDRESSES:
      return {
        ...state,
        public_addresses: action.payload,
      }
    case GET_CUSTOM_FIELDS:
      return {
        ...state,
        custom_fields: action.payload,
      }
    case GET_SELECTED_ADDRESS_ID:
      return {
        ...state,
        selectedAddressId: action.payload,
      }
    case GET_ADDRESS_DATA:
      return {
        ...state,
        selectedAddressData: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default addressReducer;