import i18n from "i18next";

export const orderDetailsTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Email": "Email",
      "Send Reminder Emails": "Send Reminder Emails",
      "Delivery Time": "Delivery Time",
      "Style": "Style",
      "Orders": "Orders",
      "Avg. Per Meal": "Avg. Per Meal",
      "Total": "Total",
      "User": "User",
      "Item": "Item",
      "Quantity": "Quantity",
      "Price": "Price ",
      "Cancel": "Cancel",
      "Print": "Print",
      "These users have not placed their orders. They could use a reminder.": "These users have not placed their orders. They could use a reminder.",
      "They need a nudge...": "They need a nudge...",
      "Last": "Last",
      "First": "First"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Email": "ای میل",
      "Send Reminder Emails": "یاد دہانی والے ای میلز بھیجیں",
      "Delivery Time": "ترسیل کا وقت",
      "Style": "انداز",
      "Orders": "احکامات",
      "Avg. Per Meal": "اوسط فی کھانے",
      "Total": "کل",
      "User": "صارف",
      "Item": "آئٹم",
      "Quantity": "مقدار",
      "Price": "قیمت ",
      "Cancel": "منسوخ کریں",
      "Print": "پرنٹ کریں",
      "These users have not placed their orders. They could use a reminder.": "ان صارفین نے اپنے آرڈر نہیں دیئے ہیں۔ وہ ایک یاد دہانی استعمال کرسکتے ہیں۔",
      "They need a nudge...": "انہیں ایک نوج کی ضرورت ہے ...",
      "Last": "آخری",
      "First": "پہلا",
    });
};