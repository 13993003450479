import React, { FC, CSSProperties, useEffect, useState } from 'react';
import CustomPopUp from './CustomPopUp';
import DeliveryOrderDetails from '../../Pages/OrderHistory/DeliveryOrderDetails';
import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
import { AdminApprovedDelivery } from '../../Interfaces/PayloadsAndResponses/Menu';
import { OrderHistoryDelivery } from '../../Interfaces/PayloadsAndResponses/Orders';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import isEmpty from '../../Utilities/is_empty';

const deliveryOrdersDetailsSidebarStyles = {
  top: 0,
  right: 0,
  bottom: 0,
  width: '99vw',
  zIndex: 6,
  maxWidth: 1000,
  height: '100vh',
  position: 'fixed',
  transition: '.7s all',
  backgroundColor: '#fafcfe',
} as CSSProperties;

export type DeliveryOrdersSidebarMeeting = Delivery | AdminApprovedDelivery | OrderHistoryDelivery

type Props = {
  isOpenSidebar: boolean;
  closeSidebar: () => void;
  invitedMeetingToken?: string;
  fromMenuDetailPopup?: boolean;
  updatePageOnOrderDelete?: () => void;
  updateOrder?: (orderId: number) => void;
  selectedMeeting: DeliveryOrdersSidebarMeeting | null | undefined;
};

const DeliveryOrdersSidebar: FC<Props> = ({ isOpenSidebar, closeSidebar, selectedMeeting, updateOrder, updatePageOnOrderDelete, fromMenuDetailPopup = true, invitedMeetingToken = '' }) => {

  const user = getloggedInUser();
  const [orderManipulated, setOrderManipulated] = useState<boolean>(false)

  useEffect(() => {
    // Handle Two Scroll Issue
    if (isOpenSidebar) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSidebar, orderManipulated])

  const orderChangeHandler = () => {
    const { isCompanyAdmin } = getloggedInUser();

    setOrderManipulated(true)
    if (!isCompanyAdmin) {
      closeSidebar()
    }
  }


  return <CustomPopUp
    hideBackDrop={!isOpenSidebar}
    allowScrolling
    onPopUpClose={closeSidebar}
  >
    <div id='DeliveryOrderDetailsSidebar' style={{ ...deliveryOrdersDetailsSidebarStyles, transform: isOpenSidebar ? 'translateX(0)' : 'translateX(100%)' }}>
      {!isEmpty(selectedMeeting) &&
        <DeliveryOrderDetails
          user={user}
          closeSidebar={closeSidebar}
          isOpenSidebar={isOpenSidebar}
          orderChangeHandler={orderChangeHandler}
          selectedMeeting={selectedMeeting!} // Will not be empty if isEmpty(selectedMeeting) is FALSE
          invitedMeetingToken={invitedMeetingToken}
          fromMenuDetailPopup={fromMenuDetailPopup}
          updatePageOnOrderDelete={updatePageOnOrderDelete}
          updateOrder={(id: number) => updateOrder && updateOrder(id)}
        />}
    </div>
  </CustomPopUp>
}

export default DeliveryOrdersSidebar;