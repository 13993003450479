// Packages
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Slide } from 'pure-react-carousel';
import { useHistory } from 'react-router-dom';
// React JSX Components
import MenuDetailPageCarousel from './MenuDetailPageCarousel';
// import CustomPopUp from '../../../Common/Popups/CustomPopUp';
// Redux Dispatch Actions
import { getSingleDeliveryMenu,
   getMenusOfRestaurant,
  //  getMenusOfRestaurantCombos,
   getSingleDeliveryRecentOrders } from '../../../Actions/menuActions';
import { useDispatch } from 'react-redux';
// Utilities
import isEmpty from '../../../Utilities/is_empty';
import SliderChevronLeft from '../SVGs/NewSliderChevronLeft.svg';
import SliderChevronRight from '../SVGs/NewSliderChevronRight.svg';
import filterSliderChevronLeft from '../SVGs/arrow-back.svg'; 
import filterSliderChevronRight from '../SVGs/arrow-right.svg';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import RestaurantDummyLogo from '../SVGs/RestaurantDummyLogo.svg';
// Types
import { MenuDetailPageParentRef } from './MenuArea';
import { MarketPlaceState } from '../MarketplacePreview/Marketplace';
import { ApprovedMeetingMenuDetailState } from '../ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import { DeliveryMenuRestaurant, MarketplaceMenuRestaurant, FoodItemDietary, FoodItemIngredient, GetMenusOfRestaurant, MenuFilter, GetMenuForSingleDelivery } from '../../../Interfaces/PayloadsAndResponses/Menu';
import ReactTooltip from 'react-tooltip';
import { GetMenuFilters, ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { useSelector } from 'react-redux';
// import CustomPopUp from '../../../Common/Popups/CustomPopUp';
// import MarketPlaceRatinPopUp from '../../../Common/Popups/MarketPlaceRatingPopUp';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
import Skeleton from 'react-loading-skeleton';
import { User } from '../../../Interfaces/PayloadsAndResponses/Auth';
import MarketplaceFilterDummyLogo from '../SVGs/marketplaceFiltersDummyLogo.svg'
import dayjs from 'dayjs';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';

// CSS

type MenuDetailPageParentState = MenuDetailPageParentRef['state']

type MenuFiltersProps = {
  dietaries?: FoodItemDietary[] | undefined;
  ingredients?: FoodItemIngredient[] | undefined;
  menuFilters?: MenuFilter[] | undefined;
  restaurants: DeliveryMenuRestaurant[] | MarketplaceMenuRestaurant[] | undefined;
  noOfSlidesForRestaurants: number;
  menuLoading: boolean;
  parentState: MenuDetailPageParentState;
  fromDashboard?: boolean;
  showCarouselButtons: boolean;
  fromInvitedMeeting?: boolean;
  invitedMeetingToken?: string;
  currentDeliveryShareMeeting?: Delivery | undefined;
  fromMarketplace?: boolean;
  mealTypeForMenuCheckAPI?: string;
  // menuPageFoodItemPrice?: number;
  // menuPageFoodItemRating?: number;
  // handleMenuRatingChange?:(value: number) => void;
  // handleMenuAmountChange?: (value: number) => void;
  marketPlaceMenu?: boolean;
  menu?: GetMenusOfRestaurant | null;
  singleSchedulerMenu?: GetMenuForSingleDelivery | null;
  handleResetFilters?: () => void;
  queryFilters?: any;
}

const topFiltersCount = 9;
// type DietariesIngredientsFilter = ((FoodItemDietary | FoodItemIngredient) & { selected?: boolean; type: string });
type MenuRestaurantsFilter = (DeliveryMenuRestaurant | MarketplaceMenuRestaurant) & { selected?: boolean }

// const rearrangeableDietariesAndIngredientsList = [`Compostable Packaging`, `Recyclable Packaging`, `Biodegradable Packaging`];
// const getFormattedDietariesAndIngredients = (dietaries: FoodItemDietary[], ingredients: FoodItemDietary[]) => {
//   const rearrangedDietariesAndIngredients: DietariesIngredientsFilter[] = [];
//   const newDietaries: DietariesIngredientsFilter[] = [];
//   const newIngredients: DietariesIngredientsFilter[] = [];
//   dietaries.forEach(dietary => {
//     const formattedDietary = { ...dietary, type: 'dietary' };
//     if (rearrangeableDietariesAndIngredientsList.includes(dietary.name?.trim())) {
//       rearrangedDietariesAndIngredients.push(formattedDietary);
//     } else {
//       newDietaries.push(formattedDietary);
//     }
//   });
//   ingredients.forEach(ingredient => {
//     const formattedIngredient = { ...ingredient, type: 'ingredient' };
//     if (rearrangeableDietariesAndIngredientsList.includes(ingredient.name?.trim())) {
//       rearrangedDietariesAndIngredients.push(formattedIngredient);
//     } else {
//       newIngredients.push(formattedIngredient);
//     }
//   });
//   return [...newDietaries, ...newIngredients, ...rearrangedDietariesAndIngredients]
// }

const excludeNames = [
  'Gluten-Free Upon Request',
  'Gluten Free Upon Request',
  'Nut-Free Upon Request',
  'Nut Free Upon Request',
  'Dairy-Free Upon Request',
  'Dairy Free Upon Request',
  'Vegan-Upon Request',
  'Vegan Upon Request',
];

const MenuFilters = ({
  parentState,
  dietaries = [],
  ingredients = [],
  menuFilters = [],
  restaurants = [],
  noOfSlidesForRestaurants = 0,
  menuLoading = false,
  fromDashboard = false,
  fromInvitedMeeting = false,
  currentDeliveryShareMeeting,
  invitedMeetingToken = '',
  fromMarketplace = false,
  showCarouselButtons = false,
  mealTypeForMenuCheckAPI,
  queryFilters,
  // menuPageFoodItemPrice,
  // menuPageFoodItemRating,
  // handleMenuRatingChange,
  // handleMenuAmountChange,
  marketPlaceMenu,
  menu,
  singleSchedulerMenu,
  handleResetFilters
}: MenuFiltersProps) => {
 
  const dispatch: any = useDispatch();
  // const newDietaries = dietaries.map(d => ({ ...d, type: 'd' }));
  // const newIngredients = ingredients.map(d => ({ ...d, type: 'i' }));
  const { isCompanyAdmin, loggedInUserCompany, loggedInUser } = getloggedInUser();

  // const [filters, setFilters] = useState<DietariesIngredientsFilter[]>(() => getFormattedDietariesAndIngredients(dietaries, ingredients));
  // const filtersForGuest = useSelector((state: ReduxRootState) => state?.menu?.menu_filters?.menu_filters);
  const filtersFromRedux : null | GetMenuFilters = useSelector((state: ReduxRootState) => state?.menu?.menu_filters);

  const [filters, setFilters] = useState<MenuFilter[]>(filtersFromRedux?.menu_filters ?? []);
  const [menuRestaurants, setMenuRestaurants] = useState<MenuRestaurantsFilter[]>(restaurants);
  // const reduxCartAddressIds = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);
  const isFilterApplied = useSelector((state: ReduxRootState) => state?.menu?.isFilterApplied);
  const screenWidthmarketPlace = window.document.body.clientWidth;
  // const [isPopupOpen, setIsPopUpOpen] = useState<boolean>(false);
  const history: any = useHistory();
  const selectedPill = filters?.find(filter => filter.selected === true);
  const pathCheck = history.location.pathname.split('/');
  const {
    restaurantCardCarousel
  } = getResponsiveNoOfSlides();

  useEffect(() => {
    const filteredArray = filters?.filter((filter: any) =>
      !excludeNames?.includes(filter?.name?.trim())
    );

    setFilters(filteredArray)
    // eslint-disable-next-line
  }, [])

  let lastIndexOfPreferedFilter = -1; // Initialize to -1 in case no prefered filter i.e 1 is found

  filters?.forEach((filterValue, index) => {
  if (filterValue?.preferred === 1) {
    lastIndexOfPreferedFilter = index;
  }
});

  const hasMenu = pathCheck[1] === 'menu';
  const hasNewParameter = pathCheck[2] !== undefined;
  const hasEmptyParameter = pathCheck[3] !== '';
  let showPricingAndRatingButton = false;
  if (hasMenu && hasNewParameter && !hasEmptyParameter) {
    showPricingAndRatingButton = true;
  }
  // Menu Food Item Count
  const totalFavoritesCount =menu?.favorites?.length || 0;
  const totalMostPopularCount = menu?.most_popular?.length || 0;
  const totalTopPicksSectionCount = menu?.top_picks?.length || 0;

  const toalRestaurantCount = menu?.restaurants[0].sections?.reduce((total, section) => {
    return total + section?.fooditems?.length;
  }, 0) || 0;

  //Total Menu FoodItem Count
  const totalMenuFoodItemCount = totalFavoritesCount + totalMostPopularCount + totalTopPicksSectionCount + toalRestaurantCount;

   // Your Order Page Food Item Count
   const totalFavoritesCountDelivery = singleSchedulerMenu?.favorites?.length || 0;
   const totalMostPopularCountDelivery = singleSchedulerMenu?.most_popular?.length || 0;
   const totalTopPicksSectionCountDelivery = singleSchedulerMenu?.top_picks?.length || 0;

   const totalDeliveryRestaurantsFoodItemCount = singleSchedulerMenu?.restaurants?.reduce((total, restaurant) => {
    return total + restaurant?.sections?.reduce((sectionTotal, section) => {
      return sectionTotal + (section?.fooditems?.length || 0);
    }, 0);
  }, 0) || 0;

  const calcuteTotalFilteredREsultsForYourOrder = totalFavoritesCountDelivery + totalMostPopularCountDelivery + totalTopPicksSectionCountDelivery + totalDeliveryRestaurantsFoodItemCount


//   const handleSubmitForMenuPage = (price: number, avg_rating: number) => {
//     const { restaurantId, mealTypeNumber, selectedCompanyAddressID } = parentState as MarketPlaceState;
//     const combo_selected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
//     const disabledRestaurants = menuRestaurants?.map((restaurant: any) => {
//       return (restaurant?.sections?.lenght > 0) ? restaurant?.id : ''
//     })
//     const filteredComboClosedRestaurants: number[] = disabledRestaurants?.filter((id: any) => {
//       return id;
//     })
//     if (combo_selected?.combo_selected === 'true' || fromMarketplace) {
//       dispatch(getMenusOfRestaurantCombos(reduxCartAddressIds, filteredComboClosedRestaurants, mealTypeForMenuCheckAPI, true));
//     } else if (selectedCompanyAddressID || fromMarketplace) {
//       dispatch(getMenusOfRestaurant(+restaurantId, mealTypeNumber, mealTypeForMenuCheckAPI, true));
//   }
// }
  useEffect(() => {
    const combo_selected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    if (!isEmpty(queryFilters) && isEmpty(combo_selected) && isEmpty(user)) {
      filters?.forEach((filter, index) => {
        // const filterTypeName =
        //   filter?.filter_type === 'd' ? 'dietries' : 'ingredients';
        if (
          queryFilters[filter?.filter_type] &&
          queryFilters[filter?.filter_type].includes(filter?.id)
        ) {
          filterChangeHandler(index, true)
        }
      });
    }
    // eslint-disable-next-line
  }, [])

  // Update Menu Ingradients and Dietaries Filter State
  // useEffect(() => {
  //   console.log('%cMENUFILTERS------','color:pink',menuFilters,filters, dietaries, ingredients)

  //   if (!isEmpty(menuFilters)) {
  //     // const newDietaries = dietaries.map(d => ({ ...d, type: 'd' }));
  //     // const newIngredients = ingredients.map(d => ({ ...d, type: 'i' }));
  //     // const rearrangedDietariesAndIngredients = getFormattedDietariesAndIngredients(dietaries, ingredients);
   
  //     // setFilters(filters);
  //   }
  //   // eslint-disable-next-line
  // }, [dietaries, ingredients]);
  // Update Menu Restaurants and Dietaries Filter State
  useEffect(() => {
    !isEmpty(restaurants) && setMenuRestaurants(restaurantsFilters => isEmpty(restaurantsFilters) ? restaurants : restaurantsFilters);
  }, [restaurants]);

  // useEffect(()=>{
  // },[mealTypeForMenuCheckAPI])

  const restaurantFilterChangeHandler = (index: number, updateMenu: boolean = false) => {
    setMenuRestaurants(restaurantsFilters => {
      const updatedFilters = [
        ...restaurantsFilters.slice(0, index),
        { ...restaurantsFilters[index], selected: !restaurantsFilters[index]?.selected },
        ...restaurantsFilters.slice(index + 1)
      ];
      updateMenu && updateFilteredMenu(updatedFilters, filters);
      return updatedFilters
    });
  }

  const filterChangeHandler = (index: number, updateMenu: boolean = false) => {
    setFilters(filters => {
      //Grouped similar filters, e.g., Gluten-Free & Gluten-Free Upon Request, etc....
      let updatedFilters: any = [];

      if ( filters[index].name?.trim() === 'Gluten-Free' ||
            filters[index].name?.trim() === 'Nut-Free' || 
            filters[index].name?.trim() === 'Dairy-Free'
      ) {

        // const dietaryName = filters[index].name?.trim().split('-');
        const dietaryName = filters[index].name?.trim();
        const indexOfOtherFilterToCombine = filters?.findIndex(element => element.name?.trim() === `${dietaryName} Upon Request`)

        if (indexOfOtherFilterToCombine !== -1) {
          const updatedFiltersAtSecondSelection = [
            ...filters?.slice(0, indexOfOtherFilterToCombine),
            { ...filters[indexOfOtherFilterToCombine], selected: !filters[indexOfOtherFilterToCombine]?.selected },
            ...filters?.slice(indexOfOtherFilterToCombine + 1)
          ];
          updatedFilters = [
            ...updatedFiltersAtSecondSelection.slice(0, index),
            { ...updatedFiltersAtSecondSelection[index], selected: !updatedFiltersAtSecondSelection[index]?.selected },
            ...updatedFiltersAtSecondSelection.slice(index + 1)
          ];
        } else {
          updatedFilters = [
            ...filters.slice(0, index),
            { ...filters[index], selected: !filters[index]?.selected },
            ...filters.slice(index + 1)
          ];
        }

      } else if (filters[index].name?.trim() === 'Vegan') {
        const indexOfOtherFilterToCombine = filters.findIndex(element => element.name?.trim() === 'Vegan-Upon Request')
        
        if (indexOfOtherFilterToCombine !== -1) {
          const updatedFiltersAtSecondSelection= [
            ...filters.slice(0, indexOfOtherFilterToCombine),
            { ...filters[indexOfOtherFilterToCombine], selected: !filters[indexOfOtherFilterToCombine]?.selected },
            ...filters.slice(indexOfOtherFilterToCombine + 1)
          ];

          updatedFilters = [
            ...updatedFiltersAtSecondSelection.slice(0, index),
            { ...updatedFiltersAtSecondSelection[index], selected: !updatedFiltersAtSecondSelection[index]?.selected },
            ...updatedFiltersAtSecondSelection.slice(index + 1)
          ];
        } else {
          updatedFilters = [
            ...filters.slice(0, index),
            { ...filters[index], selected: !filters[index]?.selected },
            ...filters.slice(index + 1)
          ];
        }

      } else  {
        updatedFilters = [
          ...filters.slice(0, index),
          { ...filters[index], selected: !filters[index]?.selected },
          ...filters.slice(index + 1)
        ];
      }
      updateMenu && updateFilteredMenu(menuRestaurants, updatedFilters);
      return updatedFilters;
    });
  }
  // useEffect(() => {

  //   // const combo_selected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
  //   const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
  //   // Whenever localFilters change, update the URL

  //   if (isEmpty(user)) {
  //     const url = new URL(window.location.href);
  //     const params = new URLSearchParams(url.search);
  //     params.delete('filters-dietry');
  //     params.delete('filters-ingredient')

  //     filters?.forEach((filter) => {
  //       (filter?.selected && filter?.filter_type === 'dietary') && filter?.selected && params.append('filters-dietry', `${filter?.name}_${filter?.id}`);
  //       (filter?.selected && filter?.filter_type === 'ingredient') && filter?.selected && params.append('filters-ingredient', `${filter?.name}_${filter?.id}`);
  //     });
  //     url.search = params?.toString();
  //     window.history.replaceState({}, '', url?.toString());
  //   }

  // }, [filters]);


  const resetAllFilters = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('filters-dietry');
    params.delete('filters-ingredient')
    setFilters(filters => filters?.map(filter => ({ ...filter, selected: false })));
    getFilteredMenuData();
    handleResetFilters?.()
  }
  const updateFilteredMenu = (menuRestaurantsFilters: MenuRestaurantsFilter[], filters: MenuFilter[]) => {
    let selectedDietaries: number[] = [], selectedIngredients: number[] = [], selectedRestaurants: number[] = [];
    filters.forEach(filter => {
      (filter?.selected && filter.filter_type === 'dietary') && selectedDietaries.push(filter.id);
      (filter?.selected && filter.filter_type === 'ingredient') && selectedIngredients.push(filter.id);
    });
    menuRestaurantsFilters.forEach(restaurant => restaurant?.selected && selectedRestaurants?.push(restaurant?.id));
    getFilteredMenuData(selectedRestaurants, selectedDietaries, selectedIngredients);
  }
  const getFilteredMenuData = (selectedRestaurants: number[] = [], selectedDietaries: number[] = [], selectedIngredients: number[] = []) => {
    if (fromDashboard) {
      const { currentMeeting, menu_type } = parentState as ApprovedMeetingMenuDetailState;
      dispatch(getSingleDeliveryMenu(currentMeeting?.slug, menu_type, selectedRestaurants, selectedDietaries, selectedIngredients, undefined, true));
      currentMeeting && dispatch(getSingleDeliveryRecentOrders(currentMeeting?.slug, '', selectedRestaurants, selectedDietaries, selectedIngredients,))
    } else if (fromMarketplace) {
      const { restaurantId, mealTypeNumber} = parentState as MarketPlaceState;
      // Added filters checks for combos //
      // Conditionally calling the combos API in case of selection of combo from marketPlace
      const combo_selected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
      if (combo_selected?.combo_selected === 'true') {
        // const disabledRestaurants = menuRestaurants?.map((restaurant: any) => {
        //   return (restaurant?.sections?.lenght > 0) ? restaurant?.id : ''
        // })
        // const selectedComboRestaurantAddressIds = menuRestaurants?.map((restaurant: any) => {
        //   return restaurant?.address_id
        // })
        // const filteredComboClosedRestaurants: number[] = disabledRestaurants?.filter((id: any) => {
        //   return id
        // })

        // dispatch(getMenusOfRestaurantCombos(selectedComboRestaurantAddressIds, mealTypeForMenuCheckAPI, false, selectedRestaurants, selectedDietaries, selectedIngredients, true))
      } else {
        restaurantId && dispatch(getMenusOfRestaurant(restaurantId, mealTypeNumber, mealTypeForMenuCheckAPI, false, selectedRestaurants, selectedDietaries, selectedIngredients, true));
      }
    } else if (fromInvitedMeeting && invitedMeetingToken) {
        dispatch(getSingleDeliveryMenu(undefined, 'any', selectedRestaurants, selectedDietaries, selectedIngredients, invitedMeetingToken, true));
    }
  }

  const moreFilterSelected = filters.slice(topFiltersCount)?.find((item) => !!item?.selected);
  const appCueId = `ac-${isCompanyAdmin ? 'admin' : 'user'}-step-${isCompanyAdmin ? '5' : '4'}`

  const sortedMenuRestaurantsRatingWise = menuRestaurants?.sort((a, b) => b?.average_rating - a?.average_rating);
  const { currentMeeting: currentScheduler } = parentState as ApprovedMeetingMenuDetailState;

  const cutoffBasedClosedRestaurant = (restaurant: MenuRestaurantsFilter) => {
    let closedStatus
    let sameDayIsActive = false
    let regularSchedulerActive = false
    let currentSchedulerData = invitedMeetingToken ? currentDeliveryShareMeeting : currentScheduler
    let sameDayCutoffForCurrentUser = isCompanyAdmin ? currentSchedulerData?.sd_admin_cutoff_at : currentSchedulerData?.sd_cutoff_at

    if (dayjs(currentSchedulerData?.admin_cutoff_at).isAfter(new Date()) || dayjs(currentSchedulerData?.cutoff_at).isAfter(new Date())) {
      regularSchedulerActive = true
    }
    if (currentSchedulerData?.sd_activation_at && dayjs(currentSchedulerData?.sd_activation_at).isBefore(new Date()) && dayjs(sameDayCutoffForCurrentUser).isAfter(new Date())) {
      sameDayIsActive = true
    } else {
      sameDayIsActive = false
    }

    if (restaurant?.same_day === true) {
      if (sameDayIsActive) {
        closedStatus = false
      }
    } else {

      if (sameDayIsActive && regularSchedulerActive) {
        closedStatus = false
      } else if (sameDayIsActive && !regularSchedulerActive) {
        closedStatus = true
      } else {
        closedStatus = false
      }
    }

    return closedStatus
  }


  const filteredArray = filters?.filter((filter: any) =>
    !excludeNames?.includes(filter?.name?.trim())
  );


  return <>
    {/* Menu Filters For MOBILE SCREEN */}
    {/* {loggedInUserCompany.self_signup && (!isEmpty(filters)) &&
      <div className='px-3 px-md-5 ps-5 ps-md-0 remove-scroll-bar'>
        <div style={{ width: '100%' }} className='py-2 m-auto'>
          <div className='d-flex justify-content-start justify-content-lg-center position-relative' >
            {filters?.map((filter, index) =>
              <div key={index} className={`btn btn-lg dietary-logo position-relative px-3 my-2 ${filter?.selected ? 'dietary-menu-filter-selected' : 'dietary-menu-filter'} ${menuLoading ? 'disabled-opacity' : ''}`}
                onClick={e => filterChangeHandler(index, true)}
              >
                {filter?.selected ?
                  <div className='mr-2 pills-svg-wrapper'>{parse(`
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                         <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M24.563,12.563a12,12,0,1,1-12-12A12,12,0,0,1,24.563,12.563ZM11.174,18.916l8.9-8.9a.774.774,0,0,0,0-1.095L18.983,7.823a.774.774,0,0,0-1.095,0l-7.261,7.261-3.39-3.39a.774.774,0,0,0-1.095,0L5.047,12.789a.774.774,0,0,0,0,1.095l5.032,5.032a.774.774,0,0,0,1.095,0Z" transform="translate(-0.563 -0.563)" fill="#167ffb"/>
                        </svg>`
                  )}</div>
                  :
                  filter.logo ? <div className='mr-2'>{parse(filter.logo)}</div> : ''
                }
                <p className='text-capitalize'>{filter?.name}</p>
              </div>
            )}
          </div>
        </div>
      </div>} */}
    {screenWidthmarketPlace <= 440 && <>
      <div className='px-1 px-md-5 picking-point' style={{ background: '#F5F5F5' }}>
        <MenuDetailPageCarousel
          totalSlides={filteredArray?.length -2}
          visibleSlides={2}
          step={5}
          showCarouselButtons={showCarouselButtons}
          NextButtonContent={<img alt='Next' src={filterSliderChevronRight} />}
          BackButtonContent={<img alt='Back' src={filterSliderChevronLeft} />}
          NextButtonStyles={{ top: '45%', right: '-30px' }}
          BackButtonStyles={{ top: '45%', left: '-30px' }}
          sliderTrayClassName='mx-auto'
        >
          {filters?.map((filter, index) =>
            //Hide filters
            (filter?.name?.trim() === 'Gluten-Free Upon Request') ||
              (filter?.name?.trim() === 'Nut-Free Upon Request') ||
              (filter?.name?.trim() === 'Dairy-Free Upon Request') ||
              (filter?.name?.trim() === 'Vegan-Upon Request') ? ''
              :
              <Slide key={index} index={index}>
                <div className={`d-flex align-items-center ${lastIndexOfPreferedFilter === index ? 'prefered':''}`}>
                  <button className='cuisines-diet-pill cuisine-diet-preferences-selected btn btn-sm p-0 border-0'>
                    <div className={`btn btn-lg dietary-logo position-relative py-0 ${filter?.selected ? 'dietary-menu-filter-selected' : 'dietary-menu-filter'} ${menuLoading ? 'disabled-opacity' : ''}`}
                      onClick={e => filterChangeHandler(index, true)}
                      id={index === filters?.length - 1 && !moreFilterSelected ? appCueId : ''}
                    >
                      <div className='mx-auto dietary-logo dietary-logo-wrapper'>
                        {filter?.logo ? <div className='d-flex align-items-center justify-content-center' style={{ width: '36px', height: '36px' }} >{parse(filter?.logo)}</div> :
                          <img src={MarketplaceFilterDummyLogo} alt='menufilters_logo' style={{ width: '36px', height: '36px'}} />
                        }
                      </div>
                      <p className='text-capitalize mt-2 mb-0 dietary-labels'>{filter?.name}</p>
                    </div>
                  </button>
                  {/* {(lastIndexOfPreferedFilter === index) && <div>
                    <div className='filters-divider'></div>
                  </div>
                  } */}
                </div>
              </Slide>
          )}
        </MenuDetailPageCarousel>
        
        {selectedPill && <button className='px-1 px-md-5 mr-auto btn pl-3 w-100' onClick={resetAllFilters}>
          <div className='cuisines-dietries-view-more d-inline-flex ml-auto px-2 pl-2'>
            <h6 className='mt-0'>Reset All Filters</h6>
          </div>
        </button>}
      </div>
    </>
    }

    {screenWidthmarketPlace >= 440 && <>
      <div className='px-1 px-md-0 col-lg-12' style={{ background: '#f3fafd' }}>
        <div className="container d-flex px-0">
          {/* {(fromMarketplace || showPricingAndRatingButton) && <div className="col-lg-2 pl-0 pt-3 pb-3 pt-lg-4 pb-lg-3 px-sm-0 position-relative">
            <button className='btn btn-zink text-nowrap rounded-pill px-3 mr-3' onClick={() => setIsPopUpOpen(!isPopupOpen)}>Ratings & Pricing&nbsp;<i className="fas fa-chevron-down ml-2"></i></button>
          </div>} */}
          {/* {isFilterApplied && totalMenuFoodItemCount > 0 && <div><p className='text-gray-100 fw-600'>{totalMenuFoodItemCount} Results Found</p></div>} */}
        {/* {isPopupOpen && <div className="px-0">
          <CustomPopUp
            backdropStyles={{ opacity: 0, cursor: 'default' }}
            onPopUpClose={() => setIsPopUpOpen(!isPopupOpen)}
            popUpClassName='order-history-range-picker'
            popUpBodyStyles={{ left: 0 }}
            allowScrolling={true}
          >
            <div
              style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
              className='bg-white order-restaurant-selector'
            >
              <MarketPlaceRatinPopUp
                fromMarketPlace
                modalIsOpen={isPopupOpen}
                closeModal={() => setIsPopUpOpen(false)}
                price={menuPageFoodItemPrice}
                avg_rating={menuPageFoodItemRating}
                onRatingChange={handleMenuRatingChange}
                onAmountChange={handleMenuAmountChange}
                handleSubmit={handleSubmitForMenuPage}
                marketPlaceMenu={marketPlaceMenu}
              />
            </div>
          </CustomPopUp>
        </div>
        } */}
          <div className={`${showPricingAndRatingButton
              ? 'col-lg-10 picking-point-order px-0 pt-3 pb-3 pt-lg-4 pb-lg-3'
              : 'col-lg-12 picking-point px-0 py-2 carousel-position-order-page'
            } ${(!isEmpty(loggedInUser) && filteredArray?.length <= 8)
              ? 'filters-centered'
              : 'filters-centered-order'
            }`}>
            <MenuDetailPageCarousel
              totalSlides={filteredArray?.length - 3}
              visibleSlides={8}
              step={5}
            showCarouselButtons={showCarouselButtons}
              NextButtonContent={<img alt='Next' src={filterSliderChevronRight} />}
              BackButtonContent={<img alt='Back' src={filterSliderChevronLeft} />}
              NextButtonStyles={{ top: '45%', right: '-11px' }}
              BackButtonStyles={{ top: '45%', left: '-11px' }}
              sliderTrayClassName='mx-auto'
            >
              {filteredArray?.map((filter, index) =>
                //Hide filters
                (filter?.name?.trim() === 'Gluten-Free Upon Request') ||
                  (filter?.name?.trim() === 'Nut-Free Upon Request') ||
                  (filter?.name?.trim() === 'Dairy-Free Upon Request') ||
                  (filter?.name?.trim() === 'Vegan-Upon Request') ? ''
                  :
                  <Slide key={index} index={index}>
                    <div className={`d-flex align-items-center mx-1  ${lastIndexOfPreferedFilter === index ? 'prefered':''}`} style={filter?.name?.length < 14 ? { width: '80px' } : filter?.name?.length < 20 ? { width: '140px' } : { width: '150px' } }>
                      <button className='cuisines-diet-pill cuisine-diet-preferences-selected btn btn-sm p-0 border-0 no-hover-effects'>
                        <div className={`hover-element-effects btn btn-lg dietary-logo position-relative py-0 ${filter?.selected ? 'dietary-menu-filter-selected' : 'dietary-menu-filter'} ${menuLoading ? 'disabled-opacity' : ''}`}
                          onClick={e => filterChangeHandler(index, true)}
                          id={index === filters?.length - 1 && !moreFilterSelected ? appCueId : ''}
                        >
                          <div className='mx-auto dietary-logo dietary-logo-wrapper'>
                            {filter?.logo ? <div className='d-flex align-items-center justify-content-center' style={{ width: '36px', height: '36px' }} >{parse(filter?.logo)}</div> :
                              <img src={MarketplaceFilterDummyLogo} alt='menufilters_logo' style={{ width: '36px', height: '36px' }} />
                            }
                          </div>
                          <p className='text-capitalize m-0 dietary-labels'>{filter?.name}</p>
                        </div>
                      </button>
                      {/* {(lastIndexOfPreferedFilter === index) && <div>
                        <div className='filters-divide'></div>
                      </div>
                      } */}
                    </div>
                  </Slide>
              )}
            </MenuDetailPageCarousel>
          </div>
        </div>
        <div className="container d-flex align-items-center justify-content-center pt-0 mt-0">
          {((isFilterApplied && totalMenuFoodItemCount > 0) || selectedPill) &&
            <button className={`btn reset-filters-button ${!showPricingAndRatingButton ? 'w-100' : ''}`} onClick={resetAllFilters}>
              <div className={`d-flex justify-content-center align-items-center ${showPricingAndRatingButton && 'd-flex'}`}>
                {menuLoading ? <Skeleton height={25} width={260} /> : <>
                  {(fromMarketplace || showPricingAndRatingButton) && <p className='text-gray-100 fw-600 m-0'>{totalMenuFoodItemCount} Results Found</p>}
                  <div className={`cuisines-dietries-view-more d-flex align-items-center ${!showPricingAndRatingButton ? 'px-2 pl-2' : 'ml-4 mb-3'}`}>
                    {!fromMarketplace ? <div className='text-gray-100 fw-600 m-0'>
                      {calcuteTotalFilteredREsultsForYourOrder !== null ? calcuteTotalFilteredREsultsForYourOrder + ' Results Found'
                        : ''}
                    </div> : <></>}
                    <h6 className={`${!showPricingAndRatingButton ? 'ml-2 mt-2' : 'mt-4'}`}>Reset Filters</h6>
                  </div>
                </>}
              </div>
            </button>
          }
        </div>
      </div>
    </>
    }
    {(menuRestaurants?.length > 1) &&
      <div className='px-1 px-md-0 mt-5'>
        <div className="container px-0 cuisine-pill-picking-point">
          <MenuDetailPageCarousel
            totalSlides={menuRestaurants?.length}
            visibleSlides={restaurantCardCarousel}
            showCarouselButtons={showCarouselButtons}
            NextButtonContent={<img alt='Next' src={SliderChevronRight} />}
            BackButtonContent={<img alt='Back' src={SliderChevronLeft} />}
            NextButtonStyles={{ top: '30%', right: '-25px' }}
            BackButtonStyles={{ top: '30%', left: '-25px' }}
            sliderTrayClassName='mx-auto'
          >
            {sortedMenuRestaurantsRatingWise?.map((restaurant, index: number) =>
              <Slide key={index} index={index} className='pb-4 mx-1'>
                <div data-tip
                  data-for={`closed-restaurant-${index}`}
                  className={`restaurant-card ${((cutoffBasedClosedRestaurant(restaurant)) || restaurant?.sections?.length < 1) && 'closed-restaurant'} mx-1 ${restaurant?.selected ? 'selected-restaurant' : ''}  ${menuLoading ? 'disabled-opacity' : ''}`}
                  style={{ position: 'relative' }}
                  onClick={e => !(cutoffBasedClosedRestaurant(restaurant) || restaurant?.sections?.length < 1) && restaurantFilterChangeHandler(index, true)}
                >
                  {/* {restaurant?.restaurant_used_ina_year && loggedInUserCompany?.self_signup === false &&
                    <div className='new-restaurant-tag p-4'>
                      <p className='new-tag px-2'> New </p>
                    </div>
                  } */}
                  {/* {restaurant?.selected &&
                    <i className='fa fa-check selected-restaurants' aria-hidden='true'></i>
                  } */}
                  {restaurant?.logo_url ?
                    <div className='mr-2 mr-sm-3 restaurant-logo' style={{ backgroundImage: `url(${restaurant?.logo_url})` }}></div> :
                    <img width={32} height={32} className='mr-1 mr-sm-2 rounded-circle restaurant-logo' alt='Logo' src={RestaurantDummyLogo} />
                  }
                  <div style={{ width: 'calc(100% - 50px)' }} className='my-0'>
                    <div className="d-flex align-items-center">
                      <p className='text-truncate m-0 restaurant-name-heading'>{restaurant?.name}</p>
                      {restaurant?.restaurant_used_ina_year && loggedInUserCompany?.self_signup === false &&
                        <p className='text-white new-restaurant-badge ml-2 m-0'> New </p>
                      }
                    </div>
                    <span className='d-flex flex-wrap align-items-center'>
                    {!restaurant.average_rating ? '' :
                        <span className='d-inline-flex align-items-center text-capitalize'>
                          {/* <span className='star' style={{ width: '12px', height: '12px', background: 'white' }}>
                            <span className='rateValue'></span>
                          </span> */}
                          <i className="fas fa-star marketplace-combo pr-1 fa-xs" aria-hidden="true"> </i>
                          <p className='m-0 restaurant-card-rating'>{restaurant.average_rating.toFixed(1)}</p>
                        </span>
                      }
                      {restaurant?.cuisines?.split(',')?.slice(0, restaurant.average_rating ? 1 : 2)?.map((cuisine: string, index: number) =>
                        <span key={index} className='text-capitalize font-size-sm-11 restaurant-cuisine-label pl-2'>{cuisine}</span>)}
                    </span>
                    
                  </div>
                </div>
                {(cutoffBasedClosedRestaurant(restaurant) || restaurant?.sections?.length < 1) && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                  <ReactTooltip place='top' type='dark' id={`closed-restaurant-${index}`} effect='float' className='react-tooltip'>
                    <span> Restaurant Closed</span>
                  </ReactTooltip>
                }
              </Slide>
            )}
          </MenuDetailPageCarousel>
        </div>
      </div>
    }
  </>
}


export default MenuFilters;
