import React, { ChangeEvent, FC } from 'react';
import FormHeading from '../Common/FormHeading';
import InputField from '../../../Common/InputField';
import { NewCompanyAdminData } from '../Modals/AddNewAdminModal';

type AddNewAdminFormProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showHeading: boolean;
  adminData: NewCompanyAdminData;
  modalType: string;
  errors: NewCompanyAdminData;
};

const AddNewAdminForm: FC<AddNewAdminFormProps> = ({
  modalType,
  adminData,
  showHeading,
  handleChange,
  errors
}) => {
  return (
    <>
      {showHeading && (
        <FormHeading
          heading='Department Admin'
          description='Please use the form below to create at least one admin to manage this department. You can add more admins later.'
        />
      )}
      <div id='main' className='container'>
        {!showHeading && (
          <div className='form-row'>
            <div className='col-11 m-auto text-center'>
              <p className='text-muted'>
                Please use the form below to invite a new admin for this
                department.{' '}
              </p>
            </div>
          </div>
        )}
        <form noValidate className='text-left'>
          <div className='form-row'>
            <InputField
              label='First Name'
              type='text'
              name='firstName'
              value={adminData.firstName}
              onChange={handleChange}
              errors={errors}
              classnames='dept-admin-fName'
              isRequired
            />
            <InputField
              label='Last Name'
              type='text'
              name='lastName'
              value={adminData.lastName}
              onChange={handleChange}
              errors={errors}
              classnames='dept-admin-lName'
              isRequired
            />
          </div>
          <div className='form-row'>
            <InputField
              label='Cell Phone'
              type='text'
              name='phoneNumber'
              value={adminData.phoneNumber}
              onChange={handleChange}
              errors={errors}
              placeholder='XXX-XXX-XXXX'
              rightLabel='For Delivery Alerts'
              classnames='dept-admin-cellphone'
              isRequired
            />
            <InputField
              label='Desk Phone'
              type='text'
              name='deskNumber'
              value={adminData.deskNumber}
              onChange={handleChange}
              placeholder='XXX-XXX-XXXX'
              errors={errors}
              classnames='dept-admin-deskphone'
              isRequired
            />
          </div>
          <div className='form-row'>
            <InputField
              sm={12}
              label='Email Address'
              type='text'
              name='email'
              value={adminData.email}
              onChange={handleChange}
              errors={errors}
              readOnly={modalType === 'edit'}
              classnames='dept-admin-email'
              isRequired
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewAdminForm;