import axios from 'axios';
import { LOADING, GET_USERS_LIST_FOR_CONFIRMATION, SET_INVITE_VENDOR_BUTTON_LOADING, REMOVE_INVITE_VENDOR_BUTTON_LOADING, SET_ALL_BUTTONS_LOADING_STATE } from './types';
// import setAuthToken from '../utils/setAuthToken';
import { getError } from './errorActions';
import { showToastNotification } from '../Utilities/showToastNotification';
import { InviteUsersPayload } from '../Pages/Invites/InviteUsers/InviteUsers';
import { AppThunkAction } from '../store';
import { GetInvitedUserForSignup, SendConfirmInvitesPayload, SendVendorInvitePayload } from '../Interfaces/PayloadsAndResponses/Invite';
import { UserHeaders } from '../Interfaces/PayloadsAndResponses/Auth';

// Get InviteStatus Data
export const getInvitedUserForSignup = (inviteToken: string, redirectToLoginOrSignupPage: (response: GetInvitedUserForSignup) => void): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get('/auth/confirmation',
      {
        params:
        {
          confirmation_token: inviteToken,
          redirect_url: axios.defaults.baseURL + '/auth/validate_token'
        }
      }
    )
    .then(res => {
      const headers = res.headers;
      const data = res.data as GetInvitedUserForSignup
      const { data: user } = data ?? {};
      try {
        const newHeaders: UserHeaders = {
          'access-token': '',
          client: '',
          expiry: '',
          'token-type': '',
          uid: '',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en/US'
        };
        Object.keys(headers).forEach(function (key) {
          //console.log(key, res.headers[key])
          switch (key) {
            case 'access-token':
            case 'client':
            case 'expiry':
            case 'token-type':
            case 'uid':
              newHeaders[key] = headers[key];
              break;
            default:
              break
          }
        })
        //Save to LocalStorage
        localStorage.setItem('headers', JSON.stringify(newHeaders));
        if (user?.first_name) {
          user.first_name = user.first_name?.trim();
        }
        localStorage.setItem('user', JSON.stringify(user));
      } catch (e) {
        console.log('error', e);
      }

      redirectToLoginOrSignupPage(data);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// POST Request for getting Users before confirmation
export const sendInvite = (inviteData: InviteUsersPayload): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .post('/users/invite', inviteData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_USERS_LIST_FOR_CONFIRMATION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const sendVendorInvite = (invitationData: SendVendorInvitePayload, btnID: string): AppThunkAction => dispatch => {
  dispatch({ type: SET_INVITE_VENDOR_BUTTON_LOADING, payload: btnID });
  axios
    .post('/users/send_vendor_invite', invitationData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({ type: REMOVE_INVITE_VENDOR_BUTTON_LOADING, payload: btnID });
      showToastNotification('Vendor Invite has been sent successfully.', 'Success!')
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const sendSignupConfirmation = (userId: string | number | string[], adminId: string | number | string[], signupSuccessHandler: (message: string) => void): AppThunkAction => dispatch => {
  axios
    .get(`/user_requests/${userId}/send_invite?admin_id=${adminId}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if (signupSuccessHandler && res.data?.message)
        signupSuccessHandler(res.data?.message);
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};


export const setAllButtonsLoadingState = (): AppThunkAction => dispatch => {
  dispatch({ type: SET_ALL_BUTTONS_LOADING_STATE });
}

export const sendConfirmInvites = (inviteData: SendConfirmInvitesPayload, successCallback?: () => void): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .put('/users/invite', inviteData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      successCallback?.();
    })
    .catch(err =>
      dispatch(getError(err))
    );
};
// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}
