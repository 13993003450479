// // Packages
// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { connect } from 'react-redux';
// import { WithTranslation } from 'react-i18next';
// import dayjs from 'dayjs';
// // React JSX Components
// // Redux Dispatch Actions
// import { cancelDelivery } from '../../Actions/scheduleDeliveryActions';
// // Utilities
// import { showToastNotification } from '../../Utilities/showToastNotification';
// // Types
// import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
// import { ReduxRootState, Users } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { cancelCartOrder } from '../../Actions/cartActions';
// // CSS

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//     borderRadius: '18px'
//   }
// };

// Modal.setAppElement('#root');
// Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal.defaultStyles.overlay!.zIndex = 6;

// type CancelMeetingPopUpProps = {
//   i18n?: WithTranslation | null;
//   users?: Users,
//   canceledDelivery: Delivery | null,
//   orders: number | undefined,
//   passedCutOf: boolean,
//   onCancelMeeting: () => void,
//   onRef: (ref: CancelMeetingPopUpRef | null) => void,
//   cancelCartOrder?: (slug: string, cancel_reason: string, deleteSucessHandler: () => void) => void;
//   cancelDelivery: (deliverySlug: string, reason: string, meetingCanceledCallback: () => void) => void,
// }

// type CancelMeetingPopUpState = {
//   modalIsOpen: boolean,
//   cancelReason: string,
//   cancelMeetingLoading: boolean,
// }
// class CancelMeetingPopUp extends Component<CancelMeetingPopUpProps, CancelMeetingPopUpState> {

//   state = {
//     modalIsOpen: false,
//     cancelReason: '',
//     cancelMeetingLoading: false,
//   };

//   componentDidMount() {
//     this.props.onRef(this);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null);
//   }
//   meetingCanceledHandler = () => {
//     this.setState({ cancelMeetingLoading: false });
//     this.closeModal();
//     showToastNotification('Your meeting and its associated orders have been cancelled.', 'Cancelled Successfully');
//     this.props.onCancelMeeting();
//   }
//   meetingCancelHandler = () => {
//     this.setState({ cancelMeetingLoading: true });
//     const { slug } = this.props?.canceledDelivery || {};
//     const { users: { delivery_dates = {} } = {} } = this.props;

//     const formattedSelectedDeliveryDate = dayjs(this.props?.canceledDelivery?.delivery_at)?.format('YYYY-MM-DD')
//     const currentDateDeliveries = delivery_dates[formattedSelectedDeliveryDate] || [];

//     if (currentDateDeliveries[0]?.enterprise_converted) {
//       slug && this.props?.cancelCartOrder && this.props?.cancelCartOrder(slug, this.state?.cancelReason, this.deleteSucessHandler)
//     } else {
//       slug && this.props.cancelDelivery(slug, this.state.cancelReason, this.meetingCanceledHandler)
//     }
//   }
//   deleteSucessHandler = () => {
//     this.setState({ modalIsOpen: false, cancelReason: '' });
//     window.location.replace('/menu')
//   }

//   openModal = () => {
//     this.setState({ modalIsOpen: true, cancelMeetingLoading: false, cancelReason: '' });
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false, cancelMeetingLoading: false });
//   }
//   handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
//     this.setState({ [name]: value } as unknown as CancelMeetingPopUpState);
//   }
//   render() {
//     const { i18n } = this.props;
//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className='order-modal' id='orderModelBox'>
//           <div className='modal-dialog' style={{ maxWidth: '100%' }}>
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className={`modal-header bg-white`} style={{ width: '690px' }}>
//                 <h5 className='modal-title' id='modal-title'>  {i18n && i18n.t('Cancel Delivery')} </h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
//                 <div id='main' className='container'>
//                   <div className='form-row'>
//                     <div className='col-11 m-auto text-center'>
//                       {!this.props.passedCutOf ?
//                         <span>
//                           <h2 className='text-center'> {i18n && i18n.t('Cancel Delivery.')}</h2>
//                           <p className='text-muted'><span className='text-danger'>{this.props.orders}  {i18n && i18n.t('Orders')}</span>  {i18n && i18n.t('Rewill be cancelled for this meeting.quired')}</p>
//                         </span>
//                         :
//                         <span>
//                           {this.props.passedCutOf === true ?
//                             <span>
//                               <h2 className='text-center text-danger'> {i18n && i18n.t('Unable to Cancel Delivery')}</h2>
//                               <p className='text-muted'>
//                                 {i18n && i18n.t('Since it is within 24 hours of delivery, we are unable to cancel this meeting.')}
//                                 <br />
//                                 {i18n && i18n.t('Please email')}
//                                 <a href='mailto:orders@chowmill.com'>orders@chowmill.com</a>
//                                 {i18n && i18n.t('for any urgent requests.')}
//                               </p>
//                             </span> : ''
//                           }
//                         </span>
//                       }
//                     </div>
//                   </div>
//                   {this.props.passedCutOf ? '' :
//                     <form noValidate className='text-left'>
//                       <div className='form-row'>
//                         <div className='col-12 company-required-field p-3'>
//                           <h6 className='orderform-section-name float-left '>  {i18n && i18n.t('Reason for Cancellation')}</h6>
//                           <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
//                           <textarea className='form-control'
//                             id='name'
//                             name='cancelReason'
//                             placeholder={i18n ? i18n.t('Prefer different vendor, meeting rescheduled, etc') : ''}
//                             onChange={this.handleChange}>
//                           </textarea>
//                           {/* {this.state.firstName === '' ? <p className='invalid-feedback-password'> {this.props.i18n && this.props.i18n.t('First name can't be empty.' )}</p> : ''} */}
//                         </div>
//                       </div>
//                     </form>
//                   }
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {this.props.passedCutOf ? '' :
//           <div className={`modal-footer bg-white`} style={{ width: '100%' }}>
//             <button
//               type='submit'
//               className='confirm-cancel-delivery ml-3 btn btn-primary delivery-btn-width'
//               onClick={this.meetingCancelHandler}
//               disabled={this.state.cancelReason === '' || this.state.cancelMeetingLoading}
//             >
//               {i18n && i18n.t('Cancel Delivery')}
//             </button>
//           </div>
//         }
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n,
//   users: state.users
// });

// export type CancelMeetingPopUpRef = React.ElementRef<typeof CancelMeetingPopUp>;

// export default (connect(mapStateToProps, { cancelDelivery, cancelCartOrder })(CancelMeetingPopUp));


// conversion from class to functional component

import React,{useState} from 'react'
import { cancelDelivery } from '../../Actions/scheduleDeliveryActions'
import { cancelCartOrder } from '../../Actions/cartActions';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { useSelector,  useDispatch } from 'react-redux';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import { showToastNotification } from '../../Utilities/showToastNotification';
import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';

//prop types
type CancelMeetingPopUpProps = {
  canceledDelivery: Delivery | null,
  orders: number | undefined,
  passedCutOf: boolean,
  onCancelMeeting: () => void,
  cancelCartOrder?: (slug: string, cancel_reason: string, deleteSucessHandler: () => void) => void;
  cancelDelivery?: (deliverySlug: string, reason: string, meetingCanceledCallback: () => void) => void,
  isModalOpen:boolean,
  closeMeetingModal?: () => void;
}
const CancelMeetingPopUp:React.FC<CancelMeetingPopUpProps> = ({onCancelMeeting,canceledDelivery,passedCutOf,orders,isModalOpen,closeMeetingModal}) => {
  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelMeetingLoading, setCancelMeetingLoading] = useState<boolean>(false);

  const dispatch:any = useDispatch()

  const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '692px',
    padding: '0',
    borderRadius: '18px'
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

  // redux states
  const i18n = useSelector((state: ReduxRootState) => state.i18n?.i18n);
  const users = useSelector((state: ReduxRootState) => state.users)

  // functions
   const meetingCanceledHandler = () => {
    setCancelMeetingLoading(false)
    closeModal()
    showToastNotification('Your meeting and its associated orders have been cancelled.', 'Cancelled Successfully');
    onCancelMeeting();
  }

  const meetingCancelHandler = () => {
    setCancelMeetingLoading(true)
    const { slug } = canceledDelivery || {};
    const {delivery_dates = {}} = users ?? {}
    const formattedSelectedDeliveryDate = dayjs(canceledDelivery?.delivery_at)?.format('YYYY-MM-DD')
    const currentDateDeliveries = delivery_dates[formattedSelectedDeliveryDate] || [];
      if (currentDateDeliveries[0]?.enterprise_converted) {
      slug && cancelCartOrder && dispatch(cancelCartOrder(slug, cancelReason, deleteSucessHandler))
    } else {
      slug && dispatch(cancelDelivery(slug, cancelReason, meetingCanceledHandler))
    }
  }

   const deleteSucessHandler = () => {
    closeMeetingModal && closeMeetingModal()
      setCancelReason('')
    window.location.replace('/menu')
  }

  const closeModal = () => {
    closeMeetingModal && closeMeetingModal()
    setCancelMeetingLoading(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCancelReason(event.target.value);
  };
  return (
          <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className='order-modal' id='orderModelBox'>
          <div className='modal-dialog' style={{ maxWidth: '100%' }}>
            <div className='modal-content' style={{ border: 'none' }}>
              <div className={`modal-header bg-white`} style={{ width: '690px' }}>
                <h5 className='modal-title' id='modal-title'>  {i18n && i18n.t('Cancel Delivery')} </h5>
                <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
                <div id='main' className='container'>
                  <div className='form-row'>
                    <div className='col-11 m-auto text-center'>
                      {!passedCutOf ?
                        <span>
                          <h2 className='text-center'> {i18n && i18n.t('Cancel Delivery.')}</h2>
                          <p className='text-muted'><span className='text-danger'>{orders}  {i18n && i18n.t('Orders')}</span>  {i18n && i18n.t('Rewill be cancelled for this meeting.quired')}</p>
                        </span>
                        :
                        <span>
                            <span>
                              <h2 className='text-center text-danger'> {i18n && i18n.t('Unable to Cancel Delivery')}</h2>
                              <p className='text-muted'>
                                {i18n && i18n.t('Since it is within 24 hours of delivery, we are unable to cancel this meeting.')}
                                <br />
                                {i18n && i18n.t('Please email')}
                                <a href='mailto:orders@chowmill.com'>orders@chowmill.com</a>
                                {i18n && i18n.t('for any urgent requests.')}
                              </p>
                            </span> 
                        </span>
                      }
                    </div>
                  </div>
                  {!passedCutOf &&
                    <form noValidate className='text-left'>
                      <div className='form-row'>
                        <div className='col-12 company-required-field p-3'>
                          <h6 className='orderform-section-name float-left '>  {i18n && i18n.t('Reason for Cancellation')}</h6>
                          <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
                          <textarea className='form-control'
                            id='name'
                            name='cancelReason'
                            placeholder={i18n ? i18n.t('Prefer different vendor, meeting rescheduled, etc') : ''}
                            onChange={handleChange}>
                          </textarea>
                        </div>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {!passedCutOf &&
          <div className={`modal-footer bg-white`} style={{ width: '100%' }}>
            <button
              type='submit'
              className='confirm-cancel-delivery ml-3 btn btn-primary delivery-btn-width'
              onClick={meetingCancelHandler}
              disabled={cancelReason === '' || cancelMeetingLoading}
            >
              {i18n && i18n.t('Cancel Delivery')}
            </button>
          </div>
        }
      </Modal>
  )
}
export default CancelMeetingPopUp