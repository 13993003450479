import React, { FC, ChangeEvent, FocusEvent, useState } from 'react';
import GooglePlacesAutocomplete from '../../Common/GooglePlacesAutocomplete';
import InputField from '../../Common/InputField';
import { UserSignup } from '../../Interfaces/PayloadsAndResponses/Users';
// import { Link } from 'react-router-dom';
import { emailPattern } from '../../Utilities/validationPatterns';
import PhoneInput from 'react-phone-input-2';
import isEmpty from '../../Utilities/is_empty';

type SignupFormProps = {
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleValidation?: (event: FocusEvent<HTMLInputElement>) => void;
  valid?: any;
  handleAddress: AddressChangeHandler;
  handlePhone?: any;
  handle?: ((phone: any) => void | undefined) | undefined;
  invalidPhone?: () => void;
  errors: any;
  validAddressRef: boolean | null;
  validPhoneRef?: boolean | null;
  // value: SignUpDataType;
  backendResponse?: UserSignup;
  register?: any;
  getValues?: any;
  isMarketPlaceSignUp?: boolean;
  isCartSignUp?: boolean,
  handleContinueWithCompany: (withCompany: boolean) => void;
};

export type AddressChangeHandler = (address: {
  city?: string,
  state?: string,
  zipCode?: number | null,
  streetNumber?: string,
  street?: string,
  addressLine: string,
  longitude?: number | null,
  latitude?: number | null,
  timezone?: string,
}) => void;

const SignupForm: FC<SignupFormProps> = ({
  // handleChange,
  handleAddress,
  handlePhone,
  handle,
  invalidPhone,
  // valid,
  errors,
  validAddressRef,
  validPhoneRef,
  // value,
  backendResponse,
  // handleValidation,
  register,
  getValues,
  isMarketPlaceSignUp,
  isCartSignUp,
  handleContinueWithCompany
}) => {
  // const [addressErrors] = useState<any>(errors)

  // useEffect(() => {
  //   if (!isEmpty(errors.addressLine)) {
  //     valid();
  //   }
  //   // eslint-disable-next-line
  // }, [addressError, errors])

  // useEffect(() => {
  //   backendResponse?.company_exist === 0 && continueClick()

  //   // eslint-disable-next-line
  // }, [backendResponse])

  const [continueAnyway, setContinueAnyway ] = useState(false);

  const continueClick = () => {
    setContinueAnyway(true);
    handleContinueWithCompany(true);
  }
  const [phoneErr, setPhoneErr] = useState<any>('empty')
  // const invalidFormatError = 'Please match the format XXX-XXX-XXXX'
  // const emptyError = 'Please enter phone number'
  // const validPhone = ''

  const errorStatefun = () => {
      if(phoneErr === 'invalid'){
         return 'Please match the format XXX-XXX-XXXX'
      }else if(phoneErr === 'empty'){
        return 'Phone number is required'
      }else {
        return ''
      }
  }

  const AddressField: JSX.Element | undefined = !isMarketPlaceSignUp ?
    <div className='form-row'>
      <div
        className={`col-sm-12 company-required-field ${validAddressRef === false ? 'px-2 pt-3' : 'pt-3 px-2'}`}
      >
        <h6 className='orderform-section-name float-left pb-1'>Address</h6>
        {/* <h6 className='orderform-section-required muted float-right pt-0'>
          {' '}
          Required
        </h6> */}
        <GooglePlacesAutocomplete
          handleAddress={handleAddress}
          errors={errors}
        // classnames='dept-delivery-address'
        />
        {validAddressRef === false && (
        // { errors['address']?.message  && (
          <small className='invalid-feedback-password'>
            {' '}
            {'Please enter address'}
          </small>
        )}
      </div>
    </div> 
  : <></>;

  const conditionForFullSignUpForm = ((backendResponse?.success === true && isEmpty(backendResponse?.message)) || (backendResponse?.success === true && !isEmpty(backendResponse?.message) && continueAnyway)) ? true : false

  return (
    <>
      <div id='main' className='px-1'>
        {/* <form noValidate className='text-left'> */}
        {!continueAnyway && backendResponse?.success === true && !isEmpty(backendResponse?.message) &&
            <>
              <hr />
              <div className="alert alert-warning mt-3" role="alert">
            <h5 className="alert-heading">Hey, wait a second!</h5>
            <p>{backendResponse?.message}<span>
              {/* {isMarketPlaceSignUp &&
                <>
                  <br />
                  <Link to='/signin' > Login </Link>
                </>
              } */}
              </span></p>
                {/* Looks like an account for this company already exists. */}
                {/* {backendResponse?.company_exist === 1 && */}
                    <div onClick={continueClick} className='cursor-pointer text-underline'>
                      <strong>Continue anyway?</strong>
                    </div>
                  {/* } */}
                {/* {!backendResponse?.success && */}
                {/* <h5 className='signup_subtext'>
                      <Link to='/' >Login</Link>
                    </h5> */}
                {/* } */}
                {/* </p> */}
              </div>
            </>
          }

          <div className='form-row'>
            <InputField
              label='First Name'
              type='text'
              name='firstName'
              readOnly={conditionForFullSignUpForm}
              // onChange={handleChange}
              placeholder='Lisa...'
              errors={errors}
              register={{...register("firstName", {
                required: "Please enter first name"
              })}}
            />
            <InputField
              label='Last Name'
              type='text'
              name='lastName'
              readOnly={conditionForFullSignUpForm}
              // onChange={handleChange}
              placeholder='Miller...'
              errors={errors}
              register={{...register("lastName", {
                required: "Please enter last name",
              })}}
            />
          </div>
          <div className='form-row'>
            <InputField
              label='Your Work Email Address'
              type='email'
              name='email'
              readOnly={conditionForFullSignUpForm}
              // onChange={handleChange}
              placeholder='lisa@company.com'
              errors={errors}
              sm={12}
              autoComplete='off'
              register={{...register("email", {
                required: "Please enter email",
                pattern: {
                  value: emailPattern,
                  message: "Invalid email address"
                }
              })}}
            />
          </div>
          {conditionForFullSignUpForm && <>
          <div className='form-row'>
            <InputField
              label='Company Name'
              type='text'
              name='companyName'
              // onChange={handleChange}
              placeholder='ACME IT Department'
              errors={errors}
              register={{...register("companyName", {
                required: "Please enter company name"
              })}}
          />
           <div
            className={`col-sm-6 company-required-field ${validPhoneRef === false ? 'px-2 pt-3' : 'pt-3 px-2'
              }`}
          >     
            <PhoneInput
              // value={phone}
              specialLabel='Phone Number'
              country={'us'}
              onlyCountries={['us']}
              masks={{ us: '...-...-....' }}
              disableDropdown={true}
              placeholder={'XXX-XXX-XXXX'}
              // countryCodeEditable={false}
              disableCountryCode={true}
              isValid={(value: any, country: any) => {
                if (isEmpty(value)) {
                  setPhoneErr('empty')
                  return false
                }
                else if (!value.match(/^[0-9]{10}$/)) {
                  invalidPhone && invalidPhone()
                  setPhoneErr('invalid')
                  return false
                } else {
                  setPhoneErr('valid')
                  handle && handle(value)
                  return true
                }
              }}
            />
            <>{(validPhoneRef === false) && <small className='invalid-feedback-password'>
              {errorStatefun()}
            </small>
            }
            </>
          </div>
            {/* <InputField
              label='Phone Number'
              type='text'
              name='phoneNumber'
              // onChange={handleChange}
              errors={errors}
              maxlength={12}
              register={{...register("phoneNumber", {
                required: "Please enter phone number",
                pattern: {
                  value: phoneNumberPattern,
                  message: "Please match the format XXX-XXX-XXXX"
                }
              })}}
              // value={value.phoneNumber}
              // onBlur={handleValidation}
            /> */}
          </div>
          {(isMarketPlaceSignUp || isCartSignUp) ? <></> : AddressField }
          {/* <div className='form-row'>
            <div
              className={`col-sm-12 company-required-field ${validAddressRef === false ? 'px-3 pt-2' : 'p-3'
                }`}
            >
              <h6 className='orderform-section-name float-left '>Address</h6>
              {/* <h6 className='orderform-section-required muted float-right pt-0'>
                {' '}
                Required
              </h6> */} {/*
              <GooglePlacesAutocomplete
                handleAddress={handleAddress}
                errors={errors}
              // classnames='dept-delivery-address'
              />
              {validAddressRef === false && (
              // { errors['address']?.message  && (
                <small className='invalid-feedback-password'>
                  {' '}
                  {'Please enter address'}
                </small>
              )}
            </div>
          </div> */}
          <div className='form-row'>
            <InputField
              label='Password'
              type='password'
              name='password'
              // onChange={handleChange}
              errors={errors}
              autoComplete='on'
              // onBlur={handleValidation}
              register={{...register("password", {
                required: "Please enter password",
                // minLength: {
                //   value: 6,
                //   message: "Please enter at least 6 characters"
                // }
              })}}
            />
            <InputField
              label='Confirm Password'
              type='password'
              name='confirmPassword'
              // onChange={handleChange}
              errors={errors}
              autoComplete='on'
              // onBlur={handleValidation}
              register={{...register("confirmPassword", {
                required: "Please enter confirm password",
                minLength: {
                  value: 6,
                  message: "Please enter at least 6 characters"
                },
                validate: {
                  matchesPreviousPassword: (value: any) => {
                    const { password } = getValues();
                    return password === value || "Passwords didn't match. Try again.";
                  }
                }
              })}}
            />
          </div>
          </>
          }

          {/* <div className='form-row'>
            <InputField
              label='First Name'
              type='text'
              name='firstName'
              onChange={handleChange}
              placeholder='Lisa...'
              errors={errors}
              register={register}
            />
            <InputField
              label='Last Name'
              type='text'
              name='lastName'
              onChange={handleChange}
              placeholder='Miller...'
              errors={errors}
            />
          </div> */}
          {/* <div className='form-row'>
            <InputField
              label='Your Work Email Address'
              type='email'
              name='email'
              onChange={handleChange}
              placeholder='lisa@company.com'
              errors={errors}
              sm={12}
              autoComplete='off'
            />
          </div> */}
          {/* { continueAnyway && */}
          {/* <> */}
          {/* <div className='form-row'>
            <InputField
              label='Company Name'
              type='text'
              name='companyName'
              onChange={handleChange}
              placeholder='ACME IT Department'
              errors={errors}
              classnames='dept-delivery-suite-number'
            />
            <InputField
              label='Phone Number'
              type='text'
              name='phoneNumber'
              onChange={handleChange}
              errors={errors}
              // value={value.phoneNumber}
              onBlur={handleValidation}
            />
          </div> */}
          {/* <div className='form-row'>
            <div
              className={`col-sm-12 company-required-field ${errors?.addressLine ? 'px-3 pt-2' : 'p-3'
                }`}
            >
              <h6 className='orderform-section-name float-left '>Address</h6>
              <GooglePlacesAutocomplete
                handleAddress={handleAddress}
                errors={addressError}
              />
              {addressError?.addressLine && (
                <small className='invalid-feedback-password'>
                  {' '}
                  {addressError?.addressLine}
                </small>
              )}
            </div>
          </div> */}
          {/* <div className='form-row'>
            <InputField
              label='Password'
              type='password'
              name='password'
              onChange={handleChange}
              errors={errors}
              autoComplete='on'
              onBlur={handleValidation}
            />
            <InputField
              label='Confirm Password'
              type='password'
              name='confirmPassword'
              onChange={handleChange}
              errors={errors}
              autoComplete='on'
              onBlur={handleValidation}
            />
          </div> */}
        {/* </form> */}
      </div>
    </>
  );
};

export default SignupForm;