import {
  createStore,
  applyMiddleware,
  compose,
  AnyAction,
  Action,
} from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { ReduxRootState } from "./Interfaces/ReduxInterface/ReduxRootState";
import rootReducer from "./Reducers";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

export type AppThunkAction<
  DispatchAction extends Action = AnyAction,
  ReturnType = void
> = ThunkAction<ReturnType, ReduxRootState, unknown, DispatchAction>;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['cart'] // just cart will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk];

// const store = createStore(
//     rootReducer,
//     initialState,
//     compose(
//         applyMiddleware(...middleware),
//         //    window.__REDUX_DEVTOOLS_EXTENSION__  && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// );
// export default store;

const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  initialState,
    compose(
      applyMiddleware(...middleware)
      //    window.__REDUX_DEVTOOLS_EXTENSION__  && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;