import { LocalStorageCompany, User, UserType } from '../Interfaces/PayloadsAndResponses/Auth';

export const getloggedInUser = () => {
    const loggedInUser = JSON.parse(localStorage.getItem('user') || '{}') as User;
    const loggedInUserCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
    return {
        loggedInUser,
        loggedInUserCompany,
        isCompanyAdmin: loggedInUser.user_type === UserType.ADMIN,
        isCompanyManager: loggedInUser.user_type === UserType.MANAGER,
        isCompanyUser: loggedInUser.user_type === UserType.USER,
        isUnsubsidizedUser: loggedInUser.user_type === UserType.UNSUBSIDIZED_USER,
    }
}

export type GetloggedInUser = ReturnType<typeof getloggedInUser>