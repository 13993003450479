import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { User } from '../Interfaces/PayloadsAndResponses/Auth';

const AppCues = () => {
  // useParams Imported to rerender page on Route Change so that AppCue.page could be called
  // const params = useParams();
  // const didMount = useRef(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    localStorage.setItem('apc_user_id', JSON.stringify(user.email))
    // const AppCue = window as any;
    // if (user?.email && AppCue?.Appcues) {
    //   if (!didMount.current) {
    //     didMount.current = true;
    //     AppCue.Appcues?.identify(user?.email, {
    //       Role: user?.user_type === UserType.ADMIN ? 'Admin' : 'User'
    //     });
    //   } else {
    //     AppCue.Appcues?.page();
    //   }
    // }
  }, []);

  return <></>
}

export default AppCues;