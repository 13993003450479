import { CART_ADDRESS_IDS, CART_AT_SERVER, SAVE_ORDER_DATA_FOR_CONFIRMATION, SAVE_ITEM_NAME_FOR_NOTIFICATION, CART_DISPLAY_CHECK, RESET_STATE, CONTINUE_ORDERING_FOR_SAME_CART } from "../Actions/types";
import { PlaceCartOrderPayload } from "../Interfaces/PayloadsAndResponses/Cart";
import { ServerCart, ServerCartData } from "../Interfaces/ReduxInterface/ReduxRootState";

const initialStateServerCart: ServerCart = {
  // items: [],
  // summary: {} as CartSummary,
  // details: {} as CartDetails,
  // cart_at_login: {} as CartAtLogin,
  cart_at_login: {} as ServerCartData,
  address_ids: [],
  cart_order_data_for_confirmation: {} as PlaceCartOrderPayload,
  itemName: '',
  cart_display_check: false,
  continue_ordering_for_same_cart: false
};

const serverCartReducer = (state: ServerCart = initialStateServerCart, action: any): ServerCart => {
  switch (action.type) {
    case CART_AT_SERVER:
      return {
        ...state,
        cart_at_login: action.payload
      };
    case CART_ADDRESS_IDS:
      return {
        ...state,
        address_ids: action.payload,
      }
    
    case SAVE_ORDER_DATA_FOR_CONFIRMATION:
      return {
        ...state,
        cart_order_data_for_confirmation: action.payload
      }
    case SAVE_ITEM_NAME_FOR_NOTIFICATION:
      return {
        ...state,
        itemName: action.payload
      }
      case CART_DISPLAY_CHECK:
        return{
          ...state,
          cart_display_check: action.payload
        }
    case CONTINUE_ORDERING_FOR_SAME_CART:
      return {
        ...state,
        continue_ordering_for_same_cart: action.payload
      }
    case RESET_STATE:
      return initialStateServerCart;
    default:
      return state;
  }
};
export default serverCartReducer;