// import React, { useEffect, useState, useCallback, useRef, FC } from 'react';
import React, { Component, createRef } from 'react';
// import $ from 'jquery'; // used for page reload earlier
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
// React Components
import MenuArea from '../Common/MenuArea';
import Header from '../../../Common/HeaderFooter/Header';
import OrderPopup, { OrderPopupRef } from '../../../Common/Popups/OrderPopup';
import Marketplace, { MarketplaceRef } from './Marketplace';
import { ShareMeetingPopUpRef } from '../../../Common/Popups/ShareMeetingPopUp';
import { MeetingDetailsPopUpRef } from '../../../Common/Popups/MeetingDetailsPopUp';
import { MenuFoodItemsLoading } from '../../../Common/Loadings/MenuPage/MenuDetailLoadings';
// Redux Actions
import { getOrderDetails } from '../../../Actions/orderActions';
import { getAnnouncements } from '../../../Actions/userActions';
import { getCompanyForOrderPurpose } from '../../../Actions/companyActions';
// import { SET_POPUP_STATE_ON_MENU_DETAIL_PAGE } from '../../../Actions/types';
import { getShowMenuTab, getSingleDeliveryMenu } from '../../../Actions/menuActions';
import { getDeliveryDates, getSingleSchedule } from '../../../Actions/scheduleDeliveryActions';
// Utitlities
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { getPricesToShown } from '../../../Utilities/getPricesToShown';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
import { dashboardTranslations } from '../../../Translations/dashboardTranslations';
import { showToastNotification } from '../../../Utilities/showToastNotification';
// Types
import { Cuisine } from '../../../Interfaces/PayloadsAndResponses/Cuisines';
import { GetAnnouncements } from '../../../Interfaces/PayloadsAndResponses/Users';
// import { LocalStorageCompany, User, UserType } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { LocalStorageCompany, User } from '../../../Interfaces/PayloadsAndResponses/Auth';

import { Delivery, SchedualeRequest, DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { Menu, OrderDetails, ReduxRootState, SchedulesDetails, Users } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// CSS
import 'animate.css';
import '../CSS/custom.css';
import 'rc-steps/assets/index.css';
// import 'rc-steps/assets/iconfont.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Cart from '../../Cart/Cart';
import isEmpty from '../../../Utilities/is_empty';
import { MARKETPLACE_PAGE, MARKETPLACE_SECTION_SCROLL, MARKETPLACE_SECTION_SCROLL_PERMISSION } from '../../../Actions/types';
import store from '../../../store';
import { getDateForMarketplace } from '../../../Utilities/getDateForMarketplace';
import { getDynamicTitles } from '../../../Actions/marketplaceActions';
import getPlaceDetails from '../../../Utilities/locationApi';
import getAddressStringData from '../../../Utilities/addressStringData';
import { modifyAddressInUrl } from '../../../Utilities/urlHandlingUtility';
import { AddressDataType } from '../../../Interfaces/PayloadsAndResponses/Address';
import { Helmet } from 'react-helmet';
type Params = { date: string, meetingSlug?: string, menu_type: string | undefined, address_id: string, dynamicAddressString: string };

type PublicMarketPlaceProps = {
  i18n: WithTranslation | null;
  menu: Menu;
  user: Users;
  order: OrderDetails;
  cuisines: Cuisine[] | null;
  announcements: GetAnnouncements;
  schedules_details: SchedulesDetails;
  schedule_update_request: SchedualeRequest | null;
  getCompanyForOrderPurpose: () => void;
  getShowMenuTab: () => void;
  getAnnouncements: () => void;
  getDynamicTitles: (dynamicTitle: string | null, seoRedirectfun?:()=>void) => void;
  getOrderDetails: (slug: string) => void;
  getSingleSchedule: (slug: string) => void;
  getDeliveryDates: (from?: string, to?: string) => void;
  getMarketplaceIPAddressData: (date?: any) => void;
  getSingleDeliveryMenu: (scheduleSlug: string, menu_type: string, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], token?: string, filterPurpose?: boolean) => void;
} & RouteComponentProps<Params>;

export type PublicMarketPlaceState = {
  cancelled: boolean;
  screenWidth: number,
  cloneOrder: boolean;
  isOpenSidebar: boolean;
  showDeliveryOrdersSidebar: boolean;
  companyData: null | LocalStorageCompany,
  menu_type: string | undefined,
  address_id: number | undefined;
  field_required: boolean | undefined;
  scheduleMarketplaceDelivery: boolean;
  currentMeeting: Delivery | null | undefined;
  showCart: boolean;
  showSignupPopup: boolean;
  localStorageStatus: string;
  // cartItemsCount: number;
  hideHeaderCart: boolean;
  selectedTypeheadPill: Array<string>;

}

class PublicLocationMarketPlace extends Component<PublicMarketPlaceProps, PublicMarketPlaceState> {
  orderPopup = createRef<OrderPopupRef>().current;
  shareMenu = createRef<ShareMeetingPopUpRef>().current;
  // RatingPopUpRef unused here
  // ratingPopUp = createRef<RatingPopUpRef>().current;
  marketplace = createRef<MarketplaceRef>().current;
  meetingPopup = createRef<MeetingDetailsPopUpRef>().current;
  private timeoutId: number | undefined;
  initialPrice = 0;
  searchKeyWordsFromURL=[]


  state: PublicMarketPlaceState = {
    menu_type: '',
    cancelled: true,
    address_id: undefined,
    cloneOrder: false,
    companyData: null,
    currentMeeting: null,
    isOpenSidebar: false,
    field_required: undefined,
    showDeliveryOrdersSidebar: false,
    scheduleMarketplaceDelivery: false,
    screenWidth: window.document.body.clientWidth,
    showSignupPopup: false,
    showCart: false,
    localStorageStatus: 'No change',
    // cartItemsCount: 0,
    hideHeaderCart: false,
    selectedTypeheadPill: [],
  }

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.document.body.clientWidth })
  }


  componentDidMount() {

    document.title = 'Chowmill: Office food delivery ';
    // Updating meta description
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', 'Get Food Delivered At Work With Chowmill');
    }


    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    });
    const {
      // getAnnouncements, getSingleSchedule, getSingleDeliveryMenu, getCompanyForOrderPurpose, getShowMenuTab,
      history,
      // menu: { menu_tab_meeting_loading },
      match: { params: { address_id,
        // date = null, meetingSlug = null 
      } },
    } = this.props

    const url = window.location.pathname?.split('/')
    const [, home, date, address_id_old] = url

    if (!isEmpty(address_id_old)) {
      // const addressPlaceId = `${address_id_old}` ?? ''
      if (!isEmpty(home) && home === 'home' && !isEmpty(date)) {
        // Redirect from the OLD URL to new one
        window.location.replace(`/get-address?placeId=${address_id_old}`);
      }
    }


    const queryStringPublic = window.location.search;
    // Create a URLSearchParams object
    const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
    const titleValuePublic = paramsDataDatapublic.get('address');
    const optionalPublicDate = paramsDataDatapublic.get('date')

    if (titleValuePublic) {
      localStorage?.removeItem?.('address');
      localStorage?.removeItem?.('address-placeId');
      localStorage?.removeItem?.('no-response')
      // Fetch location data using the utility function
      //  this.fetchLocationStringData(`${titleValuePublic}`);
      this.fetchLocationStringData(`${titleValuePublic}`).then(() => {
        // Proceed with the rest of the logic

      }).catch(error => {
        console.error('Error fetching location string data:', error);
      });
    } else {
      // const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
      // console.error('Address String not found in the URL');
    }

    const queryString = window.location.search;
    // Create a URLSearchParams object
    const paramsDataData = new URLSearchParams(queryString);
    const titleValue = paramsDataData.get('k');

    this.checkLocalStorageChanges();

    if (!isEmpty(titleValue)) {
      localStorage.setItem('SEOTitle', JSON.stringify(titleValue))
      this.props.getDynamicTitles(titleValue)
    } else {
      this.props.getDynamicTitles('')
    }

    if (!isEmpty(this.props.location?.hash)) {
      store.dispatch({
        type: MARKETPLACE_SECTION_SCROLL,
        payload: this.props.location?.hash
      })
    }

    //let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') // date for marketplace with threshold: 2pm
    // const dateForMarketplace = dayjs().add((dayjs().isAfter(new Date(thresholdDateTime)) ? 2 : 1), 'day').format('M-D-YYYY');

    const dateForMarketplace = optionalPublicDate ?? getDateForMarketplace()
    const localStorageAddress: AddressDataType = JSON.parse(localStorage.getItem('address') || '{}')



    if (!localStorage.getItem('address') && !localStorage.getItem('user')) {

      // Redirection to chowmill.com stopped from app.chowmill.com
      // this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
      // window.location.replace('https://chowmill.com/')

      //Stopped Auto Location detection
      // this.props.getMarketplaceIPAddressData()
    }
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    // const searchURLData = window.location.search
    // this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
    if (!isEmpty(user?.name) && isEmpty(titleValue)) {
      if (history.location?.search.split('=')[1] && history.location?.search.split('=')[1] !== 'billing') {

        const order_id = paramsDataData.get('order_id');
        const runningmenu_id = paramsDataData.get('runningmenu_id');
        order_id && localStorage.setItem('order_id', `${order_id}`);
        runningmenu_id && localStorage.setItem('runningmenu_id', `${runningmenu_id}`);
      }
      this.props.history?.push({ pathname: `/signin` })
    } else {

      const { isCompanyAdmin } = getloggedInUser()
      if (!isEmpty(titleValue) && isCompanyAdmin) {
        localStorage.setItem('SEOTitle', JSON.stringify(titleValue))
        this.props.getDynamicTitles(titleValue, () => { })
      } else {
        const urldata = window.location.pathname
        const [, url, marketPlaceDate, addressplaceid, restaurant_name, restid] = urldata?.split('/')
        if (url && marketPlaceDate && addressplaceid && restid && restaurant_name) {
          store.dispatch({
            type: MARKETPLACE_SECTION_SCROLL_PERMISSION,
            payload: false
          })
          const { dynamicAddressString } = this.props.match.params
          if (isEmpty(dynamicAddressString)) {
            // alert('menu--444')
            this.props.history.replace({ pathname: `/restaurant/${dateForMarketplace}/${addressplaceid}/${restaurant_name}/${restid}` })
          }
        } else {

          if (!isEmpty(addressplaceid)) {
            // getAddressDataPlaceId(addressplaceid)
          }
          const { dynamicAddressString } = this.props.match.params
          if (isEmpty(dynamicAddressString)) {
            // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
            if (!isEmpty(addressplaceid)) {
              // const placeID= localStorageAddressPlaceId?.placeId ?? addressplaceid 
              const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
              if (!isEmpty(dynamicTitle)) {
                this.props.getDynamicTitles(dynamicTitle)
              } else {
                this.props.getDynamicTitles('')
              }

              if (marketPlaceDate) {
                // alert('herer1')
                // this.props.history.push({ pathname: `/home/${marketPlaceDate}/${address_id}${!isEmpty(searchURLData) ? `${searchURLData}` : ''}` })
              } else {
                // alert('herer11')
                // this.props.history.push({ pathname: `/home/${dateForMarketplace}/${address_id}${!isEmpty(searchURLData) ? `${searchURLData}` : ''}` })
              }
            } else {
              if (titleValuePublic) {

              } else {
                // alert('herer111')
                !isEmpty(localStorageAddress) && modifyAddressInUrl(localStorageAddress)
                // this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
              }
            }
          }
        }
      }

    }

    this.scheduleMarketplace();

    dashboardTranslations();


    if (0) {
      // $(window).bind('popstate', function () {
      //   window.location.reload();
      // });
      //javascript equivalent for jquery we can handle page refresh by state
      window.addEventListener('popstate', function () {
        window.location.reload();
      });
    }

    if (address_id === null) {
      history.push('/signup');
      return;
    }
    // document.title = 'MarketPlace | Chowmill';
    window?.addEventListener('resize', this.updateScreenWidth);

    // On Escape close sidebar
    const handleKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        this.setState({ showDeliveryOrdersSidebar: false })
        this.setState({ showSignupPopup: false })
        this.setState({ showCart: false })
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }

  fetchLocationData = async (placeId: string) => {
    const data = await getPlaceDetails(placeId);
    this.saveAddress(data);
  };


  fetchLocationStringData = async (addressString: string) => {
    const data = await getAddressStringData(addressString)
    this.saveAddress(data);
    return data
  };


  hideCartOnHeader = () => {
    this.setState({ hideHeaderCart: true })
  }
  saveAddress = (address: any) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    if (isEmpty(user?.phone_number)) {
      if (!isEmpty(address?.latitude)) {
        localStorage.setItem("address", JSON.stringify(address));
        localStorage?.removeItem?.('no-response')
      } else {
        const invalidAddress = {
          googleResponse: 'invalid'
        }
        localStorage.setItem("no-response", JSON.stringify(invalidAddress));
      }
    }
  };

  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateScreenWidth);
    clearTimeout(this.timeoutId);
  }

  onUpdatePage = () => {
    if (this.state.scheduleMarketplaceDelivery) {
      this.setState({ scheduleMarketplaceDelivery: false });
    }
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    // this.props.match.params.meetingSlug && this.props.getSingleSchedule(this.props.match.params.meetingSlug)
  }


  checkLocalStorageChanges = () => {
    // const currentLocalStorage = JSON.stringify(localStorage);
    const currentLocalStorage = localStorage.getItem('address-placeId');

    if (localStorage.lastLocalStorage === undefined) {
      localStorage.lastLocalStorage = currentLocalStorage;
      this.setState({ localStorageStatus: 'No changes in Local Storage.' });
    } else if (localStorage.lastLocalStorage !== currentLocalStorage) {
      this.setState({ localStorageStatus: 'Local Storage has changed!' });



      // const urldata = window.location.pathname
      const { dynamicAddressString } = this.props.match.params
      const localStorageAddress : AddressDataType = JSON.parse(localStorage.getItem('address') || '{}')

      // const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
      if (isEmpty(dynamicAddressString)){
       !isEmpty(localStorageAddress) && modifyAddressInUrl(localStorageAddress)        
      }
    }

    localStorage.lastLocalStorage = currentLocalStorage;

    // Check for changes periodically (e.g., every 2 seconds)
    this.timeoutId = window.setTimeout(this.checkLocalStorageChanges, 2000);
    // this.timeoutId = setTimeout(this.checkLocalStorageChanges, 2000);
  };
  scheduleMarketplace = () => {
    this.setState({ scheduleMarketplaceDelivery: true, currentMeeting: null });
    if (this.marketplace !== null) {
      this.marketplace.setState({ viewRestaurantMenu: false });
    }
  }

  redirectToNewDelivery = (newDelivery: DeliveryOnSingleDate, deliveryDate: string | Date) => {
    if (!!newDelivery && !!deliveryDate && this.state?.currentMeeting?.slug !== newDelivery?.slug) {
      // this.setState({
      //   scheduleMarketplaceDelivery: false,
      // });
    }
  }

  cutOffPassed = (cutoff_at?: string) => {
    // let cutOffPassed, companyAdmin = this.user && this.user.user_type === UserType.ADMIN;
    let cutOffPassed, companyAdmin = true;


    if (companyAdmin === true) {
      cutOffPassed = cutoff_at ? dayjs(cutoff_at).isBefore(new Date()) : dayjs(this.state.currentMeeting?.admin_cutoff_at).isBefore(new Date());
    } else {
      cutOffPassed = dayjs(this.state.currentMeeting?.cutoff_at).isBefore(new Date());
    }
    return cutOffPassed;
  }

  openModalEdit = (id: number, runningmenu: Delivery) => {
    this.orderPopup?.setState({
      companyData: JSON.parse(localStorage.getItem('company') || '{}'),
      menu_type: runningmenu.menu_type,
      model_type: 'edit',
      per_user_copay: runningmenu.per_user_copay
    });
    this.orderPopup?.openModalEdit(id);
  }

  onCancelMeeting = () => {
    this.props.history.push({ pathname: `/dashboard` });
  }
  openCart = () => {
    this.setState({ showCart: true })
  }

  onUpdateOrder = () => {
    // Updating View all Items Popup orders in case of order update.
    this.state?.showDeliveryOrdersSidebar && this.state.currentMeeting?.slug && this.props.getOrderDetails(this.state.currentMeeting.slug);
    // check if order successfully placed and at the time of order custom
    // fields were required than make it not required to avoid display it on popup
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    // Get Updated menu for current delivery after new order or Order Update
    this.props.match.params.meetingSlug && this.props.getSingleDeliveryMenu(this.props.match.params.meetingSlug, 'lunch');
  }

  openMeetingSpecific = () => {
    // if (this.user && this.user.user_type === UserType.ADMIN && this.state.currentMeeting?.field_required) {
    if (this.state.currentMeeting?.field_required) {

      this.meetingPopup?.openModal(this.state.currentMeeting.slug, 'approved', true);
    }
  }

  truncateString = (str: string, maxLen: number, separator: string = ' ') => {
    if (str?.length <= maxLen) return str;
    return str?.substr(0, str?.lastIndexOf(separator, maxLen)) + '...';
  }

  getPricesToShown = (foodPrice: number, skip_markup: boolean, per_meal_budget: number) => {
    return getPricesToShown(foodPrice, skip_markup, this.state.companyData ?? getloggedInUser().loggedInUserCompany, per_meal_budget);
  }

  scheduleMarketplaceHiddenBtnHandler = () => {
    // if (this.company.enable_marketplace) {
    this.scheduleMarketplace()
    // } else if (!this.company.enable_marketplace && this.meetingPopup) {
    //   this.meetingPopup.openModal('', 'create')
    // }
  }

  trimRunningMenuName = (name: string) => {
    if (name?.split(' ’s Delivery').length > 1) {
      return name?.split(' ’s Delivery')[0] + '’s Delivery' + name?.split(' ’s Delivery')[1];
    } else {
      return name;
    }
  }

  render() {
    // Props Destructuring
    const {
      match: { params: { date = '',
       } 
       = {}
       } = {},
      menu: {
        menu,
        // menu_tab_meeting,
        loading: menuLoading,
        isOpenPopupOnMenuDetailPage,
        delivery_recent_orders_loading,
        delivery_dynamic_sections_loading,
      },
      // schedules_details: { single_schedule_loading },
    } = this.props;
    // State Destructuring
    const {
      screenWidth,
      currentMeeting,
      // showDeliveryOrdersSidebar,
      scheduleMarketplaceDelivery,
      showCart
    } = this.state;

    const queryStringPublic = window.location.search;
    const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
    const optionalPublicDate = paramsDataDatapublic.get('date')

    const urlOptionalDate = dayjs(`${optionalPublicDate}`)
    const calculateDateForMarketplace = getDateForMarketplace()
    const getPublicDate = dayjs(`${urlOptionalDate}`, 'YYYY-MM-DD').toDate()


    const searchParams = new URLSearchParams(this.props.location.search);
    const marketPlaceSearchTerms: any = searchParams.getAll('search') ?? [];
    this.searchKeyWordsFromURL = marketPlaceSearchTerms

    console.log('date', date)


    // Getting responsive number of slides for Carousel on Menu Detail Page.
    const {
      // noOfSlidesForRestaurants,
      noOfSlidesForUserLiking,
      noOfSlidesForFoodItems,
    } = getResponsiveNoOfSlides();

    // let buffet_no_menu: number = 0;
    // let document: Document = window.document;
    let MenuAreaSection: false | JSX.Element | JSX.Element[] | undefined;
    // restaurants: DeliveryMenuRestaurant[];
    // To prevent click event on Dragging Slider Items in Carousel
    // let isDraggingCarouselItems = false;

    const showCarouselButtons = screenWidth > 768;
    const isMarketPlacePreview = scheduleMarketplaceDelivery === true && this.marketplace?.state?.viewRestaurantMenu === true;
    // Loading in case of getting Future Meeting
    // const FutureMeetingLoading = <div className='h-100 w-100 d-flex justify-content-center align-items-center'><Spinner /></div>

    // Delivery Menu Part Start
    if (menuLoading === true || !menu || !currentMeeting || delivery_recent_orders_loading || delivery_dynamic_sections_loading) {
      MenuAreaSection = <MenuFoodItemsLoading totalSlides={Math.ceil(noOfSlidesForFoodItems)} visibleSlides={noOfSlidesForFoodItems} />;
    } else if (menu !== null && menuLoading === false) {
      // restaurants = menu.restaurants;
      // const menuType = currentMeeting?.menu_type;
      // only for user when type is buffet show no items
      MenuAreaSection = 
        // (isCompanyAdmin === false && menuType === 'buffet') ?
        // !isEmpty(currentMeeting) && restaurants?.map((_restaurant, index) => (
        //   <div key={index}>
        //     {!isEmpty(_restaurant && _restaurant.sections) ? _restaurant.sections && _restaurant.sections.map((section, sectionIndex) => {
        //       const buffetMenuSection = section as unknown as BuffetMenuRstaurantSection;
        //       return <React.Fragment key={sectionIndex}>
        //         <div>
        //           <h5 > {_restaurant.name} » {buffetMenuSection.section_name} </h5>
        //           {buffetMenuSection?.fooditems?.map((_fooditem) => (
        //             <div key={index} className='newContainer'>
        //               <span className='d-none'>{buffet_no_menu = 1}</span>
        //               <div className='block_'>
        //                 <div className='col_4' style={{ backgroundImage: `url(${_fooditem.image?.medium?.url})` }}></div>
        //                 <div className='col_8'>
        //                   <h3 className='text-capitalize float-left card-title mb-1'>{buffetMenuSection.section_type}: {_fooditem.name} </h3>
        //                   <p className='card-text d-block'>{_fooditem.description} {_fooditem.description ? <br /> : ''}
        //                   </p>
        //                   {_fooditem.options ? <span><b>Option: {_fooditem.options} </b></span> : ''}
        //                   <div className='options_'>
        //                     {!_fooditem.dietaries ? '' : _fooditem.dietaries.map(diet => (<span key={diet?.id}><b> {diet.name}</b> </span>))}
        //                     {_fooditem.ingredients && _fooditem.ingredients.map(ingredient => (<span key={ingredient?.id}> <b>{ingredient.name}</b> </span>))}
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           ))}
        //         </div>
        //       </React.Fragment>
        //     }) : ''
        //     }
        //   </div>
        // ))
        // :
        <MenuArea
          fromDashboard
          parentRef={this}
          menu={this.props.menu}
          currentMeeting={currentMeeting}
          cutOffPassed={this.cutOffPassed()}
          showCarouselButtons={showCarouselButtons}
          noOfSlidesForFoodItems={noOfSlidesForFoodItems}
          noOfSlidesForUserLiking={noOfSlidesForUserLiking}
          isOpenPopupOnMenuDetailPage={isOpenPopupOnMenuDetailPage}
        />
    }
    // Delivery Menu Part End

    return (
      <React.Fragment>
        <Helmet>
          <title> Chowmill: Office food delivery </title>
          <meta name="description" content="Get Food Delivered At Work With Chowmill" />
        </Helmet>
        {/* <UserLocation /> // For URLs */}
        <Header
          scheduleMarketplaceDelivery={scheduleMarketplaceDelivery}
          toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
          showLeftSidebarToggler={isMarketPlacePreview || !scheduleMarketplaceDelivery}
          fromPublicMarketPlace
          showCart={(isOpen) => this.setState({showCart: isOpen})}
          showDeliveryOrdersSidebar={(isOpen) => this.setState({ showDeliveryOrdersSidebar: isOpen})}
          isOpenSidebar={(isOpen) => this.setState({isOpenSidebar: isOpen})}
          hideHeaderCart={this.state.hideHeaderCart}
          parentComponentRef={this}
          // totalDeliveriesOnSingleDate={delivery_dates[dayjs(date, 'M-D-YYYY').format('YYYY-MM-DD')]?.length || 0}
        />
         {/* <LocationData/> */}
        <div className='container-fluid bg-white'>
          <div className='row'>
            <div id='mainMenueArea'
              style={{ paddingTop: '78px' }}
              className={'col-12 px-0'}>
              {scheduleMarketplaceDelivery ?
                <Marketplace
                  dashboardRef={this}
                  onRef={(marketplaceRef: MarketplaceRef | null) => (this.marketplace = marketplaceRef)}
                  orderPopup={this.orderPopup}
                  setState={(p: any) => { this.setState(p) }}
                  // selectedDate={dayjs(date, 'YYYY-MM-DD').toDate()}
                  selectedDate={!isEmpty(optionalPublicDate) ? getPublicDate : calculateDateForMarketplace}
                  onUpdatePage={this.onUpdatePage.bind(this)}
                  fromPublicMarketPlace
                  fromPublicAddressMarketPlace={true}
                  hideCartOnHeader={this.hideCartOnHeader.bind(this)}
                  // isFromUrlRestaurant={true} // For URLs
                  searchKeyWordsFromURL={this.searchKeyWordsFromURL}
                />
                :
                <div className='m-auto px-0 px-md-2 mx-0 text-left main-menu-area'>
                  {MenuAreaSection}
                </div>
              }
            </div>
          </div>
        </div>

        <OrderPopup
          // onDashboard={true}
          setState={(s: PublicMarketPlaceState) => this.setState(s)}
          meetingMealBudget={currentMeeting?.per_meal_budget}
          onUpdatePage={this.onUpdatePage.bind(this)}
          onUpdateOrder={this.onUpdateOrder.bind(this)}
          onClose={this.openMeetingSpecific.bind(this)}
          openCart={this.openCart}
          // company={this.company}
          onRef={(popupRef: OrderPopupRef | null) => { this.orderPopup = popupRef }}
          remaining_budget={(Number(currentMeeting?.remaining_budget) < 0) ? `0` : currentMeeting?.remaining_budget}
          fromPublicMarketPlace
          // onItemAddedToCart={this.onItemAddedToCart.bind(this)}
          showCartNotification={(itemImage, itemName) => showToastNotification(itemName, 'Success!', false, 'top-right')}
        />

        <Cart
          // selectedMeeting={currentMeeting}
          isOpenSidebar={showCart}
          // updateOrder={(id: number) => { currentMeeting && this.openModalEdit(id, currentMeeting) }}
          // updatePageOnOrderDelete={() => currentMeeting?.slug && this.props.getSingleSchedule(currentMeeting?.slug)}
          closeSidebar={() => { this.setState({ showCart: false }); document.title = 'Menu | Chowmill'; }}
          fromPublicMarketPlace
          // cartItemCount={this.state.cartItemsCount}
        />

      </React.Fragment>
    )
  }

}
const mapStateToProps = (state: ReduxRootState) => ({
  menu: state?.menu,
  order: state?.order,
  schedules_details: state?.schedules_details,
  i18n: state?.i18n?.i18n,
  schedule_update_request: state?.schedules_details?.update_schedule_status,
  cuisines: state?.cuisine_list?.cuisines,
  user: state?.users,
  announcements: state?.users?.announcements,
});

export type PublicMarketPlaceRef = React.ElementRef<typeof PublicLocationMarketPlace>;

export default connect(mapStateToProps, { getShowMenuTab, getAnnouncements, getDeliveryDates, getSingleDeliveryMenu, getDynamicTitles, getOrderDetails, getSingleSchedule, getCompanyForOrderPurpose })(PublicLocationMarketPlace);