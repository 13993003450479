// Pacakges
import React, { Component, createRef, CSSProperties } from 'react'
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElement, StripeCardElementChangeEvent, Stripe } from '@stripe/stripe-js';
// React JSX Components
import Spinner from '../Spinners/Spinner';
// Redux Actions
import { GET_PROFILE_DATA } from '../../Actions/types';
import { saveBilling, saveProfile } from '../../Actions/profileActions'
// Utilities
import store from '../../store';
import isEmpty from '../../Utilities/is_empty'
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { showToastNotification } from '../../Utilities/showToastNotification';
//Types
import { OrderPopupRef } from '../Popups/OrderPopup';
import { ProfileRef } from '../../Pages/Profile/Common/Profile';
import StripeObjectReference, { StripeRef } from './StripeObjectReference';
import { CreditCard, CreditCardType } from '../../Interfaces/Common/common';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { SaveProfilePayload } from '../../Interfaces/PayloadsAndResponses/Profile';
import { CartRef } from '../../Pages/Cart/CartDetails';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');


type StripeLayoutProps = {
  isCompanyAdmin: boolean;
  parentRef?: OrderPopupRef | ProfileRef | CartRef;
  setRef?: (ref: StripeLayoutRef | null) => void;
  cartStripeTokenHandler?: (token: string) => void;
  isLoading?: boolean;
  fromProfilePage?: boolean;
  fromOrderPopup?: boolean;
  confirmAddingCard?: boolean;
  ref?: any;
  fromCart?: boolean;
  orderPlaced?: boolean;
  stripeError?: () => void;
  disableEditMode?: boolean;
  reomveOldCard?: boolean;
  creditCardId?: number;
  addCardCallBack?: () => void;
  addingNewCreditCard?: boolean;
  defaultCreditCard?: boolean;
  // previousMethodError?: () => void;
  prevPaymentDetails: null | undefined | CreditCard;
  orderPopupCC: CreditCard | null;
  profilePageCC: CreditCard | null;
  companyBilling: any;
  saveBilling: (companyBillingId: number | null, billing: any, billingSavedCallback?: (message: string, isError?: boolean) => void) => void;
  saveProfile: (profileData: SaveProfilePayload, profileSavedCallback?: (isError?: boolean, errMsg?: string) => void) => void;
}

type StripeLayoutState = {
  isEditMode: boolean;
  checkoutErrors: null | string;
  isProcessingCard: boolean;
  submitonEnter: any;
  autoSubmitFormCount: number;
}

const { isCompanyAdmin } = getloggedInUser();
const headerStyles = { fontSize: 16 }
// Remove the font weight from above style here

class StripeLayout extends Component<StripeLayoutProps, StripeLayoutState> {
  static defaultProps = {
    isCompanyAdmin,
  };

  cardElement = createRef<null | StripeCardElement>().current;
  stripeRef = createRef<null | Stripe>().current;
  // cartRef = createRef<any>().current

  state: StripeLayoutState = {
    isEditMode: false,
    checkoutErrors: null,
    isProcessingCard: false,
    submitonEnter: this.props.orderPlaced,
    autoSubmitFormCount: 0,
  }

  componentDidMount() {
    this.props.setRef?.(this);
  }

  componentWillUnmount() {
    this.props.setRef?.(null);
  }

  handleCardDetailsChange = (ev: StripeCardElementChangeEvent) => {
    this.setState({
      checkoutErrors: ev?.error?.message || null,
    })
  };

  enableEditMode = () => {
    this.setState({
      isEditMode: true,
      checkoutErrors: null
    })
  }

  disableEditMode = () => {
    this.setState({
      isEditMode: false,
      isProcessingCard: false,
      checkoutErrors: null,
    })
    if (this.cardElement) {
      this.cardElement.clear()
    }
  }
  componentDidUpdate(prevProps: Readonly<StripeLayoutProps>, prevState: Readonly<StripeLayoutState>, snapshot?: any): void {
    if (this.state.checkoutErrors) {
      this.props.stripeError?.()
    }
  }

  deletePaymentMethodHandler = () => {
    const { isCompanyAdmin, saveBilling, saveProfile } = this.props;
    this.setState({ isProcessingCard: true });
    if (isCompanyAdmin) {
      const deleteCard = {
        billing_cards_attributes: [{
          id: this.props.creditCardId ?? 0,
          _destroy: true
        }]
      }
      saveBilling(this.props.companyBilling, deleteCard, (message, isError = false) => {
        // Delete Credit Card Details from Company Billing.
        if (!isError) {
          const { profile: adminProfile = null } = store.getState().profile;
          store.dispatch({
            type: GET_PROFILE_DATA,
            payload: adminProfile ? { ...adminProfile, card_details: null } : null
          })
        }
        showToastNotification(message, isError ? 'Oops!' : 'Status', isError);
        this.disableEditMode();
      });
    } else {
      let profileData = { user: { delete_stripe_card: true } };
      saveProfile(profileData, (isError = false, errMsg = '') => {
        showToastNotification(isError ? errMsg : 'Credit Card Removed successfully', isError ? 'Oops!' : 'Status', isError);
        this.disableEditMode();
      });
    }
  }

  handleFormSubmit = async () => {
    // ev && ev.preventDefault();
    const { fromOrderPopup, fromCart, isCompanyAdmin, saveBilling, saveProfile, parentRef } = this.props;
    const cardElement = this.cardElement;
    const stripe = this.stripeRef;
    if (cardElement && stripe) {
      //@ts-ignore
      const isValidCardElement = cardElement?._implementation?._complete || false;
      isValidCardElement && this.setState({ isProcessingCard: true });
      let { token, error } = await stripe.createToken(cardElement);
      if (token) {
      } else if (error) {
        this.setState({ submitonEnter: false })
        this.state.submitonEnter && this.props.stripeError && this.props.stripeError()
      }
      if (token && token.id) {
        // If called from Popup For Ordering Return Token and exit
        if (fromOrderPopup && token.card) {
          this.disableEditMode();
          showToastNotification('Credit Card information saved successfully', 'Status');
          (parentRef as OrderPopupRef)?.setState({ company_user_credit_card: token.card as CreditCard, company_user_credit_card_token_id: token.id });
          return;
        } else if (fromCart && token.card){
          this.disableEditMode();
          // (parentRef as CartRef).
          this.props.cartStripeTokenHandler && this.props.cartStripeTokenHandler(token.id)
          // showToastNotification('Credit Card information saved successfully', 'Status');
        }
        if (fromCart && this.props.orderPlaced) {
        } else {
          if (isCompanyAdmin) {
            const addNewCard = {
              billing_cards_attributes: [{
                token: token.id,
                invoice_credit_card: 'credit_card'
              }]
            }
            saveBilling(this.props.companyBilling, addNewCard, (message, isError = false) => {
              if (!isError) {
              // Update Credit Card Details for Company Billing Detail.
              const { profile: adminProfile = null } = store.getState().profile;
              store.dispatch({
                type: GET_PROFILE_DATA,
                payload: adminProfile ? { ...adminProfile, card_details: token?.card } : null
              });
            }
            showToastNotification(message, isError ? 'Oops!' : 'Status', isError);
            if (fromCart && token?.card) {
              this.props.cartStripeTokenHandler && this.props.cartStripeTokenHandler(token.id)
            }
            this.disableEditMode();
            this.props?.addCardCallBack?.()
          });
          } else {
          let profileData = { user: { stripe_token: token?.id } };
          saveProfile(profileData, (isError = false, errMsg = '') => {
            showToastNotification(isError ? errMsg : 'Credit Card saved successfully', isError ? 'Oops!' : 'Status', isError);
            this.disableEditMode();
          });
        }
      }
      } else if (isValidCardElement) {
        // To Show Error Message While Generating Payment Method Token
        const errMsg = error?.message ?? 'Credit Card information can not be saved successfully';
        showToastNotification(errMsg, 'Oops!', true);
        // Set State to Initial Value in any case (Success or Error)
        this.disableEditMode();
      }
    }
  }

  render() {
    const {
      isLoading = false,
      fromOrderPopup,
      fromCart,
      prevPaymentDetails,
      fromProfilePage,
      orderPopupCC,
      profilePageCC,
    } = this.props;

    const { isEditMode, isProcessingCard, checkoutErrors } = this.state;
    const isExpiredCard = isEmpty(prevPaymentDetails) ? false : dayjs(`${prevPaymentDetails?.exp_year}-${prevPaymentDetails?.exp_month}-1`, 'YYYY-M-D').isBefore(new Date())
    const CardElementStyles = { top: 0, left: 0, transition: '1.75s opacity' } as CSSProperties;
    const visibleSectionStyles = { visibility: 'visible', opacity: 1, position: 'relative' } as CSSProperties;
    const inVisibleSectionStyles = { visibility: 'hidden', opacity: 0, position: 'absolute', } as CSSProperties;
    const alreadyCCSaved = !isEmpty(fromProfilePage ? profilePageCC : orderPopupCC);

    return <div className='stripe-payment-layout my-2' style={{ maxWidth: 450 }}>
      {isLoading ? <PaymentDetailLoading /> :
        <>
          <div style={{ width: '100%' }} className="d-flex justify-content-between">
            {fromCart ?
              // remove the stripe-heading class from below
              <p className=' fs-18 font-weight-bold m-0 text-gray-primary'>Credit Card</p>
              :
              (prevPaymentDetails ? <p className={`stripe-heading m-0 fw-bold ${!this.props?.defaultCreditCard ? 'cursor-pointer': ''}`}> {this.props?.defaultCreditCard ? 'Default Card' : 'Card Details'}</p> : <p className='stripe-heading fw-bold m-0'>Set up your payment</p>)
            }
            <div style={{ minHeight: 24 }} className={`${prevPaymentDetails ? 'd-flex justify-content-between align-items-center' : ''}`}>
              {prevPaymentDetails ?
                <>
                  {/* {fromCart ?  <p className='m-0' style={headerStyles}></p> : ( <h5 className='m-0' style={headerStyles}>Card Details</h5>)} */}
                  {(fromOrderPopup || fromCart) ?
                    isProcessingCard ?
                      <p style={headerStyles} className={`m-0 text-primary`}>Processing...</p> :
                      <p style={headerStyles} className={`m-0 text-primary`}>
                        {isEditMode ?
                          <>
                            <span className='mr-3 cursor-pointer' onClick={this.handleFormSubmit}>Update</span>
                            <span className='cursor-pointer' onClick={this.disableEditMode}>Cancel</span>
                          </> :
                          <>
                            <span className='cursor-pointer' onClick={this.enableEditMode}>Edit</span>
                          </>
                        }
                      </p>
                    :
                    <>
                      {(!this.props.disableEditMode && !isEditMode) &&
                        <div className='d-flex' style={{ gap: 10 }}>
                          <p style={headerStyles} className={`m-0 text-primary cursor-pointer`} onClick={this.enableEditMode}>Edit</p>
                        </div>
                      }
                    </>
                  }
                </> :
                <div className='d-flex justify-content-between align-items-center flex-row-reverse'>
                  <div className=''>
                    {/* <h5 className='m-0' style={headerStyles}>Set up your payment</h5> */}
                    {((fromOrderPopup && this.props.confirmAddingCard) || fromCart) &&
                      <p id='ccOrderPopUp' ref={this.props?.ref} style={headerStyles} className={`mr-0 text-primary cursor-pointer`}
                        onClick={() => { !isProcessingCard && this.handleFormSubmit() }}
                      >
                        {isProcessingCard ? 'Processing...' : (fromCart ? ((this.props.orderPlaced && !this.state.checkoutErrors) ? <>{console.log(this.handleFormSubmit())}</> : '') : ((this.props.confirmAddingCard && !this.state.checkoutErrors) ? <>{console.log(this.handleFormSubmit())}</> : ''))}
                      </p>
                    }
                  </div>
                  <div className='d-flex mt-1' style={{ gap: 10 }}>
                    <img width={35} height={30} src={`/imgs/SVGs/${CreditCardType.VISA}.svg`} alt='card' />
                    <img width={35} height={30} src={`/imgs/SVGs/${CreditCardType.MASTER_CARD}.svg`} alt='card' />
                    <img width={35} height={30} src={`/imgs/SVGs/${CreditCardType.AMERICAN_EXPRESS}.svg`} alt='card' />
                    <img width={35} height={30} src={`/imgs/SVGs/${CreditCardType.DISCOVER}.svg`} alt='card' />
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='position-relative'>
            <div style={{ ...CardElementStyles, ...(isEditMode ? inVisibleSectionStyles : visibleSectionStyles) }}>
              {prevPaymentDetails &&
                <>
                  <div style={{ background: '#F7F7F7' }} className='rounded px-sm-4 px-2 py-3 d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      {/* <i style={{ color: '#1838BF', fontSize: 35 }} className='fab mr-3 fa-cc-visa'></i> */}
                      <img width={35} height={30} className='mr-3' src={`/imgs/SVGs/${prevPaymentDetails.brand}.svg`} alt='card' />
                      <p className='m-0 d-flex align-items-center'>
                        {ArrayOfLength(12).map(dot =>
                          <span
                            key={dot}
                            className={`${(dot + 1) % 4 === 0 ? 'mr-3' : 'mr-1'} credit-card-dot`}></span>
                        )}
                        <span style={{ fontSize: 20 }}>{prevPaymentDetails.last4}</span>
                      </p>
                    </div>
                    <p className={`m-0 ${isExpiredCard ? 'text-danger' : ''}`} style={{ color: '#999999' }}>
                      {prevPaymentDetails.exp_month}/{prevPaymentDetails.exp_year}
                    </p>
                  </div>
                  {isExpiredCard && <span className='text-danger'>Your card has been expired.</span>}
                </>}
            </div>
            <div style={{ ...CardElementStyles, ...((isEditMode || !prevPaymentDetails) ? visibleSectionStyles : inVisibleSectionStyles) }}>
              <Elements stripe={stripePromise}>
                {/* This Component is Used to Get Stripe Object Ref */}
                <StripeObjectReference ref={(stripeRef: StripeRef) => this.stripeRef = stripeRef?.stripe} />

                <div className='my-3' style={this.props.fromOrderPopup ? { height: '60px' } : {}} >
                  <CardElement
                    onReady={(card: StripeCardElement) => { this.cardElement = card }}
                    onChange={this.handleCardDetailsChange}
                  />
                  {checkoutErrors && <span className='text-danger mt-1'>{checkoutErrors}</span>}
                  {(!fromOrderPopup && !fromCart) &&
                    <div className='stripe-payment-btns'>
                      <button className='btn btn-primary mr-2 mt-3 save-stripe-payment'
                        disabled={!!checkoutErrors || isProcessingCard}
                        type='button'
                        onClick={this.handleFormSubmit}
                      >
                        {isProcessingCard ?
                          <Spinner spinnerSizeClass='spinner-border-sm' /> :
                          prevPaymentDetails ? 'Update' : 'Add'
                        }
                      </button>
                      {isEditMode &&
                        <button disabled={isProcessingCard} onClick={this.disableEditMode} className='btn mt-3 cancel-stripe-payment'>
                          Cancel
                        </button>}
                    </div>}
                </div>
              </Elements>
            </div>

            {(isCompanyAdmin && !this.props.addingNewCreditCard && !fromCart) ?
              <>
                {
                  isProcessingCard ?
                    <Skeleton width={112} height={25} /> :
                    <div
                      className='d-inline-block text-danger mt-2 cursor-pointer'
                      onClick={this.deletePaymentMethodHandler}
                    >
                      <i className='fas fa-minus-circle mr-2'></i>
                      Remove Card
                    </div>
                }

              </>
              :
              <>
                {!this.props.addingNewCreditCard && alreadyCCSaved && !isEditMode && !fromOrderPopup && !fromCart &&
                  <>
                    {
                      isProcessingCard ?
                        <Skeleton width={112} height={25} /> :
                        <div
                          className='d-inline-block text-danger mt-2 cursor-pointer'
                          onClick={this.deletePaymentMethodHandler}
                        >
                          <i className='fas fa-minus-circle mr-2'></i>
                          Remove Card
                        </div>
                    }
                  </>
                }
              </>
            }
          </div>
        </>}
    </div>
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  profilePageCC: state.profile.profile?.card_details ?? null,
  orderPopupCC: state.company_data_reducer.company_detail_for_order_purpose?.card_details ?? null,
  companyBilling: state.payment_saved?.billing_details?.id
})

export default connect(mapStateToProps, { saveBilling, saveProfile })(StripeLayout);

export type StripeLayoutRef = React.ElementRef<typeof StripeLayout>;


const PaymentDetailLoading = () => {
  return <div style={{ maxWidth: 450, width: '100%' }}>
    <div className='d-flex justify-content-between align-items-center mb-3'>
      <h5 className='m-0'><Skeleton width={105} height={25} /></h5>
      <div className='d-flex' style={{ gap: 10 }}>
        <p className='m-0'><Skeleton width={30} height={25} /></p>
        <p className='m-0'><Skeleton width={30} height={25} /></p>
      </div>
    </div>
    <div style={{ background: '#F7F7F7' }} className='px-4 py-3 d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <div className='mr-3'>
          <Skeleton width={40} height={35} />
        </div>
        <p className='mb-2'>
          {ArrayOfLength(16).map(dot =>
            <span
              key={dot}
              style={{ background: 'lavender' }}
              className={`${(dot + 1) % 4 === 0 ? 'mr-3' : 'mr-1'} credit-card-dot`}>
            </span>
          )}
        </p>
      </div>
      <p className={`m-0`} style={{ color: '#999999' }}>
        <Skeleton width={45} height={24} />
      </p>
    </div>
  </div>
}