import React, { Component, ErrorInfo } from 'react';
// import AirbrakeClient from 'airbrake-js';
import { Notifier } from '@airbrake/browser';
import { i18n } from 'i18next';
import { getloggedInUser } from '../Utilities/getloggedInUser';
import dayjs from 'dayjs';
import isEmpty from '../Utilities/is_empty';
import { modifyAddressInUrl } from '../Utilities/urlHandlingUtility';

type ErrorBoundaryState = {
  hasError: boolean;
}

type ErrorBoundaryProps = {
  i18n: i18n;
  children?: React.ReactNode;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  airbrake;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
      this.airbrake = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID? +process.env.REACT_APP_AIRBRAKE_PROJECT_ID : 0,
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY?? '',
        environment: process.env.REACT_APP_ENVIRONMENT
      });
      // this.airbrake = new AirbrakeClient({
      //   projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
      //   projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
      // });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });

    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
      this.airbrake?.notify({
        error: error,
        params: { info: errorInfo }
      });
    }
    if (errorInfo) {
      // Redirect to login Page incase of credientals error
      const { isCompanyAdmin, loggedInUser, loggedInUserCompany } = getloggedInUser()
      if (isCompanyAdmin) {
        const currentHour = dayjs().hour();
        const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');
        const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
        window.location.replace(`/menu/${dateForMarketplace.format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}`)
      } else if (loggedInUser && loggedInUserCompany) {
        window.location.replace('/')

      } else {
        //Replaced the signin page from default in case of error for public user
        // const addressData = JSON.parse(localStorage.getItem('address') || '{}')
        const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
        modifyAddressInUrl(localAddress)
        // window.location.replace('/')
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        // <div className='snap centered'>
        //   <img src='/imgs/oops.jpeg' alt='Oops - Something went wrong!' />
        //     <div className='snap-message'>
        //       <h2>We're sorry - Something went wrong. Our Development Team has been notified with the issue. We'll try to resolve it as soon as possible. </h2>
        //       {/* <a className='float-right' href='/dashboard'>Go to Dashboard</a> */}
        //     </div>
        // </div>
        <p></p>
      )
    }
    return this.props.children;
  }
}