import { Image } from './Auth'
import { FoodItem, FoodItemNutrition, FoodItemOptionSet, FoodOptionSetOption } from './Menu'
import { DeliveryCustomFields, MenuType } from './Schedules'

export enum OrderStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Unordered = 'unordered',
}

export type OrderCustomFields = Omit<DeliveryCustomFields, 'runningmenu_id'> & {
  orderfields_id: number;
}

export type GuestOrder = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export type OrderOptionSet = Omit<FoodItemOptionSet, 'options'> & {
  selected: boolean;
  options: FoodOptionSetOption & { selected: boolean };
}

export type CloneOrdersCustomFields = DeliveryCustomFields & {
  // created_at: string;
  field_id: number
  // fieldoption_id: null | number;
  // latest_version_id: null | number;
  options: ({
    id: number;
    name: string;
  })
  // recurring_scheduler_id: null | number;
  // updated_at: string;
}

export type CloneOrdersRunningMenu = {
  avg_per_meal: null | number | string;
  id: number;
  order_total: string;
  total_quantity: number;
}

export type OrderSummary = {
  avg_per_meal: number;
  admin_cutoff_at: string;
  sd_admin_cutoff_at: null | string;
  cancelled_total_quantity: number;
  cutoff_at: string;
  sd_cutoff_at: null | string;
  delivery_fee: number | string;
  // end_time: string; // Unused
  formatted_address: string;
  invoice_discount: string;
  invoice_download_link: string;
  marketplace: boolean;
  meeting_name: string;
  meeting_style: MenuType;
  meeting_type: string;
  order_total: number;
  ordered_at: string;
  time_zone: string;
  price_per_head: number;
  restaurant_name: string;
  runningmenu_fields: ({
    name: string;
    value: string;
  })[];
  same_day: boolean;
  sd_activation_at: string | null;
  sort_order: string;
  sort_type: string;
  total_price: number | string;
  total_quantity: number;
  user_copay: number;
}

export type OrderUser = {
  email: string;
  id: number;
  is_admin: boolean;
  name: string;
  user_type: string;
}

export type OrderFoodItem = Omit<FoodItem, 'global_avg_rating' | 'image' | 'spicy'> & {
  dishsize: null | string;
  nutritions: FoodItemNutrition[];
  serve_count: null | number;
  total_price: number;
}

export type DeliveryAllOrders = {
  admin_cutoff_at: null | string;
  sd_admin_cutoff_at: null | string;
  company_price: null | number;
  cutoff_at: null | string;
  sd_cutoff_at: null | string;
  fooditem: null | OrderFoodItem;
  group: null | string;
  guest_id: null | string;
  id: null | number;
  price: null | number;
  quantity: null | number;
  restaurant_name: null | string;
  same_day: boolean;
  share_meeting_id: null | number;
  status: OrderStatus;
  user: OrderUser;
  user_price: null | number;
  remarks: string | null;
  selected_options: string | null;
  utensils: boolean;
}

export type Order = {
  admin_cutoff_at: string;
  average_rating: string;
  custom_fields: OrderCustomFields[];
  cutoff_at: string;
  fooditem: FoodItem
  guest: null | GuestOrder;
  id: number;
  ordered_at: string;
  orderrating: boolean;
  price: string;
  quantity: number;
  rating_count: number;
  remarks: string;
  total_price: string;
  user_paid: string;
}

export type OrderHistoryDelivery = {
  delivery_at: string;
  delivery_fee: string | number; // Unused
  formatted_address: string;
  invoice_id: number | null;
  invoice_number: number | null;
  meeting_type: string; // Unused
  order_total: string | number;
  restaurant_name: string; // Unused
  runningmenu_id: number;
  runningmenu_name: string;
  slug: string;
  total_orders: number;
  time_zone?: string;
  invoice_total: string | number;
}

// POST => placeOrder Action (/schedules/${currentMeetingSlugOrId}/orders?token=${token})

export type PlaceOrder = Order;

// PUT => updateSingleOrder Action (/orders/${id})

export type UpdateOrder = Order;

// GET => getSingleOrder Action (/orders/${id})

export type GetSingleOrder = Order;

// DELETE => cancelOrder Action (/orders/${orderId}/cancel/)

export type CancelOrder = {
  message: string;
};

// POST => cloneOrders Action (/order/clone)

export type CloneOrders = CloneOrdersCustomFields[] | {
  message: string;
  runningmenu: CloneOrdersRunningMenu;
}

// GET => getFooditemForRating Action (/orders/${localStorage.getItem('order_id')}/rating);

export type RatingFoodItem = {
  fooditem_image: Image;
  fooditem_name: string;
};

// GET => getRestaurantForRating Action (/orders/${localStorage.getItem('order_id')}/restaurant);

export type RatingRestaurant = {
  restaurant_name: string;
  scheduler_name: string;
};

// GET => getOrderDetails Action (/schedules/${token ? undefined : runningmenuSlug}/orders${queryParams ? queryParams : ''}${token ? `&token=${token}` : ''})

export type GetOrderDetails = {
  enable_grouping_orders: string | boolean;
  order_summary: OrderSummary;
  orders: DeliveryAllOrders[]
}
export type SelfSignUpOrderOptions = {
  description: string,
}

export type SelfSignUpOrder = {
  fooditem_name: string,
  group: string | null,
  id: number,
  options: SelfSignUpOrderOptions[],
  quantity: number,
  total_price: string,
  utensils: boolean,
}
export type SelfSignUpOrderUser = {
  delivery_name: string,
  phone_number: string
}
// GET => getSelfSignUpOrderDetails Action (/schedules/:id/order_details)
export type GetSelfSignUpOrderDetails = {
  activation_at: string,
  admin_cutoff_at: string,
  cc_processing_fee: string,
  cutoff_at: string,
  delivery_address: string,
  delivery_at: string,
  time_zone: string;
  delivery_cost: string,
  delivery_instructions: string,
  driver_tip: string,
  formatted_address: string,
  items_subtotal: string,
  meeting_name: string,
  menu_type: string,
  orders: SelfSignUpOrder[],
  runningmenu_type: string,
  sales_tax: string,
  total: number,
  user: SelfSignUpOrderUser
}

// GET => getCompanyDeliveriesForOrders Action (/companies/meetings${(from && to) ? `?from=${from}&&to=${to}&&page=${page}` : ''})

export type CompanyDeliveriesForOrders = {
  from: string;
  meetings: OrderHistoryDelivery[];
  to: string;
  total_meetings_count: number;
}




//////////////////////////////////////////////////////////////////// PAYLOADS ////////////////////////////////////////////////

export type PlaceOrderPayload = {
  order: {
    address_id: number;
    dishsize_id: string | number;
    fooditem_id: number;
    guest_attributes?: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
    }
    quantity: number;
    optionsets_orders_attributes: {
      [key: number]: {
        options_orders_attributes: { option_id: string }[]
        optionset_id: string;
        required: string | number;
      }
    }
    remarks: string;
    runningmenufields_attributes?: {
      field_type: 'text' | 'dropdown' | 1 | 0;
      id: number;
      name: string;
      options?: {
        id: number;
        name: string;
        selected?: boolean;
      }[]
      required: 1 | 0 | boolean;
      value?: string | number;
    }[]
    stripe_token?: string;
    utensils?: boolean;
  }
}

export type OrderRatingPayload = {
  rating: {
    comment: string;
    rating_value?: number;
    runningmenu_rating?: number;
  }
}