import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const NotFoundRoute = () => {
  const headers = localStorage.getItem('headers');
  const location = useLocation();

  return <Redirect to={!!headers ? `/menu` : { pathname: '/', state: { to: location } }} />
}

export default NotFoundRoute