import React, { FC } from 'react';

type Props = {
  label: string;
  onModalClose: () => void;
};

const ModalHeader: FC<Props> = ({ label, onModalClose }) => {
  return (
    <div className='modal-header'>
      <h5 className='modal-title' id='modal-title'>
        {label}
      </h5>
      <button
        type='button'
        onClick={onModalClose}
        className='close'
        data-dismiss='modal'
        aria-label='Close'
      >
        <span aria-hidden='true'>×</span>
      </button>
    </div>
  );
};

export default ModalHeader;