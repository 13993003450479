import { LocalStorageCompany } from "../Interfaces/PayloadsAndResponses/Auth";
import { getloggedInUser } from "./getloggedInUser";

const round = (num: number) => {
  if (num % 1 === 0) {
    return num;
  }
  return (new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(num))
}

export const getPricesToShown = (foodPrice: number, skip_markup: boolean, companyData: LocalStorageCompany, per_meal_budget: number) => {
  const { isUnsubsidizedUser } = getloggedInUser();
  const markupPrice = parseFloat(`${companyData?.markup}`);
  if (isUnsubsidizedUser) {
    return +round(foodPrice + (skip_markup ? 0 : markupPrice));
  }

  let price: number = foodPrice;
  if ((!skip_markup) && companyData && (!companyData.enable_saas)) {
    price = price + markupPrice;
  }
  let reducedPrice: number = 0;
  let companyBudgetValue = parseFloat(`${per_meal_budget}`) + (markupPrice * (companyData?.reduced_markup / 100));
  if ((companyData?.reduced_markup_check === true) && (!skip_markup) && ((!companyData?.enable_saas))) {
    if (price > per_meal_budget && price <= companyBudgetValue) {
      reducedPrice = parseFloat(companyData?.user_meal_budget);
    } else {
      reducedPrice = price;
    }
  } else {
    reducedPrice = price;
  }
  return +round(reducedPrice);
}
