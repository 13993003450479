import i18n from "i18next";

export const inviteUserTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Use the form below to quickly invite users to your companys Chowmill account. Users you invite will receive an email invitation asking them to setup their accounts and begin ordering food!": "Use the form below to quickly invite users to your company's Chowmill account. Users you invite will receive an email invitation asking them to setup their accounts and begin ordering food!",
      "Invite Users": "Invite Users",
      "You can paste the data into this form from Excel (tab-separated)": "You can paste the data into this form from Excel (tab-separated)",
      "I agree to invite this list of users to the Chowmill platform allowing these users to place orders.": "I agree to invite this list of users to the Chowmill platform allowing these users to place orders.",
      "Skip This Step": "Skip This Step",
      "Email Address   First Name   Last Name": "Email Address   First Name   Last Name",
      "Confirm Invitations": "Confirm Invitations",
      "Please review the data below and we'll start inviting these users.": "Please review the data below and we'll start inviting these users.",
      "First Name	": "First Name",
      "Last Name": "Last Name",
      "Invalid email address": "Invalid email address",
      "User already exists": "User already exists",
      "Confirm Invitations ": "Confirm Invitations",
      "We were not able to invite the following users for various reasons.": "We were not able to invite the following users for various reasons.",
      "Reason": "Reason",
      "Back": "Back",
      "Looks Good": "Looks Good",
      "Email Address": "Email Address",
      "Unable to Invite": "Unable to Invite",
      "You are not allowed to manage users.": "You are not allowed to manage users.",
      "Users": "Users",
      "Invited users ‘ll receive an email invitation asking them to setup their accounts and begin ordering food.": "Invited users ‘ll receive an email invitation asking them to setup their accounts and begin ordering food.",
      "Kindly fill the form to invite the user": "Kindly fill the form to invite the user",
      "Please enter a valid email": "Please enter a valid email",
      "User Type": "User Type",
      "Bulk Users Upload": "Bulk Users Upload",
      "Drag and drop files, or": "Drag and drop files, or",
      "Browse": "Browse",
      "Supports only email CSV files.": "Supports only email CSV files.",
      "Proceed": "Proceed",
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Use the form below to quickly invite users to your companys Chowmill account. Users you invite will receive an email invitation asking them to setup their accounts and begin ordering food!": "فوری طور پر اپنی کمپنی کے چومل اکاؤنٹ میں صارفین کو مدعو کرنے کے لئے نیچے دیئے گئے فارم کا استعمال کریں۔ آپ جن صارفین کو مدعو کرتے ہیں وہ ایک ای میل دعوت نامہ وصول کریں گے جس میں ان سے اپنے اکاؤنٹ مرتب کرنے اور کھانے کا آرڈر دینے کا مطالبہ کریں گے!",
      "Invite Users": "صارفین کو مدعو کریں",
      "You can paste the data into this form from Excel (tab-separated)": " آپ اس فارم میں ڈیٹا کو ایکسل سے پیسٹ کرسکتے ہیں (ٹیب سے جدا)",
      "I agree to invite this list of users to the Chowmill platform allowing these users to place orders.": "میں صارفین کی اس فہرست کو چومل پلیٹ فارم پر مدعو کرنے پر اتفاق کرتا ہوں ، ان صارفین کو آرڈر دینے کی اجازت دیتا ہے۔",
      "Skip This Step": "اس مرحلے کو چھوڑ",
      "Email Address   First Name   Last Name": "آخری نام    پہلا نام    ای میل اڈریس",
      "Confirm Invitations": "دعوت ناموں کی تصدیق کریں",
      "Please review the data below and we'll start inviting these users.": "براہ کرم نیچے دیئے گئے ڈیٹا کا جائزہ لیں اور ہم ان صارفین کو مدعو کرنا شروع کردیں گے۔",
      "First Name": "پہلا نام",
      "Last Name": "آخری نام",
      "Invalid email address": "غلط ای میل ایڈریس",
      "User already exists": "غلط ای میل ایڈریس",
      "Confirm Invitations ": "دعوت ناموں کی تصدیق کریں",
      "We were not able to invite the following users for various reasons.": "ہم مختلف وجوہات کی بناء پر مندرجہ ذیل صارفین کو مدعو نہیں کرسکے۔",
      "Reason": "وجہ",
      "Back": "پیچھے",
      "Looks Good": "اچھا لگ رہا ہے",
      "Email Address": "ای میل اڈریس",
      "Unable to Invite": "مدعو کرنے سے قاصر",
      "You are not allowed to manage users.": "آپ کو صارفین کو انتظام کرنے کی اجازت نہیں ہے۔",
      "Users": "صارفین",
      "Invited users ‘ll receive an email invitation asking them to setup their accounts and begin ordering food.": "مدعو کیے گئے صارفین کو ایک ای میل دعوت نامہ موصول ہوگا جس میں ان سے کہا جائے گا کہ وہ اپنے اکاؤنٹس ترتیب دیں اور کھانے کا آرڈر دینا شروع کریں۔",
      "Kindly fill the form to invite the user": "صارف کو مدعو کرنے کے لیے براہ کرم فارم پُر کریں۔",
      "Please enter a valid email": "ایک درست ای میل درج کریں",
      "User Type": "صارف کی قسم",
      "Bulk Users Upload": "بلک صارفین اپ لوڈ کریں۔",
      "Drag and drop files, or": "فائلوں کو گھسیٹیں اور چھوڑیں، یا",
      "Browse": "تلاش کریں",
      "Supports only email CSV files.": "صرف ای میل CSV فائلوں کو سپورٹ کرتا ہے۔",
      "Proceed": "آگے بڑھو",
    });
};