import axios from 'axios';
import { UPDATE_SINGLE_ORDER, CREATE_NEW_ORDER, LOADING_ORDER, GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS, GET_DELIVERY_ORDERS_DETAILS, GET_SINGLE_ORDER, GET_FOODITEM_FOR_RATING, GET_RESTAURANT_FOR_RATING, STOP_LOADING_ORDER, CLONE_ORDERS, LOADING_ORDER_CANCEL, STOP_LOADING_ORDER_CANCEL, STOP_INVOICE_DOWNLOADING_LOADING, SET_INVOICE_DOWNLOADING_LOADING, GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS, MARKETPLACE_PAGE } from './types';
import { getError } from './errorActions';
import { showToastNotification } from '../Utilities/showToastNotification';
import { getDeliveryDates, updateSingleScheduleAfterCloneOrders } from './scheduleDeliveryActions';
import { CloneOrdersRunningMenu, OrderRatingPayload, PlaceOrderPayload } from '../Interfaces/PayloadsAndResponses/Orders';
import { AppThunkAction } from '../store';
import { getloggedInUser } from '../Utilities/getloggedInUser';

export const placeOrder = (orderData: PlaceOrderPayload, currentMeetingSlugOrId: number | string, orderDeliveryDate: string, token = ''): AppThunkAction => dispatch => {
  if (currentMeetingSlugOrId) {
    dispatch(setLoading());
    axios
      .post(token !== '' ? `/schedules/${currentMeetingSlugOrId}/orders?token=${token}` : `/schedules/${currentMeetingSlugOrId}/orders`, orderData,
        {
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        (orderDeliveryDate && !token) && dispatch(getDeliveryDates(orderDeliveryDate, orderDeliveryDate));
        dispatch({
          type: CREATE_NEW_ORDER,
          payload: res.data
        })
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const cloneOrders = (cloneOrdersData: { runningmenu_id: number, order_ids: number[] }): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .post(`/orders/clone`, cloneOrdersData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      const { data } = res ?? {};
      if (data?.runningmenu) { // Will be true when Try to clone orders and Custom Field was filled or not required for delivery.
        dispatch(updateSingleScheduleAfterCloneOrders(data?.runningmenu as CloneOrdersRunningMenu));
      }
      dispatch({
        type: CLONE_ORDERS,
        payload: data
      });
      const { loggedInUserCompany } = getloggedInUser()
      if (loggedInUserCompany?.self_signup === true) {
        window.location.reload()
      }
    })
    .catch(err => {
      dispatch(getError(err));
      showToastNotification('Unable to clone orders due to unknown error.', 'Oops!', true);
    });
};

export const getCompanyDeliveriesForOrders = (from: string | null, to: string | null, page = 1): AppThunkAction => dispatch => {
  dispatch(setLoading('meetings_loading'));
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    });
  axios
    .get(`/companies/meetings${(from && to) ? `?from=${from}&to=${to}&page=${page}` : ''}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS,
      payload: res.data
    }))
    .catch(err => {
      dispatch(getError(err));
      dispatch(stopLoading());
    }
    );
};

export const getDeliveryInvoice = (invoiceId: number, meetingId: number): AppThunkAction => dispatch => {
  dispatch(setDeliveryInvoiceDownloadLoading(meetingId));
  axios.get(`companies/send_invoice_email.pdf?invoice_id=${invoiceId}`, {
    headers: JSON.parse(localStorage.getItem('headers') || '{}'),
    responseType: "blob"
  })
    .then(res => {
      const file = new Blob(
        [res.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatch(stopDeliveryInvoiceDownloadLoading(meetingId));
    })
    .catch(err =>
      console.log("catch", invoiceId)
    )
}

export const getOrderDetails = (runningmenuSlug: string, token = '', queryParams = ''): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/schedules/${token ? undefined : runningmenuSlug}/orders${queryParams ? queryParams : ''}${token ? `&token=${token}` : ''}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_DELIVERY_ORDERS_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};


export const getSelfSignUpOrderDetails = (slug: any): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/schedules/${slug}/order_details`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateSelfSignUpDeliveryDetails = (slug: string, delivery_instructions: string, DeliveryDetailsLoaderHandler: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/schedules/${slug}/order_details`, { delivery_instructions: delivery_instructions },
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS,
        payload: res.data
      });
      DeliveryDetailsLoaderHandler && DeliveryDetailsLoaderHandler()
      // showToastNotification('Your ratings and reviews help us improve our food and service.', 'Thank you');
    })
    .catch(err => {
      dispatch(getError(err))
    });
};


export const cancelOrder = (orderId: number, orderDeliveryDate: string | undefined, token: string | undefined, updateOrdersCallback: (id?: string) => void): AppThunkAction => dispatch => {
  dispatch(setOrderCancelLoading(orderId));
  axios
    .delete(token ? `/orders/${orderId}/cancel/?token=${token}` : `/orders/${orderId}/cancel/`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch(setStopOrderCancelLoading(orderId))
      showToastNotification('Order cancelled successfully.', 'Status');
      // orderDeliveryDate && !token && dispatch(getDeliveryDates(orderDeliveryDate, orderDeliveryDate));
      updateOrdersCallback && updateOrdersCallback(`${orderId}`);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const cancelOrderAnyway = (orderId: number, orderDeliveryDate: string | undefined, token: string | undefined, updateOrdersCallback: (id?: string) => void, successCallBack?:() => void): AppThunkAction => dispatch => {
  dispatch(setOrderCancelLoading(orderId));
  axios
    .delete(token ? `/orders/${orderId}/cancel/?token=${token}` : `/orders/${orderId}/cancel/`,
      {
        data: {cancel_anyway: true },
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch(setStopOrderCancelLoading(orderId))
      showToastNotification('Order cancelled successfully.', 'Status');
      successCallBack && successCallBack();
      // orderDeliveryDate && !token && dispatch(getDeliveryDates(orderDeliveryDate, orderDeliveryDate));
      updateOrdersCallback && updateOrdersCallback(`${orderId}`);
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const updateSingleOrder = (rating: PlaceOrderPayload, id: number, token = ''): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .put(token === '' ? `/orders/${id}` : `/orders/${id}?token=${token}`, rating,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: UPDATE_SINGLE_ORDER,
      payload: res.data
    }))
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getSingleOrder = (id: number, token = ''): AppThunkAction => dispatch => {
  axios
    .get(token === '' ? `/orders/${id}` : `/orders/${id}?token=${token}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: GET_SINGLE_ORDER,
      payload: res.data
    }))
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getFooditemForRating = (): AppThunkAction => dispatch => {
  axios
    .get(`/orders/${localStorage.getItem('order_id')}/fooditem`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: GET_FOODITEM_FOR_RATING,
      payload: res.data
    }))
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getRestaurantForRating = (): AppThunkAction => dispatch => {
  axios
    .get(`/orders/${localStorage.getItem('order_id')}/restaurant`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: GET_RESTAURANT_FOR_RATING,
      payload: res.data
    }))
    .catch(err =>
      dispatch(getError(err))
    );
};

export const addRating = (rating: OrderRatingPayload, ratingAddedCallback: () => void): AppThunkAction => dispatch => {
  axios
    .post(`/orders/${localStorage.getItem('order_id')}/rating`, rating,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      showToastNotification('Your ratings and reviews help us improve our food and service.', 'Thank you');
      ratingAddedCallback && ratingAddedCallback();
    })
    .catch(err => {
      ratingAddedCallback && ratingAddedCallback()
      dispatch(getError(err))
    });
};

// Status Loading
export const setLoading = (loading_type = 'loading') => {
  return {
    type: LOADING_ORDER,
    payload: loading_type
  };
}
export const stopLoading = () => {
  return {
    type: STOP_LOADING_ORDER
  };
}
export const setOrderCancelLoading = (orderId: number | string) => {
  return {
    type: LOADING_ORDER_CANCEL,
    payload: orderId
  };
}
export const setStopOrderCancelLoading = (orderId: number | string) => {
  return {
    type: STOP_LOADING_ORDER_CANCEL,
    payload: orderId
  };
}
export const setDeliveryInvoiceDownloadLoading = (meetingId: number | string) => {
  return {
    type: SET_INVOICE_DOWNLOADING_LOADING,
    payload: meetingId
  };
}
export const stopDeliveryInvoiceDownloadLoading = (meetingId: number | string) => {
  return {
    type: STOP_INVOICE_DOWNLOADING_LOADING,
    payload: meetingId
  };
}
