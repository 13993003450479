import axios from 'axios';
import { GET_CUISINE, LOADING } from './types';
import { getError } from './errorActions';
import { AppThunkAction } from '../store';

export const getCuisine = (): AppThunkAction => dispatch => {
  axios
    .get('/cuisines',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_CUISINE,
        payload: res.data?.cuisines || [],
      })
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};
// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}
