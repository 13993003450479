import React, { FC } from "react";
import isEmpty from "../../../Utilities/is_empty";
import { MarketplaceDishes } from "../../../Interfaces/ReduxInterface/ReduxRootState";

type MarketPlaceFoodItemCardProps = {
  dishes: MarketplaceDishes;
  index?: number;
  screenWidth?: number | undefined;
  selectRestaurant: (dishes: MarketplaceDishes, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fromPublicMarketPlace?: boolean;
  marketplaceRestaurant?: boolean
}

const MarketPlaceFoodItemCard: FC<MarketPlaceFoodItemCardProps> = ({ dishes, index, screenWidth, selectRestaurant, fromPublicMarketPlace, marketplaceRestaurant }) => {



  return (
    <React.Fragment>
      <div style={{ 'position': 'relative' }}>
        <div onClick={e => selectRestaurant(dishes, e)}>
          <div className={`mb-1 position-relative cursor-pointer ${!marketplaceRestaurant && 'combo-restaurant-slider-card'}}`}>
            <div
              style=
              {(isEmpty(dishes.image?.url)) ? { backgroundImage: 'url("/imgs/SVGs/ComboPlaceholder.svg")' } : { backgroundImage: 'url(' + dishes.image?.url + ')' }}
              className='individual-restaurant-card-img-marketplace'
            />
            <div className='card-body individual-restaurant-card-body py-3 px-0 pb-0 d-flex bg-white'>
              <div className="restaurant-card-logo-wrapper">
                {dishes.logo?.url ?
                  <div className='mr-1 mr-sm-2 restaurant-logo' style={{ backgroundImage: `url(${dishes.logo?.url})`, width: 40, height: 40 }}></div>
                  :
                  <div id='autoThumbnailParent'>
                    <div id='autoThumbnailChild'>
                      <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{dishes?.restaurant_name?.charAt(0)} </span>
                    </div>
                  </div>
                }
              </div>
              <div className="text-overflow-handling">
                <h6 className='individual-restaurant-card-title mb-2'>{dishes?.name}</h6>
                <div className="d-flex align-items-center">
                  {+dishes?.average_rating === 0.0 ? null : <><div className='d-inline float-right' >
                    <div className='d-flex'>
                      <i className="fas fa-star marketplace-combo pr-1 fs-18" aria-hidden="true"> </i>
                      <p className='fs-18 fw-normal m-0 text-gray-100 pr-2 pr-sm-2 rating-number' style={{ fontSize: '0.95rem' }}>{Number(dishes?.average_rating).toFixed(1)}</p>
                    </div>
                  </div>
                  </>
                  }
                  <div className='card-text mb-0' style={{ 'height': 'auto' }}>
                    <div className='d-flex justitfy-content-center align-items-center mx-1'>
                      <i className="fas fa-circle small-dot" style={{ color: "#6E6E73" }}></i>
                      <span className='mr-2 individual-combo-badge ml-2'>{dishes?.restaurant_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

export default MarketPlaceFoodItemCard;