// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { WithTranslation } from 'react-i18next';
// import { getUsersInvitedForMeeting, inviteUsersForMeeting, resendInvite, generateToken } from '../../Actions/shareMeetingActions';
// import { connect } from 'react-redux';
// import isEmpty from '../../Utilities/is_empty';
// import { showToastNotification } from '../../Utilities/showToastNotification';
// import Spinner from '../Spinners/Spinner';
// import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
// import { shareMeetingPopUpTranslations } from '../../Translations/shareMeetingPopUpTranslations';
// import { ReduxRootState, ShareMeeting } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { InviteUsersForMeetingPayload } from '../../Interfaces/PayloadsAndResponses/ShareMeeting';

// const isEmptyEmail = (value: string) => {
//   if (isEmpty(value)) {
//     return true;
//   }
//   else {
//     let emailSeperator = value.replace(/(\r\n|\n|\r)/gm, ',');
//     let invitationsEmails = emailSeperator?.split(',');
//     const invalidEmails = invitationsEmails.find((email: string) => {
//       return !email?.trim() ? false : !emailValidation(email);
//     });
//     return invalidEmails ? true : false;
//   }
//   function emailValidation(value: string) {
//     let regex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
//     return regex.test(value) ? true : false;
//   }
// }

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//     borderRadius: '18px'
//   }
// };

// Modal.setAppElement('#root');
// Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal.defaultStyles.overlay!.zIndex = 6;

// type ShareMeetingPopUpProps = {
//   i18n: WithTranslation | null;
//   invited_users: ShareMeeting;
//   share_meeting_token_from_reducer: string | null;
//   currentMeeting: Delivery | null;
//   generateToken: (id: number) => void;
//   onRef: (ref: ShareMeetingPopUpRef | null) => void;
//   getUsersInvitedForMeeting: (id: number) => void;
//   resendInvite: (shareMeetingId: number, meetingId: number) => void;
//   inviteUsersForMeeting: (users: InviteUsersForMeetingPayload, meetingId: number) => void;
// }

// type ShareMeetingPopUpState = {
//   modalIsOpen: boolean,
//   userList: string,
//   meetingId: number | '',
//   isSubmited: boolean,
// }

// class ShareMeetingPopUp extends Component<ShareMeetingPopUpProps, ShareMeetingPopUpState> {
//   state: ShareMeetingPopUpState = {
//     userList: '',
//     meetingId: '',
//     isSubmited: false,
//     modalIsOpen: false,
//   };

//   componentDidMount() {
//     this.props.onRef(this);
//     this.setState({ userList: '' })
//   }
//   UNSAFE_componentWillReceiveProps(nextProps: ShareMeetingPopUpProps) {
//     if (this.state.isSubmited === true && nextProps.invited_users.inviteUserForMeetingByToken !== null && this.state.meetingId) {
//       this.props.getUsersInvitedForMeeting(this.state.meetingId);
//       this.setState({ isSubmited: false });
//       showToastNotification('Invited successfully.', 'Success!');
//     }
//     if (nextProps.invited_users && nextProps.invited_users.resend_status && nextProps.invited_users.resend_status !== this.props.invited_users.resend_status) {
//       if (nextProps.invited_users.resend_status.status === 200) {
//         showToastNotification('Invited successfully.', 'Success!');
//       }
//     }
//   }
//   componentWillUnmount() {
//     this.props.onRef(null)
//   }
//   openModal = (currentMeeting: Delivery) => {
//     shareMeetingPopUpTranslations();
//     const { id, share_token } = currentMeeting
//     if (!share_token) {
//       this.props.generateToken(id);
//     }
//     this.setState({ modalIsOpen: true, meetingId: id });
//     this.props.getUsersInvitedForMeeting(id);
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false });
//   }
//   _resendInvite(shareMeetingId: number) {
//     this.state.meetingId && this.props.resendInvite(shareMeetingId, this.state.meetingId);
//   }
//   onSubmit = (e: React.MouseEvent<HTMLElement>) => {
//     e.preventDefault();
//     this.setState({ isSubmited: true });
//     let userId = JSON.parse(localStorage.getItem('user') || '{}').id;
//     if (userId && this.state.meetingId) {
//       let user: InviteUsersForMeetingPayload = {
//         share_meeting: {
//           user_id: userId,
//           runningmenu_id: this.state.meetingId,
//           emails: this.state.userList
//         }
//       }
//       this.props.inviteUsersForMeeting(user, this.state.meetingId);
//       this.setState({ userList: '' })
//     }
//   }
//   handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//     this.setState({ userList: e.target.value });
//   }
//   copyToClip = () => {
//     const { currentMeeting, share_meeting_token_from_reducer } = this.props;
//     const { slug: currentMeetingSlug, share_token } = currentMeeting ?? {} as Delivery
//     if (navigator && navigator.clipboard && process.env.REACT_APP_GENERIC_MEETING_LINK && currentMeetingSlug && (share_token || share_meeting_token_from_reducer)) {
//       navigator.clipboard.writeText(process.env.REACT_APP_GENERIC_MEETING_LINK + currentMeetingSlug + '/' + (share_token || share_meeting_token_from_reducer));
//       showToastNotification('The shareable link for this meeting has been copied to your clipboard.', 'Copied!');
//     }
//   }
//   render() {
//     const { loading, invitedUsersForMeeting, } = this.props.invited_users || {};
//     const { i18n } = this.props
//     let InvitedUsers, HeadingForInvitedUsers;
//     if (loading) {
//       InvitedUsers = <div className='share-meeting-spinner'>
//         <div>
//           <Spinner />
//         </div>
//       </div>;
//     } else if (invitedUsersForMeeting !== null && !isEmpty(invitedUsersForMeeting) && loading === false) {
//       HeadingForInvitedUsers = (
//         <div className='form-row mt-4'>
//           <div className='col-11 m-auto text-center'>
//             <h2 className='text-center'>{i18n && i18n.t('Invited Users')}</h2>
//             <p className='text-muted'>{i18n && i18n.t('Following list of users have been invited to order for this meeting.')}</p>
//           </div>
//         </div>
//       );
//       InvitedUsers = invitedUsersForMeeting.map((user, index) => (
//         <div className='col-12 company-required-field p-3 mb-1 invite-users' key={index}>
//           <div className='form-group'>
//             <h6 className='orderform-section-name float-left'>{
//               user.first_name ?
//                 <span className='text-capitalize'>{user.first_name + ' ' + user.last_name}</span>
//                 :
//                 <span>
//                   {user.email}
//                 </span>}
//             </h6>
//             {user.ordered === 0 ?
//               <button type='button' className='btn btn-link orderform-section-required muted float-right mt-8 text-danger invite-guests-resend-invitation' onClick={this._resendInvite.bind(this, user.id)}>{i18n && i18n.t('Resend Invitation')}</button>
//               :
//               <h6 className='orderform-section-required muted float-right mt-8 text-success disable-click'> Ordered </h6>
//             }
//           </div>
//         </div>
//       ));
//     }

//     return (
//       <Modal
//         isOpen={this.state.modalIsOpen}
//         onRequestClose={this.closeModal}
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className='order-modal' id='orderModelBox'>
//           <div className='modal-dialog' style={{ maxWidth: '100%' }}>
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className={`modal-header bg-white`} style={{ width: '690px' }}>
//                 <h5 className='modal-title' id='modal-title'>{i18n && i18n.t('Invite Guests')} </h5>
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span className='close-invite-guests-modal' aria-hidden='true'>×</span></button>
//               </div>
//               <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
//                 <div id='main' className='container'>
//                   <div className='form-row'>
//                     <div className='col-11 m-auto text-center'>
//                       <h2 className='text-center'>{i18n && i18n.t('Invite Guests')} </h2>
//                       <p className='text-muted'> {i18n && i18n.t('Please use this form to invite other users to order…')} </p>
//                     </div>
//                   </div>
//                   <form noValidate className='text-left'>
//                     <div className='form-row'>
//                       <div className='col-12 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '> {i18n && i18n.t('Emails to Invite')} </h6>
//                         <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6><br />
//                         <textarea onChange={this.handleChange}
//                           id='invite-guests-textarea' className='form-control' placeholder={' Email Address \n Email Address \n Email Address \n Email Address'} name='userList' style={{ clear: 'both', marginTop: '0px', marginBottom: '0px', height: '81px', backgroundColor: 'transparent' }}>
//                         </textarea>
//                       </div>
//                     </div>
//                     <div className='form-row text-center'>
//                       <div className='col-sm-6 company-required-field p-3 text-left'>
//                         <button className='btn btn-primary delivery-btn-width text-white invite-users-btn' disabled={isEmptyEmail(this.state.userList)} onClick={this.onSubmit}> {i18n && i18n.t('Invite Users')} </button>
//                       </div>
//                       <div className='col-sm-6 company-required-field p-3 text-right'>
//                         <p className='anchor-style' onClick={this.copyToClip} ><i className='fas fa-link'></i> {i18n && i18n.t('Copy Shareable Link')} </p>
//                       </div>
//                     </div>
//                     {HeadingForInvitedUsers}
//                     <div className='form-row mt-2' id='0'>
//                       {InvitedUsers}
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   i18n: state.i18n.i18n,
//   invited_users: state.share_meeting,
//   share_meeting_token_from_reducer: state.share_meeting.share_token && state.share_meeting.share_token.share_token,
// });

// export type ShareMeetingPopUpRef = React.ElementRef<typeof ShareMeetingPopUp>;

// export default (connect(mapStateToProps, { getUsersInvitedForMeeting, inviteUsersForMeeting, resendInvite, generateToken })(ShareMeetingPopUp));

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Modal from 'react-modal';
import { getUsersInvitedForMeeting, inviteUsersForMeeting, resendInvite, generateToken } from '../../Actions/shareMeetingActions';
import isEmpty from '../../Utilities/is_empty';
import { showToastNotification } from '../../Utilities/showToastNotification';
import Spinner from '../Spinners/Spinner';
import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
import { shareMeetingPopUpTranslations } from '../../Translations/shareMeetingPopUpTranslations';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { useSelector, useDispatch } from 'react-redux';

const isEmptyEmail = (value: string) => {
  if (isEmpty(value)) {
    return true;
  }
  else {
    let emailSeperator = value.replace(/(\r\n|\n|\r)/gm, ',');
    let invitationsEmails = emailSeperator?.split(',');
    const invalidEmails = invitationsEmails.find((email: string) => {
      return !email?.trim() ? false : !emailValidation(email);
    });
    return invalidEmails ? true : false;
  }
  function emailValidation(value: string) {
    let regex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(value) ? true : false;
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '692px',
    padding: '0',
    borderRadius: '18px'
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

type ShareMeetingPopUpProps = {
  currentMeeting: Delivery | null;
};

export type ShareMeetingPopUpRef = {
  openModal: (currentMeeting: Delivery) => void;
  closeModal: () => void;
  modalIsOpen: boolean;
};

const ShareMeetingPopUp = forwardRef<ShareMeetingPopUpRef | null, ShareMeetingPopUpProps>(
  ({ currentMeeting }, ref) => {
    const [userList, setUserList] = useState<string>('');
    const [meetingId, setMeetingId] = useState<number>();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
    const invited_users = useSelector((state: ReduxRootState) => state.share_meeting);
    const share_meeting_token_from_reducer = useSelector((state: ReduxRootState) => state.share_meeting.share_token && state.share_meeting.share_token.share_token);
    const dispatch: any = useDispatch();

    useEffect(() => {
      setUserList('');
    }, []);

    useEffect(() => {
      if (isSubmitted && invited_users?.inviteUserForMeetingByToken !== null && meetingId) {
        getUsersInvitedForMeeting(meetingId);
        setIsSubmitted(false);
        showToastNotification('Invited successfully.', 'Success!');
      }
    }, [isSubmitted, invited_users?.inviteUserForMeetingByToken, meetingId]);

    useEffect(() => {
      if (invited_users?.resend_status) {
        if (invited_users?.resend_status.status === 200) {
          showToastNotification('Invited successfully.', 'Success!');
        }
      }
    }, [invited_users?.resend_status]);

    const openModal = (currentMeeting: Delivery) => {
      shareMeetingPopUpTranslations();
      const { id, share_token } = currentMeeting;
      if (!share_token) {
        dispatch(generateToken(id));
      }
      setModalIsOpen(true);
      setMeetingId(id);
      dispatch(getUsersInvitedForMeeting(id));
    };

    const closeModal = () => {
      setModalIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openModal,
      closeModal,
      modalIsOpen
    }));

    const _resendInvite = (shareMeetingId: number) => {
      meetingId && dispatch(resendInvite(shareMeetingId, meetingId));
    };

    const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setIsSubmitted(true);
      let userId = JSON.parse(localStorage.getItem('user') || '{}').id;
      if (userId && meetingId) {
        let user = {
          share_meeting: {
            user_id: userId,
            runningmenu_id: meetingId,
            emails: userList,
          },
        };
        dispatch(inviteUsersForMeeting(user, meetingId));
        setUserList('');
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUserList(e.target.value);
    };

    const copyToClip = () => {
      const { slug: currentMeetingSlug, share_token } = currentMeeting ?? ({} as Delivery);
      if (
        navigator &&
        navigator?.clipboard &&
        process.env.REACT_APP_GENERIC_MEETING_LINK &&
        currentMeetingSlug &&
        (share_token || share_meeting_token_from_reducer)
      ) {
        navigator?.clipboard.writeText(
          process.env.REACT_APP_GENERIC_MEETING_LINK +
          currentMeetingSlug +
          '/' +
          (share_token || share_meeting_token_from_reducer)
        );
        showToastNotification(
          'The shareable link for this meeting has been copied to your clipboard.',
          'Copied!'
        );
      }
    };

    let InvitedUsers, HeadingForInvitedUsers;

    if (invited_users.loading) {
      InvitedUsers = (
        <div className='share-meeting-spinner'>
          <div>
            <Spinner />
          </div>
        </div>
      );
    } else if (
      invited_users?.invitedUsersForMeeting !== null &&
      !isEmpty(invited_users?.invitedUsersForMeeting) &&
      invited_users?.loading === false
    ) {
      HeadingForInvitedUsers = (
        <div className='form-row mt-4'>
          <div className='col-11 m-auto text-center'>
            <h2 className='text-center'>
              {i18n && i18n?.t('Invited Users')}
            </h2>
            <p className='text-muted'>
              {i18n &&
                i18n?.t(
                  'Following list of users have been invited to order for this meeting.'
                )}
            </p>
          </div>
        </div>
      );
      InvitedUsers = invited_users?.invitedUsersForMeeting?.map((user, index) => (
        <div className='col-12 company-required-field p-3 mb-1 invite-users' key={index}>
          <div className='form-group'>
            <h6 className='orderform-section-name float-left'>
              {user?.first_name ? (
                <span className='text-capitalize'>{user?.first_name + ' ' + user?.last_name}</span>
              ) : (
                <span>{user?.email}</span>
              )}
            </h6>
            {user?.ordered === 0 ? (
              <button
                type='button'
                className='btn btn-link orderform-section-required muted float-right mt-8 text-danger invite-guests-resend-invitation'
                onClick={() => _resendInvite(user.id)}
              >
                {i18n && i18n?.t('Resend Invitation')}
              </button>
            ) : (
              <h6 className='orderform-section-required muted float-right mt-8 text-success disable-click'>
                Ordered
              </h6>
            )}
          </div>
        </div>
      ));
    };

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className='order-modal' id='orderModelBox'>
          <div className='modal-dialog' style={{ maxWidth: '100%' }}>
            <div className='modal-content' style={{ border: 'none' }}>
              <div className={`modal-header bg-white`} style={{ width: '690px' }}>
                <h5 className='modal-title' id='modal-title'>{i18n && i18n?.t('Invite Guests')} </h5>
                <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span className='close-invite-guests-modal' aria-hidden='true'>×</span></button>
              </div>
              <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
                <div id='main' className='container'>
                  <div className='form-row'>
                    <div className='col-11 m-auto text-center'>
                      <h2 className='text-center'>{i18n && i18n?.t('Invite Guests')} </h2>
                      <p className='text-muted'> {i18n && i18n?.t('Please use this form to invite other users to order…')} </p>
                    </div>
                  </div>
                  <form noValidate className='text-left'>
                    <div className='form-row'>
                      <div className='col-12 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {i18n && i18n?.t('Emails to Invite')} </h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('Required')}</h6><br />
                        <textarea onChange={handleChange}
                          id='invite-guests-textarea' className='form-control' placeholder={' Email Address \n Email Address \n Email Address \n Email Address'} name='userList' style={{ clear: 'both', marginTop: '0px', marginBottom: '0px', height: '81px', backgroundColor: 'transparent' }}>
                        </textarea>
                      </div>
                    </div>
                    <div className='form-row text-center'>
                      <div className='col-sm-6 company-required-field p-3 text-left'>
                        <button className='btn btn-primary delivery-btn-width text-white invite-users-btn' disabled={isEmptyEmail(userList)} onClick={onSubmit}> {i18n && i18n?.t('Invite Users')} </button>
                      </div>
                      <div className='col-sm-6 company-required-field p-3 text-right'>
                        <p className='anchor-style' onClick={copyToClip} ><i className='fas fa-link'></i> {i18n && i18n?.t('Copy Shareable Link')} </p>
                      </div>
                    </div>
                    {HeadingForInvitedUsers}
                    <div className='form-row mt-2' id='0'>
                      {InvitedUsers}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)
export default ShareMeetingPopUp