
import React, { Component, createRef } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

// React Components
import MenuArea from '../Common/MenuArea';
import Header from '../../../Common/HeaderFooter/Header';
import OrderPopup, { OrderPopupRef } from '../../../Common/Popups/OrderPopup';
import Marketplace, { MarketplaceRef } from './Marketplace';
import { ShareMeetingPopUpRef } from '../../../Common/Popups/ShareMeetingPopUp';
import { MeetingDetailsPopUpRef } from '../../../Common/Popups/MeetingDetailsPopUp';
import { MenuFoodItemsLoading } from '../../../Common/Loadings/MenuPage/MenuDetailLoadings';

// Redux Actions
import { getOrderDetails } from '../../../Actions/orderActions';
import { getAnnouncements } from '../../../Actions/userActions';
import { getCompanyForOrderPurpose } from '../../../Actions/companyActions';
import { getShowMenuTab, getSingleDeliveryMenu } from '../../../Actions/menuActions';
import { getDeliveryDates, getSingleSchedule } from '../../../Actions/scheduleDeliveryActions';

// Utitlities
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { getPricesToShown } from '../../../Utilities/getPricesToShown';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
import { dashboardTranslations } from '../../../Translations/dashboardTranslations';
import { showToastNotification } from '../../../Utilities/showToastNotification';

// Types
import { Cuisine } from '../../../Interfaces/PayloadsAndResponses/Cuisines';
import { GetAnnouncements } from '../../../Interfaces/PayloadsAndResponses/Users';
import { LocalStorageCompany, User } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { Delivery, SchedualeRequest, DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { Menu, OrderDetails, ReduxRootState, SchedulesDetails, Users } from '../../../Interfaces/ReduxInterface/ReduxRootState';

// CSS
import 'animate.css';
import '../CSS/custom.css';
import 'rc-steps/assets/index.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

// Components 
import Cart from '../../Cart/Cart';
import isEmpty from '../../../Utilities/is_empty';
import { MARKETPLACE_PAGE } from '../../../Actions/types';
import store from '../../../store';
// import { getDateForMarketplace } from '../../../Utilities/getDateForMarketplace';
import UserLocation from './UserLocation';


type Params = { date: string, address_id: string };

type PublicRestaurantMenuProps = {
  address_id?: string;
  rest_id?: string;
  i18n: WithTranslation | null;
  menu: Menu;
  user: Users;
  order: OrderDetails;
  cuisines: Cuisine[] | null;
  announcements: GetAnnouncements;
  schedules_details: SchedulesDetails;
  schedule_update_request: SchedualeRequest | null;
  getCompanyForOrderPurpose: () => void;
  getShowMenuTab: () => void;
  getAnnouncements: () => void;
  getOrderDetails: (slug: string) => void;
  getSingleSchedule: (slug: string) => void;
  getDeliveryDates: (from?: string, to?: string) => void;
  getSingleDeliveryMenu: (scheduleSlug: string, menu_type: string, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], token?: string, filterPurpose?: boolean) => void;
} & RouteComponentProps<Params>;

export type PublicRestaurantMenuState = {
  cancelled: boolean;
  screenWidth: number,
  cloneOrder: boolean;
  isOpenSidebar: boolean;
  showDeliveryOrdersSidebar: boolean;
  companyData: null | LocalStorageCompany,
  menu_type: string | undefined,
  address_id: number | undefined;
  field_required: boolean | undefined;
  scheduleMarketplaceDelivery: boolean;
  currentMeeting: Delivery | null | undefined;
  showCart: boolean;
  showSignupPopup: boolean;
  hideHeaderCart: boolean;
  selectedTypeheadPill: Array<string>;
}

class PublicRestaurantMenu extends Component<PublicRestaurantMenuProps, PublicRestaurantMenuState> {
  orderPopup = createRef<OrderPopupRef>().current;
  shareMenu = createRef<ShareMeetingPopUpRef>().current;
  marketplace = createRef<MarketplaceRef>().current;
  meetingPopup = createRef<MeetingDetailsPopUpRef>().current;

  initialPrice = 0;

  state: PublicRestaurantMenuState = {
    menu_type: '',
    cancelled: true,
    address_id: undefined,
    cloneOrder: false,
    companyData: null,
    currentMeeting: null,
    isOpenSidebar: false,
    field_required: undefined,
    showDeliveryOrdersSidebar: false,
    scheduleMarketplaceDelivery: false,
    screenWidth: window.document.body.clientWidth,
    showSignupPopup: false,
    showCart: false,
    hideHeaderCart: false,
    selectedTypeheadPill: []
  }

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.document.body.clientWidth })
  }


  componentDidMount() {
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    });
    const {
      history,
      match: { params: { address_id,
      } },
    } = this.props

    // const dateForMarketplace = getDateForMarketplace()
    // const addresssss = this?.handleGetLocation()

    if (!localStorage.getItem('address') && !localStorage.getItem('user')) {
      // Redirection to chowmill.com stopped from app.chowmill.com
      // this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
      // window.location.replace('https://chowmill.com/')
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    if (!isEmpty(user?.name)) {
      if (history.location?.search?.split('=')[1] && history.location?.search?.split('=')[1] !== 'billing') {
        const order_id = history.location?.search?.split('=')[1]
        order_id && localStorage.setItem('order_id', `${order_id}`);
      }
      // this.props.history?.push({ pathname: `/signin` })
    } else {
      // this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
    }

    this.scheduleMarketplace();

    dashboardTranslations();

    // $(window).bind('popstate', function () {
    //   window.location.reload();
    // });
    if (address_id === null) {
      // history.push('/signup');
      return;
    }
    document.title = 'Menu | Chowmill';
    window?.addEventListener('resize', this.updateScreenWidth);

    // On Escape close sidebar
    const handleKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        this.setState({ showDeliveryOrdersSidebar: false })
        this.setState({ showSignupPopup: false })
        this.setState({ showCart: false })
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }

  hideCartOnHeader = () => {
    this.setState({ hideHeaderCart: true })
  }


  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateScreenWidth);
  }

  onUpdatePage = () => {
    if (this.state.scheduleMarketplaceDelivery) {
      this.setState({ scheduleMarketplaceDelivery: false });
    }
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    // this.props.match.params.meetingSlug && this.props.getSingleSchedule(this.props.match.params.meetingSlug)
  }

  scheduleMarketplace = () => {
    this.setState({ scheduleMarketplaceDelivery: true, currentMeeting: null });
    if (this.marketplace !== null) {
      this.marketplace.setState({ viewRestaurantMenu: false });
    }
  }

  redirectToNewDelivery = (newDelivery: DeliveryOnSingleDate, deliveryDate: string | Date) => {
    if (!!newDelivery && !!deliveryDate && this.state?.currentMeeting?.slug !== newDelivery?.slug) {
    }
  }

  cutOffPassed = (cutoff_at?: string) => {
    let cutOffPassed, companyAdmin = true;

    if (companyAdmin === true) {
      cutOffPassed = cutoff_at ? dayjs(cutoff_at).isBefore(new Date()) : dayjs(this.state.currentMeeting?.admin_cutoff_at).isBefore(new Date());
    } else {
      cutOffPassed = dayjs(this.state.currentMeeting?.cutoff_at).isBefore(new Date());
    }
    return cutOffPassed;
  }

  openModalEdit = (id: number, runningmenu: Delivery) => {
    this.orderPopup?.setState({
      companyData: JSON.parse(localStorage.getItem('company') || '{}'),
      menu_type: runningmenu.menu_type,
      model_type: 'edit',
      per_user_copay: runningmenu.per_user_copay
    });
    this.orderPopup?.openModalEdit(id);
  }

  onCancelMeeting = () => {
    // this.props.history.push({ pathname: `/dashboard` });
  }
  openCart = () => {
    this.setState({ showCart: true })
  }

  onUpdateOrder = () => {
    // Updating View all Items Popup orders in case of order update.
    this.state?.showDeliveryOrdersSidebar && this.state.currentMeeting?.slug && this.props.getOrderDetails(this.state.currentMeeting.slug);
    // check if order successfully placed and at the time of order custom
    // fields were required than make it not required to avoid display it on popup
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    // Get Updated menu for current delivery after new order or Order Update
    // this.props.match.params.meetingSlug && this.props.getSingleDeliveryMenu(this.props.match.params.meetingSlug, 'lunch');
  }

  openMeetingSpecific = () => {
    if (this.state.currentMeeting?.field_required) {
      this.meetingPopup?.openModal(this.state.currentMeeting.slug, 'approved', true);
    }
  }

  truncateString = (str: string, maxLen: number, separator: string = ' ') => {
    if (str?.length <= maxLen) return str;
    return str?.substr(0, str?.lastIndexOf(separator, maxLen)) + '...';
  }

  getPricesToShown = (foodPrice: number, skip_markup: boolean, per_meal_budget: number) => {
    return getPricesToShown(foodPrice, skip_markup, this.state.companyData ?? getloggedInUser().loggedInUserCompany, per_meal_budget);
  }

  scheduleMarketplaceHiddenBtnHandler = () => {
    this.scheduleMarketplace()
  }

  trimRunningMenuName = (name: string) => {
    if (name?.split(' ’s Delivery').length > 1) {
      return name?.split(' ’s Delivery')[0] + '’s Delivery' + name?.split(' ’s Delivery')[1];
    } else {
      return name;
    }
  }



  render() {
    // Props Destructuring
    const {
      match: { params: { date = '',
      } = {}
      } = {},
      menu: {
        menu,
        loading: menuLoading,
        isOpenPopupOnMenuDetailPage,
        delivery_recent_orders_loading,
        delivery_dynamic_sections_loading,
      },
    } = this.props;
    // State Destructuring
    const {
      screenWidth,
      currentMeeting,
      scheduleMarketplaceDelivery,
      showCart
    } = this.state;

    // Getting responsive number of slides for Carousel on Menu Detail Page.
    const {
      noOfSlidesForUserLiking,
      noOfSlidesForFoodItems,
    } = getResponsiveNoOfSlides();

    let MenuAreaSection: false | JSX.Element | JSX.Element[] | undefined;

    const showCarouselButtons = screenWidth > 768;

    const isMarketPlacePreview = scheduleMarketplaceDelivery === true && this.marketplace?.state?.viewRestaurantMenu === true;


    // Delivery Menu Part Start
    if (menuLoading === true || !menu || !currentMeeting || delivery_recent_orders_loading || delivery_dynamic_sections_loading) {
      MenuAreaSection = <MenuFoodItemsLoading totalSlides={Math.ceil(noOfSlidesForFoodItems)} visibleSlides={noOfSlidesForFoodItems} />;
    } else if (menu !== null && menuLoading === false) {
      MenuAreaSection =
        <MenuArea
          fromDashboard
          parentRef={this}
          menu={this.props.menu}
          currentMeeting={currentMeeting}
          cutOffPassed={this.cutOffPassed()}
          showCarouselButtons={showCarouselButtons}
          noOfSlidesForFoodItems={noOfSlidesForFoodItems}
          noOfSlidesForUserLiking={noOfSlidesForUserLiking}
          isOpenPopupOnMenuDetailPage={isOpenPopupOnMenuDetailPage}
        />
    }
    // Delivery Menu Part End

    return (
      <React.Fragment>
        <UserLocation address_id={this.props?.match.params?.address_id} />
        <Header
          scheduleMarketplaceDelivery={scheduleMarketplaceDelivery}
          toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
          showLeftSidebarToggler={isMarketPlacePreview || !scheduleMarketplaceDelivery}
          fromPublicMarketPlace
          fromPublicRestaurantMenu={true}
          showCart={(isOpen) => this.setState({ showCart: isOpen })}
          showDeliveryOrdersSidebar={(isOpen) => this.setState({ showDeliveryOrdersSidebar: isOpen })}
          isOpenSidebar={(isOpen) => this.setState({ isOpenSidebar: isOpen })}
          hideHeaderCart={this.state.hideHeaderCart}
          parentComponentRef={this}

        />

        <div className='container-fluid bg-white'>
          <div className='row'>
            <div id='mainMenueArea'
              style={{ paddingTop: '78px' }}
              className={'col-12 px-0'}>
              {scheduleMarketplaceDelivery ?
                <Marketplace
                  dashboardRef={this}
                  onRef={(marketplaceRef: MarketplaceRef | null) => (this.marketplace = marketplaceRef)}
                  orderPopup={this.orderPopup}
                  setState={(p: any) => { this.setState(p) }}
                  // selectedDate={dayjs(date, 'YYYY-MM-DD').toDate()}
                  selectedDate={dayjs(date, 'M-D-YYYY').toDate()}
                  onUpdatePage={this.onUpdatePage.bind(this)}
                  fromPublicMarketPlace
                  fromPublicRestaurantMenu={true}
                  hideCartOnHeader={this.hideCartOnHeader.bind(this)}
                  isFromUrlRestaurant={true}
                />
                :
                <div className='m-auto px-0 px-md-2 mx-0 text-left main-menu-area'>
                  {MenuAreaSection}
                </div>
              }
            </div>

          </div>
        </div>

        <OrderPopup
          // onDashboard={true}
          setState={(s: PublicRestaurantMenuState) => this.setState(s)}
          meetingMealBudget={currentMeeting?.per_meal_budget}
          onUpdatePage={this.onUpdatePage.bind(this)}
          onUpdateOrder={this.onUpdateOrder.bind(this)}
          onClose={this.openMeetingSpecific.bind(this)}
          openCart={this.openCart}
          onRef={(popupRef: OrderPopupRef | null) => { this.orderPopup = popupRef }}
          remaining_budget={(Number(currentMeeting?.remaining_budget) < 0) ? `0` : currentMeeting?.remaining_budget}
          fromPublicMarketPlace
          fromPublicRestaurantMenu={true}
          showCartNotification={(itemImage, itemName) => showToastNotification(itemName, 'Success!', false, 'top-right')}
        />

        <Cart
          isOpenSidebar={showCart}
          closeSidebar={() => { this.setState({ showCart: false }); document.title = 'Menu | Chowmill'; }}
          fromPublicMarketPlace
          fromPublicRestaurantMenu={true}
        />

      </React.Fragment>
    )
  }

}
const mapStateToProps = (state: ReduxRootState) => ({
  menu: state?.menu,
  order: state?.order,
  schedules_details: state?.schedules_details,
  i18n: state?.i18n?.i18n,
  schedule_update_request: state?.schedules_details?.update_schedule_status,
  cuisines: state?.cuisine_list?.cuisines,
  user: state?.users,
  announcements: state?.users?.announcements,
});

export type PublicRestaurantMenuRef = React.ElementRef<typeof PublicRestaurantMenu>;

export default connect(mapStateToProps, { getShowMenuTab, getAnnouncements, getDeliveryDates, getSingleDeliveryMenu, getOrderDetails, getSingleSchedule, getCompanyForOrderPurpose })(PublicRestaurantMenu);