// Pacakages
import React, { createRef } from 'react';
import $ from 'jquery';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import parse from 'html-react-parser';
// import ReactTooltip from 'react-tooltip';
// React Components
import MenuArea from '../Common/MenuArea';
import CloneOrders from '../Common/CloneOrders';
import MenuFilters from '../Common//MenuFilters';
import Footer from '../../../Common/HeaderFooter/Footer';
import MarketplaceSearchBar from './MarketplaceSearchBar';
// import DeliveryDetailsSection from '../Common/DeliveryDetails';
import { OrderPopupRef } from '../../../Common/Popups/OrderPopup';
import DeliveryDatesCalendar from '../Common/DeliverDatesCalendar';
import DeliveryDetailsForMobiles from '../Common/DeliveryDetailsForMobiles';
import DeliveryAddressPopUp from '../../../Common/Popups/DeliveryAddressPopUp';
// import { CombosRestuarants, HeadingLoader, MarketPlaceLoading } from '../../../Common/Loadings/MenuPage/MarketPlaceLoading';
import MarketplaceFiltersPopup from '../../../Common/Popups/MarketplaceFiltersPopup';
import Cart from '../../Cart/Cart';
// import RestaurantDummyLogo from '../SVGs/RestaurantDummyLogo.svg';
// import FilterRestaurantsBy from '../../../Common/SortingDropdowns/FilterRestaurantsDropdown';
import MeetingDetailsPopUp, { MeetingDetailsPopUpRef } from '../../../Common/Popups/MeetingDetailsPopUp';
// Redux Dispatch Actions
import { getCuisine } from '../../../Actions/cuisineActions';
import { getMenusOfRestaurant, getMenusOfRestaurantReValidate, getPublicMenusOfRestaurantReValidate } from '../../../Actions/menuActions';
import { getAddressStringByLatLong, getCompanyAddresses } from '../../../Actions/companyActions';
import { getMarketplaceSuggestions, getRestaurants, getPublicRestaurants, getMarketplaceFavorites, getMostPopularRestaurants, getTrendingDishes, getPublicMostPopularRestaurants, getPublicTrendingDishes, getPublicNewRestaurantsNearYou, getNewRestaurantsNearYou, getMarketPlaceFilters, getPublicMarketPlaceFilters, getDynamicTitles } from '../../../Actions/marketplaceActions'
import { MenuFiltersLoading, MenuFoodItemsLoading } from '../../../Common/Loadings/MenuPage/MenuDetailLoadings';
// Utilities
import isEmpty from '../../../Utilities/is_empty';
// import FootItemCarouselNextButton from '../SVGs/RestaurantCarouselNextButton.svg';
// import FootItemCarouselBackButton from '../SVGs/RestaurantCarouselBackButton.svg';
import FootItemCarouselNextButton from '../SVGs/FoodItemCarouselNextButton.svg'
import FootItemCarouselBackButton from '../SVGs/FoodItemCarouselBackButton.svg'
// import NewSliderChevronLeft from '../SVGs/NewSliderChevronLeft.svg'
// import NewSliderChevronRight from '../SVGs/NewSliderChevronRight.svg'
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { getPricesToShown } from '../../../Utilities/getPricesToShown';
import { ObjectOptionalProperties } from '../../../Interfaces/Common/common';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
// Types
import { WithTranslation } from 'react-i18next';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { MarketPlaceCompanyAddress } from '../../../Interfaces/PayloadsAndResponses/Company';
import { ApprovedMeetingMenuDetailState, ApprovedMeetingMenuDetailRef } from '../ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import { Addresses, MarketplaceDishes, MarketplaceSuggestions, Menu, ReduxRootState, SchedulesDetails, ServerCartData } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { MarketplaceRestaurantSearch, GenericTypeForMarketplaceAPIs, MarketplaceRestaurant, Combo, DynamicTitles } from '../../../Interfaces/PayloadsAndResponses/Marketplace';
import { PublicMarketPlaceRef } from '../MarketplacePreview/PublicMarketPlaceWrapper';
// CSS
import 'react-multi-carousel/lib/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
// import './MarketPlace.css'
import EmailVerification from '../../Invites/EmailVerificationInvite/EmailVerification';
import MenuDetailPageCarousel from '../Common/MenuDetailPageCarousel';
import { Slide } from 'pure-react-carousel';
import { CART_ADDRESS_IDS, CART_DISPLAY_CHECK, CONTINUE_ORDERING_FOR_SAME_CART, MARKETPLACE_PAGE, MARKETPLACE_SECTION_SCROLL, MARKETPLACE_SECTION_SCROLL_PERMISSION } from '../../../Actions/types';
import store from '../../../store';
import EnterprizeConfirmationPopUp from '../../../Common/Popups/EnterprizeConfirmationPopUp';
import EnterprizePlanViewerPopUp from '../../../Common/Popups/EnterprizePlanViewerPopUp';
import { LocalStorageCompany, User } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { AddressDataResponse, AddressDataType } from '../../../Interfaces/PayloadsAndResponses/Address';
import { PlaceCartOrderPayload } from '../../../Interfaces/PayloadsAndResponses/Cart';
// import MarketPlaceDeliveryAddressPopUp from '../../../Common/Popups/MarketPlaceDeliveryAddressPopUp';
import ComboCard from './ComboCard';
import ShowAllMostPopularRestaurants from './ShowAllMostPopularRestaurants';
import ReactTooltip from 'react-tooltip';
import { getAddressDataPlaceId, getDateWithTimeZone } from '../../../Utilities/functions';
import MarketplaceRestaurantCard from './MarketPlaceRestaurantCard';
import Spinner from '../../../Common/Spinners/Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import MarketPlaceSectionSkeleton from './MarketPlaceSectionSkeleton';
import MarketplaceCombosSkeleton from './MarketplaceCombosSkeleton';
import Skeleton from 'react-loading-skeleton';

import { PublicRestaurantMenuRef } from './PublicRestaurantMenu';
// import MarketplaceScheduleDelivery from './MarketplaceScheduleDelivery';
import MarketPlaceDeliveryDetails from '../Common/MarketPlaceDeliveryDetails';
import MarketPlaceFoodItemCard from './MarketPlaceFoodItemCard';
import getPlaceDetails from '../../../Utilities/locationApi';
import MarketPlaceDeliveryAddressPublicPopUp from '../../../Common/Popups/MarketPlaceDeliverAddressPublic';
import getAddressStringData from '../../../Utilities/addressStringData';
import { modifyAddressInUrl } from '../../../Utilities/urlHandlingUtility';
import { getDateForMarketplace } from '../../../Utilities/getDateForMarketplace';
import FilterRestaurantsBy from '../../../Common/SortingDropdowns/FilterRestaurantsDropdown';
import CreateNewMeetingPopUp from '../../../Common/Popups/CreateNewMeetingPopUp';



// const MAX_INGREDIENTS_FILTER = 5;
// const carousel_responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 2,
//     slidesToSlide: 2, // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//     slidesToSlide: 2, // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };
type Params = { date: string, meetingSlug?: string, menu_type: string | undefined, address_id: string, dynamicAddressString:string };
export type MarketplaceProps = {
  // Parent Props
  dashboardRef: ApprovedMeetingMenuDetailRef | PublicMarketPlaceRef | PublicRestaurantMenuRef,
  orderPopup: OrderPopupRef | null;
  selectedDate: Date | string;
  isFromUrlRestaurant?: boolean;
  fromPublicRestaurantMenu?: boolean;
  hideCartOnHeader?: () => void;
  onUpdatePage: () => void;
  onRef: (marketplaceRef: MarketplaceRef | null) => void;
  setState: (dashboardNewState: ObjectOptionalProperties<ApprovedMeetingMenuDetailState, keyof ApprovedMeetingMenuDetailState>) => void;
  // Reducer Props
  i18n: WithTranslation | null;
  menu: Menu;
  addresses: Addresses,
  eSearch: MarketplaceSuggestions,
  schedules_details: SchedulesDetails;
  address_ids?: any;
  cart_local_data: PlaceCartOrderPayload;
  cart_server_data: ServerCartData;
  selectedAddressData: AddressDataResponse;
  fromPublicMarketPlace?: boolean;
  fromPublicAddressMarketPlace?: boolean;
  converted_to_enterprize?: boolean;
  activeRestaurantIds: number[];
  isLoading: boolean;
  dynamicTitlesReduxData?: DynamicTitles;
  searchKeyWordsFromURL?: any[];
  // publicMarketplaceRef?: PublicMarketPlaceRef;
  // Dispatch Props
  getCuisine: () => void;
  getCompanyAddresses: () => void;
  // getMarketplaceIPAddressData: (date: string) => void;
  getDynamicTitles: (dynamicTitle: string | null, seoRedirectfun?: () => void) => void;
  scrollHandling?: ((divPositionCheck: boolean) => void) | undefined;
  getMarketplaceSuggestions: (value: string) => void;
  // getMenusOfRestaurantCombos: (comboAllRestaurantsIdArray: number[], mealTypeForMenuCheckAPI: string) => void;
  getMenusOfRestaurant: (addressId: number, menuType: number, mealTypeForMenuCheckAPI: string, filterApplied?: false, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose?: boolean) => void;
  getMenusOfRestaurantReValidate: (addressId: number, menuType: number, mealTypeForMenuCheckAPI: string, placeId: number, delivery_at: string, backToMarketplace: () => void, filterApplied?: false, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose?: boolean) => void;
  getPublicMenusOfRestaurantReValidate: (addressId: number, menuType: number, mealTypeForMenuCheckAPI: string, lat: number | null, long: number | null, time_zone: string, delivery_at: string, backToMarketplace: () => void, filterApplied?: false, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose?: boolean) => void;
  getRestaurants: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, page_number: number, exclude_ids: number[], paginated?: boolean, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getPublicRestaurants: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, page_number: number, exclude_ids: number[], paginated?: boolean, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  // getMarketplaceCombos: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  // getPublicMarketplaceCombos: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getMarketplaceFavorites: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getMostPopularRestaurants: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getTrendingDishes: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getPublicMostPopularRestaurants: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getPublicTrendingDishes: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getPublicNewRestaurantsNearYou: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getNewRestaurantsNearYou: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getMarketPlaceFilters: (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;
  getPublicMarketPlaceFilters: (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, order_by?: string, order_type?: 'DESC' | 'ASC') => void;

} & RouteComponentProps<Params>;

export type MarketPlaceState = {
  sortBy: string;
  page: number;
  paginationLoading: boolean;
  favorited: boolean;
  heightControl: boolean;
  mealType: 'individual' | 'buffet';
  meal_type: string;
  isMoving: boolean;
  deliveryAt: string; // YYYY-MM-DDTHH:mm
  screenWidth: number;
  meetingName: string;
  searchKeywords: string;
  mealTypeNumber: number;
  mealTypeCheck: boolean;
  searchRestaurant: string;
  restaurantId: number | '';
  isOpenAddresPopUp: boolean;
  isOpenEmptyAddresPopUp: boolean;
  isOpenFiltersPopUp: boolean;
  startDate: undefined | Date; // set from dashboard too
  orderCount: string | number;
  viewRestaurantMenu: boolean;
  selectedTypehead: Array<string>;
  selectedRestaurants: Array<number>,
  currentNumberOfShownDietries: number;
  currentNumberOfShownCuisines: number;
  openDeliveryAddressDropdown: boolean;
  currentNumberOfShownIngredients: number;
  restaurants: MarketplaceRestaurantSearch;
  selectedCompanyAddressID: undefined | number;
  restaurantSortData: { order_by: string, order_type: string }
  selectedAddressDetail: MarketPlaceCompanyAddress | undefined;
  localAddressState: AddressDataType | any | null;
  showCart: boolean;
  marketPlaceDietries: any;
  marketplaceIngredients: any;
  marketplaceCuisines: any;
  marketplaceFilters: any;
  stickyDivRef: null | number;
  divPositionCheck: boolean;
  enterprizeConfirm: boolean,
  enterprizePopUp: boolean,
  enterprizeEmailSent: boolean,
  localStorageData: boolean;
  cartDeliveryDetails: any;
  addressPopUpCloseCheck: boolean;
  // price: number;
  // avg_rating: number;
  restaurantHeaderCheck: null | boolean;
  showAllMostPopularRestaurants: boolean;
  showAllNewRestaurantsNearYou: boolean;
  isFilterApply: boolean;
  mealTypeForMenuCheck: boolean;
  mealTypeForMenuCheckAPI: string;
  buffetToggleValue: boolean;
  // menuPageFoodItemPrice: number;
  // menuPageFoodItemRating: number;
  typeheadCheck: boolean;
  queryFilters: any;
  addressInURL: null | boolean;
  marketPlace_trending_dishes: any[];
  marketPlace_marketplaceFavorites: any[];
  marketPlace_most_popular: any[];
  marketPlace_new_restaurants_near_you: any[];
  marketPlaceSortBy: string;
  sortedMarketplace: {
    marketplace_favorite: MarketplaceRestaurant[] | null,
    marketplace_popular_restaurant: MarketplaceRestaurant[] | null,
    marketplace_new_restaurants: MarketplaceRestaurant[] | null,
    marketplace_all_restaurants: MarketplaceRestaurant[] | undefined,
  };
  sortingLoaderLocal: boolean;
  marketPlaceNewDeliveryCreation: boolean;
}

const { loggedInUserCompany, loggedInUser } = getloggedInUser();
class Marketplace extends React.Component<MarketplaceProps, MarketPlaceState> {
  perMealBudget = loggedInUserCompany.user_meal_budget;
  company = loggedInUserCompany;
  user = loggedInUser;
  localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  localAddressNew = JSON.parse(localStorage.getItem('addressokok') || '{}');
  localStorageCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;

  meetingPopup = createRef<MeetingDetailsPopUpRef>().current;
  stickyDivRef = createRef<HTMLDivElement>();
  private timeoutId: number | undefined;

  restaurantHeaderCheck = createRef<HTMLDivElement>();
  state: MarketPlaceState = {
    sortBy: '',
    page: 1,
    paginationLoading: false,
    favorited: false,
    heightControl: true,
    orderCount: '',
    deliveryAt: '', // YYYY-MM-DDTHH:mm
    isMoving: false,
    meetingName: '',
    restaurantId: '',
    mealTypeNumber: 1,
    meal_type: 'lunch',
    searchKeywords: '',
    selectedTypehead: this.props?.searchKeyWordsFromURL ?? [],
    searchRestaurant: '',
    mealTypeCheck: false,
    mealType: 'individual',
    selectedRestaurants: [],
    isOpenAddresPopUp: false,
    isOpenEmptyAddresPopUp: false,
    isOpenFiltersPopUp: false,
    viewRestaurantMenu: false,
    currentNumberOfShownCuisines: 5,
    currentNumberOfShownDietries: 5,
    selectedAddressDetail: undefined,
    currentNumberOfShownIngredients: 5,
    openDeliveryAddressDropdown: false,
    selectedCompanyAddressID: undefined,
    screenWidth: window.document.body.clientWidth,
    restaurants: {} as MarketplaceRestaurantSearch,
    restaurantSortData: { order_by: '', order_type: '' },
    startDate: new Date(dayjs(new Date(dayjs((new Date()).toString()).add(1, 'day').toDate())).format('ddd MMM D YYYY') + ' 11:45:00'), // set from dashboard too
    localAddressState: {} as AddressDataType,
    showCart: false,
    marketPlaceDietries: [],
    marketplaceIngredients: [],
    marketplaceCuisines: [],
    marketplaceFilters: [],
    stickyDivRef: null,
    divPositionCheck: false,
    enterprizeConfirm: false,
    enterprizePopUp: false,
    enterprizeEmailSent: false,
    localStorageData: this.localStorageCompany?.enterprise_status === 'not_converted' ? true : false,
    cartDeliveryDetails: {},
    addressPopUpCloseCheck: false,
    // avg_rating: 3.5,
    // price: 18,
    restaurantHeaderCheck: null,
    showAllMostPopularRestaurants: false,
    showAllNewRestaurantsNearYou: false,
    isFilterApply: false,
    mealTypeForMenuCheckAPI: 'individual',
    mealTypeForMenuCheck: false,
    buffetToggleValue: false,
    // menuPageFoodItemPrice: 0,
    // menuPageFoodItemRating: 0,
    typeheadCheck: false,
    queryFilters: {},
    addressInURL: false,
    marketPlace_trending_dishes: [],
    marketPlace_marketplaceFavorites: [],
    marketPlace_most_popular: [],
    marketPlace_new_restaurants_near_you: [],
    marketPlaceSortBy: 'rating',
    sortedMarketplace: {
      marketplace_favorite: this.props.eSearch?.marketplaceFavorites,
      marketplace_popular_restaurant: this.props?.eSearch?.most_popular,
      marketplace_new_restaurants: this.props?.eSearch?.new_restaurants_near_you,
      marketplace_all_restaurants: this.props?.eSearch?.restaurants?.restaurants,
    },
    sortingLoaderLocal: false,
    marketPlaceNewDeliveryCreation: false,
  }

  // handleRatingChange = (value: number) => {
  //   this.setState({ avg_rating: value });
  // };

  // handleAmountChange = (value: number) => {
  //   this.setState({ price: value });
  // };

  // handleMenuRatingChange = (value: number) => {
  //   this.setState({ menuPageFoodItemRating: value });
  // };

  // handleMenuAmountChange = (value: number) => {
  //   this.setState({ menuPageFoodItemPrice: value });
  // };


  // handleSubmit = (price: number, avg_rating: number) => {
  //   this.setState({ isFilterApply: true })
  //   if (this.state.selectedCompanyAddressID) {
  //     // this.props.getRestaurants(this.state.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, [], price, avg_rating,this.state.page);
  //     this.getMarketPlaceData(this.state.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, [], this.state.page)

  //   } else {
  //     const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
  //     // this.props?.fromPublicMarketPlace && this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(this.state.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, [], price, avg_rating,this.state.page)
  //     this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData(this.state.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, [], this.state.page)

  //   }
  // };

  closeModal = () => {
    this.setState({ isOpenAddresPopUp: false, isOpenEmptyAddresPopUp: false });
    this.setState({ enterprizePopUp: false })
  };
  openModal = () => {
    localStorage.setItem('IsValidAddress', JSON.stringify(true))
    this.setState({ isOpenAddresPopUp: true });
  };

  openEmptyAddressModal = () => {
    this.setState({ isOpenEmptyAddresPopUp: true });
  };


  updateScreenWidth = () => {
    this.setState({ screenWidth: window.document.body.clientWidth })
  }

  // componentDidUpdate(prevProps: MarketplaceProps, prevState: MarketPlaceState) {
  //   // console.log('componentDidUpdate, localAddress', this.localAddress);
  //   if(prevState.deliveryAt !== this.state.deliveryAt || prevState.selectedAddressDetail?.formatted_address !== this.state.selectedAddressDetail?.formatted_address) {

  //     console.log('prevState has changed this.state.selectedAddressDetail?.formatted_address', this.state.selectedAddressDetail?.formatted_address)
  //     this.props.fromPublicMarketPlace && this.props.getPublicRestaurants(this.state.searchKeywords, this.localAddress.latitude, this.localAddress.longitude, this.localAddress.timezone, 'lunch', 'individual', this.state.deliveryAt )
  //   }
  // }
  componentDidUpdate(prevProps: any) {
    // if (this.props.eSearch?.marketplace_filters !== prevProps.eSearch?.marketplace_filters) {
    //   // Updating my local state based on the Redux state change
    //   const filtersData = this.props?.eSearch?.marketplace_filters
    //   this.setState({
    //     restaurants: { ...this.state?.restaurants, cuisines: filtersData?.cuisines ?? [], dietaries: filtersData?.dietaries ?? [], ingredients: filtersData?.ingredients ?? [] }
    //   });
    // }
    if (this.props.eSearch !== prevProps.eSearch) {
      const permissionToScroll = this.props.eSearch?.permissionToScroll
      const scrollingHash = this.props.eSearch?.marketPlaceSectionScroll
      scrollingHash && permissionToScroll && this.ScrollToSpecificSection(scrollingHash?.slice(1))
    }
    this.localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  }

  formatDate = (date: string | Date): string => {
    let parsedDate;

    // If the input is a string, attempt to parse it using Day.js
    if (typeof date === 'string') {
      // Attempt to parse the date string using known formats
      parsedDate = dayjs(date, ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'MMMM DD, YYYY', 'MM/DD/YYYY', 'ddd, DD MMM YYYY HH:mm:ss Z', 'M-DD-YYYY', 'M-D-YYYY'], true);
    } else {
      // If the input is already a Date object, use it directly
      parsedDate = dayjs(date);
    }
    // Check if the parsed date is valid, if not return an empty string
    if (!parsedDate.isValid()) {
      return '';
    }
    // Return the formatted date in 'YYYY-MM-DD' format
    return parsedDate.format('YYYY-MM-DD');
  };


  componentDidMount() {

    document.title = 'Chowmill: Office food delivery';

    // const urlParams = new URLSearchParams(window.location.search);
    // const placeId = urlParams.get('address_id');
    // const idplace= `${placeId ?? ''}`
    const { match } = this.props;
    const { address_id } = match.params;

    if (this.props?.fromPublicMarketPlace || this.props?.fromPublicAddressMarketPlace) {

      // Removed Email redirect Links data from localstorage incase of public user
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      params.delete('order_id');
      params.delete('runningmenu_id');

      localStorage.removeItem('order_id');
      localStorage.removeItem('runningmenu_id');
      localStorage.removeItem('rating_count');

      url.search = params?.toString();
      window.history.replaceState({}, '', url?.toString());
    }

    const addressPlaceId = `${address_id ?? ''}`
    const url = window.location.pathname?.split('/')
    const [, home, date, address_id_old] = url

    if (!isEmpty(address_id_old)) {
      if (!isEmpty(home) && home === 'home' && !isEmpty(date)) {
        // Redirect from the OLD URL to new one
        window.location.replace(`/get-address?placeId=${address_id_old}`);
      }
    }


    const queryStringPublic = window.location.search;
    // Create a URLSearchParams object
    const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
    const titleValuePublic = paramsDataDatapublic.get('address');
    const optionalPublicDate = paramsDataDatapublic?.get('date')
    // const urlOptionalDate = dayjs(`${optionalPublicDate}`)
    // if (!isEmpty(optionalPublicDate)) {
    //   const getPublicDate = dayjs(`${urlOptionalDate}`, 'YYYY-MM-DD').toDate()
    // }



    if (titleValuePublic && this.props.fromPublicMarketPlace) {
      // Fetch location data using the utility function
      this.fetchLocationStringData(`${titleValuePublic}`, (data) => {
        // Using the result of fetchLocationStringData
        const queryStringPublic = window.location.search;
        const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
        const searchtest: any = paramsDataDatapublic.getAll('search')
        const { selectedDate, selectedAddressData } = this.props;
        if (data?.latitude) {
          let dateForGettingPublicMarketplace: any
          if (optionalPublicDate && this.props?.fromPublicAddressMarketPlace) {
            const gettingFinalDateForPublicUser: any | Date = optionalPublicDate ?? selectedDate ?? getDateForMarketplace()
            dateForGettingPublicMarketplace = gettingFinalDateForPublicUser
          } else {
            dateForGettingPublicMarketplace = selectedDate
          }
          this.setState({ localAddressState: data })
          const searchURLTerms = searchtest?.join(' ') ?? ''

          const dateForRegularUser = this.formatDate(dateForGettingPublicMarketplace)

          this.getPublicMarketPlaceData(searchURLTerms, data?.latitude, data?.longitude, data?.address_line, data?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(dateForRegularUser).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), data?.zip, [], 1, false)
        } else {
          const invalidAddress = {
            googleResponse: 'invalid'
          }
          localStorage.setItem("no-response", JSON.stringify(invalidAddress));
        }

        // Proceed with the rest of the logic here
      }, (error) => {
        console.error('Error fetching location string data:', error);
      });
      this.setState({ addressInURL: true })
    } else {
      this.setState({ addressInURL: false })
      // const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
      // console.error('Address String not found in the URL');
    }

    // if (dynamicAddressString) {
    //   // Fetch location data using the utility function
    //   // this.fetchLocationStringData(dynamicAddressString);
    // } else {
    //   console.error('Address String not found in the URL');
    // }
    if (addressPlaceId) {
      // Fetch location data using the utility function
      this.fetchLocationData(addressPlaceId);
      this.setState({ addressInURL: true })
    } else {
      // this.setState({ addressInURL: false })
      // console.error('Place ID not found in the URL');
    }

    this.props.onRef(this);
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    })

    const getSEOTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
    !this.props?.fromPublicMarketPlace && this.props?.getDynamicTitles(getSEOTitle)

    const { selectedDate, selectedAddressData } = this.props;

    const gettingFinalDateForPublicUser: any | Date = optionalPublicDate ?? selectedDate ?? getDateForMarketplace()
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')


    // date used from state for selfsignUP Working for chrome issue in firefox for date format
    const dateForPublicUser = this.formatDate(gettingFinalDateForPublicUser)
    const dateForRegularUser = this.formatDate(selectedDate)


    if (optionalPublicDate && this.props?.fromPublicAddressMarketPlace) {
      this.setState({
        deliveryAt: getDateWithTimeZone(`${dayjs(dateForPublicUser).format('YYYY-MM-DD')}T11:45`, localStorageAddressData?.time_zone ?? this.state.localAddressState?.time_zone ?? selectedAddressData?.time_zone) ?? "America/Los_Angeles"
      });
      setTimeout(() => {
        this.setState({ startDate: new Date(dayjs(new Date(dateForPublicUser)).format('ddd MMM D YYYY') + ' 11:45:00') });
      }, 500);
    } else {
      this.setState({
        deliveryAt: getDateWithTimeZone(`${dayjs(dateForRegularUser).format('YYYY-MM-DD')}T11:45`, selectedAddressData?.time_zone) ?? ''
      });
      setTimeout(() => {
        this.setState({ startDate: new Date(dayjs(new Date(dateForRegularUser)).format('ddd MMM D YYYY') + ' 11:45:00') });
      }, 500);
    }
    // this.props.getCompanyAddresses();
    // Do not call in case of public market place
    !this.props?.fromPublicMarketPlace && this.props.getCompanyAddresses();

    if (this.props?.isFromUrlRestaurant) {

      // URLURL

      const urlData: any = this.props?.match?.params

      getAddressDataPlaceId(urlData?.address_id)
      // this.setSelectedAddress(address)
      this.showRestaurantAndComboMenu(+urlData?.rest_id ?? 2726)
    }

    const urldata = window.location.pathname
    const [, , marketPlaceDate, addressplaceid, restaurant_name, restid] = urldata?.split('/')


    const searchParams = new URLSearchParams(this.props.location.search);
    const marketPlaceSearchTerms = searchParams.getAll('search');

    if (marketPlaceSearchTerms || this.props.searchKeyWordsFromURL) {
      this.searchRestaurants(false)
    }

    if (restid && restaurant_name) {
      const restaurantData: any = {
        id: restid,
        name: restaurant_name
      }
      const searchParams = new URLSearchParams(this.props.location.search);
      const dietryFilters = searchParams.getAll('filters-dietry');
      const ingredientFilters = searchParams.getAll('filters-ingredient');
      let dietFilterArray: number[] = [], ingredientFilterArray: number[] = []

      dietryFilters?.forEach((diet) => {
        dietFilterArray?.push(+diet?.split('_')?.[1])
      })
      ingredientFilters?.forEach((ingredient) => {
        ingredientFilterArray?.push(+ingredient?.split('_')?.[1])
      })
      const queryFilters = {
        dietries: dietFilterArray,
        ingredients: ingredientFilterArray
      }
      this.setState({ queryFilters: queryFilters })
      this.selectRestaurantURLDirect(restaurantData, marketPlaceDate, addressplaceid, queryFilters)
    }
    // const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
    if (localStorageAddressData?.latitude) {
      this.setState({ localAddressState: localStorageAddressData })
      const searchURLTerms = this.props.searchKeyWordsFromURL?.join(' ') ?? ''


      const dateForPublicMarketPlace = this.formatDate(selectedDate)
      // this.props.getPublicRestaurants('', localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(selectedDate).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), localStorageAddressData?.zip, [], this.state.price, this.state.avg_rating,this.state.page, false)
      this.getPublicMarketPlaceData(searchURLTerms, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(dateForPublicMarketPlace).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), localStorageAddressData?.zip, [], this.state.page, false)

      // const dateForPublicMarketPlace = this.formatDate(selectedDate)
      // this.props.getPublicRestaurants('', localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(selectedDate).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), localStorageAddressData?.zip, [], this.state.price, this.state.avg_rating,this.state.page, false)
      // this.getPublicMarketPlaceData(searchURLTerms, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(selectedDate).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), localStorageAddressData?.zip, [], this.state.page, false)

    } else {
      this.checkLocalStorageChanges()
    }
    if (isEmpty(localStorageAddressData) && !this.props?.isFromUrlRestaurant) {
      //The location popUp for marketplace in case of no address provided
      this.props?.fromPublicMarketPlace && this.openEmptyAddressModal()
    }
    window?.addEventListener('resize', this.updateScreenWidth);
    document.addEventListener('scroll', this.handleScroll);

  }

  fetchLocationData = async (placeId: string) => {
    const data = await getPlaceDetails(placeId);
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
    const { selectedDate, selectedAddressData } = this.props;
    const dateForRegularUser = this.formatDate(selectedDate)

    const locationData = !isEmpty(localStorageAddressData) ? localStorageAddressData : data

    localStorage.setItem('IsValidAddress', JSON.stringify(false))

    if (locationData?.latitude) {
      this.setState({ localAddressState: localStorageAddressData })
      this.getPublicMarketPlaceData('', locationData?.latitude, locationData?.longitude, locationData?.address_line, locationData?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(dateForRegularUser).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), locationData?.zip, [], 1, false)
    }
  };

  fetchLocationStringData = async (addressString: string, onSuccess: (data: any) => void, onError: (error: any) => void) => {

    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
    const { selectedDate, selectedAddressData } = this.props;

    const queryStringPublic = window.location.search;
    const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
    const searchtest: any = paramsDataDatapublic.getAll('search')

    // const data = await getPlaceDetails(placeId);
    const data: any  = await getAddressStringData(addressString)
    // let locationData : any
    onSuccess(data); 

    data?.latitude && data?.longitude && getAddressStringByLatLong( data?.latitude, data?.longitude)
    this.setState({ localAddressState: localStorageAddressData })

    if (data?.latitude) {
      this.setState({ localAddressState: data })
      const searchURLTerms = searchtest?.join(' ') ?? ''
      // const dateForPublicMarketPlace = this.formatDate(selectedDate)

      this.getPublicMarketPlaceData(searchURLTerms, data?.latitude, data?.longitude, data?.address_line, data?.time_zone, 'lunch', 'individual', getDateWithTimeZone(`${dayjs(selectedDate).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone), data?.zip, [], 1, false)
    } else {
      const invalidAddress = {
        googleResponse: 'invalid'
      }
      onError('error');
      localStorage.setItem("no-response", JSON.stringify(invalidAddress));
    }
    return data
  };

  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateScreenWidth);
    document.removeEventListener('scroll', this.handleScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps: MarketplaceProps) {
    const { addresses: { comp_addresses },
      schedules_details: { new_schedule_request_status },
      selectedAddressData: new_selectedAddressData
    } = nextProps;

    const {
      // addresses: { comp_addresses: prevprops_comp_addresses },
      schedules_details: { new_schedule_request_status: prevprops_new_schedule_request_status },
      // getRestaurants,
      onUpdatePage,
      selectedAddressData
    } = this.props;

    const {
      // sortBy,
      mealType,
      meal_type,
      deliveryAt,
      // searchKeywords,
      selectedCompanyAddressID,
      // price,
      // avg_rating
    } = this.state;

    this.setState({
      sortedMarketplace: {
        marketplace_favorite: this.props.eSearch?.marketplaceFavorites,
        marketplace_popular_restaurant: this.props?.eSearch?.most_popular,
        marketplace_new_restaurants: this.props?.eSearch?.new_restaurants_near_you,
        marketplace_all_restaurants: this.props?.eSearch?.restaurants?.restaurants,
      }
    })


    this.setState({
      sortedMarketplace: {
        marketplace_favorite: this.props.eSearch?.marketplaceFavorites,
        marketplace_popular_restaurant: this.props?.eSearch?.most_popular,
        marketplace_new_restaurants: this.props?.eSearch?.new_restaurants_near_you,
        marketplace_all_restaurants: this.props?.eSearch?.restaurants?.restaurants,
      }
    })


    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')

    if (new_schedule_request_status && new_schedule_request_status !== prevprops_new_schedule_request_status) {
      onUpdatePage();
    }
    const deliveryAtWithoutTimezone = deliveryAt.split('.')[0];

    if (new_selectedAddressData !== selectedAddressData) {
      this.setState({
        selectedCompanyAddressID: new_selectedAddressData.id,
        selectedAddressDetail: {
          id: new_selectedAddressData.id,
          address_line: new_selectedAddressData.address_line,
          address_name: new_selectedAddressData.address_name,
          formatted_address: new_selectedAddressData.address_line,
          delivery_instructions: new_selectedAddressData.delivery_instructions,
          time_zone: new_selectedAddressData.time_zone
        }
      })
        // getRestaurants('', new_selectedAddressData.id, meal_type, mealType, getDateWithTimeZone(deliveryAtWithoutTimezone, new_selectedAddressData?.time_zone), [], price, avg_rating,this.state.page)
        this.getMarketPlaceData('', new_selectedAddressData.id, meal_type, mealType, getDateWithTimeZone(deliveryAtWithoutTimezone, new_selectedAddressData?.time_zone), [], this.state.page)
      } else if (comp_addresses && comp_addresses.addresses) {
        if ((!isEmpty(deliveryAt)) && isEmpty(selectedCompanyAddressID) && comp_addresses.addresses[0] && isEmpty(localStorageAddressData)) {
          const adminSelectedAddress = comp_addresses?.addresses?.find(
            address => address.id === this.user.address_id) || comp_addresses.addresses[0];
          this.setState({ selectedCompanyAddressID: adminSelectedAddress.id, selectedAddressDetail: adminSelectedAddress });
          // getRestaurants('', adminSelectedAddress.id, meal_type, mealType, getDateWithTimeZone(deliveryAtWithoutTimezone, adminSelectedAddress?.time_zone), [], price, avg_rating,this.state.page)
          this.getMarketPlaceData('', adminSelectedAddress.id, meal_type, mealType, getDateWithTimeZone(deliveryAtWithoutTimezone, adminSelectedAddress?.time_zone), [], this.state.page)
  
        }
      }
    //No need to compare addresses array to find newly added address, it is returned in API response now
    // if (!isEmpty(prevprops_comp_addresses?.addresses) && comp_addresses?.addresses !== prevprops_comp_addresses?.addresses) {
    //   console.log('in UNSAFE_componentWillReceiveProps in if')
    //   let newAddress = comp_addresses?.addresses?.find((address, index) => address.id !== prevprops_comp_addresses?.addresses[index]?.id);
    //   console.log('newAddress', newAddress)

    //   if (newAddress?.id) {
    //     this.setState({ selectedCompanyAddressID: newAddress?.id, selectedAddressDetail: newAddress });
    //     getRestaurants(searchKeywords, newAddress?.id, meal_type, mealType, deliveryAt, [], sortBy);
    //   }
    // }
    // if (nextProps.eSearch.restaurants) {
    //   this.setState({ restaurants: nextProps.eSearch.restaurants });
    // }
  }
  round = (num: number) => {
    if (num % 1 === 0) {
      return num;
    }
    return (new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(num))
  }
  getPricesToShown(itemPrice: number, skip_markup: boolean) {
    return getPricesToShown(itemPrice, skip_markup, this.company, parseFloat(this.company.user_meal_budget));
  }
  returnPercentRating = (rating: number, starNumber: number) => {
    if (rating >= starNumber) {
      return '100%'
    }
    else if ((!Number.isInteger(rating)) && (starNumber - rating) < 1) {
      return Number((rating - Math.floor(rating)) * 100) + '%';
    }
  }
  handleSearchKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      this.props.getMarketplaceSuggestions(e.target.value);
    }
  }

  checkLocalStorageChanges = () => {
    // const currentLocalStorage = JSON.stringify(localStorage);
    const currentLocalStorage = localStorage.getItem('address-placeId');

    if (localStorage.lastLocalStorage === undefined) {
      localStorage.lastLocalStorage = currentLocalStorage;
      // this.setState({ localStorageStatus: 'No changes in Local Storage.' });
    } else if (localStorage.lastLocalStorage !== currentLocalStorage) {
      // this.setState({ localStorageStatus: 'Local Storage has changed!' });

      // const urldata = window.location.pathname
      const { dynamicAddressString } = this.props.match.params
      const localStorageAddress: AddressDataType = JSON.parse(localStorage.getItem('address') || '{}')

      // const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
      if (isEmpty(dynamicAddressString)) {
        !isEmpty(localStorageAddress) && modifyAddressInUrl(localStorageAddress)
      }
    }
    localStorage.lastLocalStorage = currentLocalStorage;

    // Check for changes periodically (e.g., every 2 seconds)
    this.timeoutId = window.setTimeout(this.checkLocalStorageChanges, 2000);
    // this.timeoutId = setTimeout(this.checkLocalStorageChanges, 2000);
  };
  handleDateChange = (date: Date | null, callRestaurantAPI?: boolean) => {

    const newPage = 1; // Set the page number to 1
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    if (date) {
      // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')

      let formatDeliveryAt = `${dayjs(date).format('YYYY-MM-DD')}T${dayjs(date).format('HH:mm')}`;
      
      let deliveryAt = getDateWithTimeZone(formatDeliveryAt, !isEmpty(localStorageAddressData) ? localStorageAddressData?.time_zone : this.props.selectedAddressData?.time_zone);

      const urldata = window.location.pathname
      const [, restaurantPage, , addressplaceid, restaurantName, restaurantIdMenuPage] = urldata?.split('/')

      // this.props.history.push({ pathname: `/home/${dayjs(date).format('M-D-YYYY')}/${addressplaceid}` })

      // Temporary
      // if (!this.props?.isFromUrlRestaurant) {
      const dynamicTitle: any = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
      if (!this.props?.fromPublicAddressMarketPlace) {

        if (!this.state.viewRestaurantMenu) {

          if (!this.props.fromPublicMarketPlace && !this.props?.fromPublicAddressMarketPlace) {
            this.props.history.push({ pathname: `/menu/${dayjs(date).format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}` });
          }
          // ?
          // this.props.history.push({ pathname: `/home/${dayjs(date).format('M-D-YYYY')}/${addressplaceid}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}` })
          // :
        } else {
          if (!this.props.fromPublicAddressMarketPlace) {

            if (this?.props?.fromPublicMarketPlace) {
              this.props?.fromPublicMarketPlace && this.props.history.push({ pathname: `/${restaurantPage}/${dayjs(date).format('M-D-YYYY')}/${addressplaceid}/${restaurantName}/${restaurantIdMenuPage}` })

            } else if (!isEmpty(user?.email)) {
              this.props.history.push({ pathname: `/menu/${dayjs(date).format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}` });
            }
          }
        }
      }

      this.setState({
        deliveryAt: deliveryAt,
        startDate: date,
        page: newPage
      });

      if (this.state?.viewRestaurantMenu) {
        if (this.props?.fromPublicMarketPlace) {
          this.props?.getPublicMenusOfRestaurantReValidate(+this.state.restaurantId, this.state.mealTypeNumber, this.state.mealTypeCheck === true ? 'buffet' : 'individual', localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.time_zone, deliveryAt, this.backToMarketplace)
        } else {
          this.state.selectedCompanyAddressID && this.props?.getMenusOfRestaurantReValidate(+this.state.restaurantId, this.state.mealTypeNumber, this.state.mealTypeCheck === true ? 'buffet' : 'individual', +this.state.selectedCompanyAddressID, deliveryAt, this.backToMarketplace)
        }
      } else {

        if (callRestaurantAPI === undefined) {
          if (!isEmpty(localStorageAddressData)) {
            if (this.state.typeheadCheck) {
              this.searchRestaurants(false)
            } else {
              // this.props.getPublicRestaurants(this.state.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', deliveryAt, localStorageAddressData?.zip, [], this.state.price, this.state.avg_rating, newPage)
              !this.state?.viewRestaurantMenu && this.getPublicMarketPlaceData(this.state.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', deliveryAt, localStorageAddressData?.zip, [], newPage)

            }
          } else if (this.state.selectedCompanyAddressID) {
            if (this.state.typeheadCheck) {
              this.searchRestaurants(false)
            } else {
              // this.props.getRestaurants(this.state.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealTypeCheck === true ? 'buffet' : 'individual', deliveryAt, [], this.state.price, this.state.avg_rating, newPage);
              this.getMarketPlaceData(this.state.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealTypeCheck === true ? 'buffet' : 'individual', deliveryAt, [], newPage)
            }
          }
        }
      }


      // const searchParams = new URLSearchParams(this.props.location.search);
      // const marketPlaceSearchTerms = searchParams.getAll('date');
      if (this.props?.fromPublicMarketPlace) {

        const changedDate = `${dayjs(date).format('M-D-YYYY')}`
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const DateInParams = params.get('date')
        // params.delete('date')
        // params.append('date', `${changedDate}`)
        if (!!DateInParams) {
          params.set('date', `${changedDate}`);
        } else {
          params.append('date', `${changedDate}`);
        }
        url.search = params?.toString();
        window.history.replaceState({}, '', url?.toString());
      }
    };
  }
  check_constraints() {
    let document: any = window.document;
    var test_passed = true;
    //convert string into number
    if (+this.state.orderCount < 1) {
      test_passed = false;
      document.getElementById('error_1').hidden = false;
    } else {
      document.getElementById('error_1').hidden = true;
      test_passed = true;
    }
    if (isEmpty(this.state.meetingName)) {
      test_passed = false;
      document.getElementById('error_4').hidden = false;
    } else {
      document.getElementById('error_4').hidden = true;
    }
    return test_passed;
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { selectedAddressData } = this.props;
    const { date } = this.props?.match?.params

    const companyCurentTime = selectedAddressData?.time_zone && dayjs()?.tz(`${selectedAddressData?.time_zone}`);

    const checkFor48Hours = dayjs().add(2, 'day')
    const currentHour = companyCurentTime ? companyCurentTime.hour() : dayjs().hour()

    const compareDate = dayjs(date).isBefore(dayjs(checkFor48Hours))
    const dateForMarketplaceStart = +currentHour >= 14 ? dayjs().add(3, 'day') : dayjs().add(2, 'day');
    const dateStartBuffet = new Date(`${dayjs(dateForMarketplaceStart).format('YYYY-MM-DD')}T11:45`)
    const dateForMarketplace = +currentHour >= 14 ? dayjs().add(3, 'day') : dayjs().add(2, 'day');

    if (value && compareDate) {

      let formatDeliveryAt = `${dayjs(dateForMarketplace).format('YYYY-MM-DD')}T${dayjs(dateForMarketplace).format('HH:mm')}`;
      let deliveryAt = (getDateWithTimeZone(`${dayjs(formatDeliveryAt).format('YYYY-MM-DD')}T11:45`, selectedAddressData.time_zone) ?? '')

      this.setState({
        page: 1
      });


      const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
      if (e.target?.id !== 'searchRestaurant') {
        this.setState({
          startDate: dateStartBuffet,
          deliveryAt: deliveryAt,
          page: 1
        })
        this.props.history.push(`/menu/${dateForMarketplace.format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}`)
      }
    }

    //@ts-ignore
    this.setState({ [e.target.name]: value });

    // Reset your APIs by setting appropriate states to initial values
    if (e.target.name === 'mealTypeCheck') {
      this.setState({
        mealType: value === true ? 'buffet' : 'individual',
        mealTypeForMenuCheckAPI: this.state.mealType === 'individual' ? 'buffet' : 'individual',
        page: 1, // Reset page to 1
      });

      if (this.state.selectedCompanyAddressID) {
        this.getMarketPlaceData(
          this.state.searchKeywords,
          this.state.selectedCompanyAddressID,
          this.state.meal_type,
          value === true ? 'buffet' : 'individual',
          this.state.deliveryAt,
          [],
          1, // Reset page to 1
        );
      } else {
        const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}');
        if (localStorageAddressData) {
          this.getPublicMarketPlaceData(
            this.state.searchKeywords,
            localStorageAddressData.latitude,
            localStorageAddressData.longitude,
            localStorageAddressData.address_line,
            localStorageAddressData.time_zone,
            'lunch',
            value === true ? 'buffet' : 'individual',
            this.state.deliveryAt,
            localStorageAddressData.zip,
            [],
            1, // Reset page to 1
          );
        }
      }
    }
  };

  searchMoreRestaurant = () => {
    this.setState({ viewRestaurantMenu: false })
  }
  selectRestaurantURLDirect = (restaurant: any, marketPlaceDate: any, addressid: any, queryFilters: any) => {
    // localStorage.removeItem('combo_selected')
    const restaurantId = [restaurant?.id]
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: false
    })
    store.dispatch({
      type: CART_ADDRESS_IDS,
      payload: restaurantId
    });

    store.dispatch({
      type: CART_DISPLAY_CHECK,
      payload: true
    });

    this.handleScrollHash()

    if (this.state.isMoving) {
      // e.stopPropagation();
      return;
    }
    //@ts-ignore
    if (true) {
      let mealTypeNumber = 1;
      if (this.state.meal_type === 'breakfast') {
        mealTypeNumber = 3;
      } else if (this.state.meal_type === 'dinner') {
        mealTypeNumber = 2;
      }
      if (this.state.mealTypeCheck) {
        mealTypeNumber = 4;
      }
      this.setState({
        viewRestaurantMenu: true,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        selectedRestaurants: [restaurant.id],
        restaurantId: restaurant.id,
        mealTypeNumber: mealTypeNumber
      });
      store.dispatch({
        type: CART_DISPLAY_CHECK,
        payload: true
      });
      //Hide cart on header
      this.props?.hideCartOnHeader?.()
      window.scroll(0, 0)
      // this.props.getCuisine();
      let buffetIndividualCheck = this.state.mealTypeCheck === true ? 'buffet' : 'individual'
      // this.props.getMenusOfRestaurant(restaurant.id, mealTypeNumber, buffetIndividualCheck);
      if (false) {
        // Effecting the flow temp stopped the call
        this.props.getMenusOfRestaurant(restaurant.id, mealTypeNumber, buffetIndividualCheck, false, [], queryFilters?.dietries, queryFilters?.ingredients, true);
      }
      // const addressPlaceid = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      const updatedRestaurantName = restaurant?.name?.replaceAll(' ', '_')
      
      const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')

      !isEmpty(localStorageAddressPlaceId) && this.props.history.push({ pathname: `/restaurant/${marketPlaceDate}/${localStorageAddressPlaceId?.placeId}/${updatedRestaurantName}/${restaurant.id}` })

    }
  }



  selectRestaurant = (restaurant: MarketplaceRestaurant, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // localStorage.removeItem('combo_selected')
    sessionStorage.removeItem('dish_section')


    this.setState(
      {
        searchKeywords: '',
        selectedTypehead: [],
        marketplaceCuisines: [],
        marketPlaceDietries: [],
        marketplaceIngredients: [],
        marketplaceFilters: [],
        isFilterApply: false,
        meal_type: 'lunch',
        // mealType: 'individual',
        page: 1,
        sortBy: '',
        mealTypeNumber: 1,
        // mealTypeCheck: false,
        selectedRestaurants: [],
        cartDeliveryDetails: {},
        addressPopUpCloseCheck: false,
        restaurantHeaderCheck: null,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        // mealTypeForMenuCheckAPI: 'individual',
        mealTypeForMenuCheck: false,
        buffetToggleValue: false,
        typeheadCheck: false,
      })
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('search');
    params.delete('filter-cuisine')
    params.delete('filter-dietary')
    params.delete('filter-ingredient')

    url.search = params?.toString();
    window.history.replaceState({}, '', url?.toString());
    
    const restaurantId = [restaurant?.id]
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: false
    })
    store.dispatch({
      type: CART_ADDRESS_IDS,
      payload: restaurantId
    });

    store.dispatch({
      type: CART_DISPLAY_CHECK,
      payload: true
    });
    this.handleScrollHash()


    if (this.state.isMoving) {
      e.stopPropagation();
      return;
    }
    //@ts-ignore
    if (e.target.value === undefined) {
      let mealTypeNumber = 1;
      if (this.state.meal_type === 'breakfast') {
        mealTypeNumber = 3;
      } else if (this.state.meal_type === 'dinner') {
        mealTypeNumber = 2;
      }
      if (this.state.mealTypeCheck) {
        mealTypeNumber = 4;
      }
      this.setState({
        viewRestaurantMenu: true,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        selectedRestaurants: [restaurant.id],
        restaurantId: restaurant.id,
        mealTypeNumber: mealTypeNumber
      });
      store.dispatch({
        type: CART_DISPLAY_CHECK,
        payload: true
      });
      //Hide cart on header
      this.props?.hideCartOnHeader?.()
      window.scroll(0, 0)
      // this.props.getCuisine();
      // let buffetIndividualCheck = this.state.mealTypeCheck === true ? 'buffet' : 'individual'
      let buffetIndividualToggleState = this.state.mealTypeForMenuCheckAPI
      this.props.getMenusOfRestaurant(restaurant.id, mealTypeNumber, buffetIndividualToggleState);
      // const addressPlaceid = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      // const urldata = window.location.pathname
      // const [, , , addressplaceid,] = urldata?.split('/')
      // const { selectedDate } = this.props
      const updatedRestaurantName = restaurant?.name?.replaceAll(' ', '_')
      if (this.props?.fromPublicMarketPlace) {
      
        const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
        const dateForRestaurantPage = dayjs(this.state?.startDate)
        !isEmpty(localStorageAddressPlaceId) && !isEmpty(dateForRestaurantPage) && this.props.history.push({ pathname: `/restaurant/${dateForRestaurantPage?.format('M-D-YYYY')}/${localStorageAddressPlaceId?.placeId}/${updatedRestaurantName}/${restaurant.id}` })
      }
      //  window.location.replace(`/restaurant/9-15-2023/${addressPlaceid}/${restaurantId}`)
    }
  }
  selectPopularDish = (dishes: MarketplaceDishes, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // localStorage.removeItem('combo_selected')
    sessionStorage.setItem('dish_section', JSON.stringify(dishes?.sections))
    const restaurantId = [dishes?.restaurant_address_id]
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: false
    })
    store.dispatch({
      type: CART_ADDRESS_IDS,
      payload: restaurantId
    });

    store.dispatch({
      type: CART_DISPLAY_CHECK,
      payload: true
    });
    this.handleScrollHash()


    if (this.state.isMoving) {
      e.stopPropagation();
      return;
    }
    //@ts-ignore
    if (e.target.value === undefined) {
      let mealTypeNumber = 1;
      if (this.state.meal_type === 'breakfast') {
        mealTypeNumber = 3;
      } else if (this.state.meal_type === 'dinner') {
        mealTypeNumber = 2;
      }
      if (this.state.mealTypeCheck) {
        mealTypeNumber = 4;
      }
      this.setState({
        viewRestaurantMenu: true,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        selectedRestaurants: [dishes.restaurant_address_id],
        restaurantId: dishes.restaurant_address_id,
        mealTypeNumber: mealTypeNumber
      });
      store.dispatch({
        type: CART_DISPLAY_CHECK,
        payload: true
      });
      //Hide cart on header
      this.props?.hideCartOnHeader?.()
      window.scroll(0, 0)
      // this.props.getCuisine();
      let buffetIndividualCheck = this.state.mealTypeCheck === true ? 'buffet' : 'individual'
      this.props.getMenusOfRestaurant(dishes.restaurant_address_id, mealTypeNumber, buffetIndividualCheck);
      // const addressPlaceid = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
      
      // const urldata = window.location.pathname
      // const [, , marketPlaceDate, ,] = urldata?.split('/')
      const updatedRestaurantName = dishes?.restaurant_name?.replaceAll(' ', '_')
      if (this.props?.fromPublicMarketPlace) {
  
        const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
        const dateForRestaurantPage = dayjs(this.state?.startDate)
        !isEmpty(localStorageAddressPlaceId) && !isEmpty(dateForRestaurantPage) && this.props.history.push({ pathname: `/restaurant/${dateForRestaurantPage?.format('M-D-YYYY')}/${localStorageAddressPlaceId?.placeId}/${updatedRestaurantName}/${dishes.restaurant_address_id}` })
      }
      //  window.location.replace(`/restaurant/9-15-2023/${addressPlaceid}/${restaurantId}`)
    }
  }
  truncateString = (str: string, maxLen: number, separator: string = ' ') => {
    if (str?.length <= maxLen) return str;
    return str?.substr(0, str?.lastIndexOf(separator, maxLen)) + '...';
  }
  onClickAddressOption = () => {
    this.openModal();
  }
  // Search from frontEnd
  // closeSearch = (searchName: string) => {
  //   let selectedTypehead: Array<string> = [];
  //   this.state.selectedTypehead.forEach((search: string) => {
  //     if (searchName !== search) {
  //       selectedTypehead.push(search);
  //     }
  //   });
  //   let searchKeywords = '';
  //   selectedTypehead.forEach((unique: string) => {
  //     searchKeywords = searchKeywords + ' ' + unique;
  //   });
  //   this.setState({ selectedTypehead: selectedTypehead, searchKeywords: searchKeywords });
  //   if (this.state.selectedCompanyAddressID){
  //     this.props.getRestaurants(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt);
  //   } else{
  //     this.props?.fromPublicMarketPlace &&  this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(searchKeywords, this.localAddress?.latitude, this.localAddress?.longitude, this.localAddress?.timezone, 'lunch', 'individual', this.state.deliveryAt,this.localAddress?.zipCode )
  //   }
  // }

  // Search from BackEnd
  closeSearch = (searchName: string) => {
    const newPage = 1; // Set the page number to 1
    this.setState({ typeheadCheck: false })
    let selectedTypehead: Array<string> = [];
    let localCuisinesArray = this.state?.marketplaceCuisines
    let localDietArray = this.state?.marketPlaceDietries
    let localIngredientsArray = this.state?.marketplaceIngredients

    const findSearchedFilter = (filterPillData: any) => {
      return filterPillData?.name?.trim()?.toLowerCase() === searchName?.trim()?.toLowerCase()
    }
    const filterIsAppliedOnCuisines = localCuisinesArray?.find((pillData: any) => {
      return pillData?.name?.trim()?.toLowerCase() === searchName?.trim()?.toLowerCase()
    })

    if (filterIsAppliedOnCuisines) {
      const index = localCuisinesArray?.findIndex(findSearchedFilter)
      if (index > -1) { // only splice array when item is found
        localCuisinesArray?.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    const filterIsAppliedOnDietries = localDietArray?.find((pillData: any) => {
      return pillData?.name?.trim()?.toLowerCase() === searchName?.trim()?.toLowerCase()
    })
    if (filterIsAppliedOnDietries) {
      const index = localDietArray?.findIndex(findSearchedFilter)
      if (index > -1) {
        localDietArray?.splice(index, 1);
      }
    }
    const filterIsAppliedOnIngredients = localIngredientsArray?.find((pillData: any) => {
      return pillData?.name?.trim()?.toLowerCase() === searchName?.trim()?.toLowerCase()
    })
    if (filterIsAppliedOnIngredients) {
      const index = localIngredientsArray?.findIndex(findSearchedFilter)
      if (index > -1) {
        localIngredientsArray?.splice(index, 1);
      }
    }

    let filters = {
      localDietArray: localDietArray?.map((filter: any) => { return filter?.name }),
      localCuisinesArray: localCuisinesArray?.map((filter: any) => { return filter?.name }),
      localIngredientsArray: localIngredientsArray?.map((filter: any) => { return filter?.name })
    }
    let LocalStatePillFilters = {
      localDietArray,
      localCuisinesArray,
      localIngredientsArray
    }

    this.state.selectedTypehead.forEach((search: string) => {
      if (searchName !== search) {
        selectedTypehead.push(search);
      }
    });
    let searchKeywords = '';
    selectedTypehead.forEach((unique: string) => {
      searchKeywords = searchKeywords + ' ' + unique;
    });
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('search')
    const searchedKeyWords: any = (searchKeywords && searchKeywords?.split(' ')) ?? []
    
    searchedKeyWords && searchedKeyWords?.forEach((word: any, indx: any) => {
      if (!isEmpty(word)) {
        params.append('search', `${word}`)
      }
    });

    url.search = params?.toString();
    window.history.replaceState({}, '', url?.toString());

    this.props.setState({ selectedTypeheadPill: selectedTypehead });
    this.setState({ selectedTypehead: selectedTypehead, searchKeywords: searchKeywords, marketplaceCuisines: LocalStatePillFilters?.localCuisinesArray, marketPlaceDietries: LocalStatePillFilters?.localDietArray, marketplaceIngredients: LocalStatePillFilters?.localIngredientsArray, page: newPage });
    if (this.state.selectedCompanyAddressID) {
      // this.props.getRestaurants(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, filters, this.state.price, this.state.avg_rating, newPage);
      this.getMarketPlaceData(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, filters, newPage)

    } else {
      const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
      // this.props?.fromPublicMarketPlace && this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, filters, this.state.price, this.state.avg_rating, newPage)
      this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData(searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, filters, newPage)

    }
  };

  keyPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    let document: any = window.document;
    if (event.key === 'Enter') {
      document.getElementById('searchRestaurantButton').click();
    }
  };

  showRestaurantAndComboMenu = (restaurant_id: number) => {
    // localStorage.removeItem('combo_selected')
    const restaurantId = [restaurant_id]
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: false
    })
    store.dispatch({
      type: CART_ADDRESS_IDS,
      payload: restaurantId
    });

    store.dispatch({
      type: CART_DISPLAY_CHECK,
      payload: true
    });
    if (this.state.isMoving) {
      // e.stopPropagation();
      return;
    }
    //@ts-ignore
    if (true) {
      let mealTypeNumber = 1;
      if (this.state?.meal_type === 'breakfast') {
        mealTypeNumber = 3;
      } else if (this.state?.meal_type === 'dinner') {
        mealTypeNumber = 2;
      }
      if (this.state?.mealTypeCheck) {
        mealTypeNumber = 4;
      }
      this.setState({
        viewRestaurantMenu: true,
        showAllMostPopularRestaurants: false,
        selectedRestaurants: [restaurant_id],
        restaurantId: restaurant_id,
        mealTypeNumber: mealTypeNumber
      });
      store.dispatch({
        type: CART_DISPLAY_CHECK,
        payload: true
      });
      //Hide cart on header
      this.props?.hideCartOnHeader?.()
      window.scroll(0, 0)
      // this.props.getCuisine();

      this.props.getMenusOfRestaurant(+restaurantId, 1, 'individual');
    }
  }

  getMarketPlaceData = (keyword: string, address_id: number, menu_type: string, meal_type: string, delivery_at: string, filterArrays: any, page_number?: number, exclude_ids?: any, paginated?: boolean, order_by?: string, order_type?: 'DESC' | 'ASC') => {
    if (page_number === 1) {
      // if (meal_type === 'individual') {
      //   this.props?.getMarketplaceCombos(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type);
      // }
      this.props?.getMarketplaceFavorites(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type);
      this.props.getMostPopularRestaurants(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type);
      this.props.getTrendingDishes(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type);
      // this.props.getNewRestaurantsNearYou(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type)
      // this.props.getRestaurants(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, page_number, paginated, exclude_ids, order_by, order_type);
      this.props.getMarketPlaceFilters(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type)
    } else if (page_number && page_number >= 2) {
      const mostPopularIds = this.props.eSearch?.most_popular?.map(item => item.id) || [];
      const nearRestaurantIds = this.props?.eSearch?.new_restaurants_near_you?.map(item => item.id) || [];
      const concateExcludeIds = [...mostPopularIds, ...nearRestaurantIds]; // Concatenate the IDs
      const idsToBeExcludedMarketplaceRestaurants = concateExcludeIds?.reduce((unique: any, item: any) =>
        unique?.includes(item) ? unique : [...unique, item]
        , []);
      this.props.getRestaurants(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, page_number, idsToBeExcludedMarketplaceRestaurants, paginated, order_by, order_type);
    }
  }
  getPublicMarketPlaceData = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type: string, meal_type: string, delivery_at: string, zip_code: number | null, filters: any, page_number?: number, paginated?: boolean, exclude_ids?: any, order_by?: string, order_type?: 'DESC' | 'ASC') => {
    const checkForTimeZone = time_zone ?? "America/Los_Angeles"
    if (page_number && page_number === 1) {
      // if (meal_type === 'individual') {
      //   this.props?.getPublicMarketplaceCombos(keyword, lat, long, address_line, checkForTimeZone, menu_type, meal_type, delivery_at, zip_code, filters, order_by, order_type)
      // }
      this.props.getPublicMostPopularRestaurants(keyword, lat, long, address_line, checkForTimeZone, menu_type, meal_type, delivery_at, zip_code, filters, order_by, order_type)
      this.props.getPublicTrendingDishes(keyword, lat, long, address_line, checkForTimeZone, menu_type, meal_type, delivery_at, zip_code, filters, order_by, order_type)
      //this.props.getPublicNewRestaurantsNearYou(keyword, lat, long, address_line, time_zone, menu_type, meal_type, delivery_at, zip_code, filters, order_by, order_type)
      // this.props.getPublicRestaurants(keyword, lat, long, address_line, time_zone, menu_type, meal_type, delivery_at, zip_code, filters, page_number, paginated,exclude_ids, order_by, order_type)
      this.props.getPublicMarketPlaceFilters(keyword, lat, long, address_line, checkForTimeZone, menu_type, meal_type, delivery_at, zip_code, filters, order_by, order_type)

    } else if (page_number && page_number >= 2) {
      const mostPopularIds = this.props.eSearch?.most_popular?.map(item => item.id) || [];
      const nearRestaurantIds = this.props?.eSearch?.new_restaurants_near_you?.map(item => item.id) || [];
      const concateExcludeIds = [...mostPopularIds, ...nearRestaurantIds]; // Concatenate the IDs
      const idsToBeExcludedPublicMarketplaceRestaurants = concateExcludeIds?.reduce((unique: any, item: any) =>
        unique?.includes(item) ? unique : [...unique, item]
        , []);
      this.props.getPublicRestaurants(keyword, lat, long, address_line, checkForTimeZone, menu_type, meal_type, delivery_at, zip_code, filters, page_number, idsToBeExcludedPublicMarketplaceRestaurants, paginated, order_by, order_type)
    }
  }
  searchRestaurants = (shouldClear: boolean) => {
    const newPage = 1; // Set the page number to 1
    this.setState({ typeheadCheck: true })
    let document: any = window.document;
    // let selectedTypehead = this.state.selectedTypehead;
    // this.state.searchRestaurant.split(' ').forEach((selectedType: string) => {
    //   selectedTypehead.push(selectedType)
    // });
    let selectedTypehead = [...this.state.selectedTypehead];
    selectedTypehead.push(this.state.searchRestaurant);
    let uniqueTypehead = [...new Set(selectedTypehead)];
    let searchKeywords = '';
    uniqueTypehead.forEach(unique => {
      searchKeywords = searchKeywords + ' ' + unique;
    });

    let searchMatching = { 0: [], 1: [], 2: [] }
    let foundFilters: string[] = []
    const allFiltersDataForMatching = [this.state?.restaurants?.cuisines, this.state?.restaurants?.dietaries, this.state?.restaurants?.ingredients]
    allFiltersDataForMatching?.forEach((filtersArray, idx) => {
      filtersArray?.forEach((filter) => {
        uniqueTypehead?.forEach((singleSearch) => {
          if (singleSearch?.trim()?.toLowerCase() === filter?.name?.trim()?.toLowerCase()) {
            foundFilters.push(singleSearch)
            searchMatching = { ...searchMatching, [idx]: [filter] }
          }
        })
      })
    })
    if (shouldClear) {
      searchKeywords = '';
      uniqueTypehead = [];
    }
    let localSearched = searchKeywords?.split(' ')
    const searchedWords = searchKeywords?.split(' ')
    let searchApiPayloadSearchWords = ''
    searchedWords?.forEach((word, idx) => {
      foundFilters?.forEach((foundWord) => {
        if (word?.trim()?.toLowerCase() === foundWord?.trim()?.toLowerCase()) {
          if (idx > -1) {
            localSearched?.splice(idx, 1);
          }
        }
      })
    })
    // let uniqueTypehead = [...new Set(selectedTypehead)];
    // let searchKeywords = '';
    localSearched.forEach(unique => {
      searchApiPayloadSearchWords = searchApiPayloadSearchWords + ' ' + unique;
    });
    this.props.setState({ selectedTypeheadPill: uniqueTypehead });
    this.setState({ selectedTypehead: uniqueTypehead, searchKeywords: searchKeywords })
    // document?.getElementById('searchRestaurant')?.value = '';
    const searchInput = document?.getElementById('searchRestaurant');
    if (searchInput) {
      searchInput.value = '';
    }
    // optimized filters old version
    // let filtersData = {
    //   localCuisinesArray: searchMatching?.[0]?.concat(this.state?.marketplaceCuisines)?.filter((value, index, array) => array.indexOf(value) === index),
    //   localDietArray: searchMatching?.[1]?.concat(this.state?.marketPlaceDietries)?.filter((value, index, array) => array.indexOf(value) === index),
    //   localIngredientsArray: searchMatching?.[2]?.concat(this.state?.marketplaceIngredients)?.filter((value, index, array) => array.indexOf(value) === index)
    // }
    // localCuisinesArray: searchMatching?.[0]?.concat(this.state?.marketplaceCuisines)?.filter((value, index, array) => array.indexOf(value) === index)?.map((filter: any) => { return filter?.id }),
    // optimized filters new version
    let filters = {
      localCuisinesArray: [...new Set(searchMatching?.[0]?.concat(this.state?.marketplaceCuisines)?.filter((value, index, array) => array.indexOf(value) === index)?.map((filter: any) => { return filter?.name }))],
      localDietArray: searchMatching?.[1]?.concat(this.state?.marketPlaceDietries)?.filter((value, index, array) => array.indexOf(value) === index)?.map((filter: any) => { return filter?.name }),
      localIngredientsArray: searchMatching?.[2]?.concat(this.state?.marketplaceIngredients)?.filter((value, index, array) => array.indexOf(value) === index)?.map((filter: any) => { return filter?.name })
    }


    const uniqueFilters = {
      localCuisinesArray: [...new Set(filters?.localCuisinesArray)],
      localDietArray: [...new Set(filters?.localDietArray)],
      localIngredientsArray: [...new Set(filters?.localIngredientsArray)]
    }
    const searchPresentInFilters = Object?.values(filters)?.flat()

    this.setState({ marketplaceCuisines: uniqueFilters?.localCuisinesArray, marketPlaceDietries: uniqueFilters?.localDietArray, marketplaceIngredients: uniqueFilters?.localIngredientsArray, page: newPage })
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')

    if (searchPresentInFilters?.length > 0) {
      if (this.state.selectedCompanyAddressID) {
        // this.props.getRestaurants(searchApiPayloadSearchWords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, uniqueFilters, this.state.price, this.state.avg_rating, newPage);
        this.getMarketPlaceData(searchApiPayloadSearchWords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, uniqueFilters, newPage)

      } else {
        // this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(searchApiPayloadSearchWords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, uniqueFilters, this.state.price, this.state.avg_rating, newPage)
        this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData(searchApiPayloadSearchWords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, uniqueFilters, newPage)

      }
    } else {
      if (this.state.selectedCompanyAddressID) {
        // this.props.getRestaurants(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, uniqueFilters, this.state.price, this.state.avg_rating, newPage);
        this.getMarketPlaceData(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, uniqueFilters, newPage)

      } else {

        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const searchedKeyWords: any = (searchKeywords && searchKeywords?.split(' ')) ?? []
        params.delete('search')

        searchedKeyWords && searchedKeyWords?.forEach((word: any, indx: any) => {
          if (!isEmpty(word)) {
            params.append('search', `${word}`)
          }
        });

        url.search = params?.toString();
        window.history.replaceState({}, '', url?.toString());

        //  this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, uniqueFilters, this.state.price, this.state.avg_rating, newPage)
        this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData(searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, uniqueFilters, newPage)

      }
    }


    // if (this.state.selectedCompanyAddressID){
    //   this.props.getRestaurants(searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt,filters);
    // }else{
    //   this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(searchKeywords, this.localAddress?.latitude, this.localAddress?.longitude, this.localAddress?.timezone, 'lunch', 'individual', this.state.deliveryAt,this.localAddress?.zipCode )
    // }
  };

  applyFilter = () => {
    // this.props.getRestaurants(this.state.searchKeywords, this.state.selectedCompanyAddress, this.state.meal_type, this.state.mealType, this.state.deliveryAt);
  }

  capitlizeString = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  onUpdatePage = () => {
    this.props.onUpdatePage();
  }

  lessMoreHandler = (id: string, buttonType: string, divId: string, onPopup: boolean) => {
    let document: Document = window.document;
    type loadingBtn = 'currentNumberOfShownCuisines' | 'currentNumberOfShownDietries' | 'currentNumberOfShownIngredients';

    let itemState: loadingBtn | null = null, itemArray: GenericTypeForMarketplaceAPIs[] = [];
    if (id === 'cuisineLoaderBtn') {
      if (document.getElementsByClassName('cusine-fadin-out')[1]) {
        document.getElementsByClassName('cusine-fadin-out')[1].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('cusine-fadin-out')[1].classList.remove('fadeIn') }, 500);
      } else {
        document.getElementsByClassName('cusine-fadin-out')[0].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('cusine-fadin-out')[0].classList.remove('fadeIn') }, 500);
      }
      itemState = 'currentNumberOfShownCuisines';
      itemArray = this.state?.restaurants?.cuisines
    };
    if (id === 'dietaryLoaderBtn') {
      if (document.getElementsByClassName('dietary-fadin-out')[1]) {
        document.getElementsByClassName('dietary-fadin-out')[1].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('dietary-fadin-out')[1].classList.remove('fadeIn') }, 500);
      } else {
        document.getElementsByClassName('dietary-fadin-out')[0].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('dietary-fadin-out')[0].classList.remove('fadeIn') }, 500);
      }
      itemState = 'currentNumberOfShownDietries'; itemArray = this.state?.restaurants?.dietaries
    };
    if (id === 'ingredientLoaderBtn') {
      if (document.getElementsByClassName('ingredient-fadin-out')[1]) {
        document.getElementsByClassName('ingredient-fadin-out')[1].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('ingredient-fadin-out')[1].classList.remove('fadeIn') }, 500);
      } else {
        document.getElementsByClassName('ingredient-fadin-out')[0].classList.add('fadeIn');
        setTimeout(() => { document.getElementsByClassName('ingredient-fadin-out')[0].classList.remove('fadeIn') }, 500);
      }
      itemState = 'currentNumberOfShownIngredients'; itemArray = this.state?.restaurants?.ingredients
    };
    const newDIV = document.createElement('div');
    let length, buttons = '', height;
    buttonType === 'More' ? length = itemArray?.length : length = 5;
    for (let i = 0; i < length; i++) {
      buttons = buttons +
        `<button type='button' class='btn btn-outline-dark btn-cuisine btn-sm m-1' name='ingredients[]'
        value='2' id='autoCuisine0' >${itemArray[i].name}
      </button>`
    }
    newDIV.innerHTML = `${this.state?.restaurants?.ingredients?.length > 0 ? `<h5 class='border-bottom pb-3 mb-3'>Ingredients</h5>` : ''} ${buttons}`
    if (onPopup) {
      //@ts-ignore
      document.getElementById('marketplacePopupBody') && document.getElementById('marketplacePopupBody').appendChild(newDIV)
    } else {
      //@ts-ignore
      document.getElementById('sidebar') && document.getElementById('sidebar').appendChild(newDIV)
    }
    height = newDIV?.clientHeight;
    newDIV?.remove();
    height && $(`#${divId}`).animate({ 'height': height }, 300);
    if (itemState) { //@ts-ignore
      (buttonType === 'More') ? this.setState({ [itemState as loadingBtn]: itemArray.length }) : this.setState({ [itemState]: 5 });
    }
  }
  setSelectedAddress = (fetchedAddress: number | AddressDataType) => {
   
    const dateStringWithoutTimezone = this.state.deliveryAt.split('.')[0];
    const newPage = 1; // Set the page number to 1

    if (fetchedAddress && typeof fetchedAddress !== 'number') {
      // getAddressStringByLatLong(fetchedAddress?.latitude ?? 0, fetchedAddress?.longitude ?? 0)
      // const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
      // const { latitude , longitude } = localAddress
      if (!isEmpty(fetchedAddress)) {
        // const adressPlaceId = getAddressStringByLatLong(fetchedAddress?.latitude ?? 0, fetchedAddress?.longitude ?? 0)

      }

      let deliveryAt
      if (fetchedAddress.time_zone) {
        deliveryAt = getDateWithTimeZone(dateStringWithoutTimezone, fetchedAddress.time_zone);
      } else {
        deliveryAt = getDateWithTimeZone(dateStringWithoutTimezone);
      }

      this.setState({
        localAddressState: fetchedAddress,
        deliveryAt,
        page: newPage, // Update the page number here
      });


      if (this.props.fromPublicMarketPlace && (fetchedAddress?.formatted_address || fetchedAddress?.address_line)) {

        this.setState(
          {
            searchKeywords: '',
            selectedTypehead: [],
            marketplaceCuisines: [],
            marketPlaceDietries: [],
            marketplaceIngredients: [],
            marketplaceFilters: [],
            isFilterApply: false,
            meal_type: 'lunch',
            mealType: 'individual',
            page: 1,
            sortBy: '',
            mealTypeNumber: 1,
            mealTypeCheck: false,
            selectedRestaurants: [],
            cartDeliveryDetails: {},
            addressPopUpCloseCheck: false,
            restaurantHeaderCheck: null,
            showAllMostPopularRestaurants: false,
            showAllNewRestaurantsNearYou: false,
            mealTypeForMenuCheckAPI: 'individual',
            mealTypeForMenuCheck: false,
            buffetToggleValue: false,
            typeheadCheck: false,
          })
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete('search');
        params.delete('filter-cuisine')
        params.delete('filter-dietary')
        params.delete('filter-ingredient')
        url.search = params?.toString();
        window.history.replaceState({}, '', url?.toString());
        isEmpty(fetchedAddress) && modifyAddressInUrl(fetchedAddress)
      }
      fetchedAddress?.latitude && fetchedAddress?.longitude && getAddressStringByLatLong(fetchedAddress?.latitude, fetchedAddress?.longitude)

      // this.props.getPublicRestaurants('', fetchedAddress.latitude, fetchedAddress.longitude, fetchedAddress?.address_line, fetchedAddress?.time_zone, 'lunch', 'individual', deliveryAt, fetchedAddress?.zip, [], this.state.price, this.state.avg_rating, newPage, false, this.state.sortBy);
      this.getPublicMarketPlaceData('', fetchedAddress?.latitude, fetchedAddress?.longitude, fetchedAddress?.address_line, fetchedAddress?.time_zone, 'lunch', 'individual', deliveryAt, fetchedAddress?.zip, [], newPage, false, this.state.sortBy)

    } else {
      const selectedAddressDetail = this.props?.addresses.comp_addresses?.addresses?.find((address) => address?.id === fetchedAddress);
      let deliveryAt = getDateWithTimeZone(dateStringWithoutTimezone, selectedAddressDetail?.time_zone);
      this.setState({
        selectedCompanyAddressID: fetchedAddress,
        selectedAddressDetail,
        deliveryAt,
        page: newPage, // Update the page number here
      });
      // this.props.getRestaurants('', fetchedAddress, this.state.meal_type, this.state.mealType, deliveryAt, [], this.state.price, this.state.avg_rating, newPage, false, this.state.sortBy);
      this.getMarketPlaceData('', fetchedAddress, this.state.meal_type, 'individual', deliveryAt, [], newPage, [], false, this.state.sortBy)

    }
    // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
    // this.props.history.push({ pathname: `/home/9-23-2023/${isEmpty(localStorageAddressPlaceId?.placeId) ? 'mnop': localStorageAddressPlaceId?.placeId}` })

  }
  onFilter = (sortBy: string) => {
    this.setState({ sortingLoaderLocal: true })
    localStorage.setItem('sortingMarketPlace', JSON.stringify(sortBy))
    const { new_restaurants_near_you, most_popular, restaurants, marketplaceFavorites } = this.props.eSearch
    //  const trending_dish = trending_dishes && this.marketPlaceFiltersSorting(trending_dishes, sortBy)

    const marketplace_favorite = marketplaceFavorites && this.marketPlaceFiltersSorting(marketplaceFavorites, sortBy)
    const marketplace_popular_restaurant = most_popular && this.marketPlaceFiltersSorting(most_popular, sortBy)
    const marketplace_new_restaurants = new_restaurants_near_you && this.marketPlaceFiltersSorting(new_restaurants_near_you, sortBy)
    const marketplace_all_restaurants = restaurants && this.marketPlaceFiltersSorting(restaurants?.restaurants, sortBy)
    //  const trending_dishes =  this.marketPlaceFiltersSorting(this.props.eSearch.trending_dishes, sortBy)


    const marketPlaceData: any = {
      marketplace_favorite,
      marketplace_popular_restaurant,
      marketplace_new_restaurants,
      marketplace_all_restaurants,
    }
    setTimeout(() => {
      this.setState({ sortingLoaderLocal: false })
    }, 300);
    this.setState({ sortedMarketplace: marketPlaceData })

  }

  
  // onFilter = (sortBy: string) => {
  //   const { restaurantSortData: { order_by, order_type } } = this.state;
  //   const newSortData = { order_by, order_type }
  //   let sortedRestaurants = this.state.restaurants.restaurants;
  //   let rest = this.state.restaurants;
  //   if (sortBy === 'price') {
  //     newSortData.order_by = 'price'
  //     if (order_type === 'ASC') {
  //       newSortData.order_type = 'DESC'
  //       sortedRestaurants = sortedRestaurants.sort((a, b) => (a.avg_price > b.avg_price) ? 1 : -1);
  //     } else {
  //       newSortData.order_type = 'ASC'
  //       sortedRestaurants = sortedRestaurants.sort((a, b) => (a.avg_price < b.avg_price) ? 1 : -1);
  //     }
  //   } else {
  //     newSortData.order_by = 'rating'
  //     if (order_type === 'DESC') {
  //       newSortData.order_type = 'ASC'
  //       sortedRestaurants = sortedRestaurants.sort((a, b) => (a.average_rating < b.average_rating) ? 1 : -1);
  //     } else {
  //       newSortData.order_type = 'DESC'
  //       sortedRestaurants = sortedRestaurants.sort((a, b) => (a.average_rating > b.average_rating) ? 1 : -1);
  //     }
  //   }
  //   rest.restaurants = sortedRestaurants
  //   this.setState({ sortBy: sortBy, restaurants: rest, restaurantSortData: newSortData });
  // }
  onChangeMealType = (meal_type: string) => {
    this.setState({ meal_type: meal_type });
    if (this.state.selectedCompanyAddressID) {
      // this.props.getRestaurants(this.state.searchKeywords, this.state.selectedCompanyAddressID, meal_type, this.state.mealType, this.state.deliveryAt, [], this.state.price, this.state.avg_rating,this.state.page);
      this.getMarketPlaceData(this.state.searchKeywords, this.state.selectedCompanyAddressID, meal_type, this.state.mealType, this.state.deliveryAt, [], this.state.page)
    }

  }
  marketPlaceFiltersSorting = (arrayToBeSorted: MarketplaceRestaurant[], sortBy: string) => {
    let sortedRestaurants = arrayToBeSorted
    // let arrayToSort = arrayForSorting

    // let rest = this.state.restaurants;
    if (sortBy === 'distance') {
      sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.distance ?? 0) > (b?.distance ?? 0)) ? 1 : -1) ?? [];
    } else if (sortBy === 'price') {
      sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.avg_price ?? 0) > (b?.avg_price ?? 0)) ? 1 : -1) ?? [];
    } else if (sortBy === 'rating') {
      sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.weighted_rating ?? 0) < (b?.weighted_rating ?? 0)) ? 1 : -1) ?? [];
    } else {
      sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.weighted_rating ?? 0) < (b?.weighted_rating ?? 0)) ? 1 : -1) ?? [];
    }

    return sortedRestaurants

  }
  keyExistsinRestaurant = (restaurant: MarketplaceRestaurant) => {
    let shouldRestaurantDisplay = true;
    let selectedDietries = this.state.restaurants.dietaries.filter((diet) => (diet.selected === true));
    let selectedCuisines = this.state.restaurants.cuisines.filter((cuisine) => (cuisine.selected === true));
    let selectedIngredients = this.state.restaurants.ingredients.filter((ingredient) => (ingredient.selected === true));
    if (selectedDietries.length === 0 && selectedCuisines.length === 0 && selectedIngredients.length === 0) {
      shouldRestaurantDisplay = true;
    } else {
      selectedDietries.forEach((selectedDietry) => {
        if (restaurant.dietaries.filter((restaurantDietry) => (restaurantDietry.id === selectedDietry.id)).length > 0) {
        } else {
          shouldRestaurantDisplay = false;
        }
      });
      selectedIngredients.forEach((selectedIngredient) => {
        if (restaurant.ingredients.filter((restaurantIng) => (restaurantIng.id === selectedIngredient.id)).length > 0) {
        } else {
          shouldRestaurantDisplay = false;
        }
      });
      selectedCuisines.forEach((selectedCuisine) => {
        if (restaurant.restaurant_cuisines.filter((restaurantCuisine) => (restaurantCuisine.id === selectedCuisine.id)).length > 0) {
        } else {
          shouldRestaurantDisplay = false;
        }
      });
    }
    return shouldRestaurantDisplay;
  }

  keyExistsInCombo = (combo: Combo) => {
    let shouldComboDisplay: any = true
    shouldComboDisplay = combo?.restaurants?.find((restaurant: MarketplaceRestaurant) => this.keyExistsinRestaurant(restaurant))
    return shouldComboDisplay
  }

  creatingNewDelivery = () => {
    this.setState({ marketPlaceNewDeliveryCreation: true })
  }
  closeNewDeliveryModal = () => {
    this.setState({ marketPlaceNewDeliveryCreation: false })

  }

  resetSearchBar = () => {
    this.props.setState({ selectedTypeheadPill: [] })
    this.setState({ selectedTypehead: [], searchKeywords: '', typeheadCheck: false, marketplaceCuisines: [], marketPlaceDietries: [], marketplaceIngredients: [], mealType: 'individual', mealTypeCheck: false })
  }
  resetAllFilters = () => {

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('search');
    params.delete('filter-cuisine')
    params.delete('filter-dietary')
    params.delete('filter-ingredient')

    url.search = params?.toString();
    window.history.replaceState({}, '', url?.toString());

    this.setState(
      {
        searchKeywords: '',
        selectedTypehead: [],
        marketplaceCuisines: [],
        marketPlaceDietries: [],
        marketplaceIngredients: [],
        marketplaceFilters: [],
        isFilterApply: false,
        meal_type: 'lunch',
        mealType: 'individual',
        page: 1,
        sortBy: '',
        mealTypeNumber: 1,
        mealTypeCheck: false,
        selectedRestaurants: [],
        cartDeliveryDetails: {},
        addressPopUpCloseCheck: false,
        restaurantHeaderCheck: null,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        mealTypeForMenuCheckAPI: 'individual',
        mealTypeForMenuCheck: false,
        buffetToggleValue: false,
        typeheadCheck: false,
      },
      () => {
        // This code will execute after the state has been updated and the component has re-rendered
        if (this.state.selectedCompanyAddressID) {
          this.getMarketPlaceData('', this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, [], this.state.page);
        } else {
          const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}');
          this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData('', localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, [], this.state.page);
        }
      }
    );
    this.resetSearchBar();
  }

  marketPlaceFiltersBackendSearch = (selectedPill: any, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean) => {
    let selectedName = selectedPill?.name
    let localMarketPlaceFilters = this.state.marketplaceFilters;
    const findSearchedFilter = (filterPillData: any) => {
      return filterPillData?.name?.trim()?.toLowerCase() === selectedName?.trim()?.toLowerCase()
    }
    if (key === 'cuisines') {
      const filterIsApplied = localMarketPlaceFilters?.find((pill: any) => {
        return pill?.id === selectedPill?.id
      })
      if (filterIsApplied) {
        const index = localMarketPlaceFilters?.findIndex(findSearchedFilter)
        if (index > -1) { // only splice array when item is found
          localMarketPlaceFilters?.splice(index, 1); // 2nd parameter means remove one item only
        }
      } else {
        localMarketPlaceFilters?.push(selectedPill)
        localMarketPlaceFilters = [...new Set(localMarketPlaceFilters)]
      }

    } else if (key === 'dietaries') {
      const filterIsApplied = localMarketPlaceFilters?.find((pill: any) => {
        return pill?.name?.trim()?.toLowerCase() === selectedName?.trim()?.toLowerCase()
      })
      if (filterIsApplied) {
        const index = localMarketPlaceFilters?.findIndex(findSearchedFilter)
        if (index > -1) {
          localMarketPlaceFilters?.splice(index, 1);
        }
      } else {
        localMarketPlaceFilters?.push(selectedPill)
        localMarketPlaceFilters = [...new Set(localMarketPlaceFilters)]
      }
    } else if (key === 'ingredients') {
      const filterIsApplied = localMarketPlaceFilters?.find((pill: any) => {
        return pill?.name?.trim()?.toLowerCase() === selectedName?.trim()?.toLowerCase()
      })
      if (filterIsApplied) {
        // const index = localIngredientsArray.indexOf(selectedName);
        const index = localMarketPlaceFilters?.findIndex(findSearchedFilter)
        if (index > -1) {
          localMarketPlaceFilters?.splice(index, 1);
        }
      } else {
        localMarketPlaceFilters?.push(selectedPill)
        localMarketPlaceFilters = [...new Set(localMarketPlaceFilters)]
      }

    }
    let filters = {
      localDietArray: localMarketPlaceFilters?.filter((filter: any) => filter?.filter_type === 'dietary')?.map((filter: any) => filter?.name),
      localCuisinesArray: localMarketPlaceFilters?.filter((filter: any) => filter?.filter_type === 'cuisine')?.map((filter: any) => filter?.name),
      localIngredientsArray: localMarketPlaceFilters?.filter((filter: any) => filter?.filter_type === 'ingredient')?.map((filter: any) => filter?.name),
    };


    if (!isEmpty(filters) && this.props?.fromPublicMarketPlace) {

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete('filter-cuisine')
      params.delete('filter-dietary')
      params.delete('filter-ingredient')

      if (filters?.localCuisinesArray?.length >= 1) {
        filters?.localCuisinesArray?.forEach((filter: any) => {
          params.append('filter-cuisine', `${filter}`)
        })
      }
      if (filters?.localDietArray?.length >= 1) {
        filters?.localDietArray?.forEach((filter: any) => {
          params.append('filter-dietary', `${filter}`)
        })
      }
      if (filters?.localIngredientsArray?.length >= 1) {
        filters?.localIngredientsArray?.forEach((filter: any) => {
          params.append('filter-ingredient', `${filter}`)
        })
      }

      url.search = params?.toString();
      window.history.replaceState({}, '', url?.toString());
    }

    const pageNumber = 1; // Variable to hold the page number

    if (this.state?.selectedCompanyAddressID) {
      // this.props.getRestaurants(this.state?.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, filters, this.state.price, this.state.avg_rating,this.state.page);
      this.getMarketPlaceData(this.state?.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, filters, pageNumber)
    } else {
      const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}')
      // this.props?.fromPublicMarketPlace && this.props.getPublicRestaurants(this.state?.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, filters, this.state.price, this.state.avg_rating,this.state.page)
      this.props?.fromPublicMarketPlace && this.getPublicMarketPlaceData(this.state?.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, filters, pageNumber)

    }
  }

  selectSearchStatistics = (name: string | null, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean) => {
    this.setState({ isFilterApply: true })
    let restaurants = this.state.restaurants;
    let dietaries: GenericTypeForMarketplaceAPIs[] = [];
    this.state.restaurants[key].forEach((statis) => {

      if (shouldClear) {
        statis.selected = false;
      } else if (statis.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()) {
        if (statis.name?.trim() === 'Gluten-Free' || statis.name?.trim() === 'Nut-Free' || statis.name?.trim() === 'Dairy-Free') {
          // const dietaryName = statis.name.trim().split('-');
          const dietaryName = statis.name?.trim();
          const foundFilterToGroup = this.state.restaurants[key]?.find(element => element.name?.trim() === `${dietaryName} Upon Request`)

          if (foundFilterToGroup) {
            if (statis.selected === true) {
              statis.selected = false;
              foundFilterToGroup.selected = false;
            } else {
              statis.selected = true;
              foundFilterToGroup.selected = true;
            }
          } else {
            statis.selected === true ? statis.selected = false : statis.selected = true;
          }

          // eslint-disable-next-line 
          // this.state.restaurants[key].find(element => {

          //   if(element.name.trim() === `${dietaryName} Upon Request`) {
          //     if (statis.selected === true) {
          //       statis.selected = false;
          //       element.selected = false;
          //     } else {
          //       statis.selected = true;
          //       element.selected = true;
          //     }
          //   }
          //   else {
          //     if (statis.selected === true) {
          //       statis.selected = false;
          //     } else {
          //       statis.selected = true;
          //     }
          //   }
          // })
        } else if (statis.name?.trim() === 'Vegan') {
          // eslint-disable-next-line
          // const foundFilter = this.state.restaurants[key].find(element => {
          //   if(element.name.trim() === 'Vegan-Upon Request') {
          //     if (statis.selected === true) {
          //       statis.selected = false;
          //       element.selected = false;
          //     } else {
          //       statis.selected = true;
          //       element.selected = true;
          //     }
          //   }
          //   else {
          //     if (statis.selected === true) {
          //       statis.selected = false;
          //     } else {
          //       statis.selected = true;
          //     }
          //   }
          // })

          const foundVeganFilterToCombine = this.state.restaurants[key]?.find(element => element.name?.trim() === 'Vegan-Upon Request')
          if (foundVeganFilterToCombine) {
            if (statis.selected === true) {
              statis.selected = false;
              foundVeganFilterToCombine.selected = false;
            } else {
              statis.selected = true;
              foundVeganFilterToCombine.selected = true;
            }
          } else {
            statis.selected === true ? statis.selected = false : statis.selected = true;
          }
          // if (statis.selected === true) {
          //   statis.selected = false;
          //   element.selected = false;
          // } else {
          //   statis.selected = true;
          //   element.selected = true;
          // }

          // else {
          //   if (statis.selected === true) {
          //     statis.selected = false;
          //   } else {
          //     statis.selected = true;
          //   }
          // }
        } else {
          if (statis.selected === true) {
            statis.selected = false;
          } else {
            statis.selected = true;
          }
        }
      }
      dietaries.push(statis);
    });
    restaurants[key] = dietaries;
    this.setState({ restaurants: restaurants });
  }

  // selectSearchStatistics = (id: number | null, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean) => {
  //   let restaurants = this.state.restaurants;
  //   let dietaries: GenericTypeForMarketplaceAPIs[] = [];
  //   this.state.restaurants[key].forEach((statis) => {
  //     if (shouldClear) {
  //       statis.selected = false;
  //     } else if (statis.name === id) {
  //       if (statis.selected === true) {
  //         statis.selected = false;
  //       } else {
  //         statis.selected = true;
  //       }
  //     }
  //     dietaries.push(statis);
  //   });
  //   restaurants[key] = dietaries;
  //   this.setState({ restaurants: restaurants });
  // }
  heightController = (toggle: any) => {
    this.setState({ heightControl: toggle })
  }
  comboFoodItemTotalCalculator = (combo: any) => {
    return combo?.restaurants?.reduce(
      (accumulatedValue: any, currentValue: any) => accumulatedValue + currentValue.fooditems_count,
      0,
    )
  }
  handleScrollHash = () => {
    store.dispatch({
      type: MARKETPLACE_SECTION_SCROLL,
      payload: ''
    })
    store.dispatch({
      type: MARKETPLACE_SECTION_SCROLL_PERMISSION,
      payload: false
    })
  }

  backToMarketplace = () => {

    this.setState(
      {
        searchKeywords: '',
        selectedTypehead: [],
        marketplaceCuisines: [],
        marketPlaceDietries: [],
        marketplaceIngredients: [],
        marketplaceFilters: [],
        isFilterApply: false,
        meal_type: 'lunch',
        // mealType: 'individual',
        page: 1,
        sortBy: '',
        mealTypeNumber: 1,
        // mealTypeCheck: false,
        selectedRestaurants: [],
        cartDeliveryDetails: {},
        addressPopUpCloseCheck: false,
        restaurantHeaderCheck: null,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        // mealTypeForMenuCheckAPI: 'individual',
        mealTypeForMenuCheck: false,
        buffetToggleValue: false,
        typeheadCheck: false,
      })
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('filters-dietry')
    params.delete('filters-ingredient')
    url.search = params?.toString();
    window.history.replaceState({}, '', url?.toString());

    const localStorageAddressData: AddressDataType = JSON.parse(localStorage.getItem('address') || '{}')
    // const localStorageAddressPlaceId = JSON.parse(localStorage.getItem('address-placeId') || '{}')
    window.scroll(0, 0)
    this.handleScrollHash()
    const newPage = 1; // Set the page number to 1
    // Handle Route for back to marketplace
    // if (this.props?.isFromUrlRestaurant){
    // this.props.history.push({ pathname: `/home/8-24-2023` });
    // }

    // const urldata = window.location.pathname
    // const [, , marketPlaceDate, addressplaceid,] = urldata?.split('/')
    //  this.props.history.push({ pathname: `/restaurant/${marketPlaceDate}/${addressplaceid}/${restaurant.id}` })
    if (this.props?.fromPublicMarketPlace && !this.props?.fromPublicAddressMarketPlace) {
      // const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
      !isEmpty(localStorageAddressData) && modifyAddressInUrl(localStorageAddressData)
      // this.props.history.push({ pathname: `/home/${marketPlaceDate}/${addressplaceid}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}` })
    }
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: true,
      page: newPage
    })
    if (this.props.fromPublicMarketPlace) {
      this.resetAllFilters()
    }
    this.setState({ viewRestaurantMenu: false, showAllMostPopularRestaurants: false, showAllNewRestaurantsNearYou: false, cartDeliveryDetails: {}, page: newPage })
    if (this.state.selectedCompanyAddressID) {
      if (!this.state.typeheadCheck) {
        // to keep the filters intact
        this.getMarketPlaceData(this.state.searchKeywords, this.state.selectedCompanyAddressID, this.state.meal_type, this.state.mealType, this.state.deliveryAt, [], newPage);
      }
      else if (this.state.typeheadCheck) {
        this.searchRestaurants(false)
      }
    }
    else if (this.state.typeheadCheck) {
      this.searchRestaurants(false)
    }
    else if (!isEmpty(localStorageAddressData)) {
      // to keep the filters intact
      this.getPublicMarketPlaceData(this.state.searchKeywords, localStorageAddressData?.latitude, localStorageAddressData?.longitude, localStorageAddressData?.address_line, localStorageAddressData?.time_zone, 'lunch', 'individual', this.state.deliveryAt, localStorageAddressData?.zip, [], newPage)
    }
  }

  getComboomboMenu = (combo: any) => {
  


    this.setState(
      {
        searchKeywords: '',
        selectedTypehead: [],
        marketplaceCuisines: [],
        marketPlaceDietries: [],
        marketplaceIngredients: [],
        marketplaceFilters: [],
        isFilterApply: false,
        meal_type: 'lunch',
        // mealType: 'individual',
        page: 1,
        sortBy: '',
        mealTypeNumber: 1,
        // mealTypeCheck: false,
        selectedRestaurants: [],
        cartDeliveryDetails: {},
        addressPopUpCloseCheck: false,
        restaurantHeaderCheck: null,
        showAllMostPopularRestaurants: false,
        showAllNewRestaurantsNearYou: false,
        // mealTypeForMenuCheckAPI: 'individual',
        mealTypeForMenuCheck: false,
        buffetToggleValue: false,
        typeheadCheck: false,
      })

  
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('search');
    params.delete('filter-cuisine')
    params.delete('filter-dietary')
    params.delete('filter-ingredient')

    url.search = params?.toString();
    window.history.replaceState({}, '', url?.toString());
    sessionStorage.removeItem('dish_section')

    const comboAllRestaurantsIdArray = combo?.restaurants?.map((restaurant: any) => {
      return restaurant?.id
    })

    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: false
    })
    store.dispatch({
      type: CART_ADDRESS_IDS,
      payload: comboAllRestaurantsIdArray
    });

    //This is used to identify the combo selection from marketplace 
    // const marketplaceFiltercombo = {
    //   combo_selected: 'true'
    // }
    // localStorage.setItem('combo_selected', JSON.stringify(marketplaceFiltercombo))
    // const comboAllRestaurantsArray = combo?.restaurants?.map((restaurant: any) => {
    //   return (restaurant?.is_open === true) ? restaurant?.id : ''
    // })
    // const comboOpenRestaurants: number[] = comboAllRestaurantsArray?.filter((id: any) => {
    //   return id
    // })
    this.setState({
      viewRestaurantMenu: true,
      selectedRestaurants: this.props?.activeRestaurantIds
    });
    store.dispatch({
      type: CART_DISPLAY_CHECK,
      payload: true
    });

    this.handleScrollHash()

    // const disabledRestaurants = combo?.restaurants?.map((restaurant: any) => {
    //   return (restaurant?.is_open === false) ? restaurant?.id : ''
    // })
    // const filteredComboClosedRestaurants: number[] = disabledRestaurants?.filter((id: any) => {
    //   return id
    // })
    // Changed default scroll position to top of page for market place combo Menu
    window.scroll(0, 0);
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    // this.props.getMenusOfRestaurantCombos(comboAllRestaurantsIdArray, this.state.mealType)
  }
  goToComboMenu = (combo: any) => {
    const comboStatus = combo?.restaurants?.every((value: any) => value?.is_open === false)
    return comboStatus
  }
  restaurantAvailablity = (restaurants: any) => {
    // const filterIsAvalaible = this.state?.restaurants?.restaurants?.map((restaurant) => {
    //   return restaurant?.is_open === true && this.keyExistsinRestaurant(restaurant)
    // })
    const restaurantStatus = restaurants?.every((value: any) => value?.is_open === false)
    return restaurantStatus
  }
  restaurantFilterAvailable = () => {
    const filterIsAvalaible = this.state?.restaurants?.restaurants?.map((restaurant) => {
      return restaurant?.is_open === true
    })
    const restaurantIsAvailable = filterIsAvalaible?.find(restaurant => restaurant)
    return restaurantIsAvailable
  }

  restaurantAvailabilityOnFilterPills = () => {
    const filterIsAvalaible = this.state?.restaurants?.restaurants?.map((restaurant) => {
      return restaurant?.is_open === true
    })
    let restaurantIsAvailable: boolean | undefined = false
    restaurantIsAvailable = filterIsAvalaible?.find(bit => bit)
    return restaurantIsAvailable
  }


  continueOrdering = () => {
    if (this.user.phone_number) {
      const { cart_server_data } = this.props
      this.props.history.push({ pathname: `/menu/${dayjs(cart_server_data?.delivery_at).format('M-D-YYYY')}/${cart_server_data?.slug}` });
      store.dispatch({
        type: CART_DISPLAY_CHECK,
        payload: true
      });
    } else if (this.props.fromPublicMarketPlace) {
      const { address_ids, cart_local_data, addresses: { comp_addresses } } = this.props;
      if (address_ids?.length > 0) {
        let mealTypeNumber = 1;
        if (this.state.meal_type === 'breakfast') {
          mealTypeNumber = 3;
        } else if (this.state.meal_type === 'dinner') {
          mealTypeNumber = 2;
        }
        if (this.state.mealTypeCheck) {
          mealTypeNumber = 4;
        }
        if (address_ids?.length === 1) {
          this.props.getMenusOfRestaurant(address_ids[0], mealTypeNumber, this.state.mealType);
        } else if (address_ids?.length > 1) {
          // this.props.getMenusOfRestaurantCombos(address_ids, this.state.mealType)
        }
        const selectedAddress = comp_addresses?.addresses?.find((address) => address?.id === cart_local_data.runningmenu.address_id);
        this.setState({
          viewRestaurantMenu: true,
          selectedRestaurants: address_ids,
          restaurantId: address_ids[0],
          mealTypeNumber: mealTypeNumber,
          cartDeliveryDetails: {
            admin_cutoff_at: cart_local_data?.runningmenu?.delivery_at,
            delivery_at: cart_local_data?.runningmenu?.delivery_at,
            clone_orders: null,
            delivery_instructions: '',
            formatted_address: cart_local_data?.company?.addresses_active_attributes[0]?.formatted_address,
            runningmenu_name: cart_local_data?.runningmenu?.runningmenu_name,
            runningmenu_type: 'lunch',
            time_zone: !isEmpty(cart_local_data?.company) ? cart_local_data.company?.addresses_active_attributes[0].time_zone :
              selectedAddress?.time_zone

          }
        });

        store.dispatch({
          type: CART_DISPLAY_CHECK,
          payload: true
        });
      }
    }
    window.scroll(0, 0);
  }
  comboCheck = () => {
    const comboCheckEmpty = this.state?.restaurants?.combos?.map((combo) => {
      return this.keyExistsInCombo(combo)
    })
    let comboIsAvailable: boolean | undefined = false
    comboIsAvailable = comboCheckEmpty?.find(bit => bit)
    return comboIsAvailable
  }

  handleScroll = () => {
    let top, scrollChange;
    const divElement = this.stickyDivRef.current;
    const stickyMarketPlaceFilter = this.restaurantHeaderCheck.current;
    if (divElement) {
      top = divElement.getBoundingClientRect().top;
    }
    if (top) {
      if (+top < 159) {
        this.setState({ divPositionCheck: true })
      } else if (+top > 159) {
        this.setState({ divPositionCheck: false })
      }
    }
    if (stickyMarketPlaceFilter) {
      scrollChange = +stickyMarketPlaceFilter.getBoundingClientRect().top;
    }
    if (scrollChange) {
      if (scrollChange < 149) {
        this.setState({ restaurantHeaderCheck: true })
      } else if (scrollChange > 149) {
        this.setState({ restaurantHeaderCheck: false })
      }
    }
    this.scrollHandlingofMarketPlace();
  }

  scrollHandlingofMarketPlace = () => {
    if (this.props.scrollHandling) {
      this.props.scrollHandling(this.state.divPositionCheck)
    }
  }

  fetchPaginatedData = () => {
    const { page, selectedCompanyAddressID } = this.state;
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}');
    const totalPages: number = this.props.eSearch?.restaurants?.total_pages || 0;
    if (this.state.paginationLoading || page >= totalPages) {
      return; // Return early if an API call is already in progress
    }

    this.setState({ paginationLoading: true });

    // Determine which API call to make based on selectedCompanyAddressID
    if (selectedCompanyAddressID) {
      this.fetchMoreRestaurantData(page + 1, true);
    } else if (!isEmpty(localStorageAddressData)) {
      this.fetchMoreRestaurantData(page + 1, true);
    }
  };

  fetchMoreRestaurantData = (newPage: number, paginated: boolean) => {
    const { selectedCompanyAddressID } = this.state;
    const localStorageAddressData = JSON.parse(localStorage.getItem('address') || '{}');

    // Determine which API call to make based on selectedCompanyAddressID
    if (selectedCompanyAddressID) {
      this.getMarketPlaceData('',
        selectedCompanyAddressID,
        this.state.meal_type,
        this.state.mealType,
        this.state.deliveryAt,
        [],
        newPage,
        paginated ? paginated : false)

    } else if (!isEmpty(localStorageAddressData)) {
      this.getPublicMarketPlaceData('',
        localStorageAddressData?.latitude,
        localStorageAddressData?.longitude,
        localStorageAddressData?.address_line,
        localStorageAddressData?.time_zone,
        'lunch',
        this.state.mealType,
        this.state.deliveryAt,
        localStorageAddressData?.zip,
        [],
        newPage, // Use newPage for the next page
        paginated ? paginated : false
      )
    }
    // Update the page number in the component state
    this.setState({ page: newPage, paginationLoading: false });
  };

  closeEnterpriseConfirmationPopUp = () => {
    this.setState({ enterprizePopUp: false, enterprizeConfirm: false })
  }
  openConfirmEnterprizePopUp = () => {
    this.setState({ enterprizePopUp: false, enterprizeConfirm: true, enterprizeEmailSent: true })
  }
  openEnterprizeModal = () => {
    this.setState({ enterprizePopUp: true })
  }
  changeAddressPopUpCloseCheck = () => {
    this.setState({ addressPopUpCloseCheck: false })
  }
  // Scroll to specific section
  ScrollToSpecificSection = (id: string) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    //Adding section Hash in URL 
    if (isEmpty(user)) {
      window.location.hash = id;
    }
    const element = document.getElementById(id);
    const yOffset = -250; // adjust this value to offset for any fixed header you may have
    const rect = element && element.getBoundingClientRect();
    const y = rect?.top && rect?.top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y || undefined, behavior: 'smooth' });
  };

  // scrollToTop = () => {
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // }

  handleButtonClick = () => {
    this.setState({ showAllMostPopularRestaurants: true, viewRestaurantMenu: false });
  }

  handleClickNewRestaurantNearYou = () => {
    this.setState({ showAllNewRestaurantsNearYou: true, viewRestaurantMenu: false })
  }

  handlemealTypeForMenuCheck = (value: boolean) => {
    this.setState({ mealTypeCheck: value })
    this.setState({ mealType: value === true ? 'buffet' : 'individual' })
  }

  handlemealTypeForMenuCheckAPI = (value: string) => {
    this.setState({ mealTypeForMenuCheckAPI: value })
  }

  // handleResetFilters = () => {
  //   this.setState({ menuPageFoodItemPrice: 0, menuPageFoodItemRating: 3 })
  // }

  comboCloseRestaurantCheck = (combo: Combo[]) => {
    let comboClosedRestaurant: number[] = []
    let closeRestaurantCount = 0;
    combo?.map((elem) => {
      const openRestaurants = elem?.restaurants?.filter((restaurant) => !restaurant.is_open);
      closeRestaurantCount = openRestaurants?.length
      comboClosedRestaurant.push(closeRestaurantCount)
      return null
    })
    return comboClosedRestaurant;
  };

  getRestautantComboMenuOnAddressChange = () => {
    this.props.getMenusOfRestaurant(+this.state.restaurantId, 1, 'individual')
  }

  render() {
    const {
      // mealType,
      meal_type,
      startDate,
      screenWidth,
      isOpenAddresPopUp,
      isOpenEmptyAddresPopUp,
      isOpenFiltersPopUp,
      // selectedRestaurants,
      viewRestaurantMenu,
      selectedAddressDetail,
      cartDeliveryDetails,
      // price,
      // avg_rating,
      showAllMostPopularRestaurants,
      showAllNewRestaurantsNearYou,
      // menuPageFoodItemPrice,
      // menuPageFoodItemRating,
      // restaurants
    } = this.state;

    const {
      menu,
      eSearch,
      addresses,
      dashboardRef,
      cart_server_data
    } = this.props
    const dynamicTitlesReduxData = eSearch?.dynamicTitles ? eSearch?.dynamicTitles : []
    const dynamicTitle = dynamicTitlesReduxData[0]
    const { menu_of_restaurant, loading: loading_menu, filter_purpose_loading } = menu || {};
    // Getting responsive number of slides for Carousel on Menu Detail Page.
    const {
      noOfSlidesForRestaurants,
      noOfSlidesForUserLiking,
      noOfSlidesForFoodItems,
      // noOfSlidesmostPopular,
      // noOfSlidesForComboRestaurants,
      noOfSlidesForMixMatchCombos,
    } = getResponsiveNoOfSlides();

    const queryStringPublic = window.location.search;
    // Create a URLSearchParams object
    const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
    const titleValuePublic = paramsDataDatapublic.get('address');
    // const optionalPublicDate = paramsDataDatapublic?.get('date')
    
    let closedRestaurantCombo: number[] = []
    if (eSearch?.marketplaceCombos && eSearch?.marketplaceCombos.length > 0) {
      closedRestaurantCombo = this.comboCloseRestaurantCheck(eSearch?.marketplaceCombos);
    }

    //check all restaurants isEmptyCase
    const areAllRestaurantsEmpty = (
      (isEmpty(eSearch?.marketplaceFavorites) ? true : eSearch?.marketplaceFavorites?.length === 0) &&
      (this.state?.mealType === 'buffet' || eSearch?.marketplaceCombos?.length === 0) &&
      eSearch.most_popular?.length === 0 &&
      eSearch?.restaurants?.restaurants?.length === 0 &&
      eSearch?.new_restaurants_near_you?.length === 0
    );
    const comboNumberDisplay = closedRestaurantCombo?.filter((value) => value < 3);
    const showCarouselButtons = screenWidth > 768;
    const companyTimeZone = this.company && this.company.time_zone;
    const isRestaurantsAndDietariesLoading = (loading_menu && isEmpty(menu_of_restaurant)) || (loading_menu && !filter_purpose_loading);

    const deliveryDetails = {
      admin_cutoff_at: startDate,
      delivery_at: startDate,
      clone_orders: menu_of_restaurant?.clone_orders,
      delivery_instructions: selectedAddressDetail?.delivery_instructions,
      formatted_address: selectedAddressDetail?.formatted_address,
      runningmenu_name: `${this.user.first_name?.trim()}’s New Delivery`,
      runningmenu_type: meal_type,
      time_zone: selectedAddressDetail?.time_zone
    }

    let MenuAreaSection: false | JSX.Element | JSX.Element[] | undefined;
    // let AddressList: JSX.Element | JSX.Element[] | undefined;

    if (loading_menu) {
      MenuAreaSection = <MenuFoodItemsLoading totalSlides={Math.ceil(noOfSlidesForFoodItems)} visibleSlides={noOfSlidesForFoodItems} />;
    } else {
      MenuAreaSection =
        <MenuArea
          parentRef={this}
          menu={menu as Menu}
          showCarouselButtons={showCarouselButtons}
          noOfSlidesForFoodItems={noOfSlidesForFoodItems}
          noOfSlidesForUserLiking={noOfSlidesForUserLiking}
          fromMarketplace
          handleMealTypeForMenuCheckAPI={this.handlemealTypeForMenuCheckAPI}
          mealTypeForMenuCheckAPI={this.state.mealTypeForMenuCheckAPI}
          handleMealTypeForMenuCheck={this.handlemealTypeForMenuCheck}
          restaurant_id={this.state.restaurantId}
          mealTypeNumber={this.state.mealTypeNumber}
          mealTypeCheckForMenuState={this.state.mealTypeCheck}
        // menuPageFoodItemPrice={menuPageFoodItemPrice}
        // menuPageFoodItemRating={menuPageFoodItemRating}
        />
    }

    // if (addresses.comp_addresses) {
    //   AddressList = addresses.comp_addresses.addresses.map((address, index) =>
    //     <li key={index} value={address.id} onClick={this.onClickAddressOption} >{address.formatted_address} </li>
    //   );
    // }

    // Path for favorites Section
    const defaultImagePath = "/imgs/SVGs/favourite.svg";
    const localImagePath = this.localStorageCompany?.image?.medium?.url;

    const showAddressPopUp = JSON.parse(localStorage.getItem('IsValidAddress') || '{}')
    const getGoogleAddress = JSON.parse(localStorage.getItem('no-response') || '{}')

    return (
      <div>
        {/* Most Popular Restaurant Section and New Restaurant Near You See all section */}
        {showAllNewRestaurantsNearYou ? <div>
          <ShowAllMostPopularRestaurants
            marketplace_restaurant={eSearch?.new_restaurants_near_you}
            selectRestaurant={this.selectRestaurant}
            backToMarketPlace={this.backToMarketplace}
            startDate={startDate}
            selectedCompanyAddress={selectedAddressDetail}
            showAllNewRestaurantsNearYou={true}
          />
        </div> :
          showAllMostPopularRestaurants ? <div>
            <ShowAllMostPopularRestaurants
              marketplace_restaurant={this.props?.eSearch?.most_popular}
              selectRestaurant={this.selectRestaurant}
              backToMarketPlace={this.backToMarketplace}
              startDate={startDate}
              selectedCompanyAddress={selectedAddressDetail}
            />
          </div> :
            <>
              {viewRestaurantMenu ?
                <div>
                  {screenWidth > 992 ?
                    // <DeliveryDetailsSection
                    //   fromMarketplace
                    //   // currentDelivery={deliveryDetails as unknown as Delivery}
                    //   currentDelivery={!isEmpty(cartDeliveryDetails) ? cartDeliveryDetails : deliveryDetails as unknown as Delivery}
                    //   companyTimeZone={companyTimeZone}
                    //   isMarketPlacePreview={true}
                    //   parentComponentRef={this}
                    //   redirectToNewDelivery={dashboardRef?.redirectToNewDelivery}
                    //   fromPublicMarketPlace={this.props?.fromPublicMarketPlace}
                    //   backToMarketplace={this.backToMarketplace}
                    //   divPositionCheck={this.state.divPositionCheck}
                    //   resetSearchBar={this.resetSearchBar}
                    //   setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                    //   addresses={addresses}
                    //   isOpen={isOpenAddresPopUp}
                    //   closeModal={this.closeModal}
                    //   openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                    //   // fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                    //   handleDateChange={this.handleDateChange}
                    //   getRestaurantMenuData={this.getRestautantComboMenuOnAddressChange}
                    //   // resetSearchBar={this.resetSearchBar}
                    // />
                    <MarketPlaceDeliveryDetails
                      fromMarketplace
                      // currentDelivery={deliveryDetails as unknown as Delivery}
                      currentDelivery={!isEmpty(cartDeliveryDetails) ? cartDeliveryDetails : deliveryDetails as unknown as Delivery}
                      companyTimeZone={companyTimeZone}
                      isMarketPlacePreview={true}
                      parentComponentRef={this}
                      redirectToNewDelivery={dashboardRef?.redirectToNewDelivery}
                      fromPublicMarketPlace={this.props?.fromPublicMarketPlace}
                      backToMarketplace={this.backToMarketplace}
                      divPositionCheck={this.state.divPositionCheck}
                      resetSearchBar={this.resetSearchBar}
                      setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                      addresses={addresses}
                      isOpen={isOpenAddresPopUp}
                      closeModal={this.closeModal}
                      openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                      // fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                      handleDateChange={this.handleDateChange}
                      getRestaurantMenuData={this.getRestautantComboMenuOnAddressChange}
                      // resetSearchBar={this.resetSearchBar}
                      creatingNewDelivery={() => this.creatingNewDelivery()}
                      header={<MarketplaceSearchBar
                        eRestaurantLoading={eSearch.eRestaurantLoading}
                        startDate={startDate}
                        handleDateChange={this.handleDateChange}
                        onChangeMealType={this.onChangeMealType}
                        meal_type={meal_type}
                        marketPlaceState={this.state}
                        // selectSearchStatistics={this.selectSearchStatistics} // USed for FrontEnd Search on marketPlace
                        selectSearchStatistics={this.marketPlaceFiltersBackendSearch}
                        lessMoreHandler={this.lessMoreHandler}
                        searchRestaurants={this.searchRestaurants}
                        openModal={this.openModal}
                        selectedCompanyAddress={selectedAddressDetail}
                        onChange={this.onChange}
                        keyPressed={this.keyPressed}
                        closeSearch={this.closeSearch}
                        heightController={this.heightController}
                        sortBy={this.onFilter}
                        fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                        resetAllFilters={this.resetAllFilters}
                        // price={price}
                        // avg_rating={avg_rating}
                        // onRatingChange={this.handleRatingChange}
                        // onAmountChange={this.handleAmountChange}
                        // handleSubmit={this.handleSubmit}
                        restaurantHeaderCheck={this.state.restaurantHeaderCheck}
                        cart_local_data={this.props.cart_local_data}
                        cart_server_data={cart_server_data}
                        continueOrdering={this.continueOrdering}
                        isFilterApply={this.state.isFilterApply}
                        resetSearchBar={this.resetSearchBar}
                        marketPlaceHeader={true}
                        viewRestaurantMenu={viewRestaurantMenu}
                      />}
                    />

                    :
                    <>
                      <DeliveryDetailsForMobiles
                        //currentDelivery={deliveryDetails as unknown as Delivery}
                        currentDelivery={!isEmpty(cartDeliveryDetails) ? cartDeliveryDetails : deliveryDetails as unknown as Delivery}
                        companyTimeZone={companyTimeZone}
                        isMarketPlacePreview={true}
                        parentComponentRef={this}
                        fromMarketplace
                        onCloseSideBar={() => this.props.setState({ isOpenSidebar: false })}
                        isOpenSidebar={dashboardRef?.state?.isOpenSidebar ?? false}
                      />
                      {screenWidth < 440 && !isRestaurantsAndDietariesLoading && getloggedInUser().loggedInUserCompany.self_signup === false && (getloggedInUser().isCompanyAdmin && deliveryDetails.clone_orders) && deliveryDetails.clone_orders.orders_count > 0 ?
                        <CloneOrders
                          currentDelivery={deliveryDetails as unknown as Delivery}
                          parentRef={this}
                          fromMarketplace
                        /> : null
                      }
                      {!this.props.fromPublicMarketPlace && screenWidth <= 440 && <div className='col-sm d-flex align-items-center px-0 cursor-pointer mt-1' onClick={() => this.backToMarketplace()}>
                        <span
                          data-tip
                          data-for='back-to-marketplace'
                          className='icon-wrapper-back'
                          style={{ background: '#F5F5F5' }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="marketplace-background">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                          </svg>
                          {window.navigator.userAgent.match(/MSIE|Trident/) === null &&
                            <ReactTooltip place='left' type='dark' id='back-to-marketplace' effect='solid' className='react-tooltip'>
                              <span>Back To Marketplace</span>
                            </ReactTooltip>
                          }
                        </span>
                        <p className='d-md-none fs-sm-14 fw-normal text-dark m-0 pl-2'>Back To Marketplace</p>
                      </div>}
                      {!this.props?.fromPublicMarketPlace &&
                        <div className='mobile-calendar px-2 px-sm-5 py-3'>
                          <DeliveryDatesCalendar
                            deliveryDate={`${deliveryDetails.delivery_at}`}
                            isMobileCalendar
                            onDateChangeHandler={dashboardRef?.redirectToNewDelivery}
                          />
                        </div>
                      }
                      {this.props?.fromPublicMarketPlace && screenWidth <= 440 &&
                        <div className='mobile-calendar px-3 px-md-2 px-sm-5 py-3'>
                          {/* <DeliveryDetailsSection
                      fromMarketplace
                      currentDelivery={!isEmpty(cartDeliveryDetails) ? cartDeliveryDetails : deliveryDetails as unknown as Delivery}
                      companyTimeZone={companyTimeZone}
                      isMarketPlacePreview={true}
                      parentComponentRef={this}
                      redirectToNewDelivery={dashboardRef?.redirectToNewDelivery}
                      fromPublicMarketPlace={this.props?.fromPublicMarketPlace}
                      backToMarketplace={this.backToMarketplace}
                      divPositionCheck={this.state.divPositionCheck}
                      setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                      addresses={addresses}
                      isOpen={isOpenAddresPopUp}
                      closeModal={this.closeModal}
                      openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                      // fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                      handleDateChange={this.handleDateChange}
                      // resetSearchBar={this.resetSearchBar}
                    /> */}

                          <MarketPlaceDeliveryDetails
                            fromMarketplace
                            currentDelivery={!isEmpty(cartDeliveryDetails) ? cartDeliveryDetails : deliveryDetails as unknown as Delivery}
                            companyTimeZone={companyTimeZone}
                            isMarketPlacePreview={true}
                            parentComponentRef={this}
                            redirectToNewDelivery={dashboardRef?.redirectToNewDelivery}
                            fromPublicMarketPlace={this.props?.fromPublicMarketPlace}
                            backToMarketplace={this.backToMarketplace}
                            divPositionCheck={this.state.divPositionCheck}
                            setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                            addresses={addresses}
                            isOpen={isOpenAddresPopUp}
                            closeModal={this.closeModal}
                            openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                            // fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                            handleDateChange={this.handleDateChange}
                            // resetSearchBar={this.resetSearchBar}
                            creatingNewDelivery={()=> this.creatingNewDelivery()}
                            header={<MarketplaceSearchBar
                              eRestaurantLoading={eSearch.eRestaurantLoading}
                              startDate={startDate}
                              handleDateChange={this.handleDateChange}
                              onChangeMealType={this.onChangeMealType}
                              meal_type={meal_type}
                              marketPlaceState={this.state}
                              // selectSearchStatistics={this.selectSearchStatistics} // USed for FrontEnd Search on marketPlace
                              selectSearchStatistics={this.marketPlaceFiltersBackendSearch}
                              lessMoreHandler={this.lessMoreHandler}
                              searchRestaurants={this.searchRestaurants}
                              openModal={this.openModal}
                              selectedCompanyAddress={selectedAddressDetail}
                              onChange={this.onChange}
                              keyPressed={this.keyPressed}
                              closeSearch={this.closeSearch}
                              heightController={this.heightController}
                              sortBy={this.onFilter}
                              fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                              resetAllFilters={this.resetAllFilters}
                              // price={price}
                              // avg_rating={avg_rating}
                              // onRatingChange={this.handleRatingChange}
                              // onAmountChange={this.handleAmountChange}
                              // handleSubmit={this.handleSubmit}
                              restaurantHeaderCheck={this.state.restaurantHeaderCheck}
                              cart_local_data={this.props.cart_local_data}
                              cart_server_data={cart_server_data}
                              continueOrdering={this.continueOrdering}
                              isFilterApply={this.state.isFilterApply}
                              resetSearchBar={this.resetSearchBar}
                              viewRestaurantMenu={viewRestaurantMenu}
                            />}
                          />

                          {/* </MarketPlaceDeliveryDetails> */}

                          <div />
                        </div>
                      }
                    </>}
                  {/* Clone Orders Section in case of MarketPlace And Delivery With No Orders for Company Admins*/}
                  {/* Temporarily commented for SelfSignup Post release */}
                  {screenWidth >= 440 && !isRestaurantsAndDietariesLoading && getloggedInUser().loggedInUserCompany.self_signup === false && (getloggedInUser().isCompanyAdmin && deliveryDetails.clone_orders) && deliveryDetails.clone_orders.orders_count > 0 ?
                    <CloneOrders
                      currentDelivery={deliveryDetails as unknown as Delivery}
                      parentRef={this}
                      fromMarketplace
                    /> : null
                  }
                  {/* Restaurants Logos and Dietries and Ingredients Section */}
                  <div ref={this.stickyDivRef} className='restaurants-dietries-restrictions'>
                    {/* Restaurants Logos Section Sliders */}
                    <div className={`px-0`}>
                      <div className='px-3 px-md-0'>
                        {isRestaurantsAndDietariesLoading ?
                          <MenuFiltersLoading restaurantSlidesCount={Math?.ceil(noOfSlidesForRestaurants)} /> :
                          <MenuFilters
                            fromMarketplace
                            parentState={this.state}
                            menuLoading={loading_menu}
                            showCarouselButtons={showCarouselButtons}
                            dietaries={menu_of_restaurant?.dietaries}
                            ingredients={menu_of_restaurant?.ingredients}
                            menuFilters={menu_of_restaurant?.filters}
                            restaurants={menu_of_restaurant?.restaurants}
                            noOfSlidesForRestaurants={noOfSlidesForRestaurants}
                            mealTypeForMenuCheckAPI={this.state.mealTypeForMenuCheckAPI}
                            // menuPageFoodItemPrice={this.state.menuPageFoodItemPrice}
                            // menuPageFoodItemRating={this.state.menuPageFoodItemRating}
                            // handleMenuRatingChange={this.handleMenuRatingChange}
                            // handleMenuAmountChange={this.handleMenuAmountChange}
                            marketPlaceMenu={true}
                            menu={menu?.menu_of_restaurant}
                            queryFilters={this.state.queryFilters}
                          // handleResetFilters={this.handleResetFilters}
                          />
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-12 px-0 px-md-5 text-left main-menu-area'>
                    {MenuAreaSection}
                    <Footer />
                  </div>
                </div> :
                <>
                  {(this.localStorageCompany.enterprise_status !== 'converted' && getloggedInUser().loggedInUserCompany.self_signup === true) && <div className="enterprise-plan-banner d-md-none" onClick={() => {
                    if (!this.props?.converted_to_enterprize && this.localStorageCompany.enterprise_status !== 'requested') {
                      this.openEnterprizeModal();
                    }
                  }}>
                    <div className="container px-0">
                      <span className={`d-flex align-items-center ${(this.props?.converted_to_enterprize || this.localStorageCompany.enterprise_status === 'requested') && 'opacity-3'}`}>
                        <img src="/imgs/crown.svg" alt="crown" className="mr-2 mobile-svg" />
                        <p className='enterprise-plan-heading m-0'>Upgrade to Enterprise Plan</p>
                      </span>
                    </div>
                  </div>}
                  {!this.props.fromPublicMarketPlace && <EmailVerification />}
                  {/* <div className='marketplace-header'>
              <div className="menu-img"></div>
            </div> */}
                  <div>
                    {/* <div className='container pt-3 pt-sm-4 text-left'>
              <div className='d-flex align-items-center align-items-md-baseline'>
                <div className='col-auto pt-0 pt-sm-3 pt-md-2 pl-0'>
                  <h3 className='marketplace-title-main m-0'>New Delivery</h3> */}
                    {/* <h3 className='pt-1 marketplace-title'>Let's Get Started</h3> */}
                    {/* </div>
                <div className='ml-auto col-auto pt-4 pr-0'> */}
                    {/* Filter PopUp removed for small screens */}
                    {/* <button className='btn btn-light d-inline-block d-md-none my-3' onClick={() => this.setState({ isOpenFiltersPopUp: true })}><i className='fas fa-list-ul'></i> Filters</button> */}
                    {/* <button className='btn btn-light   my-3' data-toggle='dropdown'><i className='fas fa-chevron-circle-down'></i> Sort By</button> */}
                    {/* <FilterRestaurantsBy onSortRestaurants={this.onFilter} />
                  </div>
                  {((this.user.phone_number && cart_server_data?.slug && cart_server_data?.delivery_at) ||
                      (this.props.fromPublicMarketPlace && !isEmpty(this.props.cart_local_data))
                    ) &&
                    <div className='col-auto pt-0 pt-md-2 pl-0 cursor-pointer' onClick={this.continueOrdering}>
                      <button className='btn btn-custome-white m-0 rounded-pill'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 29.002 33.003" className='mr-2'>
                          <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644" />
                        </svg>
                        Continue Your Order
                      </button>
                    </div>
                  }
                </div>

            </div> */}
                    {/* <div className={`menu-img-second ${!this.state.heightControl ? 'menu-img-elongated' : 'menu-img-normal'}`}>
            </div> */}
                    {/* removed the one circular loader from here start */}
                    {/* {eSearch.eRestaurantLoading ? <MarketPlaceLoadingSpinner classNames='spinner-center'/>
             :  */}
                    <div className='container-fluid delivery_container bg-white px-0'>
                      <div className='m-auto p-0'>
                        <div className='market-place-header'>
                          <MarketplaceSearchBar
                            eRestaurantLoading={eSearch.eRestaurantLoading}
                            startDate={startDate}
                            handleDateChange={this.handleDateChange}
                            onChangeMealType={this.onChangeMealType}
                            meal_type={meal_type}
                            marketPlaceState={this.state}
                            // selectSearchStatistics={this.selectSearchStatistics} // USed for FrontEnd Search on marketPlace
                            selectSearchStatistics={this.marketPlaceFiltersBackendSearch}
                            lessMoreHandler={this.lessMoreHandler}
                            searchRestaurants={this.searchRestaurants}
                            openModal={this.openModal}
                            selectedCompanyAddress={selectedAddressDetail}
                            onChange={this.onChange}
                            keyPressed={this.keyPressed}
                            closeSearch={this.closeSearch}
                            heightController={this.heightController}
                            sortBy={this.onFilter}
                            fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                            resetAllFilters={this.resetAllFilters}
                            // price={price}
                            // avg_rating={avg_rating}
                            // onRatingChange={this.handleRatingChange}
                            // onAmountChange={this.handleAmountChange}
                            // handleSubmit={this.handleSubmit}
                            restaurantHeaderCheck={this.state.restaurantHeaderCheck}
                            cart_local_data={this.props.cart_local_data}
                            cart_server_data={cart_server_data}
                            continueOrdering={this.continueOrdering}
                            isFilterApply={this.state.isFilterApply}
                            resetSearchBar={this.resetSearchBar}
                          />
                        </div>
                        {/* Combo Heading */}
                        <div>
                          <div className="container">
                            <div className="my-3 py-3 align-items-center text-center col-lg-12">
                              <h1 className="text-z font-weight-bold m-0" style={{ fontSize: '2rem' }}>{dynamicTitle?.title}</h1>

                              <div className="col-lg-12 px-5">
                                <div className="pt-1 pt-md-3 text-wahite m-0 mix-match-text">
                                  {dynamicTitle?.description ? parse(dynamicTitle?.description) : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-12'>
                            <>
                              {(areAllRestaurantsEmpty && (eSearch.eRestaurantLoading === false)) &&
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                  <img src="/imgs/SVGs/smiley-face.svg" className='mt-5 pt-5' alt="no restaurant found" />
                                  <h4 className='mt-32 fw-600 text-red-basic'>{'Restaurants Coming Soon !'}</h4>
                                  <p className='text-gray-primary fs-18 fw-normal text-center mb-0'>We're working hard to onboard new restaurants in your area.</p>
                                  <p className='text-gray-primary fs-18 fw-normal text-center'>Check back soon or email us at <a href="mailto:support@chowmill.com">support@chowmill.com!</a></p>
                                  <button onClick={() => this.resetAllFilters()} className="btn reset-btn-border-red fw-600 text-red-basic rounded-pill px-32 py-2">
                                    Reset Filters
                                  </button>
                                </div>
                              }
                            </>
                          </div>
                          {/* Marketplace Combos */}
                          {/* Removed the combos temporarily  */}
                          {false && <>
                            {eSearch?.marketplaceCombosLoading ?
                              <React.Fragment>
                                <div className="col-lg-12 px-0">
                                  <div className="container-fluid combo-wrapper-background">
                                    <div className=" row col-lg-12 mx-0 pr-0 px-0">
                                      {/* Skeleton for Combo API controller bit goes here */}
                                      <div className="col-lg-11 px-0 pr-0 pt-3 pt-lg-0" style={{ marginLeft: '20px' }}>
                                        {/* Skeleton for Combo API */}
                                        <MenuDetailPageCarousel
                                          totalSlides={4.5}
                                          visibleSlides={noOfSlidesForMixMatchCombos}
                                          showCarouselButtons={showCarouselButtons}
                                          NextButtonContent={<img alt='Next' src={FootItemCarouselNextButton} />}
                                          BackButtonContent={<img alt='Back' src={FootItemCarouselBackButton} />}
                                          NextButtonStyles={{ right: '10px', height: '32px', width: '32px', marginRight: '16px', }}
                                          BackButtonStyles={{ left: '-25px', height: '32px', width: '32px', marginRight: '16px', }}
                                        >
                                          {[1, 2, 3, 4, 5]?.map((combo, index) => {
                                            return <Slide key={combo + index} index={index}>
                                              <div className="mr-4">
                                                <MarketplaceCombosSkeleton />
                                              </div>
                                            </Slide>
                                          })}
                                        </MenuDetailPageCarousel>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                              :
                              <>
                                {isEmpty(eSearch?.marketplaceCombos) && (eSearch.marketplaceCombosLoading === false) ?
                                  <div>
                                    {/* for no restaurant found SVG */}
                                  </div>
                                  :
                                  <React.Fragment>
                                    {this.state?.mealType === 'individual' && (
                                      <div className="col-lg-12 px-0 pb-3">
                                        <div className="container-fluid combo-wrapper-background">
                                          <div className=" row col-lg-12 mx-0 pr-0 px-0">
                                            <div className="col-lg-11 px-0 pr-0 pt-3 pt-lg-0" style={screenWidth >= 576 ? { marginLeft: '20px' } : {}}>
                                              <MenuDetailPageCarousel
                                                totalSlides={comboNumberDisplay?.length}
                                                visibleSlides={noOfSlidesForMixMatchCombos}
                                                showCarouselButtons={showCarouselButtons}
                                                NextButtonContent={<img alt='Next' src={FootItemCarouselNextButton} />}
                                                BackButtonContent={<img alt='Back' src={FootItemCarouselBackButton} />}
                                                NextButtonStyles={{ right: '10px', height: '32px', width: '32px', marginRight: '16px', }}
                                                BackButtonStyles={{ left: '-25px', height: '32px', width: '32px', marginRight: '16px', }}
                                              >
                                                {eSearch?.marketplaceCombos?.map((combo, index) =>
                                                  closedRestaurantCombo[index] <= 2 && <Slide className='' key={index} index={index}>
                                                    <div className="mr-4">
                                                      <ComboCard 
                                                      combo={combo} 
                                                      // goToComboMenu={this.goToComboMenu} 
                                                      // getComboomboMenu={this.getComboomboMenu} 
                                                      goToComboMenu={(combo)=> false} // temporary
                                                      getComboomboMenu={()=>{}} // temporary
                                                      comboFoodItemTotalCalculator={this.comboFoodItemTotalCalculator}
                                                      />
                                                    </div>
                                                  </Slide>
                                                )}
                                              </MenuDetailPageCarousel>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                }
                              </>
                            }
                          </>}
                          {/* { SECTION SCROLLS } */}
                          {!this.props.fromPublicMarketPlace ? <div className={`container d-flex align-items-center flex-wrap px-0 py-4 ${screenWidth >= 1200 ? 'justify-content-between' : 'justify-content-center'}`}>
                            <div ref={this.restaurantHeaderCheck} className={`d-flex gap-1 py-2 pt-lg-3 pb-lg-2 sectionScroll ${screenWidth > 920 && 'justify-content-center'}`}>
                              <div className='d-flex'>
                                {/* {(this.state?.mealType === 'individual' && (!isEmpty(this.props?.eSearch?.marketplaceCombos))) && <button className='pl-0 btn combo-filter-btn d-flex align-items-center py-0'>
                                <img src="/imgs/SVGs/mix match.svg" alt="mix-match-restaurant" className='mr-1'/>
                                Mix & Match Menus
                              </button>} */}
                                {(!isEmpty(this.props?.eSearch?.marketplaceFavorites)) && <button className='btn combo-filter-btn pl-0 d-flex align-items-center py-0' onClick={() => this.ScrollToSpecificSection('my-favourites')}>
                                  {/* <img src={localImagePath ? localImagePath : defaultImagePath} alt="popular-restaurant" className='mr-1 restaurant_img' /> */}
                                  Your Favorites
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.most_popular)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('most-popular')}>
                                  {/* <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='mr-1'/> */}
                                  Popular Restaurants
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.trending_dishes)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('trending_dishes')}>
                                  {/* <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='mr-1'/> */}
                                  Trending Dishes
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.new_restaurants_near_you)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('new-restaurant-near-you')}>
                                  {/* <img src="/imgs/SVGs/near rest.svg" alt="New Restaurants Near You" className='mr-1'/> */}
                                  New Restaurants Near You
                                </button>}
                                {(!isEmpty(this.props.eSearch?.restaurants?.restaurants)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('all-restaurants')}>
                                  {/* <img src="/imgs/SVGs/All Restaurants.svg" alt="all-restaurant" className='mr-1'/> */}
                                  All Restaurants
                                </button>}
                              </div>
                            </div>


                            <div className='d-flex justify-center align-items-center gap-1'>
                              {(!loggedInUserCompany.self_signup && !isEmpty(loggedInUser)) && !areAllRestaurantsEmpty && <>
                                {!this.props?.eSearch?.menuTypeLoader ? <div className='custom-control d-flex custom-switch pull-right mr-0 buffet-menu-switch buffet-marketplace-switch'>
                                  <input type='checkbox' className='custom-control-input buffet-menu-input' id='mealTypeCheck' name='mealTypeCheck' checked={this.state?.mealTypeCheck} onChange={this.onChange} />
                                  <label className={`custom-control-label buffet-menu-label buffet-marketplace-switch ${screenWidth >= 992 ? 'custom-buffet-switch-container' : 'custom-buffet-switch-container'}`} htmlFor='mealTypeCheck' >&nbsp;&nbsp;</label>
                                </div> :
                                  <Skeleton className='rounded-pill' height={30} width={260} />
                                }
                              </>
                              }

                              {!areAllRestaurantsEmpty && <div className=' col-auto mx-2 pr-0 mt-1'>
                                {/* Filter PopUp removed for small screens */}
                                <button className='btn btn-light d-inline-block d-md-none my-3' onClick={() => this.setState({ isOpenFiltersPopUp: true })}><i className='fas fa-list-ul'></i> Filters</button>
                                <button className='btn btn-light my-3' data-toggle='dropdown'><i className='fas fa-chevron-circle-down'></i> Sort By</button>
                                <FilterRestaurantsBy onSortRestaurants={this.onFilter} />
                              </div>}
                            </div>


                          </div> : <></>}
                          {this.props.fromPublicMarketPlace ? <div className={`container d-flex align-items-center flex-wrap px-0 py-4  justify-content-center`}>
                            <div ref={this.restaurantHeaderCheck} className={`d-flex gap-1 py-2 pt-lg-3 pb-lg-2 sectionScroll ${screenWidth > 920 && 'justify-content-center'}`}>
                              <div className='d-flex'>
                                {/* {(this.state?.mealType === 'individual' && (!isEmpty(this.props?.eSearch?.marketplaceCombos))) && <button className='pl-0 btn combo-filter-btn d-flex align-items-center py-0'>
                                  <img src="/imgs/SVGs/mix match.svg" alt="mix-match-restaurant" className='mr-1' />
                                  Mix & Match Menus
                                </button>} */}
                                {(!isEmpty(this.props?.eSearch?.marketplaceFavorites)) && <button className='btn combo-filter-btn pl-0 d-flex align-items-center py-0' onClick={() => this.ScrollToSpecificSection('my-favourites')}>
                                  {/* <img src={localImagePath ? localImagePath : defaultImagePath} alt="popular-restaurant" className='mr-1 restaurant_img' /> */}
                                  Your Favorites
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.most_popular)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('most-popular')}>
                                  {/* <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='mr-1' /> */}
                                  Popular Restaurants
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.trending_dishes)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('trending_dishes')}>
                                  {/* <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='mr-1'/> */}
                                  Trending Dishes
                                </button>}
                                {(!isEmpty(this.props?.eSearch?.new_restaurants_near_you)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('new-restaurant-near-you')}>
                                  {/* <img src="/imgs/SVGs/near rest.svg" alt="New Restaurants Near You" className='mr-1' /> */}
                                  New Restaurants Near You
                                </button>}
                                {(!isEmpty(this.props.eSearch?.restaurants?.restaurants)) && <button className='btn combo-filter-btn d-flex align-items-center py-0 pl-0' onClick={() => this.ScrollToSpecificSection('all-restaurants')}>
                                  {/* <img src="/imgs/SVGs/All Restaurants.svg" alt="all-restaurant" className='mr-1' /> */}
                                  All Restaurants
                                </button>}
                              </div>
                            </div>
                            {(!loggedInUserCompany.self_signup && !isEmpty(loggedInUser)) && <>
                              {!this.props?.eSearch?.menuTypeLoader ? <div className='custom-control d-flex custom-switch pull-right mr-0 buffet-menu-switch buffet-marketplace-switch'>
                                <input type='checkbox' className='custom-control-input buffet-menu-input' id='mealTypeCheck' name='mealTypeCheck' checked={this.state?.mealTypeCheck} onChange={this.onChange} />
                                <label className={`custom-control-label buffet-menu-label buffet-marketplace-switch ${screenWidth >= 992 ? 'custom-buffet-switch-container' : 'custom-buffet-switch-container'}`} htmlFor='mealTypeCheck' >&nbsp;&nbsp;</label>
                              </div> :
                                <Skeleton className='rounded-pill' height={30} width={260} />
                              }
                            </>
                            }
                            {!areAllRestaurantsEmpty && <div className=' col-auto  pr-0 mt-1 ml-auto'>
                              {/* Filter PopUp removed for small screens */}
                              <button className='btn btn-light d-inline-block d-md-none my-3' onClick={() => this.setState({ isOpenFiltersPopUp: true })}><i className='fas fa-list-ul'></i> Filters</button>
                              <button className='btn btn-light my-3' data-toggle='dropdown'><i className='fas fa-chevron-circle-down'></i> Sort By</button>
                              <FilterRestaurantsBy onSortRestaurants={this.onFilter} />
                            </div>
                            }
                          </div> : <></>}
                          {/* Favorite Restaurants */}
                          {!this.props?.fromPublicMarketPlace ? <>
                            {/* / Restaurant section skeleton controller bit goes here / */}
                            {(eSearch?.marketplaceFavoritesLoading || this.state?.sortingLoaderLocal) ?
                              <div className='col-lg-12 px-0 pt-4'>
                                <div className="container bg-white px-3 restaurant-sliders">
                                  <MarketPlaceSectionSkeleton />
                                </div>
                              </div>
                              :
                              <div className="col-lg-12 px-0" id='my-favourites'>
                                <div className="container bg-white px-0 restaurant-sliders">
                                  <div className={`popular-feature-box px-3 px-md-0 d-flex align-items-center justify-content-between ${isEmpty(eSearch?.marketplaceCombos) && !eSearch.marketplaceCombosLoading ? 'pt-0' : 'pt-3'}`}>
                                    {!isEmpty(eSearch?.marketplaceFavorites) && <div className='d-flex align-items-center justify-content-start'
                                      style={{ minWidth: '280px' }}>
                                      <img src={localImagePath ? localImagePath : defaultImagePath} alt="all-restaurant" className='all-restaurant-img' />
                                      <h4 className='mb-0 font-weight-bold restaurant-heading mx-3 mx-sm-0'>
                                        Your Favorites
                                      </h4>
                                    </div>}
                                    <div className='flex align-items-center justify-end see_all_section text-gray-100 cursor-pointer fw-600'></div>
                                  </div>
                                  {/* <MenuDetailPageCarousel
                                    totalSlides={eSearch?.marketplaceFavorites?.length || 0}
                                    visibleSlides={noOfSlidesmostPopular}
                                    // visibleSlides={4}
                                    showCarouselButtons={showCarouselButtons}
                                    NextButtonContent={<img alt='Next' src={NewSliderChevronRight} />}
                                    BackButtonContent={<img alt='Back' src={NewSliderChevronLeft} />}
                                    NextButtonStyles={{ right: '22px', height: '24px', width: '24px', top: '-20px' }}
                                    BackButtonStyles={{ left: '93%', height: '24px', width: '24px', top: '-20px', position: "absolute" }}
                                    mostPopular={true}
                                  > */}
                                  {(eSearch?.marketplaceFavorites && eSearch?.marketplaceFavorites?.length >= 1) ? <div className='d-flex pt-4 align-items-center marketplace-restaurant-section'>
                                    {/* {this.state.sortedMarketplace?.marketplace_favorite?.map((favorite_restaurants, index) => */}
                                    {this.state.sortedMarketplace?.marketplace_favorite?.map((favorite_restaurants, index) =>
                                      <div className='individual-restaurants col-md-6 px-0 w-100' key={index}>
                                        <MarketplaceRestaurantCard
                                          restaurants={favorite_restaurants}
                                          index={index}
                                          screenWidth={this.state.screenWidth}
                                          selectRestaurant={this.selectRestaurant}
                                          fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                                        />
                                      </div>
                                    )}
                                  </div> : <></>}
                                  {/* </MenuDetailPageCarousel> */}
                                </div>
                              </div>
                            }
                          </> : <></>}
                          {/* Restaurants Restaurants */}
                          {/* {eSearch.marketPlaceMostPopularRestaurantsLoading ? */}
                          {(eSearch.marketPlaceMostPopularRestaurantsLoading || this.state?.sortingLoaderLocal) ?
                              <div className="col-lg-12 px-0 pt-4" >
                                <div className="container bg-white px-0 restaurant-sliders">
                                  <MarketPlaceSectionSkeleton />
                                </div>
                              </div> :
                              <>
                                {eSearch?.most_popular && !isEmpty(eSearch?.most_popular) && (
                                  <div className="col-lg-12 px-0" id='most-popular'>
                                    <div className="container bg-white px-0 restaurant-sliders">
                                      <div className='popular-feature-box pt-4 px-3 px-md-0 d-flex align-items-center justify-content-between'>
                                        {!isEmpty(eSearch?.most_popular) && <div className='d-flex align-items-center justify-content-start'
                                          style={{ minWidth: '280px' }}>
                                          {/* <img src="/imgs/SVGs/Most Popular.svg" alt="all-restaurant" className='all-restaurant-img' /> */}
                                          <h4 className='mb-0 font-weight-bold restaurant-heading mx-3 mx-sm-0'>
                                            Popular Restaurants
                                          </h4>
                                        </div>}
                                        {/* {screenWidth < 775 ? (eSearch?.most_popular && eSearch?.most_popular?.length >= 2) && <div className='flex align-items-center justify-end see_all_section text-gray-100 cursor-pointer fw-600' onClick={this.handleButtonClick}>See All({eSearch?.most_popular?.length})</div> : (eSearch?.most_popular && eSearch?.most_popular?.length > 3) && <p className='flex justify-center fs-16 text-gray-100 cursor-pointer mb-0 fw-600' onClick={this.handleButtonClick}>See All</p>} */}
                                      </div>
                                      {/* <MenuDetailPageCarousel
                                totalSlides={eSearch?.most_popular?.length || 0}
                                visibleSlides={noOfSlidesmostPopular}
                                // visibleSlides={4}
                                showCarouselButtons={showCarouselButtons}
                                NextButtonContent={<img alt='Next' src={NewSliderChevronRight} />}
                                BackButtonContent={<img alt='Back' src={NewSliderChevronLeft} />}
                                NextButtonStyles={{ right: '22px', height: '24px', width: '24px', top: '-20px' }}
                                BackButtonStyles={{ left: '93%', height: '24px', width: '24px', top: '-20px', position: "absolute" }}
                                mostPopular={true}
                              > */}
                                      <div className='d-flex pt-4 align-items-center marketplace-restaurant-section'>

                                        {/* Sorting based on rating applied from fromEnd on combo restaurants */}
                                        {/* {this.state?.sortedMarketplace?.marketplace_popular_restaurant?.map((most_popular, index) => */}
                                        {this.state?.sortedMarketplace?.marketplace_popular_restaurant?.map((most_popular, index) =>
                                          <div className='individual-restaurants col-md-6 px-0 w-100' key={index}>
                                            <MarketplaceRestaurantCard
                                              restaurants={most_popular}
                                              index={index}
                                              screenWidth={this.state.screenWidth}
                                              selectRestaurant={this.selectRestaurant}
                                              fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {/* </MenuDetailPageCarousel> */}
                                    </div>
                                  </div>
                                )}
                              </>

                            }
                          {(eSearch?.marketplaceTrendingDishesLoading || this.state?.sortingLoaderLocal) ?
                            <div className="col-lg-12 px-0 pt-4" >
                              <div className="container bg-white px-0 restaurant-sliders">
                                <MarketPlaceSectionSkeleton />
                              </div>
                            </div> :
                            <>
                              {eSearch?.trending_dishes && !isEmpty(eSearch?.trending_dishes) && (
                                <div className="col-lg-12 px-0" id='trending_dishes'>
                                  <div className="container bg-white px-0 restaurant-sliders">
                                    <div className='popular-feature-box pt-4 px-3 px-md-0 d-flex align-items-center justify-content-between'>
                                      {!isEmpty(eSearch?.trending_dishes) && <div className='d-flex align-items-center justify-content-start'
                                        style={{ minWidth: '280px' }}>
                                        <h4 className='mb-0 font-weight-bold restaurant-heading mx-3 mx-sm-0'>
                                          Trending Dishes
                                        </h4>
                                      </div>}
                                      {/* {screenWidth < 775 ? (eSearch?.most_popular && eSearch?.most_popular?.length >= 2) && <div className='flex align-items-center justify-end see_all_section text-gray-100 cursor-pointer fw-600' onClick={this.handleButtonClick}>See All({eSearch?.most_popular?.length})</div> : (eSearch?.most_popular && eSearch?.most_popular?.length > 3) && <p className='flex justify-center fs-16 text-gray-100 cursor-pointer mb-0 fw-600' onClick={this.handleButtonClick}>See All</p>} */}
                                    </div>
                                    <div className='d-flex pt-4 align-items-center marketplace-restaurant-section'>

                                      {/* Sorting based on rating applied from fromEnd on combo restaurants */}
                                      {eSearch?.trending_dishes?.map((trending_dishes, index) =>
                                        <div className='individual-restaurants col-md-6 px-0 w-100' key={index}>
                                          <MarketPlaceFoodItemCard
                                            dishes={trending_dishes}
                                            index={index}
                                            screenWidth={this.state.screenWidth}
                                            selectRestaurant={this.selectPopularDish}
                                            fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {/* </MenuDetailPageCarousel> */}
                                  </div>
                                </div>
                              )}
                            </>

                          }
                          <>
                           

                            {(eSearch?.marketPlaceNewRestaurantNearYouLoading || this.state?.sortingLoaderLocal) ?
                              <div className="col-lg-12 px-0 pt-4" >
                                <div className="container bg-white px-0 restaurant-sliders">
                                  <MarketPlaceSectionSkeleton />
                                </div>
                              </div>
                              :
                              <>
                                {eSearch?.new_restaurants_near_you && !isEmpty(eSearch?.new_restaurants_near_you) && (
                                  <div className="col-lg-12 px-0" id='new-restaurant-near-you'>
                                    <div className="container bg-white px-0 restaurant-sliders">
                                      <div className='popular-feature-box pt-4 px-3 px-md-0 d-flex align-items-center justify-content-between'>
                                        {!isEmpty(eSearch?.new_restaurants_near_you) && <div className='d-flex align-items-center justify-content-start'
                                          style={{ minWidth: '280px' }}>
                                          {/* <img src="/imgs/SVGs/near restaurant.svg" alt="New Restaurants Near You" className='all-restaurant-img' /> */}
                                          <h4 className='mb-0 font-weight-bold restaurant-heading mx-3 mx-sm-0'>
                                            New Restaurants Near You
                                          </h4>
                                        </div>}
                                        {/* {screenWidth < 775 ? eSearch?.new_restaurants_near_you?.length >= 2 && <div className='flex align-items-center justify-end see_all_section text-gray-100 cursor-pointer fw-600' onClick={this.handleClickNewRestaurantNearYou}>See All({eSearch?.new_restaurants_near_you?.length})</div> : eSearch?.new_restaurants_near_you.length > 3 && <p className='flex justify-center fs-16 text-gray-100 cursor-pointer mb-0 fw-600' onClick={this.handleClickNewRestaurantNearYou}>See All</p>} */}
                                      </div>
                                      {/* <MenuDetailPageCarousel
                              totalSlides={eSearch?.new_restaurants_near_you?.length || 0}
                              visibleSlides={noOfSlidesmostPopular}
                              // visibleSlides={4}
                              showCarouselButtons={showCarouselButtons}
                              NextButtonContent={<img alt='Next' src={NewSliderChevronRight} />}
                              BackButtonContent={<img alt='Back' src={NewSliderChevronLeft} />}
                              NextButtonStyles={{ right: '22px', height: '24px', width: '24px', top: '-20px' }}
                              BackButtonStyles={{ left: '93%', height: '24px', width: '24px', top: '-20px', position: "absolute" }}
                              mostPopular={true}
                            > */}
                                      <div className='d-flex pt-4 align-items-center marketplace-restaurant-section'>

                                        {/* {this.state?.sortedMarketplace?.marketplace_new_restaurants?.map((new_restaurants_near_you, index) => */}
                                        {this.state?.sortedMarketplace?.marketplace_new_restaurants?.map((new_restaurants_near_you, index) =>
                                          <div className='individual-restaurants col-md-6 px-0 w-100' key={index}>
                                            <MarketplaceRestaurantCard
                                              restaurants={new_restaurants_near_you}
                                              index={index}
                                              screenWidth={this.state.screenWidth}
                                              selectRestaurant={this.selectRestaurant}
                                              fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {/* </MenuDetailPageCarousel> */}
                                    </div>
                                  </div>
                                )}
                              </>
                            }
                          </>
                          {eSearch.eRestaurantLoading ? <div className='col-lg-12 px-0'>
                            <div className="container bg-white px-3 restaurant-sliders">
                              {/* / Skeleton for Marketplace section restaurants / */}
                              <MarketPlaceSectionSkeleton />
                            </div>
                          </div> :
                            <>
                              {(!isEmpty(!eSearch?.restaurants?.restaurants)) && (
                                <InfiniteScroll
                                  dataLength={eSearch?.restaurants?.restaurants?.length || 0}
                                  next={this.fetchPaginatedData}
                                  hasMore={!this.state.paginationLoading}
                                  loader={this.props?.isLoading ? <div style={{ height: "50px" }}><Spinner spinnerSizeClass='infinite-scroll-spinner' /></div>
                                    : null
                                  }
                                  style={{ overflow: 'visible' }}
                                >
                                  <div className='container mx-auto marketplace-individual-restaurant-section px-0' id='all-restaurants'>
                                    <div className='col-12 pb-4 pt-4 px-3 px-md-0 d-flex'>
                                      {!isEmpty(eSearch?.restaurants?.restaurants) && <div className='d-flex align-items-center justify-content-start' style={{ minWidth: '280px' }}>
                                        {/* <img src="/imgs/SVGs/All Restaurants.svg" alt="all-restaurant" className='all-restaurant-img' /> */}
                                        <h4 className='mb-0 font-weight-bold restaurant-heading mx-3 mx-sm-0'>
                                          All Restaurants
                                        </h4>
                                      </div>}
                                    </div>
                                    <>
                                      {/* {this.state?.sortedMarketplace?.marketplace_all_restaurants?.map((restaurant, index) => */}
                                      {this.state?.sortedMarketplace?.marketplace_all_restaurants?.map((restaurant, index) =>
                                        <React.Fragment key={index}>
                                          <div className='individual-restaurants col-md-6 px-0 w-100'>
                                            <MarketplaceRestaurantCard
                                              restaurants={restaurant}
                                              index={index}
                                              screenWidth={this.state.screenWidth}
                                              selectRestaurant={this.selectRestaurant}
                                              fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                                              marketplaceRestaurant={true}
                                            />
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </>
                                  </div>
                                </InfiniteScroll>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {/* / For Handling Address / */}
              {/* {isEmpty(this.localAddress) && isEmpty(loggedInUser) && !this.props?.fromPublicRestaurantMenu ? */}
              {isEmpty(this.localAddress) && (isEmpty(titleValuePublic) || getGoogleAddress?.googleResponse === 'invalid') && !this.state.addressInURL && isEmpty(loggedInUser) ?
                <>
                  {<div className=' w-100 mt-4 address-popup-ove' onClick={() => this.setState({ addressPopUpCloseCheck: true })}>
                    <div className="container px-0">
                      {/* <div className="col-lg-12 w-100 position-relative address-popup-form px-0">
                        <div className="bg-white mb-1 mb-sm-2 px-4 py-3 mx-3 mx-sm-0 row address-popup-radious">
                          <div className='px-0 col-sm-12 col-md-12 col-lg-6 d-flex pb-1 pb-sm-0'>
                            <p className='mb-0 fw-normal delivery-lable py-2 d-none'>DELIVERING TO:</p>
                            <div className='main-custom-options main-custom-options-marketplace d-flex align-items-center'>
                              <span className='icons-wrapper mx-0 ml-md-0 mr-lg-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25.062 33">
                                  <path id="location-dot-thin_-_Outline" data-name="location-dot-thin - Outline" d="M12.031-.5A12.533,12.533,0,0,1,24.562,12.031c0,2.464-1.394,5.974-4.142,10.432a96.042,96.042,0,0,1-6.514,9.129,2.393,2.393,0,0,1-3.75,0C7.658,28.521-.5,18.023-.5,12.031A12.531,12.531,0,0,1,12.031-.5ZM14.473.76A11.533,11.533,0,0,0,.5,12.031c0,2.253,1.374,5.679,3.972,9.908a90.26,90.26,0,0,0,6.462,9.026,1.393,1.393,0,0,0,2.193,0c3.137-3.926,10.436-13.633,10.436-18.937a11.534,11.534,0,0,0-.26-2.442,11.635,11.635,0,0,1,.258,2.442,12.385,12.385,0,0,1-1.155,4.555,40.474,40.474,0,0,1-2.836,5.351,104.843,104.843,0,0,1-6.445,9.029,1.389,1.389,0,0,1-2.183,0A104.616,104.616,0,0,1,4.5,21.94a40.169,40.169,0,0,1-2.838-5.353A12.321,12.321,0,0,1,.5,12.031,11.529,11.529,0,0,1,12.031.5,11.635,11.635,0,0,1,14.473.76ZM12.031,30.5a.4.4,0,0,0,.313-.157,103.758,103.758,0,0,0,6.375-8.931A39.472,39.472,0,0,0,21.484,16.2a11.448,11.448,0,0,0,1.076-4.166,10.529,10.529,0,0,0-21.057,0A11.387,11.387,0,0,0,2.58,16.2a39.172,39.172,0,0,0,2.766,5.214,103.531,103.531,0,0,0,6.374,8.93A.4.4,0,0,0,12.031,30.5Zm0-23.979a5.513,5.513,0,1,1-5.513,5.513A5.519,5.519,0,0,1,12.031,6.518Zm0,10.026a4.513,4.513,0,1,0-4.513-4.513A4.518,4.518,0,0,0,12.031,16.544Zm0-9.023a4.51,4.51,0,1,1-4.51,4.51A4.515,4.515,0,0,1,12.031,7.521Zm0,8.021a3.51,3.51,0,1,0-3.51-3.51A3.514,3.514,0,0,0,12.031,15.542Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                                </svg>
                              </span>
                              <input
                                type='text'
                                className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                                id='address'
                                name='selectedCompanyAddress'
                                placeholder='Add Delivery Address'
                                readOnly
                              />
                              <i className='fas fa-chevron-down mx-1 px-1' style={{ lineHeight: 'inherit' }}></i>
                            </div>
                          </div>
                          <div className="px-0 col-sm-12 col-md-12 col-lg-6 d-flex pb-1 pb-sm-0 mt-2 mt-lg-0">
                            <div className=" pt-2 pt-md-0 px-0 px-ml-3 d-flex align-items-center bg-white">
                              <div className='px-0 position-relative d-flex align-items-center'>
                                <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 28.429">
                                    <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                                  </svg>
                                </span>
                                <input
                                  type='text'
                                  className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                                  id='address'
                                  name='selectedCompanyAddress'
                                  placeholder={dayjs().hour() >= 14 ? `${dayjs(`${dayjs(new Date()).add(2, 'day')}`).format('dddd, MMM D')} at 11:45 AM` : `${dayjs(`${dayjs(new Date()).add(1, 'day')}`).format('dddd, MMM D')} at 11:45 AM`}
                                  readOnly
                                />
                                <i className='fas fa-chevron-down mx-1' style={{ lineHeight: 'inherit' }}></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="">
                        <div className="bg-white address-form-popup-radious mx-3 mx-sm-0">
                          <MarketPlaceDeliveryAddressPublicPopUp
                            setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                            selectedCompanyAddress={selectedAddressDetail}
                            addresses={addresses}
                            isOpen={isOpenEmptyAddresPopUp}
                            closeModal={this.closeModal}
                            openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                            fromPublicMarketPlace={this.props?.fromPublicMarketPlace ? true : false}
                            popUpCloseCheck={this.state.addressPopUpCloseCheck}
                            changeAddressPopUpCloseCheck={this.changeAddressPopUpCloseCheck}
                          />
                        </div>
                      </div>
                    </div>
                  </div>}
                </>
                :
                <>
                  {showAddressPopUp && <DeliveryAddressPopUp
                    setAddressInMarketPlace={(address: number | AddressDataType) => this.setSelectedAddress(address)}
                    selectedCompanyAddress={selectedAddressDetail}
                    addresses={addresses}
                    isOpen={isOpenAddresPopUp}
                    closeModal={this.closeModal}
                    openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
                    fromPublicMarketPlace={this.props.fromPublicMarketPlace ? this.props.fromPublicMarketPlace : false}
                    handleDateChange={this.handleDateChange}
                    resetSearchBar={this.resetSearchBar}
                  />}
                </>
              }
              <MeetingDetailsPopUp
                onRef={(ref: MeetingDetailsPopUpRef | null) => { this.meetingPopup = ref }}
                selectedDate={startDate}
                selectedType={meal_type}
                onUpdatePage={this.onUpdatePage.bind(this)}
                selectedRestaurants={undefined}
                marketPlace={true}
                meeting={{
                  mealType: meal_type,
                  deliveryInstructions: selectedAddressDetail?.delivery_instructions,
                  selectedCompanyAddress: selectedAddressDetail,
                  selectedCompanyAddressID: selectedAddressDetail?.id
                }}
              />
              {false ? <MarketplaceFiltersPopup
                applyFilter={this.applyFilter}
                isOpen={isOpenFiltersPopUp}
                closeModal={() => this.setState({ isOpenFiltersPopUp: false })}
                startDate={startDate}
                onChange={this.onChange}
                keyPressed={this.keyPressed}
                closeSearch={this.closeSearch}
                handleDateChange={this.handleDateChange}
                onChangeMealType={this.onChangeMealType}
                meal_type={meal_type}
                marketPlaceState={this.state}
                selectSearchStatistics={this.selectSearchStatistics}
                lessMoreHandler={this.lessMoreHandler}
                searchRestaurants={this.searchRestaurants}
                openModal={this.openModal}
                selectedCompanyAddress={selectedAddressDetail}
                onFilter={this.onFilter}
              /> : <></>}

              <Cart
                isOpenSidebar={this.state.showCart}
                closeSidebar={() => { this.setState({ showCart: false }); document.title = 'Menu | Chowmill'; }}
                fromPublicMarketPlace={this.props.fromPublicMarketPlace}
                fromMarketPlace={true}
                continueOrdering={!isEmpty(cartDeliveryDetails) ? undefined : this.continueOrdering}
              />
              <>
                {this.state.enterprizePopUp && <EnterprizePlanViewerPopUp
                  modalIsOpen={this.state.enterprizePopUp}
                  closeModal={this.closeEnterpriseConfirmationPopUp}
                  confirmAction={this.openConfirmEnterprizePopUp}
                />}
                {this.state.enterprizeConfirm && <EnterprizeConfirmationPopUp
                  modalIsOpen={this.state.enterprizeConfirm}
                  closeModal={this.closeEnterpriseConfirmationPopUp}
                  confirmAction={() => { }}
                />
                }
              </>
              {/* {<div className='scrollToTop-button'>
                <button className='btn btn-danger rounded-circle' onClick={this.scrollToTop}>
                  <i className="fas fa-chevron-up"></i>
                </button>
              </div>} */}
            </>
        }
        {(viewRestaurantMenu && this.state.marketPlaceNewDeliveryCreation) ? <CreateNewMeetingPopUp
          selectedDate={startDate}
          selectedType={meal_type}
          selectedMealTypeForMenuCheckAPI={this.state.mealTypeForMenuCheckAPI}
          onUpdatePage={this.onUpdatePage.bind(this)}
          selectedRestaurants={undefined}
          marketPlace={true}
          modalState={this.state.marketPlaceNewDeliveryCreation}
          onCloseModal={this.closeNewDeliveryModal}
          meeting={{
            mealType: meal_type,
            deliveryInstructions: selectedAddressDetail?.delivery_instructions,
            selectedCompanyAddress: selectedAddressDetail,
            selectedCompanyAddressID: selectedAddressDetail?.id
          }}
        />
          :
          <></>
        }
      </div>
    )
  }
}
const mapStateToProps = (state: ReduxRootState) => ({
  menu: state.menu,
  addresses: state.addresses,
  eSearch: state.marketplaceSuggestions,
  i18n: state.i18n.i18n,
  schedules_details: state.schedules_details,
  // address_ids: state.server_cart.address_ids,
  address_ids: state.cart.address_ids,
  selectedAddressData: state.addresses.selectedAddressData,
  cart_server_data: state.server_cart.cart_at_login,
  converted_to_enterprize: state?.company_data_reducer?.converted_to_enterprize,
  cart_local_data: state.cart.cart_order_payload,
  activeRestaurantIds: state?.server_cart?.address_ids,
  isLoading: state?.loading?.isLoading
});

export type MarketplaceRef = React.ElementRef<typeof Marketplace>;

export default connect(mapStateToProps, { getMarketplaceSuggestions, getRestaurants, getPublicRestaurants, getPublicMarketPlaceFilters, getMostPopularRestaurants, getTrendingDishes, getPublicMostPopularRestaurants, getPublicTrendingDishes, getNewRestaurantsNearYou, getMarketPlaceFilters, getPublicNewRestaurantsNearYou, getDynamicTitles, getMarketplaceFavorites, getCuisine, getCompanyAddresses, getMenusOfRestaurant, getMenusOfRestaurantReValidate, getPublicMenusOfRestaurantReValidate })(withRouter(Marketplace));

