import axios from 'axios';
import { GET_INVITED_USERS_FOR_MEETING, LOADING_INVITED_USERS, INVITE_USER_FOR_MEETING_BY_TOKEN, RESEND_INVITE, GENERATE_SHARE_MEETING_TOKEN, GET_SCHEDULE } from './types';
import { getError } from './errorActions';
import { InviteUserForMeetingByTokenPayload, InviteUsersForMeetingPayload } from '../Interfaces/PayloadsAndResponses/ShareMeeting';
import { AppThunkAction } from '../store';

export const getUsersInvitedForMeeting = (id: number): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/schedules/${id}/share_meetings/`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_INVITED_USERS_FOR_MEETING,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const inviteUserForMeetingByToken = (user: InviteUserForMeetingByTokenPayload, meetingSlug: string, token: string, isAlreadySharedMeeting: boolean): AppThunkAction => (dispatch, getState) => {
  dispatch(setLoading());
  // share token means running menu token and token means share meeting token
  axios
    .post(`/schedules/${meetingSlug}/share_meetings/by_token?share_token=${token}`, user,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if (!isAlreadySharedMeeting === true) { // If share Meeting was not already created then Redirect to New URL.
        window.location.href = res.data.share_meeting_link;
      } else {
        dispatch({
          type: INVITE_USER_FOR_MEETING_BY_TOKEN,
          payload: res,
        });
        const prevScheduleForShareMeeting = getState().menu.schedule;
        dispatch({
          type: GET_SCHEDULE,
          payload: prevScheduleForShareMeeting ? { ...prevScheduleForShareMeeting, share_meeting: { ...prevScheduleForShareMeeting.share_meeting, ...user.share_meeting } } : null
        })
      }
    })
    .catch(err => {
      dispatch(getError(err))
    });
};

export const inviteUsersForMeeting = (users: InviteUsersForMeetingPayload, meetingId: number): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .post(`/schedules/${meetingId}/share_meetings/`, users,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: INVITE_USER_FOR_MEETING_BY_TOKEN,
        payload: res
      })
    })
    .catch(err => {
      dispatch(getError(err))
    });
};

export const resendInvite = (shareMeetingId: number, meetingId: number): AppThunkAction => dispatch => {
  axios
    .get(`/schedules/${meetingId}/share_meetings/${shareMeetingId}/resend_invite`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: RESEND_INVITE,
        payload: res
      })
    )
    .catch(err => {
      dispatch(getError(err))
    });
};
export const generateToken = (id: number): AppThunkAction => dispatch => {
  axios
    .post(`/schedules/${id}/share_meetings/generate_token`, {},
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GENERATE_SHARE_MEETING_TOKEN,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(getError(err))
    });
};
// export const saveCustomer = (token: any, nonce: any, meetingId: any): AppThunkAction => dispatch => {
//   axios.post(`/schedules/${meetingId}/share_meetings/add_customer?token=${token}&nonce=${nonce}`,
//     {
//       headers: JSON.parse(localStorage.getItem('headers') || '{}')
//     })
//     .then(res => {
//       localStorage.setItem('company', JSON.stringify(res.data.company));
//       dispatch({
//         type: SET_CUSTOMER_DATA,
//         payload: res.data
//       });
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };
// Status Loading
export const setLoading = () => {
  return {
    type: LOADING_INVITED_USERS
  };
}