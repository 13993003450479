import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { getloggedInUser } from '../../Utilities/getloggedInUser';

const { loggedInUser: user } = getloggedInUser();

type PrivateRouteProps = {
  component: any;
  exact: boolean;
  path: string | Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const location = useLocation();
  const { auth } = useSelector((state: ReduxRootState) => state);

  return <Route
    {...rest}
    render={props =>
      (auth.isAuthenticated === true && user && user.address_id !== null) || props.location.pathname === '/signup' ? (
        <Component {...props} />
      ) : (
        <Redirect to={(user !== null && user.address_id === null) ? '/signup' : { pathname: '/signin', state: { to: location } }} />
      )
    }
  />
}

export default PrivateRoute;