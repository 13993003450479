// Pacakages
import React, { Component, createRef } from 'react';
import $ from 'jquery'
import dayjs from 'dayjs';
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Confetti from 'react-confetti'
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
// Recat JSX Components
import ButtonSpinner from '../Spinners/ButtonSpinner';
import StripeLayout, { StripeLayoutRef } from '../Stripe/StripeLayout';
import ScheduleDeliveryForm, { ScheduleDeliveryFormRef } from '../ScheduleDeliveryForm';
// Redux Actions
import { getCompanyForOrderPurpose } from '../../Actions/companyActions';
import { placeOrder, getSingleOrder, updateSingleOrder } from '../../Actions/orderActions';
import { getDeliveryDates, makeDeliveryRequest } from '../../Actions/scheduleDeliveryActions';
import { addToCart, placeCartOrder, updateCartOrder } from '../../Actions/cartActions';
import { UPDATE_COMPANY_FOR_ORDER_PURPOSE, GET_COMPANY_FOR_ORDER_PURPOSE, EMPTY_CART, CONTINUE_ORDERING_FOR_SAME_CART } from '../../Actions/types';
import { SET_CONFIRM_ORDER_POPUP_STATE } from '../../Actions/types';
// Utilities
import store from '../../store';
import isEmpty from '../../Utilities/is_empty';
import { GetloggedInUser, getloggedInUser } from '../../Utilities/getloggedInUser';
import { emailPattern, phoneNumberPattern } from '../../Utilities/validationPatterns';
import { getPricesToShown } from '../../Utilities/getPricesToShown';
import { showToastNotification } from '../../Utilities/showToastNotification';
import { orderPopUpTranslations } from '../../Translations/orderPopUpTranslations';
import { getDateWithTimeZone, utcTotimezoneDate } from '../../Utilities/functions';
// Types
import { CreditCard } from '../../Interfaces/Common/common';
import { Cuisine } from '../../Interfaces/PayloadsAndResponses/Cuisines';
import { DeliveryRequestPayload } from '../../Interfaces/PayloadsAndResponses/Schedules';
import { LocalStorageCompany, User, UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
import { OrderCustomFields, PlaceOrderPayload } from '../../Interfaces/PayloadsAndResponses/Orders';
import { Addresses, CartOrderConfirmation, CompanyData, Errors, OrderDetails, ReduxRootState, SchedulesDetails, ServerCartData } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { FoodItemDietary, FoodItem, FoodItemDishsize, FoodItemNutrition, FoodItemIngredient, FoodItemOptionSet, RecentOrder } from '../../Interfaces/PayloadsAndResponses/Menu';
import { DeliveryFeePayload, CartDetails, ItemDataType, CartSummary, OrderType, PlaceCartOrderPayload, UpdateCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
// Accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './css/OrderPopup.css';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '0',
    background: 'none',
    border: 'none',
    borderRadius: '18px'
  }
};
const emptyCartPopUpStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '570px',
    width: '90vw',
    padding: '0',
    background: 'none',
    border: 'none',
    borderRadius: '18px'
  }
};
const customStylesTwoCol = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '800px',
    width: '90vw',
    padding: '0',
    background: 'none',
    border: 'none',
    borderRadius: '18px'
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

type Params = { date: string, meetingSlug?: string };

type OrderPopupProps = {
  // Component Own Props
  // onDashboard: boolean;
  invitedMeeting?: boolean;
  token?: string;
  setState: (newState: any) => void;
  onRef: (orderPopupRef: OrderPopupRef | null) => void;
  meetingMealBudget: undefined | string;
  remaining_budget: undefined | string;
  onUpdatePage: () => void;
  onUpdateOrder: () => void;
  onClose?: () => void;
  openCart?: () => void;
  // Default Props
  userObj?: User;
  companyObj?: LocalStorageCompany | null;
  isAdmin?: boolean;
  isCompanyManager?: boolean;
  isCompanyUser?: boolean;
  isUnsubsidizedUser?: boolean;
  // MapStateToProps
  schedules_details: SchedulesDetails;
  order: OrderDetails
  errors: Errors,
  allowedRestaurantsIdArray: number[];
  company_data_reducer: CompanyData,
  i18n: WithTranslation | null;
  cuisines: Cuisine[] | null;
  isOpenPopupOnMenuDetailPage: boolean;
  // addressIdForDeliveryAt: any;
  selectedAddressID: number | null | undefined;
  deliveryAtforCart: any;
  timeZoneforCart: any;
  // Dispatch Props
  getSingleOrder: (id: number, token?: string) => void;
  getDeliveryDates: (from?: string, to?: string) => void;
  updateSingleOrder: (rating: PlaceOrderPayload, id: number, token?: string) => void;
  getCompanyForOrderPurpose: (token?: string, meetingSlug?: string) => void;
  placeOrder: (orderData: PlaceOrderPayload, currentMeetingSlugOrId: number, orderDeliveryDate: string, token?: string) => void;
  makeDeliveryRequest: (requestData: DeliveryRequestPayload, updataeDeliveryDates: boolean | undefined, cloneOrdersIds?: number[], createNewScheduleCallback?: (() => void) | undefined) => void;
  addToCart: (itemData: ItemDataType, cartData: CartDetails, deliveryFee: DeliveryFeePayload, cartAddressIds: number[], isOrderForConfirmation?: boolean, emptyCartNotification?: (() => void) | undefined, itemAddedToCart?: (() => void) | undefined, loaderHandle?:(() => void | undefined)) => void;
  // getDeliveryFee: (deliveryFee: DeliveryFeePayload) => void;
  cartItems: ItemDataType[];
  cartSummary: CartSummary;
  cartDetailsLocal: CartDetails;
  cartAddressIds: number[];
  cartAddressIdsLocal: number[];
  confirmedCartOrderData: PlaceCartOrderPayload;
  cartOrderForConfirmation: CartOrderConfirmation;
  confirmedItemName: string;
  serverCart: ServerCartData;
  fromPublicMarketPlace?: boolean;
  fromPublicRestaurantMenu?: boolean;
  currentMeetingAddressId?: string | number;
  currentMeetingDeliveryAt?: string;
  placeCartOrder: (orderData: PlaceCartOrderPayload, headers: undefined, itemName?: string, emptyCartNotification?: (() => void) | undefined, itemAddedToCart?: (() => void) | undefined, loaderHandle?:(() => void | undefined), placeCartOrderSuccessHandler?:((res: any) => void | undefined)) => void;
  updateCartOrder: (updateCartData: UpdateCartOrderPayload, slug: string) => void;
  onItemAddedToCart?: () => void;
  showCartNotification?: (itemImage: string | null, itemName: string) => void;
  loader?:boolean;
  addresses: Addresses,
} & RouteComponentProps<Params>;

export type OrderPopupState = {
  admin_cutoff_at: string,
  same_day_admin_cutoff_at: string;
  same_day_activation_at: string;
  best_seller: 0 | 1,
  companyData: GetloggedInUser['loggedInUserCompany'],
  company_user_credit_card: null | CreditCard,
  company_user_credit_card_token_id: null | string,
  currentMeetingSlugOrId: null | string | number,
  calories: number,
  count: number,
  cutoff_at: string,
  same_day_cutoff: string;
  deliveryAt: string | Date,
  dietaries: FoodItemDietary[],
  dishsizes: FoodItemDishsize[] | null,
  dishsize_id: null | number,
  field_required: boolean,
  firstPopupState: boolean,
  foodItemNutritions: FoodItemNutrition[],
  guestInfo: typeof orderGuestInfo,
  ingredients: FoodItemIngredient[],
  initialPrice: number,
  isActiveAdditionalOptionsTab: boolean,
  isActiveNutritionalFactsTab: boolean,
  itemDescription: string,
  itemID: number | null,
  itemImage: string | null,
  itemImageSmall: string | null,
  isGuestOrder: boolean,
  itemPrice: number,
  itemName: string,
  itemAddress: string;
  itemSalesTaxPercentage: number;
  itemOptionsSet: FoodItemOptionSet[],
  loading: boolean,
  marketPlacePopUp: boolean,
  meal_type: string,
  menu_type: string,
  modalBodyHeight: null | number,
  modalIsOpen: boolean,
  model_type: 'edit' | 'add',
  NetPrice: number,
  newOrderCreated: boolean,
  orderFieldAttributes: OrderCustomFields[],
  orderID: number,
  orderPrice: number,
  orderPlaced:boolean,
  showPaymentSection: boolean,
  per_user_copay: 0 | 1,
  per_user_copay_amount: string,
  remarks: string,
  restaurants_name: string,
  selectedRestaurants: number[],
  serve_count: number,
  skip_markup: boolean,
  spicy: 0 | 1,
  submitSchedule: boolean,
  selectedCompanyAddressID: undefined | null | number,
  success: string,
  userPaidAmount: number,
  average_rating: number,
  expanded: 'additional-opt-panel' | 'additonal-details-panel' | 'nutrition-facts-panel' | boolean,
  showConfirmEmptyCartPopUp: boolean,
  loader: boolean,
  isLoadingForSavingGuestData: boolean,
  addUtensils: boolean,
  editUtensils: boolean,
  orderFieldAttributesRequiredCheck: boolean,
  allowCustomization: boolean | undefined,
}

const orderGuestInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  phoneNumberError: ''
}

const { loggedInUserCompany, loggedInUser, isCompanyAdmin, isCompanyManager, isCompanyUser, isUnsubsidizedUser } = getloggedInUser();
class OrderPopup extends Component<OrderPopupProps, OrderPopupState> {
  orderDataLocal = JSON.parse(localStorage.getItem('order_data_confirmation') || '{}');

  static defaultProps = {
    userObj: loggedInUser,
    companyObj:  JSON.parse(localStorage.getItem('company') || '{}'),
    isAdmin: isCompanyAdmin,
    isCompanyManager,
    isUnsubsidizedUser,
    isCompanyUser
  };

  stripePaymentRef = createRef<StripeLayoutRef>().current;
  scheduleForm = createRef<ScheduleDeliveryFormRef>().current;
  confettiCanvasRef = createRef<HTMLCanvasElement>();
  ccOrderingRef = React.createRef<any>();

  state: OrderPopupState = {
    admin_cutoff_at: '',
    same_day_admin_cutoff_at: '',
    same_day_activation_at: '',
    best_seller: 0,
    companyData: loggedInUserCompany,
    company_user_credit_card: null,
    company_user_credit_card_token_id: null,
    currentMeetingSlugOrId: null,
    calories: 0,
    count: 1,
    cutoff_at: '',
    same_day_cutoff: '',
    deliveryAt: '',
    dietaries: [],
    dishsizes: null,
    dishsize_id: null,
    field_required: false,
    firstPopupState: true,
    foodItemNutritions: [],
    guestInfo: orderGuestInfo,
    ingredients: [],
    initialPrice: 0,
    isActiveAdditionalOptionsTab: false,
    isActiveNutritionalFactsTab: false,
    itemDescription: '',
    itemID: null,
    itemImage: '',
    itemImageSmall: '',
    isGuestOrder: false,
    itemPrice: 0,
    itemName: '',
    itemOptionsSet: [],
    itemAddress: '',
    itemSalesTaxPercentage: 0,
    loading: false,
    marketPlacePopUp: false,
    meal_type: '',
    menu_type: '',
    modalBodyHeight: null,
    modalIsOpen: false,
    model_type: 'edit',
    NetPrice: 0,
    newOrderCreated: false,
    orderFieldAttributes: [],
    orderID: 0,
    orderPrice: 0.0,
    orderPlaced: false,
    showPaymentSection: false,
    per_user_copay: 0,
    per_user_copay_amount: '0.0',
    remarks: '',
    restaurants_name: '',
    selectedRestaurants: [],
    serve_count: 1,
    skip_markup: false,
    spicy: 0,
    submitSchedule: false,
    selectedCompanyAddressID: null,
    success: '',
    userPaidAmount: 0.0,
    average_rating: 0,
    expanded: false,
    showConfirmEmptyCartPopUp: false,
    loader: false,
    isLoadingForSavingGuestData: false,
    addUtensils: false,
    editUtensils: false,
    orderFieldAttributesRequiredCheck: false,
    allowCustomization: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps: OrderPopupProps) {
    // Previous Props Destructring;
    const {
      isAdmin,
      isCompanyManager,
      isCompanyUser,
      isUnsubsidizedUser,
      invitedMeeting,
      order: { new_order_request_status, update_single_order_request, view_single_order },
      company_data_reducer: { company_detail_for_order_purpose }
    } = this.props;
    // Next Props Destructring;
    const {
      company_data_reducer: { company_detail_for_order_purpose: next_company_for_order_purpose } = {},
      errors: next_errors,
      schedules_details: { new_schedule_request_status: next_new_schedule_request_status },
      order: { new_order_request_status: next_new_order_request_status, update_single_order_request: next_update_single_order_request, view_single_order: next_view_single_order },
    } = nextProps;

    // When New Schedule is Created
    if (next_new_schedule_request_status && this.state.submitSchedule) {
      this.setState({ submitSchedule: false, currentMeetingSlugOrId: next_new_schedule_request_status.schedule.slug })
      setTimeout(() => {
        this.onSubmit();
      }, 1);
    }
    if (next_company_for_order_purpose && invitedMeeting) {
      if (next_company_for_order_purpose.company) {
        this.setState({ companyData: next_company_for_order_purpose.company });
      }
    }
    let userPaid = 0;
    if (this.state.per_user_copay === 1) {
      userPaid = (document.getElementById('buybutton') && +document.getElementById('buybutton')!.innerText!.split('$')[1]) || 0;
    }
    // Kept for future reference in case of any issue
    // if (next_company_for_order_purpose && !isAdmin && this.state.modalIsOpen) {
    //   !this.state.company_user_credit_card && this.setState({ company_user_credit_card: next_company_for_order_purpose.card_details });
    //   if ((next_company_for_order_purpose.customer === false && this.state.per_user_copay === 1 && userPaid > 0) || (next_company_for_order_purpose.customer === false && this.state.per_user_copay === 1 && (userPaid === '0.0' || +userPaid === 0) && next_company_for_order_purpose.pending && next_company_for_order_purpose.pending !== '0.0')) {
    //     this.setState({ showPaymentSection: true });
    //   }
    // }
    if (next_company_for_order_purpose && next_company_for_order_purpose !== company_detail_for_order_purpose && !isAdmin && this.state.modalIsOpen) {
      const showPaymentSection = isUnsubsidizedUser || (this.state.per_user_copay === 1 && userPaid > 0);
      this.setState({ showPaymentSection, company_user_credit_card: next_company_for_order_purpose.card_details })
    // Disable background scroll
      // document.body.style.overflow = 'hidden';
    }

    if ((next_new_order_request_status && next_new_order_request_status !== new_order_request_status && this.state.modalIsOpen === true)
      || (next_update_single_order_request && next_update_single_order_request !== update_single_order_request && this.state.modalIsOpen === true)) {
    // Disable background scroll
      document.body.style.overflow = 'hidden';
      this.setState({ loading: false })
      if (next_new_order_request_status || next_update_single_order_request) {
        this.setState({ loading: false, newOrderCreated: true });

        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('os', 'thankyou');
        this.props.history.replace({ search: searchParams.toString() });

        // $('.modal-content').animate({ 'height': '335px' });
        setTimeout(() => {
          let modalContent = document.getElementsByClassName('modal-content')[0] as HTMLElement;
          if (modalContent) {
            modalContent.style.height = '100%';
          }
        }, 500);
        // if (this.props.onDashboard === true) {
        this.props.onUpdatePage();
        this.props.onUpdateOrder();
        if (next_company_for_order_purpose?.customer === false && (isCompanyManager || isCompanyUser)) {
          let updatedCompany = next_company_for_order_purpose;
          updatedCompany.customer = true;
          store.dispatch({
            type: GET_COMPANY_FOR_ORDER_PURPOSE,
            payload: updatedCompany
          })
        }
        // }
      } else {
        document.body.style.overflow = 'unset';
        this.closeModal();
        showToastNotification('Try again!', 'Oops!');
      }
    } else if (view_single_order !== next_view_single_order && next_view_single_order) {
      const { fooditem, ...order } = next_view_single_order
      const {
        custom_fields: fields,
        guest,
        admin_cutoff_at,
        cutoff_at,
        id: order_id,
        remarks,
        user_paid
      } = order || {};

      fields.forEach((key) => {
        if (key.field_type === 'dropdown') {
          key.options.map(opts => {
            if (opts.selected) {
              key.value = `${opts.id}`;
            }
            return false;
          })
        }
        return false;
      })
      setTimeout(() => {
        if (user_paid) {
          this.setState({ userPaidAmount: parseFloat(user_paid) - parseFloat(`${this.state.NetPrice}`) });
        }
      }, 200);
      this.setState({
        orderFieldAttributes: fields,
        dishsizes: fooditem.dishsizes ?? null,
        remarks: remarks,
        admin_cutoff_at: admin_cutoff_at,
        cutoff_at: cutoff_at,
        userPaidAmount: parseFloat(
          user_paid
        ),
        isGuestOrder: !isEmpty(guest),
        guestInfo: {
          ...orderGuestInfo,
          firstName: guest?.first_name ?? '',
          lastName: guest?.last_name ?? '',
          email: guest?.email ?? '',
          phoneNumber: guest?.phone_number ?? '',
        },
      });
      this.openModalWithItem(order, fooditem, order_id, this.state.selectedCompanyAddressID ?? undefined, this.state.currentMeetingSlugOrId ?? undefined);
    }
    // Display errors occors on order submissions.
    if (next_errors && next_errors.errors) {
      this.closeModal();
      this.setState({ loading: false })
      if (this.state.marketPlacePopUp) {
        showToastNotification(next_errors.errors, 'Sorry!', true);
      } else {
        showToastNotification(next_errors.errors, 'Oops!');
      }
      store.dispatch({ type: 'CLEAR_ERRORS', payload: null });
    }
    let editOrderData = this.props.order.delivery_orders_details?.orders?.filter((order:any) => order.id === this.state.orderID);
    if(this.state.model_type === 'edit'){
      editOrderData && this.setState({ editUtensils: editOrderData[0]?.utensils })
    }
    const orderFieldAttributes = this.scheduleForm?.state?.orderFieldAttributes;
    const hasRequiredEqualTo1: boolean = orderFieldAttributes ? orderFieldAttributes?.some((attribute) => attribute.required === 1) : false;
    this.setState({ orderFieldAttributesRequiredCheck: hasRequiredEqualTo1 })
  }

  componentDidMount() {
    // Clear Previous page error to avoid duplicate toast notification on Menu Detail page.
    store.dispatch({ type: 'CLEAR_ERRORS', payload: null });
    orderPopUpTranslations();
    this.props.onRef(this);

    // Disable background scroll
    if(this.state.modalIsOpen){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    let editOrderData = this.props.order.delivery_orders_details?.orders?.filter((order:any) => order.id === this.state.orderID);
    if(this.state.model_type === 'edit'){
      editOrderData && this.setState({editUtensils: editOrderData[0]?.utensils })
    }

    !isEmpty(this.orderDataLocal) && this.emptyCartNotification();
  }
  
  componentWillUnmount() {
    this.props.onRef(null)
    document.body.style.overflow = 'unset';
  }

  loaderForGuestInfo = () => {
    this.setState({ isLoadingForSavingGuestData: true });

    setTimeout(() => {
      this.setState({ isLoadingForSavingGuestData: false });
    }, 1000); 
  }

  handleButtonClickInParent = () => {
    // Trigger a click event on the child element using the ref
    this.state.orderPlaced && this?.ccOrderingRef?.current?.click();
  };

  loaderHandle = () =>{
    this.setState({loader: false});
    this.closeModal()
  }
  openModal = () => {
    this.setState({ modalIsOpen: true });
    // Disable background scroll
    document.body.style.overflow = 'hidden';
    
    let { token } = this.props;
    if (token) {
      this.props.getCompanyForOrderPurpose(token);
    } else {
      !this.props.fromPublicMarketPlace &&
        this.props.getCompanyForOrderPurpose(token, this.props.match.params.meetingSlug);
    }
  }
  afterOpenModal = () => {
    this.getPrices();
  }
  closeModal = () => {
    // !isEmpty(this.orderDataLocal) && localStorage.removeItem('order_data_confirmation')
    document.body.style.overflow = 'unset';
    // now this not required because food preferences modal is no more there.
    // this.props.isOpenPopupOnMenuDetailPage && store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: false });
    const { company_detail_for_order_purpose } = this.props.company_data_reducer;
    store.dispatch({
      type: UPDATE_COMPANY_FOR_ORDER_PURPOSE,
      payload: { ...company_detail_for_order_purpose, duplicate_order_exists: false }
    });
    this.setState({
      admin_cutoff_at: '',
      company_user_credit_card: null,
      company_user_credit_card_token_id: null,
      dishsizes: null,
      dishsize_id: null,
      guestInfo: orderGuestInfo,
      isGuestOrder: false,
      menu_type: '',
      marketPlacePopUp: false,
      modalIsOpen: false,
      newOrderCreated: false,
      showPaymentSection: false,
      remarks: '',
      serve_count: 1,
      isActiveAdditionalOptionsTab: false,
      isActiveNutritionalFactsTab: false,
      foodItemNutritions: [],
      showConfirmEmptyCartPopUp: false,
      loader: false,
      addUtensils: false,
      orderPlaced: false,
    });
  }
  
  setOrderConfirmModal = () => {
    store.dispatch({ type: SET_CONFIRM_ORDER_POPUP_STATE, payload: false });
  }
  openModalEdit(id: number) {
    this.props.getSingleOrder(id);
  }
  getInvitedMeetingOrder(id: number, token: string) {
    this.props.getSingleOrder(id, token);
  }

  openModalWithItem(order: RecentOrder | null, fooditem: FoodItem, order_id: number, address_id: number | undefined, currentMeetingSlugOrId: number | string | undefined, per_user_copay_amount?: string, isRecentOrder: boolean = false) {

    let dishsizes = fooditem?.dishsizes, selectedDishSize = null, selectedDishSizeServeCount = 1;
    dishsizes && dishsizes.forEach(dish => {
      if (dish.selected === true) {
        selectedDishSize = dish.id;
        selectedDishSizeServeCount = dish.serve_count
      } else {
        dish.selected = false;
      }
    })
    // const foodItemNutritions = fooditem?.nutritions ?? [];
    const foodItemNutritions = fooditem?.nutritions ?? [];
    const caloriesCount = foodItemNutritions?.find(nutrition => nutrition?.name === 'Calories');

    this.setState({
      itemID: fooditem.id,
      itemName: fooditem.name,
      itemDescription: fooditem.description,
      itemOptionsSet: fooditem.optionsets,
      itemPrice: fooditem.price,
      itemImage: fooditem.image.medium.url,
      itemImageSmall: fooditem.image.thumb.url,
      itemAddress: fooditem.address_line,
      itemSalesTaxPercentage: fooditem.sales_tax,
      orderID: order_id,
      selectedCompanyAddressID: address_id,
      currentMeetingSlugOrId: currentMeetingSlugOrId ?? null,
      count: (order && order.quantity && !isRecentOrder) ? order.quantity : 1,
      dietaries: fooditem.dietaries,
      ingredients: fooditem.ingredients,
      restaurants_name: fooditem.restaurant_name,
      foodItemNutritions: foodItemNutritions ?? [],
      //foodItemNutritions: [{id: 845802, name: "Calories", value: 300, childs: [{id: 112, name: "Calory", value: 300}]},
      //{id: 845801, name: "Vitamins", value: 30, childs: [{id: 112, name: "vitamin D", value: 10}, {id: 112, name: "vitamin E", value: 20}]}],

      best_seller: fooditem.best_seller,
      spicy: fooditem.spicy,
      calories: parseFloat(`${caloriesCount?.value || 0}`),
      skip_markup: this.props.fromPublicMarketPlace? true : fooditem.skip_markup,
      orderPrice: (order && !isRecentOrder) ? +order.price : 0.0,
      per_user_copay_amount: per_user_copay_amount ?? '0.0',
      dishsizes: dishsizes ?? null,
      initialPrice: 0,
      firstPopupState: true,
      // To Handle Dishsize for Already Created Orders or its clones
      dishsize_id: selectedDishSize,
      serve_count: selectedDishSizeServeCount,
      average_rating: fooditem.average_rating,
      allowCustomization: fooditem?.allow_customizations
    });
    this.openModal();
  }

  round = (num: number | string) => {
    const numberInput = parseFloat(`${num}`);
    //let formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })
    if (numberInput % 1 === 0) {
      return numberInput;
    }
    return +(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(numberInput))
  }
  disableOptionsAfterMaxSelection = (optionSetId: number, endLimit: number, optionSetOptionId: number) => {
    let options: NodeListOf<HTMLInputElement> = document.querySelectorAll(`.getSelectedOptionSet${optionSetId} .fooditem-optionsets-option`);
    let selected = 0;
    let isSelected = false;
    options?.forEach(optionInput => {
      if (optionInput.checked) {
        if (+optionInput.id === +optionSetOptionId) {
          isSelected = true;
        }
        selected = selected + 1
      }
    })
    // Object.keys(options).forEach(function (key, index) {
    //   if (options[key].checked) {
    //     if (parseFloat(options[key].id) === optionSetOptionId) {
    //       isSelected = true;
    //     }
    //     selected = selected + 1
    //   }
    // })
    return ((selected >= endLimit) && (!isSelected)) ? true : false;
  }
  getPrices = () => {
    this.setState({ orderPlaced: true })
    if(!isCompanyAdmin){
      this.handleButtonClickInParent()
    }
    let prices: NodeListOf<HTMLInputElement> = document.querySelectorAll('.ReactModalPortal .fooditem-optionsets-option');
    let Net_Price = 0;
    prices?.forEach(priceInput => {
      if (priceInput.checked) {
        Net_Price = Net_Price + parseFloat(priceInput.value);
      }
    })
    // Object.keys(prices).forEach(function (key, index) {
    //   if (prices[key]?.checked) {
    //     Net_Price = Net_Price + parseFloat(prices[key].value);
    //   }
    // })
    this.setState({ NetPrice: Net_Price, firstPopupState: false });
    this.setState({ initialPrice: Net_Price });

  }
  calculatePrice(markup: string | number, skip_markup?: boolean) {
    const { firstPopupState, initialPrice, companyData, itemPrice } = this.state;
    let Net_Price = 0.0;
    if (firstPopupState === false) {
      let prices: NodeListOf<HTMLInputElement> = document.querySelectorAll('.ReactModalPortal .fooditem-optionsets-option');
      prices.forEach(optionInput => {
        if (optionInput.checked) {
          Net_Price += parseFloat(optionInput.value);
        }
      })
      // Object.keys(prices).forEach(function (key) {
      //   if (prices[key].checked) {
      //     Net_Price += parseFloat(prices[key].value);
      //   }
      // })
    } else {
      Net_Price = parseFloat(`${initialPrice}`);
    }
    if (skip_markup || companyData.enable_saas === true) {
      return (itemPrice ? parseFloat(`${itemPrice}`) : 0) + Net_Price;
    } else {
      return (itemPrice ? parseFloat(`${itemPrice}`) : 0) + Net_Price + parseFloat(`${markup}`);
    }
  }
  calculateEditPrice() {
    const { model_type, companyData, skip_markup, itemPrice, orderPrice } = this.state;
    if (model_type === 'add') {
      return ((companyData.reduced_markup_check === true) && (!skip_markup) && (!companyData.enable_saas)) ?
        this.getPricesToShown(itemPrice, skip_markup) - (+companyData.markup * (companyData.reduced_markup / 100)) :
        this.getPricesToShown(itemPrice, skip_markup);
    } else {
      // it will return difference of order price and current price after setting up options
      return this.round(((companyData.reduced_markup_check === true) && (!skip_markup) && (!companyData.enable_saas)) ?
        this.getPricesToShown(itemPrice, skip_markup) - (+companyData.markup * (companyData.reduced_markup / 100)) - orderPrice :
        (this.getPricesToShown(itemPrice, skip_markup) - orderPrice));
    }
  }
  getPricesToShown(itemPrice: number, skip_markup: boolean) {
    const fooditemPrice = parseFloat(`${itemPrice}`);
    const { meetingMealBudget } = this.props;
    const { marketPlacePopUp, companyData } = this.state;
    let optionPrices = this.calculatePrice(companyData.markup, skip_markup);
    let reducedPrice: number = 0
    if (marketPlacePopUp === true) {
      reducedPrice = getPricesToShown(fooditemPrice, skip_markup, companyData, parseFloat(companyData.user_meal_budget));
    } else {
      reducedPrice = getPricesToShown(fooditemPrice, skip_markup, companyData, parseFloat(meetingMealBudget!));
    }
    if ((!skip_markup) && (!companyData.enable_saas)) {
      return this.round(reducedPrice) + parseFloat((optionPrices - parseFloat(`${companyData.markup}`) - fooditemPrice).toString());
    } else {
      return this.round(reducedPrice) + parseFloat((optionPrices - fooditemPrice).toString());
    }
  }
  getPriceWithCoPay = (price: number) => {
    const { remaining_budget, meetingMealBudget } = this.props;
    const { per_user_copay_amount, showPaymentSection, NetPrice, model_type, per_user_copay } = this.state;
    const userPaidAmount = parseFloat(`${this.state.userPaidAmount}`) // To 
    let priceWithCopay = 0;
    if (per_user_copay && model_type !== 'edit') {
      if (parseFloat(per_user_copay_amount) >= 0) {
        if (remaining_budget !== undefined && parseFloat(remaining_budget) >= parseFloat(`${price}`)) {
          priceWithCopay = parseFloat(per_user_copay_amount);
        } else {
          if (remaining_budget !== undefined && parseFloat(remaining_budget) > 0 && parseFloat(remaining_budget) > parseFloat(per_user_copay_amount)) {
            priceWithCopay = parseFloat(`${price}`) - parseFloat(remaining_budget);
            priceWithCopay = priceWithCopay + parseFloat(per_user_copay_amount);
          } else {
            priceWithCopay = price;
          }
          // if (company && priceWithCopay < company.per_user_copay_amount) { // This Key Does not exist in Company Object but was using 
          //   priceWithCopay = price;
          // }
        }
      } else {
        if (meetingMealBudget && price > parseFloat(meetingMealBudget)) {
          priceWithCopay = price - parseFloat(meetingMealBudget)
        } else {
          priceWithCopay = 0;
        }
      }
      // if (Number(priceWithCopay) && this.props.company_data_reducer?.company_detail_for_order_purpose?.customer === false && !showPaymentSection) {
      if (Number(priceWithCopay) && !showPaymentSection) {
        this.setState({ showPaymentSection: true })
      }
      if (!Number(priceWithCopay) && showPaymentSection) {
        this.setState({ showPaymentSection: false });
      }
    } else if (per_user_copay && remaining_budget && parseFloat(remaining_budget) > 0) {
      // handle cases when remaining budged left and user try to update with some options
      let optionsPriceDiffRemBud = NetPrice - parseFloat(remaining_budget);
      if (optionsPriceDiffRemBud > 0) {
        priceWithCopay = (userPaidAmount + optionsPriceDiffRemBud) >= 0 ? (userPaidAmount + optionsPriceDiffRemBud) : 0.0;
      } else {
        priceWithCopay = (userPaidAmount + NetPrice) >= 0 ? (userPaidAmount) : 0.0;
      }
    } else {
      priceWithCopay = (userPaidAmount + NetPrice) >= 0 ? (userPaidAmount + NetPrice) : 0.0;
    }
    const foodItemPriceAfterAddingStripeFee = (!priceWithCopay || (Number(priceWithCopay).toFixed(2) === '0.00')) ? priceWithCopay : ((+priceWithCopay + .30) / (1 - .029)).toFixed(2); // Formula For Adding Stripe Fee
    return this.round(foodItemPriceAfterAddingStripeFee);
  }
  checklimits() {
    let check_error = false;
    this.state?.itemOptionsSet?.forEach(option => {
      let count_checked = 0;
      option?.options?.forEach(value => {
        let optionElement = document.getElementById(`${value?.id}`) as HTMLInputElement | undefined;
        if (optionElement && optionElement.checked) {
          count_checked++;
        }
      })
      if (!(option.start_limit === 0 && option.end_limit === 0)) {
        if (!(option.start_limit <= count_checked && count_checked <= option.end_limit)) {

          document.getElementById(`order-${option.id}`)?.classList.add('required-error-highlight');
          if (check_error === false) {
            const tesNode = ReactDOM.findDOMNode(document.getElementById(`order-${option.id}`)) as HTMLElement;
            $('#order-popup-body')?.animate({ scrollTop: tesNode.offsetTop }, 1000);
          }
          check_error = true;
        } else if (document.getElementById(`order-${option.id}`)?.classList.contains('required-error-highlight')) {
          document.getElementById(`order-${option.id}`)?.classList.remove('required-error-highlight');
        }
      }
    })
    let specialReqElement = document.getElementById('special_requests') as HTMLTextAreaElement;
    let specialDescription = document.getElementById('special') as HTMLHeadingElement;
    if ((specialReqElement?.value?.length) > 255) {
      specialDescription.innerHTML = 'Not More Than 250 Characters';
      specialDescription?.classList.add('required-error-highlight');
      if (check_error === false) {

        const tesNode = ReactDOM.findDOMNode(specialDescription) as HTMLHeadingElement;
        $('#order-popup-body')?.animate({ scrollTop: tesNode.offsetTop }, 1000);
      }
      check_error = true;
    }
    else if (specialDescription?.classList.contains('required-error-highlight')) {
      specialDescription?.classList.remove('required-error-highlight');
    }
    return check_error;
  }

  validateCustomFields() {
    let requiredCustomFields = true;
    if (this.state.field_required && this.props.userObj?.user_type === UserType.ADMIN) {
      this.scheduleForm?.state.orderFieldAttributes.forEach(field => {
        if (field.required === 1) {
          if (!field.value && requiredCustomFields) {
            requiredCustomFields = false;
            document.getElementById(`custom-${field.id}`)?.classList.add('required-error-highlight');
            this.setState({ expanded: 'additonal-details-panel'})
            $('#order-popup-body').animate({ scrollTop: 1000 }, 1000);
          } else if (document.getElementById(`custom-${field.id}`)?.classList.contains('required-error-highlight')) {
            document.getElementById(`custom-${field.id}`)?.classList.remove('required-error-highlight');
            this.setState({ expanded: false})
          }
        }
      });
    }
    return requiredCustomFields;
  }

  // compare two arrays
  compareAddressIds = (cartAddressIdsLocal: number[], cartAddressIds: number[]) =>
  cartAddressIdsLocal.length === cartAddressIds.length &&
  cartAddressIdsLocal.every((element, index) => element === cartAddressIds[index]);

  // in case of Public Marketplace add to cart feature
  addToCart = () => {
    const {
      count,
      itemID,
      itemName,
      itemImage,
      itemDescription,
      itemPrice,
      itemAddress,
      itemSalesTaxPercentage,
      remarks,
      orderID,
      skip_markup,
      itemOptionsSet,
      deliveryAt,
      showConfirmEmptyCartPopUp
    } = this.state;

    const {
      cartSummary,
      cartItems,
      cartAddressIds,
      cartAddressIdsLocal,
      cartDetailsLocal,
      addToCart,
      // onItemAddedToCart,
      // showCartNotification,
      companyObj,
      placeCartOrder,
      match: { params: { date } },
      addresses,
      selectedAddressID
      // updateCartOrder,
      // serverCart: {
        // pending_cart_exist
        //delivery_at,
        // id, 
        // slug,
        // total, orders_quantity
      // }
    } = this.props;
    
    const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
    const companyData = companyObj ?? loggedInUserCompany
    if (!itemID) { // If Fooditem is not Present then RETURN Because without Fooditem ID order cannot be placed.
      return false;
    }
    
    let itemData: ItemDataType = {
      itemID: +itemID,
      itemName,
      itemImage,
      itemDescription,
      itemOptionsSet,
      itemPrice,
      itemPriceWithOptions: +(parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString())).toFixed(2),
      itemAddress,
      itemSalesTaxPercentage,
      quantity: count,
      optionsets_orders_attributes: [],
      remarks: remarks,
      utensils: this.state.addUtensils
    }

    const selectedAddress = addresses.comp_addresses?.addresses?.find((address) => address?.id === selectedAddressID);

    let isoDateWithOffsetTimezone;
    const utcToTimeZoneDate = utcTotimezoneDate(this.props?.currentMeetingDeliveryAt ?? date, this?.props?.timeZoneforCart ? this?.props?.timeZoneforCart : localAddress?.time_zone).toString()
    const extractedDateAndTime = utcToTimeZoneDate?.split(':00')?.[0]

    isoDateWithOffsetTimezone = getDateWithTimeZone(deliveryAt ? deliveryAt : extractedDateAndTime, selectedAddress?.time_zone ? selectedAddress?.time_zone : localAddress?.time_zone) 



    const cartDetails: CartDetails = !isEmpty(cartDetailsLocal) ?
      !this.compareAddressIds(cartAddressIdsLocal, cartAddressIds) ?
        {
          ...cartDetailsLocal,
          itemCount: count,
          // deliveryDate: `${dayjs(isoDateWithOffsetTimezone).format('YYYY-MM-DD')}T${dayjs(deliveryAt).format('HH:mm')}`,
          deliveryDate: isoDateWithOffsetTimezone,
          deliveryAddress: localAddress
        } : cartDetailsLocal
      : {
          name: 'New Delivery',
          itemCount: count,
          cuttoff: '',
          // deliveryDate: `${dayjs(deliveryAt).format('YYYY-MM-DD')}T${dayjs(deliveryAt).format('HH:mm')}`,
          deliveryDate: isoDateWithOffsetTimezone,
          deliveryAddress: localAddress
        }

    const order: NodeListOf<HTMLInputElement> = document.querySelectorAll('.ReactModalPortal .fooditem-optionsets-option');

    let order_selected_fields: any = [], order_selected: any = [], option_set_order_id: any = [];
    if (orderID > 0) {
      const optionSets = itemOptionsSet;
      (optionSets ?? []).forEach(foodItemOptionSet => {
        foodItemOptionSet.options.forEach(foodOptionSetOption => {
          if (foodOptionSetOption.selected) {
            order_selected_fields[foodOptionSetOption.id] = foodOptionSetOption.optionsorder_id;
          }
          option_set_order_id[foodOptionSetOption.id] = foodItemOptionSet.optionsetorder_id;
        });
        order_selected[foodItemOptionSet.id] = foodItemOptionSet.optionsetorder_id
        return false;
      });
    }
    let optionSetArray: any = [];
    let curOptionSetID: any = null;
    let d: any;
    (order ?? []).forEach(optionInput => {
      if (optionInput?.checked && !order_selected_fields[optionInput.id]) {
        //adding new fields
        if (curOptionSetID === optionInput.title) {
          d.options_orders_attributes.push({ option_id: optionInput.id });
        } else {
          if (d) {
            optionSetArray = optionSetArray.concat(d);
          }
          d = { id: option_set_order_id[optionInput.id], optionset_id: optionInput.title, required: optionInput.src, options_orders_attributes: [{ option_id: optionInput.id }] }
          curOptionSetID = optionInput.title
        }
      } else if (!optionInput.checked && order_selected_fields[optionInput.id]) {
        //destroying old fields
        const main_id = optionInput.title;
        if (curOptionSetID === optionInput.title) {
          d.options_orders_attributes.push({ option_id: optionInput.id, _destroy: 1, id: order_selected_fields[optionInput.id] });
        } else {
          if (d) {
            optionSetArray = optionSetArray.concat(d);
          }
          d = { optionset_id: optionInput.title, required: optionInput.src, id: order_selected[main_id], options_orders_attributes: [{ option_id: optionInput.id, _destroy: 1, id: order_selected_fields[optionInput.id] }] }
          curOptionSetID = optionInput.title
        }
      }
    })
    optionSetArray = optionSetArray.concat(d);
    if (d && optionSetArray.length > 0) {
      itemData.optionsets_orders_attributes = Object.assign({}, optionSetArray);
    }

    // Prepare DeliveryFee Payload
    type route = {route_to_text: string}
    const routes: route[] = [];

    const itemAddressExist = cartItems.find(a => a.itemAddress === itemData.itemAddress)
    if (!this.compareAddressIds(cartAddressIdsLocal, cartAddressIds)) {
      routes.push({route_to_text: itemAddress})
    } else {
      if (cartItems.length > 0) {
        !itemAddressExist && routes.push({route_to_text: itemAddress})
        const uniqueAddresses = Array.from(new Set(cartItems.map(item => item.itemAddress)))
          .map(address => { return cartItems.find(item => item.itemAddress === address)})
        uniqueAddresses.forEach(item => { item && routes.push({route_to_text: item?.itemAddress}) })
      } else {
        routes.push({route_to_text: itemAddress})
      }
    }
    routes.push({route_to_text: cartDetails?.deliveryAddress?.address_line ?? ''})

    const deliveryFeePayload: DeliveryFeePayload = {
      data: {
        delivery_at: `${dayjs(deliveryAt).format('YYYY-MM-DD')} ${dayjs(deliveryAt).format('HH:mm')}`, //"2022-12-31 06:03";
        // item_value: cartSummary?.itemsSubtotal ? (cartSummary.itemsSubtotal + (cartSummary.salesTax ?? 0)).toString()  : itemPrice.toString(),//"FOODITEM_TOTAL_PRICE";
        item_value: !this.compareAddressIds(cartAddressIdsLocal, cartAddressIds)?
          (itemData.itemPriceWithOptions * itemData.quantity).toString()
          : cartSummary?.itemsSubtotal ?
            (cartSummary?.itemsSubtotal + ((itemData.itemPriceWithOptions ?? 0) * itemData.quantity )).toString()
            : (itemData.itemPriceWithOptions * itemData.quantity).toString(),//"FOODITEM_TOTAL_PRICE";
        pack_size_id: "2",
        routes: routes
      }
    }

    //In case of self signup
    const orderAttributes: OrderType[] = []
    let itemObj: OrderType = {} as OrderType
    itemObj = {
      quantity: itemData.quantity,
      fooditem_id: itemData.itemID,
      optionsets_orders_attributes: itemData.optionsets_orders_attributes,
      remarks: 'no remarks',
      utensils: this.state.addUtensils,
    }
    orderAttributes.push(itemObj)

    const orderData: PlaceCartOrderPayload = {
      runningmenu: {
        runningmenu_name: cartDetails.name ?? '', 
        // delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
        // delivery_at: `${dayjs(deliveryAt ? deliveryAt : date).format('YYYY-MM-DD')}T${dayjs(deliveryAt ? deliveryAt : this.props.currentMeetingDeliveryAt).format('HH:mm')}`,
        delivery_at: getDateWithTimeZone(deliveryAt ? deliveryAt : extractedDateAndTime, selectedAddress?.time_zone ? selectedAddress?.time_zone : localAddress?.time_zone),
        // driver_tip: 0,
        orders_attributes: orderAttributes,
        address_ids: cartAddressIds,
        remove_previous_cart: ((companyData?.self_signup === true) && (showConfirmEmptyCartPopUp === true)) ? true : false,
        // address_id: (this.state.marketPlacePopUp === true ? this.props.addressIdForDeliveryAt : this.state.selectedCompanyAddressID) ?? '',
        address_id: (this.state.marketPlacePopUp === true ? this.props.selectedAddressID : this.state.selectedCompanyAddressID) ?? '',
      }
    }

    // const updateCartData:  UpdateCartOrderPayload = {
    //   runningmenu: {
    //     orders_attributes: [ {
    //       id: itemData.itemID,
    //       quantity: itemData.quantity,
    //       status: 'active'
    //     }
    //     ]
    //   }
    // }

    // if (!this.checklimits()) {
    //   //In case of self signup
    //   companyObj?.self_signup === true ?
    //     // slug?
    //       // updateCartOrder(updateCartData, slug)
    //     // :
    //     placeCartOrder(orderData, undefined, itemData.itemName, this.emptyCartNotification)
    //   : (cartAddressIdsLocal.length > 0 ? (
    //     this.compareAddressIds(cartAddressIdsLocal, cartAddressIds) ?
    //       addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds)
    //       : addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds, true, this.emptyCartNotification)
    //     ) : addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds)
    //     )
      
    //   onItemAddedToCart?.()
    //   // showCartNotification?.(itemData?.itemImage, itemData?.itemName)
    //   this.closeModal();
    // }

    if (!this.checklimits()) {
      this.setState ({loader:true})
      if(companyData?.self_signup === true) {
        placeCartOrder(orderData, undefined, itemData.itemName, this.emptyCartNotification, this.itemAddedToCart, this.loaderHandle);
        // this.closeModal();
      } else {
        if (cartAddressIdsLocal.length > 0) {
          if (this.compareAddressIds(cartAddressIdsLocal, cartAddressIds)) {
            addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds, false, () => { }, this.itemAddedToCart, this.loaderHandle)
            //this.closeModal();
          } else {
            addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds, true, this.emptyCartNotification, this.itemAddedToCart,this.loaderHandle)
          }
        } else {
          addToCart(itemData, cartDetails, deliveryFeePayload, cartAddressIds, false, () => { }, this.itemAddedToCart,this.loaderHandle);
          //this.closeModal();
        }
      }
    }

  }
  
  emptyCartNotification = () => {
    this.setState({showConfirmEmptyCartPopUp: true, modalIsOpen: true });
  }

  placeCartOrderSuccessHandler = (res: any) => {
    if(res.slug) {
      window.location.href = `/menu/${dayjs(res.delivery_at).format('M-D-YYYY')}/${res?.slug}`
    }
  }

  placeConfirmedCartOrder = () => {
    store.dispatch({
      type: CONTINUE_ORDERING_FOR_SAME_CART,
      payload: true
    })
    const companyDataCart = this.props.companyObj ?? loggedInUserCompany

    if(companyDataCart?.self_signup === true) {
      const {placeCartOrder} = this.props;
      if(!isEmpty(this.orderDataLocal)) {
        const OrderDataLocal = {
          runningmenu : {
            ...this.orderDataLocal.runningmenu,
            remove_previous_cart: true,
            address_id: this.props.currentMeetingAddressId,
            utensils: this.state.addUtensils,
          }
        };
        placeCartOrder?.(OrderDataLocal, undefined, undefined, undefined, undefined, undefined, this.placeCartOrderSuccessHandler);
        localStorage.removeItem('order_data_confirmation');
      } else {
        const {confirmedCartOrderData, confirmedItemName} = this.props;
        const OrderData = {runningmenu : { ...confirmedCartOrderData.runningmenu, remove_previous_cart: true}};
        placeCartOrder(OrderData, undefined, confirmedItemName);
        // this.setState({showConfirmEmptyCartPopUp: false,  modalIsOpen: false});
      }
      this.setState({showConfirmEmptyCartPopUp: false,  modalIsOpen: false});

    } else if(this.props.fromPublicMarketPlace) {
      const {cartOrderForConfirmation, addToCart} = this.props;
      store.dispatch({type: EMPTY_CART});
      addToCart(cartOrderForConfirmation.items, cartOrderForConfirmation.details, cartOrderForConfirmation.deliveryFeePayload, cartOrderForConfirmation.address_ids)
    }
  }

  removeLocalOrder = () => {
    localStorage.removeItem('order_data_confirmation'); 
  }

  onSubmitSchedule = () => {
    let cuisines_menus_attributes: { cuisine_id: number }[] = [];
    this.props.cuisines?.forEach((cuisine: Cuisine) => {
      if (cuisine.name === 'Pick for Me') {
        cuisines_menus_attributes = [{ cuisine_id: cuisine.id }];
      }
    });
    let temp = [];
    const fields = this.scheduleForm?.state.orderFieldAttributes;
    if (fields && fields.length > 0) {
      for (var g = 0; g < fields.length; g++) {
        if (fields[g].field_type === 'text') {
          temp.push({ value: fields[g].value, field_id: fields[g].id });
        } else {
          temp.push({ fieldoption_id: fields[g].value, field_id: fields[g].id });
        }
      }
    }
    this.scheduleForm?.setState({ scheduleFromOrder: true });
    const { addresses,
      selectedAddressID
    } = this.props;

    const companyDataScheduler = this.props.companyObj ?? loggedInUserCompany
    const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
    const selectedAddress = addresses.comp_addresses?.addresses?.find((address) => address?.id === selectedAddressID);

    if (this.scheduleForm?.state && this.validateCustomFields() && !this.checklimits()) {
      const requestData = {
        order_ids: undefined, // was but this props does not exist now this.props.orderIds,
        runningmenu: {
          runningmenu_name: this.props.userObj?.first_name + '’s New Delivery',
          per_meal_budget: companyDataScheduler?.user_meal_budget ?? loggedInUserCompany?.user_meal_budget,
          runningmenu_type: this.state.meal_type,
          address_id: this.state.selectedCompanyAddressID!, // Need Consideration
          delivery_at: getDateWithTimeZone(this.state.deliveryAt ? this.state.deliveryAt : this.props.match.params.date, selectedAddress?.time_zone ? selectedAddress?.time_zone : localAddress.time_zone),
          orders_count: 1,
          //Sending same data for end time as sent in delivery_at as requested from backend
          // end_time: getDateWithTimeZone(this.state.deliveryAt ? this.state.deliveryAt : this.props.match.params.date, selectedAddress?.time_zone ? selectedAddress?.time_zone : localAddress.time_zone),
          cuisines_menus_attributes: cuisines_menus_attributes,
          menu_type: this.state.menu_type as ('buffet' | 'individual'),
          address_ids: this.props?.allowedRestaurantsIdArray,
          runningmenufields_attributes: Object.assign({}, temp),
          hide_meeting: true
        }
      }
      this.setState({ submitSchedule: true })
      this.props.makeDeliveryRequest(requestData, false);
    }
  }
  // For Order related stuff Submission
  onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { isAdmin: isCompanyAdmin, company_data_reducer: { company_detail_for_order_purpose } } = this.props;
    const {
      guestInfo: { firstName, lastName, email, phoneNumber },
      count,
      itemID,
      remarks,
      orderID,
      dishsizes,
      menu_type,
      dishsize_id,
      isGuestOrder,
      itemOptionsSet,
      field_required,
      company_user_credit_card,
      company_user_credit_card_token_id,
      showPaymentSection,
      currentMeetingSlugOrId,
      selectedCompanyAddressID,
    } = this.state;

    const companyDataOrder = this.props.companyObj ?? loggedInUserCompany

    if (menu_type === 'buffet' && this.props.userObj?.user_type === UserType.ADMIN && !isEmpty(dishsizes) && (isEmpty(dishsize_id))) {
      const tesNode = ReactDOM.findDOMNode(document.getElementById('select-dishes')) as HTMLElement;
      let dishErrorElement: HTMLElement = document.getElementById('select-dishes-error') as HTMLElement;
      dishErrorElement.innerHTML = `Dish size can't be empty.`;
      $('#order-popup-body').animate({ scrollTop: tesNode.offsetTop }, 1000);
      return false;
    }

    if (!itemID) { // If Fooditem is not Present then RETURN Because without Fooditem ID order cannot be placed.
      return false;
    }

    let orderData: PlaceOrderPayload = {
      order: {
        dishsize_id: dishsize_id ?? '', // In case of Buffet It should not be empty
        quantity: count,
        fooditem_id: +itemID,
        address_id: selectedCompanyAddressID!,
        optionsets_orders_attributes: [],
        remarks: remarks,
        utensils: this.state.model_type === 'edit' ? this.state.editUtensils : this.state.addUtensils,
      }
    }

    if (isGuestOrder && isCompanyAdmin) { // If Guest Order is on then add guest order info into Order Data.
      orderData['order']['guest_attributes'] = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber
      }
    }

    if (isCompanyAdmin && field_required && this.scheduleForm?.state) {
      orderData.order.runningmenufields_attributes = this.scheduleForm?.state.orderFieldAttributes
    }

    const isPaymentSectionEditMode = (showPaymentSection && !isCompanyAdmin && (this.stripePaymentRef?.state.isEditMode || !company_user_credit_card)) ?? false;
    const { cardElement, handleFormSubmit } = this.stripePaymentRef || {};

    if (isPaymentSectionEditMode) {
      //@ts-ignore
      const isValidCardElement = cardElement?._implementation?._complete || false;
      if (isValidCardElement) {
        this.stripePaymentRef?.setState?.({ checkoutErrors: `Please ${company_user_credit_card ? 'update' : 'save'} your credit card first.` })
      } else {
        handleFormSubmit?.();
      }
      return;
    }

    const isPaymentMethodRequired = showPaymentSection && company_detail_for_order_purpose?.customer === false;
    if (isPaymentMethodRequired) {
      if (company_user_credit_card_token_id) {
        orderData.order.stripe_token = company_user_credit_card_token_id as unknown as string;
      } else {
        handleFormSubmit?.();
      }
    } else if (!isPaymentMethodRequired && company_user_credit_card_token_id) {
      orderData.order.stripe_token = company_user_credit_card_token_id as unknown as string;
    }

    const order: NodeListOf<HTMLInputElement> = document.querySelectorAll('.ReactModalPortal .fooditem-optionsets-option');

    let order_selected_fields: any = [], order_selected: any = [], option_set_order_id: any = [];
    if (orderID > 0) {
      const optionSets = itemOptionsSet;
      (optionSets ?? []).forEach(foodItemOptionSet => {
        foodItemOptionSet.options.forEach(foodOptionSetOption => {
          if (foodOptionSetOption.selected) {
            order_selected_fields[foodOptionSetOption.id] = foodOptionSetOption.optionsorder_id;
          }
          option_set_order_id[foodOptionSetOption.id] = foodItemOptionSet.optionsetorder_id;
        });
        order_selected[foodItemOptionSet.id] = foodItemOptionSet.optionsetorder_id
        return false;
      });
    }
    let optionSetArray: any = [];
    let curOptionSetID: any = null;
    let d: any;
    (order ?? []).forEach(optionInput => {
      if (optionInput?.checked && !order_selected_fields[optionInput.id]) {
        //adding new fields
        if (curOptionSetID === optionInput.title) {
          d.options_orders_attributes.push({ option_id: optionInput.id });
        } else {
          if (d) {
            optionSetArray = optionSetArray.concat(d);
          }
          d = { id: option_set_order_id[optionInput.id], optionset_id: optionInput.title, required: optionInput.src, options_orders_attributes: [{ option_id: optionInput.id }] }
          curOptionSetID = optionInput.title
        }
      } else if (!optionInput.checked && order_selected_fields[optionInput.id]) {
        //destroying old fields
        const main_id = optionInput.title;
        if (curOptionSetID === optionInput.title) {
          d.options_orders_attributes.push({ option_id: optionInput.id, _destroy: 1, id: order_selected_fields[optionInput.id] });
        } else {
          if (d) {
            optionSetArray = optionSetArray.concat(d);
          }
          d = { optionset_id: optionInput.title, required: optionInput.src, id: order_selected[main_id], options_orders_attributes: [{ option_id: optionInput.id, _destroy: 1, id: order_selected_fields[optionInput.id] }] }
          curOptionSetID = optionInput.title
        }
      }
    })
    optionSetArray = optionSetArray.concat(d);
    if (d && optionSetArray.length > 0) {
      orderData.order.optionsets_orders_attributes = Object.assign({}, optionSetArray);
    }
    // check first limits than validate custom fields check for required field is within validateCustomFields custom field
    if (!this.checklimits()) {
      if (((isPaymentMethodRequired && !isEmpty(orderData.order.stripe_token)) || (isPaymentMethodRequired === false)) && (this.validateCustomFields())) {
        this.setState({ loading: true });
        const deliveryDate = this.props?.match?.params?.date && dayjs(this.props?.match?.params?.date, 'M-D-YYYY').format('D-M-YYYY');
        if (orderID > 0) { // In case of Update Order
          this.props.updateSingleOrder(orderData, orderID, this.props.token ? this.props.token : '')
        } else if (!orderID && currentMeetingSlugOrId && companyDataOrder?.self_signup !== true) { // In case of placing New Order for company user not self signup
          this.props.placeOrder(orderData, (currentMeetingSlugOrId as unknown as number), deliveryDate, this.props.token ? this.props.token : '')
        }
        this.setState({ showPaymentSection: false });
      }
    }
  };

  incrementCount = () => {
    if (this.state.count < 99999) {
      this.setState({
        count: parseInt(`${this.state.count}`) + 1
      });
    }
    this.getPrices();
  }
  decrementCount = () => {
    if (this.state.count > 1) {
      this.setState({
        count: parseInt(`${this.state.count}`) - 1
      });
    }
    this.getPrices()
  }
  adjustCount = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) || value === '') {
      this.setState({ count: +value });
    }
    this.getPrices();
  }
  onChangeRemarks = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ remarks: e.target.value })
  }
  checkCount = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
    if (parseInt(value) === 0 || value === '') {
      this.setState({ count: 1 })
    } else if (parseInt(value) > 99999) {
      this.setState({ count: 99999 })
    }
    this.getPrices();
  }
  handleDishsizes = (selectedDishId: number) => {
    let updatedDishsizes = this.state.dishsizes;
    let dishsize_id = null;
    let serve_count = 1;
    updatedDishsizes?.forEach((dish) => {
      if (dish.id === selectedDishId) {
        dish.selected = true;
        dishsize_id = dish.id;
        serve_count = dish.serve_count;
      } else {
        dish.selected = false;
      }
    });
    this.setState({ dishsizes: updatedDishsizes, dishsize_id: dishsize_id, serve_count: serve_count })
  }

  guestChangeInputHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const { guestInfo } = this.state
    this.setState({
      guestInfo: { ...guestInfo, [name]: value }
    })
  }

  saveAdditionalOptions = () => {
    // loader for saving guest data
    this.loaderForGuestInfo()
    const { guestInfo, isGuestOrder } = this.state;
    const { firstName, lastName, email, phoneNumber } = guestInfo;
    const OptionalPhoneNumber = phoneNumber !== '' ? !phoneNumberPattern.test(phoneNumber) : ''
    const inValid = [isEmpty(firstName), isEmpty(lastName), !emailPattern.test(email),OptionalPhoneNumber ].find(error => error);
    if (inValid !== undefined && isGuestOrder) {
      this.setState({
        guestInfo: {
          ...guestInfo,
          firstNameError: isEmpty(firstName) ? 'Please Enter First Name' : '',
          lastNameError: isEmpty(lastName) ? 'Please Enter last Name' : '',
          emailError: emailPattern.test(email) ? '' : 'Please Enter a valid Email'
        }
      })
      if (OptionalPhoneNumber) {
        this.setState({
          guestInfo: {
            ...guestInfo,
            phoneNumberError: phoneNumberPattern.test(phoneNumber) ? '' : 'Please match the format (e.g. XXX-XXX-XXXX).'
          }
        })
      }
    } else {
      this.setState({
        isActiveAdditionalOptionsTab: false,
        guestInfo: {
          ...guestInfo,
          firstNameError: '',
          lastNameError: '',
          emailError: '',
          phoneNumberError: '',
        }
      })
      if (OptionalPhoneNumber) {
        this.setState({
          guestInfo: {
            ...guestInfo,
            phoneNumberError: ''
          }
        })
      }
    }
  }

  // additionalOptionsHandler = () => {
  //   let { height = null } = document.getElementById('order-popup-body')?.getBoundingClientRect() || {};
  //   this.setState({
  //     isActiveAdditionalOptionsTab: true,
  //     modalBodyHeight: height
  //   })
  // };

  // showNutritionalFactsTabHandler = () => {
  //   let { height: modalBodyHeight = null } = document.getElementById('order-popup-body')?.getBoundingClientRect() || {};
  //   let { height: modalFooterHeight = null } = document.getElementById('order-popup-footer')?.getBoundingClientRect() || {};
  //   this.setState({
  //     isActiveNutritionalFactsTab: true,
  //     modalBodyHeight: (modalBodyHeight && modalFooterHeight) ? (modalBodyHeight + modalFooterHeight) : null
  //   })
  // };

  returnPercentRating = (rating: number, starNumber: number) => {
    if (rating >= starNumber) {
      return '100%'
    }
    else if ((!Number.isInteger(rating)) && (starNumber - rating) < 1) {
      return Number((rating - Math.floor(rating)) * 100) + '%';
    }
  }
  handleAccordion = (panel: 'additional-opt-panel' | 'additonal-details-panel' | 'nutrition-facts-panel') => (event: React.SyntheticEvent, newExpanded: boolean) => {
    this.setState({expanded: (newExpanded ? panel : false)});
    this.setState({ orderFieldAttributesRequiredCheck: !this.state.orderFieldAttributesRequiredCheck })
  };
  itemAddedToCart = () => toast(<div className=''>
    <p className='text-gray-primary fs-16 fw-normal m-0'>{`${this.state.itemName}`} added to cart</p>
    <button className='link-style bg-transparent border-0 px-0' onClick={this.props.openCart} > View Cart <i className="fas fa-chevron-right ml-2 fs-16" aria-hidden="true"></i></button>
  </div>, {
    icon: ({ theme, type }) => <img alt='food Item' className='rounded-lg cart-item-img' src={this.state?.itemImageSmall ?? '/imgs/SVGs/ItemPlaceholder.svg'} />,
    // theme: "dark",
    type: "success"
});

handleUtensilsCheckBox = () => {
  if(this.state.model_type === 'edit')
  {this.setState({
    editUtensils: !this.state.editUtensils,
  })}else{
    this.setState({addUtensils:!this.state.addUtensils})
  }
}
  render() {
    const {
      i18n,
      order,
      userObj,
      company_data_reducer: {
        company_detail_for_order_purpose,
        company_data_reducer_loading,
      },
      schedules_details,
      companyObj,
      onUpdatePage,
      remaining_budget,
      isCompanyUser,
      isCompanyManager,
      isAdmin: isCompanyAdmin,
      match: { params: { date, meetingSlug } }
    } = this.props;
  
    // Company Object will always be present in Localstorage when Popup is Open.
    const user_company = company_detail_for_order_purpose?.company || companyObj!;

    const {
      admin_cutoff_at,
      same_day_admin_cutoff_at,
      same_day_activation_at,
      best_seller,
      calories,
      count,
      cutoff_at,
      same_day_cutoff,
      company_user_credit_card,
      dietaries,
      dishsizes,
      field_required,
      foodItemNutritions,
      guestInfo: {
        firstName,
        lastName,
        email,
        phoneNumber,
        firstNameError,
        lastNameError,
        emailError,
        phoneNumberError
      },
      ingredients,
      itemDescription,
      itemID,
      itemImage,
      isGuestOrder,
      itemPrice,
      itemName,
      itemOptionsSet,
      loading,
      marketPlacePopUp,
      menu_type,
      // modalBodyHeight,
      modalIsOpen,
      model_type,
      newOrderCreated,
      orderID,
      per_user_copay,
      remarks,
      restaurants_name,
      // isActiveAdditionalOptionsTab,
      // isActiveNutritionalFactsTab,
      showPaymentSection,
      skip_markup,
      spicy,
      submitSchedule,
      average_rating,
      showConfirmEmptyCartPopUp,
      editUtensils
    } = this.state;

    const isCompanyManagerOrUser = isCompanyManager || isCompanyUser;
    // const isActiveMainTab = !(isActiveAdditionalOptionsTab || isActiveNutritionalFactsTab);
    const showDuplicateOrdersWarning = meetingSlug && date && company_detail_for_order_purpose?.duplicate_order_exists && !isCompanyAdmin;

    let userOutOfBudgetPrice;
    if (isCompanyAdmin) {
      userOutOfBudgetPrice = 0;
    } else if (isUnsubsidizedUser) {
      const foodItemPrice = this.round(parseFloat(`${count}`) * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString()));
      userOutOfBudgetPrice = +(((+foodItemPrice + .30) / (1 - .029)).toFixed(2)); // Added Stripe fee for Unsubsidized User.
    } else { // To handle userOutOfBudgetPrice for Company (User, Manager and Guest User).
      userOutOfBudgetPrice = +this.getPriceWithCoPay(parseFloat(this.calculatePrice(user_company && user_company.markup, skip_markup).toString()))
    }

    const isSameDayDeliveryActive = (dayjs(schedules_details?.single_schedule?.schedule?.sd_activation_at).isBefore(new Date())) && (dayjs(isCompanyAdmin? schedules_details?.single_schedule?.schedule?.sd_admin_cutoff_at : schedules_details?.single_schedule?.schedule?.sd_cutoff_at ).isAfter(new Date()))

    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={newOrderCreated ? customStyles : showConfirmEmptyCartPopUp ? emptyCartPopUpStyle : isEmpty(itemImage) ? customStyles : customStylesTwoCol}
      >
        {/* <div className='order-modal custom-order-modal'> */}
        <div>
          <div className={`${newOrderCreated ? 'modal-dialog' : showConfirmEmptyCartPopUp ? '': isEmpty(itemImage) ? 'modal-dialog' : 'modal-dialog-long'}`}>
            <div className='modal-content'>
              {/* Modal Header */}
              {!showConfirmEmptyCartPopUp &&
                <div className={`order-popup-modal-header border-0 bg-white d-flex justify-content-between align-items-center`}>
                  {/* {isActiveMainTab ? */}
                    <>
                      <h5 id='modal-title' className='modal-title fooditem-text-capitalize text-case order-popup-title order-popup-header-title'>
                        {newOrderCreated ? (i18n && i18n.t('Order Received')) : itemName}
                      </h5>
                    </>
                  {/* :
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-chevron-left cursor-pointer'
                        onClick={() => { isActiveNutritionalFactsTab ? this.setState({ isActiveNutritionalFactsTab: false }) : this.saveAdditionalOptions() }}
                      >
                      </i>
                      <h5 className='pl-3 m-0 modal-title fooditem-text-capitalize text-case order-popup-title'>
                        {isActiveNutritionalFactsTab ? 'Nutrition Facts' : 'Additional Options'}
                      </h5>
                    </div>
                  } */}
                  <button type='button' onClick={this.closeModal} className='close m-0 p-0' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>
                      {/* <i className="fa-regular fa-xmark m-0 p-0"></i> */}
                      <i className="fas fa-times"></i>

                    </span>
                  </button>
                </div>
              }
              {/* Modal Body */}
              {!newOrderCreated && showConfirmEmptyCartPopUp === false ?
                <>
                  {/* Display Rating */}
                  <div className='d-flex pl-4' style={{ width: 'fit-content' }} >
                    {[1, 2, 3, 4, 5].map((star) =>
                      <div key={star} >
                        <i className={`${(average_rating ?? 0) < star ? 'grey-star' : 'yellow-star'} fas fa-star order-popup-rating-star`} ></i>
                      </div>
                    )}
                    {average_rating > 0 &&
                      average_rating && <b className='pl-3'> {Number(average_rating).toFixed(1)} </b>
                    }
                  </div>
                  <div
                    // style={{ height: isActiveNutritionalFactsTab ? modalBodyHeight! : `calc(95vh - 220px)`,  }}
                    style={{ height: `calc(95vh - 290px)`, overflowX: 'hidden',overflowY: 'scroll' }}
                    className='position-relative order-poup-header-wrapper '
                    id='order-popup-body'
                  >
                    {/* Initial Modal Body (First Tab) */}
                    <div
                      className=' p-0 w-100'
                      style={{
                        transition: 'all .5s', top: 0,
                        // ...(isActiveMainTab ?
                          transform: 'translateX(0)', position: 'relative'
                          // transform: 'translateX(-100%)', position: 'absolute'
                        // )
                      }}
                    >
                      <div className='contianer modal-content-wrapper'>
                        <div className='row'>
                              <div className={`col-xs-12 ${!isEmpty(itemImage) ? 'col-md-6' : 'col-md-12'} order-popup-column-right `}>
                                <div className='sticky-top'>
                                  {/* Dietaries and Ingredients */}
                                  <div className='d-flex flex-wrap py-2 popUp-diet-ing'>
                                    {best_seller ? (<span><span className='badge badge-light badge-pill order-popup-custom-badges px-2 py-1'>Best seller</span>&nbsp;</span>) : ''}
                                    {spicy ? (<span><span className='badge badge-light badge-pill order-popup-custom-badges px-2 py-1'>Spicy</span>&nbsp;</span>) : ''}
                                    { !isEmpty(foodItemNutritions) ?
                                        ''
                                      :
                                        calories > 0 && <span><span className='badge badge-light badge-pill order-popup-custom-badges px-2 py-1'>{`Calories: ${calories}`}</span>&nbsp;</span>
                                    }
                                    {!isEmpty(ingredients) && ingredients.map(ing =>
                                      <div key={ing.id} className='order-diet-pills badge badge-light mr-2'>
                                        <div className="d-flex align-items-center dietary-logo popup-dietary-logo">
                                            {ing?.logo && <div className='mr-2'>{parse(ing?.logo)}</div> }
                                          <p className='m-0'> {ing.name} </p>
                                        </div>
                                      </div>
                                    )}
                                    {!isEmpty(dietaries) && dietaries.map(diet =>
                                      <div key={diet.id} className='order-diet-pills badge badge-light mr-2'>
                                        <div className="d-flex align-items-center dietary-logo popup-dietary-logo">
                                            {diet?.logo && <div className='mr-2'>{parse(diet?.logo)}</div> }
                                          <p className='m-0'> {diet.name} </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {!isEmpty(itemDescription) && <p className='text-case text-eclipses'> {itemDescription} </p>}
                                  {!isEmpty(itemImage) && <div className='card-item-img-lg' style={{ backgroundImage: `url(${itemImage})` }} />}
                                </div>
                              </div>
                            <div 
                              className={`col-xs-12 ${!isEmpty(itemImage) ? 'col-md-6 order-popup-column-left nutrition-facts-column' : 'col-12' } pt-3 pt-lg-0`}
                            >
                              
                              {/* {isEmpty(itemImage) && (!isEmpty(itemDescription) &&
                              <div className='col-12'>
                                <p className='text-case'> {itemDescription} </p>
                              </div>)} */}
                              {/* Nutritional Facts */}
                              {!isEmpty(foodItemNutritions) && calories !== 0 &&
                                <div className='nutrition-facts-collapse'>
                                  <Accordion expanded={this.state.expanded === 'nutrition-facts-panel'} onChange={this.handleAccordion('nutrition-facts-panel')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="nutrition-facts-panel-content"
                                      id="nutrition-facts-panel-header"
                                      className='accordian_wrapper'
                                    >
                                      <Typography className='order-popup-accordian-heading'>Nutritional Facts</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div>
                                        <div className='px-0 pt-2 order-popup-inner-wrapper'>
                                          <h5 className='mb-3 amount-per-serving-heading'>
                                            Amount Per Serving
                                          </h5>
                                          <>
                                          </>
                                          {foodItemNutritions.map(({ childs, id, name, value }, index) =>
                                            <React.Fragment key={id}>
                                              {index !== 0 && <hr className='my-2' />}
                                              <div className='mt-1 d-flex justify-content-between py-1'>
                                                <p className='mb-0 amount-per-serving-heading'>{name}</p>
                                                <p className='mb-0 amount-per-serving-heading'>{value}g</p>
                                              </div>
                                              {childs?.map(({ id, name, value }) =>
                                                <div key={id} className='pl-3' style={{ color: '#999999' }}>
                                                  <div className='d-flex justify-content-between py-1'>
                                                    <p className='mb-1 amount-per-serving-label'>{name}</p>
                                                    <p className='mb-1 amount-per-serving-label'>{value}g</p>
                                                  </div>
                                                </div>
                                              )}
                                            </React.Fragment>)
                                          }
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion> 
                                </div>
                              }
                              {/* Optionsets */}
                              <div className='pt-2'>
                                <form noValidate>
                                  {itemOptionsSet.map((optionItem, index4) => (
                                    <React.Fragment key={index4}>
                                      {optionItem.end_limit === 1 && optionItem.required === 1 ?
                                        <div className='pt-3'>
                                          <div className='d-flex justify-content-between align-items-center pb-1'>
                                            <h6 className='orderform-section-name float-left text-case order_popup_section_heading'>{optionItem.name}</h6>
                                          </div>
                                          <div>
                                            <h6 id={`order-${optionItem.id}`} className='orderform-section-required muted'>Required</h6>
                                          </div>
                                          {/* <div className='clearfix' /> */}
                                          {optionItem?.options?.map((optionsValue, index) => (
                                            <div className='form-check py-2' key={`${itemID}` + optionsValue.id}>
                                              <input
                                                type='radio'
                                                id={`${optionsValue.id}`}
                                                title={`${optionItem.id}`}
                                                onChange={this.getPrices}
                                                value={optionsValue.price}
                                                className='form-check-input fooditem-optionsets-option'
                                                name={'radioButton-' + optionItem.id}
                                                defaultChecked={(!(model_type === 'add') && ((orderID === 0 && index === 0) || (orderID > 0 && optionsValue.selected === true))) ? true : false}
                                              />
                                              <div className='d-flex align-items-start justify-content-between' style={{ columnGap: '20px' }}>
                                                <label className='form-check-label pl-4 text-case order-popup-labels' htmlFor={`${optionsValue.id}`}> {optionsValue.description} </label>
                                                {(userObj?.user_type === UserType.ADMIN || isUnsubsidizedUser || this.props?.fromPublicMarketPlace || this.props?.token) ?
                                                  <div className='float-right muted'>
                                                    {(optionsValue.price > 0) ? '$' + this.round(optionsValue.price) : ''}
                                                  </div>
                                                  : ''
                                                }
                                                {isCompanyManagerOrUser && per_user_copay === 1 ?
                                                  <div className='float-right muted'>{optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}</div>
                                                  : ''
                                                }
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        :
                                        <div className={` pt-2 getSelectedOptionSet${optionItem.id}`}>
                                          <div className='d-flex justify-content-between align-items-center pb-1'>
                                            <h6 className='orderform-section-name float-left text-case order-section-sub-heading'>{optionItem.name}</h6>
                                          </div>
                                          <div>
                                            <h6 id={`order-${optionItem.id}`} className='orderform-section-required muted'>
                                              {
                                                (optionItem.start_limit > 0 || optionItem.end_limit > 0) ?
                                                  (optionItem.start_limit === 0 && optionItem.end_limit === 1) ?
                                                    'Optional, Maximum 1' :
                                                    (+optionItem.start_limit === +optionItem.end_limit) ?
                                                      'Pick Just ' + optionItem.start_limit :
                                                      (optionItem.start_limit === 0) ?
                                                        'Optional, Maximum ' + optionItem.end_limit :
                                                        'Must Select ' + optionItem.start_limit + ', No More Than ' + optionItem.end_limit
                                                  :
                                                  'Optional'
                                              }
                                            </h6>
                                          </div>
                                          {/* <div className='clearfix' /> */}
                                          {optionItem.options.map((optionsValue) => (
                                            <div className='form-check py-2' key={`${itemID}` + optionsValue.id}>
                                              <input
                                                type='checkbox'
                                                id={`${optionsValue.id}`}
                                                title={`${optionItem.id}`}
                                                onChange={this.getPrices}
                                                value={optionsValue.price}
                                                // src={optionsValue.required}
                                                name={optionsValue.description}
                                                className='form-check-input fooditem-optionsets-option'
                                                // className='form-check-input'
                                                defaultChecked={(orderID > 0 && optionsValue.selected === true) ? true : false}
                                                disabled={(this.disableOptionsAfterMaxSelection(optionItem.id, optionItem.end_limit, optionsValue.id) && (optionItem.start_limit > 0 || optionItem.end_limit > 0)) ? true : false}
                                              />
                                              <div className='d-flex align-items-start justify-content-between' style={{ columnGap: '20px' }}>
                                                <label className='form-check-label pl-4 text-case order-popup-labels d-block' htmlFor={`${optionsValue.id}`}> {optionsValue.description} </label>
                                                {(userObj?.user_type === UserType.ADMIN || isUnsubsidizedUser || this.props?.fromPublicMarketPlace || this.props?.token) ?
                                                  <div className='float-right muted d-block'>
                                                    {optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}
                                                  </div>
                                                  : ''
                                                }
                                                {isCompanyManagerOrUser && per_user_copay === 1 ?
                                                  <div className='float-right muted d-block'>
                                                    {optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}
                                                  </div>
                                                  : ''
                                                }
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      }
                                    </React.Fragment>
                                  ))
                                  }
                                  <div className='accordian_wrapper mt-3 mb-3 p-3 w-100'> 
                                    {<label className='d-flex justify-content-between m-0'>
                                      <div className='order-popup-accordian-heading'>Include Utensils</div>
                                      {loading && (!loggedInUserCompany.self_signup && !isEmpty(loggedInUser)) ?  <Skeleton height={18} width={18} /> : <input
                                        type="checkbox"
                                        checked={model_type === 'edit' ? editUtensils : this.state.addUtensils}
                                        onChange={this.handleUtensilsCheckBox}
                                      />}
                                    </label>}
                                  </div>
                                  {/* Additional Options */}
                                  {(!this.props.fromPublicMarketPlace && user_company?.self_signup !== true) || (!this.props.fromPublicMarketPlace && user_company?.self_signup !== true) ?
                                  <div className={`nutrition-facts-collapse ${!isEmpty(itemOptionsSet) && 'mt-3'}`}>
                                    <Accordion expanded={this.state.expanded === 'additional-opt-panel'} onChange={this.handleAccordion('additional-opt-panel')}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="additional-opt-panel-content"
                                        id="additional-opt-panel-header"
                                        className='accordian_wrapper'
                                      >
                                        <Typography className='order-popup-accordian-heading'>Additional Options</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails className='order-popup-inner-wrapper'>
                                        <div className='pt-3'>
                                        <h6 className={`orderform-section-name float-left order-popup-additional-optional-labels`}>
                                          {i18n && i18n.t('Extra Instructions')}
                                        </h6>
                                        <h6 id='special' className={`orderform-section-required muted float-right orderform-section-required`}>
                                          {i18n && i18n.t('Any Special Requests?')}
                                        </h6>
                                          <div className={`form-check p-0 ${isCompanyAdmin ? 'option_select' : ''}`}>
                                            <div className='form-group mb-0 '>
                                              <textarea
                                                rows={3}
                                                name='remarks'
                                                id='special_requests'
                                                value={remarks ?? ''}
                                                className='form-control p-2'
                                                onChange={this.onChangeRemarks}
                                                style={{ backgroundColor: 'white' }}
                                                disabled={!this.state.allowCustomization}
                                                placeholder={
                                                  this.state.allowCustomization
                                                    ? (i18n ? i18n.t('Allergies, spice levels, etc.') : '')
                                                    : "This vendor does not accept customization requests for this dish."
                                                }>
                                              </textarea>
                                            </div>
                                          </div>
                                        </div>
                                        {isCompanyAdmin &&
                                        <>
                                          <div className='d-flex justify-content-between pt-3'>
                                            <h6> Is this order for someone else?</h6>
                                            <div className='custom-control custom-switch'>
                                              <input type='checkbox' className=' custom-control-input' id='mealTypeCheck' name='mealTypeCheck' checked={isGuestOrder} onChange={() => { this.setState({ isGuestOrder: !isGuestOrder, guestInfo: orderGuestInfo }) }} />
                                              <label className='custom-control-label cursor-pointer' htmlFor='mealTypeCheck'></label>
                                            </div>
                                          </div>
                                          {isGuestOrder &&
                                            <>
                                              <form className={`${isGuestOrder ? 'pb-2' : 'disabled-opacity'}`} style={{ transition: '.35s opacity' }}>
                                                {/* <div className='row'> */}
                                                  <div className='col-12 pt-3 px-0'>
                                                    <label>Guest's First Name</label>
                                                    <input
                                                      type='text'
                                                      name='firstName'
                                                      value={firstName}
                                                      placeholder='First name'
                                                      className='form-control-lg form-control'
                                                      disabled={!isGuestOrder}
                                                      onChange={this.guestChangeInputHandler}
                                                    />
                                                    <span className={`text-danger mt-1 ${firstNameError ? 'd-block' : 'd-none'}`}>
                                                      {firstNameError}
                                                    </span>
                                                  </div>
                                                  <div className='col-12 pt-3 px-0'>
                                                    <label>Guest's Last Name</label>
                                                    <input
                                                      type='text'
                                                      name='lastName'
                                                      value={lastName}
                                                      placeholder='Last name'
                                                      className='form-control-lg form-control'
                                                      disabled={!isGuestOrder}
                                                      onChange={this.guestChangeInputHandler}
                                                    />
                                                    <span className={`text-danger mt-1 ${lastNameError ? 'd-block' : 'd-none'}`}>
                                                      {lastNameError}
                                                    </span>
                                                  </div>
                                                {/* </div>
                                                <div className='row'> */}
                                                  <div className='col-12 pt-3 px-0'>
                                                    <label>Guest's Email Address</label>
                                                    <input
                                                      type='email'
                                                      className='form-control-lg form-control'
                                                      disabled={!isGuestOrder}
                                                      value={email} name='email'
                                                      placeholder='Email Address'
                                                      onChange={this.guestChangeInputHandler}
                                                    />
                                                    <span className={`text-danger mt-1 ${emailError ? 'd-block' : 'd-none'}`}>
                                                      {emailError}
                                                    </span>
                                                  </div>
                                                  <div className='col-12 pt-3 px-0'>
                                                    <label>Guest's Phone Number</label>
                                                    <input
                                                      type='text'
                                                      name='phoneNumber'
                                                      value={phoneNumber}
                                                      placeholder='Optional, for Delivery notification'
                                                      className='form-control-lg form-control'
                                                      disabled={!isGuestOrder}
                                                      onChange={this.guestChangeInputHandler}
                                                    />
                                                    {phoneNumberError &&
                                                      <span className={`text-danger mt-1 `}>
                                                      {`${phoneNumber !=='' ? phoneNumberError : ''}`}
                                                    </span>}
                                                  </div>
                                                {/* </div> */}
                                                {/* <div className='form-group'>
                                                  <label className='my-2'>Guest's Email Address</label>
                                                  <input
                                                    type='email'
                                                    className='form-control'
                                                    disabled={!isGuestOrder}
                                                    value={email} name='email'
                                                    placeholder='Email Address'
                                                    onChange={this.guestChangeInputHandler}
                                                  />
                                                  <span className={`text-danger mt-1 ${emailError ? 'd-block' : 'd-none'}`}>
                                                    {emailError}
                                                  </span>
                                                </div> */}
                                              </form>
                                              <button type='button' className='btn save-btn-clr float-right' onClick={this.saveAdditionalOptions} >
                                                <>
                                                  {this.state.isLoadingForSavingGuestData ?
                                                    <Skeleton height={30} width={40} />
                                                    : <span>Save</span>
                                                  }
                                                </>
                                              </button>
                                            </>
                                          }
                                          
                                        </>
                                        }
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                  : ''}
                                  {/* Buffet Delivery DishSizes */}
                                  {dishsizes && menu_type === 'buffet' ?
                                    <div>
                                      <div className="d-flex align-item-center justify-content-between">
                                      <h6 className='orderform-section-name mt-5 mb-3' id='select-dishes'>Dish size</h6>
                                      <h6 className='orderform-section-required muted mt-5 mb-3'>Price</h6>
                                      </div>
                                      {/* <div className='clearfix' /> */}
                                      <p id='select-dishes-error' className='orderform-section-required dishsizes-error-highlight muted mt-5 mb-3'></p>
                                      {(dishsizes ?? []).map(dish => (
                                        <div className='form-check option_select pt-3 pb-3 pl-0 pr-0' key={dish.id}>
                                          <input
                                            type='radio'
                                            id={`${dish.id}`}
                                            name='dishsize_id'
                                            title={`${dish.id}`}
                                            checked={dish.selected}
                                            className='form-check-input ml-0'
                                            onChange={() => this.handleDishsizes(dish.id)}
                                          />
                                          <label className='form-check-label pl-3' htmlFor={`${dish.id}`}> {dish.title}</label>
                                          <div className='float-right muted'>${Number(dish.price).toFixed(2)}</div>
                                          <p className='dishes-serves-count'>Serves {dish.serve_count} (${Number(dish.price / dish.serve_count).toFixed(2)} / person)  </p>
                                        </div>
                                      ))}
                                    </div> : ''
                                  }

                                  {/* Duplicate Orders Warning Card*/}
                                  {showDuplicateOrdersWarning &&
                                    <>
                                      <hr />
                                      <div
                                        className='mt-3 p-3 cursor-pointer d-flex align-items-center justify-content-between alert alert-warning' style={{ borderRadius: 10 }}>
                                        <div className='d-flex flex-column'>
                                          <h6 className='orderform-section-name float-left'>Your Other Order</h6>
                                          <h6 style={{ fontSize: 14 }}>
                                            We noticed you have another order at this time as well. You can view all your orders here.&nbsp;
                                            <Link to={`/order-history/${date}/${date}`}>orders here</Link>.
                                          </h6>
                                          <div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  } 
                                  {/* Payment Section in case of Non-Admin Users */}
                                  {showPaymentSection && !isCompanyAdmin &&
                                    <>
                                      <hr />
                                      <div className="order-popup-stripe-inner-wrapper">
                                        <h4>Credit Card Details</h4>
                                        <h5>Your Out of Pocket: ${userOutOfBudgetPrice}</h5>
                                        {company_user_credit_card ?
                                          <p className='muted'>Your below card on file will be charged the ${userOutOfBudgetPrice} out of pocket.</p> :
                                          <p className='muted'>This item requires an out-of-pocket cost not paid by your company. Please provide your credit card details below in order to complete your purchase.</p>
                                        }
                                        <StripeLayout
                                          parentRef={this}
                                          fromOrderPopup
                                          ref={this.ccOrderingRef}
                                          confirmAddingCard={this.state.orderPlaced}
                                          isLoading={company_data_reducer_loading}
                                          setRef={(ref: StripeLayoutRef | null) => { this.stripePaymentRef = ref }}
                                          prevPaymentDetails={company_user_credit_card}
                                        />
                                      </div>
                                    </>
                                  }
                                  {/* {!this.props.company_data_reducer?.company_detail_for_order_purpose?.card_details && user_company?.self_signup === true &&
                                    <>
                                      <hr />
                                      <div className="order-popup-stripe-inner-wrapper">
                                        <h4>No Credit Card on File</h4>
                                        <p>Your credit card is needed to process this order.</p>
                                        <div className='self-signup-card-details'>
                                          <p className='billing-info-note'>Please note your credit card will not be charged until delivery is completed on day of delivery</p>
                                          <p className='self-signup-billing-info'>If you would like to sitch to billing by invoice, please email us at <a className='self-signup-billing-info' href="mailto:support@chowmill.com">support@chowmill.com</a></p>
                                        </div>
                                        <StripeLayout
                                          parentRef={this}
                                          fromOrderPopup
                                          isLoading={company_data_reducer_loading}
                                          setRef={(ref: StripeLayoutRef | null) => { this.stripePaymentRef = ref }}
                                          prevPaymentDetails={company_user_credit_card}
                                        />
                                      </div>
                                    </>
                                  } */}
                                </form>
                              </div>
                              
                              {/* Additional Details new name Order Fields */}
                              {/* Delivery Fields Required in case of Admin */}
                              {(!this.props.fromPublicMarketPlace && user_company?.self_signup !== true && field_required && userObj?.user_type === UserType.ADMIN) || (!this.props.fromPublicMarketPlace && user_company?.self_signup !== true && marketPlacePopUp === true) ?
                                <div className='mt-3 nutrition-facts-collapse'>
                                  <Accordion expanded={this.state.expanded === 'additonal-details-panel' || this.state.orderFieldAttributesRequiredCheck} onChange={this.handleAccordion('additonal-details-panel')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="additonal-details-panel-content"
                                      id="additonal-details-panel-header"
                                      className='accordian_wrapper'
                                    >
                                      <Typography className='order-popup-accordian-heading'>{this.props.i18n && this.props.i18n.t('Order Fields')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div>
                                        {/* {(field_required && userObj?.user_type === UserType.ADMIN) || (marketPlacePopUp === true) ? */}
                                        <ScheduleDeliveryForm
                                          selectedType={undefined}
                                          selectedAddress={undefined}
                                          meetingStatus={undefined}
                                          onRef={(ref: ScheduleDeliveryFormRef | null) => this.scheduleForm = ref}
                                          setState={(p: OrderPopupState) => { this.setState(p) }}
                                          closeModal={this.closeModal}
                                          onUpdatePage={onUpdatePage}
                                          orderPopUp={true}
                                        />
                                        {/* : ''} */}
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              : ''}
                            </div>
                        </div>
                        {/* <div className='row'>
                          <div className='col-6' style={{backgroundColor: 'green'}}>
                            {!isEmpty(itemDescription) ? (<p className='text-case'> {itemDescription} </p>) : ''}
                          </div>
                          <div className='col-6' style={{ backgroundColor: 'blue'}}>
                            Other Half
                          </div>
                        </div> */}
                      </div>
                      {/* <div className='p-4'> */}
                        {/* {!isEmpty(itemDescription) ? (<p className='text-case'> {itemDescription} </p>) : ''} */}
                        {/* <div className={`${!isEmpty(best_seller || spicy || isEmpty(ingredients) || isEmpty(dietaries)) ? 'mb-2 d-flex justify-content-between align-items-baseline' : ''}`}>
                          <div>
                            {best_seller ? (<span><span className='badge badge-light footer-pill'>Best seller</span>&nbsp;</span>) : ''}
                            {spicy ? (<span><span className='badge badge-light footer-pill'>Spicy</span>&nbsp;</span>) : ''}
                            {calories > 0 ? (<span><span className='badge badge-light footer-pill'>{'Calories: ' + calories}</span>&nbsp;</span>) : ''}
                            {!ingredients ? '' : ingredients.map(ing =>
                              <span key={ing.id}>
                                <span className='badge badge-light footer-pill'>{ing.name}</span>&nbsp;
                              </span>)}
                            {!dietaries ? '' : dietaries.map(diet =>
                              <span key={diet.id}>
                                <span className='badge badge-light footer-pill'>
                                  {/* {diet.checked ? <i className='fas fa-check-circle pr-1' aria-hidden='true'></i> : ''} */}{/*
                                  {diet.name}
                                </span>&nbsp;
                              </span>
                            )}
                          </div>
                          {!isEmpty(foodItemNutritions) &&
                            <div>
                              <p className='view-nutrition-fact m-0 text-nowrap ml-2'
                                style={{ color: '#157FFC', fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}
                                onClick={this.showNutritionalFactsTabHandler}
                              >
                                View nutrition facts
                              </p>
                            </div>}
                        </div> */}
                        {/* {itemImage ? (<div className='card-item-img-lg' style={{ backgroundImage: `url(${itemImage})` }} />) : ''} */}
                        {/* <form noValidate>
                          {itemOptionsSet.map((optionItem, index4) => (
                            <React.Fragment key={index4}>
                              {optionItem.end_limit === 1 && optionItem.required === 1 ?
                                <div>
                                  <h6 className='orderform-section-name float-left mt-5 mb-3 text-case'>{optionItem.name}</h6>
                                  <h6 id={`order-${optionItem.id}`} className='orderform-section-required muted float-right mt-5 mb-3'>Required</h6>
                                  <div className='clearfix' />
                                  {optionItem.options.map((optionsValue, index) => (
                                    <div className='form-check option_select p-3' key={`${itemID}` + optionsValue.id}>
                                      <input
                                        type='radio'
                                        id={`${optionsValue.id}`}
                                        title={`${optionItem.id}`}
                                        onChange={this.getPrices}
                                        value={optionsValue.price}
                                        className='form-check-input fooditem-optionsets-option'
                                        name={'radioButton-' + optionItem.id}
                                        defaultChecked={(!(model_type === 'add') && ((orderID === 0 && index === 0) || (orderID > 0 && optionsValue.selected === true))) ? true : false}
                                      />
                                      <label className='form-check-label pl-3 text-case' htmlFor={`${optionsValue.id}`}> {optionsValue.description} </label>
                                      {(userObj?.user_type === UserType.ADMIN || isUnsubsidizedUser) ?
                                        <div className='float-right muted'>
                                          {(optionsValue.price > 0) ? '$' + this.round(optionsValue.price) : ''}
                                        </div>
                                        : ''
                                      }
                                      {isCompanyManagerOrUser && per_user_copay === 1 ?
                                        <div className='float-right muted'>{optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}</div>
                                        : ''
                                      }
                                    </div>
                                  ))}
                                </div>
                                :
                                <div className={'getSelectedOptionSet' + optionItem.id}>
                                  <h6 className='orderform-section-name float-left mt-5 mb-3 text-case'>{optionItem.name}</h6>
                                  <h6 id={`order-${optionItem.id}`} className='orderform-section-required muted float-right mt-5 mb-3'>
                                    {
                                      (optionItem.start_limit > 0 || optionItem.end_limit > 0) ?
                                        'Must Select ' + optionItem.start_limit + ', No More Than ' + optionItem.end_limit :
                                        'Optional'
                                    }
                                  </h6>
                                  <div className='clearfix' />
                                  {optionItem.options.map((optionsValue) => (
                                    <div className='form-check option_select p-3' key={`${itemID}` + optionsValue.id}>
                                      <input
                                        type='checkbox'
                                        id={`${optionsValue.id}`}
                                        title={`${optionItem.id}`}
                                        onChange={this.getPrices}
                                        value={optionsValue.price}
                                        // src={optionsValue.required}
                                        name={optionsValue.description}
                                        className='form-check-input fooditem-optionsets-option'
                                        defaultChecked={(orderID > 0 && optionsValue.selected === true) ? true : false}
                                        disabled={(this.disableOptionsAfterMaxSelection(optionItem.id, optionItem.end_limit, optionsValue.id) && (optionItem.start_limit > 0 || optionItem.end_limit > 0)) ? true : false}
                                      />
                                      <label className='form-check-label pl-3 text-case' htmlFor={`${optionsValue.id}`}> {optionsValue.description} </label>
                                      {userObj?.user_type === UserType.ADMIN ?
                                        <div className='float-right muted'>
                                          {optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}
                                        </div>
                                        : ''
                                      }
                                      {isCompanyManagerOrUser && per_user_copay === 1 ?
                                        <div className='float-right muted'>
                                          {optionsValue.price > 0 ? '$' + this.round(optionsValue.price) : ''}
                                        </div>
                                        : ''
                                      }
                                    </div>
                                  ))}
                                </div>
                              }
                            </React.Fragment>
                          ))
                          }
                          {/* Buffet Delivery DishSizes */}{/*
                          {dishsizes && menu_type === 'buffet' ?
                            <div>
                              <h6 className='orderform-section-name float-left mt-5 mb-3' id='select-dishes'>Dish size</h6>
                              <h6 className='orderform-section-required muted float-right mt-5 mb-3'>Price</h6>
                              <div className='clearfix' />
                              <p id='select-dishes-error' className='orderform-section-required dishsizes-error-highlight muted mt-5 mb-3'></p>
                              {(dishsizes ?? []).map(dish => (
                                <div className='form-check option_select pt-3 pb-3 pl-0 pr-0' key={dish.id}>
                                  <input
                                    type='radio'
                                    id={`${dish.id}`}
                                    name='dishsize_id'
                                    title={`${dish.id}`}
                                    checked={dish.selected}
                                    className='form-check-inputw'
                                    onChange={() => this.handleDishsizes(dish.id)}
                                  />
                                  <label className='form-check-label pl-3' htmlFor={`${dish.id}`}> {dish.title}</label>
                                  <div className='float-right muted'>${Number(dish.price).toFixed(2)}</div>
                                  <p className='dishes-serves-count'>Serves {dish.serve_count} (${Number(dish.price / dish.serve_count).toFixed(2)} / person)  </p>
                                </div>
                              ))}
                            </div> : ''
                          }
                          {/* Additional Options Card*/}{/*
                          <div className='bg-light mt-3 p-3 cursor-pointer d-flex align-items-center justify-content-between' style={{ borderRadius: 10 }} onClick={this.additionalOptionsHandler}>
                            <div className='d-flex flex-column pr-2'>
                              <h6 className='orderform-section-name float-left'>
                                {i18n && i18n.t('Additional Options')}
                              </h6>
                              <h6 style={{ fontSize: 14, color: '#868484 ' }}>
                                {isCompanyAdmin ?
                                  'Any Special Requests (allergies, spice levels, etc)? Or Ordering for Guest? ' :
                                  'Any Special Requests (allergies, spice levels, etc)?'
                                }
                              </h6>
                              <div>
                                {!isEmpty(remarks) ?
                                  <span style={{ borderRadius: 5, opacity: 0.5 }} className='badge badge-pill badge-primary'>
                                    Extra Instructions
                                    <i className='fas fa-times ml-2' onClick={e => { e.stopPropagation(); this.setState({ remarks: '' }) }}></i>
                                  </span>
                                  : ''}
                                {isGuestOrder && isCompanyAdmin ?
                                  <span style={{ borderRadius: 5, opacity: 0.5 }} className='badge badge-pill badge-primary ml-2'>
                                    Guest Order
                                    <i className='fas fa-times ml-2' onClick={e => { e.stopPropagation(); this.setState({ guestInfo: orderGuestInfo, isGuestOrder: false }) }}></i>
                                  </span>
                                  : ''}
                              </div>
                            </div>
                            <div>
                              <i className='fas fa-chevron-right' onClick={() => this.setState({ isActiveAdditionalOptionsTab: false })}></i>
                            </div>
                          </div>
                          {/* Duplicate Orders Warning Card*/}{/*
                          {showDuplicateOrdersWarning &&
                            <>
                              <hr />
                              <div
                                className='mt-3 p-3 cursor-pointer d-flex align-items-center justify-content-between alert alert-warning' style={{ borderRadius: 10 }}>
                                <div className='d-flex flex-column'>
                                  <h6 className='orderform-section-name float-left'>Your Other Order</h6>
                                  <h6 style={{ fontSize: 14 }}>
                                    We noticed you have another order at this time as well. You can view all your orders here.&nbsp;
                                    <Link to={`/order-history/${date}/${date}`}>orders here</Link>.
                                  </h6>
                                  <div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          {/* Payment Section in case of Non-Admin Users */}{/*
                          {showPaymentSection && !isCompanyAdmin &&
                            <>
                              <hr />
                              <h4>Credit Card Details</h4>
                              <h5>Your Out of Pocket: ${userOutOfBudgetPrice}</h5>
                              {company_user_credit_card ?
                                <p className='muted'>Your below card on file will be charged the ${userOutOfBudgetPrice} out of pocket.</p> :
                                <p className='muted'>This item requires an out-of-pocket cost not paid by your company. Please provide your credit card details below in order to complete your purchase.</p>
                              }
                              <StripeLayout
                                parentRef={this}
                                fromOrderPopup
                                isLoading={company_data_reducer_loading}
                                setRef={(ref: StripeLayoutRef | null) => { this.stripePaymentRef = ref }}
                                prevPaymentDetails={company_user_credit_card}
                              />
                            </>
                          }
                        </form> */}
                        {/* Delivery Fields Required in case of Admin */}
                        {/* {(field_required && userObj?.user_type === UserType.ADMIN) || (marketPlacePopUp === true) ?
                          <ScheduleDeliveryForm
                            selectedType={undefined}
                            selectedAddress={undefined}
                            meetingStatus={undefined}
                            onRef={(ref: ScheduleDeliveryFormRef | null) => this.scheduleForm = ref}
                            setState={(p: OrderPopupState) => { this.setState(p) }}
                            closeModal={this.closeModal}
                            onUpdatePage={onUpdatePage}
                            orderPopUp={true}
                          /> : ''} */}
                      {/* </div> */}
                    </div>
                    {/* Additional Options Tab Body */}
                    {/* <div
                      className='modal-body order-popup-modal-body p-0 w-100'
                      style={{
                        transition: 'all .5s', top: 0,
                        // ...(isActiveAdditionalOptionsTab ?
                          transform: 'translateX(0)', position: 'relative'
                          // { transform: 'translateX(100%)', position: 'absolute', overflow: 'hidden' }
                        // )
                      }}
                    >
                      <div className='p-4'>
                        <h6 className={`orderform-section-name float-left`}>
                          {i18n && i18n.t('Extra Instructions')}
                        </h6>
                        <h6 id='special' className={`orderform-section-required muted float-right`}>
                          {i18n && i18n.t('Any Special Requests?')}
                        </h6>
                        <div className={`form-check p-0 ${isCompanyAdmin ? 'option_select' : ''}`}>
                          <div className='form-group'>
                            <textarea
                              rows={3}
                              name='remarks'
                              id='special_requests'
                              value={remarks ?? ''}
                              className='form-control p-2'
                              onChange={this.onChangeRemarks}
                              style={{ backgroundColor: 'white' }}
                              disabled={!isActiveAdditionalOptionsTab}
                              placeholder={i18n ? i18n.t('Allergies, spice levels, etc.') : ''}
                            ></textarea>
                          </div>
                        </div>
                        {isCompanyAdmin ? <>
                          <br />
                          <div className='d-flex justify-content-between'>
                            <h6> Is this order for someone else?</h6>
                            <div className='custom-control custom-switch'>
                              <input type='checkbox' className=' custom-control-input' id='mealTypeCheck' name='mealTypeCheck' checked={isGuestOrder} onChange={() => { this.setState({ isGuestOrder: !isGuestOrder, guestInfo: orderGuestInfo }) }} />
                              <label className='custom-control-label' htmlFor='mealTypeCheck'></label>
                            </div>
                          </div>
                          <form className={`${isGuestOrder ? '' : 'disabled-opacity'}`} style={{ transition: '.35s opacity' }}>
                            <div className='row'>
                              <div className='col-12 col-md-6'>
                                <label className='my-2'>Guest's First Name</label>
                                <input
                                  type='text'
                                  name='firstName'
                                  value={firstName}
                                  placeholder='First name'
                                  className='form-control'
                                  disabled={!isGuestOrder}
                                  onChange={this.guestChangeInputHandler}
                                />
                                <span className={`text-danger mt-1 ${firstNameError ? 'd-block' : 'd-none'}`}>
                                  {firstNameError}
                                </span>
                              </div>
                              <div className='col-12 col-md-6'>
                                <label className='my-2'>Guest's Last Name</label>
                                <input
                                  type='text'
                                  name='lastName'
                                  value={lastName}
                                  placeholder='Last name'
                                  className='form-control'
                                  disabled={!isGuestOrder}
                                  onChange={this.guestChangeInputHandler}
                                />
                                <span className={`text-danger mt-1 ${lastNameError ? 'd-block' : 'd-none'}`}>
                                  {lastNameError}
                                </span>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-12 col-md-6'>
                                <label className='my-2'>Guest's Email Address</label>
                                <input
                                  type='email'
                                  className='form-control'
                                  disabled={!isGuestOrder}
                                  value={email} name='email'
                                  placeholder='Email Address'
                                  onChange={this.guestChangeInputHandler}
                                />
                                <span className={`text-danger mt-1 ${emailError ? 'd-block' : 'd-none'}`}>
                                  {emailError}
                                </span>
                              </div>
                              <div className='col-12 col-md-6'>
                                <label className='my-2'>Guest's Phone Number</label>
                                <input
                                  type='text'
                                  name='phoneNumber'
                                  value={phoneNumber}
                                  placeholder='Optional, for Delivery notification'
                                  className='form-control'
                                  disabled={!isGuestOrder}
                                  onChange={this.guestChangeInputHandler}
                                />
                                {phoneNumberError &&
                                  <span className={`text-danger mt-1 `}>
                                  {`${phoneNumber !=='' ? phoneNumberError : ''}`}
                                </span>}
                              </div>
                            </div>
                            {/* <div className='form-group'>
                              <label className='my-2'>Guest's Email Address</label>
                              <input
                                type='email'
                                className='form-control'
                                disabled={!isGuestOrder}
                                value={email} name='email'
                                placeholder='Email Address'
                                onChange={this.guestChangeInputHandler}
                              />
                              <span className={`text-danger mt-1 ${emailError ? 'd-block' : 'd-none'}`}>
                                {emailError}
                              </span>
                            </div> */}{/*
                          </form>
                        </>
                          : ''}
                      </div>
                    </div> */}
                    {/* Nutritional Facts Tabs */}
                    {/* <div
                      className='modal-body order-popup-modal-body p-0 w-100'
                      style={{
                        maxHeight: isActiveNutritionalFactsTab ? modalBodyHeight! : 'calc(95vh - 220px)',
                        height: isActiveNutritionalFactsTab ? (modalBodyHeight ?? 'auto') : 'auto',
                        transition: 'all .5s', top: 0,
                        // ...(isActiveNutritionalFactsTab ?
                          transform: 'translateX(0)', position: 'relative'
                          // { transform: 'translateX(100%)', position: 'absolute', overflow: 'hidden' }
                        // )
                      }}
                    >
                      <div className='p-4'>
                        <h5 className='mb-3 amount-per-serving-heading'>
                          Amount Per Serving
                        </h5>
                        {foodItemNutritions.map(({ childs, id, name, value }, index) =>
                          <React.Fragment key={id}>
                            {index !== 0 && <hr className='my-2' />}
                            <div className='mt-1 d-flex justify-content-between'>
                              <p className='mb-0 amount-per-serving-heading'>{name}</p>
                              <p className='mb-0 amount-per-serving-heading'>{value}g</p>
                            </div>
                            {childs?.map(({ id, name, value }) =>
                              <div key={id} className='pl-3' style={{ color: '#999999' }}>
                                <div className='d-flex justify-content-between'>
                                  <p className='mb-1'>{name}</p>
                                  <p className='mb-1'>{value}g</p>
                                </div>
                              </div>
                            )}
                          </React.Fragment>)
                        }
                      </div>
                    </div> */}
                  </div>
                  {/* Modal Footer */}
                  {/* {!isActiveNutritionalFactsTab && (model_type === 'edit' || model_type === 'add') ? */}
                  {(model_type === 'edit' || model_type === 'add') &&
                    (<form onSubmit={this.onSubmit} id='order-popup-footer' className={`modal-footer bg-white border-0`}>
                      {/* {!isActiveAdditionalOptionsTab ? */}
                      {/* Public Marketplace */}
                      {(this.props.fromPublicMarketPlace || user_company?.self_signup === true) ? 
                        <React.Fragment>
                          {
                            // dayjs(admin_cutoff_at).isAfter(new Date()) ?
                            // marketPlacePopUp ?
                              <>
                                <i className='ml-3 fas fa-minus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.decrementCount} />
                                {/* Order Quantity Input When New Delivery is to be Created from Marketplace In Case of Admin */}
                                <input
                                  id='admin-marketplace-delivery-order-adjustcount'
                                  className='ml-3 form-control'
                                  type='text'
                                  min={1}
                                  value={count}
                                  max={50}
                                  style={{ minWidth: '75px', maxWidth: '75px' }}
                                  onChange={this.adjustCount}
                                  onBlur={this.checkCount}
                                />
                                <i className='ml-3 fas fa-plus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.incrementCount} />
                                <button // Button to Create New Delivery First and then Place Order From Marketplace Page in case of ADMIN
                                  type='submit'
                                  className='ml-3 btn btn-primary buy-button buy-btn-wide'
                                  id='buybutton123'
                                  // disabled={(!company_user_credit_card && !this.props.company_data_reducer?.company_detail_for_order_purpose?.card_details && user_company?.self_signup === true) || submitSchedule || this.state?.loading}
                                  // onClick={() => { setParentState({ update: false }); this.onSubmitSchedule(); this.getPrices(); }}
                                  onClick={() => this.addToCart()}
                                  // onClick={() => { this.props.fromPublicMarketPlace ? this.addToCart() :  this.onSubmitSchedule(); this.getPrices(); }}

                                >
                              {this.state.loader ? (
                                <ButtonSpinner />
                              ) : (
                                <React.Fragment>
                                  {i18n && i18n.t('Add to Order')}
                                  {menu_type === 'buffet' ? '' : ` - $${(count * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString())).toFixed(2)}`}
                                </React.Fragment>
                              )}
                                  {/* {menu_type === 'buffet' ? '' : ' - $' + this.round(parseFloat(`${count}`) * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString()))}  // OLD METHOD OF DISPLAYING TOTAL PRICE CAUSING NaN ON SEVERAL CASES */}
                                  {/* {menu_type === 'buffet' ? '' : ' - $' + (count * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString())).toFixed(2)} */}
                                </button>
                              </>
                              // :
                              // <></>
                              // <p className='text-danger'>Apologies, the cutoff time for this delivery has passed.</p>
                          }
                        </React.Fragment>
                        // {/* End Public Marketplace */}
                      : 
                        <>
                          {/* Company Admin Cases Goes Here */}
                          {userObj?.user_type === UserType.ADMIN ?
                            ((dayjs(admin_cutoff_at).isAfter(new Date()) || isSameDayDeliveryActive || (dayjs(same_day_activation_at).isBefore(new Date()) && dayjs(same_day_admin_cutoff_at).isAfter(new Date()))) ?
                              // <div className='d-flex justify-content-between w-100'>
                              <>
                                <div className='d-flex align-items-center'>
                                  <i className='ml-3 fas fa-minus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.decrementCount} />
                                  {/* Order Quantity Input When Delivery is Already Created In Case of Admin */}
                                  <input
                                    id='admin-created-delivery-order-adjustcount'
                                    className='ml-3 form-control'
                                    type='text'
                                    min={1}
                                    value={count}
                                    max={50}
                                    style={{ minWidth: '75px', maxWidth: '75px' }}
                                    onChange={this.adjustCount}
                                    onBlur={this.checkCount}
                                  />
                                  <i className='ml-3 fas fa-plus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.incrementCount} />
                                </div>
                                {/* show loading on buy button untill order loads from api */}
                                {(!order.loading) ?
                                  (<span>
                                    {menu_type !== 'buffet' ?
                                      (<button // Button to Create New Order For Already Created Meeting of Type INDIVIUAL in case of ADMIN
                                        type='submit'
                                        className='ml-3 btn btn-primary buy-button buy-btn-wide'
                                        id='buybutton'
                                        disabled={(per_user_copay === 0 && parseFloat(this.calculatePrice(user_company?.markup).toString()) > (parseFloat(`${user_company?.budget}`))) ? true : false}
                                        // onClick={() => { setParentState({ update: true }); this.getPrices(); }}
                                        onClick={() => { this.getPrices(); }}
                                      >
                                        {model_type === 'edit' ? 'Update' : i18n && i18n.t('Add to Order')}
                                        {/* {' - $' + this.round(parseFloat(`${count}`) * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString()))}  // OLD METHOD OF DISPLAYING TOTAL PRICE CAUSING NaN ON SEVERAL CASES */}
                                        {' - $' + (count * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString())).toFixed(2)}
                                      </button>)
                                      :
                                      (<button // Button to Create New Order For Already Created Meeting of Type BUFFET in case of ADMIN
                                        type='submit'
                                        className='ml-3 btn btn-primary buy-button buy-btn-wide'
                                        id='buybutton'
                                        // onClick={() => { setParentState({ update: true }); this.getPrices(); }}
                                        onClick={() => { this.getPrices(); }}
                                      >
                                        {model_type === 'edit' ? 'Update' : i18n && i18n.t('Add to Order')}
                                      </button>)
                                    }
                                  </span>)
                                  :
                                  // Spinner Button after Create new Order Request for Already Created Meeting for ADMIN
                                  (<button className='ml-3 btn btn-primary buy-button buy-btn-wide' disabled>
                                    <ButtonSpinner />
                                  </button>)
                                }
                              </>
                              // </div>
                              :
                              // Admin Cases for Marketplace Orders or Update Orders with Cuttoff Passed goes here
                              <React.Fragment>
                                {
                                  marketPlacePopUp ?
                                    <>
                                      <i className='ml-3 fas fa-minus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.decrementCount} />
                                      {/* Order Quantity Input When New Delivery is to be Created from Marketplace In Case of Admin */}
                                      <input
                                        id='admin-marketplace-delivery-order-adjustcount'
                                        className='ml-3 form-control'
                                        type='text'
                                        min={1}
                                        value={count}
                                        max={50}
                                        style={{ minWidth: '75px', maxWidth: '75px' }}
                                        onChange={this.adjustCount}
                                        onBlur={this.checkCount}
                                      />
                                      <i className='ml-3 fas fa-plus-circle fa-lg' style={{ color: '#6c757d' }} onClick={this.incrementCount} />
                                      <button // Button to Create New Delivery First and then Place Order From Marketplace Page in case of ADMIN
                                        type='submit'
                                        className='ml-3 btn btn-primary buy-button buy-btn-wide'
                                        id='buybutton123'
                                        disabled={submitSchedule || this.state?.loading}
                                        // onClick={() => { setParentState({ update: false }); this.onSubmitSchedule(); this.getPrices(); }}
                                        onClick={() => { this.onSubmitSchedule(); this.getPrices(); }}
                                      >
                                        {i18n && i18n.t('Add to Order')}
                                        {/* {menu_type === 'buffet' ? '' : ' - $' + this.round(parseFloat(`${count}`) * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString()))}  // OLD METHOD OF DISPLAYING TOTAL PRICE CAUSING NaN ON SEVERAL CASES */}
                                        {menu_type === 'buffet' ? '' : ' - $' + (count * parseFloat(this.getPricesToShown(itemPrice, skip_markup).toString())).toFixed(2)}
                                      </button>
                                    </>
                                    :
                                    <p className='text-danger'>Apologies, the cutoff time for this delivery has passed.</p>
                                }
                              </React.Fragment>)
                            : // Company Contract User (UNSUBSIDIZED_USER) Cases Goes Here
                            userObj?.user_type === UserType.UNSUBSIDIZED_USER ?
                              (dayjs(cutoff_at).isAfter(new Date()) ?
                                (<React.Fragment>
                                  {(!loading) ?
                                    (<span className='col-12 footer-spacing'>
                                      <button
                                        type='submit'
                                        className='ml-3 btn btn-primary buy-button float-right'
                                        id='unsubsidized_user-buy-button'
                                        disabled={this.state?.loading}
                                        onClick={() => { this.getPrices(); }}
                                      >
                                        {i18n && model_type === 'add' ? i18n.t('Add to Order') : i18n?.t('Update')}
                                        {' - $' + userOutOfBudgetPrice}
                                      </button>
                                    </span>)
                                    :
                                    (<button className='ml-3 btn btn-primary buy-button buy-btn-wide' disabled>
                                      <ButtonSpinner />
                                    </button>)
                                  }
                                </React.Fragment>)
                                :
                                <React.Fragment>
                                  <p className='text-danger'>{i18n && i18n.t('Apologies, the cutoff time for this delivery has passed.')}</p>
                                </React.Fragment>)
                              : // Company User and Manager Cases Goes Here
                              ((dayjs(cutoff_at).isAfter(new Date()) || (dayjs(same_day_activation_at).isBefore(new Date()) && dayjs(same_day_cutoff).isAfter(new Date()))) ?
                                (<React.Fragment>
                                  {(!loading) ?
                                    (<span className='col-12 footer-spacing'>
                                      {(per_user_copay === 0 && (remaining_budget !== undefined && parseFloat(`${this.calculateEditPrice()}`) > (parseFloat(remaining_budget)))) ?
                                        (<span className='text-danger subsidy-line-height'>
                                          {user_company && user_company.show_remaining_budget && menu_type !== 'buffet' ?
                                            <span className='text-primary subsidy-line-height'>
                                              Remaining Subsidy: ${remaining_budget ? parseFloat(remaining_budget) : 0}.
                                            </span>
                                            : ''
                                          }
                                          <span className='text-primary subsidy-line-height'>
                                            Item out of budget.
                                          </span>
                                        </span>) :
                                        (<span>
                                          {user_company && user_company.show_remaining_budget && menu_type !== 'buffet' && per_user_copay === 0 ?
                                            <span className='text-primary subsidy-line-height'>
                                              Remaining Subsidy: ${remaining_budget ? parseFloat(remaining_budget) : 0}
                                            </span> : ''
                                          }
                                        </span>)
                                      }
                                      <button
                                        type='submit'
                                        className='ml-3 btn btn-primary buy-button float-right'
                                        id='buybutton'
                                        disabled={(per_user_copay === 0 && (remaining_budget !== undefined && parseFloat(`${this.calculateEditPrice()}`) > (parseFloat(remaining_budget)))) ? true : false}
                                        // onClick={() => { setParentState({ update: true }); this.getPrices(); }}
                                        onClick={() => { this.getPrices(); }}
                                      >
                                        {(user_company && (per_user_copay === 0)) ?
                                          (i18n && model_type === 'add' ? i18n.t('Add to Order') : i18n?.t('Update')) :
                                          model_type === 'edit' ? 'Update' : i18n && i18n.t('Add to Order')}
                                        {(user_company && (per_user_copay === 0)) ?
                                          '' :
                                          ' - $' + userOutOfBudgetPrice
                                        }
                                      </button>
                                    </span>)
                                    :
                                    (<button className='ml-3 btn btn-primary buy-button buy-btn-wide' disabled>
                                      <ButtonSpinner />
                                    </button>)
                                  }
                                </React.Fragment>)
                                :
                                <React.Fragment>
                                  <p className='text-danger'>{i18n && i18n.t('Apologies, the cutoff time for this delivery has passed.')}</p>
                                </React.Fragment>)
                          }
                        </>
                      }
                      {/* :
                        <button
                          type='button'
                          style={{ width: 132 }}
                          className={'ml-3 btn btn-primary buy-button buy-btn-wide'}
                          onClick={this.saveAdditionalOptions}
                        >
                          Save
                        </button>
                      } */}
                    </form>)
                  }
                </> :
                showConfirmEmptyCartPopUp === true ? 
                <>
                   <div className="modal-content cursor-default p-32">
                    <div className="cart-delete-modal cart-delete-modal-body mx-auto">
                      <div className="mx-auto d-flex aling-items-center justify-content-center">
                        <div className="trash-bin-wrapper">
                          <i className="far fa-trash-alt" aria-hidden="true"></i>
                        </div>
                      </div>
                      <h5 className='fw-600 text-gray-primary mb-0 text-center pt-3'>Empty Shopping Cart</h5>
                      <p className=" fs-18 m-0 text-center" style={{}}>
                        {`Adding this item will clear your previous cart and begin a new order. Are you sure you want to delete ${user_company?.self_signup === true ? (this.props.serverCart?.orders_quantity ?? 0) : (this.props.cartDetailsLocal?.itemCount ?? 0) } items and start fresh?`}
                      </p>
                        
                      <div className="pt-4 d-flex justify-content-center align-items-center">
                        <button className="btn rounded-pill cart-item-keep-btn"  onClick={() => {this.closeModal(); this.removeLocalOrder()}}>
                          {i18n && i18n.t('Keep It')}
                        </button>
                        <button className ="btn rounded-pill cart-item-delete ml-3" onClick={() => {this.closeModal(); this.placeConfirmedCartOrder()}}>
                          {i18n && i18n.t('Yes, Empty It')}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className='' style={{borderTop: '1px solid #dee2e6'}}>
                    <div className='d-flex flex-column justify-content-center align-items-center px-4' style={{ minHeight: 266, gap: 20 }}>
                      <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                      
                        <p style={{ fontSize: 18, color: '#646464' }} className='text-center'>
                          {i18n && i18n.t('Empty Cart abc!')}
                          <br />
                        </p>
                    </div>
                    <div className={`modal-footer`}>
                    <button className='ml-3 btn btn-primary buy-button buy-btn-wide' onClick={() => {this.setState({showConfirmEmptyCartPopUp: false, modalIsOpen: false}) }}>
                        {i18n && i18n.t('Cancel')}
                      </button>
                      <button className='ml-3 btn btn-primary buy-button buy-btn-wide' onClick={() => this.placeConfirmedCartOrder() }>
                        {i18n && i18n.t('Add to Order')}
                      </button>
                    </div>
                  </div> */}
                </>
                  :
                  <div className='' style={{borderTop: '1px solid #dee2e6'}}>
                    <div className='d-flex flex-column justify-content-center align-items-center px-4' style={{ minHeight: 266, gap: 20 }}>
                      <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                      
                        <p style={{ fontSize: 18, color: '#646464' }} className='text-center'>
                          {i18n && i18n.t('You’re All Set!')}
                          <br />
                          <span>{i18n && i18n.t('Your order for')}</span>
                          <span style={{ fontWeight: 700 }} className='text-capitalize'> {itemName} </span>
                          <span>{i18n && i18n.t('from')}</span>
                          <span style={{ fontWeight: 700 }} className='text-capitalize'> {restaurants_name} </span> 
                          <span>{i18n && i18n.t('has been received!')}</span>
                        </p>
                    </div>
                    {/* <div id='order-popup-body' className='modal-body'>
                      <div className='wrapper'>
                        <span id='slide'>
                          <h1> {i18n && i18n.t('You’re All Set!')}</h1>
                          <br /> {i18n && i18n.t('Your order for')} <b className='text-case text-capitalize'>{itemName} </b>
                          {i18n && i18n.t('from')} <b> {restaurants_name}</b>  {i18n && i18n.t('has been received!')}<p></p>
                        </span>
                      </div>
                      <Confetti canvasRef={this.confettiCanvasRef} numberOfPieces={350} gravity={0.03} opacity={0.65} width={490} height={180} recycle={false} />
                    </div> */}
                    <div className={`modal-footer`}>
                      <button className='ml-3 btn btn-primary buy-button buy-btn-wide' onClick={() => {this.closeModal(); this.setOrderConfirmModal() }}>
                        {i18n && i18n.t('Okay')}
                      </button>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  order: state.order,
  errors: state.errors,
  i18n: state.i18n.i18n,
  company_data_reducer: state.company_data_reducer,
  cuisines: state.cuisine_list.cuisines,
  schedules_details: state.schedules_details,
  isOpenPopupOnMenuDetailPage: state.menu.isOpenPopupOnMenuDetailPage,
  cartSummary: state?.cart?.summary,
  cartItems: state?.cart?.items,
  serverCart: state?.server_cart.cart_at_login,
  cartAddressIds: state?.server_cart?.address_ids,
  cartAddressIdsLocal: state?.cart?.address_ids,
  confirmedCartOrderData: state?.server_cart?.cart_order_data_for_confirmation,
  cartOrderForConfirmation: state?.cart_order_pending_confirmation,
  confirmedItemName: state?.server_cart?.itemName,
  cartDetailsLocal: state?.cart?.details,
  // addressIdForDeliveryAt: state?.addresses?.public_addresses,
  selectedAddressID: state?.addresses?.selectedAddressId,
  addresses: state.addresses,
  allowedRestaurantsIdArray: state?.server_cart?.address_ids,
  // credit_card_info: state?.company_data_reducer?.company_detail_for_order_purpose?.card_details
  deliveryAtforCart: state.order?.self_signup_delivery_orders_details?.delivery_at,
  timeZoneforCart: state.order?.self_signup_delivery_orders_details?.time_zone
});

export type OrderPopupRef = React.ElementRef<typeof OrderPopup>;

export default connect(mapStateToProps, { placeOrder, getSingleOrder, updateSingleOrder, getCompanyForOrderPurpose, makeDeliveryRequest, getDeliveryDates, addToCart, placeCartOrder, updateCartOrder })(withRouter(OrderPopup));