import { GET_COMPANY_FOR_ORDER_PURPOSE, UPDATE_COMPANY_FOR_ORDER_PURPOSE, GET_CHILD_COMPANIES_LIST, GET_COMPANY_ADMINS_LIST, COMPANY_REDUCER_LOADING, GET_COMPANY_LOCATIONS, DELETE_COMPANY_ADMIN, DELETE_COMPANY_LOCATION, GET_COMPANY_TAGS, GET_COMPANY_TIMEZONES, GET_COMPANY_ADDRESSES_FOR_USER, CONVERTED_TO_ENTERPRIZE, RESET_STATE } from '../Actions/types';
import { CompanyData } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: CompanyData = {
  company_data_reducer_loading: false,
  company_detail_for_order_purpose: null,
  child_companies: null,
  company_admins: null,
  company_locations: null,
  company_tags: [],
  company_time_zones: [],
  company_addresses: [],
  converted_to_enterprize: false
}

const companyReducer = (state: CompanyData = initialState, action: any): CompanyData => {
  switch (action.type) {
    case COMPANY_REDUCER_LOADING:
      return {
        ...state,
        company_data_reducer_loading: true
      }
    case GET_COMPANY_FOR_ORDER_PURPOSE:
      return {
        ...state,
        company_detail_for_order_purpose: action.payload,
        company_data_reducer_loading: false
      }
    case UPDATE_COMPANY_FOR_ORDER_PURPOSE:
      return {
        ...state,
        company_detail_for_order_purpose: action.payload,
      }
    case GET_CHILD_COMPANIES_LIST:
      return {
        ...state,
        child_companies: action.payload,
        company_data_reducer_loading: false
      }
    case GET_COMPANY_ADMINS_LIST:
      return {
        ...state,
        company_admins: action.payload,
        company_data_reducer_loading: false
      }
    case GET_COMPANY_LOCATIONS:
      return {
        ...state,
        company_locations: action.payload,
        company_data_reducer_loading: false
      }
    case DELETE_COMPANY_ADMIN:
      let updatedAdmins = {
        company_admins: state?.company_admins?.company_admins.filter((item) => item.id !== action.payload) || []
      }
      return {
        ...state,
        company_admins: state.company_admins ? {
          ...state.company_admins,
          company_admins: updatedAdmins.company_admins
        } : null,
      }
    case DELETE_COMPANY_LOCATION:
      let updatedLocations = {
        locations: state?.company_locations?.locations.filter((item) => item.id !== action.payload) || []
      }
      return {
        ...state,
        company_locations: state.company_locations ? {
          ...state.company_locations,
          locations: updatedLocations.locations
        } : null
      }
    case GET_COMPANY_TAGS:
      return {
        ...state,
        company_tags: action.payload
      }
    case GET_COMPANY_TIMEZONES:
      return {
        ...state,
        company_time_zones: action.payload
      }
    case GET_COMPANY_ADDRESSES_FOR_USER:
      return {
        ...state,
        company_addresses: action.payload
      }
      case CONVERTED_TO_ENTERPRIZE:
        return {
          ...state,
          converted_to_enterprize: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default companyReducer;