// Packages
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
// React JSX Components
import ButtonSpinner from '../Spinners/ButtonSpinner';
import ScheduleDeliveryForm, { ScheduleDeliveryFormRef } from '../ScheduleDeliveryForm';
// Redux Dispatch Actions
// Utilities
// Types
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { MarketPlaceCompanyAddress } from '../../Interfaces/PayloadsAndResponses/Company';
// CSS

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '752px',
    padding: '0'
  }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
Modal.defaultStyles.overlay!.zIndex = 6;

export type MarketplaceMeeting = {
  mealType: string;
  deliveryInstructions: string | undefined;
  selectedCompanyAddress: MarketPlaceCompanyAddress | undefined;
  selectedCompanyAddressID: number | undefined;
}

type CreateNewMeetingPopUpProps = {
  i18n: WithTranslation | null,
  onRef?: (ref: CreateNewMeetingPopUpRef | null) => void,
  selectedDate: Date | undefined,
  selectedType?: string | null,
  selectedMealTypeForMenuCheckAPI: string,
  selectedAddress?: string | null,
  onUpdatePage: () => void,
  fromDashboard?: boolean,
  modalState:boolean,
  onCloseModal: ()=>void,
  marketPlace?: boolean,
  meeting?: MarketplaceMeeting,
  selectedRestaurants?: number[],
  createSchedular?: boolean,
}
export type CreateNewMeetingPopUpState = {
  modalIsOpen: boolean,
  meetingUpdateSlug: string | undefined,
  showLoading: boolean,
  pending: boolean,
  meetingStatus: string | undefined,
  requiredFields: boolean,
}
class CreateNewMeetingPopUp extends Component<CreateNewMeetingPopUpProps, CreateNewMeetingPopUpState> {

  state = {
    modalIsOpen: false,
    meetingUpdateSlug: '',
    showLoading: false,
    pending: false,
    meetingStatus: '',
    requiredFields: false
  };

  closeModal = () => {
    this.props.onCloseModal()
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value } as unknown as CreateNewMeetingPopUpState)
  }
  onSubmit = () => {
    this?.sch_req_form?.onSubmit();
  }

  sch_req_form = React.createRef<ScheduleDeliveryFormRef>().current;

  render() {
    return (
      <Modal
        isOpen={this.props.modalState}
        onRequestClose={this.closeModal}
        style={{ ...customStyles, content: { ...customStyles?.content, borderRadius: this.props?.fromDashboard ? '18px' : '4px' } }}
        contentLabel='Begin Order Modal'
      >
        <div className='order-modal' id='begin-order-orderModelBox'>
          <div className='modal-dialog' style={{ maxWidth: '100%' }}>
            <div className='modal-content' style={{ border: 'none' }}>
                <div className='modal-header' style={{ width: 750 }}>
                  <h5 className='modal-title' id='modal-title'> Begin Order </h5>
                  <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
                </div> 
              <div id='mydiv' className='modal-body' style={{ width: 750 }}>
                {/* use props from msrketplace are, selectedRestaurants, marketPlace, meeting  */}
                <ScheduleDeliveryForm
                  selectedDate={this.props.selectedDate}
                  selectedType={this.props.selectedType}
                  selectedMealTypeForMenuCheckAPI={this.props.selectedMealTypeForMenuCheckAPI}
                  selectedAddress={this.props.selectedAddress}
                  meetingUpdateSlug={this.state.meetingUpdateSlug}
                  meetingStatus={this.state.meetingStatus}
                  onRef={(ref: ScheduleDeliveryFormRef | null) => { this.sch_req_form = ref }}
                  setState={(p: CreateNewMeetingPopUpState) => { this.setState(p) }}
                  closeModal={this.closeModal}
                  onUpdatePage={this.props.onUpdatePage}
                  requiredFields={this.state.requiredFields}
                  marketPlace={this.props.marketPlace}
                  selectedRestaurants={this.props.selectedRestaurants}
                  meeting={this.props.meeting}
                  createSchedular={this.props.createSchedular}
                />
              </div>
              <div className={`modal-footer`} style={{ width: 750 }}>
                <button
                  type='submit'
                  onClick={this.onSubmit}
                  className={`confirm-update-delivery ml-3 btn delivery-btn-width btn-success`}
                >
                  {(this.state.showLoading === true) ? 
                  <ButtonSpinner /> :
                  (this.props.i18n && this.props.i18n.t('Begin Order'))}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  i18n: state.i18n.i18n
});

export type CreateNewMeetingPopUpRef = React.ElementRef<typeof CreateNewMeetingPopUp>;

export default connect(mapStateToProps, {})(CreateNewMeetingPopUp);