// import React, { Component, createRef } from 'react';
// import { RouteComponentProps } from 'react-router-dom';
// import Header from '../../Common/HeaderFooter/Header';
// import GenericMeetingPopUp, { GenericMeetingPopUpRef } from '../../Common/Popups/GenericMeetingPopUp';

// type Params = { token: string, meetingSlug: string };
// type GenericShareMeetingProps = RouteComponentProps<Params>;
// class GenericShareMeeting extends Component<GenericShareMeetingProps, {}> {
//   genericMeeting = createRef<GenericMeetingPopUpRef>().current;

//   componentDidMount() {
//     this.genericMeeting?.openModal();
//   }

//   render() {
//     const { token: token_to_create_share_meeting, meetingSlug: shared_meeting_slug } = this.props.match.params;
//     return (
//       <React.Fragment>
//         <Header />
//         <GenericMeetingPopUp
//           onRef={(ref: GenericMeetingPopUpRef | null) => { this.genericMeeting = ref }}
//           token={token_to_create_share_meeting}
//           meetingSlug={shared_meeting_slug}
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default GenericShareMeeting;


import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../Common/HeaderFooter/Header';
import GenericMeetingPopUp, { GenericMeetingPopUpRef } from '../../Common/Popups/GenericMeetingPopUp';




const GenericShareMeeting: FC = () => {

  const history = useHistory();
  const token_to_create_share_meeting = history.location.pathname.split('/')[3];
  const shared_meeting_slug = history.location.pathname.split('/')[2];
  const genericMeeting = useRef<GenericMeetingPopUpRef | null>(null);
  

  useEffect(() => {
    genericMeeting.current?.openModal();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <GenericMeetingPopUp
        ref={(ref: GenericMeetingPopUpRef | null) => { genericMeeting.current = ref }}
        token={token_to_create_share_meeting}
        meetingSlug={shared_meeting_slug}
      />
    </React.Fragment>
  );
};

export default GenericShareMeeting;
