import React, { forwardRef } from 'react';
import Modal from 'react-modal';
import ModalHeader from '../ModalHeader';
import { MarketPlaceState } from '../../Pages/Menu/MarketplacePreview/Marketplace';
import MarketplaceSearchBar from '../../Pages/Menu/MarketplacePreview/MarketplaceSearchBar';
import { MarketPlaceCompanyAddress } from '../../Interfaces/PayloadsAndResponses/Company';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    padding: '0'
  }
};
Modal.setAppElement('#root');
type Props = {
  isOpen: boolean,
  closeModal: () => void,
  startDate: Date | undefined;
  handleDateChange: (date: Date | null) => void;
  onChangeMealType: (meal_type: string) => void;
  meal_type: string;
  marketPlaceState: MarketPlaceState;
  // Old
  // selectSearchStatistics: (id: number | null, key: 'dietaries' | 'ingredients' | 'cuisines', shouldClear?: boolean) => void;
  // New
  selectSearchStatistics: (name: string | null, key: 'dietaries' | 'ingredients' | 'cuisines', shouldClear?: boolean) => void;
  lessMoreHandler: (id: string, buttonType: string, divId: string, onPopup: boolean) => void;
  searchRestaurants: (shouldClear: boolean) => void;
  openModal: () => void;
  selectedCompanyAddress: MarketPlaceCompanyAddress | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  keyPressed: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  closeSearch: (searchName: string) => void;
  onFilter: (sortBy: string) => void;
  applyFilter: () => void;

};
const MarketplaceFiltersPopup = forwardRef(({
  applyFilter,
  isOpen,
  closeModal,
  startDate,
  handleDateChange,
  onChangeMealType,
  meal_type,
  marketPlaceState,
  selectSearchStatistics,
  lessMoreHandler,
  searchRestaurants,
  openModal,
  selectedCompanyAddress,
  onChange,
  keyPressed,
  closeSearch,
  onFilter,
}: Props, ref) => {
  const applyFilters = () => {
    closeModal();
    applyFilter();
  };

  const clearFilters = () => {
    selectSearchStatistics('', 'cuisines', true);
    selectSearchStatistics('', 'dietaries', true);
    selectSearchStatistics('', 'ingredients', true);
    searchRestaurants(true);
    closeModal();
  };

  return (
    <Modal
      isOpen={false}
      style={customStyles}
      onRequestClose={closeModal}
      contentLabel='Filter Results'
    >
      <ModalHeader
        label={`Filter Results`}
        onModalClose={closeModal}
      />
      <div id='marketplacePopupBody' className='modal-body main-market-place-filter-bar'>
        <MarketplaceSearchBar
          startDate={startDate}
          onPopup={true}
          closePopup={closeModal}
          handleDateChange={handleDateChange}
          onChangeMealType={onChangeMealType}
          meal_type={meal_type}
          marketPlaceState={marketPlaceState}
          selectSearchStatistics={selectSearchStatistics}
          lessMoreHandler={lessMoreHandler}
          searchRestaurants={searchRestaurants}
          openModal={openModal}
          selectedCompanyAddress={selectedCompanyAddress}
          onChange={onChange}
          keyPressed={keyPressed}
          closeSearch={closeSearch}
          sortBy={onFilter}
        />
      </div>
      <div className='modal-footer'>
        <button className='mr-auto btn btn-light float-left' onClick={clearFilters}>
          <i className='fas fa-minus-circle pr-1' aria-hidden='true' /> Clear
          Filters
        </button>
        <button
          type='submit'
          className='float-right btn btn-primary btn-primary-cmill marketplace-apply-filter buy-btn-wide'
          id='buybutton123'
          onClick={applyFilters}
        >
          <i className='fas fa-list-ul pr-1' aria-hidden='true' /> Apply Filters
        </button>
      </div>
    </Modal>
  );
});

export default MarketplaceFiltersPopup;