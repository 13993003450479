// Packages
import React, { Component } from 'react';
import $ from 'jquery';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
// React Components
import Spinner from './Spinners/Spinner';
// Redux Actions
import { getCuisine } from '../Actions/cuisineActions';
import { getSchedule } from '../Actions/menuActions';
import { getCompanyAddresses, getCustomFields } from '../Actions/companyActions';
import { makeDeliveryRequest, updateScheduleRequest, getSingleSchedule } from '../Actions/scheduleDeliveryActions';
// Utitlities
import store from '../store';
import isEmpty from '../Utilities/is_empty';
import { getloggedInUser } from '../Utilities/getloggedInUser';
// import { setTimeWithinLimit } from '../Utilities/setTimeWithinLimit';
import { showToastNotification } from '../Utilities/showToastNotification';
// Types
import { WithTranslation } from 'react-i18next';
import { MarketplaceMeeting } from './Popups/MeetingDetailsPopUp';
import { Cuisine } from '../Interfaces/PayloadsAndResponses/Cuisines';
import { CloneOrderCustomFields } from '../Pages/Menu/Common/CloneOrders';
import { LocalStorageCompany, User, UserType } from '../Interfaces/PayloadsAndResponses/Auth';
import { DeliveryCuisines, DeliveryCustomFieldPayload, DeliveryCustomFields, DeliveryRequestPayload } from '../Interfaces/PayloadsAndResponses/Schedules';
import { GetCompanyCustomFields, MarketPlaceCompanyAddress } from '../Interfaces/PayloadsAndResponses/Company';
import { Addresses, Errors, Menu, ReduxRootState, SchedulesDetails } from '../Interfaces/ReduxInterface/ReduxRootState';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import { getDateWithTimeZone } from '../Utilities/functions';

const MAX_CUISINES = 15;

const { loggedInUser, loggedInUserCompany } = getloggedInUser();

type Params = { date: string, meetingSlug?: string };

type SelectedCuisine = {
  id?: number;
  cuisine_id?: number;
  _destroy?: boolean;
  label: string;
  value: number;
}

type ScheduleDeliveryFormCustomField = {
  // created_at?: string;
  field_id?: number
  field_type: 'text' | 'dropdown' | 1 | 0;
  // fieldoption_id?: null | number;
  id: number;
  // latest_version_id?: null | number;
  name: string;
  options?: ({
    id: number;
    name: string;
    selected?: boolean;
  })[];
  // recurring_scheduler_id?: null | number;
  required: 1 | 0 | boolean;
  runningmenu_id?: number;
  // updated_at?: string;
  value?: string | number;
}

type ScheduleDeliveryFormProps = {
  orderPopUp?: boolean;
  selectedDate?: Date;
  selectedType?: string | null | undefined;
  selectedMealTypeForMenuCheckAPI?: string;
  selectedAddress?: string | null | undefined;
  meetingUpdateSlug?: string;
  meetingStatus?: string;
  onRef?: (ref: ScheduleDeliveryFormRef | null) => void;
  closeModal: () => void;
  setState?: (parentState: any) => void;
  onUpdatePage: () => void;
  requiredFields?: boolean;
  marketPlace?: boolean | undefined;
  marketPlacePage?: boolean | undefined;
  selectedRestaurants?: Array<number>;
  meeting?: MarketplaceMeeting | undefined;
  cloneOrderPurposes?: boolean;
  orderFieldAttributes?: CloneOrderCustomFields;
  userObj?: User; // Set to Optional due to Default Props Type Issue
  companyObj?: LocalStorageCompany; // Set to Optional due to Default Props Type Issue
  errors: Errors;
  addresses: Addresses;
  menu: Menu;
  cuisines: Cuisine[] | null;
  schedules_details: SchedulesDetails;
  i18n: WithTranslation | null;
  allowedRestaurantsIdArray: number[];
  getCompanyAddresses: () => void;
  getCustomFields: () => void;
  getSchedule: (token?: string) => void;
  getCuisine: () => void;
  getSingleSchedule: (scheduleSlug: string, setLoading?: boolean, token?: string) => void;
  updateScheduleRequest: (requestedScheduleSlug: string, requestData: DeliveryRequestPayload, updateScheduleCallback?: () => void) => void
  makeDeliveryRequest: (requestData: DeliveryRequestPayload, updataeDeliveryDates?: boolean, cloneOrdersIds?: number[], createNewScheduleCallback?: () => void, createSchedular?: boolean) => void;
  createSchedular?: boolean;
} & RouteComponentProps<Params>;

type ScheduleDeliveryFormState = {
  time: string;
  cancel: boolean;
  delivery_at: string | undefined;
  // submit: boolean;
  mealBudget: string;
  orderCount: number;
  pending: boolean;
  menuType: boolean;
  // schedule_id: '',
  meetingName: string;
  admin_cutoff: string;
  postCuisines: SelectedCuisine[];
  typeValue: string;
  // showLoading: boolean;
  specialRequests: string | null;
  // oldCustomFields: [],
  // send_request: false,
  // minTime: new Date(),
  // maxTime: new Date(),
  selectedCuisines: SelectedCuisine[];
  minimumMealBudget: string;
  buffetPerUserBudget: string;
  openCustomSelect: boolean;
  orderFieldAttributes: ScheduleDeliveryFormCustomField[]; //(DeliveryCustomFields | DeliveryCustomFields | CloneOrderCustomFields | CompanyCustomField)[];
  alreadySavedCuisines: DeliveryCuisines[];
  scheduleFromOrder: boolean;
  requestedScheduleSlug: string;
  maxCuisinesReached: boolean;
  delivery_instructions: string | null;
  selectedCompanyAddressID: number | null;
  selectedCompanyAddress: null | MarketPlaceCompanyAddress,
  hide_meeting_local_check: boolean;
  // date: string;
  startDate: Date;
}

class ScheduleDeliveryForm extends Component<ScheduleDeliveryFormProps, ScheduleDeliveryFormState> {
  static defaultProps = {
    userObj: loggedInUser,
    companyObj: loggedInUserCompany,
  }
  state: ScheduleDeliveryFormState = {
    time: '11:45',
    delivery_at: undefined,
    cancel: false,
    // submit: false,
    mealBudget: '',
    orderCount: 1,
    pending: false,
    menuType: false,
    // schedule_id: '',
    meetingName: '',
    admin_cutoff: '',
    postCuisines: [],
    typeValue: 'lunch',
    // showLoading: false,
    specialRequests: '',
    // oldCustomFields: [],
    // send_request: false,
    // minTime: new Date(),
    // maxTime: new Date(),
    selectedCuisines: [],
    minimumMealBudget: '',
    buffetPerUserBudget: '',
    openCustomSelect: false,
    orderFieldAttributes: [],
    alreadySavedCuisines: [],
    scheduleFromOrder: false,
    requestedScheduleSlug: '',
    maxCuisinesReached: false,
    delivery_instructions: '',
    hide_meeting_local_check: this.props?.schedules_details?.single_schedule?.schedule?.hide_meeting ?? false,
    selectedCompanyAddressID: null,
    selectedCompanyAddress: null,
    // date: this.props.selectedDate ? this.props.selectedDate : '',
    startDate: this.props.selectedDate ?? dayjs((new Date()).toString()).add(2, 'day').toDate()
  }
  componentDidMount() {
    const {
      userObj,
      marketPlace,
      meeting,
      selectedType,
      selectedMealTypeForMenuCheckAPI,
      meetingStatus,
      selectedDate,
      cloneOrderPurposes,
      orderFieldAttributes,
      getSchedule,
      getCuisine,
      getCompanyAddresses,
      getCustomFields,
      onRef,
      companyObj: { user_meal_budget } = {},
      match: { params: { meetingSlug } }
    } = this.props;

    if (marketPlace) {
      this.setState({
        orderCount: 1,
        meetingName: `${userObj?.first_name}’s New Delivery`,
        typeValue: meeting?.mealType ?? 'lunch',
        selectedCompanyAddress: meeting?.selectedCompanyAddress ?? null,
        selectedCompanyAddressID: meeting?.selectedCompanyAddressID ?? null,
        delivery_instructions: meeting?.deliveryInstructions ?? '',
        menuType: selectedMealTypeForMenuCheckAPI === 'buffet' || selectedType === 'buffet',
        hide_meeting_local_check: true
      })
    }
    if (meetingStatus === 'create' && selectedDate) {
      if (marketPlace) {
        this.setState({
          // minTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 11:45:00'),
          // maxTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00'),
          startDate: new Date(dayjs(new Date(selectedDate)).format('ddd MMM D YYYY  hh:mm a'))
        });
      } else {
        this.setState({
          // minTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 11:45:00'), 
          // maxTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00'), 
          startDate: new Date(dayjs(new Date(selectedDate)).format('ddd MMM D YYYY') + ' 11:45:00')
        });
      }
    }
    if (cloneOrderPurposes && orderFieldAttributes) {
      const mealBudget = this.state.mealBudget || (!meetingSlug ? user_meal_budget! : this.state.mealBudget);
      this.setState({ orderFieldAttributes: orderFieldAttributes, mealBudget }) // Update Meal Budget if Cloning Orders and Delivery is not created yet (ON MARKETPLACE)
    } else {
      getCustomFields();
      getCuisine();
      getSchedule();
      getCompanyAddresses();
    }
    this.getRequestDataProps();
    onRef?.(this);
    var scheduleDelForm = document.querySelector('#orderModelBox');
    if (scheduleDelForm) {
      scheduleDelForm.addEventListener('click', e => this.toggleCustomSelect(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>));
    }
  }
  componentWillUnmount() {
    this.props.onRef?.(null)
  }
  getRequestDataProps() {
    if (this.props.meetingUpdateSlug) {
      this.props.getSingleSchedule(this.props.meetingUpdateSlug, false);
    }
  }
  highlightRequiredFields(fields: GetCompanyCustomFields['custom_fields'] | undefined) {
    if (this.props.userObj?.user_type === UserType.ADMIN && fields) {
      fields.forEach(field => {
        let requiredSelectedElement = document.getElementById(field.id + field.name) as (HTMLSelectElement | HTMLInputElement);
        if (field.required === 1 && requiredSelectedElement && !requiredSelectedElement?.value) {
          document.getElementById(`custom-${field.id}`)?.classList.add('required-error-highlight');
        }
      });
    }
  }
  setcustomFields(fields: DeliveryCustomFields[]) {
    const customFields = fields;
    customFields.forEach(key => {
      if (key.field_type === 'dropdown') {
        key.options.forEach(option => {
          if (option.selected) {
            key.value = `${option.id}`;
          }
          return false;
        })
      }
      return false;
    });
    this.setState({ orderFieldAttributes: customFields })
  }

  convertUTCToTimezone(utcString: string, timezone: string) {
    const date = new Date(utcString);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timezone
    };
    const formatter = new Intl.DateTimeFormat([], options);
    const formattedDateString = formatter.format(date);
    return formattedDateString;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ScheduleDeliveryFormProps) {

    const { menu: next_menu, cuisines: next_cuisines,
      addresses: {
        comp_addresses: next_comp_addresses,
        custom_fields: next_custom_fields
      },
      schedules_details: {
        single_schedule: next_single_schedule,
        new_schedule_request_status: next_new_schedule_request_status,
        update_schedule_status: next_update_schedule_status
      }
    } = nextProps;

    const {
      cloneOrderPurposes,
      closeModal,
      marketPlace,
      onUpdatePage,
      requiredFields,
      addresses: { comp_addresses },
      companyObj: { user_meal_budget } = {},
      schedules_details: { new_schedule_request_status, update_schedule_status, single_schedule },
    } = this.props;

    // this.setState({ first_time: true });
    if (next_menu.schedule && isEmpty(this.state.mealBudget) && user_meal_budget) {
      this.setState({ mealBudget: user_meal_budget });
    }
    let defaultCuisine: SelectedCuisine[] = [];
    if (next_cuisines) {
      const pickforMeCuisine = next_cuisines?.find(cusine => cusine.name === 'Pick for Me');
      defaultCuisine = pickforMeCuisine ? [{ value: pickforMeCuisine.id, label: pickforMeCuisine.name }] : [];
    }
    // If current delivery is present then it will be set down in next lines
    if (next_comp_addresses?.addresses && !next_single_schedule?.schedule) {
      if (isEmpty(this.state.selectedCompanyAddressID) && next_comp_addresses.addresses[0]) {
        const defaultAddress = next_comp_addresses.addresses[0];
        this.setState({ selectedCompanyAddressID: defaultAddress?.id, selectedCompanyAddress: defaultAddress });
      }
      // if (isEmpty(this.state.delivery_instructions) && nextProps.addresses.comp_addresses.addresses[0]) {
      //   this.setState({ delivery_instructions: nextProps.addresses.comp_addresses.addresses[0].delivery_instructions });
      // }
    }
    if (next_custom_fields && !cloneOrderPurposes) {
      let minBudget = user_meal_budget;
      this.setState({ minimumMealBudget: minBudget! });
      if (this.state.pending === false) {
        if (requiredFields === true) {
          setTimeout(() => {
            this.highlightRequiredFields(nextProps.addresses.custom_fields?.custom_fields);
          }, 500);
        }
        this.setState({ orderFieldAttributes: next_custom_fields.custom_fields })
      }
    }
    if (next_new_schedule_request_status && next_new_schedule_request_status !== new_schedule_request_status) {
      // this.setState({ sent_request: false });
      if (this.state.scheduleFromOrder !== true) { // Not From Order Popup.
        closeModal();
        onUpdatePage();
      }
      this.props.setState && this.props.setState({ showLoading: false });
      if (cloneOrderPurposes) {
        // this.state.currentMeeting?.slug && this.props.getBookedOrders(this.state.currentMeeting.slug);
      } else if (this.state.pending === false && marketPlace) {
        showToastNotification(`Meeting is scheduled Sucessfully!`, 'Success!')
      } else if (this.state.scheduleFromOrder !== true) {
        showToastNotification('We have received your request!', 'Thank You!')
      }
    }
    if (nextProps.errors && nextProps.errors.errors) {
      showToastNotification(nextProps.errors.errors, 'Sorry!', true)
      // this.setState({ submit: false })
      this.props.setState && this.props.setState({ showLoading: false });
      store.dispatch({ type: 'CLEAR_ERRORS', payload: null });
    }
    if (next_update_schedule_status && next_update_schedule_status !== update_schedule_status) {
      closeModal();

      // if (cloneOrderPurposes) {
      //   store.dispatch({ type: 'CLONE_WITH_FIELD', payload: null });
      // } else {
      // let queryDate = dayjs(nextProps?.schedules_details?.request_data?.schedule?.delivery_at).format('ddd MMM DD YYYY');
      // let selectedElement: HTMLElement = document.querySelector(`[aria - label= '${queryDate}']`) as HTMLElement;
      // selectedElement.click();
      onUpdatePage();
      // }
      // onUpdatePage();
      // this.props.setState({ showLoading: false, update: true });
      this.props.setState && this.props.setState({ showLoading: false, });
      showToastNotification('Meeting updated successfully.', 'Success!');
    }
    if ((this.state.pending === false && isEmpty(this.state.meetingName)) || (this.state.pending === false && marketPlace)) {
      this.setState({
        postCuisines: defaultCuisine,
        selectedCuisines: defaultCuisine
      })
    }
    if (next_single_schedule !== null && next_single_schedule !== single_schedule) {
      if (next_single_schedule.schedule) {
        const selected_cuisines = next_single_schedule.selected_cuisines;
        this.setState({ alreadySavedCuisines: selected_cuisines })
        let n_cusines: SelectedCuisine[] = [];
        selected_cuisines.forEach(cuisine => {
          const temp: SelectedCuisine = { id: cuisine.id, cuisine_id: cuisine.value, _destroy: false, label: cuisine.label, value: cuisine.value }
          n_cusines.push(temp);
        })
        let requestedSchedule = next_single_schedule.schedule;
        let a = requestedSchedule;
        this.setState({
          meetingName: a.runningmenu_name,
          delivery_at: a.delivery_at,
          delivery_instructions: a.delivery_instructions,
          typeValue: a.runningmenu_type ?? 'lunch',
          // time: setTimeWithinLimit(a.runningmenu_type, dayjs(a.end_time).format('HH:mm')) ?? '11:45',
          time: '11:45',
          orderCount: a.orders_count,
          selectedCuisines: n_cusines,
          postCuisines: n_cusines,
          requestedScheduleSlug: a.slug,
          selectedCompanyAddressID: a.address_id,
          admin_cutoff: a.admin_cutoff_at,
          mealBudget: a.per_meal_budget,
          pending: true,
          specialRequests: a.special_request,
          menuType: a.menu_type === 'individual' ? false : true,
          startDate: new Date(this.convertUTCToTimezone(a.delivery_at, a.time_zone))// + ' ' + dayjs(a.pop_end_time, 'HH:mm').format('h:mm a'))
        })
        // if (a.runningmenu_type === 'breakfast') {
        //   this.setState({ 
        //     minTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 7:15:00'), 
        //     maxTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 10:00:00') 
        //   })
        // } else if (a.runningmenu_type === 'lunch') {
        //   this.setState({ 
        //     minTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 11:45:00'), 
        //     maxTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') 
        //   })
        // } else {
        //   this.setState({ 
        //     minTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 17:15:00'),
        //      maxTime: new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 19:15:00') 
        //     })
        // }
        this.props.setState && this.props.setState({ showLoading: false });
        if (next_single_schedule.schedule.custom_fields !== null && next_single_schedule.schedule.custom_fields.length > 0 && !cloneOrderPurposes) {
          this.setcustomFields(next_single_schedule.schedule.custom_fields);
        } else {
          this.setState({ orderFieldAttributes: next_single_schedule.custom_fields })
        }
      } else {
        if (this.state.pending === false) {
          this.setState({
            pending: false,
            specialRequests: '',
            orderCount: 1,
            selectedCuisines: defaultCuisine
          })
        }
      }
    }
    // Select Current delivery address in Form
    if ((next_single_schedule && next_single_schedule !== single_schedule) ||
      (next_comp_addresses?.addresses && next_comp_addresses?.addresses !== comp_addresses?.addresses)) {
      const selectedDeliveryAddress = next_comp_addresses?.addresses.find(address => address?.id === next_single_schedule?.schedule?.address_id);
      selectedDeliveryAddress && this.setState({ selectedCompanyAddressID: selectedDeliveryAddress?.id, selectedCompanyAddress: selectedDeliveryAddress });
    }
    // if (this.state.submit === true) {
    // this.getRequestDataProps();
    // this.setState({ submit: false, showLoading: true });
    // }
  }
  // This Function is not used anymore.
  capitlizeString = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { name, value, type, } } = e;
    //@ts-ignore
    const inputValue = type === 'checkbox' ? e.target.checked : value;
    //@ts-ignore
    this.setState({ [name]: inputValue });
  }
  onClickOption = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.props.addresses.comp_addresses?.addresses.forEach(address => {
      //@ts-ignore
      const selectedValue: string = e.target.value;
      if (e.target && address.id === +selectedValue) {
        this.setState({ delivery_instructions: address.delivery_instructions, selectedCompanyAddressID: +selectedValue, selectedCompanyAddress: address })
      }
    });
    this.toggleCustomSelect(e);
  }
  toggleCustomSelect = ({ target }: React.MouseEvent<HTMLInputElement | HTMLDivElement | HTMLLIElement, MouseEvent>) => {
    if (dayjs(this.state.admin_cutoff).isAfter(new Date()) || this.props.meetingStatus === 'create') {
      //@ts-ignore
      if (target && target.name === 'selectedCompanyAddress') {
        if (this.state.openCustomSelect === false) {
          this.setState({ openCustomSelect: true });
        }
      } else {
        setTimeout(() => {
          this.setState({ openCustomSelect: false });
        }, 100);
      }
    }
  }

  setMenuType = (type: 'breakfast' | 'lunch' | 'dinner') => {
    this.setState({ typeValue: type })
  }

  noOptionsMessage = () => {
    return `No more option.`
  }
  onChangeCuisine = (selectedCuisines: any, { action }: any) => {
    const newSelectedCuisines = [...(selectedCuisines || []) as SelectedCuisine[]]
    let _cuisines: { [K: string]: SelectedCuisine } = {};
    let c_selection: SelectedCuisine[] = [];

    !isEmpty(this.state.alreadySavedCuisines) && this.state.alreadySavedCuisines.forEach(alreadySavedCuisine => {
      // iterator._destroy = true
      // iterator.cuisine_id = iterator.value
      // _cuisines[iterator.value] = iterator
      _cuisines[alreadySavedCuisine.value] = { ...alreadySavedCuisine, _destroy: true, cuisine_id: alreadySavedCuisine.value };
    })

    newSelectedCuisines.forEach(selectedCuisine => {
      if (_cuisines[selectedCuisine.value]) {
        _cuisines[selectedCuisine.value]._destroy = false;
      } else {
        selectedCuisine.cuisine_id = selectedCuisine.value
        _cuisines[selectedCuisine.value] = selectedCuisine
      }
    })
    Object.keys(_cuisines).forEach(iterator => {
      c_selection.push(_cuisines[iterator])
    })
    if (action === 'select-option' && this.state.maxCuisinesReached) {
      return;
    }
    // set max reached once achieved
    if (action === 'select-option' && selectedCuisines.length === MAX_CUISINES) {
      this.setState({ maxCuisinesReached: true });
    }
    const maxCuisinesReached = selectedCuisines.length >= MAX_CUISINES;
    this.setState({ maxCuisinesReached, selectedCuisines: newSelectedCuisines, postCuisines: c_selection });
  };

  handleOrderFields = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
    let customFields = this.state.orderFieldAttributes;
    if (e.target.type === 'text') {

      for (let k = 0; k < customFields.length; k++) {
        if (parseInt(e.target.name) === parseInt(`${customFields[k].id} `)) {
          customFields[k].value = e.target.value;
          this.setState({ orderFieldAttributes: customFields });
        }
      }
    } else {
      for (let i = 0; i < customFields.length; i++) {
        if (parseInt(e.target.name) === parseInt(`${customFields[i].id} `)) {
          customFields[i].value = e.target.value;
          for (let j = 0; j < (customFields[i]?.options || []).length; j++) {
            if (parseInt(e.target.name) === parseInt(`${customFields[i].id} `)) {
              customFields[i].options![j].selected = true;
              this.setState({ orderFieldAttributes: customFields });
            }
          }
        }
      }
    }
  }
  check_constraints() {
    var test_passed = true;
    let error1: HTMLElement = document.getElementById('error_1') as HTMLElement;
    let error2: HTMLElement = document.getElementById('error_2') as HTMLElement;
    // let error3: HTMLElement = document.getElementById('error_3') as HTMLElement;
    let error4: HTMLElement = document.getElementById('error_4') as HTMLElement;
    if (this.state.orderCount < 1) {
      test_passed = false;
      if (this.state.menuType === true) {
        error1.hidden = false;
        error2.hidden = true;
        const tesNode = ReactDOM.findDOMNode(error1) as HTMLElement | null;
        $('#mydiv').animate({ scrollTop: tesNode?.offsetTop }, 1000);
      } else {
        error2.hidden = false;
        error1.hidden = true;
        const tesNode = ReactDOM.findDOMNode(error2) as HTMLElement | null;;
        $('#mydiv').animate({ scrollTop: tesNode?.offsetTop }, 1000);
      }
    } else {
      error1.hidden = true;
      error2.hidden = true;
    }
   // Temporary removed according to this ticket hotfix/C20-2920

    // if (this.state.selectedCuisines.length < 1) {
    //   error3.hidden = false;
    //   if (test_passed === true) {
    //     const tesNode = ReactDOM.findDOMNode(error3) as HTMLElement | null;;
    //     $('#mydiv').animate({ scrollTop: tesNode?.offsetTop }, 1000);
    //   }
    //   test_passed = false;
    // } else {
    //   error3.hidden = true;
    // }
    if (isEmpty(this.state.meetingName)) {
      test_passed = false;
      error4.hidden = false;
      const tesNode = ReactDOM.findDOMNode(error4) as HTMLElement | null;
      $('#mydiv').animate({ scrollTop: tesNode?.offsetTop }, 1000);
    } else {
      error4.hidden = true;
    }
    if (this.props.userObj?.user_type === UserType.ADMIN) {
      this.state.orderFieldAttributes.forEach(field => {
        if (field.required === 1) {
          if (!field.value) {
            document.getElementById(`custom-${field.id}`)?.classList.add('required-error-highlight');
            if (test_passed === true) {
              // const tesNode = ReactDOM.findDOMNode(document.getElementById(field.id+field.name));
              $('#mydiv').animate({ scrollTop: 650 }, 1000);
            }
            test_passed = false;
          }
          else if (document.getElementById(`custom-${field.id}`)?.classList.contains('required-error-highlight')) {
            document.getElementById(`custom-${field.id}`)?.classList.remove('required-error-highlight');
          }
        }
      });
    }
    return test_passed;
  }
  onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    let sameDayAdminCutoff = this.props?.schedules_details?.single_schedule?.schedule?.sd_admin_cutoff_at
    let sameDayEnabled = this.props?.schedules_details?.single_schedule?.schedule?.same_day


    if (this.props.orderPopUp || this.props.cloneOrderPurposes) { // If Not For Update Meeting Detail Then Let handle form submission from Parent Component.
      return
    }

    let test;
    if (this.props.requiredFields === false) {
      test = this.check_constraints();
    } else {
      test = true;
    }
    // Handling CustomFields here
    let deliveryNewCustomFields: DeliveryCustomFieldPayload[] = [];
    const fields = this.state.orderFieldAttributes;
    if (fields && fields.length > 0) {
      for (var g = 0; g < fields.length; g++) {
        if (fields[g].field_type === 'text') {
          if (!isEmpty(this.state.requestedScheduleSlug)) {
            deliveryNewCustomFields.push({ field_id: fields[g].id, value: fields[g].value, id: fields[g].runningmenu_id });
          } else {
            deliveryNewCustomFields.push({ field_id: fields[g].id, value: fields[g].value });
          }
        } else {
          if (!isEmpty(this.state.requestedScheduleSlug)) {
            deliveryNewCustomFields.push({ field_id: fields[g].id, fieldoption_id: fields[g].value, id: fields[g].runningmenu_id });
          } else {
            deliveryNewCustomFields.push({ field_id: fields[g].id, fieldoption_id: fields[g].value });
          }
        }
      }
    }
    // Ending CustomFields Handling here.
    if (test === true && this.props.meetingStatus !== 'approved') {
      const requestData: DeliveryRequestPayload = {
        runningmenu: {
          runningmenu_name: this.state.meetingName,
          delivery_instructions: this.state.delivery_instructions ?? '',
          runningmenu_type: this.state.typeValue ?? 'lunch',
          address_id: this.state.selectedCompanyAddressID!, // ! need to be consideration
          per_meal_budget: this.state.mealBudget,
          delivery_at: getDateWithTimeZone(this.state.startDate ? this.state.startDate : this.props.match.params.date, this.state.selectedCompanyAddress?.time_zone ),
          orders_count: this.state.orderCount,
          // end_time: getDateWithTimeZone(this.state.startDate ? this.state.startDate : this.props.match.params.date, this.state.selectedCompanyAddress?.time_zone ),
          cuisines_menus_attributes: [],
          special_request: this.state.specialRequests ?? '',
          menu_type: this.state.menuType ? 'buffet' : 'individual',
          hide_meeting: this.state.hide_meeting_local_check
        }
      }
      let selectedCuisines: ({ id?: number, cuisine_id: number, _destroy?: boolean })[] = [];
      this.state.postCuisines && this.state.postCuisines.forEach(key => {
        if (key._destroy === true || key._destroy === false) {
          selectedCuisines.push({ id: key.id, cuisine_id: key.value, _destroy: key._destroy === true ? true : false })
        } else {
          selectedCuisines.push({ cuisine_id: key.value })
        }
      }
      );
      if (this.props.marketPlace) {
        requestData.runningmenu.address_ids = this.props.allowedRestaurantsIdArray;
      }
      requestData.runningmenu.cuisines_menus_attributes = selectedCuisines;
      requestData.runningmenu.runningmenufields_attributes = Object.assign({}, deliveryNewCustomFields);
      if (this.state.pending === false) {
        // 2nd Argument is to get Delivery Dates Check;
        if (this.props?.createSchedular) {
          this.props.makeDeliveryRequest(requestData, true, [], () => { }, this.props?.createSchedular);
        } else {
          this.props.makeDeliveryRequest(requestData, true);
        }
      } else {
        this.props.updateScheduleRequest(this.state.requestedScheduleSlug, requestData);
      }
      this.setState({
        // send_request: true, 
        requestedScheduleSlug: '',
        postCuisines: [],
        alreadySavedCuisines: [],
        // submit: true
      });
      this.props.setState && this.props.setState({ showLoading: true })
    } else if (test === true && this.props.meetingStatus === 'approved' && (this.state.admin_cutoff ? dayjs(this.state.admin_cutoff).isAfter(new Date()) : (sameDayEnabled && dayjs(sameDayAdminCutoff).isAfter(new Date())))) {
      const requestData: DeliveryRequestPayload = {
        runningmenu: {
          runningmenu_name: this.state.meetingName,
          delivery_instructions: this.state.delivery_instructions ?? '',
          address_id: this.state.selectedCompanyAddressID!, // ! need to be consideration
          orders_count: this.state.orderCount,
          // end_time: getDateWithTimeZone(this.state.startDate ? this.state.startDate : this.props.match.params.date),
          cuisines_menus_attributes: [],
          special_request: this.state.specialRequests ?? '',
          menu_type: this.state.menuType ? 'buffet' : 'individual',
          // delivery_at: `${dayjs(this.state.startDate).format('YYYY-MM-DD')}T${dayjs(this.state.startDate).format('HH:mm')} `,
          delivery_at: getDateWithTimeZone(this.state.startDate ? this.state.startDate : this.props.match.params.date),
          per_meal_budget: this.state.mealBudget,
          hide_meeting: this.state.hide_meeting_local_check
        }
      }
      let selectedCuisines: ({ id?: number, cuisine_id: number, _destroy?: boolean })[] = [];
      this.state.postCuisines && this.state.postCuisines.forEach(key => {
        if (key._destroy === true || key._destroy === false) {
          selectedCuisines.push({ id: key.id, cuisine_id: key.value, _destroy: key._destroy === true ? true : false })
        } else {
          selectedCuisines.push({ cuisine_id: key.value })
        }
      }
      );
      if (this.props.marketPlace) {
        requestData.runningmenu.address_ids = this.props.selectedRestaurants;
      }
      requestData.runningmenu.cuisines_menus_attributes = selectedCuisines;
      requestData.runningmenu.runningmenufields_attributes = Object.assign({}, deliveryNewCustomFields);
      if (this.state.requestedScheduleSlug) {
        if (dayjs(this.state.admin_cutoff).isAfter(new Date())) {
          requestData.runningmenu.skip_cuttoff_check = true;
        }
        this.props.updateScheduleRequest(this.state.requestedScheduleSlug, requestData);
      }
      this.setState({
        // send_request: true, 
        requestedScheduleSlug: '',
        postCuisines: [],
        alreadySavedCuisines: [],
        // submit: true,
      });
      this.props.setState && this.props.setState({ showLoading: true })
    } else if (test === true && this.props.meetingStatus === 'approved' && (this.state.admin_cutoff ? dayjs(this.state.admin_cutoff).isBefore(new Date()) : (sameDayEnabled && dayjs(sameDayAdminCutoff).isBefore(new Date()))) && dayjs(this.state.delivery_at).isAfter(new Date())) {
      const requestData: DeliveryRequestPayload = {
        runningmenu: {
          skip_cuttoff_check: true,
          delivery_instructions: this.state.delivery_instructions ?? '',
          hide_meeting: this.state.hide_meeting_local_check
        }
      }
      this.props.updateScheduleRequest(this.state.requestedScheduleSlug, requestData);
    } else if (test === true && dayjs(this.state.admin_cutoff).isBefore(new Date())) {
      const requestData: DeliveryRequestPayload = {
        runningmenu: {
          skip_cuttoff_check: true,
          hide_meeting: this.state.hide_meeting_local_check
        }
      }
      requestData.runningmenu.runningmenufields_attributes = Object.assign({}, deliveryNewCustomFields);
      if (this.state.requestedScheduleSlug) {
        this.props.updateScheduleRequest(this.state.requestedScheduleSlug, requestData);
      }
      this.setState({
        // send_request: true, 
        requestedScheduleSlug: '', postCuisines: [], alreadySavedCuisines: []
      });
      // this.setState({ submit: true });
      this.props.setState && this.props.setState({ showLoading: true })
    }
  }
  handleChange = (date: Date) => {
    this.setState({
      startDate: date,
    });
  };
  render() {
    const { cuisines, addresses } = this.props || {};
    let CuisineSuggestions;
    let CuisinesArray: ({ value: number, label: string })[] = [];
    let AddressList;
    let selectedCompanyAddress;
    if (addresses.comp_addresses) {
      AddressList = addresses.comp_addresses.addresses.map(address =>
        <li key={address.id} value={address.id} onClick={this.onClickOption} >{address?.formatted_address} </li>
      );
      if (this.state.pending === false) {
        selectedCompanyAddress = addresses.comp_addresses.addresses[0];
      } else {
        addresses.comp_addresses.addresses.forEach(address => {
          if (this.state.selectedCompanyAddressID && this.state.selectedCompanyAddressID === address.id) {
            selectedCompanyAddress = address
          }
        });
      }
    }
    if (cuisines) {
      cuisines.map((cuisineList) =>
        CuisinesArray.push({ value: cuisineList.id, label: cuisineList.name })
      )
    }
    CuisineSuggestions = (
      <Select
        value={this.state.selectedCuisines}
        onChange={this.onChangeCuisine}
        options={this.state.maxCuisinesReached ? this.state.selectedCuisines : CuisinesArray}
        noOptionsMessage={this.noOptionsMessage}
        isMulti={true}
        placeholder={'Select Cuisines'}
        className='filter-dropdown'
        classNamePrefix='filter-dropdown'
        isDisabled={dayjs(this.state.admin_cutoff).isBefore(new Date())}
        // isDisabled={this.props.meetingStatus === 'approved'}
      />
    )
    return (
      <div className={`${this.props?.marketPlacePage ? ' pt-2 ' : ''}`}>
        {this.state.mealBudget ?
          <div className={`order-popup-inner-wrapper pb-2 ${this.props?.marketPlacePage ? 'markePlacePage ' : ''}`}>
            <div id='main' className={this.props.orderPopUp ? 'container px-0' : 'container'}>
              <>{this.props.marketPlacePage ? <>
                <div className='form-row mt-4'>
                  <div className='col-11 m-auto text-center'>
                    <h3 className='text-center'>Delivery Information</h3>
                    <div className='form-row'>
                      <div className='col-11 m-auto text-center'>
                        {/* <p className='text-muted'>{this.props.i18n && this.props.i18n.t('care')}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </> :
                <>
              {this.props.requiredFields === false ?
                <div className='form-row'>
                  <div className='col-11 m-auto text-center'>
                    <p className='text-muted'>{this.props.i18n && this.props.i18n.t('care')}</p>
                  </div>
                </div>
                    : ''}
                </>
              }
              </>

              <form noValidate onSubmit={this.onSubmit} className='text-left'>
                <React.Fragment>
                  {this.props.requiredFields === false ? <div>
                    <div className='form-row'>
                      <div className='col-sm-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '>  {this.props.i18n && this.props.i18n.t('Name This Delivery')}</h6>
                        <h6 className='orderform-section-required muted float-right pt-0'> {this.props.i18n && this.props.i18n.t('Required')}</h6>
                        <input
                          type='text'
                          className='form-control'
                          id='name'
                          name='meetingName'
                          value={this.state.meetingName || ''}
                          onChange={this.onChange}
                          disabled={dayjs(this.state.admin_cutoff).isBefore(new Date())}
                        />
                        <p id='error_4' hidden={true} className='invalid-feedback-password'> {this.props.i18n && this.props.i18n.t(`Delivery name can't be empty.`)}</p>
                      </div >
                      <div className='col-sm-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '> {this.props.i18n && this.props.i18n.t('Location')}</h6>
                        <div className='main-custom-options'>
                          <input
                            readOnly
                            type='text'
                            id='address'
                            name='selectedCompanyAddress'
                            onClick={this.toggleCustomSelect}
                            disabled={dayjs(this.state.admin_cutoff).isBefore(new Date())}
                            value={this.state.selectedCompanyAddress?.formatted_address || selectedCompanyAddress?.formatted_address}
                            className={dayjs(this.state.admin_cutoff).isBefore(new Date()) ? 'form-control faded' : 'form-control faded bg-white'}
                          />
                          {this.state.openCustomSelect === true ?
                            <ul className='custom-options'>
                              {AddressList}
                              <li onClick={this.toggleCustomSelect} className='text-center'>close</li>
                            </ul> : ''
                          }
                        </div>
                      </div>
                    </div >
                    <div className='form-row '>
                      <div className='col-sm-6 company-required-field px-3 pb-3 pt-3'>
                        <h6 className='orderform-section-name float-left d-block'> {this.props.i18n && this.props.i18n.t('Meal Type')}</h6>
                        <h6 className='orderform-section-required muted float-right '>&nbsp;</h6>
                        <div className='d-block' style={{ clear: 'both' }}>
                          <div className='btn-group btn-group-toggle' data-toggle='buttons' style={{ pointerEvents: this.props.meetingStatus === 'approved' ? 'none' : 'auto' }}>
                            <label className={this.state.typeValue === 'breakfast' ? 'btn btn-sm btn-outline-secondary active' : 'btn btn-sm btn-outline-secondary'} onClick={() => this.setMenuType('breakfast')}>
                              <input type='radio' name='options' id='option0' />  {this.props.i18n && this.props.i18n.t('Breakfast')}
                            </label>
                            <label className={this.state.typeValue === 'lunch' ? 'btn btn-sm btn-outline-secondary active' : 'btn btn-sm btn-outline-secondary'} onClick={() => this.setMenuType('lunch')}>
                              <input type='radio' name='options' id='option1' />  {this.props.i18n && this.props.i18n.t('Lunch')}
                            </label>
                            <label className={this.state.typeValue === 'dinner' ? 'btn btn-sm btn-outline-secondary active' : 'btn btn-sm btn-outline-secondary'} onClick={() => this.setMenuType('dinner')}>
                              <input type='radio' name='options' id='option2' />  {this.props.i18n && this.props.i18n.t('Dinner')}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved' ? 'disabled-background pointer-none col-sm-6 company-required-field p-3 scheduler-input-time form-z-index' : 'cursor-pointer width-50-percent company-required-field p-3 scheduler-input-time form-z-index'}>
                        <h6 className='orderform-section-name float-left '> {this.props.i18n && this.props.i18n.t('Delivery Date & Time')}</h6>
                        <h6 id='time-required' className='orderform-section-required muted float-right mt-8 pt-0'>
                          {this.props.i18n && this.props.i18n.t('Required')}</h6>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          showTimeSelect
                          timeFormat='HH:mm'
                          timeIntervals={15}
                          timeCaption='time'
                          dateFormat='MMMM d, yyyy h:mm aa'
                          minDate={this.props.meetingStatus === 'approved' ? dayjs((new Date()).toString()).add(2, 'day').toDate() : dayjs((new Date()).toString()).add(2, 'day').toDate()}
                          maxDate={dayjs((new Date()).toString()).add(29, 'day').toDate()}
                          disabled={dayjs(this.state.admin_cutoff).isBefore(new Date())}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-sm-6 company-required-field p-3 budged-slider-heign' style={{minWidth:'310px'}}>
                        <h6 className='orderform-section-name float-left d-block mb-0'>
                          {!this.state.menuType ? <span>{this.props.i18n && this.props.i18n.t('Per Meal Budget')}</span> : ''}
                        </h6>
                        <h6 className='orderform-section-required muted float-right'>{this.state.buffetPerUserBudget || (!this.state.menuType) ? '$' : ''}{!this.state.menuType ? this.state.mealBudget : this.state.buffetPerUserBudget}</h6>
                        <div className='form-group mt-3'>
                          {!this.state.menuType ? <input type='range' className='form-control-range' id='mealBudget' name='mealBudget' value={this.state.mealBudget || ''} disabled={dayjs(this.state.admin_cutoff).isBefore(new Date())} min={this.state.minimumMealBudget} max={50} step={1} onChange={this.onChange} /> : ''}
                        </div>
                      </div>
                      <div className='col-sm-6 company-required-field p-3 budged-slider-heign' style={{minWidth:'310px'}}>
                        <h6 className='orderform-section-name float-left d-block'> {this.props.i18n && this.props.i18n.t('Approximate Count')}</h6>
                        {(this.props.meetingStatus !== 'approved' && !this.props?.marketPlacePage) ? <h6 className='orderform-section-required muted float-right buffet-style-alignment'>
                          <div className='custom-control custom-switch d-inline ml-3'>
                            <input type='checkbox' className='custom-control-input' id='menuType' name='menuType' checked={this.state.menuType} disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'} onChange={this.onChange} />
                            <label className='custom-control-label' htmlFor='menuType'> {this.props.i18n && this.props.i18n.t('Buffet Style')}</label>
                          </div>
                        </h6> : ''}
                        <input type='number' max={999} min={1} className='form-control' id='count' name='orderCount'
                          value={this.state.orderCount} disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'} onChange={this.onChange} />
                        <p id='error_1' hidden={true} className='invalid-feedback-password invalid-error-count'> {this.props.i18n && this.props.i18n.t(`Count can't be empty.`)}</p>
                        <p id='error_2' hidden={true} className='invalid-feedback-password invalid-error-count'>  {this.props.i18n && this.props.i18n.t(`Count can't be empty.`)} </p>
                        <label id='countError'></label>
                      </div>
                    </div>
                    <div className='form-row '>
                      <div className='col-sm-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left d-block'> {this.props.i18n && this.props.i18n.t('Cuisine Preference')}</h6>
                        <div style={{ clear: 'both' }}> {CuisineSuggestions} </div>
                        {/* <p hidden={true} id='error_3' className='invalid-feedback-password'>{this.props.i18n && this.props.i18n.t('You have to select atleast one cuisine.')}</p> */}
                      </div>
                      <div className='col-sm-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left d-block'> {this.props.i18n && this.props.i18n.t('Special Requests')}</h6>
                        <div className='form-group '>
                          <input
                            type='text'
                            className='form-control'
                            id='specialRequest'
                            placeholder='e.g. low carb options, add desserts, etc'
                            name='specialRequests' value={this.state.specialRequests || ''}
                            disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'}
                            onChange={this.onChange} />
                        </div>
                      </div>
                    </div>
                    <div className='form-row '>
                      <div className='col-sm-6 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left d-block'> {this.props.i18n && this.props.i18n.t('Delivery Instructions')}</h6>
                        <textarea
                          className='form-control p-2'
                          name='delivery_instructions'
                          rows={3}
                          placeholder={this.props.i18n ? this.props.i18n.t('eg. Call receptionist upon arrival; wait in the lobby; setup in conference room, etc.') : ''}
                          value={this.state.delivery_instructions ? this.state.delivery_instructions : ''}
                          onChange={this.onChange}
                        >
                        </textarea>
                      </div>
                      <div className='col-sm-6 company-required-field p-3 d-flex align-items-start justify-content-start '>
                        <input
                          type="checkbox"
                          name="hide_meeting_local_check"
                          className='pr-3 mt-3 mr-3'
                          id="admin-orders"
                          checked={!this.state.hide_meeting_local_check}
                          onChange={() => this.setState({ hide_meeting_local_check: !this.state.hide_meeting_local_check })}
                          disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'}
                        />
                        <label htmlFor="admin-orders" className='mt-2'> Allow all users (admins & non-admins) to place orders in this delivery.</label>
                      </div>
                    </div>
                  </div >
                    : ''}
                </React.Fragment >
                {/* Custom Fields */}
                {
                  !isEmpty(this.state.orderFieldAttributes) && !this.props.orderPopUp ?
                    <div className='form-row mt-4'>
                      <div className='col-11 m-auto text-center'>
                        <h2 className='text-center'> {this.props.i18n && this.props.i18n.t('Order Fields')}</h2>
                        {this.props.requiredFields === false ?
                          <p className='text-muted'>
                            {this.props.i18n && this.props.i18n.t('customfields')}</p>
                          :
                          <p className='text-danger'> {this.props.i18n && this.props.i18n.t('We are missing some required specifics for this meeting.')}</p>
                        }
                      </div>
                    </div>
                    : ''
                }
                {
                  this.state.orderFieldAttributes && this.state.orderFieldAttributes.map((fields, index) => (
                    <React.Fragment key={index}>
                      <div className='form-row ' id={`${index}`}>
                        {(fields.field_type === 'text' || fields.field_type === 1) ?
                          <div className={`col-12 company-required-field pt-3 ${this.props.orderPopUp ? '' : 'p-3'}`}>
                            <div className='form-group mb-0'>
                              <h6 className='orderform-section-name float-left order-popup-additional-info-labels'>{fields.name} <span style={{color:"red"}}>*</span></h6>
                              {fields.required === 1 || fields.required === true ?
                                <h6 id={`custom-${fields.id}`}
                                  className='orderform-section-required muted float-right pt-0 mt-8 mb-0 order-popup-additional-info-labels'>
                                  {this.props.i18n && this.props.i18n.t('Required')}</h6> : null}
                              <input type='text' className='form-control form-control-lg' id={fields.id + fields.name}
                                name={`${fields.id}`} onChange={this.handleOrderFields}
                                //  disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'}
                                value={fields.value ? fields.value : ''} />
                            </div>
                          </div>
                          :
                          <div className={`col-12 company-required-field pt-3 ${this.props.orderPopUp ? '' : 'p-3'}`}>
                            <div className='form-group mb-0'>
                              <h6 className='orderform-section-name float-left order-popup-additional-info-labels'>{fields.name} <span style={{color:"red"}}>*</span></h6>
                              {fields.required === 1 || fields.required === true ?
                                <h6 id={`custom-${fields.id}`}
                                  className='orderform-section-required muted float-right pt-0 mt-8 mb-0 order-popup-additional-info-labels'>
                                  {this.props.i18n && this.props.i18n.t('Required')}</h6> : null}
                              <select className='form-control form-control-lg' id={fields.id + fields.name}
                                value={fields.value ? fields.value : ''} name={`${fields.id}`}
                                //   disabled={dayjs(this.state.admin_cutoff).isBefore(new Date()) && this.props.meetingStatus === 'approved'}
                                onChange={this.handleOrderFields}>
                                <option value=''> {this.props.i18n && this.props.i18n.t('Please Select One')}</option>
                                {fields.options && fields.options.map((fieldOptions, index1) =>
                                  <option value={fieldOptions.id} key={index1}>
                                    {fieldOptions.name}
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  ))
                }
              </form >
            </div >
          </div > :
          <span className='post-meeting-spnner'>
            <Spinner />
          </span>
        }
      </div >
    )
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  errors: state.errors,
  addresses: state.addresses,
  menu: state.menu,
  cuisines: state.cuisine_list.cuisines,
  schedules_details: state.schedules_details,
  i18n: state.i18n.i18n,
  allowedRestaurantsIdArray: state?.server_cart?.address_ids,
});

export type ScheduleDeliveryFormRef = React.ElementRef<typeof ScheduleDeliveryForm>;

export default connect(mapStateToProps, { getCompanyAddresses, getCustomFields, getSchedule, getCuisine, getSingleSchedule, makeDeliveryRequest, updateScheduleRequest })(withRouter(ScheduleDeliveryForm));
