import { IS_LOADING, START_BUTTON_LOADING, STOP_BUTTON_LOADING } from './types';

//Start Button Loading
export const startButtonLoading = () => {
  return {
    type: START_BUTTON_LOADING
  };
};

//Stop Button Loading
export const stopButtonLoading = () => {
  return {
    type: STOP_BUTTON_LOADING
  };
};

export const setLoadingAction = (isLoading: boolean) => (dispatch: any) => {
  dispatch({
    type: IS_LOADING,
    payload: { isLoading },
  });
};