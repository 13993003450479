import { SET_CURRENT_USER, SEND_FORGOT_PASSWORD_EMAIL, RESET_STATUS, RESET_STATE } from '../Actions/types';
import { User, UserHeaders } from '../Interfaces/PayloadsAndResponses/Auth';
import { Auth } from '../Interfaces/ReduxInterface/ReduxRootState';
import isEmpty from '../Utilities/is_empty';

const initialState: Auth = {
  isAuthenticated: false,
  loggedInUser: {} as User,
  loggedInUserHeaders: {} as UserHeaders,
  forgot_mail_status: null,
  reset_status: null,
};

const authReducer = (state: Auth = initialState, action: any): Auth => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload.headers),
        loggedInUserHeaders: action.payload.headers,
        loggedInUser: action.payload.user
      };
    case SEND_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgot_mail_status: action.payload
      };
    case RESET_STATUS:
      return {
        ...state,
        reset_status: action.payload
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;