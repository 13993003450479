import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import AddLocationForm, { AddressChangeHandler } from '../../Pages/AccountDetail/Forms/AddLocationForm';
import ButtonSpinner from '../Spinners/ButtonSpinner';
import isEmpty from '../../Utilities/is_empty';
import { addressValidationFromGoogleValidationAPI, hasValueInKey } from '../../Utilities/functions';
import { addNewLocationData, getAddressStringByLatLong } from '../../Actions/companyActions';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { Addresses, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { MarketPlaceCompanyAddress } from '../../Interfaces/PayloadsAndResponses/Company';
import { NewAddressData } from '../../Pages/AccountDetail/Modals/AddNewLocationModal';
import { initialAddressData } from '../../Pages/AccountDetail/Modals/AddNewDepartmentModal';
import store from '../../store';
import { GET_SELECTED_ADDRESS_ID } from '../../Actions/types';
import { AddressDataType } from '../../Interfaces/PayloadsAndResponses/Address'
import { getDateForMarketplace } from '../../Utilities/getDateForMarketplace';
import dayjs from 'dayjs';
import { PlaceCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import { updateCartAddress } from '../../Actions/cartActions';
import { User } from '../../Interfaces/PayloadsAndResponses/Auth';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    padding: '0',
    borderRadius: '10px',
    // transition: 'height 0.4s ease-in-out',
  }
};

type InitialDataState = {
  addressName: string,
  suitNumber: string,
  deliveryInstructions: string
}


export type DeliveryAddressPopupErrors = {
  addressLine: string,
  addressName: string,
  timezone?: string
}

const initialData = {
  addressName: '',
  suitNumber: '',
  deliveryInstructions: ''
};

const initialErrors = {
  addressLine: '',
  addressName: '',
  timezone: ''
};
Modal.setAppElement('#root');
type Props = {
  isOpen: boolean,
  closeModal: () => void,
  addresses?: Addresses,
  setAddressInMarketPlace: (addressId: number| AddressDataType) => void,
  selectedCompanyAddress: MarketPlaceCompanyAddress | undefined,
  openFilter: () => void,
  fromPublicMarketPlace: boolean,
  fromCart?: boolean,
  cartAddress?: string,
  setNewAddressForCart?: () => void,
  handleDateChange: (date: Date | null, callRestaurantAPI?: boolean) => void
  resetSearchBar?: () => void;
};
const DeliveryAddressPopUp = forwardRef(({
  openFilter,
  isOpen,
  closeModal,
  addresses,
  setAddressInMarketPlace,
  selectedCompanyAddress,
  fromPublicMarketPlace,
  fromCart,
  setNewAddressForCart,
  handleDateChange,
  resetSearchBar,
}: Props, ref) => {
  const dispatch: any = useDispatch();
  const { company_id } = useParams<{ company_id: string | undefined }>();
  const loggedInCompanyId = getloggedInUser().loggedInUserCompany?.id;
  const companyId = company_id || loggedInCompanyId;
  const [deliveryAddressFormToggle, setDeliveryAddressFormToggle] = useState<boolean>(true)
  const { errors } = useSelector((state: ReduxRootState) => state.errors);
  const { cart_at_login } = useSelector(((state: ReduxRootState) => state.server_cart));
  const reduxCartAddressIds = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);


  const [_errors, setErrors] = useState<DeliveryAddressPopupErrors>(initialErrors);
  // const [hoverDivId, setHoverDivId] = useState<number | null>();
  // const selectedAddress = selectedCompanyAddress || addresses?.comp_addresses?.addresses[0]
  const selectedAddress = selectedCompanyAddress
  const [firstShown, setFirstShown] = useState<boolean>(false);
  const [formData, setFormData] = useState<InitialDataState>(initialData);
  const { addressName, suitNumber, deliveryInstructions } = formData;
  const [addressData, setAddressData] = useState<NewAddressData>(initialAddressData);
  const { addressLine, longitude, latitude, city, state, zipCode, streetNumber, street, timezone, formatted_address } = addressData;
  const { buttonLoading } = useSelector((state: ReduxRootState) => state.loading);
  const [popUpAddress, setPopUpAddress] = useState<MarketPlaceCompanyAddress | undefined>()
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  const [addressVerified, setAddressVerified]= useState<boolean>(false)
  const [wrongDeliveryAddress, setWrongDeliveryAddress]= useState<boolean>(false)

  useEffect(() => {
    let _errors = initialErrors;
    if (errors && !firstShown) {
      if (errors['addresses_active.address_line'] && errors['addresses_active.address_line'][0]) {
        _errors.addressLine = errors['addresses_active.address_line'][0];
        setErrors(_errors);
      }
      setFirstShown(true);
    }
  }, [errors, firstShown]);
  useEffect(() => {
    // used for state issue
  }, [_errors])
  useImperativeHandle(
    ref,
    () => ({
      clearErrors: () => {
        setFormData(initialData);
        setAddressData(initialAddressData);
      },
    }), []);
  const handleAddressChange = (newAddress: MarketPlaceCompanyAddress) => {
    setPopUpAddress(newAddress)
    // if (newAddress.id !== selectedAddress?.id) {
    //   setAddressInMarketPlace(newAddress.id);
    //   closeModal();
    //   document.body.clientWidth < 700 ? openFilter() : void (0)
    // }
  }
  const handleAddressPopUp = () => {

    closeModal && closeModal()
    resetSearchBar && resetSearchBar()
    store.dispatch({
      type: GET_SELECTED_ADDRESS_ID,
      payload: popUpAddress?.id
    });
    // closeModal()

    if (popUpAddress?.id) {
      setAddressInMarketPlace(popUpAddress.id);
    }
    const dateForMarketplace = getDateForMarketplace(popUpAddress?.time_zone);
    handleDateChange(new Date(dayjs(dateForMarketplace).format('ddd MMM D YYYY') + ' 11:45'), false)
    closeModal()
    document.body.clientWidth < 700 ? openFilter() : void (0)
  }
  const onAddressPopUpClose = () => {
    setPopUpAddress({...popUpAddress, id: selectedAddress?.id} as MarketPlaceCompanyAddress)
    closeModal()
  }
  const handleChange = ({ target: { name, value } }: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement>) => {
    validate()
    name === 'addressName' && isEmpty(value) ? setErrors({ ...errors, addressName: 'Please enter address name.' }) : setErrors({ ...errors, addressName: '' })
    setFormData({ ...formData, [name]: value })
  };
  const handleAddress: AddressChangeHandler = async (address) => {
    if (firstShown) {
      let errors = initialErrors;
      if (isEmpty(address)) {
        errors.addressLine = 'Please enter delivery address.';
        setErrors(errors);
      } else {
        errors.addressLine = '';
        if (address.timezone) {
          handleTimezone(address.timezone)
          errors.timezone = ''
        } else {
          errors.timezone = 'Please enter timezone'
        }
      }
      setErrors(errors);
    }
    !isEmpty(address) ? setErrors({ ...errors, addressLine: '' }) : setErrors({ ...errors, addressLine: 'Please enter delivery address.' })
    const {streetNumber, street, city} = address
    let formatted_address = `${streetNumber? `${streetNumber} ` : ''}${street? `${street}`: ''}${(street || streetNumber) && city? ',' : ''}${city? ` ${city}`: '' }`;
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    if(fromCart && user?.ss_user){
      const validatingAddress = await addressValidationFromGoogleValidationAPI(address?.addressLine)

      if(validatingAddress?.addressComplete){
        setAddressVerified(true)
        setWrongDeliveryAddress(false)



      }else{
        setAddressVerified(false)
        setWrongDeliveryAddress(true)
      }
    }

    setAddressData({ ...addressData, ...address,  formatted_address});
  };

  // const verifingEnteredAddress = async (addressLine:string) => {
  //   const validationResult = await addressValidationFromGoogleValidationAPI("Krados Restaurant, Qazi Muhammad Isa Road, Civic Center Commercial Area Faisal Town, Lahore, Pakistan")
  //   if(validationResult){
  //     return validationResult
  //   }else{
  //     return false
  //   }
  // }
  const handleTimezone = (timezone: string) => {
    let errors = initialErrors;
    if (isEmpty(timezone)) {
      if (firstShown) {
        validate()
        errors.timezone = 'Please enter timezone';
      }
    } else {
      errors.timezone = '';
    }
    setErrors(errors);
    setAddressData({ ...addressData, timezone });
  }
  const validate = () => {
    let errors = initialErrors;
    if (isEmpty(addressLine)) {
      errors.addressLine = 'Please enter delivery address.';
    } else {
      errors.addressLine = '';
    }
    if (isEmpty(timezone)) {
      errors.timezone = 'Please enter timezone ';
    } else {
      errors.timezone = '';
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    resetSearchBar && resetSearchBar()
    e.preventDefault();
    validate()
    setFirstShown(true)
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    const newLocationData = {
      address_line: addressLine,
      address_name: addressName,
      suite_no: suitNumber,
      longitude: longitude,
      latitude: latitude,
      delivery_instructions: deliveryInstructions,
      street: street,
      street_number: streetNumber,
      city: city,
      state: state,
      zip: zipCode,
      time_zone: timezone,
      formatted_address
    };
    let arrayData = [];
    arrayData.push(newLocationData);
    const formattedLocationData = {
      company: {
        addresses_active_attributes: arrayData
      }
    };
    let hasError = validate();
    if (!hasValueInKey(hasError)) {
      setFormData({} as InitialDataState);
      setAddressData({} as NewAddressData);

      // if (!loggedInCompanyId) {
      //   store.dispatch({
      //     type: GET_PUBLIC_ADDRESSES,
      //     payload: formattedLocationData
      //   })
      //   // localStorage.setItem('public_address', JSON.stringify(formattedLocationData));
      // }

      if (fromPublicMarketPlace) {
        setAddressInMarketPlace(newLocationData)
        closeModal();

        localStorage.setItem("address", JSON.stringify(newLocationData))
        newLocationData?.latitude && newLocationData?.longitude && getAddressStringByLatLong(newLocationData?.latitude, newLocationData?.longitude)
      } else {
        //dispatch(addNewLocation(+companyId, formattedLocationData, closeModal));
        const dateForMarketplace = getDateForMarketplace(newLocationData.time_zone);
        handleDateChange(new Date(dayjs(dateForMarketplace).format('ddd MMM D YYYY') + ' 11:45'), false)
        dispatch(addNewLocationData(+companyId, formattedLocationData, closeModal));

        // newPayload = { ...orderDataWithTipPercent, company: { addresses_active_attributes: [cartDetails?.deliveryAddress] } }

        if (fromCart) {
          const orderDataWithTipPercent: PlaceCartOrderPayload = {
            runningmenu: {
              runningmenu_name: cart_at_login?.runningmenu_name ?? '',
              delivery_at: cart_at_login?.delivery_at, //"2023-01-15T11:45",
              driver_tip: +cart_at_login?.driver_tip ?? 0,
              driver_tip_percentage: +cart_at_login?.driver_tip_percentage ?? 0,
              orders_attributes: [],
              address_ids: reduxCartAddressIds,
              remove_previous_cart: false,
              // address_id: 3041,
            }
          }

          // let newPayload = {
          //   ...{
          //     runningmenu: {
          //       runningmenu_name: 'ADDRESS - Changed',
          //       remove_previous_cart: false
          //     }
          //     // address_id: 32
          //   }, ...formattedLocationData
          // }
          let newPayload2 = {
            ...orderDataWithTipPercent, ...formattedLocationData
          }
         user?.ss_user && addressValidationFromGoogleValidationAPI(formattedLocationData?.company.addresses_active_attributes[0].address_line)
          closeModal();

          dispatch(updateCartAddress?.(newPayload2 as PlaceCartOrderPayload, cart_at_login?.slug, closeModal))
        }
        //  dispatch(placeCartOrder?.(newPayload2 as PlaceCartOrderPayload));
        //  updateCartOrder
        //  dispatch(updateCartOrder?.(newPayload2 as any,cart_at_login?.slug ))

        // let newAddress = { ...cart_at_login, address_line: formattedLocationData.company.addresses_active_attributes[0].address_line, formatted_address: formattedLocationData.company.addresses_active_attributes[0].formatted_address }
        setNewAddressForCart && setNewAddressForCart()
        // store.dispatch({
        //   type: SAVE_CART_ORDER_PAYLOAD,
        //   payload: orderData
        // })

      }

      setFirstShown(false);
    }
  };
  const deliveryAddressFormToogler = () => {
    setDeliveryAddressFormToggle(!deliveryAddressFormToggle)
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel='Delivery Address'
      >
        {/* <ModalHeader
        label={`Delivery Address`}
        onModalClose={closeModal}
      /> */}
        <div className='modal-header new-modal-header location-modal-header'>
          {(!deliveryAddressFormToggle || !fromCart) ?
            <div className='d-flex justify-content-start align-items-center' onClick={deliveryAddressFormToogler}>
              <i className='fas fa-chevron-left pr-2 cursor-pointer'></i>
              <h5 className='modal-title' id='modal-title'>
                Delivery Address
              </h5>
            </div> :
            <>
              <h5 className='modal-title' id='modal-title'>
                Delivery Address
              </h5>
            </>}
          <button
            type='button'
            onClick={closeModal}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>×</span>
          </button>
        </div>
        <div id='mydiv' className={`modal-body  ${(deliveryAddressFormToggle || fromCart) ? 'location-modal' : 'location-modal-body'}`}>
          <div>
            <div>
              <div id='main' className='container px-0 main-del-add-pop-up'>
                {!fromCart ? <div className='form-row'>
                  {(deliveryAddressFormToggle || fromCart) && <div className='location-modal-header cursor-pointer p-0 p-sm-3 ' onClick={deliveryAddressFormToogler} >
                    <i className='fas fa-plus px-3'></i>
                    <span>Add New Delivery Address </span>
                  </div>
                    }
                  
                </div>: <>
                {wrongDeliveryAddress ? <span className='text-danger'>Invalid address.<br/> Please be sure to select a valid & complete delivery address.</span> : <></>}
                </>}
                <div className='text-left market-popup'>
                  <div>
                    {(deliveryAddressFormToggle || fromCart) &&  addresses?.comp_addresses?.addresses?.map((address, index) =>
                      <div
                        key={index}
                        className='form-row mt-3 delivery-address'
                        // onMouseEnter={() => setHoverDivId(index)}
                        // onMouseLeave={() => setHoverDivId(null)}
                      >
                        <input className='mx-3' id={`${index}`} type='radio' value={address.id} checked={ !isEmpty(popUpAddress) ? address.id === popUpAddress?.id : address.id === selectedAddress?.id} name='address' onChange={() => handleAddressChange(address)} />

                        {/* <label htmlFor={`${index}`} className=' company-required-field p-3 text-center d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                        {address.id === selectedAddress?.id ? <i className='far fa-dot-circle marketplace-address-popup-selected' aria-hidden='true'></i> : <i className={index === hoverDivId ? 'far fa-dot-circle marketplace-address-popup-selected' : 'far fa-circle'} aria-hidden='true'></i>}
                      </label> */}
                        <label htmlFor={`${index}`} className='col-10 company-required-field px-3 m-0' style={{ cursor: 'pointer' }} >
                          <h5>{address.address_name} </h5><h6 className='muted'>{address?.formatted_address}</h6>
                        </label>
                        {/* <input className='' id={`${index}`} type='radio' value={address.id} name='address' onChange={() => handleAddressChange(address)} /> */}
                      </div>)
                    }

                    {!loggedInCompanyId && (deliveryAddressFormToggle || fromCart) &&
                      <div className='form-row mt-3 delivery-address'>
                        <input className='mx-3' id={`${localAddress.formatted_address}`} type='radio' value={localAddress.formatted_address} checked name='address' />
                        <label htmlFor={`${localAddress.formatted_address}`} className='col-10 company-required-field px-3 m-0' style={{ cursor: 'pointer' }} >
                          {/* h5 tag used for consistency of form no data displayed for public user */}
                          <h5> </h5>
                          <h6 className='muted'>{localAddress?.formatted_address}</h6>
                        </label>
                      </div>
                    }


                    {/* {deliveryAddressFormToggle && addresses?.comp_addresses ? ( addresses?.comp_addresses?.addresses?.map((address, index) =>
                      <div
                        key={index}
                        className='form-row mt-3 delivery-address'
                      >
                        <input className='mx-3' id={`${index}`} type='radio' value={address.id} checked={popUpAddress ? address.id === popUpAddress : address.id === selectedAddress?.id} name='address' onChange={() => handleAddressChange(address)} />
                        <label htmlFor={`${index}`} className='col-10 company-required-field px-3 m-0' style={{ cursor: 'pointer' }} >
                          <h5>{address.address_name} </h5><h6 className='muted'>{address?.formatted_address}</h6>
                        </label>
                      </div>)
                    ): (
                        <div className='form-row mt-3 delivery-address'>
                          <input className='mx-3' id={`${localAddress.formatted_address}`} type='radio' value={localAddress.formatted_address} checked name='address' />
                          <label htmlFor={`${localAddress.formatted_address}`} className='col-10 company-required-field px-3 m-0' style={{ cursor: 'pointer' }} >
                            <h6 className='muted'>{localAddress?.formatted_address}</h6>
                          </label>
                      </div>
                      )
                    } */}

                    {/* <div className='form-row mt-4'>
                    <div className='col-12 m-auto text-center'>
                      <h5 className='text-left'>New Delivery Address</h5>
                    </div>
                  </div> */}
                    {(!deliveryAddressFormToggle || fromCart) && <AddLocationForm
                      showHeading={false}
                      handleChange={handleChange}
                      handleTimezone={handleTimezone}
                      handleAddress={handleAddress}
                      timezone={timezone}
                      errors={_errors}
                      focusDeliveryAddress ={true}
                    />
                    }
                  </div>
                  {/* <button
                  type='submit'
                  className='ml-3 mt-3 btn btn-primary normalBtn'
                  onClick={handleSubmit}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? <ButtonSpinner /> : 'Add Location'}
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer new-location-modal-footer px-32 py-4'>
          {deliveryAddressFormToggle ?
            <>

              <button className='btn restaurant-location-btn restaurant-location-btn-cancel ' onClick={() => onAddressPopUpClose()}>Cancel</button>
              <button className='btn restaurant-location-btn restaurant-location-btn-done' onClick={(e) => { fromPublicMarketPlace ? onAddressPopUpClose() : (fromCart ? handleSubmit(e) : handleAddressPopUp()) }} disabled={fromCart ? !addressVerified : false}>Done</button>
            </>
            :
            <div className='px-sm-4 px-0'>
              <button className='btn restaurant-location-btn restaurant-location-btn-cancel ' onClick={() => onAddressPopUpClose()}>Cancel</button>
              <button
                type='submit'
                onClick={handleSubmit}
                disabled={buttonLoading}
                className='btn restaurant-location-btn restaurant-location-btn-done ml-2'>
                {buttonLoading ? <ButtonSpinner /> : 'Add Location'}
              </button>
              {/* <button
                type='submit'
                className='ml-3 mt-3 btn btn-primary normalBtn'
                onClick={handleSubmit}
                disabled={buttonLoading}
              >
                {buttonLoading ? <ButtonSpinner /> : 'Add Location'}
              </button> */}
            </div>
          }
        </div>
      </Modal>
      {/* <AddNewDeliveryAddressPopUp
      setAddressInMarketPlace={(address: number) => this.setSelectedAddress(address)}
      selectedCompanyAddress={selectedAddressDetail}
      addresses={addresses}
      isOpen={true}
      closeModal={closeModal}
      openFilter={() => this.setState({ isOpenFiltersPopUp: true })}
    /> */}
    </>
  );
});
export default DeliveryAddressPopUp;