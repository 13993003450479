import React, { FC, useEffect } from "react";
import isEmpty from "../../../Utilities/is_empty";
import { MarketplaceRestaurant } from "../../../Interfaces/PayloadsAndResponses/Marketplace";
import dayjs from "dayjs";

type ShowAllMostPopularRestaurantsProps = {
  marketplace_restaurant: MarketplaceRestaurant[] | null;
  selectRestaurant: (marketplace_restaurant: MarketplaceRestaurant, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  backToMarketPlace: () => void;
  startDate: Date | undefined;
  selectedCompanyAddress: any;
  showAllNewRestaurantsNearYou?: boolean
}

const ShowAllMostPopularRestaurants: FC<ShowAllMostPopularRestaurantsProps> = ({ marketplace_restaurant, selectRestaurant, backToMarketPlace, startDate, selectedCompanyAddress, showAllNewRestaurantsNearYou }) => {
  const backToMarketPlaceRestaurants = () => {
    backToMarketPlace?.()
  }
  useEffect(() => {
    window.scroll(0, 0)

  }, [])
  const dateStr: Date | undefined = startDate;
  let formattedDate: string = '';

  if (dateStr) {
    formattedDate = dayjs(dateStr).format("dddd, MMM D [at] h:mm A");
  }

  return (
    <div className="bg-white">
      <div className="container-fluid px-3 px-sm-0 border-bottom">
          <div className="container">
        <div className="row py-2 py-sm-3 px-3 ">
          <div className='px-0 col-sm-12 col-md-12 col-lg-4 d-flex pb-1 pb-sm-0'>
            <div className='main-custom-options main-custom-options-marketplace d-flex align-items-center'>
              <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 25.062 33">
                  <path id="location-dot-thin_-_Outline" data-name="location-dot-thin - Outline" d="M12.031-.5A12.533,12.533,0,0,1,24.562,12.031c0,2.464-1.394,5.974-4.142,10.432a96.042,96.042,0,0,1-6.514,9.129,2.393,2.393,0,0,1-3.75,0C7.658,28.521-.5,18.023-.5,12.031A12.531,12.531,0,0,1,12.031-.5ZM14.473.76A11.533,11.533,0,0,0,.5,12.031c0,2.253,1.374,5.679,3.972,9.908a90.26,90.26,0,0,0,6.462,9.026,1.393,1.393,0,0,0,2.193,0c3.137-3.926,10.436-13.633,10.436-18.937a11.534,11.534,0,0,0-.26-2.442,11.635,11.635,0,0,1,.258,2.442,12.385,12.385,0,0,1-1.155,4.555,40.474,40.474,0,0,1-2.836,5.351,104.843,104.843,0,0,1-6.445,9.029,1.389,1.389,0,0,1-2.183,0A104.616,104.616,0,0,1,4.5,21.94a40.169,40.169,0,0,1-2.838-5.353A12.321,12.321,0,0,1,.5,12.031,11.529,11.529,0,0,1,12.031.5,11.635,11.635,0,0,1,14.473.76ZM12.031,30.5a.4.4,0,0,0,.313-.157,103.758,103.758,0,0,0,6.375-8.931A39.472,39.472,0,0,0,21.484,16.2a11.448,11.448,0,0,0,1.076-4.166,10.529,10.529,0,0,0-21.057,0A11.387,11.387,0,0,0,2.58,16.2a39.172,39.172,0,0,0,2.766,5.214,103.531,103.531,0,0,0,6.374,8.93A.4.4,0,0,0,12.031,30.5Zm0-23.979a5.513,5.513,0,1,1-5.513,5.513A5.519,5.519,0,0,1,12.031,6.518Zm0,10.026a4.513,4.513,0,1,0-4.513-4.513A4.518,4.518,0,0,0,12.031,16.544Zm0-9.023a4.51,4.51,0,1,1-4.51,4.51A4.515,4.515,0,0,1,12.031,7.521Zm0,8.021a3.51,3.51,0,1,0-3.51-3.51A3.514,3.514,0,0,0,12.031,15.542Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                </svg>
              </span>
                <input
                  type='text'
                  className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                  id='address'
                  name='selectedCompanyAddress'
                  value={selectedCompanyAddress?.formatted_address || ''}
                  readOnly
                />
                <i className='fas fa-chevron-down mx-1 px-1' style={{ lineHeight: 'inherit' }}></i>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 pt-2 py-md-2 py-lg-0 px-0 px-ml-3 d-flex align-items-center justify-content-between">
            <div className={`px-0 position-relative d-flex align-items-center`}>
              <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 25 28.429">
                  <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                </svg>
              </span>
              <div className="marketplace-time-picker-input">
                {formattedDate && formattedDate}
              </div>
              <i className='fas fa-chevron-down mx-1' style={{ lineHeight: 'inherit' }}></i>
              </div>
              <h4 className='m-0 cursor-pointer d-none'>
                <i className="fas fa-info-circle" style={{ color: '#A8A8AA' }} title="Defining what is Cutoff Time"></i>
              </h4>
            </div>
          </div>
          </div>
          </div>
          <div className="container">
          <div className='col-sm d-flex align-items-center px-0 cursor-pointer' style={{color:"#1E4353"}} onClick={backToMarketPlaceRestaurants}>
            <span
              data-tip
              data-for='back-to-marketplace'
              className='py-2 py-sm-4'
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="marketplace-background">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </span>
            <p className=' fs-14 fw-normal text-dark m-0 pl-2 '>Back To Marketplace</p>
          </div>
          <div className='popular-feature-box pb-3 px-0 px-sm-3 px-md-0 d-flex justify-content-between'>
            <div className='d-flex align-items-center justify-content-start' style={{ minWidth: '280px' }}>
              <img src="/imgs/SVGs/Most Popular.svg" alt="all-restaurant" className='all-restaurant-img' />
              <h4 className='mb-0 font-weight-bold restaurant-heading mx-0 mx-sm-0'>{showAllNewRestaurantsNearYou ? 'New Restaurants Near You' : 'Most Popular'}</h4>
            </div>
          </div>
        <>
        <div className="d-flex flex-wrap">
        {marketplace_restaurant?.map((marketplace_restaurant, index) =>
          <React.Fragment key={index}>
            <div className='individual-restaurants col-lg-3 px-0 w-100 pl-0'>
              <div>
                <div onClick={e => selectRestaurant(marketplace_restaurant, e)}>
                  <div className='card combo-card bg-transparent' key={index} id={'toggleCard' + marketplace_restaurant.id}  >
                    <div key={index}
                      style=
                      {(isEmpty(marketplace_restaurant.image)) ? { backgroundImage: 'url("/imgs/SVGs/ComboPlaceholder.svg")' } : { backgroundImage: 'url(' + marketplace_restaurant.image + ')' }}
                      className='individual-restaurant-card-img'
                    />
                    <div className='card-body individual-restaurant-card-body py-3 px-0 pb-0 d-flex bg-white'>
                      <div className="restaurant-card-logo-wrapper">
                        {marketplace_restaurant.logo_url?.url ?
                          <div className='mr-1 mr-sm-2 restaurant-logo' style={{ backgroundImage: `url(${marketplace_restaurant.logo_url?.url})`, width: 40, height: 40 }}></div>
                          :
                          <div id='autoThumbnailParent'>
                            <div id='autoThumbnailChild'>
                              <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{marketplace_restaurant?.name?.charAt(0)} </span>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="text-overflow-handling">
                        <h6 className='individual-restaurant-card-title mb-2'>{marketplace_restaurant?.name}</h6>
                        <div className="d-flex align-items-center">
                          {+marketplace_restaurant?.average_rating === 0.0 ? null : <>
                          <div className='d-inline float-right'>
                            <div className='d-flex'>
                              <i className="fas fa-star marketplace-combo pr-1 fs-18" aria-hidden="true"> </i>
                              <p className='fs-18 fw-normal m-0 text-gray-100 pr-2 pr-sm-2 rating-number' style={{ fontSize: '0.95rem' }}>{marketplace_restaurant?.average_rating}</p>
                            </div>
                          </div>
                            <span className='d-flex justitfy-content-center align-items-center mx-1'><i className="fas fa-circle small-dot" style={{color:"#6E6E73"}}></i></span>
                          </>
                          }
                          {marketplace_restaurant?.distance && marketplace_restaurant?.distance !== "Google Return ZERO_RESULTS" && <div className='d-flex'>
                            <div className='fs-14 fw-normal badge badge-light text-gray-500 combo-badge'>{marketplace_restaurant?.distance} mi</div>
                            <span className='d-flex justitfy-content-center align-items-center mx-1'><i className="fas fa-circle small-dot" style={{color:"#6E6E73"}}></i></span>
                          </div>
                          }
                          <p className='card-text mb-0' style={{ 'height': 'auto' }}>
                            {marketplace_restaurant?.cuisines && marketplace_restaurant?.cuisines?.split(',')?.slice(0, 1)?.map((cuisine, index) => (
                              <span key={index} className='mr-2 individual-combo-badge'>{cuisine}</span>
                            ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        </div>
      </>
     </div>
    </div>
  )
}

export default ShowAllMostPopularRestaurants;