import React, { FC, useEffect, useState } from 'react';

type Props = {
  onSortRestaurants: (sortBy: string) => void;
};
const FilterRestaurantsBy: FC<Props> = ({ onSortRestaurants }) => {
  const [selectedSorting, setSeletedSorting] = useState<string>('rating')
  useEffect(() => {
    const sortingType = JSON.parse(localStorage.getItem('sortingMarketPlace') || '{}')
    if (sortingType === 'distance') {
      setSeletedSorting('distance')
    } else if (sortingType === 'price') {
      setSeletedSorting('price')
    } else {
      setSeletedSorting('rating')
    }
  }, [selectedSorting])

  const sorting = (sortingValue: string) => {
    if (sortingValue === 'distance') {
      setSeletedSorting('distance')
    } else if (sortingValue === 'price') {
      setSeletedSorting('price')
    } else {
      setSeletedSorting('rating')
    }
    onSortRestaurants(sortingValue)
  }
  return (
    <div className='dropdown-menu dropdown-menu-right filter-restaurant-dropdown border-0'>
      <div className='p-2 dropdown-item'>
        <p className='m-0 ml-2' onClick={() => { sorting('rating') }}>Rating <span className='ml-3'>{selectedSorting === 'rating' ? '✓' : ''}</span></p>
      </div>
      <div className=''/>
      <div className='p-2 dropdown-item'>
        <p className='m-0 ml-2' onClick={() => { sorting('distance') }}>Distance <span className='ml-3'>{selectedSorting === 'distance' ? '✓' : ''}</span></p>
      </div>
      <div className='p-2 dropdown-item'>
        <p className='m-0 ml-2' onClick={() => { sorting('price') }}>Price <span className='ml-3'>{selectedSorting === 'price' ? '✓' : ''}</span></p>
      </div>
    </div>
  );
};

export default FilterRestaurantsBy;
