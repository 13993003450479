import { MAKE_NEW_SCHEDULE_REQUEST, EMPTY_NEW_SCHEDULE_REQUEST_DATA, GET_SINGLE_SCHEDULE, GET_SINGLE_SCHEDULE_LOADING, UPDATE_SCHEDULE_REQUEST, RESET_STATE } from '../Actions/types';
import { SchedulesDetails } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: SchedulesDetails = {
  new_schedule_request_status: null,
  single_schedule: null,
  single_schedule_loading: false,
  update_schedule_status: null
}

const scheduleDeliveryReducer = (state: SchedulesDetails = initialState, action: any): SchedulesDetails => {
  switch (action.type) {
    case MAKE_NEW_SCHEDULE_REQUEST:
      return {
        ...state,
        new_schedule_request_status: action.payload,
      }
    case EMPTY_NEW_SCHEDULE_REQUEST_DATA:
      return {
        ...state,
        new_schedule_request_status: null,
      }
    case UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        update_schedule_status: action.payload,
      }
    case GET_SINGLE_SCHEDULE_LOADING:
      return {
        ...state,
        single_schedule_loading: true
      }
    case GET_SINGLE_SCHEDULE:
      return {
        ...state,
        single_schedule: action.payload,
        single_schedule_loading: false
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default scheduleDeliveryReducer