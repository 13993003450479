import React, { FC, useEffect, useRef, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignUpWidget from "../Signup/SignUpWidget";
// import CartLoginWrapper from "./CartLoginWrapper";
import StripeLayout, { StripeLayoutRef } from "../../Common/Stripe/StripeLayout";
import PhoneInput from "react-phone-input-2";
// import isEmpty from "../../Utilities/is_empty";
// import { PlaceOrderPayload } from "../../Interfaces/PayloadsAndResponses/Orders";
import { ItemDataType, OrderType, PlaceCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import isEmpty from '../../Utilities/is_empty';
import { useSelector, useDispatch } from "react-redux";
import { ReduxRootState } from "../../Interfaces/ReduxInterface/ReduxRootState";
import { CartDetails as CartDetailsType, CartSummary } from "../../Interfaces/PayloadsAndResponses/Cart";
// import { DeliveryFeePayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import dayjs from 'dayjs';
// import { useParams } from 'react-router-dom';
import { updateCart } from '../../Actions/cartActions';
import store from '../../store';
import { CART_DETAILS_ITEM_COUNT, CART_ITEMS_UPDATE_ALL, CART_SUMMARY, CART_SUMMARY_TIP, CART_SUMMARY_TIP_PERCENTAGE, EMPTY_CART, SAVE_CART_ORDER_PAYLOAD} from "../../Actions/types";
import ReactTooltip from "react-tooltip";
// import { getZonedTime } from '../../Utilities/functions';
import { User } from "../../Interfaces/PayloadsAndResponses/Auth";
import { PhoneFormatter } from "../../Utilities/PhoneFormatter";
import Modal from 'react-modal';
import { getloggedInUser } from "../../Utilities/getloggedInUser";
import ButtonSpinner from "../../Common/Spinners/ButtonSpinner";
import Skeleton from "react-loading-skeleton";
import Login from "../Login/Login";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

const deletePopUpStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'10px',
    transform: 'translate(-50%, -50%)',
    // width: '90vw',
    maxWidth: '600px',
    padding: '0',
  }
};

type CartDetailsProps = {
  isOpenSidebar: boolean;
  closeSidebar: () => void;
  fromPublicMarketPlace: boolean;
  onItemAddedToCart?: (itemImage: string | null, itemName: string) => void;
  continueOrdering?: () => void;
}

enum OPERATION {
  INCREMENT = 'Increment',
  DECREMENT = 'decrement'
}

type TipSelectionType = {
  value: number | string;
  label: string;
}

const TipSelection: TipSelectionType[] = [
  { value: 5, label: '5%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 'other', label: 'Other' }

]

const CartDetails: FC<CartDetailsProps> = ({ fromPublicMarketPlace = false, closeSidebar, isOpenSidebar, continueOrdering}) => {
  const reduxCartItems = useSelector((state: ReduxRootState) => state.cart.items ?? []);
  const reduxCartSummary = useSelector((state: ReduxRootState) => state.cart.summary);
  const reduxCartDetails = useSelector((state: ReduxRootState) => state.cart.details);
  const reduxCartAddressIds = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);

  const [cartItems, setCartItems] = useState<ItemDataType[]>(reduxCartItems ?? []);
  const [cartSummary, setCartSummary] = useState<CartSummary>(reduxCartSummary ?? {});
  const [cartDetails, setCartDetails] =useState<CartDetailsType>(reduxCartDetails ?? {});
  const [defaultTip, setDefaultTip] = useState<number>(0)
  const [defaultTipTotal, setDefaultTipTotal] = useState<number>(0)

  const[incDecLoader] = useState<boolean>(false)
  const [loadingIndices, setLoadingIndices] = useState<number|undefined>();
  // const { date }: any = useParams();
  const dispatch: any = useDispatch();

  // const tempCard = useSelector((state: ReduxRootState) => state.profile);
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);
  // const timeZone = JSON.parse(localStorage.getItem('address') || '{}')?.timezone;

  const [cartState, setCartState] = useState<any>('cart')
  // const [cartCreditCard, setCartCreditCard] = useState<any>()
  // const { company_detail_for_order_purpose} = useSelector((state: ReduxRootState) => state.company_data_reducer);
  const { cart_at_login } = useSelector(((state: ReduxRootState) => state.server_cart));
  const cardDetails = useSelector((state: ReduxRootState) => state.profile.profile?.card_details);
  const addressIdForDeliveryAt = useSelector((state: ReduxRootState) => state?.addresses?.selectedAddressId);

  const [expanded, setExpanded] = useState<boolean>(true);
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
  const { loggedInUser } = getloggedInUser()
  // const localAddress = JSON.parse(localStorage.getItem('address') || '{}');

  // stripePaymentRef = createRef<StripeLayoutRef>().current;
  const stripePaymentRef = useRef<StripeLayoutRef>().current;
  // const stripePaymentRef1 = useRef<StripeLayoutRef>().current;
  const { handleFormSubmit } = stripePaymentRef || {};
  // const [cartStripeToken, setCartStripeToken] = useState('')
  const [cartStripeTokenLocal, setCartStripeTokenLocal] = useState('')

  const [company_user_credit_card_token_id] = useState(null)
  let token = ''
    if (company_user_credit_card_token_id) {
      token = company_user_credit_card_token_id as unknown as string;
      if (token){
        
      }
    } else {
      handleFormSubmit?.();
    }

  const [cartDeliveryDetails, setCartDeliveryDetails] = useState<any>({
    name: user.first_name + user.last_name ?? '',
    phoneNumber: user.phone_number ?? '',
    deliveryInstructions: ''
  })
  const [phoneErr, setPhoneErr] = useState<any>('empty')

  const errorStatefun = () => {
      if(phoneErr === 'invalid'){
         return 'Please match the format XXX-XXX-XXXX'
      }else if(phoneErr === 'empty'){
        return 'Phone number is required'
      }else {
        return ''
      }
  }
  const cartDeliveryDetailsHandler = (e: any) => {
    setCartDeliveryDetails({
      ...cartDeliveryDetails,
      [e.target.name]: e.target.value
    })
  }
  // const cartDeliveryDetailsPhoneHandler = (phone: any) => {
  //   setCartDeliveryDetails({
  //     ...cartDeliveryDetails,
  //     phone: phone
  //   })
  // }
  // const cartDisplayHandling = (page: any) => {
  
  const [tipItnput, setTipItnput] = useState<boolean>(false);
  const [tipValue, setTipValue] = useState<number>(0);
  const [tipOtherValue, setTipOtherValue] = useState<number | undefined>();
  const [CCinfo] = useState<any>(cardDetails)
  const [checkoutPopUp, setCheckoutPopUp] = useState<boolean>(false)
  const [deletePopUP, setDeletePopUP] = useState<boolean>(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<number>();
  const [tipName, setTipName] = useState<undefined|string>();
  const [tempDriverTip, setTempDriverTip] = useState<number>() 
  const [tempDriverTipPercent, setTempDriverTipPercent]=useState<number>() 

  const cartDisplayHandling = (page: any) => {
    setCartState(page)
  }
  const cartPopupDisplayHandling = () => {
    setCheckoutPopUp(true)
    }

  const closeModal = () => {
    if (deletePopUP) {
      setDeletePopUP(false)
    } else {
      setCheckoutPopUp(false)
      window.location.reload()
    }
  }

  const placeOrder = () => {
    if (!isEmpty(cartDeliveryDetails.name) && !isEmpty(cartDeliveryDetails.phoneNumber) && (cartDeliveryDetails.phoneNumber?.length === 10)) {
      const slugForCart = cart_at_login.slug || ""
      const formattedPhone = PhoneFormatter(cartDeliveryDetails?.phoneNumber)
      const Checkoutpayload = {
        person_name: cartDeliveryDetails?.name,
        person_phone: formattedPhone,
        delivery_instructions: cartDeliveryDetails?.deliveryInstructions,
      }
      const stripeCheckoutPayLoad = {
        person_name: cartDeliveryDetails?.name,
        person_phone: formattedPhone,
        delivery_instructions: cartDeliveryDetails?.deliveryInstructions,
        stripe_token: cartStripeTokenLocal,
      }
      if (cartStripeTokenLocal){
        dispatch(updateCart(stripeCheckoutPayLoad, slugForCart, cartPopupDisplayHandling))
      }else{
        dispatch(updateCart(Checkoutpayload, slugForCart, cartPopupDisplayHandling))

      }
    }
  }
  useEffect(() => {
  }, [CCinfo])

  const cartNavigationHandler = ( page: string) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
    if (user?.email && page === 'checkout'){
      setCartState('confirm')
    }else if (user?.email && page === 'placeOrder'){
      setCartState('cart')
    }else if (page === 'checkout'){
      setCartState('signUp')
    }else if (page === 'placeOrder') {
      setCartState('login')
    }
  }
  const cartStripeTokenHandler = (token: string) => {
    setCartStripeTokenLocal(token)
  } 
  // store.dispatch({
  //   type: SET_MENU_TAB_MEETING,
  //   payload: meeting
  // });

  const CalculateItemTotal = (cartItems: ItemDataType[]) => {
    const itemTotal = cartItems.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0);
    return itemTotal
  }

  const CalculateItemPriceTotal = (cartItems: ItemDataType[]) => {
    const itemTotal = cartItems.reduce((accumulator, item) => {
      return accumulator + (item.itemPriceWithOptions ? item.itemPriceWithOptions : item.itemPrice) * item.quantity;
    }, 0);
    return itemTotal
  }

  const CalculateSalesTax = (cartItems: ItemDataType[]) => {
    const salesTaxTotal = cartItems.reduce((accumulator, item) => {
      return accumulator + ((item.itemPriceWithOptions ? item.itemPriceWithOptions : item.itemPrice) * item.quantity) * item.itemSalesTaxPercentage;
    }, 0);
    return salesTaxTotal
  }
  // Stopped. it disturbed the chowmill.com to app redirect
  // useEffect(() => {
  //   localStorage?.removeItem?.('address');
  // }, [])

  useEffect(() => {
    if(cartItems.length > 0) {
      setDefaultTip(0)
      setDefaultTipTotal(0)
      let defaultTipCalculated = 0;
      let defaultTipTotalCalculated = 0;
      if (cartSummary.driverTipPercent === undefined) {
        defaultTipCalculated = ((cartSummary.itemsSubtotal / 100) * 10)
        setDefaultTip(defaultTipCalculated)
        defaultTipTotalCalculated = +(defaultTip && ((defaultTip + cartSummary?.total).toFixed(2)));
        setDefaultTipTotal(defaultTipTotalCalculated)
        setTipOtherValue(undefined);
      }

      // prepareDeliveryFeePayload(cartItems);
      const ItemsSubTotal: number = +(CalculateItemPriceTotal(cartItems).toFixed(2));
      const SalesTax: number = +(CalculateSalesTax(cartItems).toFixed(2));
      const totalForStripeFee = (ItemsSubTotal ?? 0) + (SalesTax ?? 0) + (cartSummary.deliveryFee ?? 0) + (cartSummary.driverTip ?? defaultTipCalculated);
      const totalWithStripeFeeFormulaValue = +((totalForStripeFee) + .30);
      // const deviderFor = +(1 - .029)
      const stripeCalc = +(totalWithStripeFeeFormulaValue / 0.971)
      const StripeFee: number = +(stripeCalc - totalForStripeFee).toFixed(2); // Stripe Fee Formula
      // const StripeFee: number = (+((((ItemsSubTotal ?? 0) + (SalesTax ?? 0) + (cartSummary.deliveryFee ?? 0) + (cartSummary.driverTip ?? 0) + .30) / (1 - .029))).toFixed(2));
      const Total: number = +((ItemsSubTotal  ?? 0) + (SalesTax ?? 0) + (cartSummary.deliveryFee ?? 0) + (StripeFee ?? 0) + (cartSummary.driverTip ?? 0)).toFixed(2);

      const CartSummaryPayload: CartSummary = {
        itemsSubtotal: ItemsSubTotal,
        salesTax: SalesTax,
        deliveryFee: cartSummary.deliveryFee,
        cardProcessingFee: StripeFee,
        driverTip: cartSummary.driverTip,
        driverTipPercent: cartSummary.driverTipPercent === undefined ? 10 : cartSummary.driverTipPercent,
        total: isNaN(+cartSummary.driverTip) ? defaultTipTotal : Total
      }

      store.dispatch({
        type: CART_SUMMARY,
        payload: CartSummaryPayload
      });
      if (cartSummary.driverTipPercent) {
        calculateDriverTip()
      }

      //Preapare Cart Order Payload and save at redux
      const orderAttributes: OrderType[] = []
      let itemObj: OrderType = {} as OrderType
      cartItems?.forEach((item) => {
        itemObj = {
          quantity: item.quantity,
          fooditem_id: item.itemID,
          optionsets_orders_attributes: item.optionsets_orders_attributes,
          remarks: 'no remarks',
          utensils: item?.utensils,
        }
        orderAttributes.push(itemObj)
      });


      const orderDataWithTip: PlaceCartOrderPayload = {
        runningmenu: {
          runningmenu_name: cartDetails.name ?? '', 
          delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
          driver_tip: cartSummary.driverTip ?? 0,
          // driver_tip_percentage: cartSummary.driverTipPercent ?? 0,
          orders_attributes: orderAttributes,
          address_ids: reduxCartAddressIds,
          remove_previous_cart: false
        }
      }

      const orderDataWithTipPercent: PlaceCartOrderPayload = {
        runningmenu: {
          runningmenu_name: cartDetails.name ?? '', 
          delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
          // driver_tip: cartSummary.driverTip ?? 0,
          driver_tip_percentage: cartSummary.driverTipPercent ?? 0,
          orders_attributes: orderAttributes,
          address_ids: reduxCartAddressIds,
          remove_previous_cart: false
        }
      }

      let orderData: PlaceCartOrderPayload

      if (cartSummary.driverTipPercent) {
        let orderDataWithAddressId: any = {}
        if (loggedInUser.phone_number) {
          orderDataWithAddressId = { ...orderDataWithTipPercent, address_id: addressIdForDeliveryAt ?? '' }
        } else {
          orderDataWithAddressId = { ...orderDataWithTipPercent, company: { addresses_active_attributes: [cartDetails?.deliveryAddress] } }
          // orderDataWithAddressId = orderDataWithTipPercent
        }
        orderData = orderDataWithAddressId
      } else {
        let orderDataWithAddressId: any = {}
        if (loggedInUser.phone_number) {
          orderDataWithAddressId = { ...orderDataWithTip, address_id: addressIdForDeliveryAt ?? '' }
        } else {
          orderDataWithAddressId = { ...orderDataWithTip, company: { addresses_active_attributes: [cartDetails?.deliveryAddress] } }
          // orderDataWithAddressId = orderDataWithTip
        }
        orderData = orderDataWithAddressId
      }

      if(orderData?.runningmenu?.orders_attributes?.length >= 1) {
        store.dispatch({
          type: SAVE_CART_ORDER_PAYLOAD,
          payload: orderData
        })
      }
    }
    // eslint-disable-next-line
  },[cartItems, tipValue, tipOtherValue, defaultTipTotal,cartSummary.itemsSubtotal])

  useEffect(() => {
    if(reduxCartItems.length > 0) {
      setDefaultTip(0)
      setDefaultTipTotal(0)
      let defaultTipCalculated = 0;
      let defaultTipTotalCalculated = 0;
      if(reduxCartSummary.driverTipPercent === undefined) {
        defaultTipCalculated = ((cartSummary.itemsSubtotal / 100) * 10)
        setDefaultTip(defaultTipCalculated)
        defaultTipTotalCalculated = +(defaultTip && ((defaultTip + (cartSummary?.total)).toFixed(2)));
        setDefaultTipTotal(defaultTipTotalCalculated)
        setTipOtherValue(undefined);
      }
      setCartItems(reduxCartItems)
      const ItemsSubTotal: number = +(CalculateItemPriceTotal(reduxCartItems)).toFixed(2);
      const SalesTax: number = +(CalculateSalesTax(reduxCartItems)).toFixed(2);

      const totalForStripeFee = (ItemsSubTotal ?? 0) + (SalesTax ?? 0) + (reduxCartSummary.deliveryFee ?? 0) + (reduxCartSummary.driverTip ?? defaultTipCalculated)
      const totalWithStripeFeeFormulaValue = +((totalForStripeFee) + .30)
      // const deviderFor = +(1 - .029)
      const stripeCalc = +(totalWithStripeFeeFormulaValue / 0.971)
      const StripeFee: number = +(stripeCalc - totalForStripeFee).toFixed(2); // Stripe Fee Formula
      const Total: number = +((ItemsSubTotal  ?? 0) + (SalesTax ?? 0) + (reduxCartSummary.deliveryFee ?? 0) + (StripeFee ?? 0) + (reduxCartSummary.driverTip ?? 0)).toFixed(2);

      setCartSummary({
        itemsSubtotal: ItemsSubTotal,
        salesTax: SalesTax,
        deliveryFee: reduxCartSummary.deliveryFee,
        cardProcessingFee: StripeFee,
        driverTip: reduxCartSummary.driverTip,
        driverTipPercent: reduxCartSummary.driverTipPercent === undefined ? 10 : reduxCartSummary.driverTipPercent,
        total: Total
      })
      setCartDetails({...reduxCartDetails, itemCount: +(CalculateItemTotal(reduxCartItems))})
    }
    // eslint-disable-next-line
  }, [reduxCartItems, reduxCartSummary]);
  
  // Get Delivery Fee
  const prepareDeliveryFeePayload = (items: ItemDataType[]) => {
    if( items.length > 0) {
      const uniqueAddresses = Array.from(new Set(items.map(a => a.itemAddress)))
      .map(address => {
        return items.find(a => a.itemAddress === address)
      })

      type route = {route_to_text: string}
      const routes: route[] = [];
      uniqueAddresses.forEach(item => {
        item && routes.push({route_to_text: item?.itemAddress})
      })

      // type route = {
      //   route_to_text: string;
      // }
      // const routes: route[] = [];
      // let routeObj = {} as route
      // items.forEach((item) => {
      //   routeObj = {
      //     route_to_text: item.itemAddress
      //   }
      //   routes.push(routeObj)
      // });
      
      // const localAddress = JSON.parse(localStorage.getItem('address') || '{}')?.addressLine;
      // routes.push({route_to_text: localAddress})
      
      cartDetails?.deliveryAddress && routes.push({route_to_text: cartDetails.deliveryAddress?.address_line})
  
      // const deliveryFeePayload: DeliveryFeePayload = {
      //   data: {
      //     // delivery_at: `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(date).format('HH:mm')}`, // "2022-01-17 00:03"
      //     delivery_at: `${dayjs(cartDetails.deliveryDate).format('YYYY-MM-DD')} ${dayjs(cartDetails.deliveryDate).format('HH:mm')}`, // "2022-01-17 00:03"
      //     item_value: (+(CalculateItemPriceTotal(items))).toString(),
      //     pack_size_id: "2",
      //     routes: routes
      //   }
      // }
      // dispatch(getDeliveryFee(deliveryFeePayload, incDecLoaderHandler))
    }
  }

  //Increment & Decrement Item Count
  const incrementDecrementItemCount = (itemIndex: number, operation: OPERATION) => {
    setLoadingIndices(itemIndex)
    const resultedCartItems = cartItems?.map((item, index) => {
      calculateDriverTip()
      if (index === itemIndex) {
        OPERATION.INCREMENT === operation ? item.quantity++ : item.quantity --
      }
      return item
    });
    setCartItems(resultedCartItems);
    store.dispatch({
      type: CART_ITEMS_UPDATE_ALL,
      payload: resultedCartItems
    });
    store.dispatch({
      type: CART_DETAILS_ITEM_COUNT,
      payload: +(CalculateItemTotal(resultedCartItems))
    });
    prepareDeliveryFeePayload(resultedCartItems);
    calculateDriverTip();
  };

  const calculateDriverTip = () => {
    if (tipName !== 'other_tip' && cartSummary.driverTipPercent !== undefined) {
      const tipValue: number = +(((cartSummary.driverTipPercent ?? 0) / 100) * (cartSummary.itemsSubtotal)).toFixed(2);
      store.dispatch({
        type: CART_SUMMARY_TIP,
        payload: tipValue
      })
      setTipValue(tipValue);
    }
  }

  
  // const incDecLoaderHandler = (value:boolean) => {
  //   setIncDecLoader(value)
  // }
  const deleteCartItemConfirmation = (itemIndex: number) => {
    setDeleteItemIndex(itemIndex);
    setDeletePopUP(true);
  }

  //Delete Item
  const deleteCartItem = (itemIndex: number) => {
    setLoadingIndices(undefined)
    if(cartItems.length > 1) {
      cartItems.splice(itemIndex, 1)
      setCartItems(cartItems);
      store.dispatch({
        type: CART_ITEMS_UPDATE_ALL,
        payload: cartItems
      });
      prepareDeliveryFeePayload(cartItems);
      setDeletePopUP(false);
      calculateDriverTip();
    } else {
      calculateDriverTip();
      setDefaultTip(0.00)
      store.dispatch({
        type: EMPTY_CART
      })
      setCartItems([])
      setCartSummary({} as CartSummary)
      setCartDetails({} as CartDetailsType)
      setDeletePopUP(false);
      closeSidebar?.()
    }
  }

  const onTipSelect = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setLoadingIndices(undefined)
    if(e.target.name === 'other_tip' ) {
      setTipOtherValue(+e.target.value);
      setTipName(e.target.name)
      setCartSummary({...cartSummary, driverTip: +e.target.value, driverTipPercent: 0})
    } else {
      if(e.target.value === 'other') {
        setTempDriverTip(cartSummary.driverTip)
        setTempDriverTipPercent(cartSummary.driverTipPercent)
        //setCartSummary({...cartSummary, driverTip: 0, driverTipPercent: 0})
        setTipValue(0)
        setTipItnput(true);
        setTipName(e.target.name)
      } else {
        setTipName(e.target.name)
        setTipOtherValue(undefined)
        const tipValue: number = +((+e.target.value / 100) * (cartSummary.itemsSubtotal)).toFixed(2);
        setCartSummary({...cartSummary, driverTip: tipValue, driverTipPercent: +e.target.value})
        store.dispatch({
          type: CART_SUMMARY_TIP,
          payload: tipValue
        })
        store.dispatch({
          type: CART_SUMMARY_TIP_PERCENTAGE,
          payload: +e.target.value
        })
        setTipValue(tipValue);
        setTipItnput(false);
      }
    }
  }

  const goToTipSelection = () => {
    setLoadingIndices(undefined)
    if(isEmpty(tipOtherValue)){
      tempDriverTip && setCartSummary({...cartSummary, driverTip: tempDriverTip, driverTipPercent: tempDriverTipPercent})
      store.dispatch({
        type: CART_SUMMARY_TIP_PERCENTAGE,
        payload: tempDriverTipPercent
      })
      store.dispatch({
        type: CART_SUMMARY_TIP,
        payload: tempDriverTip
      })
        tempDriverTip && setTipValue(tempDriverTip);
      }else{
      store.dispatch({
        type: CART_SUMMARY_TIP,
        payload: tipOtherValue
      })
    }
    
    
    setTipItnput(false);
  }

  // const orderAttributes: OrderType[] = []
  // let itemObj: OrderType = {} as OrderType
  // cartItems?.forEach((item) => {
  //   itemObj = {
  //     quantity: item.quantity,
  //     fooditem_id: item.itemID,
  //     optionsets_orders_attributes: item.optionsets_orders_attributes,
  //     remarks: 'no remarks'
  //   }
  //   orderAttributes.push(itemObj)
  // });


  // const orderDataWithTip: PlaceCartOrderPayload = {
  //   runningmenu: {
  //     runningmenu_name: cartDetails.name ?? '', 
  //     delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
  //     driver_tip: cartSummary.driverTip ?? 0,
  //     // driver_tip_percentage: cartSummary.driverTipPercent ?? 0,
  //     orders_attributes: orderAttributes,
  //     address_ids: reduxCartAddressIds,
  //     remove_previous_cart: false
  //   }
  // }

  // const orderDataWithTipPercent: PlaceCartOrderPayload = {
  //   runningmenu: {
  //     runningmenu_name: cartDetails.name ?? '', 
  //     delivery_at: cartDetails.deliveryDate, //"2023-01-15T11:45",
  //     // driver_tip: cartSummary.driverTip ?? 0,
  //     driver_tip_percentage: cartSummary.driverTipPercent ?? 0,
  //     orders_attributes: orderAttributes,
  //     address_ids: reduxCartAddressIds,
  //     remove_previous_cart: false
  //   }
  // }

  // let orderData: PlaceCartOrderPayload

  // if (cartSummary.driverTipPercent) {
  //   let orderDataWithAddressId: any = {}
  //   if (loggedInUser.phone_number) {
  //     orderDataWithAddressId = { ...orderDataWithTipPercent, address_id: addressIdForDeliveryAt ?? '' }
  //   } else {
  //     orderDataWithAddressId = orderDataWithTipPercent
  //   }
  //   orderData = orderDataWithAddressId
  // } else {
  //   let orderDataWithAddressId: any = {}
  //   if (loggedInUser.phone_number) {
  //     orderDataWithAddressId = { ...orderDataWithTip, address_id: addressIdForDeliveryAt ?? '' }
  //   } else {
  //     orderDataWithAddressId = orderDataWithTip
  //   }
  //   orderData = orderDataWithAddressId
  // }

  // if(orderData?.runningmenu?.orders_attributes?.length >= 1) {
  //   store.dispatch({
  //     type: SAVE_CART_ORDER_PAYLOAD,
  //     payload: orderData
  //   })
  // }

  // const headers = JSON.parse(localStorage.getItem('headers') || '{}')
  // useEffect(() => {
  //   if(!isEmpty(headers) && cartItems.length > 0){
  //     setCartState('confirm')
  //     dispatch(placeCartOrder(orderData))
  //   }
  //   // eslint-disable-next-line
  // }, [])

  const handleCartSummary = () => {
    setExpanded(!expanded);
  };

  const redirectToCartMenu = () => {
    continueOrdering?.()
    closeSidebar?.()
  }

  return (
    <>
      {cartState === 'cart' && <section className="cart-wrapper">
        {/* Cart header Section */}
        <header className="d-flex justify-content-between border-bottom p-3 p-md-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <div className="d-flex align-items-center justify-content-center rounded-circle position-relative cart-logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 33" style={{ height: 32, width: 28 }}>
                <path id="bag-shopping-thin" d="M8,8V6A6,6,0,1,1,20,6V8h5a3,3,0,0,1,3,3V26a6,6,0,0,1-6,6H6a6,6,0,0,1-6-6V11A3,3,0,0,1,3,8ZM9,8H19V6A5,5,0,1,0,9,6ZM3,9a2,2,0,0,0-2,2V26a5,5,0,0,0,5,5H22a5,5,0,0,0,5-5V11a2,2,0,0,0-2-2H20v5.5a.5.5,0,1,1-1,0V9H9v5.5a.5.5,0,0,1-1,0V9Z" transform="translate(0.5 0.5)" fill="#dc3644" stroke="#dc3644" strokeWidth="1" />
              </svg>
              <div className="badge badge-danger badge-position rounded-pill position-absolute badge-position">
                {cartDetails.itemCount}
              </div>
            </div>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fw-600 fs-sm-12 m-0 text-gray-100">
                Your Cart from
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                {cartDetails.name}
              </h4>
              {continueOrdering && 
                <>
                  <span className="fs-18 fw-600 text-gray-100">
                    <i className="fas fa-map-marker-alt fa-md mr-2"></i>{cartDetails.deliveryAddress?.formatted_address}
                  </span>
                  <p onClick={redirectToCartMenu} className="fs-18 fw-600 text-danger m-0 d-flex align-items-center cursor-pointer">
                    Continue Ordering 
                    <i className="fas fa-angle-right ml-2"></i>
                  </p>
                </>
              }
              {/* <span className="cutt-off-badge mt-1"> */}
              {cartDetails.deliveryDate &&
                <span className={`'text-nowrap ' ${dayjs(cartDetails.deliveryDate).isAfter(new Date()) ? 'badge-outline-primary cursor-pointer mt-0 mt-sm-1 d-inline-block' : 'badge-outline-danger cursor-pointer mt-0 mt-sm-1 d-inline-block'}`}>
                  <span data-tip
                    data-for='menu-detail-page-cutoff'
                    // style={{ color: dayjs(currentDeliveryCutOff).isAfter(new Date()) ? '#007bff' : '#dc3545' }}
                  >
                    {i18n?.t('Cutoff ')}
                    {
                      <React.Fragment>
                        {dayjs(cartDetails.deliveryDate).isAfter(new Date()) ?
                          // dayjs(`${dayjs(cartDetails.deliveryDate).subtract(1, 'day').format('YYYY-MM-DD')}${'13:45'}`)
                          //   .toNow(true) : dayjs(cartDetails.deliveryDate).subtract(1, 'day').fromNow()
                          `in ${dayjs(`${dayjs(cartDetails.deliveryDate).subtract(1, 'day').format('YYYY-MM-DD')}${'13:45'}`).toNow(true)}`:
                          `was ${dayjs(cartDetails.deliveryDate).subtract(1, 'day').fromNow()}`
                        }
                      </React.Fragment>
                      // dayjs(cartDetails.deliveryDate).isAfter(new Date()) ?
                      //   dayjs(cartDetails.deliveryDate).toNow(true) : dayjs(cartDetails.deliveryDate).fromNow()
                    }
                  </span>
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                    // <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className='react-tooltip'>
                    //   <span>{getZonedTime(cartDetails.deliveryDate, 'EEE. MMM dd h:mma, zzzz', timeZone)}</span>
                    // </ReactTooltip>
                    <ReactTooltip place='bottom' type='dark' id='menu-detail-page-cutoff' effect='solid' className='react-tooltip'>
                      <span>{cartDetails.deliveryDate ?? ''}</span>
                      {/* <span>{`${dayjs(cartDetails.deliveryDate).subtract(1, 'day').format("ddd")} ${getZonedTime(`${dayjs(dateForUser).subtract(1, 'day').format(' MMM DD 13:45')}`, 'MMM dd h:mma, zzzz', timeZone)}`}</span> */}
                    </ReactTooltip>

                  }
                </span>
              }
              {/* </span> */}
            </div>
          </div>
          <div className="cart-close-btn cursor-pointer" onClick={(() => closeSidebar?.())}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }} className="text-black">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* cart products list */}
        <section className={`cart-summary-items-wrapper px-3 px-sm-4`}>
          <div className="cart-summary-group-item">
            <div className="cart-summary-items">
              <ul className="px-0 list-grpup overflow-auto">
                {cartItems?.map((cart: ItemDataType, index: any) => 
                  <li className="pt-32 cart-item-smallscreen cursor-pointer" key={index}>
                    <div className="product-row d-flex align-items-center justify-content-between">
                      <div className="product-card d-flex">
                        <div className="product-card-img position-relative">
                          <img src={cart?.itemImage ?? '/imgs/SVGs/ItemPlaceholder.svg'} alt="" style={{ width: 56, height: 56 }} className="img-fluid rounded-lg" />
                          {cart?.quantity>1 &&<div 
                            className="badge badge-danger d-flex align-items-center justify-content-center rounded-circle card-badge-position cursor-pointer"  
                            // onClick={() => deleteCartItem(index)}
                            onClick={() => deleteCartItemConfirmation(index)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" className="d-flex align-items-center justify-content-center m-auto mt-1 cart-item-svg-height">
                              <path id="trash-solid" d="M4.225.553,4,1H1A1,1,0,0,0,1,3H13a1,1,0,0,0,0-2H10L9.775.553A1,1,0,0,0,8.881,0H5.119A1,1,0,0,0,4.225.553ZM13,4H1l.663,10.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406Z" fill="#dc3644" />
                            </svg>
                          </div>}
                        </div>
                        <div className="pl-2 pl-sm-3 product-card-info">
                          <h5 className="fs-18 fs-sm-14 m-0 fw-600 text-gray-700">{cart?.itemName}</h5>
                          <p className="fs-18 fs-sm-14 m-0 mt-1 fw-normal text-gray-600">{`$${cart?.itemPriceWithOptions ? cart?.itemPriceWithOptions : cart?.itemPrice}`}</p>
                        </div>
                      </div>
                      <div className="product-card-description">
                        <div className="instant-cart-manager-container d-flex align-items-center">
                          {incDecLoader ? <button className={`${incDecLoader && loadingIndices === index ? 'svg-whileloading-spinner' : 'decrease-product-count'}`} >{cart?.quantity === 1 ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" className="d-flex align-items-center justify-content-center m-auto mt-1 svg-height">
                              <path id="trash-solid" d="M4.225.553,4,1H1A1,1,0,0,0,1,3H13a1,1,0,0,0,0-2H10L9.775.553A1,1,0,0,0,8.881,0H5.119A1,1,0,0,0,4.225.553ZM13,4H1l.663,10.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406Z" fill="#dc3644" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                            </svg>
                          }</button> :
                            <React.Fragment>
                              <button className="decrease-product-count" onClick={(() => cart?.quantity === 1 ? deleteCartItemConfirmation(index) : incrementDecrementItemCount(index, OPERATION.DECREMENT))}>
                                {cart?.quantity === 1 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" className="d-flex align-items-center justify-content-center m-auto mt-1 svg-height">
                                    <path id="trash-solid" d="M4.225.553,4,1H1A1,1,0,0,0,1,3H13a1,1,0,0,0,0-2H10L9.775.553A1,1,0,0,0,8.881,0H5.119A1,1,0,0,0,4.225.553ZM13,4H1l.663,10.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406Z" fill="#dc3644" />
                                  </svg>
                                  :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                  </svg>
                                }
                              </button>
                            </React.Fragment>
                          }       
                          <span className={`instant-cart-manager-quantity fs-18 fw-normal px-3`}>
                            {incDecLoader && loadingIndices === index ? <div className="spinner-onCart-itemQuantity"><ButtonSpinner /></div> : <React.Fragment>{cart.quantity}</React.Fragment>}
                            {/* {incDecLoader? <ButtonSpinner/>:
                            <React.Fragment>{cart.quantity}</React.Fragment>} */}
                          </span>
                          {incDecLoader ? <button className={`${incDecLoader && loadingIndices === index ? 'svg-whileloading-spinner' : 'increase-product-count'}`} >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>
                          }</button> :
                            <React.Fragment>
                              <button className="increase-product-count" onClick={() => incrementDecrementItemCount(index, OPERATION.INCREMENT)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                              </button>
                            </React.Fragment>
                          }      
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                <li className='pt-32'></li>
              </ul>
            </div>
          </div>
        </section>
        {/* Cart product Descriptions */}
        {/* / moved the pricing detail section to Place orders page / */}
        {false && <section className='cart-summary-amounts-wrapper pt-3 pt-sm-4 px-0 px-sm-4 w-100'>
          <Accordion expanded={expanded} onChange={handleCartSummary}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            className="cart-accordian-wrapper"
            // onClick={prepareDeliveryFeePayload(cartItems)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white', backgroundColor: '#6e6e73', borderRadius: '50%' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="py-1 accordian-summary"
            >

              {/* cart-summary-total-amount*/}
              <div
                className="cart-summary-total-amount d-flex align-items-center justify-content-between my-1 w-100"
              // onClick={(() => prepareDeliveryFeePayload(cartItems))}
              > <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Total</h4>
                {incDecLoader ? <Skeleton height={25} width={100} /> :
                  <React.Fragment>
                    <h4 className="fw-600 ml-auto mr-3 mb-0 text-gray-700">{
                      `$${isNaN(cartSummary?.total) ? '0.00' : isNaN(+cartSummary.driverTip) ? (cartSummary.total + defaultTip).toFixed(2) : cartSummary?.total ?? 0}`
                    }</h4>
                  </React.Fragment>
                }
                {/* <h4 className="fw-600 ml-auto mr-3 mb-0 text-gray-700">{
                `$${isNaN(cartSummary?.total) ? '0.00' : isNaN(+cartSummary.driverTip) ? defaultTipTotal :cartSummary?.total ?? 0}`}</h4> */}
              </div>

            </AccordionSummary>

            <AccordionDetails className="px-3 px-sm-4 pt-3 pb-0">
              <div>
                <li className="d-flex align-items-center justify-content-between mb-2">
                  <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">Items Subtotal</p>
                  {/* <p className="fs-18 fw-normal text-gray-600 m-0">{`$${(+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p> */}
                  {incDecLoader ? <Skeleton height={25} width={100} /> :
                    <p className="fs-18 fs-sm-14 fw-normal text-gray-600 m-0">{`$${isNaN(+cartSummary?.itemsSubtotal) ? '0.00' : (+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</p>}
                </li>
                {/* cart-summary-amounts-sales-tex-fee */}
                <li className="cart-summary-amounts-sales-tex-fee d-flex align-items-center justify-content-between mt-1">
                  <p className="cart-summary-details-text">Sales Tax</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p> */}
                  {incDecLoader ? <Skeleton height={25} width={100} /> :
                    <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.salesTax) ? '0.00' : (+cartSummary?.salesTax ?? 0).toFixed(2)}`}</p>}
                </li>
                {/* cart-summary-amounts-delivery-fee */}
                <li className="cart-summary-amounts-delivery-fee d-flex align-items-center justify-content-between">
                  <p className="cart-summary-details-text">Delivery Fee</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p> */}
                  {incDecLoader ? <Skeleton height={25} width={100} /> :
                    <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.deliveryFee) ? '0.00' : (+cartSummary?.deliveryFee ?? 0).toFixed(2)}`}</p>}
                </li>
                {/* cart-summary-card-processing-fee */}
                <li className="cart-summary-card-processing-fee d-flex align-items-center justify-content-between">
                  <p className="cart-summary-details-text">Credit Card Processing Fee</p>
                  {/* <p className="cart-summary-details-text">{`$${(+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p> */}
                  {incDecLoader ? <Skeleton height={25} width={100} /> :
                    <p className="cart-summary-details-text">{`$${isNaN(+cartSummary?.cardProcessingFee) ? '0.00' : (+cartSummary?.cardProcessingFee ?? 0).toFixed(2)}`}</p>}
                </li>
                {/* cart-summary-tip-amount */}
                <li className="cart-summary-tip-amount d-flex align-items-center justify-content-between mt-3" style={{marginBottom:"12px"}}>
                  <p className="cart-summary-details-text">Driver Tip</p>
                  {/* <p className="cart-summary-details-text">10% ($2.5)</p> */}
                  {incDecLoader ? <Skeleton height={25} width={100} /> :
                    <p className="cart-summary-details-text">
                      {/* {`${(cartSummary?.driverTipPercent && (cartSummary?.driverTipPercent >0)) ?
                      cartSummary?.driverTipPercent+'%'
                        : ''} ($${(+cartSummary.driverTip ?? 0).toFixed(2)})
                    `} */}
                      {`${(cartSummary?.driverTipPercent && (!isEmpty(cartSummary) && cartSummary?.driverTipPercent! > 0)) ?
                        cartSummary?.driverTipPercent + '%'
                        : !tipItnput && (tipOtherValue === undefined) ? TipSelection[1].label : ''} ${!isEmpty(cartSummary) && cartSummary?.driverTipPercent && cartSummary?.driverTipPercent! > 0 ? 'is' : ''} $${isNaN(+cartSummary.driverTip) ? (defaultTip && defaultTip.toFixed(2)) : (+cartSummary.driverTip ?? 0).toFixed(2)}
                    `}
                    </p>}
                </li>
                {/* cart-summary-tip-amount-selection */}
                {tipItnput ?
                  <li className="d-flex align-items-center justify-content-between">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text px-3" id="basic-addon1">$</span>
                      </div>
                      <input className="form-control mr-2 input-tip-calculation" type='number' placeholder="Enter tip amount" name="other_tip" value={tipOtherValue} onChange={onTipSelect}/>
                    </div>
                    <button
                      className="btn btn-danger"
                      style={{minWidth:'64px',height:'42px'}}
                      onClick={goToTipSelection}
                    >
                      Ok
                    </button>
                  </li>
                :
                  <div className="cart-summary-tip-amount-selection">
                    <select
                      multiple
                      style={{backgroundColor:'#F8F9FA'}}
                      className="form-control cart-summary-tip-selection p-0"
                      id="exampleFormControlSelect2"
                      onChange={onTipSelect}
                    >
                      {/* {TipSelection?.map((opt, index) => <option key={index} value={opt.value} selected={cartSummary?.driverTipPercent === opt.value || (cartSummary?.driverTipPercent?.toString() === '' && opt.value === 'other')}>{opt.label}</option>)} */}
                      {TipSelection?.map((opt, index) => <option key={index} value={opt.value} selected={(tipOtherValue  ? index === 4 :  cartSummary.driverTipPercent === undefined && index === 1) || (cartSummary?.driverTipPercent === opt.value) || (cartSummary?.driverTipPercent?.toString() === '' && opt.value === 'other')}>{opt.label}</option>)}
                    </select>
                  </div>
                }
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Cart checkout button */}
        </section>
        }
        <footer className="px-0 mt-2 px-3 px-sm-0 bg-white cart-footer-alingment">
          <div
            className="cart-summary-total-amount d-flex align-items-center justify-content-betweenmy-1 w-100 my-2 px-3 px-sm-4"
          >
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Items Subtotal</h4>
            <h4 className="fw-600 fs-sm-16 ml-auto mr-3 mb-0 text-gray-700">{`$${isNaN(+cartSummary?.itemsSubtotal) ? '0.00' : (+cartSummary?.itemsSubtotal ?? 0).toFixed(2)}`}</h4>
          </div>
          <hr style={{width:'100%',marginTop:'2rem',marginBottom:'2rem'}} />
          <div className="px-3 px-sm-4">
            {(cartSummary?.total && cartSummary.total <= 0) ? <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" disabled >Checkout</button>
              :
              <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" onClick={() => cartNavigationHandler('checkout')}>Checkout</button>
            }
          </div>
        </footer>
      </section>
      }

      {/* Second page Signup page */}
      {cartState === 'signUp' && <section className="signup-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => setCartState('cart')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644"/>
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                To continue please
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                Signup
              </h4>
            </div>
          </div>
          <div className="cart-close-btn cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* Signup form section */}
        <section className="signup-form-section mt-3 px-3">
          {/* Signup form main section  */}
          {/* <h4 className="m-0">signup form here</h4> */}
          <SignUpWidget
            isCartSignUp={true}
            cartDisplayHandling={cartDisplayHandling}
            // cartOrderPayload={orderData}
            isOpenModal={false}
          />
        </section>
        {/* Cart signup page footer */}
        {/* <footer className="mt-3 px-4">
          <button className="btn btn-block btn-danger py-3 fs-18 fw-600">Sign Up</button>
          <p className="fs-18 fw-normal text-center text-gray-200 m-0 mt-3">Already have an account ? <span className="text-danger fw-600 cursor-pointer" onClick={() => setCartState('login')}>Log In</span></p>
        </footer> */}
      </section>}
      {/* Cart Signup page End here */}

      {/* login page third page*/}
      {cartState === 'login' && <section className="login-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => setCartState('signUp')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644"/>
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                To continue please
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                Log In
              </h4>
            </div>
          </div>
          <div className="cart-close-btn cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* login form section */}
        <section className="login-form-section mt-3 w-100 px-4">
          {/* login form main section  */}
          {/* <h4 className="m-0">login form here</h4> */}
          {/* <CartLoginWrapper
          isCartLogin = {true}
          cartDisplayHandling={cartDisplayHandling}
          // cartOrderPayload={orderData}
          loginModal={false}
          /> */}
          <Login
            isCartLogin = {true}
            cartDisplayHandling={cartDisplayHandling}
            // cartOrderPayload={orderData}
            isOpenModal={false}
          />
        </section>
        {/* Cart login page footer */}
        {/* <footer className="mt-3 px-4">
          <button className="btn btn-block btn-danger py-3 fs-18 fw-600"  onClick={() => setCartState('confirm')}>Next Step</button>
          <p className="fs-18 fw-normal text-center text-gray-200 m-0 mt-3"><span className="text-danger fw-600 cursor-pointer">proceed</span></p>
        </footer> */}
      </section>}
      {/* Cart Login page End here */}


      {/* Place order page*/}
      {cartState === 'confirm' && <section className="order-page-wrapper">
        <header className="d-flex justify-content-between border-bottom p-4 cart-header">
          <div className="d-flex aling-items-center cart-summery">
            <button className="d-flex align-items-center justify-content-center rounded-circle border-0 cart-logo" onClick={() => cartNavigationHandler('placeOrder')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28.67" height="24.572" viewBox="0 0 28.67 24.572">
                <path id="arrow-right-regular" d="M11.738,64.437.476,75.187a1.537,1.537,0,0,0,0,2.224l11.262,10.75a1.536,1.536,0,0,0,2.12-2.224l-8.491-8.1H27.134a1.536,1.536,0,1,0,0-3.072H5.371l8.491-8.1a1.539,1.539,0,0,0-2.124-2.227Z" transform="translate(0 -64.013)" fill="#dc3644" />
              </svg>
            </button>
            <div className="cart-summary-header-title pl-2 pl-sm-3">
              <p className="fs-16 fs-sm-12 fw-600 m-0 text-gray-100">
                Confirmation of
              </p>
              <h4 className="m-0 fw-600 fs-sm-16">
                {cartDetails.name}
              </h4>
            </div>
          </div>
          <div className="cart-close-btn cursor-pointer" onClick={(() => closeSidebar?.())}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 32, width: 28 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </header>
        {/* place order page Content */}
        <section className="place-order-section mt-3 px-3 px-sm-4 overflow-auto">
          <div className="cart-order-delivery-info">
            <ul className="px-0 list-grpup">
              <li className="d-flex align-items-center justify-content-start">
                <div className="cart-order-location d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                </div>
                <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{cart_at_login?.formatted_address}</h6>
              </li>
              <li className="d-flex align-items-center justify-content-start pt-2">
                <div className="cart-order-location d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: 24, width: 24 }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                  </svg>
                </div>
                <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0 pl-2 pl-sm-3">{dayjs(cartDetails.cuttoff).format('ddd, MMM DD')} at {dayjs(cartDetails.cuttoff)?.format('hh:mm A')}</h6>
              </li>
            </ul>
          </div>
          {/*Order page Deliver info form */}
          <hr style={{width:'50%'}}/>
          <div className="pt-0 pt-sm-0">
            <h6 className="fs-18 fs-sm-14 fw-600 text-gray-600 m-0">Delivery Details</h6>
            <form action="" className="p-3">
              <div>
                <label htmlFor="name" className="">Name of Person</label>
                <input type="text" className="form-control" name="name" value={cartDeliveryDetails.name} onChange={(e: any) => cartDeliveryDetailsHandler(e)} id="" />
              </div>
              <div className="pt-2">
                <PhoneInput
                  specialLabel='Phone Number'
                  country={'us'}
                  onlyCountries={['us']}
                  masks={{ us: '...-...-....' }}
                  disableDropdown={true}
                  placeholder={'XXX-XXX-XXXX'}
                  // countryCodeEditable={false}
                  value={cartDeliveryDetails.phoneNumber}
                  onChange={phone => setCartDeliveryDetails({ ...cartDeliveryDetails, phoneNumber: phone })}
                  disableCountryCode={true}
                  isValid={(value: any, country: any) => {
                    if (isEmpty(value)) {
                      setPhoneErr('empty')
                      // invalidphone()
                      return false
                    }
                    else if (!value.match(/^[0-9]{10}$/)) {
                      // invalidphone()
                      // invalidphone(value)
                      setPhoneErr('invalid')
                      return false
                    } else {
                      // cartDeliveryDetailsPhoneHandler(value)
                      setPhoneErr('valid')
                      // handle && handle(value)
                      return true
                    }
                  }}
                />
                {<small className='invalid-feedback-password'>
                  {errorStatefun()}
                </small>
                }
              </div>
              {/* <div>
                <label htmlFor="">Phone Number</label>
                <input type="text" className="form-control" name="" id="" />
              </div> */}
              <div>
                <label htmlFor="deliveryInstructions" className="pt-2">Delivery Instructions</label>
                <textarea name="deliveryInstructions" className="form-control" value={cartDeliveryDetails.deliveryInstructions} id="" onChange={(e: any) => cartDeliveryDetailsHandler(e)} cols={20} rows={3} />
              </div>
              {/* <h3>your input form and payment card is here</h3> */}
            </form>
            <div className="mt-2 p-2 cart-cc">
              <div>
                <StripeLayout
                  parentRef={this}
                  fromCart={true}
                  isLoading={false}
                  cartStripeTokenHandler={cartStripeTokenHandler}
                  prevPaymentDetails={cardDetails}
                />
              </div>
            </div>
          </div>
          <dl className="cart-summary-total-amount d-flex align-items-center justify-content-between px-4 mb-3 mt-4">
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">Total</h4>
            {/* <h4 className="fw-600 m-0 text-gray-700">{`$${(+cartSummary?.total ?? 0).toFixed(2)}`}</h4> */}
            <h4 className="fw-600 fs-sm-16 m-0 text-gray-700">{`$${isNaN(cartSummary?.total) ? '0.00' :(+cartSummary?.total ?? 0).toFixed(2)}`}</h4>
          </dl>
          {/* Cart Place order page footer */}
          <footer className="mt-3 px-4 px-sm-0">
            <button className="btn btn-block btn-danger py-3 fs-18 fw-600 checkout-btn" onClick={placeOrder}>Place Order</button>
          </footer>
        </section>
      </section>}
      {/* Cart Login page End here */}
      <Modal
        isOpen={checkoutPopUp || deletePopUP}
        onRequestClose={closeModal}
        style={deletePopUP ? deletePopUpStyles : customStyles}
      >
        {/* // Delete Modal */}
        {deletePopUP ?
          <div className='order-modal'>
            <div className='slide'>
              <div className='modal-content cursor-default p-32'>
                <div className="cart-delete-modal cart-delete-modal-body mx-auto">
                    <div className="mx-auto d-flex aling-items-center justify-content-center">
                      <div className="trash-bin-wrapper">
                        <i className="far fa-trash-alt"></i>
                      </div>
                    </div>
                    <h5 className="mb-0 fw-600 text-gray-primary text-center pt-3 ">Delete Item</h5>
                    <p className="fs-18 m-0 text-center" style={{color:'#4D5B60'}}>Are you sure you want to delete this Item?</p>
                    <div className="pt-4 d-flex justify-content-center align-items-center">
                      <button
                        onClick={() => setDeletePopUP(false)}
                        className="btn rounded-pill cart-item-keep-btn"
                      >
                        Keep It
                      </button>
                      <button
                        onClick={() => deleteCartItem(deleteItemIndex as number)}
                        className='btn rounded-pill cart-item-delete ml-3'
                      >
                        Yes, Delete
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        :
          // Order Confirm Modal
          <div className='order-modal'>
            <div className='slide'>
              <div className='modal-content cursor-default'>
                <div className='modal-header'>
                  <h3
                    id='modal-title'
                    className='modal-title'
                    style={{ fontWeight: 600, fontSize: 22 }}
                  >
                    Order Received
                  </h3>
                  <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div>
                  <div id='order-status-update-popup' className='modal-body py-2'>
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
                      <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                      <p style={{ fontSize: 20, color: '#646464' }} className='text-center'>
                        You’re All Set!
                        <br />
                        Your order has been received!
                      </p>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      onClick={closeModal}
                      className='btn accept-order-btn border-0'
                      style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px' }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Modal>
    </>
  );
}
export type CartRef = React.ElementRef<typeof CartDetails>;
export default CartDetails