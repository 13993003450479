import { GET_PROFILE_DATA, SET_PROFILE_DATA, SET_BILLING_DATA, RESET_STATE } from '../Actions/types';
import { ProfileData } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: ProfileData = {
  profile: null,
  billing: null
}

const profileReducer = (state: ProfileData = initialState, action: any): ProfileData => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
      }
    case SET_PROFILE_DATA:
      return {
        ...state,
        profile: action.payload,
      }
    case SET_BILLING_DATA:
      return {
        ...state,
        billing: action.payload,
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default profileReducer;