import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { upgradeToEnterprize } from '../../Actions/marketplaceActions';
import Spinner from '../Spinners/Spinner';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 55%)',
    padding: '0',
    borderRadius:'16px',
  }
};

type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  confirmAction: () => void,
}

const EnterprizePlanViewerPopUp: FC<props> = ({ modalIsOpen, closeModal, confirmAction }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: any = useDispatch();

  const convertToEnterprize = () => {
    setIsLoading(true) //dont need to false the state because the popup close on response when confirmAction is called
    dispatch(upgradeToEnterprize(confirmAction))
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={closeModal}
      // contentLabel='Delivery Address'
    >
      <div className='new-modal-header '>
            <button
              type='button'
              onClick={closeModal}
              className='close pt-2 pr-3'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>×</span>
            </button>
          </div>
          <div className="table-responsive table-scroll">
            <table className="table table-borderless enterprise-table mb-0" id="main-table">
              <thead>
                <tr>
                  <th scope="col" className='pt-2 pb-1'>
                    <h4 className='m-0 fw-600 mobile-heading'>Features</h4>
                  </th>
                  <th scope="col" className='pt-2 pb-1'>
                    <h4 className='m-0 fw-600 mobile-heading'>Basic</h4>
                  </th>
                  <th scope="col" className='pt-2 pb-1'>
                    <h4 className='m-0 fw-600 d-flex align-items-center mobile-heading'>Enterprise <img src="/imgs/crown.svg" alt="Crown" className='ml-1 ml-sm-2  mobile-svg'/></h4>
                  </th>
                </tr>
                <tr>
                  <td colSpan={3} className='p-1'></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p className='enterprise-features-list-text'>Multi-Restaurants Ordering / Combos</p></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Alerts & Reminders</p></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Reports</p></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Driver / Delivery Tracking</p></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Pay with Credit Card</p></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Pay with Invoicing</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Curated Combos Based on Employee Makeup</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Food from all restaurants delivered at same time</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Subsidized Meal Plans</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Chowmill Guarantee - Meal Replacement In case of Emergencies</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Dedicated Support with Immediate Assistance</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Group Delivery Setup (Racks /Bin)</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>User Management</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Meal Plan Scheduling</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Guest Ordering</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
                <tr>
                  <td><p className='enterprise-features-list-text'>Dedicated Drivers</p></td>
                  <td><i className="fas fa-check-circle text-gray-800"></i></td>
                  <td><i className="fas fa-check-circle text-success"></i></td>
                </tr>
              </tbody>
              <tfoot>
                {/* <tr>
                  <td style={{padding:'14px 0 18px 40px'}}><h4 className='m-0 fw-600 mobile-heading'>Service Fee Pricing</h4></td>
                  <td><h4 className='m-0 text-gray-100 mobile-heading'>Free</h4></td>
                  <td className='d-flex align-items-center'> <h4 className='text-black m-0 fw-600 mobile-heading'>10%</h4><h5 className='m-0 text-gray-100 mx-3 mobile-heading'><del>17%</del></h5> </td>
                </tr> */}
                <tr>
                  <td colSpan={3} className="p-0 m-0">
                    {isLoading ?
                      <button className='btn btn-primary btn-lg rounded-pill d-flex ml-auto mr-3 mb-3 enterprise-request-btn mt-3 btn-request-upgrade'disabled><Spinner /></button> :
                      <button className='btn btn-primary btn-lg rounded-pill d-flex ml-auto mr-3 mb-3 enterprise-request-btn mt-3' onClick={convertToEnterprize}>
                        Request Upgrade</button>
                    }
                  </td>
                </tr>
              </tfoot>
        </table>
        
      </div>
    </Modal>
  )
}

export default EnterprizePlanViewerPopUp;
