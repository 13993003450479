import i18n from "i18next";

export const orderPopUpTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Best Sellar": "Best Sellar",
      "Calories": "Calories",
      "Extra Instructions": "Extra Instructions",
      "Required": "Required",
      "Any Special Requests?": "Any Special Requests?",
      "Allergies, spice levels, etc.": "Allergies, spice levels, etc.",
      "Add to Order": "Add to Order",
      "Restaurants & Categories": "Restaurants & Categories",
      "Dietary Restrictions": "Dietary Restrictions",
      "Ingredients": "Ingredients",

      "Okay": "Okay",
      "has been received!": "has been received!",
      "from": "from",
      "Your order for": "Your order for",
      "Order Received": "Order Received",
      "Apologies, the cutoff time for this delivery has passed.": "Apologies, the cutoff time for this delivery has passed.",
      "Thank You!": "Thank You!",
      "Confirm": "Confirm"
    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Best Sellar": "بہترین بیچنے والے",
      "Calories": "کیلوری",
      "Extra Instructions": "اضافی ہدایات",
      "Required": "ضروری ہے",
      "Any Special Requests?": "کوئی خصوصی درخواست؟",
      "Allergies, spice levels, etc.": "الرجی ، مسالہ کی سطح وغیرہ۔",
      "Add to Order": "آرڈر میں شامل کریں۔",
      "Restaurants & Categories": "ریستوراں اور زمرے",
      "Dietary Restrictions": "غذائی پابندیاں",
      "Ingredients": "اجزاء",

      "Okay": "ٹھیک ہے",
      "has been received!": "وصول ہو چکا ہے!",
      "from": "",
      "Your order for": "آپ کے لئے آرڈر",
      "Order Received": "آرڈر موصول ھو گیا",
      "Apologies, the cutoff time for this delivery has passed.": "معذرت ، اس کی ترسیل کے لئے کٹ آف کا وقت گزر گیا۔",
      "Thank You!": "شکریہ!",
      "Confirm": "تصدیق کریں"
    });
};