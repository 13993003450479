import React, { FC, useState } from "react";
import isEmpty from "../../../Utilities/is_empty";
import { MarketplaceRestaurant } from "../../../Interfaces/PayloadsAndResponses/Marketplace";
import { useDispatch } from "react-redux";
import { addToFavouritesRestaurants, removeFavouritesRestaurants } from "../../../Actions/marketplaceActions";
import { getloggedInUser } from "../../../Utilities/getloggedInUser";
import SignUpWidget from '../../../Pages/Signup/SignUpWidget';
import Login from "../../Login/Login";

type MarketPlaceRestaurantCardProps = {
  restaurants: MarketplaceRestaurant;
  index?: number;
  screenWidth?: number | undefined;
  selectRestaurant: (restaurants: MarketplaceRestaurant, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fromPublicMarketPlace?: boolean;
  marketplaceRestaurant?: boolean
}
interface ModalState {
  type: 'login' | 'signup' | null;
  isOpen: boolean;
}

const MarketplaceRestaurantCard: FC<MarketPlaceRestaurantCardProps> = ({ restaurants, index, screenWidth, selectRestaurant, fromPublicMarketPlace, marketplaceRestaurant }) => {
  const [isFavorite, setIsFavorite] = useState<boolean | undefined>(restaurants ? restaurants?.user_favorite : false);
  const [defaultFavoriteHover, setDefaultFavoriteHover] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({ type: null, isOpen: false });
  const dispatch: any = useDispatch();
  const { loggedInUser } = getloggedInUser();
  const handleFavourite = () => {
    let user_favorite = restaurants ? restaurants?.user_favorite : false;
    if (!isEmpty(loggedInUser) && user_favorite) {
      dispatch(removeFavouritesRestaurants(restaurants?.id))
    } else if (!isEmpty(loggedInUser)) {
      dispatch(addToFavouritesRestaurants(restaurants?.id))
    } else {
      setIsFavorite(false);
      setModalState({ type: 'login', isOpen: true });
    }
  }

  const signupLoginRedirect = (page: string) => {
    if (page === 'login') {
      setModalState({ type: 'signup', isOpen: true })
    } else if (page === 'signup') {
      setModalState({ type: 'login', isOpen: true })
    }
  }

  return (
    <React.Fragment>
      <div style={{ 'position': 'relative' }} onMouseEnter={() => setDefaultFavoriteHover(true)} onMouseLeave={() => setDefaultFavoriteHover(false)}>
        <div onClick={e => selectRestaurant(restaurants, e)}>
          <div className={`mb-1 position-relative cursor-pointer ${!marketplaceRestaurant && 'combo-restaurant-slider-card'}}`}>
            <div className={`rounded-circle d-flex heart-emoji-on-individual-restaurant ${isFavorite ? '' : defaultFavoriteHover ? 'favourite-restaurant' : 'notfavourite-restaurant'} `} onClick={(e) => { e.stopPropagation(); handleFavourite(); !fromPublicMarketPlace && setIsFavorite(!isFavorite) }} >
              {isFavorite ?
                <i className='fas fa-heart selected-favourites-icon'></i>
                :
                defaultFavoriteHover &&
                <i className='far fa-heart'></i>
              }
            </div>
            <div
              style=
              {(isEmpty(restaurants.image)) ? { backgroundImage: 'url("/imgs/SVGs/ComboPlaceholder.svg")' } : { backgroundImage: 'url(' + restaurants.image + ')' }}
              className='individual-restaurant-card-img-marketplace'
            />
            <div className='card-body individual-restaurant-card-body py-3 px-0 pb-0 d-flex bg-white'>
              <div className="restaurant-card-logo-wrapper">
                {restaurants.logo_url?.url ?
                  <div className='mr-1 mr-sm-2 restaurant-logo' style={{ backgroundImage: `url(${restaurants.logo_url?.url})`, width: 40, height: 40 }}></div>
                  :
                  <div id='autoThumbnailParent'>
                    <div id='autoThumbnailChild'>
                      <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{restaurants?.name?.charAt(0)} </span>
                    </div>
                  </div>
                }
              </div>
              <div className="text-overflow-handling">
                <h6 className='individual-restaurant-card-title mb-2'>{restaurants?.name}</h6>
                <div className="d-flex align-items-center">
                  {+restaurants?.average_rating === 0.0 ? null : <><div className='d-inline float-right' >
                    <div className='d-flex'>
                      <i className="fas fa-star marketplace-combo pr-1 fs-18" aria-hidden="true"> </i>
                      <p className='fs-18 fw-normal m-0 text-gray-100 pr-2 pr-sm-2 rating-number' style={{ fontSize: '0.95rem' }}>{restaurants?.average_rating}</p>
                    </div>
                  </div>
                    <span className='d-flex justitfy-content-center align-items-center mx-1'>
                      {(restaurants?.average_rating > 0 && restaurants?.distance !== "Google Return ZERO_RESULTS") && (
                        <i className="fas fa-circle small-dot" style={{ color: "#6E6E73" }}></i>
                      )}
                    </span>
                  </>
                  }
                  {restaurants?.distance && restaurants?.distance !== "Google Return ZERO_RESULTS" && <div className='d-flex'>
                    <div className='fs-14 fw-normal badge badge-light text-gray-500 combo-badge'>{restaurants?.distance} mi</div>
                  </div>
                  }
                  <div className='card-text mb-0' style={{ 'height': 'auto' }}>
                    <div className='d-flex justitfy-content-center align-items-center mx-1'>
                      {((restaurants?.distance !== "Google Return ZERO_RESULTS" || restaurants?.average_rating > 0) && restaurants?.cuisines?.length > 0) && (
                        <i className="fas fa-circle small-dot" style={{ color: "#6E6E73" }}></i>
                      )}
                      {restaurants?.cuisines && restaurants?.cuisines?.split(',')?.slice(0, 1)?.map((cuisine, index) => (
                        <div key={index} className='d-flex align-items-center'>
                          <span className='mr-2 individual-combo-badge ml-2'>{cuisine}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {fromPublicMarketPlace &&
        <>
          <SignUpWidget
            isMarketPlaceSignUp
            signupLoginRedirect={signupLoginRedirect}
            closeModal={() => setModalState({ type: null, isOpen: false })}
            isOpenModal={modalState.type === 'signup' ? modalState.isOpen : false}
          />
          <Login
            isMarketPlaceLogin
            signupLoginRedirect={signupLoginRedirect}
            closeModal={() => setModalState({ type: null, isOpen: false })}
            isOpenModal={modalState.type === 'login' ? modalState.isOpen : false}
          />
        </>}
    </React.Fragment>

  )
}

export default MarketplaceRestaurantCard;