import { GET_CUISINE, RESET_STATE } from '../Actions/types';
import { Cuisines } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Cuisines = {
  cuisines: null
}

const cuisineReducer =  (state: Cuisines = initialState, action: any): Cuisines => {
  switch (action.type) {
    case GET_CUISINE:
      return {
        ...state,
        cuisines: action.payload,
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default cuisineReducer;