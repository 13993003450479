// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ReactNotifications } from 'react-notifications-component'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const root = createRoot(document.getElementById('root')!);
// base URL for envirnoment
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

console.log('Environment', process.env.REACT_APP_ENVIRONMENT)

function noop() { }
if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}
noop();

root.render(
  <I18nextProvider i18n={i18n}>
    <ReactNotifications />
    <App />
  </I18nextProvider>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
