import axios from 'axios';
import {
  GET_COMPANY_ADDRESSES, GET_CUSTOM_FIELDS, GET_COMPANY_FOR_ORDER_PURPOSE, GET_CHILD_COMPANIES_LIST,
  GET_COMPANY_ADMINS_LIST, GET_COMPANY_LOCATIONS, ADD_COMPANY_ADMIN, UPDATE_COMPANY_ADMIN,
  DELETE_COMPANY_LOCATION, DELETE_COMPANY_ADMIN, ADD_COMPANY_LOCATION, UPDATE_MEAL_BUDGET,
  COMPANY_REDUCER_LOADING, GET_ADDRESS_DATA, GET_SELECTED_ADDRESS_ID
} from './types';
import { getError } from './errorActions';
import { startButtonLoading, stopButtonLoading } from './loadingActions';
import { showToastNotification } from '../Utilities/showToastNotification';
import { AddNewDepartmentPayload, AddNewLocationPayload, DeleteCompanyLocationPayload, GetTimeZoneByLocation } from '../Interfaces/PayloadsAndResponses/Company';
import { UpdatedMealBudgetPayload } from '../Pages/AccountDetail/Modals/MealRangeModal';
import { AddNewCompanyAdminPayload, EditCompanyAdminPayload, DeleteCompanyAdminPayload } from '../Interfaces/PayloadsAndResponses/Company';
import { AppThunkAction } from '../store';
import { History } from 'history';
import { getloggedInUser } from '../Utilities/getloggedInUser';

export const getCompanyAddresses = (): AppThunkAction => dispatch => {
  localStorage.removeItem?.('address');
  localStorage?.removeItem?.('address-placeId');
  localStorage?.removeItem?.('lastLocalStorage');
  axios
    .get('/companies/addresses',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_COMPANY_ADDRESSES,
        payload: res.data
      })

    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getCompanyForOrderPurpose = (token = '', meetingSlug?: string): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get(token ? `/company?token=${token}` : `/company${meetingSlug ? `?slug=${meetingSlug}` : ``}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      let company = res.data.company;
      company.customer = res.data.customer;
      company.display_intercom = res.data.display_intercom;
      dispatch({
        type: GET_COMPANY_FOR_ORDER_PURPOSE,
        payload: res.data
      })
      localStorage.setItem('company', JSON.stringify(company));
    })
    .catch(err => {
      dispatch(getError(err))
      // redirectToLogin && redirectToLogin();
    }
    );
};

export const getCustomFields = (): AppThunkAction => dispatch => {
  axios
    .get('/companies/fields',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_CUSTOM_FIELDS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};


export const getChildCompanies = (): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get('/childs',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_CHILD_COMPANIES_LIST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getCompanyAdmins = (companyId: number): AppThunkAction => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/childs/${companyId}/company_admins`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_COMPANY_ADMINS_LIST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getCompanyLocations = (companyId: number): AppThunkAction => dispatch => {
  // dispatch(setLoading());
  axios
    .get(`/childs/${companyId}/company_locations`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_COMPANY_LOCATIONS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getTimeZoneByLocation = async (lat: number, long: number) => {
  const location = `${lat},${long}`;
  const timeStamp = Math.round(new Date().getTime() / 1000);
  try {
    const resp = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timeStamp}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`)
    return resp.data as GetTimeZoneByLocation;
  }
  catch (err) {
    console.error(err);
  }
};

export const addNewDepartment = (newDepartmentData: AddNewDepartmentPayload, modalClose: () => void, history: History): AppThunkAction => dispatch => {
  dispatch(startButtonLoading());
  axios
    .post('/childs', newDepartmentData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch(getChildCompanies());
      history.push(`/account/${res?.data?.id}`);
      modalClose();
      dispatch(stopButtonLoading());
      showToastNotification('Department has been added successfully.', 'Success!');
    })
    .catch(err => {
      dispatch(getError(err));
      dispatch(stopButtonLoading());
    });
};

export const addNewCompanyAdmin = (id: number, newAdminData: AddNewCompanyAdminPayload, modalClose: () => void): AppThunkAction => dispatch => {
  dispatch(startButtonLoading());
  axios
    .put(`/childs/${id}`, newAdminData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: ADD_COMPANY_ADMIN,
        payload: res.data
      })
      modalClose();
      dispatch(stopButtonLoading());
      dispatch(getCompanyAdmins(id));
      showToastNotification('Department admin has been added successfully.', 'Success!');
    })
    .catch(err => {
      dispatch(getError(err));
      showToastNotification('A user with this email address already exists.', 'Duplicate Email Found!', true);
      dispatch(stopButtonLoading());
    });
};

export const addNewLocation = (companyId: number, newLocationData: AddNewLocationPayload, modalClose: () => void): AppThunkAction => dispatch => {
  dispatch(startButtonLoading());
  axios
    .put(`/childs/${companyId}`, newLocationData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      const address = 'true'
      localStorage.setItem("newaddress", JSON.stringify(address));
      dispatch({
        type: ADD_COMPANY_LOCATION,
        payload: res.data
      })
      modalClose();
      dispatch(getCompanyLocations(companyId));
      dispatch(getCompanyAddresses());
      const selfsignUP = getloggedInUser()?.loggedInUserCompany?.self_signup
      if (selfsignUP === true) {
        showToastNotification('Location has been added successfully.', 'Success!');
      } else {
        showToastNotification('Department location has been added successfully.', 'Success!');
      }
      dispatch(stopButtonLoading());
    })
    .catch(err => {
      dispatch(getError(err));
      dispatch(stopButtonLoading());
    });
};

export const addNewLocationData = (companyId: number, newLocationData: AddNewLocationPayload, modalClose?: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/childs/${companyId}`, newLocationData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_ADDRESS_DATA,
        payload: res?.data?.address
      })
      dispatch({
        type: GET_SELECTED_ADDRESS_ID,
        payload: res?.data?.address?.id
      })
      modalClose?.();
      dispatch(getCompanyLocations(companyId));
      dispatch(getCompanyAddresses());
    })
    .catch(err => {
      dispatch(getError(err));
    });
};

export const editMealBudget = (id: number, updatedMealBudget: UpdatedMealBudgetPayload, modalClose: () => void): AppThunkAction => dispatch => {
  dispatch(startButtonLoading());
  axios
    .put(`/childs/${id}`, updatedMealBudget,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: UPDATE_MEAL_BUDGET,
        payload: res.data
      })
      dispatch(getChildCompanies());
      modalClose();
      dispatch(stopButtonLoading());
      showToastNotification('Meal budget updated successfully.', 'Success!');
    })
    .catch(err => {
      dispatch(getError(err));
      dispatch(stopButtonLoading());
    });
};

export const editCompanyAdmin = (companyId: number, updatedAdminData: EditCompanyAdminPayload, modalClose: () => void): AppThunkAction => dispatch => {
  dispatch(startButtonLoading());
  axios
    .put(`/childs/update_user`, updatedAdminData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: UPDATE_COMPANY_ADMIN,
        payload: res.data
      })
      modalClose();
      showToastNotification('Department admin upadted successfully.', 'Success!');
      dispatch(getCompanyAdmins(companyId));
      dispatch(stopButtonLoading());
    })
    .catch(err => {
      dispatch(getError(err));
      dispatch(stopButtonLoading());
    });
};

export const deleteCompanyLocation = (companyId: number, updatedData: DeleteCompanyLocationPayload, locationDeletedHandler: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/childs/${companyId}`, updatedData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(() => {
      dispatch({
        type: DELETE_COMPANY_LOCATION,
        payload: updatedData?.company?.addresses_active_attributes.id
      })
      locationDeletedHandler && locationDeletedHandler()
      showToastNotification('Department location deleted successfully.', 'Success!');
    })
    .catch(err => {
      if (err?.response?.status === 422) {
        showToastNotification(err?.response?.data?.message?.['addresses_active.address_line'][0], 'Oops', true)
        locationDeletedHandler && locationDeletedHandler()
      } else {
        dispatch(getError(err));
      }
    }
    );
};

export const deleteCompanyAdmin = (updatedData: DeleteCompanyAdminPayload): AppThunkAction => dispatch => {
  axios
    .put(`/childs/update_user`, updatedData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(() => {
      dispatch({
        type: DELETE_COMPANY_ADMIN,
        payload: updatedData?.user?.id
      })
      showToastNotification('Department admin deleted successfully.', 'Success!');
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

// Status Loading
export const setLoading = () => {
  return {
    type: COMPANY_REDUCER_LOADING
  };
}

export const getAddressStringByLatLong = async (lat: number, long: number, callToSyncData?: () => void) => {
  const location = `${lat},${long}`;
  // const timeStamp = Math.round(new Date().getTime() / 1000);
  try {
    const resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`)

    const PlaceIdData = {
      placeId: resp?.data?.results?.[0]?.place_id
    }
    localStorage.setItem("address-placeId", JSON.stringify(PlaceIdData));
    callToSyncData?.()
    // window.location.reload()
    // window.location.replace(`/home/9-23-2023/${resp?.data?.[0]?.place_id}`)
    return resp.data as any;
  }
  catch (err) {
    console.error(err);
  }
};
// https://maps.googleapis.com/maps/api/geocode/json?address=<ADDRESS_STRING>&key=<YOUR_API_KEY>

export const getAddressStringData = async (addressString: string) => {
  // const location = `${lat},${long}`;
  // const timeStamp = Math.round(new Date().getTime() / 1000);
  try {
    const resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`)
    return resp.data as any;
  }
  catch (err) {
    console.error(err);
  }
};