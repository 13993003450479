import axios from 'axios';
import { SEND_ORDER_DETAILS_EMAIL } from './types';
import { getError } from './errorActions';
import { AppThunkAction } from '../store';

// export const sendShowOrderEmail = (id: any): AppThunkAction => dispatch => {
//   axios
//     .get(`/orders/${id}/email`,
//       {
//         headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       })
//     .then(res =>
//       dispatch({
//         type: SEND_SHOW_ORDER_EMAIL,
//         payload: res
//       })
//     )
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

export const sendOrderDetailsEmail = (runningmenuSlug: string): AppThunkAction => dispatch => {
  axios
    .get(`/schedules/${runningmenuSlug}/notify_users`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: SEND_ORDER_DETAILS_EMAIL,
      payload: res
    }))
    .catch(err =>
      dispatch(getError(err))
    );
};