import { RESET_STATE, SEND_ORDER_DETAILS_EMAIL } from '../Actions/types';
import { EmailsDetails } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: EmailsDetails = {
  sendStatus: null
}

const emailReducer = (state: EmailsDetails = initialState, action: any): EmailsDetails => {
  switch (action.type) {
    case SEND_ORDER_DETAILS_EMAIL:
      return {
        ...state,
        sendStatus: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default emailReducer