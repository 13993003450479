import { RESET_STATE, SAVE_CART_ORDER_DATA_FOR_CONFIRMATION } from "../Actions/types";
import { CartDetails, DeliveryFeePayload, ItemDataType } from "../Interfaces/PayloadsAndResponses/Cart";
import { CartOrderConfirmation } from "../Interfaces/ReduxInterface/ReduxRootState";

const initialState: CartOrderConfirmation = {
  items: {} as ItemDataType,
  details: {} as CartDetails,
  deliveryFeePayload: {} as DeliveryFeePayload,
  address_ids: [],
  address_id: ''
  // cart_at_login: {} as CartAtLogin,
};

const cartPendingConfirmationReducer = (state: CartOrderConfirmation = initialState, action: any): CartOrderConfirmation => {
  switch (action.type) {
    case SAVE_CART_ORDER_DATA_FOR_CONFIRMATION:
      return {
        ...state,
        items: action.payload.requestItemData,
        details: action.payload.requestCartData,
        deliveryFeePayload: action.payload.requestDeliveryFee,
        address_ids: action.payload.cartAddressIds
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default cartPendingConfirmationReducer;
