import { useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import dayjs from "dayjs";

interface CalenderProps {
  meetingsWithOrders: Date[];
  meetingsWithNoOrders: Date[];
  daySelectorHandler: (date: Date) => void;
  currentDeliveryDateParam: string;
}

const Calendar: React.FC<CalenderProps> = ({ meetingsWithOrders, meetingsWithNoOrders, daySelectorHandler, currentDeliveryDateParam }) => {
  // Converted the Date format coming from parent component causing problems on safari
  const currentDateForDelivery = currentDeliveryDateParam && dayjs(currentDeliveryDateParam, 'M-D-YYYY')?.format('YYYY-MM-DD');
  const [currentMonth, setCurrentMonth] = useState(new Date(currentDateForDelivery));

  // This date pattern is not Working correctly for Safari so using Safari compatible date pattern
  // const selectedDate = new Date(currentDateForDelivery ?? '');
  const selectedDate = dayjs(currentDateForDelivery).toDate();


  const changeWeekHandle = (btnType: 'prev' | 'next') => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      // setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      // const presentYear = new Date().getFullYear()
      // const presentMonth = new Date().getMonth()+1;
      // if(currentMonth.getFullYear() > presentYear) {
      //   setDisableNavigation(true);
      // } else if (currentMonth.getMonth() > presentMonth+3) {
      //   setDisableNavigation(true);
      // }
      setCurrentMonth(addWeeks(currentMonth, 1));
      // setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            {/* prev week */}
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        {/* <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div>
        </div> */}
        <div className="col col-center">
          <span className="font-weight-bold">{format(currentMonth, dateFormat)}</span>
        </div>
        {/* <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div>
        </div> */}
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEEEEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="single-date cursor-default col col-center calender_line_height" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row calender_line_height">{days}</div>;
  };

  const DaySelectorHandler = (date: Date) => { daySelectorHandler(date); }

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        const orderPresent = meetingsWithOrders?.find(date => isSameDay(date, cloneDay));
        const deliverExist = meetingsWithNoOrders?.find(date => isSameDay(date, cloneDay));

        days.push(
          orderPresent || deliverExist ?
            <div
              className={`single-date cursor-default col ${orderPresent ? 'order-present' : deliverExist ? 'delivery-present' : 'delivery-not-present'}`}
              key={day.toString()}
              onClick={() => {
                DaySelectorHandler(cloneDay);
              }}
            >
              <div className={`all-days ${isSameDay(day, selectedDate) ? 'calendar-selected-day' : ''}`}>
                <span className="number">{formattedDate}</span>
              </div>
            </div>
            :
            <div
              className={`single-date cursor-default col ${orderPresent ? 'order-present' : deliverExist ? 'delivery-present' : 'delivery-not-present'}`}
              key={day.toString()}
            >
              <div className={`all-days ${isSameDay(day, selectedDate) ? 'calendar-selected-day' : ''}`}>
                <span className="number">{formattedDate}</span>
              </div>
            </div>
        )
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="mobile-calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
