import React from 'react';
import isEmpty from '../../../Utilities/is_empty';
import FoodItemSlide from '../Common/FoodItemSlide';
import { Slide } from 'pure-react-carousel';
import MenuDetailPageCarousel from './MenuDetailPageCarousel';
import FootItemCarouselNextButton from '../SVGs/FootItemCarouselNextButton.svg';
import FootItemCarouselBackButton from '../SVGs/FootItemCarouselBackButton.svg';
import { MenuDetailPageParentRef, MenuSections } from './MenuArea';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { FoodItem, RecentOrder } from '../../../Interfaces/PayloadsAndResponses/Menu';

export type GenericOrderAndFoodItem = { fooditem: FoodItem, order: RecentOrder | null }

type MenuSectionFoodItemsProps = {
  foodItems: FoodItem[],
  cutOffPassed: boolean;
  currentMeeting?: Delivery;
  showCarouselButtons: boolean;
  parentRef: MenuDetailPageParentRef;
  fromMarketplace?: boolean;
  fromDashboard?: boolean;
  fromInvitedMeeting?: boolean;
  noOfSlidesForFoodItems: number;
  sectionType: MenuSections;
  recentOrdersData?: RecentOrder[];
}

const MenuSectionFoodItems = ({
  cutOffPassed,
  currentMeeting,
  fromDashboard = false,
  fromInvitedMeeting = false,
  fromMarketplace = false,
  parentRef,
  sectionType,
  foodItems,
  noOfSlidesForFoodItems,
  showCarouselButtons,
  recentOrdersData = [],
}: MenuSectionFoodItemsProps) => {


  const getFoodItemsBasedOnImages = (foodItemsList: FoodItem[] = []): { foodItemsWithImages: GenericOrderAndFoodItem[], foodItemsWithoutImages: GenericOrderAndFoodItem[] } => {
    let foodItemsWithImages: GenericOrderAndFoodItem[] = [], foodItemsWithoutImages: GenericOrderAndFoodItem[] = [];
    foodItemsList?.forEach((fooditem, index) => {
      if (fooditem?.image?.medium?.url) {
        foodItemsWithImages.push({ fooditem, order: recentOrdersData[index] ?? null });
      } else {
        foodItemsWithoutImages.push({ fooditem, order: recentOrdersData[index] ?? null });
      }
    });
    if (isEmpty(foodItemsWithImages)) {
      foodItemsWithImages = foodItemsWithoutImages;
      foodItemsWithoutImages = [];
    }
    return { foodItemsWithImages, foodItemsWithoutImages }
  }

  const { foodItemsWithImages, foodItemsWithoutImages } = getFoodItemsBasedOnImages(isEmpty(foodItems) ? [] : foodItems);

  return <MenuDetailPageCarousel
    totalSlides={foodItemsWithImages?.length + Math.ceil(foodItemsWithoutImages?.length / 2)}
    visibleSlides={noOfSlidesForFoodItems}
    showCarouselButtons={showCarouselButtons}
    NextButtonContent={<img alt='Next' src={FootItemCarouselNextButton} />}
    BackButtonContent={<img alt='Back' src={FootItemCarouselBackButton} />}
    NextButtonStyles={{ right: '10px', height: '50px', width: '50px' }}
    BackButtonStyles={{ left: '15px', height: '50px', width: '50px' }}
  >
    {foodItemsWithImages?.map((item, sectionIndex) => (
      <Slide className='new-section py-4' key={item.fooditem?.id + '-' + sectionIndex} index={sectionIndex}>
        <FoodItemSlide
          fromDashboard={fromDashboard}
          fromMarketplace={fromMarketplace}
          fromInvitedMeeting={fromInvitedMeeting}
          parentRef={parentRef}
          fooditem={item.fooditem}
          sectionType={sectionType}
          cutOffPassed={cutOffPassed}
          className='h-100'
          foodItemSlideStyles={{ flexGrow: 1 }}
          deliveryDetails={currentMeeting}
          recentOrder={item.order}
        />
      </Slide>
    ))}
    {foodItemsWithoutImages?.map((item, sectionIndex) => {
      const secondItem = foodItemsWithoutImages[sectionIndex + 1];
      const lastItem = sectionIndex === foodItemsWithoutImages?.length - 1;
      return sectionIndex % 2 === 0 ?
        <Slide className='py-4' index={sectionIndex} key={item.fooditem?.id + '-' + sectionIndex}>
          <div className={`d-flex flex-column justify-content-between h-100`} style={{ gap: 10 }}>
            <FoodItemSlide
              fromDashboard={fromDashboard}
              fromMarketplace={fromMarketplace}
              sectionType={sectionType}
              fromInvitedMeeting={fromInvitedMeeting}
              parentRef={parentRef}
              fooditem={item.fooditem}
              deliveryDetails={currentMeeting}
              foodItemSlideStyles={lastItem ? {} : { flexGrow: 1, maxHeight: `calc(50% - 10px)` }}
              cutOffPassed={cutOffPassed}
              recentOrder={item.order}
            />
            {secondItem &&
              <FoodItemSlide
                fromDashboard={fromDashboard}
                fromMarketplace={fromMarketplace}
                sectionType={sectionType}
                fromInvitedMeeting={fromInvitedMeeting}
                parentRef={parentRef}
                fooditem={secondItem.fooditem}
                deliveryDetails={currentMeeting}
                foodItemSlideStyles={{ flexGrow: 1, maxHeight: `calc(50% - 10px)` }}
                cutOffPassed={cutOffPassed}
                recentOrder={item.order}
              />
            }
          </div>
        </Slide> : ''
    })}
  </MenuDetailPageCarousel>
}

export default MenuSectionFoodItems;