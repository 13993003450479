import React, { ChangeEvent, FocusEvent, FC } from 'react';

type Props = {
  label?: string,
  name: string,
  value?: string,
  errors?: any,
  type: string,
  placeholder?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean,
  readOnly?: boolean,
  sm?: number,
  rightLabel?: string,
  classnames?: string,
  autoComplete?: string,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  register?: any,
  maxlength?: number
};

const InputField: FC<Props> = ({ label, name, type, sm, value, onChange, errors, placeholder, isRequired, readOnly, rightLabel, classnames, autoComplete, onBlur, register, maxlength }) => {
  return (
    <div className={`col-sm-${sm ? sm : 6} company-required-field ${errors[name] ? 'px-2 pt-3' : 'pt-3 px-2'}`}>
      {label && <h6 className='orderform-section-name float-left pb-1'>{label}</h6>}
      {(isRequired || rightLabel) && (
        <h6 className='orderform-section-required muted float-right pt-0'>
          {' '}
          {rightLabel ? rightLabel : 'Required'}
        </h6>
      )}
      {register ?
        <input
          {...register}
          type={type}
          name={name}
          className={`form-control ${errors && errors?.name ? 'is-invalid' : ''} ${classnames || ''}`}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          autoComplete={autoComplete}
          maxlength={maxlength}
        />
      :
        <input
          type={type}
          name={name}
          className={`form-control ${errors && errors[name] ? 'is-invalid' : ''} ${classnames || ''}`}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          onBlur={onBlur}
        />
      }
      {errors && errors[name] && (
        <small className='invalid-feedback-password'>
          {' '}
          {register ? errors[name]?.message : errors[name]}
        </small>
      )}
    </div>
  );
};

export default InputField;
