import i18n from "i18next";

export const genericMeetingTranslations = () => {
  i18n.addResourceBundle('en', 'translations',
    {
      "Your Name": "Your Name",
      "Tell us a bit about yourself so we know whose ordering.": "Tell us a bit about yourself so we know whose ordering.",
      "What's Your Name?": "What's Your Name?",
      "First Name": "First Name",
      "Required": "Required",
      "Last Name": "Last Name",
      "Email": "Email",
      "Begin Order": "Begin Order"

    });
  i18n.addResourceBundle('ur', 'translations',
    {
      "Your Name": "تمھارا نام",
      "Tell us a bit about yourself so we know whose ordering.": "ہمیں اپنے بارے میں تھوڑا سا بتائیں تاکہ ہم جان لیں کہ کس کا حکم ہے۔",
      "What's Your Name?": "آپ کا نام کیا ہے؟",
      "First Name": "پہلا نام",
      "Required": "ضروری ہے",
      "Last Name": "آخری نام",
      "Email": "ای میل",
      "Begin Order": "آرڈر شروع کریں"
    });
};