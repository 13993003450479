import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrayOfLength } from '../../../Utilities/ArraysUtilities';

const DeliveryOrderDetailsLoading = ({ closeSidebar }: { closeSidebar: () => void; }) => {

  const DetailsLoading = ArrayOfLength(5).map(entry =>
    <div key={entry} className='px-3'>
      <p className='mb-2'><Skeleton width={128} height={20} /></p>
      <p className='mb-2'><Skeleton width={128} height={20} /></p>
    </div>
  )

  return <section>
    {/* Sidebar Header */}
    <div className='px-3 px-md-4 py-3 bg-white d-flex justify-content-between align-items-center'>
      <h4 className='m-0'>
        <Skeleton width={200} height={30} />
        <p className='mb-0 badge badge-pill d-inline mt-2'>
          <Skeleton width={45} height={18} />
        </p>
      </h4>
      <i onClick={() => closeSidebar?.()} className='cursor-pointer p-1 m-1 fas fa-times'></i>
    </div>
    {/* Order Details Section*/}
    <div className='px-3 px-md-4'>
      {/* Main Header For Order Details Section*/}
      <div className='d-flex justify-content-between py-2'>
        <h5 className='m-0 align-self-start py-1'>
          <Skeleton width={120} height={26} />
        </h5>
        <div className='d-flex align-items-center align-self-end py-1'>
          <span className='mx-1'>
            <Skeleton width={88} height={35} />
          </span>
          <span className='mx-1'>
            <Skeleton width={88} height={35} />
          </span>
        </div>
      </div>
      {/* Delivery Restaurant Address and Runningmenu Fields */}
      <div className='row'>
        <div className='col-12 col-md-3 my-1 d-flex flex-column justify-content-between'>
          <p>
            <Skeleton width={225} height={24} />
          </p>
          <p>
            <Skeleton width={225} height={24} />
          </p>
          <p>
            <Skeleton width={225} height={24} />
          </p>
        </div>
        <div className='col-12 col-md-9 my-1col-12 col-md-9 my-1 d-flex justify-content-md-end' style={{ overflow: 'auto' }}>
          <div className='d-flex'>
            {DetailsLoading}
          </div>
          <div className='d-flex mb-2'>
            {DetailsLoading}
          </div>
        </div>
      </div>
      {/* Delivery Orders Filters*/}
      <div>
        <h3>
          <Skeleton width={88} height={28} />
        </h3>
        <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
          {/* Orders Status Filters */}
          <div className='d-flex align-items-center my-1 align-self-start flex-wrap'>
            <div className='align-self-start align-self-md-center'>
              <Skeleton width={120} height={36} />
            </div>
          </div>
          {/* Search Filter */}
          <div className='d-flex my-1 align-items-center align-self-start align-self-md-center overflow-hidden'>
            <Skeleton width={260} height={40} />
          </div>
        </div>
      </div>
    </div>
  </section>
}

export default DeliveryOrderDetailsLoading;