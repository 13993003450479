import { LOADING_INVITED_USERS, GET_INVITED_USERS_FOR_MEETING, INVITE_USER_FOR_MEETING_BY_TOKEN, RESEND_INVITE, SET_CUSTOMER_DATA, GENERATE_SHARE_MEETING_TOKEN, RESET_STATE } from '../Actions/types';
import { ShareMeeting } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: ShareMeeting = {
  invitedUsersForMeeting: null,
  inviteUserForMeetingByToken: null,
  resend_status: null,
  loading: false,
  customer_data: null,
  share_token: null,
  // company_data: null,
}

const shareMeetingReducer = (state: ShareMeeting = initialState, action: any): ShareMeeting => {
  switch (action.type) {
    case LOADING_INVITED_USERS:
      return {
        ...state,
        loading: true
      }
    case GET_INVITED_USERS_FOR_MEETING:
      return {
        ...state,
        invitedUsersForMeeting: action.payload,
        loading: false
      }
    case INVITE_USER_FOR_MEETING_BY_TOKEN:
      return {
        ...state,
        inviteUserForMeetingByToken: action.payload,
        loading: false
      }
    case RESEND_INVITE:
      return {
        ...state,
        resend_status: action.payload
      }
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customer_data: action.payload
      }
    // case GET_COMPANY_FOR_ORDER_PURPOSE:
    //   return {
    //     ...state,
    //     company_data: action.payload
    //   }
    case GENERATE_SHARE_MEETING_TOKEN:
      return {
        ...state,
        share_token: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default shareMeetingReducer;