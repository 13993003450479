import React, { CSSProperties, FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../Interfaces/ReduxInterface/ReduxRootState';
import '../Common/Styles/header.css'

export type TableColumn = {
  label: string,
  columnName?: string;
  sortable?: boolean;
  hidden?: boolean;
  className?: string;
  style?: CSSProperties,
  id?: string;
}

type Props = {
  columns: TableColumn[];
  sortingColumn: string | string[];
  handleSorting: (sortType: string) => void;
  classNamesForHeader?: string;
  inlineHeader?: boolean;
  theadStyles?: CSSProperties;
};

const TableHeader: FC<Props> = ({ columns, sortingColumn, handleSorting, classNamesForHeader, inlineHeader, theadStyles = {} }) => {
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);
  return (
    <thead id='table-header' style={theadStyles}>
      <tr className={classNamesForHeader ? classNamesForHeader : ''} >
        {columns.map(
          (column, index) =>
            !column.hidden && (
              <th 
                className={` user-custom-header ${column?.className ? column?.className : ''}
                  ${(column.label === 'Qty' || column.label === 'Status' || column.label === 'Group') ? column.label === 'Qty'? 'orderDetailsTableQty':'orderDetailsTableHeader' : 'user-table-header'}
                  ${sortingColumn === column.columnName && column.sortable
                    ? 'cursor-pointer sorting-icon'
                    : column.sortable
                      ? 'cursor-pointer'
                      : ''}
                `}
                style={column.style}
                onClick={() =>
                  column.sortable ? handleSorting(column?.columnName || '') : ''
                }
                key={index}
                id={column?.id || ''}
              >
                <span className={`${inlineHeader ? 'd-flex text-nowrap justify-content-between' : ''}`}>
                  {i18n?.t(column.label)}
                  {column.sortable && (
                    <span className='icon-span'>
                      <i
                        className={`fas fa-sort px-2 m-1 ${classNamesForHeader && column.columnName !== sortingColumn ? 'sort-icon' : ''} ${column.columnName === sortingColumn ? '' : 'sorted_asc'
                          }`}
                        aria-hidden='true'
                      />
                    </span>
                  )}
                </span>
              </th>
            )
        )}
      </tr>
    </thead>
  );
}

export default TableHeader;