import dayjs from 'dayjs';
import { format } from 'date-fns-tz';
import { getUserOrLocalTimeZone } from './functions';

export const getDateForMarketplace = (time_zone?: string) => {

  let zonedDateTime = new Date().toLocaleString(
    "en-US",
    { timeZone: time_zone ? time_zone : getUserOrLocalTimeZone().userOrLocalTimeZone }
  );
  const thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') // date for marketplace with threshold: 2pm
  
  return dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime)) ? 2 : 1), 'day').format('M-D-YYYY');
}

export const getFutureDateWithTimeZone = (future_date: string, time_zone?: string) => {
  const futureDateWithTimeZone = format(
    new Date(future_date),
    'EEE. MMM dd h:mma, zzzz',
    { timeZone: time_zone ? time_zone : getUserOrLocalTimeZone().userOrLocalTimeZone }
  );

  let timeZoneAbbreviation = futureDateWithTimeZone.split(',')?.[1];
  if (timeZoneAbbreviation) {
    return `${futureDateWithTimeZone.split(',')[0]} ${timeZoneAbbreviation?.match(/[A-Z]/g)?.join('')}`;
  } else {
    return `${futureDateWithTimeZone.split(',')[0]}`;
  }
}

export const getCurrentDateTime = (time_zone?: string, dateToBeConverted?: string | Date) => {
  const date = new Date(dateToBeConverted ? dateToBeConverted : '');
  const options: Intl.DateTimeFormatOptions = {
    timeZone: time_zone ? time_zone : getUserOrLocalTimeZone().userOrLocalTimeZone,
    timeZoneName: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}