import { GET_BILLING_DATA, RESET_STATE } from '../Actions/types';
import { PaymentSaved } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: PaymentSaved = {
  billing_details: null
}

const billingReducer = (state: PaymentSaved = initialState, action: any): PaymentSaved => {
  switch (action.type) {
    case GET_BILLING_DATA:
      return {
        ...state,
        billing_details: action.payload
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default billingReducer;