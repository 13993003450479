// // Packages
// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import { connect } from 'react-redux';
// import { RouteComponentProps, withRouter } from 'react-router-dom';
// // React JSX Components
// import ButtonSpinner from '../Spinners/ButtonSpinner';
// // Redux Dispatch Actions
// import { getSingleDeliveryMenu } from '../../Actions/menuActions';
// import { EMPTY_ORDER_RATING_DATA, SET_POPUP_STATE_ON_MENU_DETAIL_PAGE } from '../../Actions/types';
// import { getFooditemForRating, getRestaurantForRating, addRating } from '../../Actions/orderActions';
// // Utilities
// import store from '../../store';
// import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
// import { getloggedInUser } from '../../Utilities/getloggedInUser';
// import { showToastNotification } from '../../Utilities/showToastNotification';
// // Types
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { OrderRatingPayload, RatingFoodItem, RatingRestaurant } from '../../Interfaces/PayloadsAndResponses/Orders';
// // CSS

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//     comment: '',
//     borderRadius: 17,
//   }
// };
// Modal.setAppElement('#root');
// Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
// Modal.defaultStyles.overlay!.zIndex = 6;

// type Params = { meetingSlug?: string };

// type RatingPopUpProps = {
//   isAdmin?: boolean;
//   forOrderItAgainRating?: boolean;
//   fooditem: RatingFoodItem | null;
//   restaurant: RatingRestaurant | null;
//   isOpenPopupOnMenuDetailPage: boolean;
//   onRef: (ref: RatingPopUpRef | null) => void;
//   getFooditemForRating: () => void;
//   getRestaurantForRating: () => void;
//   addRating: (ratingData: OrderRatingPayload, successCallback: () => void) => void;
//   getSingleDeliveryMenu: (slug: string, menuType: string) => void;
// } & RouteComponentProps<Params>;

// type RatingPopUpState = {
//   modalIsOpen: boolean;
//   rating: number;
//   ratingLoading: boolean,
//   comment: string;
//   hoveredStar: number;
// }

// export type RatingType = 'runningmenu_services' | 'order_services';

// class RatingPopUp extends Component<RatingPopUpProps, RatingPopUpState> {

//   static defaultProps = {
//     isAdmin: getloggedInUser().isCompanyAdmin
//   }

//   state: RatingPopUpState = {
//     modalIsOpen: false,
//     hoveredStar: 0,
//     rating: 0,
//     ratingLoading: false,
//     comment: '',
//   };

//   calculateRatingData = () => {
//     const { isAdmin, getRestaurantForRating, getFooditemForRating } = this.props;
//     const orderId = localStorage.getItem('order_id');
//     const runningmenu_id = localStorage.getItem('runningmenu_id');
//     const ratingCount = localStorage.getItem('rating_count');

//     if (isAdmin && orderId && runningmenu_id) { // Admin Rating For Running Menu Service
//       getRestaurantForRating();
//     } else if (orderId && !runningmenu_id) { // Admin OR User Rating For Fooditem
//       getFooditemForRating();
//     }
//     this.setState({ rating: +(ratingCount ?? 0) });
//   }

//   componentDidMount() {
//     const { onRef, forOrderItAgainRating } = this.props;
//     onRef(this);
//     if (!forOrderItAgainRating) {
//       this.calculateRatingData();
//       store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: false })
//     }
//     // Added this Event Listner because REACT does not have chance to unmount the component on Manually Refreshing the Page.
//     forOrderItAgainRating && window.addEventListener('beforeunload', this.clearRatingData);
//   }
//   componentWillUnmount() {
//     this.props.onRef(null);
//     if (this.props.forOrderItAgainRating) {
//       this.props.forOrderItAgainRating && window.removeEventListener('beforeunload', this.clearRatingData); // remove the event handler for normal unmounting
//       this.clearRatingData();
//     }
//   }

//   clearRatingData = () => {
//     localStorage.removeItem('order_id');
//     localStorage.removeItem('runningmenu_id');
//     localStorage.removeItem('rating_count');
//     store.dispatch({
//       type: EMPTY_ORDER_RATING_DATA
//     });
//   }

//   openModal = () => {
//     this.setState({ modalIsOpen: true });
//   }
//   closeModal = () => {
//     this.setState({ modalIsOpen: false, ratingLoading: false });
//     this.props.isOpenPopupOnMenuDetailPage && store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: false })
//     this.clearRatingData();
//   }
//   handleChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
//     const inputName = 'comment';
//     this.setState({ [inputName]: value });
//   }

//   onSubmit = () => {
//     const { isAdmin, addRating, getSingleDeliveryMenu } = this.props;
//     const meetingSlug = this.props?.match?.params?.meetingSlug;
//     console.log(meetingSlug,'meeting')
//     const { comment, rating: selectedRating } = this.state;
//     const runningmenu_id = localStorage.getItem('runningmenu_id');
//     const isAdminRunningMenuServiceRating = isAdmin && runningmenu_id;
//     const isFoodItemRating = !runningmenu_id;

//     let rating: OrderRatingPayload['rating'] = {
//       comment: comment
//     };
//     if (isAdminRunningMenuServiceRating) { // Admin Rating For Running Menu Service
//       rating.runningmenu_rating = selectedRating;
//     } else if (isFoodItemRating) { // Admin OR User Rating For Fooditem
//       rating.rating_value = selectedRating;
//     };
//     if (selectedRating <= 0) {
//       showToastNotification('Please select a rating by clicking on the stars below.', 'Oops!');
//     } else {
//       this.setState({ ratingLoading: true });
//       addRating({ rating: rating }, () => {
//         meetingSlug && getSingleDeliveryMenu(meetingSlug, 'lunch')
//         this.closeModal();
//       });
//     }
//   }

//   render() {
//     const {
//       isAdmin,
//       fooditem,
//       restaurant,
//     } = this.props;

//     const {
//       rating,
//       hoveredStar,
//       modalIsOpen,
//       ratingLoading,
//     } = this.state;

//     const runningmenu_id = localStorage.getItem('runningmenu_id');
//     const isAdminRunningMenuServiceRating = isAdmin && runningmenu_id;
//     const isFoodItemRating = !runningmenu_id;

//     let Stars, foodImageUrl;
//     Stars = ArrayOfLength(5).map(item => (
//       <div key={item}
//         onMouseEnter={() => this.setState({ hoveredStar: item + 1 })}
//         onMouseLeave={() => this.setState({ hoveredStar: 0 })}
//         onClick={() => { this.setState({ rating: item + 1 }) }}
//         className='d-inline mx-1 px-1 cursor-pointer'
//       >
//         <i className={`${(hoveredStar || rating) <= item ? 'far' : 'fas'} fa-star fa-2x`}
//         > </i>
//       </div>
//     ));

//     foodImageUrl = fooditem && fooditem.fooditem_image && fooditem.fooditem_image.url;
//     return (
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={this.closeModal}
//         shouldCloseOnOverlayClick={!ratingLoading}
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className='order-modal' id='orderModelBox'>
//           <div className='modal-dialog' >
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className='modal-header' style={{ width: '690px' }}>
//                 {(isAdminRunningMenuServiceRating) ?
//                   <h5 className='modal-title' id='modal-title'> Rate Food & Service for <span className='text-capitalize'>{restaurant && restaurant.scheduler_name} </span> </h5>
//                   : ''}
//                 {isFoodItemRating ?
//                   <h5 className='modal-title' id='modal-title'> Rate: <span className='text-capitalize'>{fooditem && fooditem.fooditem_name} </span> </h5>
//                   : ''}
//                 <button type='button' onClick={this.closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
//                 <div id='main' className='container'>
//                   <form noValidate className='text-center'>
//                     {isAdminRunningMenuServiceRating ?
//                       <div>
//                         {/* <h5 >What did you think of <b className='text-capitalize'>{restaurant && restaurant.restaurant_name}</b>?</h5>
//                         <div style={{ textAlign: 'center', marginTop: '30px', minHeight: '80px' }}>
//                         {RestaurantServices}
//                         </div> */}
//                         <h5>How was our service?</h5>
//                         <div style={{ textAlign: 'center', marginTop: '30px', minHeight: '80px' }}>
//                           {Stars}
//                         </div>
//                       </div>
//                       : ''}
//                     {isFoodItemRating ?
//                       <div>
//                         {foodImageUrl ? <div className='card-item-img-lg' style={{ backgroundImage: `url(${foodImageUrl})` }} /> : ''}
//                         <div style={{ textAlign: 'center', marginTop: '30px', minHeight: '80px' }}>
//                           {Stars}
//                         </div>
//                       </div>
//                       : ''}
//                     <div className='form-row'>
//                       <div className='col-12 company-required-field p-3'>
//                         <h6 className='orderform-section-name float-left '>Comments </h6>
//                         <h6 className='orderform-section-required muted float-right '>Suggestions for improvements </h6>
//                         <textarea
//                           rows={4}
//                           id='name'
//                           name='comment'
//                           className='form-control'
//                           onChange={this.handleChange}
//                           placeholder='Love it? hate it? Let us know!'
//                         >
//                         </textarea>
//                         {isFoodItemRating &&
//                           <div className='pt-4'>
//                             <p className='muted text-center'>
//                               <i className='fas fa-heart mr-1' aria-hidden='true'></i>
//                               Review with empathy!<br />
//                               Your reviews are shared anonymously with our restaurant partners including local mom & pop restaurants, please rate with care.
//                             </p>
//                           </div>
//                         }
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='modal-footer' style={{ width: 690 }}>
//           <button
//             type='submit'
//             style={{ borderRadius: 1000 }}
//             className='ml-3 btn btn-primary delivery-btn-width'
//             onClick={this.onSubmit}
//             disabled={rating <= 0 || ratingLoading}
//           >
//             {ratingLoading ?
//               <ButtonSpinner /> : 'Submit'
//             }
//           </button>
//         </div>
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   fooditem: state.order.rating_fooditem,
//   restaurant: state.order.rating_restaurant,
//   isOpenPopupOnMenuDetailPage: state.menu.isOpenPopupOnMenuDetailPage
// });

// export type RatingPopUpRef = React.ElementRef<typeof RatingPopUp>;

// export default connect(mapStateToProps, { getSingleDeliveryMenu, getFooditemForRating, getRestaurantForRating, addRating })(withRouter(RatingPopUp));


// conversion from class to functional component

import React, { useState, useEffect, useImperativeHandle, forwardRef, } from 'react';
import Modal from 'react-modal';
import ButtonSpinner from '../Spinners/ButtonSpinner';
// Redux Dispatch Actions
import { getSingleDeliveryMenu } from '../../Actions/menuActions';
import { EMPTY_ORDER_RATING_DATA, SET_POPUP_STATE_ON_MENU_DETAIL_PAGE } from '../../Actions/types';
import { getFooditemForRating, getRestaurantForRating, addRating } from '../../Actions/orderActions';
// Utilities
import store from '../../store';
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { showToastNotification } from '../../Utilities/showToastNotification';
// Types
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { OrderRatingPayload } from '../../Interfaces/PayloadsAndResponses/Orders';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// interfaces
type RatingPopUpProps = {
  forOrderItAgainRating?: boolean;
}
type RouteParams = {
  meetingSlug: string;
  date: string;
}

// exporting ref
export type RatingPopUpRef = {
  openModal: () => void;
  calculateRatingData: () => void
}

const RatingPopUp = forwardRef<RatingPopUpRef | null, RatingPopUpProps>(({
  forOrderItAgainRating,
}, ref) => {
  const fooditem = useSelector((state: ReduxRootState) => state?.order?.rating_fooditem);
  const restaurant = useSelector((state: ReduxRootState) => state?.order?.rating_restaurant);
  const isOpenPopupOnMenuDetailPage = useSelector((state: ReduxRootState) => state?.menu?.isOpenPopupOnMenuDetailPage);

  const dispatch: any = useDispatch();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '692px',
      padding: '0',
      comment: '',
      borderRadius: 17,
    }
  };
  Modal.setAppElement('#root');
  Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0,0,0,0.75)';
  Modal.defaultStyles.overlay!.zIndex = 6;

  // states
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [ratingLoading, setRatingLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [hoveredStar, setHoveredStar] = useState<number>(0);

  const isAdmin = getloggedInUser()?.isCompanyAdmin
  // functions
  const calculateRatingData = () => {
    const orderId = localStorage.getItem('order_id');
    const runningmenu_id = localStorage.getItem('runningmenu_id');
    const ratingCount = localStorage.getItem('rating_count');

    if (isAdmin && orderId && runningmenu_id) { // Admin Rating For Running Menu Service
      dispatch(getRestaurantForRating())
    } else if (orderId && !runningmenu_id) { // Admin OR User Rating For Fooditem
      dispatch(getFooditemForRating())
    }
    setRating(+(ratingCount ?? 0));
  }

  useEffect(() => {
    if (!forOrderItAgainRating) {
      calculateRatingData();
      store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: false })
    }
    // Added this Event Listner because REACT does not have chance to unmount the component on Manually Refreshing the Page.
    forOrderItAgainRating && window.addEventListener('beforeunload', clearRatingData);

    // component will unmount
    return () => {
      if (forOrderItAgainRating) {
        forOrderItAgainRating && window.removeEventListener('beforeunload', clearRatingData); // remove the event handler for normal unmounting
        clearRatingData();
      }
    }
    // eslint-disable-next-line
  }, [])

  const clearRatingData = () => {
    localStorage.removeItem('order_id');
    localStorage.removeItem('runningmenu_id');
    localStorage.removeItem('rating_count');
    store.dispatch({
      type: EMPTY_ORDER_RATING_DATA
    });
  }

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
    setRatingLoading(false)
    isOpenPopupOnMenuDetailPage && store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: false })
    clearRatingData();
  }
  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(value)
  }

  const param = useParams<RouteParams>();
  const onSubmit = () => {
    const meetingSlug = param?.meetingSlug
    let selectedRating = rating
    const runningmenu_id = localStorage.getItem('runningmenu_id');
    const isAdminRunningMenuServiceRating = isAdmin && runningmenu_id;
    const isFoodItemRating = !runningmenu_id;

    let orderRating: OrderRatingPayload['rating'] = {
      comment: comment
    };
    if (isAdminRunningMenuServiceRating) { // Admin Rating For Running Menu Service
      orderRating.runningmenu_rating = selectedRating;
    } else if (isFoodItemRating) { // Admin OR User Rating For Fooditem
      orderRating.rating_value = selectedRating;
    };
    if (selectedRating <= 0) {
      showToastNotification('Please select a rating by clicking on the stars below.', 'Oops!');
    } else {
      setRatingLoading(true)
      dispatch(addRating({ rating: orderRating }, () => {
        meetingSlug && dispatch(getSingleDeliveryMenu(meetingSlug, 'lunch'))
        closeModal();
      }));
    }
  }

  const runningmenu_id = localStorage.getItem('runningmenu_id');
  const isAdminRunningMenuServiceRating = isAdmin && runningmenu_id;
  const isFoodItemRating = !runningmenu_id;

  let Stars, foodImageUrl;
  Stars = ArrayOfLength(5)?.map(item => (
    <div key={item}
      onMouseEnter={() => setHoveredStar(item + 1)}
      onMouseLeave={() => setHoveredStar(0)}
      onClick={() => setRating(item + 1)}
      className='d-inline mx-1 px-1 cursor-pointer'
    >
      <i className={`${(hoveredStar || rating) <= item ? 'far' : 'fas'} fa-star fa-2x`}> </i>
    </div>
  ));

  foodImageUrl = fooditem && fooditem?.fooditem_image && fooditem?.fooditem_image?.url;

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    calculateRatingData
  }));
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={!ratingLoading}
      style={customStyles}
      contentLabel='Example Modal'>
      <div className='order-modal' id='orderModelBox'>
        <div className='modal-dialog' >
          <div className='modal-content' style={{ border: 'none' }}>
            <div className='modal-header' style={{ width: '690px' }}>
              {isAdminRunningMenuServiceRating &&
                <h5 className='modal-title' id='modal-title'> Rate Food & Service for <span className='text-capitalize'>{restaurant && restaurant?.scheduler_name} </span> </h5>
              }
              {isFoodItemRating &&
                <h5 className='modal-title' id='modal-title'> Rate: <span className='text-capitalize'>{fooditem && fooditem?.fooditem_name} </span> </h5>
              }
              <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
            </div>
            <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
              <div id='main' className='container'>
                <form noValidate className='text-center'>
                  {isAdminRunningMenuServiceRating &&
                    <div>
                      <h5>How was our service?</h5>
                      <div style={{ textAlign: 'center', marginTop: '30px', minHeight: '80px' }}>
                        {Stars}
                      </div>
                    </div>
                  }
                  {isFoodItemRating &&
                    <div>
                      {foodImageUrl && <div className='card-item-img-lg' style={{ backgroundImage: `url(${foodImageUrl})` }} />}
                      <div style={{ textAlign: 'center', marginTop: '30px', minHeight: '80px' }}>
                        {Stars}
                      </div>
                    </div>
                  }
                  <div className='form-row'>
                    <div className='col-12 company-required-field p-3'>
                      <h6 className='orderform-section-name float-left '>Comments </h6>
                      <h6 className='orderform-section-required muted float-right '>Suggestions for improvements </h6>
                      <textarea
                        rows={4}
                        id='name'
                        name='comment'
                        className='form-control'
                        onChange={handleChange}
                        placeholder='Love it? hate it? Let us know!'
                      >
                      </textarea>
                      {isFoodItemRating &&
                        <div className='pt-4'>
                          <p className='muted text-center'>
                            <i className='fas fa-heart mr-1' aria-hidden='true'></i>
                            Review with empathy!<br />
                            Your reviews are shared anonymously with our restaurant partners including local mom & pop restaurants, please rate with care.
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-footer' style={{ width: 690 }}>
        <button
          type='submit'
          style={{ borderRadius: 1000 }}
          className='ml-3 btn btn-primary delivery-btn-width'
          onClick={onSubmit}
          disabled={rating <= 0 || ratingLoading}
        >
          {ratingLoading ?
            <ButtonSpinner /> : 'Submit'
          }
        </button>
      </div>
    </Modal>
  )
})


export default RatingPopUp