import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReduxRootState } from "../../Interfaces/ReduxInterface/ReduxRootState";

type Props = {
  path?: string | null,
  classNames?: string,
  label: string,
  icon?: string,
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const MenuLink: FC<Props> = ({ path, label, icon = null, classNames, onClick }) => {
  const i18n = useSelector((state: ReduxRootState) => state.i18n?.i18n);
  return (
    <>
      {path ?
        <Link className={`dropdown-item profiledropdownitem ${classNames || ''}`} to={path} onClick={onClick}>
          {i18n && i18n.t(label)}
          {icon && <i className={`${icon} float-right pt-1`} />}
        </Link> :
        <span className={`dropdown-item profiledropdownitem cursor-pointer ${classNames || ''}`} onClick={e => { e?.stopPropagation(); onClick?.(); }}>
          {i18n && i18n.t(label)}
          {icon && <i className={`${icon} float-right pt-1`} />}
        </span>
      }
      <div className="dropdown-divider" />
    </>
  );
};

export default MenuLink;
