import React, { FC } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '593px',
    width: '90vw',
    padding: '0',
  }
};

type props = {
  modalIsOpen: boolean,
  closeModal: () => void,
  confirmAction: () => void
}

const EnterprizeConfirmationPopUp: FC<props> = ({ modalIsOpen, closeModal, confirmAction }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Confirmation Modal'>
      <div className='order-modal'>
        <div className='slide'>
          <div className='modal-content cursor-default'>
            <div className='modal-header'>
              <h3
                id='modal-title'
                className='modal-title'
                style={{ fontWeight: 600, fontSize: 22 }}
              >
                Request Received
              </h3>
              <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div>
              <div id='order-status-update-popup' className='modal-body py-2'>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight: 266, gap: 20 }}>
                  <img width={150} height={150} src='/imgs/confirmation-tick.gif' alt='confirmation-tick' />
                  <p style={{ fontSize: 20, color: '#646464' }} className='text-center mb-0'>
                    Your request for enterprise plan has been received.
                    <br />
                    Our Team will contact you soon via e-mail
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  onClick={closeModal}
                  className='btn accept-order-btn border-0'
                  style={{ borderRadius: 22, backgroundColor: '#157FFC', padding: '12px 44px', color: 'white' }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EnterprizeConfirmationPopUp;
