import { logoutUser } from './authActions';
import { GET_ERRORS, GET_LOADING_OFF, CLEAR_ERRORS } from './types';
import { showToastNotification } from '../Utilities/showToastNotification';
import { AppThunkAction } from '../store';

//get errors
export const getError = (error: any): AppThunkAction => dispatch => {
  dispatch({
    type: GET_LOADING_OFF,
  });
  if (error.response) {
    if (error?.response?.data?.message) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data.message
      });

      if (error?.response?.status === 422 && error?.response?.data?.message && error?.response?.data?.message === 'Unauthorized access') {
        showToastNotification(`Oops, you can't access this page.', 'Oops!`, true);
      }

    } else if (error?.response?.status === 401 && !error?.response?.data?.success) {
      localStorage.clear();
      dispatch(sendError(error?.response?.data?.errors[0]));
      window.location.href = '/signin';
    } else if (error?.response?.status === 401) {
      dispatch(logoutUser());
      dispatch(sendError(error?.response?.data?.errors[0]));
    } else {
      return {
        type: GET_ERRORS,
        payload: error
      };
    }
  }
};

export const sendError = (error: any) => {
  return {
    type: GET_ERRORS,
    payload: error
  };
};

export const clearBackendErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};