import React, { FC } from 'react';
// import spinner from './spinner.gif';
// import './SpinnerStyle.css';
type Props = {
    classNames?: string;
}
const ButtonSpinner: FC<Props> = ({ classNames }) => {
    return (
        <div className={`${classNames ? classNames : ''} spinner-border spinner-wide-handling`} role='status'>
            <span className='sr-only'>Loading...</span>
        </div>
    );
};
export default ButtonSpinner