import React, { FC, useEffect, useState } from 'react';
import GooglePlacesAutocomplete from '../../../Common/GooglePlacesAutocomplete';
// import InputField from '../../../Common/InputField';
import TimezonePicker from '../../../Common/TimeZonePicker/TimezonePicker';
import { DeliveryAddressPopupErrors } from '../../../Common/Popups/DeliveryAddressPopUp';
import { NewLocationModalErrors } from '../Modals/AddNewLocationModal';
import { NewDepartmentModalErrors } from '../Modals/AddNewDepartmentModal';
import isEmpty from '../../../Utilities/is_empty';

export type AddressChangeHandler = (address: {
  city?: string,
  state?: string,
  zipCode?: number | null,
  streetNumber?: string,
  street?: string,
  addressLine: string,
  longitude?: number | null,
  latitude?: number | null,
  timezone?: string,
  formatted_address?: string,
  focusDeliveryAddress?: boolean;
}) => void;

type MarketPlaceAddLocationFormProps = {
  showHeading: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valid?:any,
  handleAddress: AddressChangeHandler;
  handleTimezone: (timezone: string) => void;
  timezone: string;
  errors: DeliveryAddressPopupErrors | NewLocationModalErrors | NewDepartmentModalErrors;
  focusDeliveryAddress?: boolean;
  showTimeZone: boolean;
};

const MarketPlaceAddLocationForm: FC<MarketPlaceAddLocationFormProps> = ({
  // handleChange,
  handleAddress,
  valid,
  handleTimezone,
  timezone,
  errors,
  focusDeliveryAddress,
  showTimeZone
}) => {
  const [addressError] = useState<any>(errors)

  useEffect(() => {
    if (!isEmpty(errors.addressLine) || !isEmpty(errors.timezone)) {
      valid?.();
    }
    // eslint-disable-next-line
  }, [addressError, errors])
  return (
    <>
    <div id='main' className='container'>
      <form noValidate className='text-left'>
        <div className='form-row pb-1'>
          <div
            className={`col-sm-12 company-required-field px-2`}
          >
            <h6 className='orderform-section-name float-left pb-1'>Enter Delivery Address</h6>
            <GooglePlacesAutocomplete
              handleAddress={handleAddress}
              errors={addressError}
             focusDeliveryAddress ={focusDeliveryAddress}
              classnames='dept-delivery-address'
            />
            {addressError?.addressLine && (
              <p className='invalid-feedback-password mb-0'>
                {' '}
                {addressError?.addressLine}
              </p>
            )}
          </div>
        </div>
        {/* <div className='form-row'>
          <InputField
            label='Suite Number'
            type='text'
            name='suitNumber'
            onChange={handleChange}
            placeholder=''
            sm={12}
            errors={errors}
            classnames='dept-delivery-suite-number'
          />
        </div> */}
        {showTimeZone &&
          <div className='col-sm-12 company-required-field pt-3 px-2 pb-1'>
            <h6 className='orderform-section-name float-left pb-1'>Timezone</h6>
            <TimezonePicker
              absolute={false}
              placeholder='Select Timezone'
              onChange={handleTimezone}
              value={timezone}
              classnames='dept-delivery-timezone'
              isInvalid={errors?.timezone}
            />
            {addressError?.timezone && (
              <p className='invalid-feedback-password mb-0'>
                {' '}
                {addressError?.timezone}
              </p>
            )}
          </div>
        }
        {/* <div className='form-row pb-1'>
          <InputField
            sm={12}
            label='Enter delivery instructions'
            type='text'
            name='deliveryInstructions'
            placeholder='Call receptionist upon arrival, wait in the lobby, setup in conference room'
            onChange={handleChange}
            classnames='dept-delivery-instructions'
            errors={errors}
          />
        </div> */}
      </form>
    </div>
  </>
  )
}

export default MarketPlaceAddLocationForm