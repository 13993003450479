import React from 'react'
import { useDispatch } from 'react-redux'
import { resendVerifyEmail } from '../../../Actions/userActions'
import { User } from '../../../Interfaces/PayloadsAndResponses/Auth'
// import { getloggedInUser } from '../../../Utilities/getloggedInUser'

export type EmailRedirect = {
  page: string
}

const EmailVerification = () => {
  const dispatch: any = useDispatch()
  // const { loggedInUser: user } = getloggedInUser();
  const localStorageUserData = JSON.parse(localStorage.getItem('user') || '{}') as User;
  // const email_verified = user?.email_verified
  const currentPage = window.location.pathname.split('/')

  const emailRedirectionPage: EmailRedirect = {
    page: currentPage[1]
  }
  if (localStorageUserData?.email_verified === false){
    localStorage.setItem('email_page', JSON.stringify(emailRedirectionPage));
  }


  const verifyEmail = () => {
    dispatch(resendVerifyEmail())
  }
  const menuPage = window?.location?.pathname.split('/')
  return (
    <div>
      {localStorageUserData?.email_verified === false &&
        <div className={`email-not-verified ${(menuPage?.[1] === 'menu') && 'menu-page-alert'}`}><div className="alert d-flex align-items-center container mb-0 py-3 px-3 px-md-0 " role="alert">
          <i className="fas fa-exclamation-triangle mr-3" aria-hidden="true" ></i>
          <div>Your email is <b>UNVERIFIED</b>. Check inbox for verification link. We cannot send you any emails until you verify your email address.&nbsp;
            <span className='cursor-pointer' onClick={verifyEmail}>
              <u>
               Resend Verification Link
              </u>
            </span>.
          </div>
        </div>
        </div>
      }
    </div>
  )
}

export default EmailVerification