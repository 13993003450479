import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getAddressStringByLatLong, getTimeZoneByLocation } from "../Actions/companyActions";
import isEmpty from "./is_empty";
import { showToastNotification } from "./showToastNotification";

export interface LocationData {
  city: string;
  state: string;
  zip: string;
  street_number: string;
  street: string;
  address_line: string;
  longitude: number;
  latitude: number;
  time_zone: string;
  formatted_address: string;
}

const user = JSON.parse(localStorage.getItem("user") || "{}");

const getAddressStringData = async (address: string): Promise<LocationData | null> => {
  try {
    const results = await geocodeByAddress(address);

    if (results.length > 0) {
      const placeDetails = results[0];
      const latLng = await getLatLng(placeDetails);

      // Assuming you have a getTimeZoneByLocation function implemented
      const timezoneResponse = await getTimeZoneByLocation(latLng.lat, latLng.lng);
      latLng?.lat && latLng?.lng && getAddressStringByLatLong(latLng.lat, latLng.lng)
      const time_zone = timezoneResponse?.timeZoneId;

      const formatted_address = placeDetails.formatted_address;

      const locationData: LocationData = {
        city: placeDetails.address_components.find(component => component.types.includes('locality'))?.long_name || '',
        state: placeDetails.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '',
        zip: placeDetails.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
        street_number: placeDetails.address_components.find(component => component.types.includes('street_number'))?.short_name || '',
        street: placeDetails.address_components.find(component => component.types.includes('route'))?.long_name || '',
        address_line: formatted_address || '',
        longitude: latLng.lng || 0,
        latitude: latLng.lat || 0,
        time_zone: time_zone || '',
        formatted_address: formatted_address || '',
      };

      saveAddress(locationData);
      return locationData;
    } else {
      console.error('Error fetching location data: Place details not found');
      return null;
    }
  } catch (error) {
    showToastNotification('Invalid address string', 'Oops!', true);
    window.location.replace('/')
    console.error('Error fetching location data:', error);
    return null;
  }
};

const saveAddress = (address: LocationData) => {
  if (user?.phone_number) {
    // Dispatch your action to add new location data here
  } else {
    if (!isEmpty(address?.latitude)) {
      localStorage.setItem("address", JSON.stringify(address));
    }
  }
};

export default getAddressStringData;
