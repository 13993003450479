// Pacakges
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
// Utilities
import ShoppingCart from '../SVGs/ShoppingCart.svg';
// import SliderChevronRight from '../SVGs/SliderChevronRight.svg';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
// Types
import { ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { ShareMeetingPopUpRef } from '../../../Common/Popups/ShareMeetingPopUp';
import { Delivery, DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import isEmpty from '../../../Utilities/is_empty';
import ReactTooltip from 'react-tooltip';

type DeliveryDetailsForMobilesProps = {
  currentDelivery: Delivery;
  isMarketPlacePreview: boolean;
  parentComponentRef: any;
  companyTimeZone: string;
  isInvitedMeeting?: boolean;
  isOpenSidebar: boolean;
  fromDashboard?: boolean;
  fromMarketplace?: boolean;
  redirectToNewDelivery?: (delivery: DeliveryOnSingleDate, date: Date | string) => void;
  onCloseSideBar: () => void;
}
const DeliveryDetailsForMobiles = ({
  currentDelivery,
  isMarketPlacePreview,
  isInvitedMeeting = false,
  isOpenSidebar = false,
  fromDashboard = false,
  fromMarketplace = false,
  onCloseSideBar,
  redirectToNewDelivery,
  parentComponentRef
}: DeliveryDetailsForMobilesProps) => {

  const history = useHistory();
  const { users: { delivery_dates = {} } = {}, order } = useSelector((state: ReduxRootState) => state) || {};
  const { date: selectedDeliveryDateParam } = useParams<{ date: string, meetingSlug: string }>()
  const groupTagsArray = order.delivery_orders_details?.orders;
  const groupTags = groupTagsArray?.map((val) => {
    return val.group
  })
  const finalGrouptags = new Set(groupTags)
  const uniqueGroups: any = []
  finalGrouptags.forEach((val) => {
    if(!isEmpty(val)){
      uniqueGroups.push(val) 
    }
  })
  const { scheduleMarketplaceHiddenBtnHandler, meetingPopup: deliveryDetailPopup, cancelMeeting, shareMenu, cutOffPassed } = parentComponentRef || {};
  const shareMeetingPopupRef = shareMenu as ShareMeetingPopUpRef;

  const { isCompanyAdmin, isCompanyManager, loggedInUserCompany } = getloggedInUser();
  const formattedSelectedDeliveryDate = dayjs(selectedDeliveryDateParam, 'M-D-YYYY')?.format('YYYY-MM-DD');
  const isFutureDelivery = dayjs(currentDelivery.delivery_at).isAfter(new Date());
  const isEditableDelivery = isCompanyAdmin && (fromMarketplace || isFutureDelivery);
  const isBuffetDelivery = currentDelivery?.menu_type === 'buffet';
  const currentDeliveryCutOff = isCompanyAdmin ? currentDelivery?.admin_cutoff_at : currentDelivery?.cutoff_at;

  const [singleDateDeliveries, setSingleDateDeliveries] = useState<DeliveryOnSingleDate[]>(delivery_dates[formattedSelectedDeliveryDate] || []);

  useEffect(() => {
    if (delivery_dates[formattedSelectedDeliveryDate]) {
      setSingleDateDeliveries(delivery_dates[formattedSelectedDeliveryDate])
    }
  }, [delivery_dates, formattedSelectedDeliveryDate]);

  const scheduleMarketplceHandler = () => {
    onCloseSideBar();
    scheduleMarketplaceHiddenBtnHandler?.();
    history.push(`/menu/${selectedDeliveryDateParam}`)
  }

  const deliveryChangeHandler = (delivery: DeliveryOnSingleDate, deliveryIndex: number) => {
    if (deliveryIndex > 0) {
      setSingleDateDeliveries(prevDeliveries => {
        const prevDeliveriesList = [...prevDeliveries];
        const newSelectedDelivery = prevDeliveriesList?.splice(deliveryIndex, 1);
        return [...newSelectedDelivery, ...prevDeliveriesList]
      })
      redirectToNewDelivery?.(delivery, selectedDeliveryDateParam)
      onCloseSideBar();
      history.push(`/menu/${dayjs(delivery.delivery_at).format('M-D-YYYY')}/${delivery?.slug}`);
    } else if (deliveryIndex === 0 && isEditableDelivery && deliveryDetailPopup?.openModal) {
      (fromMarketplace && isMarketPlacePreview) ?
        deliveryDetailPopup.openModal('', 'create') :
        deliveryDetailPopup.openModal(currentDelivery?.slug, currentDelivery?.status)
      onCloseSideBar();
    }
  }

  const showDeliveryOrdersHandler = () => {
    parentComponentRef.setState({ showDeliveryOrdersSidebar: true, isOpenSidebar: false })
  }

  const openInviteGuestsPopup = () => {
    if (currentDelivery && shareMeetingPopupRef) {
      onCloseSideBar();
      shareMeetingPopupRef.openModal(currentDelivery)
    }
  }

  const { item_name, more_items = 0, quantity } = currentDelivery?.your_order || {}
  const userOrders = <div className=' d-flex justify-content-center flex-column'>
    <div className={`${currentDelivery?.your_order ? 'cursor-pointer' : 'cursor-default'} user-orders`}
      onClick={() => currentDelivery?.your_order && parentComponentRef.setState({ showDeliveryOrdersSidebar: true })}
    >
      <div className='d-flex justify-content-between'>
        <p className='text-nowrap delivery-details'>YOUR ORDER</p>
        {currentDelivery?.your_order &&
          <span className='ml-2 '>
            {more_items > 0 ? `${more_items} More ${more_items > 1 ? 'Items' : 'Item'}` : `Update or Cancel`}
            <i className='fas fa-external-link-alt ml-1'></i>
          </span>
        }
      </div>
      <div>
        {currentDelivery?.your_order ?
          <>
            <p className='user-first-order-name'>{quantity}
              <i className='fas fa-times mx-1' style={{ fontSize: 'smaller' }}></i>
              {item_name}
            </p>
            {!dayjs(currentDeliveryCutOff).isAfter(new Date()) && <p style={{ 'fontSize': '16px' }}>
              Group(s) :
              {uniqueGroups?.map((value: any, idx: any, arr: any) => {
                if (idx === arr.length - 1) {
                  return '\t\t' + value
                }
                return '\t\t' + value + ','
              })}
            </p>
            }
          </>
          :
          <p style={{ color: '#6e6e6e', fontSize: 18 }}>
            <img className='mr-2' width={27} height={27} src={ShoppingCart} alt='Empty Cart' />
            No Orders Placed
          </p>
        }
      </div>
    </div>
  </div>

  const inviteGuestsAndCancelButtonsClasses = `btn btn-sm text-nowrap ${cutOffPassed?.() ? 'disabled-opacity' : 'cursor-pointer'} `
  const inviteGuestsAndCancelButtons = fromDashboard ?
    <>
      <div className='delivery-actions d-flex mt-3'>
        {!isBuffetDelivery &&
          <button className={`invite-guests share-meeting-btn ${inviteGuestsAndCancelButtonsClasses}`}
            onClick={openInviteGuestsPopup}
            data-tip data-for={'invite-guest-tooltip'}
          >
            <i className='fas fa-share-square' ></i>
            {/* {i18n && i18n.t('Invite Guests')} */}
            {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
              <ReactTooltip place='top' type='dark' id='invite-guest-tooltip' effect='solid' className='react-tooltip' >
                <span>
                  Invite Guests
                </span>
              </ReactTooltip>
            }
          </button>
        }
        {isCompanyAdmin &&
          <button className={`btn-danger cancel-delivery ml-2 ${inviteGuestsAndCancelButtonsClasses}`}
            type='button'
            onClick={() => { onCloseSideBar(); cancelMeeting?.openModal() }}
            data-tip data-for={'cancel-delivery-tooltip'}

          >
            <i className='far fa-calendar-times'></i>
            {/* {i18n && i18n.t('Cancel Delivery')} */}
            {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
              <ReactTooltip place='top' type='dark' id='cancel-delivery-tooltip' effect='solid' className='react-tooltip' >
                <span>
                  Cancel Delivery
                </span>
              </ReactTooltip>
            }
          </button>
        }
        {
          (fromDashboard ? singleDateDeliveries : [currentDelivery as unknown as DeliveryOnSingleDate])?.map((delivery, index) =>
            <React.Fragment key={delivery?.id || index}>
              <div>
                <div key={delivery?.id}
                  //className={`d-flex align-items-center cursor-pointer all-deliveries-list-item p-0 ${index === 0 ? 'mb-3' : ''}`}
                  onClick={() => { deliveryChangeHandler(delivery, index) }}
                  data-tip data-for={'edit-scheduler-tooltip'}
                >
                  <div >
                    {loggedInUserCompany?.self_signup && index === 0 ? '' :
                      <div>
                        {isEditableDelivery && index === 0 &&
                          <div className={`edit-delivery cancel-delivery ml-2 btn btn-sm text-nowrap cursor-pointer`}
                          ><i className='fas fa-edit ml-2'></i></div>

                        }
                      </div>
                    }
                  </div>
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                    <ReactTooltip place='top' type='dark' id='edit-scheduler-tooltip' effect='solid' className='react-tooltip' >
                      <span>
                        Edit Delivery
                      </span>
                    </ReactTooltip>
                  }
                </div>
              </div>
            </React.Fragment>)}
      </div>
    </> : <></>

  const BackdropStyles = { inset: 0, zIndex: 6, transform: isOpenSidebar ? 'translateX(0)' : 'translateX(-100%)', backgroundColor: 'black', opacity: '0.35' }

  return <>
    <div className='position-fixed' onClick={onCloseSideBar} style={BackdropStyles}></div>
    <div className='left-sidebar' style={{ transform: isOpenSidebar ? 'translateX(0)' : 'translateX(-100%)' }}>

      {/* Schedule Marketplace Delivery In case Of From Dashboard Menu Detail Page   */}
      {isCompanyAdmin && fromDashboard && !isMarketPlacePreview &&
        <button className='btn btn-danger w-100 my-3' onClick={scheduleMarketplceHandler}>
          <i className='fas fa-plus-circle mr-1' aria-hidden='true'></i> New Delivery
        </button>}

      {/* Current Delivery Date  */}
      {loggedInUserCompany?.self_signup === false && !fromMarketplace &&
        <p className=' my-1' style={{ fontSize: 21 }}>
          {isInvitedMeeting ? 'Delivery' : 'Deliveries'} on {dayjs(currentDelivery?.delivery_at)?.format('ddd. MMM D')}
        </p>}

      {/* Single Delivery Details Card */}
      <div className='top-bar'>
        <div className='all-deliveries'>
          {(fromDashboard ? singleDateDeliveries : [currentDelivery as unknown as DeliveryOnSingleDate])?.map((delivery, index) =>
          <React.Fragment key={delivery?.id || index}>
              {index === 1 &&
                <p className='text-danger my-1' style={{ fontSize: 21 }}>More Deliveries</p>
              }
              <div className={`${loggedInUserCompany?.self_signup && index === 0 ?'' : 'current-delivery-item'}`}>

                {/* Delivery Name and Address Info */}
                
                <div key={delivery?.id}
                  className={`d-flex align-items-center cursor-pointer all-deliveries-list-item p-0 ${index === 0 ? 'mb-3' : ''}`}
                  onClick={() => { deliveryChangeHandler(delivery, index) }}
                >
                   <div style={{ width: '90%' }}>
                    <p>{loggedInUserCompany?.self_signup && index === 0 ? '' : delivery?.formatted_address}</p>
                    {loggedInUserCompany?.self_signup && index === 0 ? '' : 
                    fromMarketplace && <p>{delivery?.runningmenu_name}  at {dayjs(delivery?.delivery_at).format('hh:mma')}
                      {isEditableDelivery && index === 0 &&
                        <i className='fas fa-edit ml-2 delivery-edit-icon-color' ></i>
                      }
                    </p>
                    }
                    {isCompanyAdmin && <>
                    {loggedInUserCompany?.self_signup && index === 0 ? '' : 
                    <>
                      <p>Delivery Instructions</p>
                      <p className='m-0 delivery-instructions'>{delivery?.delivery_instructions || 'None'}</p>
                      </>
                    }
                    </>
                    }
                  </div>
                  {loggedInUserCompany?.self_signup && index === 0 ? '' :
                  <>
                   {/* {index > 0 ?
                    <img width={30} height={30} src={SliderChevronRight} alt='Current Delivery' /> :
                    <i className='fas fa-check-circle' style={{ fontSize: 30, color: '#28A745A1' }}></i>
                  } */}
                  </> 
                  }
                </div>

                {/*Delivery Order Details Section*/}
                {loggedInUserCompany?.self_signup === false && index === 0 && !fromMarketplace && <div>

                  {/*Delivery Order Details in case of both Buffet and Indiviual Delivery for Company Admins*/}
                  {isCompanyAdmin &&
                    <div>
                      <div className='d-flex delivery-details cursor-default'>
                        {!isBuffetDelivery ? <>
                          <div className='mx-1'>
                            <p className='text-nowrap mb-0'>ITEMS</p>
                            <div className='text-nowrap d-flex'>{currentDelivery?.total_quantity}
                              {currentDelivery?.total_quantity && <div><span data-tip
                                data-for='view-details' className="fas fa-eye ml-2 fa-sm cursor-pointer" onClick={showDeliveryOrdersHandler} style={{ color: "#167FFB" }}></span> {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                                  <ReactTooltip place='top' type='dark' id='view-details' effect='solid' className='react-tooltip'>
                                    <span>View Details</span>
                                  </ReactTooltip>
                                } </div>}
                            </div>
                          </div>
                          <div className='mx-2'>
                            <p className='text-nowrap mb-0'>AVG / MEAL</p>
                            <p className='text-nowrap'>${currentDelivery?.avg_per_meal ?
                              Number(currentDelivery?.avg_per_meal).toFixed(2) : '00.00'}</p>
                          </div>
                        </> :
                          <div className='mx-1 text-nowrap'>
                            <p className='text-nowrap mb-0'>PRICE PER HEAD</p>
                            <p className='d-flex'>${currentDelivery?.price_per_head ? Number(currentDelivery?.price_per_head).toFixed(2) : '0.00'}
                              {currentDelivery?.total_quantity && <div> <span data-tip
                                data-for='view-details' className="fas fa-eye ml-2 fa-sm cursor-pointer" onClick={showDeliveryOrdersHandler} style={{ color: "#167FFB" }}></span>
                                {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                                  <ReactTooltip place='top' type='dark' id='view-details' effect='solid' className='react-tooltip'>
                                    <span>View Details</span>
                                  </ReactTooltip>
                                }
                              </div>}
                            </p>
                          </div>
                        }
                        <div className='mx-1'>
                          <p className='text-nowrap mb-0'>TOTAL</p>
                          <p className='text-nowrap'>${currentDelivery?.order_total}
                            {/* <i className='fas fa-list-ul ml-2' style={{ color: '#0089ff' }}></i> */}
                          </p>
                        </div>
                      </div>
                      {/* {currentDelivery?.total_quantity ?
                        <p
                          style={{ textDecoration: 'underline', color: '#147BFC' }}
                          className='m-0 link-style'
                          onClick={showDeliveryOrdersHandler}>
                          View Details
                        </p> : ''
                      } */}
                    </div>
                  }

                  {/*Cancel and Invite Guests for Current Delivery for Company AdminsW*/}
                  {isCompanyAdmin && !isMarketPlacePreview &&
                    inviteGuestsAndCancelButtons
                  }

                  {/*Order Details in case of Indiviual Delivery for Company Users*/}
                  {!isCompanyAdmin && userOrders}

                  {/*Cancel and Invite Guests for Current Delivery for Company Managers*/}
                  {isCompanyManager &&
                    inviteGuestsAndCancelButtons
                  }

                </div>
                }
              </div>
            </React.Fragment>)}
        </div>
      </div>
    </div>
  </>
}

export default DeliveryDetailsForMobiles;