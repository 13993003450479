import { IS_LOADING, RESET_STATE, START_BUTTON_LOADING, STOP_BUTTON_LOADING } from '../Actions/types';
import { Loading } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: Loading = {
  buttonLoading: false,
  isLoading: false
}

const loadingReducer = (state: Loading = initialState, action: any): Loading => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case START_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: true
      };
    case STOP_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: false
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}


export default loadingReducer;