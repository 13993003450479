import React, { ChangeEvent, FC, useRef } from 'react';
import InputField from '../../../Common/InputField';
import { NewLocationModalErrors } from '../Modals/AddNewLocationModal';

type Props = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors?: NewLocationModalErrors
};

const AddNewDepartmentForm: FC<Props> = ({ handleChange, errors }) => {
  const budgetRef = useRef<HTMLInputElement>(null);
  return (
    <div id='main' className='container'>
      <div className='form-row'>
        <div className='col-11 m-auto text-center'>
          <p className='text-muted'>
            Use the form below to create a new department within your
            organization. Upon creation, the admins you invite below will be
            sent an email to setup their account.
          </p>
        </div>
      </div>
      <form noValidate className='text-left'>
        <div>
          <div className='form-row'>
            <InputField
              label='Department Name'
              type='text'
              name='name'
              onChange={handleChange}
              errors={errors}
              classnames='dept-name'
              isRequired
            />
            <div className='col-sm-6 company-required-field p-3 budged-slider-heign'>
              <h6 className='orderform-section-name float-left d-block mb-0'>
                <span>Department Budget</span>
              </h6>
              <h6 className='orderform-section-required muted float-right'>
                ${budgetRef?.current?.value ? budgetRef?.current?.value : 0}
              </h6>
              <div className='form-group mt-3'>
                <input
                  ref={budgetRef}
                  type='range'
                  className='form-control-range dept-budget'
                  id='mealBudget'
                  name='mealBudget'
                  min={0}
                  step={1}
                  onChange={handleChange}
                  defaultValue={0}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewDepartmentForm;