import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import isEmpty from '../../../Utilities/is_empty';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';
import { MarketPlaceState } from './Marketplace';
import { MarketPlaceCompanyAddress } from '../../../Interfaces/PayloadsAndResponses/Company';
import MenuDetailPageCarousel from '../Common/MenuDetailPageCarousel';
import { Slide } from 'pure-react-carousel';
// import SliderChevronLeft from '../SVGs/SliderChevronLeft.svg';
// import SliderChevronRight from '../SVGs/SliderChevronRight.svg';
import NewSliderChevronLeft from '../SVGs/arrow-back.svg';
import NewSliderChevronRight from '../SVGs/arrow-right.svg';
import store from '../../../store';
// import { GET_PUBLIC_ADDRESSES } from '../../../Actions/types';
import EnterprizeConfirmationPopUp from '../../../Common/Popups/EnterprizeConfirmationPopUp';
import EnterprizePlanViewerPopUp from '../../../Common/Popups/EnterprizePlanViewerPopUp';
import { GET_SELECTED_ADDRESS_ID } from '../../../Actions/types';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
// import MarketPlaceRatinPopUp from '../../../Common/Popups/MarketPlaceRatingPopUp';
import MarketPlaceFilterPopUp from '../../../Common/Popups/MarketPlaceFilterPopUp';
import { PlaceCartOrderPayload } from '../../../Interfaces/PayloadsAndResponses/Cart';
import { ReduxRootState, ServerCartData } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// import MarketPlaceRatingTopSection from '../../../Common/Popups/MarketPlaceRatingTopSection';
// import { MarketPlaceDeitariesIngredientsLoading } from '../../../Common/Loadings/MenuPage/MarketPlaceLoading';
import CustomPopUp from '../../../Common/Popups/CustomPopUp';
import { useSelector } from 'react-redux';
import Spinner from '../../../Common/Spinners/Spinner';
import { GenericTypeForMarketplaceAPIs } from '../../../Interfaces/PayloadsAndResponses/Marketplace';
import MarketplaceFilterDummyLogo from '../SVGs/marketplaceFiltersDummyLogo.svg'
// import FilterRestaurantsBy from '../../../Common/SortingDropdowns/FilterRestaurantsDropdown';

type FilterItem = {
  selected: boolean;
};

export type MarketplaceSearchBarProps = {
  startDate: Date | undefined;
  handleDateChange: (date: Date) => void;
  onChangeMealType: (meal_type: string) => void;
  meal_type: string;
  marketPlaceState: MarketPlaceState;
  //search on marketplace from frontEnd
  // selectSearchStatistics: (id: number | null, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean | undefined) => void;
  //search on marketplace from BackEnd
  selectSearchStatistics: (pill: any, key: 'cuisines' | 'dietaries' | 'ingredients', shouldClear?: boolean | undefined) => void;
  lessMoreHandler: (id: string, buttonType: string, divId: string, onPopup: boolean) => void;
  searchRestaurants: (shouldClear: boolean) => void;
  openModal: () => void;
  selectedCompanyAddress: MarketPlaceCompanyAddress | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  keyPressed: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  closeSearch: (searchName: string) => void;
  onPopup?: boolean;
  closePopup?: () => void;
  eRestaurantLoading?: boolean | null;
  heightController?: ((toggle: any) => void | undefined) | undefined;
  sortBy: (sortBy: string) => void;
  fromPublicMarketPlace?: boolean;
  resetAllFilters?: () => void;
  // price?: number;
  // avg_rating?: number;
  // onRatingChange?: (value: number) => void;
  // onAmountChange?: (value: number) => void;
  // handleSubmit?: (price: number, avg_rating: number) => void;
  restaurantHeaderCheck?: null | boolean;
  cart_local_data? : PlaceCartOrderPayload;
  cart_server_data? : ServerCartData;
  continueOrdering?: () => void;
  isFilterApply?: boolean;
  resetSearchBar?: () => void;
  marketPlaceHeader?: boolean;
  viewRestaurantMenu?: null | boolean;
};

const MarketplaceSearchBar: FC<MarketplaceSearchBarProps> = ({
  onPopup,
  closePopup,
  startDate,
  handleDateChange,
  onChangeMealType,
  meal_type,
  marketPlaceState,
  selectSearchStatistics,
  lessMoreHandler,
  searchRestaurants,
  openModal,
  selectedCompanyAddress,
  onChange,
  keyPressed,
  closeSearch,
  eRestaurantLoading,
  heightController,
  sortBy,
  fromPublicMarketPlace,
  resetAllFilters,
  // price,
  // avg_rating,
  // onRatingChange,
  // onAmountChange,
  // handleSubmit,
  restaurantHeaderCheck,
  cart_local_data,
  cart_server_data,
  continueOrdering,
  isFilterApply,
  resetSearchBar,
  marketPlaceHeader,
  viewRestaurantMenu
}) => {
  let addTimePeriod: 'day' = 'day';
  let datePickerId = onPopup ? 'popupDatepicker' : 'marketpaceDatepicker';
  let datePickerIconId = onPopup ? 'popupDatepickerIcon' : 'marketpaceDatepickerIcon';
  let cuisinesDivId = onPopup ? 'popupCuisines' : 'marketpaceCuisines';
  let dietariesDivId = onPopup ? 'popupDietaries' : 'marketpaceDietaries';
  // const userCompany = JSON.parse(localStorage.getItem('company') || '{}');
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const TimeZone = !isEmpty(user) ? (selectedCompanyAddress?.time_zone ? selectedCompanyAddress?.time_zone : user?.time_zone) : localAddress?.time_zone;
  const thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00')
  // let ingredientsDivId = onPopup ? 'popupIngredients' : 'marketpaceIngredients';
  const [divPositionCheck, setDivPositionCheck] = useState(false);
  const [enterprizePopUp, setEnterprizePopUp] = useState<boolean>(false);
  const [enterprizeConfirm, setEnterprizeConfirm] = useState<boolean>(false);
  //const [ratingPopUp, setRatingPopUp] = useState<boolean>(false)
  //const [filterPopUp, setFilterPopUp] = useState<boolean>(false)
  // const [showEnterprizeWidget, setShowEnterprizeWidget] = useState<boolean>(userCompany?.enterprise_status === 'not_converted' ? true : false)
  // const [enterprizeEmailSent, setEnterprizeEmailSent] = useState<boolean>(false)
  const stickyDivRef = React.useRef<HTMLDivElement>(null);
  const screenWidth = window.document.body.clientWidth;
  // const showCarouselButtons = screenWidth < 760 ? false : true; // becuase it value never use /Responsiveness issue /for mobile screen width /Modified by using Media Query
  const marketPlaceFiltersLoading = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions?.marketPlaceFiltersLoading)
  const marketPlaceCountLoading = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions);
  const marketplaceFilters: any =  useSelector((state: ReduxRootState) =>  state?.marketplaceSuggestions?.marketplace_filters);
  const { marketplaceCombosLoading, marketplaceFavoritesLoading,marketPlaceMostPopularRestaurantsLoading, marketPlaceNewRestaurantNearYouLoading } = marketPlaceCountLoading
  const loading = useSelector((state: ReduxRootState) => state?.loading?.isLoading)
  // const marketPlaceFilters = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions?.marketplace_filters)
  //Select Diataries, cuisines and ingregients loading
  const isFiltersLoading =
  marketplaceCombosLoading ||
  marketplaceFavoritesLoading ||
  marketPlaceMostPopularRestaurantsLoading ||
  marketPlaceNewRestaurantNearYouLoading ||
  marketPlaceFiltersLoading ||
  eRestaurantLoading || loading;

  const filterType: Record<string, string> = {
    dietary: 'dietaries',
    cuisine: 'cuisines',
    ingredient: 'ingredients',
  };

  const updateArrayAfterComparison = (originalArray: GenericTypeForMarketplaceAPIs[], ArrayObjectsToCompare: GenericTypeForMarketplaceAPIs[]) => {
    return originalArray?.map(item => {
      const matchingObject = ArrayObjectsToCompare?.find(compareObj => (
        compareObj?.id === item?.id && compareObj?.name === item?.name
      ));

      if (matchingObject) {
        return { ...item, selected: true };
      }

      return item;
    });
  }

  const marketplaceFiltersToBeRendered = updateArrayAfterComparison(marketplaceFilters, marketPlaceState?.marketplaceFilters);

  const excludeNames = [
    'Gluten-Free Upon Request',
    'Gluten Free Upon Request',
    'Nut-Free Upon Request',
    'Nut Free Upon Request',
    'Dairy-Free Upon Request',
    'Dairy Free Upon Request',
    'Vegan-Upon Request',
    'Vegan Upon Request',
  ];
  
  const filteredArray = marketplaceFiltersToBeRendered?.filter((filter:any) =>
    !excludeNames?.includes(filter?.name?.trim())
  );
  

  const filtersCombined: any = marketplaceFiltersToBeRendered ?? [];
  const checkResetFilter = filtersCombined?.find((filter : any) => filter?.selected === true)
  const [marketPlaceFilterPopUp, setMarketPlaceFilterPopUp] = useState<boolean>(false);
  const marketPlaceSuggestion = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions);
  const totalRestaurantsCount = (marketPlaceSuggestion?.marketplaceFavorites?.length ?? 0) + (marketPlaceSuggestion?.most_popular?.length ?? 0) + (marketPlaceSuggestion?.restaurants?.restaurants?.length ?? 0) + (marketPlaceSuggestion?.new_restaurants_near_you?.length ?? 0) + ((marketPlaceState?.mealType === "individual" ? (marketPlaceSuggestion?.marketplaceCombos?.length ?? 0) : 0))
  // const [marketPlaceRatingPopUp, setMarketPlaceRatingPopUp] = useState<boolean>(false)
  // const [marketPlaceTopRatingPopup, setMarketPlaceTopRatingPopup] = useState<boolean>(false)

  let lastIndexOfPreferedFilter = -1; // Initialize to -1 in case no 1 is found

  // Iterate through the array and find the index of the last 1
  marketplaceFiltersToBeRendered?.forEach((filterValue, index) => {
    if (filterValue?.preferred === 1) {
      lastIndexOfPreferedFilter = index;
    }
  });

  useEffect(()=>{
  const localAddress = JSON.parse(localStorage.getItem('address') || '{}');
  // const { latitude , longitude } = localAddress
  if(!isEmpty(localAddress)){
    // const adressPlaceId = getAddressStringByLatLong(latitude,longitude)
  }

  },[])

  const handleScroll = () => {
    if (!stickyDivRef.current) {
      return;
    }
    const top = stickyDivRef.current.getBoundingClientRect().top;
    if (top === 73) {
      setDivPositionCheck(true);
      setRestrictionToggle(false)
    } else {
      setDivPositionCheck(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [divPositionCheck]);
  const [datePickerRef, setDatePickerRef] = useState<any>();
  const [restrictionToggle, setRestrictionToggle] = useState<Boolean>(false)
  const DatePickerRefHandler = (calendar: any) => {
    if (isEmpty(calendar)) return;
    calendar.handleCalendarClickOutside = (e: any) => (e.target.id === datePickerId || e.target.id === datePickerIconId) ? e.preventDefault() : calendar.setOpen(false);
    setDatePickerRef(calendar)
  }
  const closeModal = () => {
    setEnterprizePopUp(false)
  }
  const closeEnterpriseConfirmationPopUp = () => {
    setEnterprizeConfirm(false)
  }
  const openConfirmEnterprizePopUp = () => {
    setEnterprizePopUp(false)
    setEnterprizeConfirm(true)
  }
  // const openEnterprizeModal = () => {
  //   setEnterprizePopUp(true)
  // }

  useEffect(() => {
    store.dispatch({
      type: GET_SELECTED_ADDRESS_ID,
      // payload: marketPlaceState?.selectedCompanyAddressID || selectedCompanyAddress?.id
      payload: selectedCompanyAddress?.id
    })
    // eslint-disable-next-line
  }, [selectedCompanyAddress?.id])
  // const selectedFilter = <span className='mr-2' style={{ width: '24px', height: '24px' }} ><i className='fas fa-check-circle'></i></span>
  //local filter clearing function un-used
  // const clearFilters = () => {
  //   selectSearchStatistics(null, 'cuisines', true);
  //   selectSearchStatistics(null, 'dietaries', true);
  //   selectSearchStatistics(null, 'ingredients', true);
  // };

  const selectedCount = (filterArray: FilterItem[]) => {
    if (!Array?.isArray(filterArray)) return 0;
    return filterArray?.filter((item) => item?.selected).length;
  }
  //SelectedFilterCount of RestaurantsData
  const SelectedFilterCount = selectedCount(filtersCombined)

  const { noOfSlidesForMarketPlaceFilters} = getResponsiveNoOfSlides();

  // const handleRatingPopUpClose = () => {
  //   setMarketPlaceRatingPopUp(false);
  // };

  // const handleTopRatingPopUpClose = () => {
  //   setMarketPlaceTopRatingPopup(false);
  // };

  const handleFilterAppliedPopup = () => {
    setMarketPlaceFilterPopUp(false)
  }

  return (
    <>
      {/* When its sticky on top then this gradient is showing */}
      <div className="container-fluid ">
      <div className="container px-0">
      <div ref={stickyDivRef} className={`col-lg-12 d-flex flex-wrap align-items-center px-0 py-2 py-md-3 px-sm-0 market-place-filters-container newdeliverypage-sticky   ${divPositionCheck && 'hit-top bg-carousel'} `} style={viewRestaurantMenu ? { gap: '20px' } : {}}>
      <div className={`px-0 ${marketPlaceHeader ? '' : ' col-sm-12 col-md-12 col-lg-4'} d-flex pb-1 pb-sm-0`} onClick={() => { openModal(); onPopup === true && closePopup && closePopup() }}>
              {/* <p className='mb-0 fw-normal delivery-lable py-2 d-none d-md-block'>DELIVERING TO:</p> */}
              <div className='main-custom-options main-custom-options-marketplace d-flex align-items-center'>
                <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 25.062 33">
                    <path id="location-dot-thin_-_Outline" data-name="location-dot-thin - Outline" d="M12.031-.5A12.533,12.533,0,0,1,24.562,12.031c0,2.464-1.394,5.974-4.142,10.432a96.042,96.042,0,0,1-6.514,9.129,2.393,2.393,0,0,1-3.75,0C7.658,28.521-.5,18.023-.5,12.031A12.531,12.531,0,0,1,12.031-.5ZM14.473.76A11.533,11.533,0,0,0,.5,12.031c0,2.253,1.374,5.679,3.972,9.908a90.26,90.26,0,0,0,6.462,9.026,1.393,1.393,0,0,0,2.193,0c3.137-3.926,10.436-13.633,10.436-18.937a11.534,11.534,0,0,0-.26-2.442,11.635,11.635,0,0,1,.258,2.442,12.385,12.385,0,0,1-1.155,4.555,40.474,40.474,0,0,1-2.836,5.351,104.843,104.843,0,0,1-6.445,9.029,1.389,1.389,0,0,1-2.183,0A104.616,104.616,0,0,1,4.5,21.94a40.169,40.169,0,0,1-2.838-5.353A12.321,12.321,0,0,1,.5,12.031,11.529,11.529,0,0,1,12.031.5,11.635,11.635,0,0,1,14.473.76ZM12.031,30.5a.4.4,0,0,0,.313-.157,103.758,103.758,0,0,0,6.375-8.931A39.472,39.472,0,0,0,21.484,16.2a11.448,11.448,0,0,0,1.076-4.166,10.529,10.529,0,0,0-21.057,0A11.387,11.387,0,0,0,2.58,16.2a39.172,39.172,0,0,0,2.766,5.214,103.531,103.531,0,0,0,6.374,8.93A.4.4,0,0,0,12.031,30.5Zm0-23.979a5.513,5.513,0,1,1-5.513,5.513A5.519,5.519,0,0,1,12.031,6.518Zm0,10.026a4.513,4.513,0,1,0-4.513-4.513A4.518,4.518,0,0,0,12.031,16.544Zm0-9.023a4.51,4.51,0,1,1-4.51,4.51A4.515,4.515,0,0,1,12.031,7.521Zm0,8.021a3.51,3.51,0,1,0-3.51-3.51A3.514,3.514,0,0,0,12.031,15.542Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                  </svg>
                </span>
                {!isEmpty(localAddress) ?
                  <input
                    type='text'
                    className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                    id='address'
                    name='selectedCompanyAddress'
                    value={
                      localAddress?.formatted_address || localAddress?.address_line || ''
                      //marketPlaceState?.selectedAddressDetail?.formatted_address || selectedCompanyAddress?.formatted_address || ''
                    }
                    readOnly
                  />
                :
                  <input
                    type='text'
                    className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                    id='address'
                    name='selectedCompanyAddress'
                    value={
                      // marketPlaceState?.selectedAddressDetail?.address_line || selectedCompanyAddress?.address_line || ''
                      selectedCompanyAddress?.formatted_address || ''

                    }
                    readOnly
                  />
                }
                {/* {userCompany?.self_signup ?
                  <input
                    type='text'
                    className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                    id='address'
                    name='selectedCompanyAddress'
                    value={
                      // marketPlaceState?.selectedAddressDetail?.address_line || selectedCompanyAddress?.address_line || ''
                      marketPlaceState?.selectedAddressDetail?.formatted_address || selectedCompanyAddress?.formatted_address || ''

                    }
                    readOnly
                  />
                  :
                  <input
                    type='text'
                    className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0'
                    id='address'
                    name='selectedCompanyAddress'
                    value={
                      localAddress?.formatted_address || localAddress?.address_line || marketPlaceState?.selectedAddressDetail?.formatted_address || selectedCompanyAddress?.formatted_address || ''
                    }
                    readOnly
                  />
                } */}
                <i className='fas fa-chevron-down mx-1 px-1' style={{ lineHeight: 'inherit' }}></i>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 pt-2 py-md-2 py-lg-0 px-0 px-ml-3 d-flex align-items-center justify-content-between">
              <div id={datePickerId} className={`px-0 position-relative d-flex align-items-center`} onClick={() => datePickerRef && !datePickerRef?.state?.open && datePickerRef?.setOpen(true)}>
                {/* <i className='far fa-calendar-times mx-1'></i> */}
                {/* <p className='mb-0 fw-normal delivery-lable py-2 pl-0 pl-lg-4 d-none d-md-block'>WHEN:</p> */}
                <span className='icons-wrapper mx-0 ml-md-0  mr-lg-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 25 28.429">
                    <path id="calendar-thin_-_Outline" data-name="calendar-thin - Outline" d="M6.429-.5a.93.93,0,0,1,.929.929v2.5h9.286V.429a.929.929,0,1,1,1.857,0v2.5h2.071A3.933,3.933,0,0,1,24.5,6.857V24a3.933,3.933,0,0,1-3.929,3.929H3.429A3.933,3.933,0,0,1-.5,24V6.857A3.933,3.933,0,0,1,3.429,2.929H5.5V.429A.93.93,0,0,1,6.429-.5ZM5.5,4.786H3.429A2.074,2.074,0,0,0,1.357,6.857V8.929H22.643V6.857a2.074,2.074,0,0,0-2.071-2.071H18.5V6.429a.929.929,0,1,1-1.857,0V4.786H7.357V6.429a.929.929,0,0,1-1.857,0Zm17.143,6H1.357V24a2.074,2.074,0,0,0,2.071,2.071H20.571A2.074,2.074,0,0,0,22.643,24Z" transform="translate(0.5 0.5)" fill="#dc3644" />
                  </svg>
                </span>
                <DatePicker
                  ref={DatePickerRefHandler}
                  selected={startDate}
                  onChange={d => { 
                    handleDateChange(d as Date); 
                    datePickerRef && datePickerRef?.setOpen(false); 
                    // resetSearchBar && resetSearchBar()
                  }}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  timeCaption='time'
                  dateFormat={`EEEE, LLL d 'at' h:mm aa`}
                  className='marketplace-time-picker-input'
                  //minDate={new Date()}
                  minDate={dayjs((new Date()).toString()).add((dayjs(new Date(new Date().toLocaleString("en-US", { timeZone: TimeZone }))).isAfter(new Date(thresholdDateTime))? 2 : 1), 'day').toDate()}
                  maxDate={dayjs((new Date()).toString()).add(120, addTimePeriod).toDate()}
                />
                <i id={datePickerIconId} className='fas fa-chevron-down mx-1' style={{ lineHeight: 'inherit' }}></i>
              </div>
              <h4 className='m-0 cursor-pointer d-none'>
                <i className="fas fa-info-circle" style={{color:'#A8A8AA'}} title="Defining what is Cutoff Time"></i>
              </h4>
            </div>
            {restaurantHeaderCheck ? <div className="col-lg-4 col-sm-12 pt-2 pt-md-0 px-0 d-flex align-items-center">
              {(isFilterApply || SelectedFilterCount !== 0) ? <div>
                <button className='btn btn-link text-nowrap' onClick={resetAllFilters}>Reset Filters</button>
              </div> :
                <></>
              }
              <div>
                {/* <button className='btn btn-zink rounded-pill mr-2 text-nowrap d-flex align-items-center'
                onClick={()=> {setMarketPlaceFilterPopUp(false); setMarketPlaceTopRatingPopup(!marketPlaceTopRatingPopup)}}
                >
                {avg_rating} <i className='fas fa-star text-gray-800 mr-2 ml-1' style={{fontSize:'13px'}}></i> $ {price}
                <div className='ml-2'><i className='fas fa-solid fa-chevron-down'></i></div>
                </button> */}
                {/* {marketPlaceTopRatingPopup && <div>
                  <CustomPopUp
                    backdropStyles={{ opacity: 0, cursor: 'default' }}
                    onPopUpClose={handleTopRatingPopUpClose}
                    popUpClassName='order-history-range-picker'
                    popUpBodyStyles={{ left: 0 }}
                    allowScrolling={true}
                  >
                    <div
                      style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
                      className='bg-white order-restaurant-selector'
                    >
                      <MarketPlaceRatingTopSection
                        fromMarketPlace
                        modalIsOpen={marketPlaceTopRatingPopup}
                        closeModal={() => setMarketPlaceTopRatingPopup(false)}
                        price={price}
                        avg_rating={avg_rating}
                        onRatingChange={onRatingChange}
                        onAmountChange={onAmountChange}
                        handleSubmit={handleSubmit}
                        isFilterApply={isFilterApply}
                      />
                    </div>
                  </CustomPopUp>
                </div>} */}
                
                {/* <a className="btn btn-secondary dropdown-toggle rounded-pill" role="button" data-toggle="dropdown" aria-expanded="false">
                  4<i className="fas fa-star text-gray-800" aria-hidden="true"></i> $25
                </a> */}
                {/* {ratingPopUp && <div className="">
                    <div className="wrapper px-4">
                      <div className="filter-header pt-4 pb-2">
                        <h5 className='m-0 fw-600' style={{color:"#1E4353"}}>Rating</h5>
                      </div>
                      <div className="pills-section">
                        <div className="">
                          <p className='text-gray-100 fs-16 m-0'>4 & Above</p>
                        </div>
                        <div className="d-flex mt-2">
                          <span className="rating-wrapper d-flex flex-column justify-content-center align-items-center">
                            <i className="fas fa-star text-gray-800" aria-hidden="true"></i>
                            <p className='m-0 mt-2 text-gray-100'>3</p>
                          </span>
                          <i className="seprator-line"></i>
                          <span className="rating-wrapper d-flex flex-column justify-content-center align-items-center">
                            <i className="fas fa-star text-gray-800" aria-hidden="true"></i>
                            <p className='m-0 mt-2 text-gray-100'>3.5</p>
                          </span>
                          <i className="seprator-line"></i>
                          <span className="rating-wrapper d-flex flex-column justify-content-center align-items-center">
                            <i className="fas fa-star fa-lg marketplace-combo" aria-hidden="true"></i>
                            <p className='m-0 mt-2 text-gray-100'>4</p>
                          </span>
                          <i className="seprator-line septator-line-yellow"></i>
                          <span className="rating-wrapper d-flex flex-column justify-content-center align-items-center">
                            <i className="fas fa-star marketplace-combo" aria-hidden="true"></i>
                            <p className='m-0 mt-2 text-gray-100'>4.5</p>
                          </span>
                          <i className="seprator-line septator-line-yellow"></i>
                          <span className="rating-wrapper d-flex flex-column justify-content-center align-items-center">
                            <i className="fas fa-star marketplace-combo" aria-hidden="true"></i>
                            <p className='m-0 mt-2 text-gray-100'>5</p>
                          </span>
                        </div>
                        <div className="pt-4">
                          <h5 className='m-0 fw-600' style={{color:"#1E4353"}}>Avg. Price</h5>
                        </div>
                        <div>
                          <input type="range" id="volume" name="volume"
                                min="0" max="11"/>
                          
                        </div>

                      </div>
                      <div className="filter-footer py-4 d-flex justify-content-end">
                        <button className='btn btn-light rounded-pill px-4 py-2 font-weight-bold'>Cancel</button>
                        <button className='btn btn-danger rounded-pill px-4 py-2 ml-2 font-weight-bold'>Apply</button>
                      </div>
                    </div>
                </div>} */}
              </div>
              {!marketPlaceHeader && <div className="ml-0">
                <div className='position-relative'>
                  <button className='btn btn-zink rounded-pill mr-3 text-nowrap d-flex align-items-center ml-1'
                    onClick={() => {setMarketPlaceFilterPopUp(!marketPlaceFilterPopUp) }}>
                    {SelectedFilterCount ? `${SelectedFilterCount} Filter${SelectedFilterCount > 1 ? ('s') : ''} Applied` : 'No Filter Applied'}
                    <div className='ml-2'>
                      <i className='fas fa-solid fa-chevron-down'></i>
                    </div>
                  </button>
                {marketPlaceFilterPopUp && <div className="col-lg-4 px-0">
                  <CustomPopUp
                    backdropStyles={{ opacity: 0, cursor: 'default' }}
                    onPopUpClose={handleFilterAppliedPopup}
                    popUpClassName='order-history-range-picker'
                    popUpBodyStyles={{ left: 0 }}
                    allowScrolling={true}
                  >
                    <div
                      style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
                      className='bg-white order-restaurant-selector'
                    >
                      <MarketPlaceFilterPopUp
                        marketPlaceFiltersState={marketPlaceState?.marketplaceFilters}
                        modalIsOpen={marketPlaceFilterPopUp}
                        closeModal={() => setMarketPlaceFilterPopUp(false)}
                        resetAllFilters={resetAllFilters}
                        selectSearchStatistics={selectSearchStatistics}
                        eRestaurantLoading={eRestaurantLoading}
                      />
                    </div>
                  </CustomPopUp>
                  </div>
                  }
                
                </div>
                {/* {filterPopUp && <div className="">
                    <div className="wrapper px-4">
                      <div className="filter-header py-3">
                      <h4 className='m-0 fw-600'>Filters</h4>
                      </div>
                      <div className="pills-section d-flex flex-wrap py-3">
                        <button type="button" className="cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm btn-info" name="ingredients[]" value="2" id="autoCuisine0">
                          <div className="d-flex dietary-logo">
                            <div className="mr-2" style={{width: "24px", height: "24px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.957" viewBox="0 0 24 18.957">
                                  <g id="chicken" transform="translate(-80.252 -120.551)">
                                    <g id="izliL3.tif" transform="translate(80.252 120.551)">
                                      <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                                        <path id="Path_6" data-name="Path 6" d="M208.406,148.823c.084-.442.141-.892.256-1.326a8.111,8.111,0,0,1,2.667-4.069,15.676,15.676,0,0,1,4.832-2.759,20.21,20.21,0,0,1,4.4-1.149,8.3,8.3,0,0,1,3.026.124,5.935,5.935,0,0,1,.856.341l.01.1c-.56.333-1.112.682-1.682,1a6.886,6.886,0,0,1-2.855.71,4.5,4.5,0,0,0-4.021,3.916,4.547,4.547,0,1,0,9.011,1.234,16.358,16.358,0,0,1,.222-1.734,8.616,8.616,0,0,1,1.609-3.055c.023.046.042.087.063.126a25.851,25.851,0,0,1,2.2,5,6.889,6.889,0,0,1,.287,1.654,3.352,3.352,0,0,0,1.731,2.921,8.281,8.281,0,0,0,1.182.555c.2.08.259.2.152.349a3.279,3.279,0,0,1-.606.724,3.787,3.787,0,0,1-2.212.721c-.483.022-.968.012-1.452.012q-6.783,0-13.567-.006a7.684,7.684,0,0,1-3.853-.911,4.172,4.172,0,0,1-2.219-3.252.511.511,0,0,0-.038-.107Z" transform="translate(-208.406 -135.268)" fill="#d77d35"></path>
                                        <path id="Path_7" data-name="Path 7" d="M218.734,146.36a3.727,3.727,0,0,1,3.686-3.74,6.8,6.8,0,0,0,3.289-1.033,14.977,14.977,0,0,0,2.568-1.873.549.549,0,0,1,.8.089.522.522,0,0,1-.027.722,14.954,14.954,0,0,0-2,2.845,7.25,7.25,0,0,0-.892,3.213,3.678,3.678,0,0,1-3.011,3.446A3.723,3.723,0,0,1,218.734,146.36Z" transform="translate(-210.456 -135.296)" fill="#e78b38"></path>
                                        <path id="Path_8" data-name="Path 8" d="M232.734,138.233l-.608-.64c.258-.248.587-.576.932-.887a1.081,1.081,0,0,0,.377-.913c-.014-.28-.01-.562,0-.842a.684.684,0,0,1,.567-.7.7.7,0,0,1,.825.45,1.565,1.565,0,0,0,.838.837.7.7,0,0,1,.446.785.687.687,0,0,1-.716.605c-.3.007-.608.006-.912,0a.972.972,0,0,0-.753.309C233.385,137.6,233.028,137.941,232.734,138.233Z" transform="translate(-213.114 -134.236)" fill="#8b9898"></path>
                                        <path id="Path_9" data-name="Path 9" d="M223.854,137.674c.551-.528,1.071-1.021,1.584-1.52.271-.262.271-.352.041-.652a.725.725,0,0,1,.091-1.027.655.655,0,0,1,.98.147,1.438,1.438,0,0,0,1.125.575.581.581,0,0,1,.585.527.5.5,0,0,1-.5.536,1.091,1.091,0,0,1-.437-.037.385.385,0,0,0-.437.128c-.352.4-.709.789-1.055,1.19a.362.362,0,0,1-.319.137C224.991,137.668,224.469,137.674,223.854,137.674Z" transform="translate(-211.472 -134.251)" fill="#8b9898"></path>
                                      </g>
                                    </g>
                                  </g>
                              </svg>
                            </div>
                            <p>Chicken</p>
                          </div>
                        </button>
                        <button type="button" className="cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm btn-info" name="ingredients[]" value="2" id="autoCuisine0">
                          <div className="d-flex dietary-logo">
                            <div className="mr-2" style={{width: "24px", height: "24px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.957" viewBox="0 0 24 18.957">
                                  <g id="chicken" transform="translate(-80.252 -120.551)">
                                    <g id="izliL3.tif" transform="translate(80.252 120.551)">
                                      <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                                        <path id="Path_6" data-name="Path 6" d="M208.406,148.823c.084-.442.141-.892.256-1.326a8.111,8.111,0,0,1,2.667-4.069,15.676,15.676,0,0,1,4.832-2.759,20.21,20.21,0,0,1,4.4-1.149,8.3,8.3,0,0,1,3.026.124,5.935,5.935,0,0,1,.856.341l.01.1c-.56.333-1.112.682-1.682,1a6.886,6.886,0,0,1-2.855.71,4.5,4.5,0,0,0-4.021,3.916,4.547,4.547,0,1,0,9.011,1.234,16.358,16.358,0,0,1,.222-1.734,8.616,8.616,0,0,1,1.609-3.055c.023.046.042.087.063.126a25.851,25.851,0,0,1,2.2,5,6.889,6.889,0,0,1,.287,1.654,3.352,3.352,0,0,0,1.731,2.921,8.281,8.281,0,0,0,1.182.555c.2.08.259.2.152.349a3.279,3.279,0,0,1-.606.724,3.787,3.787,0,0,1-2.212.721c-.483.022-.968.012-1.452.012q-6.783,0-13.567-.006a7.684,7.684,0,0,1-3.853-.911,4.172,4.172,0,0,1-2.219-3.252.511.511,0,0,0-.038-.107Z" transform="translate(-208.406 -135.268)" fill="#d77d35"></path>
                                        <path id="Path_7" data-name="Path 7" d="M218.734,146.36a3.727,3.727,0,0,1,3.686-3.74,6.8,6.8,0,0,0,3.289-1.033,14.977,14.977,0,0,0,2.568-1.873.549.549,0,0,1,.8.089.522.522,0,0,1-.027.722,14.954,14.954,0,0,0-2,2.845,7.25,7.25,0,0,0-.892,3.213,3.678,3.678,0,0,1-3.011,3.446A3.723,3.723,0,0,1,218.734,146.36Z" transform="translate(-210.456 -135.296)" fill="#e78b38"></path>
                                        <path id="Path_8" data-name="Path 8" d="M232.734,138.233l-.608-.64c.258-.248.587-.576.932-.887a1.081,1.081,0,0,0,.377-.913c-.014-.28-.01-.562,0-.842a.684.684,0,0,1,.567-.7.7.7,0,0,1,.825.45,1.565,1.565,0,0,0,.838.837.7.7,0,0,1,.446.785.687.687,0,0,1-.716.605c-.3.007-.608.006-.912,0a.972.972,0,0,0-.753.309C233.385,137.6,233.028,137.941,232.734,138.233Z" transform="translate(-213.114 -134.236)" fill="#8b9898"></path>
                                        <path id="Path_9" data-name="Path 9" d="M223.854,137.674c.551-.528,1.071-1.021,1.584-1.52.271-.262.271-.352.041-.652a.725.725,0,0,1,.091-1.027.655.655,0,0,1,.98.147,1.438,1.438,0,0,0,1.125.575.581.581,0,0,1,.585.527.5.5,0,0,1-.5.536,1.091,1.091,0,0,1-.437-.037.385.385,0,0,0-.437.128c-.352.4-.709.789-1.055,1.19a.362.362,0,0,1-.319.137C224.991,137.668,224.469,137.674,223.854,137.674Z" transform="translate(-211.472 -134.251)" fill="#8b9898"></path>
                                      </g>
                                    </g>
                                  </g>
                              </svg>
                            </div>
                            <p>Vegeterians</p>
                          </div>
                        </button>
                        <button type="button" className="cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm btn-info" name="ingredients[]" value="2" id="autoCuisine0">
                          <div className="d-flex dietary-logo">
                            <p>Keto</p>
                          </div>
                        </button>
                        <button type="button" className="cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm btn-info" name="ingredients[]" value="2" id="autoCuisine0">
                          <div className="d-flex dietary-logo">
                            <div className="mr-2" style={{width: "24px", height: "24px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.957" viewBox="0 0 24 18.957">
                                  <g id="chicken" transform="translate(-80.252 -120.551)">
                                    <g id="izliL3.tif" transform="translate(80.252 120.551)">
                                      <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                                        <path id="Path_6" data-name="Path 6" d="M208.406,148.823c.084-.442.141-.892.256-1.326a8.111,8.111,0,0,1,2.667-4.069,15.676,15.676,0,0,1,4.832-2.759,20.21,20.21,0,0,1,4.4-1.149,8.3,8.3,0,0,1,3.026.124,5.935,5.935,0,0,1,.856.341l.01.1c-.56.333-1.112.682-1.682,1a6.886,6.886,0,0,1-2.855.71,4.5,4.5,0,0,0-4.021,3.916,4.547,4.547,0,1,0,9.011,1.234,16.358,16.358,0,0,1,.222-1.734,8.616,8.616,0,0,1,1.609-3.055c.023.046.042.087.063.126a25.851,25.851,0,0,1,2.2,5,6.889,6.889,0,0,1,.287,1.654,3.352,3.352,0,0,0,1.731,2.921,8.281,8.281,0,0,0,1.182.555c.2.08.259.2.152.349a3.279,3.279,0,0,1-.606.724,3.787,3.787,0,0,1-2.212.721c-.483.022-.968.012-1.452.012q-6.783,0-13.567-.006a7.684,7.684,0,0,1-3.853-.911,4.172,4.172,0,0,1-2.219-3.252.511.511,0,0,0-.038-.107Z" transform="translate(-208.406 -135.268)" fill="#d77d35"></path>
                                        <path id="Path_7" data-name="Path 7" d="M218.734,146.36a3.727,3.727,0,0,1,3.686-3.74,6.8,6.8,0,0,0,3.289-1.033,14.977,14.977,0,0,0,2.568-1.873.549.549,0,0,1,.8.089.522.522,0,0,1-.027.722,14.954,14.954,0,0,0-2,2.845,7.25,7.25,0,0,0-.892,3.213,3.678,3.678,0,0,1-3.011,3.446A3.723,3.723,0,0,1,218.734,146.36Z" transform="translate(-210.456 -135.296)" fill="#e78b38"></path>
                                        <path id="Path_8" data-name="Path 8" d="M232.734,138.233l-.608-.64c.258-.248.587-.576.932-.887a1.081,1.081,0,0,0,.377-.913c-.014-.28-.01-.562,0-.842a.684.684,0,0,1,.567-.7.7.7,0,0,1,.825.45,1.565,1.565,0,0,0,.838.837.7.7,0,0,1,.446.785.687.687,0,0,1-.716.605c-.3.007-.608.006-.912,0a.972.972,0,0,0-.753.309C233.385,137.6,233.028,137.941,232.734,138.233Z" transform="translate(-213.114 -134.236)" fill="#8b9898"></path>
                                        <path id="Path_9" data-name="Path 9" d="M223.854,137.674c.551-.528,1.071-1.021,1.584-1.52.271-.262.271-.352.041-.652a.725.725,0,0,1,.091-1.027.655.655,0,0,1,.98.147,1.438,1.438,0,0,0,1.125.575.581.581,0,0,1,.585.527.5.5,0,0,1-.5.536,1.091,1.091,0,0,1-.437-.037.385.385,0,0,0-.437.128c-.352.4-.709.789-1.055,1.19a.362.362,0,0,1-.319.137C224.991,137.668,224.469,137.674,223.854,137.674Z" transform="translate(-211.472 -134.251)" fill="#8b9898"></path>
                                      </g>
                                    </g>
                                  </g>
                              </svg>
                            </div>
                            <p>Biodegradeable</p>
                          </div>
                        </button>
                      </div>
                      <div className="filter-footer py-4 d-flex justify-content-end">
                        <button className='btn btn-light rounded-pill px-4 py-2 font-weight-bold'>Reset</button>
                        <button className='btn btn-danger rounded-pill px-4 py-2 ml-2 font-weight-bold'>View Results</button>
                      </div>
                    </div>
                </div>} */}
              </div>}
              {viewRestaurantMenu ? <></> : <>
                {((!marketPlaceHeader && user?.phone_number && cart_server_data?.slug && cart_server_data?.delivery_at) ||
                  (fromPublicMarketPlace && !isEmpty(cart_local_data))
                ) &&
                  <div className='col-auto pt-0 pt-md-2 pl-0 cursor-pointer' onClick={() => continueOrdering && continueOrdering()}>
                    <button className='btn btn-custome-white m-0 text-danger '>
                      {<div><img src="/imgs/SVGs/forward.svg" alt="Continue Your Order"></img></div>}
                    </button>
                  </div>
                }
              </>}
            </div> :
              <></>
            }

            {viewRestaurantMenu ? <></> :
              <>
                {((!marketPlaceHeader && user?.phone_number && cart_server_data?.slug && cart_server_data?.delivery_at) ||
                  (fromPublicMarketPlace && !isEmpty(cart_local_data))
                ) &&
                  <div className='col-auto pt-0 pt-md-2 pl-0 cursor-pointer' onClick={() => continueOrdering && continueOrdering()}>
                    <button className='btn btn-custome-white m-0 text-danger pt-0 '>
                      {!restaurantHeaderCheck ? <div>Continue Your Order
                        <i className='text-danger ml-2 fas fa-chevron-right cursor-pointer' /></div> : ''}
                    </button>
                  </div>
                }
              </>
            }
            {/* {!restaurantHeaderCheck && marketPlaceRatingPopUp && <div className="col-lg-6 px-0">
              <CustomPopUp
                backdropStyles={{ opacity: 0, cursor: 'default' }}
                onPopUpClose={handleRatingPopUpClose}
                popUpClassName='order-history-range-picker'
                popUpBodyStyles={{ left: 0 }}
                allowScrolling={true}
              >
                <div
                  style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
                  className='bg-white order-restaurant-selector'
                >
                <MarketPlaceRatinPopUp
                  modalIsOpen={marketPlaceRatingPopUp}
                  closeModal={() => setMarketPlaceRatingPopUp(false)}
                  price={price}
                  avg_rating={avg_rating}
                  onRatingChange={onRatingChange}
                  onAmountChange={onAmountChange}
                  handleSubmit={handleSubmit}
                />
              </div>
              </CustomPopUp>
            </div>
            } */}
             
          </div>
        </div>
      </div>
      {/* /  API Skeleton for Filter Pills Marketplace and its controller  / */}
      {marketPlaceHeader ? <></>: <>
      {marketPlaceFiltersLoading ? <div className='container p-0'>
        <MenuDetailPageCarousel
          totalSlides={10}
          visibleSlides={screenWidth < 760 ? (noOfSlidesForMarketPlaceFilters) : 7}
          showCarouselButtons={false}
          NextButtonContent={<img alt='Next' src={NewSliderChevronRight} />}
          BackButtonContent={<img alt='Back' src={NewSliderChevronLeft} />}
          NextButtonStyles={{ top: '44%', right: '5px', height: '24px' }}
          BackButtonStyles={{ top: '44%', left: '5px', zIndex: '10', height: '24px' }}
          sliderTrayClassName='mx-auto'
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((filterpill, index) => {
            return <Slide key={filterpill + index} index={filterpill + index} className='p-0 mr-2'>
              <Skeleton className='rounded-pill' height={40} />
            </Slide>
          })}
          </MenuDetailPageCarousel>
        </div> : <>
          {!restaurantHeaderCheck && !viewRestaurantMenu && <div className='carousel-background'>
        <div className="bg-gray-900 container p-0">
        <div className={`d-flex align-items-center container px-sm-0 bg-gray-900 market-place-filters-container market-place-filters-container-carousel-scrollable hit-top carousel-responsiveness`}>
        {/* <div>
        <button className='btn btn-zink text-nowrap rounded-pill px-3' onClick={()=> {setMarketPlaceRatingPopUp(!marketPlaceRatingPopUp); setMarketPlaceTopRatingPopup(false)}}>Ratings & Pricing&nbsp;<i className="fas fa-chevron-down ml-2 fa-xs"></i></button>
        </div> */}
        <div className={`w-100 picking-point-order overflow-hidden ml-0 ml-sm-2 ${divPositionCheck && ''}`}>
          <MenuDetailPageCarousel
            totalSlides={filteredArray?.length - 3 ?? 0}
            visibleSlides={screenWidth < 760 ? noOfSlidesForMarketPlaceFilters : 9}
            showCarouselButtons={(filteredArray?.length ?? 0) > (screenWidth < 760 ? noOfSlidesForMarketPlaceFilters : 9)}// Only show if more items than visible 
            step={5}
            NextButtonContent={<img alt='Next' src={NewSliderChevronRight} />}
            BackButtonContent={<img alt='Back' src={NewSliderChevronLeft} />}
            NextButtonStyles={{ top: '44%', right: '-5px', height: '24px' }}
            BackButtonStyles={{ top: '44%', left: '-5px', zIndex: '10', height: '24px' }}
            sliderTrayClassName='mx-auto'
          >
            {filteredArray?.map((filters: any, index: number) => {
              const updatedFilterType = filterType[filters?.filter_type] || filters?.filter_type;
            return (
                (filters?.name?.trim() === 'Gluten Free Upon Request') ||
                (filters?.name?.trim() === 'Dairy Free Upon Request') ||
                (filters?.name?.trim() === 'Vegan Upon Request') ||
                (filters?.name?.trim() === 'Gluten-Free Upon Request') ||
                (filters?.name?.trim() === 'Nut-Free Upon Request') ||
                (filters?.name?.trim() === 'Dairy-Free Upon Request') ||
                (filters?.name?.trim() === 'Vegan-Upon Request') ? ''
                :
                <Slide key={`restaurant-menu-filter-${index}`} index={index} className='py-0' style={{ minWidth: '80px' }}>
                  <div data-tip
                    data-for={`restaurant-menu-filter-${index}`}
                    style={{ position: 'relative' }}
                  >
                    <div className='my-auto mr-2' style={filters?.name?.length < 14 ? { width: '80px' } : filters?.name?.length < 20 ? { width: '120px' } : { width: '150px' }}>
                      <React.Fragment key={index}>
                        {
                        isFiltersLoading ? <button type='button' className={filters?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm py-0' : 'cuisines-diet-pills btn btn-sm py-0'} name='updatedFilterType[]'
                          value='2' id='autoCuisine0'  ><Skeleton height={40} width={40} />
                        </button> :
                         <div className='d-flex justify-content-center align-items-center'>
                           <button type='button' className={`hover-element-effects ${filters?.selected === true ? 'cuisine-diet-preferences-selected btn btn-sm py-0' : 'btn btn-sm py-0'}`} name='updatedFilterType[]'
                            value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(filters, updatedFilterType)}>
                            <div className='d-flex mx-auto dietary-logo dietary-logo-wrapper'>
                              {filters?.logo ? <div className='d-flex align-items-center justify-content-center' style={{ width: '36px', height: '36px' }} >{parse(filters?.logo)}</div> : 
                                <img src={MarketplaceFilterDummyLogo} alt='marketplacefilters_logo'/>
                              }
                            </div>
                              <p className='mt-2 mb-0 dietary-labels'>{filters?.name} </p>
                          </button>
                          {(lastIndexOfPreferedFilter === index) && <div>
                            <div className='filters-divider'></div>
                          </div>}
                         </div>
                          }
                      </React.Fragment>
                    </div>
                  </div>
                </Slide>)
            })}
          </MenuDetailPageCarousel>
          {/* <div className='market-place-cuisines-dietries'>
              {<div className='restrictions-toogle' onClick={clearFilters}>
                {<div className='cuisines-dietries-toogle rounded-pill'>
                  <h6 className='mb-0'>Show less</h6>
                  <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                </div> 
                }
              </div>}
            </div> */}
          {checkResetFilter && divPositionCheck && <button className='mr-auto btn float-left' onClick={resetAllFilters}>
            {/* <i className='fas fa-minus-circle pr-1' aria-hidden='true' /> Clear
          Filters */}
            <div className='cuisines-dietries-view-more  d-inline-flex ml-auto'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="arrows-repeat-light" d="M6.875,3.506h8.273L12.725,5.523a.623.623,0,0,0-.08.879.631.631,0,0,0,.48.224.622.622,0,0,0,.4-.145l3.75-3.12a.64.64,0,0,0,.225-.514.576.576,0,0,0-.225-.479l-3.75-3.12a.627.627,0,0,0-.881.079.623.623,0,0,0,.08.879l2.424,2.052H6.875A6.848,6.848,0,0,0,0,9.086a.634.634,0,0,0,.625.659.626.626,0,0,0,.625-.624A5.626,5.626,0,0,1,6.875,3.506ZM19.375,8.5a.624.624,0,0,0-.625.624,5.619,5.619,0,0,1-5.59,5.615H4.852l2.424-2.017a.624.624,0,1,0-.8-.958l-3.75,3.12a.607.607,0,0,0-.227.445.677.677,0,0,0,.225.479l3.75,3.12a.5.5,0,0,0,.365.18.624.624,0,0,0,.4-1.1L4.852,15.984h8.273A6.877,6.877,0,0,0,20,9.121.626.626,0,0,0,19.375,8.5Z" transform="translate(0 0.896)" fill="#167ffb" />
              </svg>
              <h6 className='ml-2'>Reset All Filters</h6>
              {/* <i className='fas fa-chevron-up mx-2 pb-1'></i> */}
            </div>
          </button>}
          {false && !divPositionCheck && <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='market-place-searchbar mr-40 d-none d-lg-block'>
              <span className='search_icon' id='searchRestaurantButton' onClick={() => searchRestaurants(false)}><i className='fa fa-search'></i></span>
              <input className='search_input' type='text' name='searchRestaurant' onKeyPress={keyPressed} onChange={onChange}
                placeholder='Search Food, Restaurants, Cuisines, etc.' id='searchRestaurant'
              />
            </div>
            {marketPlaceState?.selectedTypehead.map((typeahead, index) => (typeahead ? <span key={index} className='badge badge-pill custome-gray-badge px-3 py-2 py-md-1 m-1'>
              {typeahead} <i className='ml-2 fas fa-times cursor-pointer' onClick={() => closeSearch(typeahead)}></i></span> : ''))}
          </div>
          }
        </div>
      </div>
      <div className='container d-flex px-4 px-md-5 justify-content-center'>
        {((checkResetFilter && totalRestaurantsCount > 0) || SelectedFilterCount !== 0) && (
          <button className='btn py-0 fw-600 d-flex align-items-center' onClick={resetAllFilters}>
              {(totalRestaurantsCount === null || isFiltersLoading) && (
                <div className='loading-spinner mr-2 mb-3'>
                  <Spinner spinnerSizeClass='filter-count-loading' />
                </div>
              )}
              <p className='text-gray-100 fw-600'>
                {totalRestaurantsCount !== null && !marketplaceCombosLoading && !marketplaceFavoritesLoading && !marketPlaceMostPopularRestaurantsLoading && !marketPlaceNewRestaurantNearYouLoading && !marketPlaceFiltersLoading && !eRestaurantLoading && !loading
                  ? totalRestaurantsCount + ' Results Found'
                  : 'Results Found'}
              </p>
            <div className='cuisines-dietries-view-more d-inline-flex ml-4 mb-3'>
              <h6 className='m-0 py-2'>Reset Filters</h6>
            </div>
          </button>)}
              </div>
            </div>
          </div>}
        </>}
      </>
      }
      <div id='sidebar' style={{backgroundColor:'#F5F5F5'}} className={onPopup === true ? 'col-12 px-0 marketplace-filters-popUp' : 'col-12 d-md-block d-none px-0'}>
        {false && <div className="container px-0">
        <div className={`col-12 ${!divPositionCheck && 'd-md-block'} d-none pt-4 px-0 market-place-filters-container-pill`} >
          {/* <div className={`col-12 ${!divPositionCheck && 'd-md-block'} d-none bg-white pt-4 px-4 market-place-filters-container-pill`} > */}
          {
            !divPositionCheck &&
            <div className='marketplace-filters'>
              <div className='col filters-slection-continer'>
                {/* <div className='py-3'> */}
                {/* <div className='pretty p-icon p-round mr-2'>
                  <input type='radio' name='meal_type' onChange={() => onChangeMealType('breakfast')} checked={meal_type === 'breakfast' ? true : false} />
                  <div className='state '>
                    <i className='icon fas fa-circle'></i>
                    <label>Breakfast</label>
                  </div>
                </div> */}
                {/* <div className='pretty p-icon p-round mr-2'>
                  <input type='radio' name='meal_type' onChange={() => onChangeMealType('lunch')} checked={meal_type === 'lunch' ? true : false} />
                  <div className='state '>
                    <i className='icon fas fa-circle'></i>
                    <label>Lunch</label>
                  </div>
                </div> */}
                {/* <div className='pretty p-icon p-round mr-2'>
                  <input type='radio' name='meal_type' onChange={() => onChangeMealType('dinner')} checked={meal_type === 'dinner' ? true : false} />
                  <div className='state '>
                    <i className='icon fas fa-circle'></i>
                    <label>Dinner</label>
                  </div>
                </div> */}
                {/* <div className='col d-flex'>
                <div className='mx-2'>is this a buffet?</div>
                <div className='custom-control custom-switch custom-switch-buffet  pull-right d-inline mr-0'>
                  <input type='checkbox' className=' custom-control-input ' id='mealTypeCheck' name='mealTypeCheck' checked={marketPlaceState?.mealTypeCheck} onChange={onChange} /><label
                    className='custom-control-label custom-buffet-toggle' htmlFor='mealTypeCheck'> {marketPlaceState?.mealTypeCheck ? 'yes' : 'no'} </label>
                </div>
              </div>
            </div> */}
                {/* {restrictionToggle && <div className='restrictions-toogle' onClick={() => { setRestrictionToggle(!restrictionToggle); heightController && heightController(restrictionToggle) }}>
              {restrictionToggle ? <div className='cuisines-dietries-toogle rounded-pill'>
                <h6 className='mb-0'>View less</h6>
                <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
              </div> :
                <div className='cuisines-dietries-toogle rounded-pill'>
                  <h6 className='px-1 mb-0'>{'View more'}</h6>
                  <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                </div>
              }
            </div>
            } */}
              </div>

              <div className='col cuisines-diet-filter-menu px-0'>
                {!restrictionToggle && <div>
                  {marketPlaceState?.restaurants?.cuisines?.slice(0, 2).map((cuisine, index) => {
                    return <React.Fragment key={index}>
                      {eRestaurantLoading ? <button type='button' className={cuisine?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                        value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                      </button> :
                        <button type='button' className={cuisine?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                          value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(cuisine, 'cuisines')}>
                          {/* removed the tick icon for cuisines */}
                          {/* {cuisine.selected === true && selectedFilter} */}
                          {cuisine?.name}
                        </button>
                      }
                    </React.Fragment>
                  })}
                  {marketPlaceState?.restaurants?.dietaries?.slice(0, 3)?.map((dietary, index) => {
                    return (
                      (dietary?.name?.trim() === 'Gluten-Free Upon Request') ||
                        (dietary?.name?.trim() === 'Nut-Free Upon Request') ||
                        (dietary?.name?.trim() === 'Dairy-Free Upon Request') ||
                        (dietary?.name?.trim() === 'Vegan-Upon Request') ? ''
                        :
                        <React.Fragment key={index}>
                          {eRestaurantLoading ? <button type='button' className={dietary?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                            value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                          </button> :
                            <button type='button' className={dietary?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='dietary[]'
                              value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(dietary, 'dietaries')}>
                              {/* {dietary.selected === true && selectedFilter} */}
                              {/* {dietary.name} */}
                              <div className='d-flex dietary-logo'>
                                {dietary?.logo ? <div className='mr-2' style={{ width: '16px', height: '16px' }} >{parse(dietary?.logo)}</div> : ''}
                                <p>{dietary?.name} </p>
                              </div>
                            </button>}
                        </React.Fragment>)
                  })}
                  {marketPlaceState?.restaurants?.ingredients?.slice(0, 3)?.map((ingredient, index) => {
                    return <React.Fragment key={index}>
                      {eRestaurantLoading ? <button type='button' className={ingredient?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                        value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                      </button> :
                        <button type='button' className={ingredient?.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='ingredients[]'
                          value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(ingredient, 'ingredients')}>
                          {/* {ingredient.selected === true && selectedFilter}
                      {ingredient.name} */}
                          <div className='d-flex dietary-logo'>
                            {ingredient?.logo ? <div className='mr-2' style={{ width: '16px', height: '16px' }} >{parse(ingredient?.logo)}</div> : ''}
                            <p>{ingredient?.name} </p>
                          </div>
                        </button>}
                    </React.Fragment>
                  })}
                </div>}
                <div className='market-place-cuisines-dietries'>
                  {!restrictionToggle && <div className='restrictions-toogle' onClick={() => { setRestrictionToggle(!restrictionToggle); heightController && heightController(restrictionToggle) }}>
                    {restrictionToggle ? <div className='cuisines-dietries-toogle rounded-pill'>
                      <h6 className='mb-0'>Show less</h6>
                      <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                    </div> :
                      <div className='cuisines-dietries-toogle rounded-pill d-flex  align-items-center text-nowrap'>
                        <p className='px-1 fs-18 fw-normal mb-0 '>{'View More'}</p>
                        <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                      </div>
                    }
                  </div>}
                </div>
              </div>
            </div>
          }
          {/* <div className='d-flex justify-content-between align-items-center '>
          <div className='col-auto pt-4 pr-0'>
            <button className='btn btn-light d-inline-block d-md-none my-3'><i className='fas fa-list-ul'></i> Filters</button>
            <button className='btn btn-light   my-3' data-toggle='dropdown'><i className='fas fa-chevron-circle-down'></i> Sort By</button>
          </div>
          <div className='d-flex justify-content-left '>
            <div className='searchbar'>
              <input className='search_input' type='text' name='searchRestaurant' onKeyPress={keyPressed} onChange={onChange}
                placeholder='Search Food, Restaurants, Cuisines, etc.' id='searchRestaurant'
              />
              <span className='search_icon' id='searchRestaurantButton' onClick={() => searchRestaurants(false)}><i className='fas fa-search'></i></span>
            </div>
          </div>
         </div> */}
          <div className={`animated-marketplace-restrictions ${!restrictionToggle ? 'marketplace-restrictions-open' : 'marketplace-restrictions-closed'}`}>
            {/* {restrictionToggle && <div className='restrictions-toogle d-flex' onClick={() => { setRestrictionToggle(!restrictionToggle); heightController && heightController(restrictionToggle) }}>
              {restrictionToggle ? <div className='cuisines-dietries-view-more rounded-pill d-inline-flex ml-auto'>
                <h6 className='px-1 fs-18 fw-normal mb-0'>View less</h6>
                <i className='fas fa-chevron-up mx-2 mt-2 pb-1'></i>
              </div> :
                <div className='cuisines-dietries-toogle rounded-pill'>
                  <p className='px-1 mb-0'>{'View more'}</p>
                  <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                </div>
              }
            </div>
            } */}

            <div id={cuisinesDivId} className='cusine-ease-grow cusine-fadin-out animated'>
              {marketPlaceState?.restaurants?.cuisines?.length > 0 ? <div className='d-flex aling-items-start justify-content-between pb-2'>
                <h5 className='marketplace-cuisines-dietry-header'>Cuisine Preference</h5>
                {restrictionToggle && <div className='restrictions-toogle d-flex' onClick={() => { setRestrictionToggle(!restrictionToggle); heightController && heightController(restrictionToggle) }}>
                  {restrictionToggle ? <div className='cuisines-dietries-view-more rounded-pill d-inline-flex ml-auto'>
                    <h6 className='px-1 fs-18 fw-normal mb-0'>View less</h6>
                    <i className='fas fa-chevron-up mx-2 pb-1'></i>
                  </div> :
                    <div className='cuisines-dietries-toogle rounded-pill'>
                      <p className='px-1 mb-0'>{'View more'}</p>
                      <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                    </div>
                  }
                </div>
                }
              </div> : ''}
              {marketPlaceState?.restaurants?.cuisines?.map((cuisine, index) => {
                return <React.Fragment key={index}>
                  {eRestaurantLoading ? <button type='button' className={cuisine.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                    value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                  </button> :
                    cuisine.name !== 'Pick for Me' &&
                    <button type='button' className={cuisine.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                      value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(cuisine, 'cuisines')} >
                      {/* removed the tick icon below for cuisines */}
                      {/* {cuisine.selected === true && selectedFilter} */}
                      {cuisine.name}
                    </button>
                  }
                </React.Fragment>
              })}
            </div>
            {/* {marketPlaceState?.restaurants?.cuisines?.length > 5 &&
            (marketPlaceState?.currentNumberOfShownCuisines <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('cuisineLoaderBtn', 'More', cuisinesDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
              <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('cuisineLoaderBtn', 'Less', cuisinesDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}
            <div id={dietariesDivId} className='cusine-ease-grow dietary-fadin-out animated'>
              {marketPlaceState?.restaurants?.dietaries?.length > 0 ? <div className='d-flex aling-items-start justify-content-between pb-2'>
                <h5 className='marketplace-cuisines-dietry-header'>Dietary Restrictions</h5>
                {(marketPlaceState?.restaurants?.cuisines?.length < 0 && restrictionToggle) && <div className='restrictions-toogle d-flex' onClick={() => { setRestrictionToggle(!restrictionToggle); heightController && heightController(restrictionToggle) }}>
                  {restrictionToggle ? <div className='cuisines-dietries-view-more rounded-pill d-inline-flex ml-auto'>
                    <h6 className='px-1 fs-18 fw-normal mb-0'>View less</h6>
                    <i className='fas fa-chevron-up mx-2 pb-1'></i>
                  </div> :
                    <div className='cuisines-dietries-toogle rounded-pill'>
                      <p className='px-1 mb-0'>{'View more'}</p>
                      <i className='fas fa-chevron-down mx-2 mt-2 pb-1'></i>
                    </div>
                  }
                </div>
                }
              </div> : ''}
              {marketPlaceState?.restaurants?.dietaries?.map((dietary, index) => {
                return (
                  (dietary?.name?.trim() === 'Gluten-Free Upon Request') ||
                    (dietary?.name?.trim() === 'Nut-Free Upon Request') ||
                    (dietary?.name?.trim() === 'Dairy-Free Upon Request') ||
                    (dietary?.name?.trim() === 'Vegan-Upon Request') ? ''
                    :
                    <React.Fragment key={index}>
                      {eRestaurantLoading ? <button type='button' className={dietary.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                        value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                      </button> :
                        <button type='button' className={dietary.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='dietary[]'
                          value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(dietary, 'dietaries')}>
                          {/* {dietary.selected === true && selectedFilter}
                      {dietary.name} */}
                          <div className='d-flex dietary-logo'>
                            {dietary?.logo ? <div className='mr-2' style={{ width: '16px', height: '16px' }} >{parse(dietary?.logo)}</div> : ''}
                            <p>{dietary.name} </p>
                          </div>
                        </button>}
                    </React.Fragment>
                )
              })}
              {marketPlaceState?.restaurants?.ingredients?.map((ingredient, index) => {
                return <React.Fragment key={index}>
                  {eRestaurantLoading ? <button type='button' className={ingredient.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='cuisines[]'
                    value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                  </button> :
                    <button type='button' className={ingredient.selected === true ? 'cuisines-diet-pills cuisine-diet-preferences-selected btn btn-sm' : 'cuisines-diet-pills profile-cuisine-diet-preferences btn btn-sm'} name='ingredients[]'
                      value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(ingredient, 'ingredients')}>
                      {/* {ingredient.selected === true && selectedFilter}
                    {ingredient.name} */}
                      <div className='d-flex dietary-logo'>
                        {ingredient?.logo ? <div className='mr-2' style={{ width: '16px', height: '16px' }} >{parse(ingredient?.logo)}</div> : ''}
                        <p>{ingredient.name} </p>
                      </div>
                    </button>}
                </React.Fragment>
              })}
            </div>
            {/* {marketPlaceState?.restaurants?.dietaries?.length > 5 &&
            (marketPlaceState?.currentNumberOfShownDietries <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('dietaryLoaderBtn', 'More', dietariesDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
              <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('dietaryLoaderBtn', 'Less', dietariesDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}

            {/* <div id={ingredientsDivId} className='cusine-ease-grow ingredient-fadin-out animated'>
            {marketPlaceState?.restaurants?.ingredients?.length > 0 ? <h5 className='pb-3 my-3'>Ingredients</h5> : ''}
            {marketPlaceState?.restaurants?.ingredients?.slice(0, marketPlaceState?.currentNumberOfShownIngredients).map((ingredient, index) => {
              return <React.Fragment key={index}>
                {eRestaurantLoading ? <button type='button' className={ingredient.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='cuisines[]'
                  value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
                </button> :
                  <button type='button' className={ingredient.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='ingredients[]'
                    value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(ingredient.id, 'ingredients')} >{ingredient.name}
                  </button>}
              </React.Fragment>
            })}
          </div> */}
            {/* {marketPlaceState?.restaurants?.ingredients?.length > 5 &&
            (marketPlaceState?.currentNumberOfShownIngredients <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('ingredientLoaderBtn', 'More', ingredientsDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
              <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('ingredientLoaderBtn', 'Less', ingredientsDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}

          </div>
        </div>
        </div>}

        {/* <div className='d-flex justify-content-between align-items-center pt-0 pt-md-3'>
          <div className='col-auto pr-0 pl-0'>
          <button className='btn btn-light d-inline-block d-md-none my-3'><i className='fas fa-list-ul'></i> Filters</button>
          <button className='btn btn-light my-3 pl-0 sort-button' data-toggle='dropdown'><span className='location-sorting'> Sort By</span><i className='fas fa-chevron-down pl-2'></i></button>
          <FilterRestaurantsBy onSortRestaurants={sortBy} />
         </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='market-place-searchbar mr-40'>
              <span className='search_icon' id='searchRestaurantButton' onClick={() => searchRestaurants(false)}><i className='fa fa-search'></i></span>
              <input className='search_input' type='text' name='searchRestaurant' onKeyPress={keyPressed} onChange={onChange}
                placeholder='Search Food, Restaurants, Cuisines, etc.' id='searchRestaurant'
              />
            </div>
            {marketPlaceState?.selectedTypehead.map((typeahead, index) => (typeahead ? <span key={index} className='badge badge-pill custome-gray-badge px-3 py-2 py-md-1 m-1'>
              {typeahead} <i className='ml-2 fas fa-times cursor-pointer' onClick={() => closeSearch(typeahead)}></i></span> : ''))}
          </div>
        </div> */}

             {/* Enterprise Account Section starts here*/}
        {/* {showEnterprizeWidget && !fromPublicMarketPlace && userCompany?.enterprise_status === 'not_converted' && userCompany?.self_signup && <section>
          <div className="mt-3 enterprise-account-wrapper">
            <div className="row px-3 pt-32 pb-40 position-relative">

              <button className='btn btn-sm btn-light rounded-circle cursor-pointer enterprise-mode-colose-btn' style={{ 'zIndex': '3' }} onClick={() => setShowEnterprizeWidget(false)}>
                <i onClick={() => setShowEnterprizeWidget(false)} className="fas fa-times fa-lg mt-1 text-primary cursor-pointer"></i>
              </button>

              <div className='col-lg-10 pt-2 pl-5'>
                <h2 className='text-white fw-600 mb-2'>Upgrade to Enterprise Plan</h2>
                <p className="lead text-white mb-4">Need to setup recurring meal plans for multiple offices, or let users & guests order their own meals? Checkout our Enterprise Plan below.</p>
                <button onClick={() => openConfirmEnterprizePopUp()} className='btn btn-light rounded-pill text-primary fw-600 bg-white' style={{padding:'11px 32px'}}>Request Enterprise Plan</button>
                <button onClick={() => openEnterprizeModal()} className='btn btn-light rounded-pill enterprise-mode-btn'>Learn More</button>
              </div>
              <div className="col-lg-2 enterprise-account-img">
                <img src="/imgs/rocket.svg" alt="" />
              </div>
            </div>
          </div>
        </section>} */}
        {/* Enterprise Account Ends here */}

        {/* <h5 className='border-bottom pb-3 mb-3'>Delivery Details</h5> */}
        {/* <div id={datePickerId} className='py-3 px-2 selectable-filter position-relative' onClick={() => datePickerRef && !datePickerRef?.state?.open && datePickerRef.setOpen(true)}>
        <DatePicker
          ref={DatePickerRefHandler}
          selected={startDate}
          onChange={d => { handleDateChange(d as Date); datePickerRef && datePickerRef.setOpen(false) }}
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={15}
          timeCaption='time'
          dateFormat={`EEEE, LLL d 'at' h:mm aa`}
          className='marketplace-time-picker-input'
          minDate={new Date()}
          maxDate={dayjs((new Date()).toString()).add(29, addTimePeriod).toDate()}
        /> <i id={datePickerIconId} className='fas fa-chevron-circle-right small muted float-right date-picket-circle' style={{ lineHeight: 'inherit' }}></i>
      </div> */}
        {/* <small className='muted font-weight-bold' style={{ 'color': 'rgb(17, 17, 17)' }}>Delivery Address</small><br /> */}
        {/* <div className='py-3 px-2 selectable-filter' onClick={() => { openModal(); onPopup === true && closePopup && closePopup() }}>
        <div className='main-custom-options main-custom-options-marketplace'>
          <input type='text' className='dropdown-menu-arrow marketplace-address-picker-input form-control p-0' id='address' name='selectedCompanyAddress' value={marketPlaceState?.selectedAddressDetail?.formatted_address || selectedCompanyAddress?.formatted_address || ''} readOnly />
          <i className='fas fa-chevron-circle-right small muted float-right address-circle-right' style={{ lineHeight: 'inherit' }}></i>
        </div>
      </div> */}
        {/* <hr className='w-50 my-1' /> */}
        {/* <div className='py-3'>
        <div className='pretty p-icon p-round mr-2'>
          <input type='radio' name='meal_type' onChange={() => onChangeMealType('breakfast')} checked={meal_type === 'breakfast' ? true : false} />
          <div className='state '>
            <i className='icon fas fa-check'></i>
            <label>Breakfast</label>
          </div>
        </div>
        <div className='pretty p-icon p-round mr-2'>
          <input type='radio' name='meal_type' onChange={() => onChangeMealType('lunch')} checked={meal_type === 'lunch' ? true : false} />
          <div className='state '>
            <i className='icon fas fa-check'></i>
            <label>Lunch</label>
          </div>
        </div>
        <div className='pretty p-icon p-round mr-2'>
          <input type='radio' name='meal_type' onChange={() => onChangeMealType('dinner')} checked={meal_type === 'dinner' ? true : false} />
          <div className='state '>
            <i className='icon fas fa-check'></i>
            <label>Dinner</label>
          </div>
        </div>
        <div className='custom-control custom-switch  pull-right d-inline mr-0'>
          <input type='checkbox' className=' custom-control-input' id='mealTypeCheck' name='mealTypeCheck' checked={marketPlaceState?.mealTypeCheck} onChange={onChange} /><label
            className='custom-control-label' htmlFor='mealTypeCheck'> Buffet </label>
        </div>
      </div> */}
        {/* <hr /> */}
        {/* <div className='d-flex justify-content-left '>
        <div className='searchbar' style={{ width: '100%' }}>
          <input className='search_input' type='text' name='searchRestaurant' onKeyPress={keyPressed} onChange={onChange}
            placeholder='Search Food, Restaurants, Cuisines, etc.' id='searchRestaurant'
          />
          <span className='search_icon' id='searchRestaurantButton' onClick={() => searchRestaurants(false)}><i className='fas fa-search'></i></span>
        </div>
      </div> */}
        {/* {marketPlaceState?.selectedTypehead.map((typeahead, index) => (typeahead ? <span key={index} className='badge badge-pill badge-dark p-2 m-1'>
        {typeahead} <i className='ml-2 far fa-times-circle cursor-pointer' onClick={() => closeSearch(typeahead)}></i></span> : ''))} */}
        {/* <hr /> */}
        {/* <div id={cuisinesDivId} className='cusine-ease-grow cusine-fadin-out animated'>
        {marketPlaceState?.restaurants?.cuisines?.length > 0 ? <h5 className='border-bottom pb-3 mb-3'>Cuisines</h5> : ''}
        {marketPlaceState?.restaurants?.cuisines?.slice(0, marketPlaceState?.currentNumberOfShownCuisines).map((cuisine, index) => {
          return <React.Fragment key={index}>
            {eRestaurantLoading ? <button type='button' className={cuisine.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='cuisines[]'
              value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
            </button> :
              <button type='button' className={cuisine.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='cuisines[]'
                value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(cuisine.id, 'cuisines')} >{cuisine.name}
              </button>
            }
          </React.Fragment>
        })}
      </div> */}
        {/* {marketPlaceState?.restaurants?.cuisines?.length > 5 &&
        (marketPlaceState?.currentNumberOfShownCuisines <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('cuisineLoaderBtn', 'More', cuisinesDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
          <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('cuisineLoaderBtn', 'Less', cuisinesDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}
        {/* <div id={dietariesDivId} className='cusine-ease-grow dietary-fadin-out animated'>
        {marketPlaceState?.restaurants?.dietaries?.length > 0 ? <h5 className='border-bottom pb-3 my-3'>Dietary Restrictions</h5> : ''}
        {marketPlaceState?.restaurants?.dietaries?.slice(0, marketPlaceState?.currentNumberOfShownDietries)?.map((dietary, index) => {
          return <React.Fragment key={index}>
            {eRestaurantLoading ? <button type='button' className={dietary.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='cuisines[]'
              value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
            </button> :
              <button type='button' className={dietary.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='dietary[]'
                value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(dietary.id, 'dietaries')} >{dietary.name}
              </button>}
          </React.Fragment>
        })}</div> */}
        {/* {marketPlaceState?.restaurants?.dietaries?.length > 5 &&
        (marketPlaceState?.currentNumberOfShownDietries <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('dietaryLoaderBtn', 'More', dietariesDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
          <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('dietaryLoaderBtn', 'Less', dietariesDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}
        {/* <div id={ingredientsDivId} className='cusine-ease-grow ingredient-fadin-out animated'>
        {marketPlaceState?.restaurants?.ingredients?.length > 0 ? <h5 className='border-bottom pb-3 my-3'>Ingredients</h5> : ''}
        {marketPlaceState?.restaurants?.ingredients?.slice(0, marketPlaceState?.currentNumberOfShownIngredients).map((ingredient, index) => {
          return <React.Fragment key={index}>
            {eRestaurantLoading ? <button type='button' className={ingredient.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='cuisines[]'
              value='2' id='autoCuisine0'  ><Skeleton height={20} width={40} />
            </button> :
              <button type='button' className={ingredient.selected === true ? 'btn btn-outline-dark btn-cuisine btn-sm m-1 selected-dark' : 'btn btn-outline-dark btn-cuisine btn-sm m-1'} name='ingredients[]'
                value='2' id='autoCuisine0' onClick={() => selectSearchStatistics(ingredient.id, 'ingredients')} >{ingredient.name}
              </button>}
          </React.Fragment>
        })}
      </div>
      {marketPlaceState?.restaurants?.ingredients?.length > 5 &&
        (marketPlaceState?.currentNumberOfShownIngredients <= 5 ? <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('ingredientLoaderBtn', 'More', ingredientsDivId, !!onPopup)}><i className='fas fa-plus'></i><span> More</span></button> :
          <button className='btn btn-light btn-sm d-block w-100 mt-3' onClick={() => lessMoreHandler('ingredientLoaderBtn', 'Less', ingredientsDivId, !!onPopup)}><i className='fas fa-minus'></i><span> Less</span></button>)} */}
      </div>
      <>
        {enterprizePopUp && <EnterprizePlanViewerPopUp
        modalIsOpen={enterprizePopUp}
        closeModal={closeModal}
        confirmAction={openConfirmEnterprizePopUp}
        />}
        {enterprizeConfirm && <EnterprizeConfirmationPopUp
          modalIsOpen={enterprizeConfirm}
          closeModal={closeEnterpriseConfirmationPopUp}
          confirmAction={() => { }}
        />
        }
      </>
    </>
  );
};

export default MarketplaceSearchBar;
