import React from 'react'
import { Slide } from 'pure-react-carousel';
import Skeleton from 'react-loading-skeleton';
import { ArrayOfLength } from '../../../Utilities/ArraysUtilities';
import MenuDetailPageCarousel from '../../../Pages/Menu/Common/MenuDetailPageCarousel';
import DietaryDummyLogo from '../../../Pages/Menu/SVGs/DietaryDummyLogo.svg';

export const MenuFiltersLoading = ({ restaurantSlidesCount, }: { restaurantSlidesCount: number }) => {
  return <>
    <MenuDeitariesIngredientsLoading />
    <MenuRestaurantLoading totalSlides={Math?.ceil(restaurantSlidesCount)} />
  </>
}

export const MenuRestaurantLoading = ({ totalSlides, }: { totalSlides: number }) =>
  <div className='px-1 px-md-5' style={{ background: '#f7f7f7' }}>
    <MenuDetailPageCarousel
      totalSlides={Math?.ceil(totalSlides)}
      visibleSlides={totalSlides}
      showCarouselButtons={false}
      sliderTrayClassName='mx-auto'
    >
      {ArrayOfLength(Math?.ceil(totalSlides))?.map((restaurant, index: number) =>
        <Slide key={index} index={index} className='py-3'>
          <div className={`restaurant-card mr-3 mr-sm-0 mx-sm-3 mw-100`}>
            <span className='mr-1 mr-sm-2'><Skeleton circle width={45} height={45} /></span>
            <div style={{ width: 'calc(100% - 50px)' }} className='my-auto pr-2'>
              <p style={{ width: 'calc(100% - 0px)' }} className='text-truncate m-0'><Skeleton width='100%' height={20} /></p>
              <span className='badge badge-light'><Skeleton width={48} height={12} /></span>
              <span className='badge badge-light'><Skeleton width={48} height={12} /></span>
            </div>
          </div>
        </Slide>
      )}
    </MenuDetailPageCarousel>
  </div>

export const MenuDeitariesIngredientsLoading = () => {
  return <div className='px-3 px-md-5'>
    <div style={{ width: 'fit-content' }} className='py-2 m-auto'>
      <div className='d-flex flex-wrap justify-content-center position-relative '>
        {ArrayOfLength(Math?.ceil(6))?.map(dietary =>
          <div key={dietary} className={`dietary-logo position-relative`}>
            <img src={DietaryDummyLogo} alt='' className='mr-2' />
            <p> <Skeleton width={65} height={20} /></p>
          </div>
        )}
      </div>
    </div>
  </div>
}

export const MenuFoodItemsLoading = ({ totalSlides, visibleSlides }: { totalSlides: number, visibleSlides: number }) => {

  return <>
    {[1, 2].map(section => <React.Fragment key={section}>
      <div className={`d-flex align-items-center`}>
        <div className='mr-2'><Skeleton circle width={66} height={66} /></div>
        <h4 className='m-0'><Skeleton width={120} height={24} /></h4>
      </div>
      <MenuDetailPageCarousel
        totalSlides={totalSlides}
        visibleSlides={visibleSlides}
        showCarouselButtons={false}
      >
        {ArrayOfLength(totalSlides)?.map((fooditem, index) => (
          <Slide className='new-section py-4' key={fooditem} index={index}>
            <div className={'h-100 mx-2'}>
              <div className='menu-detail-fooditem-card'>
                <Skeleton width='100%' height={150} />
                <div className='p-3 flex-grow-1 bg-white'>
                  <p className='m-0'><Skeleton width='100%' height={25} /></p>
                  <div>
                    <Skeleton width={85} height={20} />
                  </div>
                  <div style={{ color: '#8B9898' }} className='mt-2 card-text d-block text-case'>
                    <Skeleton width='100%' height={30} />
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        ))}
      </MenuDetailPageCarousel>
    </React.Fragment>)}
  </>
}